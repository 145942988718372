import * as Sentry from "@sentry/browser";
import { generalNetworkError } from "module/common/helpers/variables";
import { IProspect } from "module/main/store/prospectStore/interfaces";

// select-keys function and helpers
// the different arities allows for using it with aggregates
// Ex: data = {hello: 1, two: 2, three: 4}
//     selectKeys(['hello', 'three'], data) which yields {hello: 1, three: 4}
//
// We can use it on aggregates
// Ex2: data2 = [{hello: 1, two: 2, three: 4}, {hello: 10, two: 20, three: 40}]
//      data2.map(selectKeys('two')) which yields [{two: 2}, {two: 20}]

const selectKeysArity2 = (keys: Array<string>, map: { [key: string]: any }) => {
  return keys.reduce((acc: any, key: string) => {
    if (map.hasOwnProperty(key)) acc[key] = map[key];

    return acc;
  }, {});
};

const selectKeysArity1 = (keys: Array<string>) => (map: { [key: string]: any }) =>
  selectKeysArity2(keys, map);

export const selectKeys = (keys: Array<string>, map?: { [key: string]: any }) => {
  if (map) return selectKeysArity2(keys, map);
  return selectKeysArity1(keys);
};

// get-in function and helpers
// the different arities allows for using it with aggregates
// getIn function takes a path and a data structure to walk through.
// Returns undefined a path doesn't exist.
// Ex:  data = [{hello: {one: { two: 'hello'}}}, {hello: {one: { two: 'bye'}}}]
//      data.map(getIn(['hello', 'one', 'two'])) which yields  ['hello', 'bye']
//
// Ex2: data = {hello: [1,2,3 {four: "booom"}]}
//      getIn(['hello', 3, 'four'], data) which yields "booom"
const getInArity2 = (
  path: Array<string | number>,
  map: Array<any> | { [key: string]: any }
) => {
  return path.reduce((acc: any, step: string | number) => {
    if (!acc) return undefined;
    return acc[step];
  }, map);
};
const getInArity1 =
  (path: Array<string | number>) => (map: Array<any> | { [key: string]: any }) =>
    getInArity2(path, map);

export const getIn = (
  path: Array<string | number>,
  map?: Array<any> | { [key: string]: any }
) => {
  if (map) return getInArity2(path, map);
  return getInArity1(path);
};

// update-in function to update deeply nested structures.
// It walks the `map`
// NOTE: Update the function to support multiple-arities
// this will allow it to be used with aggregates.
export const updateIn = (
  path: Array<string | number>,
  changeFn: (currentMap: any, x: any, args: any) => any,
  fnArgs: any,
  map: Array<any> | { [key: string]: any }
) => {
  // Note: This is update in place
  // walk the data-structure with the path provided to get the current-value
  const value = getIn(path, map);
  const newValue = changeFn(map, value, fnArgs); // apply the supplied update function
  const [lastKey] = path.slice(-1);

  // update the path with the new value computed with `changeFn`
  const val: any = path.slice(0, -1).reduce((acc: any, key) => acc[key], map);
  val[lastKey] = newValue;
};

export const identity = (x: any) => x;

const all = (coll: Array<any>) => coll.reduce((acc: boolean, el: any) => acc && el, true);

export const any = (coll: Array<any>) =>
  coll.reduce((acc: boolean, el: any) => acc || el, false);

export const compose =
  (...fns: Array<(...args: any[]) => void>) =>
  (args: any) =>
    fns.reduceRight((args: any, f: (...args: any[]) => void) => f(args), args);

export const convertArrayToObject = (array: Array<any>, key: any) => {
  const initialValue = {};
  return array.reduce((obj: any, item: any) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const fnil =
  (f: (...args: any[]) => void, ...args: any) =>
  (...args_: any) => {
    const newArgs = args.reduce((acc: any[], arg: any, idx: number) => {
      const arg_ = args_[idx];
      if (arg_ === null || arg_ === undefined) {
        acc.push(arg);
      } else {
        acc.push(arg_);
      }

      return acc;
    }, []);
    return f(...newArgs);
  };

export const empty = (coll: Array<any>) => coll.length === 0;

export const emptyMap = (map: any) => Object.keys(map).length === 0;

export const allEmpty = (...args: Array<any>) => all(args.map((coll) => empty(coll)));

export const hasKey = (map: any, key: string) => map.hasOwnProperty(key);

export const hasAnyKey = (map: any, keys: Array<string>) => {
  return any(keys.map((key: string) => hasKey(map, key)));
};
export const anyEmpty = (...args: Array<any>) => any(args.map((coll) => empty(coll)));

export const delay = (fn: (...args: any[]) => void, millisecs: number) =>
  setTimeout(() => fn(), millisecs);

export const toPromise = (fn: (...args: any[]) => void) =>
  new Promise((resolve) => resolve(fn()));

export const filterBy = (path: string[]) => (coll: any[], data: any) => {
  return coll.filter((item: any) => getIn(path, item) === data);
};

export const filterById = (coll: any[], data: any) => filterBy(["id"])(coll, data);

// string fns
export const capitalize = (str: string) =>
  str ? str[0].toUpperCase() + str.slice(1) : str;

// export functionality
export const downloadBlob = ({
  blob,
  fileName,
  replaceDefaultName = false,
}: {
  blob: any;
  fileName: string;
  replaceDefaultName?: boolean;
}) => {
  const url: any = window.URL.createObjectURL(new Blob([blob]));
  const link: any = document.createElement("a");
  const today: any = new Date();
  const name: string = replaceDefaultName
    ? fileName
    : fileName?.toLowerCase().replace(" ", "-") || "";

  // make the link downloadable
  link.href = url;
  link.setAttribute("download", `${name}-export-${today.toLocaleDateString()}.csv`);
  document.body.appendChild(link);
  link.click();
};

// dedupes a collection based on an objects attribute rather than
// reference.
export const dedupeByKey = (coll: Array<any>, path: Array<any>) => {
  const dedupedColl: Array<any> = [];

  coll.forEach((elem: any) => {
    if (!dedupedColl.includes(getIn(path, elem))) dedupedColl.push(elem);
  });

  return dedupedColl;
};

export const arrayEquals = (a: Array<any>, b: Array<any>) => {
  if (a.length !== b.length) return false;
  const uniqueValues = Array.from(new Set([...a, ...b]));
  for (const v of uniqueValues) {
    const aCount = a.filter((e: any) => e === v).length;
    const bCount = b.filter((e: any) => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};

interface IError {
  response: any;
  data: any;
}

export const formatErrorMessage: (
  // err in try-catch clause has to be any so we have to do this
  error: IError | any,
  defaultMessage?: string
) => string = (error, defaultMessage = generalNetworkError) => {
  try {
    if (!error || error.status === 500) return defaultMessage;

    const { response, data } = error;
    const errorData = response?.data || data;
    if (errorData?.detail) {
      return errorData.detail;
    }
    let detail = errorData && Object.values(errorData)[0];
    const [{ detail: nestedDetail }] = detail || [{}];
    if (!nestedDetail && typeof detail !== "string" && detail.length > 0) {
      detail = detail[0];
      if (typeof detail !== "string") return defaultMessage;
    }

    return nestedDetail || detail || defaultMessage;
  } catch (errorMessage) {
    Sentry.captureException(JSON.stringify({ error, errorMessage }));
    return defaultMessage;
  }
};

export const convertStringFromMatchList = (
  string: string | undefined,
  replaceObj: {
    [key: string]: string;
  }
) => {
  if (!string) return "Temporarily Unavailable";

  const specialChar = Array.from("^$.*+?=!:|/()[]{}\\");
  const matchToFind = Object.keys(replaceObj)
    .map((match) => (specialChar.includes(match) ? `\\${match}` : match))
    .join("|");

  const regEx = new RegExp(matchToFind, "g");

  const newString = string.replace(regEx, (match) => replaceObj[match]);
  return newString[0].toUpperCase() + newString.slice(1);
};

export const validateZipCode = (value: string) => {
  return /^\d{5}$/.test(value) || !value.length
    ? undefined
    : "Must be a valid 5 digit zip code.";
};

export const ROUTES_COOKIE_NAME = "prev_route";

/**
 * Checks if an element is visible in the screen
 *
 * @param element the element we're checking
 * @param offsetSize the size from the top of the page to the top visibility an element can have
 * @returns if the element is visible from the offset to the end of the screen height
 */
export const isElementVisible = (element: Element, offsetSize: number = 0) => {
  const rect = element.getBoundingClientRect();

  // Partially visible elements return false
  return rect.top >= offsetSize && rect.bottom <= window.innerHeight;
};

// local-storage store keyval
export const saveToLocalStorage = (key: string, value: string) => {
  window.localStorage.setItem(key, value);
};

export const removeFromLocalStorage = (key: string) => {
  window.localStorage.removeItem(key);
};

export const getFromLocalStorage = (key: string, defaultValue: any) => {
  const data: any = localStorage.getItem(key);
  return JSON.parse(data) || defaultValue;
};

// is used to check if the prospect is in a sms campaign or not
export const hasSMSCampaignsCheck = (prospect: IProspect) => {
  // the data can return either a list of campaigns OR a single campaign
  const campaignsArray = prospect.campaigns;
  const campaignObj = prospect.campaign;
  return !!campaignObj || !!campaignsArray?.length;
};

export const formatInputWithMaskOptions = (
  value: string,
  inputRegex: RegExp,
  replacements: string[]
) => {
  const matches = value.replace(/\D/g, "").match(inputRegex);

  if (!matches) return value;

  const { groups } = matches;
  for (let i = replacements.length - 1; i >= 0; i--) {
    const group = groups?.[`$${i}`];
    if (!group) replacements.pop();
  }
  return replacements
    .join("")
    .replace(/(\$\d)/g, (_, $) => (groups?.[$] ? groups?.[$] : ""));
};
