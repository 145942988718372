import React from "react";
import styled, { css } from "styled-components/macro";

const StyledHeader = styled.div`
  background: var(--tealBlueGradientFlip);
  padding: var(--pad2) var(--xpad);
  position: relative;
  z-index: 0;

  &:after {
    display: ${(props) => (props.extended ? "block" : "none")};
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    opacity: 0.33;
    background: var(--lightGray);
  }
  ${(props) =>
    props.theme.isMobile
      ? css`
          margin-top: 60px;
          /* navbar icon + navlink padding + nav padding */
          margin-top: calc(22px + 4vw + 0.8rem);
        `
      : null}
`;

function Header(props) {
  return (
    <StyledHeader {...props} data-test="header">
      <h2 className="text-white text-left m-0 my-1">{props.children}</h2>
    </StyledHeader>
  );
}

export default Header;
