import React, { useState, useRef, useEffect } from "react";
import { formatInputWithMaskOptions } from "module/common/utils/utils";
import { Input } from "reactstrap";

const PHONE_FORMAT_REGEX = /(?<$0>\d{0,3})(?<$1>\d{0,3})(?<$2>\d{0,4})/; //(123) 456-7890

const PhoneNumberField = ({ innerRef, name, placeholder, defaultValue, ...rest }) => {
  const [value, setValue] = useState("");
  const inputRef = useRef(null);
  const valueRef = useRef({ start: null, end: null, prevValue: value });

  const onNumberChange = (e) => {
    const { selectionStart, selectionEnd } = e.target;
    let { value: newValue } = e.target;
    const { prevValue } = valueRef.current;

    const currentDigits = newValue.replace(/\D/g, "");
    const oldDigits = prevValue.replace(/\D/g, "");

    const prevNonDigits = getNonDigitUntilSelection(prevValue, selectionStart);
    const indexToRemove = selectionStart - prevNonDigits;

    if (indexToRemove >= 0 && currentDigits.length === oldDigits.length)
      // remove closest backlooking digit if user deletes special char
      newValue = getStringWithCharRemoved(currentDigits, indexToRemove);

    const formattedPhoneNum = getFormattedPhoneNumber(newValue);

    valueRef.current = {
      start: newValue.length > value.length ? null : selectionStart,
      end: newValue.length > value.length ? null : selectionEnd,
      prevValue: formattedPhoneNum,
    };

    setValue(formattedPhoneNum);
  };

  useEffect(() => {
    const { end, start } = valueRef.current || {};
    if (start && end) inputRef.current.setSelectionRange(start, end);
  }, [value]);

  useEffect(() => {
    if (defaultValue) setValue(getFormattedPhoneNumber(defaultValue));
  }, [defaultValue]);

  return (
    <Input
      type="tel"
      data-test="phone-number-field"
      name={name}
      placeholder={placeholder || "Enter your mobile number"}
      innerRef={(e) => {
        inputRef.current = e;
        innerRef(e);
      }}
      value={value}
      onChange={onNumberChange}
      {...rest}
    />
  );
};

const getNonDigitUntilSelection = (str, selectionStart) =>
  //Retrieves length of non-digits that occur up to the user mouse selection
  str.slice(0, selectionStart + 1).replace(/\d/g, "").length;

const getStringWithCharRemoved = (str, id) => str.slice(0, id) + str.slice(id + 1);

const getFormattedPhoneNumber = (num) =>
  formatInputWithMaskOptions(num, PHONE_FORMAT_REGEX, ["($0", ") $1", "-$2"]);

export default PhoneNumberField;
