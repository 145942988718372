import { Dispatch } from "redux";
import * as api from "./api";
// actions

import { updateProspectSuccess, fetchProspect, fetchProspectSuccess } from "./actions";
import { updateRemindersProspect } from "../SequencesStore/reminders/actions";
import { addErrorToast, addNewToast } from "../Global/Toasts/actions";
import { AppDispatch } from "../store";

// selectors
import { getProspect } from "./selectors";
import { getLeadStageName, getLeadStageList } from "../leadstages/selectors";
import { prospectMessagesList } from "../ProspectDetails/messages/selectors";
import { populateProspectMessages } from "../ProspectDetails/messages/actions";

// utils
import { arrayToMapIndex } from "../utils";
import { ProspectRecord, PartialProspectRecord, IProspect } from "./interfaces";
import { getIn, hasAnyKey, empty } from "module/common/utils/utils";
import { DEAD, DocumentVisibilityState } from "module/common/helpers/variables";
import { populateProspectActivities } from "../ProspectDetails/activities/actions";
import { formatErrorMessage } from "module/common/utils/utils";
import { updateCampaignProspectsUnreadReminders } from "../campaignProspectStore/actions";

// prospect details
export const prospectFetchSingle = (id: number) => async (dispatch: any) => {
  dispatch(fetchProspect(true));

  return api
    .getProspect(id)
    .then(({ data }) => {
      const prospect = ProspectRecord(data, false);
      // anytime we call this endpoint we can assume we have the full data
      dispatch(updateProspectSuccess({ ...prospect, partial: false }));
      dispatch(fetchProspectSuccess(false));
      return data;
    })
    .catch(() => {
      dispatch(fetchProspect(false));
    });
};

export const prospectUpdate = async (
  id: any,
  payload: any,
  dispatch: any,
  optimistic: boolean,
  onSuccess: any = () => null,
  onFail: any = () => null
) => {
  return api
    .patchProspect(id, payload)
    .then((response: any) => {
      const { data } = response;
      if (!optimistic) {
        const prospect = PartialProspectRecord(data, false);
        dispatch(updateProspectSuccess(prospect));
      }
      onSuccess(data);
      return data;
    })
    .catch((error: any) => {
      if (optimistic) {
        dispatch(onFail);
      }
      dispatch(addNewToast({ message: "Failed to update prospect", color: "danger" }));
      throw error;
    });
};

export const prospectUpdateStatus =
  (id: string, payload: any, fallbackPayload?: any) => (dispatch: any, getState: any) => {
    const state = getState();
    const prospect = getProspect(state, id);
    const leadStages = getLeadStageList(state);
    const hasActiveSequence = !!fallbackPayload.activeProspectSequence;

    let newProspect;
    // logic to automatically set the ownerverified status to =verified=
    // if priority os qualified is set
    if (payload.isPriority || payload.isQualifiedLead) {
      newProspect = { ...prospect, ...payload, ownerVerifiedStatus: "verified" };
    } else {
      newProspect = { ...prospect, ...payload };
      if (hasAnyKey(payload, ["doNotCall", "wrongNumber"])) {
        newProspect.has_unread_sms = false;
        const dead = leadStages.find((leadStage: any) => {
          return leadStage.leadStageTitle === DEAD;
        });
        if (dead) {
          newProspect.leadStage = dead.id;
        }
      }
    }

    //only update reminders if has active sequence
    if (hasActiveSequence) {
      dispatch(updateRemindersProspect({ id: id, data: payload }));
    }

    // dispatch to store
    dispatch(updateProspectSuccess(newProspect));
    const onSuccess = (data: any) => {
      // update activities
      const { id, activities = [] } = data;
      if (!empty(activities)) {
        dispatch(populateProspectActivities({ id, data: activities }));
      }
    };
    const onFail = () => {
      dispatch(updateProspectSuccess(fallbackPayload));
    };
    return prospectUpdate(id, payload, dispatch, !prospect.partial, onSuccess, onFail);
  };

export const prospectUpdateOptimistically =
  (id: string, payload: any) => (dispatch: any, getState: any) => {
    const state = getState();
    const prospect = getProspect(state, id);
    const optimisticProspect = { ...prospect, ...payload };

    // dispatch optimistically
    dispatch(updateProspectSuccess(optimisticProspect));
    return prospectUpdate(id, payload, dispatch, true);
  };

const PROSPECT_REMINDER_ERROR_MESSAGE =
  "There was an issue updating the prospect reminder.";
export const prospectSetReminder =
  (id: any, payload: any) => async (dispatch: any, getState: any) => {
    return api
      .prospectSetReminder(id, payload)
      .then(({ data }) => {
        const state = getState();
        const newProspect = PartialProspectRecord(data, false);
        const prospect = getProspect(state, id);

        const updatedProspect = {
          ...prospect,
          ...newProspect,
        };

        updatedProspect.campaign = prospect.campaign; // specifically set the campaign id since that's missing from the newProspect data

        // keep the leadstage title
        dispatch(updateProspectSuccess(updatedProspect));
        // updates unread page
        dispatch(
          updateCampaignProspectsUnreadReminders({
            prospectId: updatedProspect.id,
            reminderAgent: updatedProspect.reminderAgent,
            reminderDateUtc: updatedProspect.reminderDateUtc,
          })
        );
      })
      .catch(() => {
        dispatch(addErrorToast(PROSPECT_REMINDER_ERROR_MESSAGE));
      });
  };

export const prospectRemoveReminder =
  (prospect: IProspect) => async (dispatch: AppDispatch) => {
    return api
      .prospectRemoveReminder(prospect.id)
      .then(({ data }) => {
        const prospectRecord = PartialProspectRecord(data);
        dispatch(
          updateCampaignProspectsUnreadReminders({
            prospectId: prospectRecord.id,
            reminderAgent: prospectRecord.reminderAgent,
            reminderDateUtc: prospectRecord.reminderDateUtc,
          })
        );
        dispatch(updateProspectSuccess({ ...prospect, ...prospectRecord }));
      })
      .catch(() => {
        dispatch(addErrorToast(PROSPECT_REMINDER_ERROR_MESSAGE));
      });
  };

export const prospectEmailToCrmAction =
  (id: number, payload: any) => (dispatch: any, getState: any) => {
    return api
      .prospectEmailToPodio(id, payload)
      .then(({ data }: any) => {
        const state = getState();
        const prospect = getProspect(state, id);
        const leadStage = getLeadStageName(state, "Pushed to Podio");
        const updateStatuses = {
          ownerVerifiedStatus: "verified",
          isQualifiedLead: true,
        };
        // update prospect activities
        dispatch(populateProspectActivities({ id, data }));

        dispatch(
          updateProspectSuccess({
            ...prospect,
            ...updateStatuses,
            emailedToPodio: true,
            leadStage: leadStage.id,
          })
        );
      })
      .catch((error: any) => {
        const { response = {} } = error;
        const { status } = response;

        const errorMessage = getIn(["data", "detail"], response);

        if (status === 400 && errorMessage) {
          dispatch(addNewToast({ message: errorMessage, color: "danger" }));
        } else {
          dispatch(addNewToast({ message: "Email to CRM Failed", color: "danger" }));
        }
      });
  };

export const prospectPushToZapierAction =
  (id: number, payload: any) => (dispatch: any, getState: any) => {
    return api
      .prospectPushToZapier(id, payload)
      .then(({ data }: any) => {
        const state = getState();
        const prospect = getProspect(state, id);
        const leadStage = getLeadStageName(state, "Pushed to Podio");
        const updateStatuses = {
          ownerVerifiedStatus: "verified",
          isQualifiedLead: true,
        };

        // update prospect activities
        dispatch(populateProspectActivities({ id, data }));

        dispatch(
          updateProspectSuccess({
            ...prospect,
            ...updateStatuses,
            pushedToZapier: true,
            leadStage: leadStage.id,
          })
        );
      })
      .catch((error: any) => {
        const { response = {} } = error;
        const { status } = response;

        const errorMessage = getIn(["data", "detail"], response);

        if (status === 400 && errorMessage) {
          dispatch(addNewToast({ message: errorMessage, color: "danger" }));
        } else {
          dispatch(addNewToast({ message: "Push to Zapier Failed", color: "danger" }));
        }
      });
  };

export const prospectAssignNumber =
  (id: number, payload = {}) =>
  async (dispatch: any) => {
    try {
      const response = await api.assignNumber(id, payload);
      const data = response.data;

      dispatch(updateProspectSuccess({ id, sherpaPhoneNumber: data.sherpaPhoneNumber }));
      dispatch(fetchProspectSuccess(false));

      return data;
    } catch {
      dispatch(addNewToast({ message: "Could not assign a number.", color: "danger" }));
    }

    return;
  };

const filterAlreadyReadMessages = (localMessages: any[], serverMessages: any[]) => {
  if (localMessages.length === 0) return serverMessages;

  return serverMessages.reduce((acc, item, idx) => {
    const localMessage = localMessages[idx];
    // edge case where message is marked as read is in flight and poll
    // came back before the update finished
    if (localMessage && !localMessage.unreadByRecipient && item.unreadByRecipient) {
      acc.push(localMessage);
    } else {
      acc.push(item);
    }

    return acc;
  }, []);
};

export const callGetMessages =
  (id: number) => async (dispatch: any, getState: (...args: any[]) => any) => {
    if (document.visibilityState === DocumentVisibilityState.HIDDEN) return;
    return api
      .getMessages(id)
      .then((data) => {
        const storeMessages = prospectMessagesList(getState(), id);
        const filteredMessages = filterAlreadyReadMessages(storeMessages, data);
        dispatch(
          populateProspectMessages({
            [id]: arrayToMapIndex("id", filteredMessages),
          })
        );
        if (filteredMessages.some((message: any) => message.unreadByRecipient)) {
          dispatch(updateProspectSuccess({ id, hasUnreadSms: true }));
        }
        return data;
      })
      .catch(() => {
        dispatch(
          addErrorToast(
            "Error fetching prospect messages. Please refresh the page and try again"
          )
        );
      });
  };

export const reportProspect =
  (id: number, callback: any) => async (dispatch: any, getState: any) => {
    const response = await api.reportProspect(id);
    if (response.status === 201) {
      const state = getState();
      const prospect = getProspect(state, id);
      dispatch(updateProspectSuccess({ ...prospect, isBlocked: true, doNotCall: true }));
      dispatch(
        addNewToast({
          message: "Thank you!  Prospect has been reported.",
          color: "success",
        })
      );
      dispatch(populateProspectActivities({ id, data: response.data.activities }));
    } else {
      dispatch(
        addNewToast({
          message: "An error occurred while reporting.",
          color: "danger",
        })
      );
    }
    callback();
  };

export const prospectSendMessage =
  (id: number, body: { message: string }) => (dispatch: Dispatch) => {
    return api
      .sendMessage(id, body)
      .then(({ data }) => data)
      .catch((err) => {
        const defaultMessage =
          "There was an issue while sending the message. Please refresh and try again.";
        const errorMessage = formatErrorMessage(err, defaultMessage);

        dispatch(addErrorToast(errorMessage));
        throw err;
      });
  };
