import { createAction } from "../../redux-helpers";

export const POPULATE_PROSPECT_NOTES = "POPULATE_PROSPECT_NOTES";
export const SET_PROSPECT_NOTE_ERROR = "SET_PROSPECT_NOTE_ERROR";
export const SET_PROSPECT_NOTES_STATUS = "SET_PROSPECT_NOTES_STATUS";
export const ADD_PROSPECT_NOTE = "ADD_PROSPECT_NOTE";
export const DELETE_PROSPECT_NOTE = "DELETE_PROSPECT_NOTE";
export const EDIT_PROSPECT_NOTE = "EDIT_PROSPECT_NOTE";

export const populateProspectNotes = createAction(POPULATE_PROSPECT_NOTES);
export const setProspectNotesStatus = createAction(SET_PROSPECT_NOTES_STATUS);
export const setProspectNotesError = createAction(SET_PROSPECT_NOTE_ERROR);
export const addProspectNote = createAction(ADD_PROSPECT_NOTE);
export const editProspectNote = createAction(EDIT_PROSPECT_NOTE);
export const deleteProspectNote = createAction(DELETE_PROSPECT_NOTE);
