import { keyframes } from "styled-components";

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const load = (width: number) => keyframes`
  0% {
    width: 0%;
  }

  100% {
    width: ${width};
  }
`;

export const pulse = keyframes`
  0% { opacity: 1; }
	50% { opacity: 0; }
	100% { opacity: 1; }
`;

export const cssHighlight = keyframes`
  0% { transform: scale(1, 1); }
  50% { transform: scale(1.06, 1.06); }
  100% { transform: scale(1, 1); }
`;
