import { produce } from "immer";

// actions
import {
  FETCH_PROPERTY_TAGS_SUCCESS,
  UPDATE_PROPERTY_TAG,
  REMOVE_PROPERTY_TAG,
  FETCH_PROPERTY_TAGS_STATUS,
  UPDATE_PROPERTY_TAG_SORT_ORDER,
} from "./actions";

// utils
import { arrayToMapIndex } from "../../utils";
import { Initial, Success } from "module/common/helpers/variables";

const state = {
  sortOrder: [],
  tags: {},
  status: Initial,
};

const maybeUpdateSortOrder = (tag: any, state: any, meta: any) => {
  if (meta.type === "create") {
    state.sortOrder.push(tag.id);
  }
};

const computeSortOrder = (coll: Array<any>) => coll.map((item: any) => item.id);

const updateTag = (tag: any, state: any, meta: any) => {
  if (meta.partial) {
    state.tags[tag.id] = { ...state.tags[tag.id], ...tag };
  } else {
    state.tags[tag.id] = tag;
  }

  maybeUpdateSortOrder(tag, state, meta);
};

const reducer = produce((currentState: any, action: any) => {
  switch (action.type) {
    case FETCH_PROPERTY_TAGS_SUCCESS: {
      currentState.tags = arrayToMapIndex("id", action.payload);
      currentState.sortOrder = computeSortOrder(action.payload);
      currentState.status = Success;
      break;
    }
    case UPDATE_PROPERTY_TAG: {
      // handle full override or partial
      const { meta, payload: tag } = action;
      updateTag(tag, currentState, meta);

      break;
    }
    case REMOVE_PROPERTY_TAG: {
      delete currentState.tags[action.payload];
      currentState.sortOrder = currentState.sortOrder.filter(
        (id: number) => id !== action.payload
      );
      break;
    }
    case UPDATE_PROPERTY_TAG_SORT_ORDER: {
      const sortOrder = [...currentState.sortOrder].filter(
        (id) => id !== action.payload.id
      );

      sortOrder.splice(action.payload.index, 0, action.payload.id);

      currentState.sortOrder = sortOrder;
      break;
    }
    case FETCH_PROPERTY_TAGS_STATUS: {
      currentState.status = action.payload;
    }
  }
}, state);

export default reducer;
