import { produce } from "immer";
import {
  POPULATE_ACTIVITIES,
  POPULATE_PROSPECT_ACTIVITIES,
  SET_ACTIVITIES_STATUS,
} from "./actions";
import { Fetching, Success } from "module/common/helpers/variables";
import { IState, IAction } from "./interfaces";

const initialState: IState = {
  error: "",
  list: {},
  status: Fetching,
};

export const path = ["prospectDetailsReducer", "prospectActivities"];

const reducer = produce((draft: IState, action: IAction) => {
  switch (action.type) {
    case SET_ACTIVITIES_STATUS: {
      draft.status = action.payload.status;
      break;
    }
    case POPULATE_ACTIVITIES: {
      draft.list = { ...draft.list, ...action.payload };
      draft.status = Success;
      break;
    }
    case POPULATE_PROSPECT_ACTIVITIES: {
      const { id, data = [] } = action.payload;

      if (draft.list[id]) {
        const currentActivities = draft.list[id].map((activity: any) => activity.id);
        const newActivities = data.filter((activity: any) => {
          return !currentActivities.includes(activity.id);
        });
        draft.list[id] = draft.list[id].concat(newActivities);
      } else {
        draft.list[id] = data;
      }
      break;
    }
  }
}, initialState);

export default reducer;
