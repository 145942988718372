import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ToastComponent from "./ToastComponent";
import { getToasts } from "../../../store/Global/Toasts/selectors";
import styled, { css } from "styled-components";
import { emptyToastArray } from "../../../store/Global/Toasts/actions";
import { toastLingerTime } from "module/common/helpers/variables";

const Wrapper = styled.div`
  width: 100%;
  pointer-events: none;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  z-index: 10000;

  ${(props) =>
    props.theme.isDesktop
      ? css`
          max-width: 500px;
        `
      : null}
  * {
    pointer-events: all;
  }
`;

function ToastContainer() {
  const toasts = useSelector(getToasts);
  const dispatch = useDispatch();
  const mappedToasts = toasts.map(({ message, id, color, persist, removeClose }) => (
    <ToastComponent
      dispatch={dispatch}
      color={color}
      key={id}
      id={id}
      message={message}
      persist={persist}
      removeClose={removeClose}
    />
  ));

  // automatically clear toast array after last toast has faded away
  useEffect(() => {
    let timeout;
    toasts.length &&
      (timeout = setTimeout(() => dispatch(emptyToastArray()), toastLingerTime + 400));
    return () => clearTimeout(timeout);
  }, [dispatch, toasts]);

  return <Wrapper>{mappedToasts}</Wrapper>;
}

export default ToastContainer;
