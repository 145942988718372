import React, { useEffect, Suspense } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { history } from "module/common/helpers/history";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../utils/theme";
import GlobalStyle from "module/common/helpers/GlobalStyles";
import { isMobile } from "detectDevice";
import moment from "moment-timezone";

// components
import { ProtectedRoute } from "./components/ProtectedRoute";
import DataLoader from "module/common/components/DataLoader";
import { NavbarDesktop, NavbarMobile } from "./components/Navbar";
import WidgetContainer from "module/common/widgets/WidgetContainer";
import NateroInitScript from "../scripts/natero";
import BannerContainer from "module/main/App/components/Banner/BannerContainer";
import StatusModal from "module/main/App/components/StatusModal";

// pages
import FilePolling from "module/main/components/FilePolling/FilePolling";
import AppStatus from "./components/AppStatus";
import ServiceWorkersContainer from "./components/ServiceWorkers";
import useTelnyxSession from "module/common/components/ActiveCall/useTelnyxSession";
import useEnvBanner from "./useEnvBanner";
import LoginPage from "module/main/components/LoginPage";
import { ReactstrapSpinner } from "module/common/components/ReactstrapSpinner";

// store
import { selectHasAuthToken, selectUserData } from "module/main/store/Auth/selectors";
import {
  getCompanyData,
  getFeatureNotifications,
} from "module/main/store/Company/selectors";
import { getAudioRef } from "module/main/store/Calls/selectors";
import { getModals } from "module/main/store/Global/Modals/selectors";
import { IModal } from "module/main/store/Global/Modals/interfaces";

// utils
import "assets/fontAwesome/index";
import "./App.css";
import { deviceClass } from "module/common/helpers/utils";
import { AppEnv } from "module/common/helpers/variables";
import { addAllProdPixelScripts } from "./utils";
import { useInitialDataLoad } from "./useInitialDataLoad";

import runTasks from "module/common/helpers/tasks/TaskRunner";
import FeatureNotificationModal from "./components/FeatureNotificationModal";

import { sharedRoutes, mobileRoutes, desktopRoutes } from "../routes";
import ToastContainer from "module/main/App/components/Toasts/ToastContainer";
import { changeDocumentTitle } from "../routes/utils";
import { globalDynamicConfig } from "dynamicConfig";

function App() {
  const isProduction =
    globalDynamicConfig.config.REACT_APP_DYNAMIC_ENV === AppEnv.Production;
  // selectors
  const hasAuthToken = useSelector(selectHasAuthToken);
  const userData = useSelector(selectUserData);
  const company = useSelector(getCompanyData);
  const statusModals = useSelector(getModals);
  const c2cAudioRef = useSelector(getAudioRef);
  useEnvBanner();
  // special hooks
  const status = useInitialDataLoad({ hasAuthToken, userData, company });
  useTelnyxSession({ hasAuthToken });

  const ActiveNotifications = Object.values(useSelector(getFeatureNotifications))
    .filter((notification) => notification.displayFeature)
    .map((notification) => (
      <FeatureNotificationModal
        key={notification.id}
        notification={notification}
      ></FeatureNotificationModal>
    ));

  // change global timezone when it changes
  useEffect(() => {
    moment.tz.setDefault(company.timezone);
  }, [company.timezone]);

  useEffect(() => {
    addAllProdPixelScripts();
    runTasks();
  }, []);

  useEffect(() => {
    changeDocumentTitle(history.location);
    history.listen((location) => {
      changeDocumentTitle(location);
    });
  }, [history.location]);

  const getNavBar = () => {
    if (window.location.pathname.includes("/public")) return null;
    const desktopNav = <NavbarDesktop />;
    const mobileNav = <NavbarMobile page={history} />;
    return isMobile ? mobileNav : desktopNav;
  };

  const renderRoutes = (routes: any) => {
    return routes.map(
      (
        { path, component, exact, protectedRoute, protectedFeature }: any,
        key: number
      ) => {
        if (protectedRoute) {
          return (
            <ProtectedRoute
              exact={!!exact}
              path={path}
              component={component}
              key={key}
              protectedFeature={protectedFeature}
            />
          );
        }
        return <Route exact={exact} path={path} component={component} key={key} />;
      }
    );
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      <AppStatus />
      <ServiceWorkersContainer />
      <ToastContainer />
      <Suspense fallback={<ReactstrapSpinner size="lg" fullSize />}>
        <DataLoader
          emptyResultsMessage="User could not be authorized"
          status={status}
          appWrapper
          className={deviceClass}
          data={[userData]}
          dataTest={isMobile ? "mobile-view" : "desktop-view"}
          renderData={() => (
            <Router history={history}>
              <BannerContainer />
              {hasAuthToken ? getNavBar() : null}
              {hasAuthToken && !isMobile && ActiveNotifications}
              <Switch>
                <Route exact path="/login" component={LoginPage} />
                {isMobile ? (
                  <Redirect from="/campaigns" to="/" />
                ) : (
                  <Redirect exact from="/" to="/campaigns" />
                )}
                {renderRoutes(sharedRoutes)}
                {isMobile ? renderRoutes(mobileRoutes) : renderRoutes(desktopRoutes)}
                <Route>
                  <p className="text-center w-100 textXL">No Page Found</p>
                </Route>
              </Switch>

              {hasAuthToken && userData && isProduction && (
                <>
                  <NateroInitScript userData={userData} />
                </>
              )}
              {hasAuthToken && <>{!isMobile && <WidgetContainer />}</>}
              {hasAuthToken && userData && (
                <>
                  <FilePolling />
                  <audio autoPlay ref={c2cAudioRef} />
                </>
              )}
              {statusModals.length
                ? statusModals.map((e: IModal) => <StatusModal {...e} key={e.id} />)
                : null}
            </Router>
          )}
        />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
