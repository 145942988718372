import { companySetFeatures, companySetFeatureStatus } from "./actions";
import { getCompanyFeatures } from "./api";
import { addNewToast } from "../../Global/Toasts/actions";
import { FetchError, Fetching } from "module/common/helpers/variables";

export const fetchCompanyFeatures = (id: any) => async (dispatch: any) => {
  dispatch(companySetFeatureStatus(Fetching));
  return getCompanyFeatures(id)
    .then(({ data }) => {
      dispatch(companySetFeatures(data));
    })
    .catch(() => {
      dispatch(companySetFeatureStatus(FetchError));
      addNewToast({
        message: "Could not retrieve Company features",
        color: "danger",
      });
    });
};
