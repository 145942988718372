import React, { useEffect, useState, useContext } from "react";
import { Label, Button, FormGroup, CustomInput } from "reactstrap";
import IconBg from "module/common/components/IconBg";
import styled from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";
import InputSelect from "../../../../common/components/InputSelect";
import LeadStagesSelector from "module/common/components/LeadStagesSelector";
import ProspectRelaySelector from "./components/ProspectRelaySelector/ProspectRelaySelector";
import Modal from "../../../../common/components/Modal";
import {
  agentSelector,
  activeCampaignSelector,
} from "../../../../main/store/uiStore/prospectDetailsView/selectors";
import {
  prospectUpdateOptimistically,
  prospectEmailToCrmAction,
  prospectPushToZapierAction,
} from "../../../../main/store/prospectStore/thunks";
import {
  selectCompanyId,
  selectCompanyPodioStatus,
} from "../../../../main/store/Company/selectors";
import { setActiveCampaign } from "../../../../main/store/uiStore/prospectDetailsView/actions";
import { LoadingSpinner } from "../../../../common/components/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagsSelector from "../../../../common/components/TagsSelector/TagsSelector";
import ReminderSelector from "./components/ReminderSelector";
import { ProspectCampaignsContext } from "../../../../common/helpers/context";
import { LoadingButton } from "../../../../common/components/Buttons";

import { hasCampaignsCheck } from "../utils";
import { globalDynamicConfig } from "dynamicConfig";
import { AppEnv } from "module/common/helpers/variables";
import UncontrolledToolTip from "../../../../common/components/UncontrolledToolTip";
import { postSyncToPodio, getCrmStatus } from "module/main/services/crmIntegrations";
import { updateProspectSuccess } from "../../../../main/store/prospectStore/actions";
import { setIntegrationTime } from "../../../../main/store/Global/prospects/actions";
import { getIntegrationTime } from "../../../../main/store/Global/prospects/selectors";
import { getCompanyIntegrationStatus } from "module/main/store/Company/thunks";

const BtnHolster = styled.div`
  display: block;

  button {
    margin-right: var(--pad1);
    margin-top: var(--pad1);
    display: inline-block;
    &.activated {
      pointer-events: none;

      &:after {
        width: 3px;
        height: 3px;
        transition: all 0.2s ease-in-out;
        transform: translate(-50%, -50%) scale(200);
      }
    }
    &.disabled {
      color: var(--gray);
      background-color: var(--lightGray);
      border-color: var(--lightGray);
    }
  }
`;

const Radio = styled(CustomInput)`
  line-height: 1.2;
  margin-bottom: 0.6em;
  font-weight: bold;

  &[disabled] {
    color: var(--mediumGray);
  }
`;

const FieldWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: var(--pad4);
  }
  .iconBg {
    color: var(--sherpaBlue);
  }
`;

const BtnSuccessWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    margin-left: 0.6em;
  }
`;

const StyledCrmLabel = styled(Label)`
  margin-top: var(--pad3);
`;

const RenderAgentOptions = (agents) => {
  return agents.map((agent) => ({ label: agent.fullName, value: agent.id }));
};

// TODO: Break all these fields into their own
// sub-component to avoid re-renders
const FieldsSection = ({ prospect }) => {
  const { loading, prospectCampaigns: campaigns } = useContext(ProspectCampaignsContext);
  const [activeAgent, setActiveAgent] = useState(null);
  const [modal, setModal] = useState(false);
  const [emailToCrm, setEmailtoCrm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [fieldBtnStatus, setFieldBtnStatus] = useState({
    isEmailingToCrm: false,
    isPushingToZapier: false,
    isPushingToCrm: false,
  });
  const [pushedToCrm, setPushedToCrm] = useState(false);
  const [prospectExist, setProspectExist] = useState(false);
  const [crmTimer, setCrmTimer] = useState(60);

  const [selectedCampaign, setSelectedCampaign] = useState({});

  // selectors
  const activeCampaign = useSelector(activeCampaignSelector);
  const agents = useSelector(agentSelector);
  const podioStatus = useSelector(selectCompanyPodioStatus);
  const companyId = useSelector(selectCompanyId);
  const integrationTime = useSelector(getIntegrationTime);
  const prospectIntegrationTime = integrationTime[prospect.id];
  const dispatch = useDispatch();
  const activatedCampaigns = campaigns.filter(
    (e) => e.zapierWebhook || e.podioPushEmailAddress
  );

  const { emailedToPodio, pushedToZapier } = prospect;

  // create agent options
  const agentOpts = RenderAgentOptions(agents);

  useEffect(() => {
    if (podioStatus !== null) return;
    dispatch(getCompanyIntegrationStatus(companyId));
  }, [companyId]);

  useEffect(() => {
    setActiveAgent(agentOpts.find((opt) => opt.value === prospect.agent) || null);
    setSelectedCampaign({}); // reset the selected campaign;
    // eslint-disable-next-line
  }, [prospect.id]);

  useEffect(() => {
    const asyncGetCrmStatus = async () => {
      if (globalDynamicConfig.config.REACT_APP_DYNAMIC_ENV !== AppEnv.Production) return;
      try {
        const result = await getCrmStatus(prospect.id);
        if (result.status === 201) {
          setProspectExist(true);
        }
      } catch (err) {
        setProspectExist(false);
      }
    };

    asyncGetCrmStatus();

    // eslint-disable-next-line
  }, [prospect.id]);

  useEffect(() => {
    const currTime = Math.round(Date.now() / 1000);
    if (prospectIntegrationTime - currTime > 0) {
      const currentCountdown = prospectIntegrationTime - currTime;
      setTimeout(() => {
        if (currentCountdown - 1 <= 0) {
          setProspectExist(true);
          setPushedToCrm(false);
        } else {
          setPushedToCrm(true);
          setCrmTimer(currentCountdown - 1);
        }
      }, 1000);
    } else if (!prospectIntegrationTime && !prospectExist) {
      setPushedToCrm(false);
      setCrmTimer(60);
    }
    // eslint-disable-next-line
  }, [pushedToCrm, prospectIntegrationTime, crmTimer, prospect.id]);

  const onAgentChange = (data) => {
    const payload = { agent: parseInt(data.value) };
    dispatch(prospectUpdateOptimistically(prospect.id, payload));
    setActiveAgent(data);
  };

  const onTagChange = (data) => {
    let payload = { tags: [] };
    if (data) {
      payload = { tags: data.map(({ value }) => value) };
    }
    dispatch(prospectUpdateOptimistically(prospect.id, payload));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (emailToCrm) {
      // dispatch that action
      dispatch(
        prospectEmailToCrmAction(prospect.id, { campaign: selectedCampaign.id })
      ).then(() => {
        setSubmitting(false);
        setModal(false);
      });
    } else {
      // dispatch other action
      dispatch(
        prospectPushToZapierAction(prospect.id, { campaign: selectedCampaign.id })
      ).then(() => {
        setSubmitting(false);
        setModal(false);
      });
    }
  };

  const campaignOnChange = (campaign) => () => {
    // Setting the selected campaign in local state
    setSelectedCampaign(campaigns.find((e) => e.id === campaign.id));

    dispatch(setActiveCampaign(campaign.id));
    if (!Object.keys(activeCampaign).length === 0) {
      setSelectedCampaign(activeCampaign);
    }
  };

  const handleFieldBtnClick = (action, field) => {
    if (selectedCampaign.id) {
      const args = [prospect.id, { campaign: selectedCampaign.id }];
      const newFieldBtnStatus = { ...fieldBtnStatus, [field]: false };

      setFieldBtnStatus({ ...fieldBtnStatus, [field]: true });
      dispatch(action(...args)).then(() => setFieldBtnStatus(newFieldBtnStatus));
    } else {
      // dispatch thunk to show campaigns
      setModal(true);
      setEmailtoCrm(field === "isEmailingToCrm");
    }
  };

  const handleSetIntegrationTime = () => {
    const currTime = Math.round(Date.now() / 1000);
    const countdownEnd = currTime + 60;
    dispatch(setIntegrationTime({ id: prospect.id, integrationTime: countdownEnd }));
  };

  const handlePushToCRM = async () => {
    try {
      setFieldBtnStatus({ ...fieldBtnStatus, isPushingToCrm: true });
      const result = await postSyncToPodio(prospect.id);
      setTimeout(() => {
        if (result.status === 201) {
          setFieldBtnStatus({ ...fieldBtnStatus, isPushingToCrm: false });
          setPushedToCrm(true);
          dispatch(updateProspectSuccess({ ...prospect, isQualifiedLead: true }));
          handleSetIntegrationTime();
        }
      }, 3000);
    } catch (_) {
      // TODO: add an error?
    }
  };

  // determine crm buttons state
  const crmButtonClasses = (completed, value, status) => {
    const styles = "fw-bold";
    let state = "";

    if (!completed && (!selectedCampaign.id || value)) {
      state = "ready";
    } else if (status || completed) {
      state = "activated";
    } else if (!value) {
      state = "disabled";
    }

    const classes = `${styles} ${state}`;

    return classes;
  };

  return (
    <>
      <FieldWrapper data-test="crm-wrapper">
        <StyledCrmLabel>CRM Options</StyledCrmLabel>
        <BtnHolster data-test="button-holster">
          <Button
            id="crm"
            data-test="email-to-crm-btn"
            color="primary"
            className={crmButtonClasses(
              emailedToPodio,
              selectedCampaign.podioPushEmailAddress,
              fieldBtnStatus.isEmailingToCrm
            )}
            onClick={() =>
              handleFieldBtnClick(prospectEmailToCrmAction, "isEmailingToCrm")
            }
            disabled={!hasCampaignsCheck(prospect)}
          >
            {fieldBtnStatus.isEmailingToCrm || emailedToPodio ? (
              <LoadingSpinner
                isLoading={fieldBtnStatus.isEmailingToCrm}
                color="white"
                size="1em"
                renderContent={() => (
                  <BtnSuccessWrapper>
                    Emailed to CRM <FontAwesomeIcon size="sm" icon="check" />
                  </BtnSuccessWrapper>
                )}
              />
            ) : (
              "Email to CRM"
            )}
          </Button>
          <Button
            id="zapier"
            data-test="push-to-zapier-btn"
            color="primary"
            className={crmButtonClasses(
              pushedToZapier,
              selectedCampaign.zapierWebhook,
              fieldBtnStatus.isPushingToZapier
            )}
            onClick={() =>
              handleFieldBtnClick(prospectPushToZapierAction, "isPushingToZapier")
            }
            disabled={!hasCampaignsCheck(prospect)}
          >
            {fieldBtnStatus.isPushingToZapier || pushedToZapier ? (
              <LoadingSpinner
                isLoading={fieldBtnStatus.isPushingToZapier}
                color="white"
                size="1em"
                border="3px"
                renderContent={() => (
                  <BtnSuccessWrapper>
                    Pushed to Zapier <FontAwesomeIcon icon="check" />
                  </BtnSuccessWrapper>
                )}
              />
            ) : (
              "Push to Zapier"
            )}
          </Button>
          {prospectExist ? (
            <LoadingButton
              size="md"
              color="primary"
              onClick={handlePushToCRM}
              loading={fieldBtnStatus.isPushingToCrm}
              data-test="update-crm-btn"
              disabled={!podioStatus}
            >
              Push Updates To CRM
            </LoadingButton>
          ) : (
            <LoadingButton
              size="md"
              color="primary"
              id="crm"
              onClick={handlePushToCRM}
              loading={fieldBtnStatus.isPushingToCrm}
              data-test="update-crm-btn"
              className={crmButtonClasses(
                pushedToCrm,
                undefined,
                fieldBtnStatus.isPushingToCrm
              )}
              disabled={!podioStatus}
            >
              {pushedToCrm
                ? `Successfully pushed, syncing in ${crmTimer}`
                : "Push to CRM with Integration"}
            </LoadingButton>
          )}
          <Modal
            isOpen={modal}
            toggle={() => setModal(false)}
            title="Campaigns"
            size="md"
          >
            <form onSubmit={onSubmit}>
              <Label className="fw-black textL mb-2">
                Complete your action using the following campaign:
              </Label>
              <FormGroup className="mt-1 mb-3" htmlFor="campaigns">
                <LoadingSpinner
                  isLoading={loading}
                  color="blue"
                  size="1em"
                  border="3px"
                  renderContent={() => (
                    <>
                      {activatedCampaigns.length === 0 && (
                        <p className="text-center font-weight-bold">
                          {" "}
                          Please complete Zapier configuration OR Podio configuration in
                          Campaign Settings.
                        </p>
                      )}
                      {campaigns.length &&
                        campaigns.map((campaign, idx) => {
                          return (
                            <Radio
                              key={idx}
                              type="radio"
                              name="campaigns"
                              data-test={`${idx}-radio-selection`}
                              className="campaign-radio"
                              label={campaign.name}
                              defaultChecked={selectedCampaign.id === campaign.id}
                              disabled={
                                !campaign.zapierWebhook && !campaign.podioPushEmailAddress
                              }
                              value={campaign.id}
                              onChange={campaignOnChange(campaign)}
                              id={campaign.name}
                            />
                          );
                        })}
                    </>
                  )}
                />
              </FormGroup>
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  color="primary"
                  size="lg"
                  data-test="crm-modal-submit"
                  disabled={!selectedCampaign.id || submitting}
                >
                  <LoadingSpinner
                    isLoading={submitting}
                    color="light"
                    renderContent={() => <>Submit</>}
                  />
                </Button>
              </div>
            </form>
          </Modal>
        </BtnHolster>
      </FieldWrapper>
      <FieldWrapper data-test="tag-drop-down">
        <TagsSelector prospect={prospect} onChange={onTagChange} />
      </FieldWrapper>
      <FieldWrapper data-test="prospect-lead-stages-drop-down">
        <Label>
          Lead Stage
          <UncontrolledToolTip
            text="Create custom lead stages in Account Settings"
            id="tooltip-lead-stages-field-section-create"
          />
        </Label>
        <LeadStagesSelector prospect={prospect} removeMenuPortalTarget />
      </FieldWrapper>
      <FieldWrapper data-test="agent-drop-down">
        <Label>Agent</Label>
        <InputSelect
          name="status"
          id="statusSelect"
          value={activeAgent}
          icon={<IconBg icon="headset" faSize="sm" />}
          options={agentOpts}
          placeholder="Select"
          onChange={onAgentChange}
          removeMenuPortalTarget
        />
      </FieldWrapper>
      <FieldWrapper data-test="prospect-relay-drop-down">
        <Label>Relay</Label>
        <ProspectRelaySelector prospect={prospect} removeMenuPortalTarget />
      </FieldWrapper>
      <FieldWrapper data-test="reminder-drop-down">
        <ReminderSelector prospect={prospect} />
      </FieldWrapper>
    </>
  );
};

export default FieldsSection;
