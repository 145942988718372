import { combineReducers } from "redux";
import sequenceDetails from "./sequenceDetails/reducer";
import reminders from "./reminders/reducer";
import {
  FETCH_SEQUENCES,
  FETCH_SEQUENCES_SUCCESS,
  FETCH_SEQUENCES_FAILURE,
  DELETE_SEQUENCE_SUCCESS,
  ADD_SEQUENCE_TO_LIST,
} from "./actions";
import { IState } from "./interfaces";
import { Fetching, Success, FetchError, Initial } from "module/common/helpers/variables";
import { produce } from "immer";
import { convertArrayToObject } from "module/common/utils/utils";

export const initialState: IState = {
  sequencesList: [],
  status: Initial,
  error: "",
};

export const path = ["sequences", "sequences"];

const reducer = produce((draft: IState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case FETCH_SEQUENCES: {
      draft.status = Fetching;
      break;
    }
    case FETCH_SEQUENCES_SUCCESS: {
      draft.status = Success;
      draft.sequencesList = convertArrayToObject(payload, "id");
      break;
    }
    case FETCH_SEQUENCES_FAILURE: {
      draft.status = FetchError;
      draft.error = payload;
      break;
    }
    case DELETE_SEQUENCE_SUCCESS: {
      delete draft.sequencesList[payload];
      break;
    }
    case ADD_SEQUENCE_TO_LIST: {
      draft.sequencesList.push(payload);
      break;
    }
  }
}, initialState);

const root = combineReducers({
  sequences: reducer,
  sequenceDetails,
  reminders,
});

export default root;
