import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { Button, Row } from "reactstrap";
import styled, { css } from "styled-components";
// Components
import Header from "../../../common/components/Header";
import WithPermissions from "../../../common/components/WithPermissions";
import { unreadTabUpdateSelectedItem } from "../../../main/store/uiStore/allUnreadPageView/actions";
import { getSelectedItem } from "../../../main/store/uiStore/allUnreadPageView/selectors";
import UnreadMessages from "./UnreadMessages";
import ProspectHeader from "../ProspectLists/ProspectHeader";
// Redux
import { setActiveCampaignProspect } from "../../../main/store/uiStore/campaignsPageDesktopView/campaignsList/filterData/actions";
import { setProspectCyclePath } from "../../../main/store/uiStore/prospectDetailsView/actions";

// ugly dirty logic used to see if a modal-dialog is open
// this prevents the prospect-popup from closing when it detects a click outside
export const extraLogic = () => {
  return document.getElementsByClassName("modal-dialog").length < 1;
};

function UnreadMessagePage() {
  const dispatch = useDispatch();
  const selectedItem = useSelector(getSelectedItem);

  // set the prospect path to cycle source
  useEffect(() => {
    dispatch(setProspectCyclePath(["campaignProspectStore", "campaignProspectsUnread"]));
  }, [dispatch]);

  const clearSelectedProspect = (prospectId) => {
    dispatch(unreadTabUpdateSelectedItem(`${prospectId}0`));
    dispatch(setActiveCampaignProspect(prospectId));
  };

  return (
    <WithPermissions redirect showBlockedMessage checkSubscription pageName="all-unread">
      <Wrapper
        data-test="unread-messages-tab"
        className="pageContent d-flex flex-column"
        selected={selectedItem}
      >
        <div className="pageWrapper">
          <div>
            <Header className="unread-header">New Messages</Header>
            <Header className="message-header">
              <Row>
                <BackButton
                  data-test="prospect-details-back-button"
                  className="text-dark textM d-flex pl-0"
                  color="link"
                  onClick={() => clearSelectedProspect(0)}
                >
                  <FontAwesomeIcon
                    icon="arrow-alt-circle-left"
                    size="2x"
                    color="rgba(255,255,255, .75)"
                    className="mr-2"
                  />
                </BackButton>
                All Unread
              </Row>
            </Header>
          </div>

          <UnreadMessages>
            <ProspectHeader
              title="All Unread"
              tooltipText="Showing up to 100 of the most recent unread messages"
            />
          </UnreadMessages>
        </div>
      </Wrapper>
    </WithPermissions>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  .unread-list {
    padding: var(--ypad) var(--xpad);
  }

  .itemBody {
    overflow: hidden;
    max-width: 40vw;
  }

  .pageWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
  }
  .message-header {
    display: none;
  }
  .message-list {
    // The list overflows due to the virtualized list, this avoids the brother to overflow too
    max-height: 93.3vh;
  }

  @media (max-width: 767.98px) {
    ${(props) =>
      props.selected === "00"
        ? css`
            .message-list,
            .unread-header {
              display: block;
            }

            .message-details,
            .message-header {
              display: none;
            }
          `
        : css`
            .message-list,
            .unread-header {
              display: none;
            }

            .message-details,
            .message-header {
              display: block;
            }
          `}
  }
`;

export const ShadowWrapper = styled.div`
  box-shadow: 0 0 35px -10px #646464;
  z-index: 9;
  flex: 1;
  display: flex;

  // shenanigans to get the internal scrolling to work when in this popover mode
  .pageContent {
    display: flex;

    .displayedData {
      display: flex;
      flex: 1;

      & > div {
        flex: 1;

        .tabContent {
          overflow-y: auto;
        }
      }
    }
  }
`;

export const SlideIn = styled(CSSTransition)`
  position: absolute;
  top: 0;
  right: -100%;
  transition: right 0.3s ease-in-out;
  z-index: 999;
  width: 35vw;
  display: flex;
  height: 100%;

  &.slide {
    &-enter {
      right: -100%;
      &-done {
        right: 0;
      }
    }

    &-exit {
      &-active {
        right: -100%;
      }
    }
  }
`;

export const CloseButton = styled.button`
  border-radius: 2rem 0 0 2rem;
  background: var(--sherpaBlue);
  color: white;
  border: 0;
  height: 3rem;
  width: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 25px -5px #646464;

  svg {
    margin-left: 4px;
  }
`;

const BackButton = styled(Button)`
  align-items: center;
  padding-top: 0 !important;
  padding-bottom: 0.4em !important;
  padding: 0 !important;
  &:hover svg {
    color: white !important;
  }
`;

UnreadMessagePage.defaultProps = {
  className: "",
};

export default UnreadMessagePage;
