import { produce } from "immer";

import { SET_SMART_STACKER_LIST_RESULTS } from "../results/actions";

import {
  UPDATE_SMART_STACKER_QUERY,
  RESET_SMART_STACKER_QUERY,
  SMART_STACKER_UPDATE_FILTERS,
  SMART_STACKER_UPDATE_LIST_FILTERS,
  SMART_STACKER_RESET_FILTERS,
  SMART_STACKER_UPDATE_BOOL_FILTERS,
  SMART_STACKER_SET_FILTERS_MODAL_OPEN,
  SET_SMART_STACKER_PROSPECT_DATA_MODAL,
  SET_SMART_STACKER_SORT_ORDER,
  SET_SMART_STACKER_SEARCHED,
  SMART_STACKER_SET_ADVANCED_FILTERS_MODAL_OPEN,
  UPDATE_PROP_STACK_FILTER,
  FETCH_PROP_STACK_FILTERS,
  SET_ACTIVE_PROP_STACK_SAVED_FILTER,
  UPDATE_DATE_CRITERIA_FILTER,
} from "./actions";

export const searchFilter = {
  size: 100,
  query: {
    name: "",
    address: "",
    city: "",
    phone: "",
  },
  filters: {
    // date filters
    lastSoldDate: { type: "", dates: [] },
    inboundDate: { type: "", dates: [] },
    outboundDate: { type: "", dates: [] },
    skiptraceDate: { type: "", dates: [] },
    firstImportDate: { type: "", dates: [] },
    lastImportDate: { type: "", dates: [] },
    // offshore date filters for property tags
    criteria: "",
    dateFrom: "",
    dateTo: "",
    // end of offshore date filters for property tags
    prospectStatus: {
      include: [],
      exclude: [],
      criteria: "",
      dateFrom: "",
      dateTo: "",
    },
    propertyTags: {
      option: "any",
      include: [],
      exclude: [],
      criteria: "",
      dateFrom: "",
      dateTo: "",
    },
    distressIndicators: [0, 0], // [0, 0] should be an array of 2 numbers, min/max
    // [true, false] -> first element true means the checkbox is selected, second element false means "No" toggle is selected
    // [true, true] -> first element true means checkbox is selected, second element true means "Yes" toggle is selected
    // [] -> checkbox is not selected
    leadStageId: [],
    isBlocked: [],
    doNotCall: [],
    isPriority: [],
    isQualifiedLead: [],
    wrongNumber: [],
    optedOut: [],
    ownerVerifiedStatus: [], // open, verified_owner, non_owner are the options
    isArchived: false,
    isReminder: [],
    skipTraced: [],
    inCampaign: [],
    inDmCampaign: [],
    inSequence: [],
    recentlyVacant: [],
    zipCode: "",
  },
  // Sort can take 5 options right now ["tags", "campaigns", "last_contact", "first_import_date", "last_import_date"]
  sort: {
    field: "last_contact",
    order: "desc",
  },
};

const state = {
  // default applied filters to true because when we initialize PropStack,
  // we have the inital search w/o filters
  appliedFilters: true, // when the filters has been applied and search executed, appliedFilters is set to true
  searched: false,
  searchFilter,
  isFiltersModalOpen: false,
  isProspectDataModalOpen: false,
  isAdvancedFiltersModalOpen: false,
  savedFilters: {},
  savedFilterInUse: "",
  savedFilterEditing: false,
};

export const path = ["uiStore", "smartStackerListView", "filters"];

const reducer = produce((base: any, action: any) => {
  switch (action.type) {
    case SET_SMART_STACKER_LIST_RESULTS:
      base.appliedFilters = true;
      break;
    case SMART_STACKER_UPDATE_FILTERS: {
      Object.assign(base.searchFilter.filters, action.payload);
      base.appliedFilters = false; // when new filters are added to the search, appliedFilters should be set to false
      break;
    }
    case UPDATE_DATE_CRITERIA_FILTER: {
      Object.assign(base.searchFilter.filters[action.payload.key], action.payload.data);
      base.appliedFilters = false;
      break;
    }
    case SMART_STACKER_UPDATE_LIST_FILTERS: {
      const { list, value } = action.payload;
      // add or remove
      if (action.meta.remove) {
        base.searchFilter.filters[list] = base.searchFilter.filters[list].filter(
          (id: any) => id !== value
        );
      } else {
        base.searchFilter.filters[list].push(value);
      }

      base.appliedFilters = false;
      break;
    }
    case SMART_STACKER_UPDATE_BOOL_FILTERS: {
      const { id, value, type } = action.payload;
      const filters = base.searchFilter.filters;
      const includeArray = filters.prospectStatus.include;
      const excludeArray = filters.prospectStatus.exclude;
      const prospectStatuses = [
        "isBlocked",
        "doNotCall",
        "isQualifiedLead",
        "isPriority",
        "wrongNumber",
      ];

      const selectFilter = (include: boolean) => {
        prospectStatuses.forEach((e) => {
          if (
            (Array.isArray(value) && value.find((a: any) => a.value === e)) ||
            (filters[e][1] !== include && undefined)
          ) {
            filters[e] = [true, include];
          } else if (filters[e][1] === include) {
            filters[e] = [];
          }
        });
      };

      if (type === "checkbox") {
        // checkbox is deselected, and value can be reset
        if (!value) {
          filters[id] = [];
          filters.prospectStatus.include = includeArray.filter((e: any) => e !== id);
          filters.prospectStatus.exclude = excludeArray.filter((e: any) => e !== id);
        } else {
          filters[id] = [value, true]; // auto set the toggle value to true
          if (value) {
            filters.prospectStatus.include = [...includeArray, id];
          } else {
            filters.prospectStatus.include = includeArray.filter((e: any) => e !== id);
          }
        }
      } else if (type === "toggle") {
        const arrayKey = value ? "include" : "exclude";

        filters[id] = [true, value]; // if you are toggling, the checkbox should already be set to true
        filters.prospectStatus[arrayKey] = [...filters.prospectStatus[arrayKey], id];
        filters.prospectStatus[value ? "exclude" : "include"] = filters.prospectStatus[
          value ? "exclude" : "include"
        ].filter((e: any) => e !== id);
      } else if (type === "include" || type === "exclude") {
        const revType = type === "include" ? "exclude" : "include";
        selectFilter(type === "include");
        filters.prospectStatus[type] = value ? value.map((e: any) => e.value) : [];
        filters.prospectStatus[revType] = filters.prospectStatus[revType].filter(
          (e: any) => filters.prospectStatus[type].indexOf(e) < 0
        );
      }
      base.appliedFilters = false;
      break;
    }
    case SMART_STACKER_RESET_FILTERS: {
      base.searchFilter.filters = searchFilter.filters;
      base.appliedFilters = false;
      base.savedFilterInUse = null;
      break;
    }
    case SMART_STACKER_SET_FILTERS_MODAL_OPEN: {
      base.isFiltersModalOpen = action.payload;
      break;
    }
    case SMART_STACKER_SET_ADVANCED_FILTERS_MODAL_OPEN: {
      base.isAdvancedFiltersModalOpen = action.payload;
      break;
    }
    case SET_SMART_STACKER_PROSPECT_DATA_MODAL: {
      base.isProspectDataModalOpen = action.payload;
      break;
    }
    case UPDATE_SMART_STACKER_QUERY: {
      base.searchFilter.query[action.payload.key] = action.payload.term;
      break;
    }
    case RESET_SMART_STACKER_QUERY: {
      base.searchFilter.query = searchFilter.query;
      break;
    }
    case SET_SMART_STACKER_SORT_ORDER: {
      base.searchFilter.sort = action.payload;
      break;
    }
    case SET_SMART_STACKER_SEARCHED: {
      base.searched = action.payload;
      break;
    }
    case FETCH_PROP_STACK_FILTERS: {
      base.savedFilters = action.payload;
      break;
    }
    case UPDATE_PROP_STACK_FILTER: {
      const { payload } = action;

      base.savedFilters[payload.id] = payload;
      break;
    }
    case SET_ACTIVE_PROP_STACK_SAVED_FILTER: {
      const BEFilters = base.savedFilters[action.payload.id].filterJson;

      // skips updating the filters if they are just editing a filter.
      if (!action.payload.isEditing) {
        base.searchFilter.filters = {
          ...searchFilter.filters,
          ...BEFilters,
          ...(Array.isArray(BEFilters.lastSoldDate) && {
            lastSoldDate: { type: "", dates: [] },
          }),
          ...(Array.isArray(BEFilters.inboundDate) && {
            inboundDate: { type: "", dates: [] },
          }),
          ...(Array.isArray(BEFilters.outboundDate) && {
            outboundDate: { type: "", dates: [] },
          }),
          ...(Array.isArray(BEFilters.skipTraceDate) && {
            skipTraceDate: { type: "", dates: [] },
          }),
        };
        base.appliedFilters = true;
      }
      base.savedFilterEditing = action.payload.isEditing;
      base.savedFilterInUse = action.payload.id;
    }
  }
}, state);

export default reducer;
