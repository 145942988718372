import { createGlobalStyle, css } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
      ${(props) =>
        props.theme.isDesktop
          ? css`
              --xpad: var(--pad5);
              --ypad: var(--pad4);
              --xypad: var(--ypad) var(--xpad);
            `
          : css`
              --xpad: var(--pad3);
              --ypad: var(--pad3);
              --xypad: var(--ypad) var(--xpad);
            `}
  }

  #root {
      ${(props) =>
        props.theme.isDesktop
          ? css`
              display: flex;

              .pageContent {
                min-height: 100vh;
                margin-left: calc(4rem + 35px);
                flex-grow: 1;

                .pageContent {
                  margin-left: 0;
                  min-height: unset;
                }
              }
            `
          : null}
  }


  body, html {
    font-size: 12px;
    ${(props) =>
      props.theme.isDesktop
        ? css`
            background: var(--ghostBlue) !important;
          `
        : css``}
  }

  .form-control,
  .react-select__value-container {
      ${(props) =>
        props.theme.isDesktop
          ? css`
              height: calc(1em + 1rem) !important;
              &:not(textarea) {
                padding: 0 !important;
              }
              &.react-select__value-container--is-multi {
                height: auto !important;
                min-height: calc(1em + 1rem) !important;
              }
            `
          : css`
              height: calc(1em + 1.5rem) !important;
            `}
  }

  .small-input .react-select__value-container {
      ${(props) =>
        props.theme.isDesktop
          ? css`
              height: calc(1em + 1rem) !important;
              font-size: 1rem !important;
            `
          : null}
  }

  .large-input {
      ${(props) =>
        props.theme.isDesktop
          ? css`
              height: calc(1.6em + 1.6rem) !important;
              font-size: 1.5rem !important;
            `
          : null}
  }

  .rdtPicker {
      ${(props) =>
        props.theme.isDesktop
          ? css`
              max-width: 600px;
              font-size: 1.6rem;

              td,
              th {
                height: 4.4rem;
              }

              th.rdtNext,
              th.rdtPrev {
                font-size: 2.5rem;
              }
            `
          : null}
  }
  iframe#launcher-frame {
    width: calc(4rem + 35px);
  }
  .white-space-normal {
    white-space: normal;
  }
`;

export default GlobalStyle;
