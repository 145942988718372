import { createAction } from "../redux-helpers";

export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const SET_SCRIBE_SHERPA_CATEGORIES = "SET_SCRIBE_SHERPA_CATEGORIES";
export const FETCH_CATEGORIES_ERROR = "FETCH_CATEGORIES_ERROR";
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
export const UPDATE_CATEGORY_SORT_ORDER = "UPDATE_CATEGORY_SORT_ORDER";
export const REMOVE_CATEGORY = "REMOVE_CATEGORY";
export const REMOVE_TEMPLATE = "REMOVE_TEMPLATE";

export const fetchCategories = createAction(FETCH_CATEGORIES);
export const fetchCategoriesSuccess = createAction(FETCH_CATEGORIES_SUCCESS);
export const setScribeSherpaCategories = createAction(SET_SCRIBE_SHERPA_CATEGORIES);
export const fetchCategoriesError = createAction(FETCH_CATEGORIES_ERROR);
export const updateCategories = createAction(UPDATE_CATEGORIES);
export const removeCategory = createAction(REMOVE_CATEGORY);
export const removeTemplate = createAction(REMOVE_TEMPLATE);
