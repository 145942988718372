import { IState } from "./interfaces";
import {
  FETCH_LEADSTAGES,
  FETCH_LEADSTAGES_FAILURE,
  FETCH_LEADSTAGES_SUCCESS,
  ADD_LEADSTAGE,
  ADD_LEADSTAGE_SUCCESS,
  UPDATE_LEADSTAGE,
  UPDATE_LEADSTAGE_SUCCESS,
  DELETE_LEADSTAGE,
  DELETE_LEADSTAGE_SUCCESS,
  UPDATE_LEADSTAGE_SORT_ORDER,
} from "./actions";

export const initialState: IState = {
  isLoading: false,
  isAdding: false,
  isUpdating: false,
  isDeleting: false,
  error: false,
  leadStageList: {},
  leadStageSortOrder: [],
};

export default function reducer(state: IState = initialState, action: any) {
  switch (action.type) {
    case FETCH_LEADSTAGES:
      return { ...state, isLoading: action.payload };
    case FETCH_LEADSTAGES_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case FETCH_LEADSTAGES_SUCCESS:
      const newLS = { ...state.leadStageList, ...action.payload };
      return {
        ...state,
        isLoading: false,
        leadStageList: newLS,
        leadStageSortOrder: Object.values(newLS)
          .sort((x: any, y: any) => x.sortOrder - y.sortOrder)
          .map((ls: any) => ls.id),
      };
    case ADD_LEADSTAGE:
      return {
        ...state,
        isAdding: action.payload,
      };
    case ADD_LEADSTAGE_SUCCESS:
      return {
        ...state,
        isLoading: true,
        isAdding: false,
        leadStageList: {
          ...state.leadStageList,
          [action.payload.data.id]: action.payload.data,
        },
        leadStageSortOrder: [...state.leadStageSortOrder, action.payload.data.id],
      };
    case UPDATE_LEADSTAGE:
      return {
        ...state,
        isUpdating: action.payload,
      };
    case UPDATE_LEADSTAGE_SUCCESS:
      return {
        ...state,
        isLoading: true,
        isUpdating: false,
        leadStageList: {
          ...state.leadStageList,
          [action.payload.data.id]: action.payload.data,
        },
        leadStageSortOrder: action.payload.updateSort
          ? [...state.leadStageSortOrder, action.payload.data.id]
          : state.leadStageSortOrder,
      };
    case DELETE_LEADSTAGE:
      return {
        ...state,
        isDeleting: action.payload,
      };
    case DELETE_LEADSTAGE_SUCCESS:
      const newLeadStageList = { ...state.leadStageList };
      delete newLeadStageList[action.payload];

      return {
        ...state,
        isLoading: true,
        isDeleting: false,
        leadStageList: newLeadStageList,
        leadStageSortOrder: state.leadStageSortOrder.filter(
          (sortOrder: number) => sortOrder !== action.payload
        ),
      };
    case UPDATE_LEADSTAGE_SORT_ORDER: {
      const sortOrder = [...state.leadStageSortOrder].filter(
        (id) => id !== action.payload.id
      );

      sortOrder.splice(action.payload.index, 0, action.payload.id);
      return {
        ...state,
        leadStageSortOrder: sortOrder,
      };
    }
    default:
      return state;
  }
}
