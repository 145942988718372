import React from "react";
import Modal from "module/common/components/Modal";

// comopnents
import QuickRepliesForm from "module/common/components/SmsManager/forms/QuickRepliesForm";

// redux related
import {
  createQuickReply,
  editQuickReply,
  getProspectQR,
} from "module/main/store/QuickRepliesStore/thunk";
import { useDispatch } from "react-redux";
import { addNewToast } from "module/main/store/Global/Toasts/actions";

const handleEditOrCreate = (dispatch, payload, initialValue) => {
  if (initialValue.id) return dispatch(editQuickReply(initialValue.id, payload, true));
  return dispatch(createQuickReply(payload));
};

const MessageQuickRepliesEditOrCreate = ({
  showEditForm,
  setShowEditForm,
  quickReplyToEdit,
  setQuickReplyToEdit,
  prospectId,
  setQuickRepliesModalVisibility,
}) => {
  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={showEditForm}
      title={quickReplyToEdit.id ? "Edit Quick Reply" : "Create Quick Reply"}
      toggle={() => {
        setQuickReplyToEdit({});
        setShowEditForm((state) => !state);
      }}
      size="lg"
      autoFocus={false}
    >
      <QuickRepliesForm
        closeModal={() => {
          //clear the initial values and close modal
          setQuickReplyToEdit({});
          setShowEditForm(false);
        }}
        initialValues={quickReplyToEdit}
        submitHandler={(payload, originalValue) =>
          handleEditOrCreate(dispatch, payload, originalValue).then(() =>
            dispatch(getProspectQR(prospectId))
              .then((response) => {
                if (originalValue.id) setQuickRepliesModalVisibility(true);
                return response;
              })
              .catch(() =>
                dispatch(
                  addNewToast({
                    message: "Something went wrong while fetching quick replies",
                    color: "danger",
                  })
                )
              )
          )
        }
      />
    </Modal>
  );
};

export default MessageQuickRepliesEditOrCreate;
