import { createAction } from "../redux-helpers";

export const FETCH_LEADSTAGES = "FETCH_LEADSTAGES";
export const FETCH_LEADSTAGES_SUCCESS = "FETCH_LEADSTAGES_SUCCESS";
export const FETCH_LEADSTAGES_FAILURE = "FETCH_LEADSTAGES_FAILURE";
export const ADD_LEADSTAGE = "ADD_LEADSTAGE";
export const ADD_LEADSTAGE_SUCCESS = "ADD_LEADSTAGE_SUCCESS";
export const UPDATE_LEADSTAGE = "UPDATE_LEADSTAGE";
export const UPDATE_LEADSTAGE_SUCCESS = "UPDATE_LEADSTAGE_SUCCESS";
export const DELETE_LEADSTAGE = "DELETE_LEADSTAGE";
export const DELETE_LEADSTAGE_SUCCESS = "DELETE_LEADSTAGE_SUCCESS";
export const UPDATE_LEADSTAGE_SORT_ORDER = "UPDATE_LEADSTAGE_SORT_ORDER";

export const fetchLeadStages = createAction(FETCH_LEADSTAGES);
export const fetchLeadStagesSuccess = createAction(FETCH_LEADSTAGES_SUCCESS);
export const fetchLeadStagesFailure = createAction(FETCH_LEADSTAGES_FAILURE);
export const postLeadStage = createAction(ADD_LEADSTAGE);
export const postLeadStageSuccess = createAction(ADD_LEADSTAGE_SUCCESS);
export const putLeadStage = createAction(UPDATE_LEADSTAGE);
export const putLeadStageSuccess = createAction(UPDATE_LEADSTAGE_SUCCESS);
export const deleteLeadStage = createAction(DELETE_LEADSTAGE);
export const deleteLeadStageSuccess = createAction(DELETE_LEADSTAGE_SUCCESS);
export const updateLeadStageSortOrder = createAction(UPDATE_LEADSTAGE_SORT_ORDER);
