import { useEffect } from "react";
import { KeyNames } from "module/common/helpers/variables";

/** Detects key presses and a key with a special key combination
 *
 * When the user presses the key combination and lifts the "key", the "keyUpHandler" is called
 * and that's how it validates that it was pressed and if it has a special key its also pressed
 */
const useKeyUpPress = (
  keys: KeyNames[],
  callback: (pressedKey: KeyNames) => void,
  specialKey?: "altKey" | "ctrlKey" | "metaKey" | "shiftKey"
) => {
  const keyUpHandler = (e: KeyboardEvent) => {
    const pressedKey = e.key as KeyNames;
    if (keys.includes(pressedKey) && (!specialKey || e[specialKey])) {
      callback(pressedKey);
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", keyUpHandler);
    return () => {
      window.removeEventListener("keyup", keyUpHandler);
    };
  }, []);
};

export default useKeyUpPress;
