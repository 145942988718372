import AxiosConfig from "../../../common/utils/axiosConfig";

export const fetchProspectQuickReplies = (id: number) => {
  // dispatch(setSmsTemplatesStatus(Fetching));
  return AxiosConfig.get(`/prospects/${id}/quick_replies/`);
};

export const fetchQuickRepliesList = () => {
  return AxiosConfig.get(`/quick-replies/`);
};

export const postQuickReply = (payload: any) => {
  return AxiosConfig.post("/quick-replies/", payload);
};

export interface PutQuickReplyPayload
  extends Partial<{
    message: string;
    question: string;
    sortOrder: number;
  }> {}

export const putQuickReply = (id: number, payload: PutQuickReplyPayload) => {
  return AxiosConfig.put(`/quick-replies/${id}/`, payload);
};

export const destroyQuickReply = (id: number) => {
  return AxiosConfig.delete(`/quick-replies/${id}/`);
};
