import { produce } from "immer";

// actions
import { ADD_BANNER, REMOVE_BANNER } from "./actions";

// interfaces
import { IState, IBanner } from "./interfaces";

const initialState: IState = {
  list: [],
};

const reducer = produce((draft: IState, action: any) => {
  switch (action.type) {
    case ADD_BANNER: {
      const banner = action.payload as IBanner;
      const bannerList = draft.list;
      const newBanner = { ...banner, id: banner.id || new Date().toISOString() };
      bannerList.push(newBanner);
      draft.list = bannerList;

      break;
    }
    case REMOVE_BANNER: {
      const bannerId = action.payload as IBanner;
      const bannerList = draft.list.filter((t) => t.id !== bannerId);
      draft.list = bannerList;
      break;
    }
  }
}, initialState);

export default reducer;
