import {
  FETCH_CAMPAIGNS,
  SET_FETCH_CAMPAIGNS,
  SET_FETCH_CAMPAIGNS_ERROR,
  RESET_CAMPAIGNS_DATA,
  ARCHIVE_CAMPAIGN,
  UPDATE_SMS_TEMPLATE,
  SET_FETCH_CAMPAIGNS_NEXT_PAGE,
  SET_FETCH_CAMPAIGNS_NEXT_PAGE_SUCCESS,
  PAGINATE_CAMPAIGN_LIST,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGNS_LIST,
} from "./actions";
import { ApiFetchStatus } from "module/common/helpers/variables";
import { FETCH_CAMPAIGN_NEXT_PAGE } from "../campaignProspectStore/actionTypes";
import { IState } from "./interfaces";

// campaigns reducer
export const initialState: IState = {
  campaigns: {},
  status: ApiFetchStatus.Initial,
  isLoadingMore: false,
};
export const path = ["campaigns"];

export default function reducer(state: IState = initialState, action: any) {
  const { payload } = action;

  switch (action.type) {
    case FETCH_CAMPAIGNS:
      return {
        ...state,
        status: ApiFetchStatus.Fetching,
      };
    case FETCH_CAMPAIGN_NEXT_PAGE:
      return {
        ...state,
        isLoadingMore: action.payload,
      };
    case SET_FETCH_CAMPAIGNS:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          ...payload.campaigns,
        },
        status: ApiFetchStatus.Success,
      };
    case PAGINATE_CAMPAIGN_LIST:
      return {
        ...state,
        campaigns: { ...state.campaigns, ...action.payload.campaigns },
        status: ApiFetchStatus.Success,
        isLoadingMore: false,
      };
    case SET_FETCH_CAMPAIGNS_ERROR:
      return {
        ...state,
        error: action.error,
        status: ApiFetchStatus.Error,
      };
    case ARCHIVE_CAMPAIGN:
      const campaign = state.campaigns[payload.id];

      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          [payload.id]: {
            ...payload,
            market: campaign.market,
            createdBy: campaign.createdBy,
          },
        },
        status: ApiFetchStatus.Success,
      };
    case UPDATE_SMS_TEMPLATE:
      const campaignsToUpdate = { ...state.campaigns, [payload.id]: payload };

      return {
        ...state,
        campaigns: campaignsToUpdate,
        status: ApiFetchStatus.Success,
      };
    case RESET_CAMPAIGNS_DATA:
      return initialState;
    case SET_FETCH_CAMPAIGNS_NEXT_PAGE:
      return {
        ...state,
        isLoadingMore: action.payload,
      };
    case SET_FETCH_CAMPAIGNS_NEXT_PAGE_SUCCESS: {
      const newState = {
        ...state,
        campaigns: { ...state.campaigns, ...payload.campaigns },
        isLoadingMore: false,
      };
      return newState;
    }
    case UPDATE_CAMPAIGN: {
      const oldCampaign = state.campaigns[action.payload.id] || {};

      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          [action.payload.id]: {
            ...oldCampaign,
            ...action.payload,
            market: oldCampaign.market
              ? { ...oldCampaign.market, ...action.payload.market }
              : action.payload.market,
          },
        },
      };
    }
    case UPDATE_CAMPAIGNS_LIST: {
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
}

// campaigns folder reducer
