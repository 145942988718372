import { PopoverBody } from "reactstrap";
import styled from "styled-components";

export const StyledPopoverBody = styled(PopoverBody)`
  padding: var(--pad1) var(--pad1) !important;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;

  button {
    color: var(--darkNavy);
  }
  a {
    color: var(--darkNavy);
    margin: 10px;
  }
`;
