import React, { useState } from "react";
import styled from "styled-components";
import logo from "assets/images/BlueBlackSherpaLogo.svg";
import mobileLogo from "assets/images/sherpaLogo.svg";
import InputGroupBorder from "module/common/components/InputGroupBorder";
import { Card, Input, Label, FormGroup, Button, Form } from "reactstrap";
import { LoadingSpinner } from "../../../common/components/LoadingSpinner";
import { Link } from "react-router-dom";
import { LogoHolster } from "module/common/styles";
import Error from "./Error";

export default function LoginForm({ submit, formError, isMobile }) {
  // state
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);

  // onchange handlers
  const changeUsername = (e) => setUsername(e.target.value);
  const changePWHandler = (e) => setPassword(e.target.value);
  const onSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    submit(username, password).catch(() => setSubmitting(false));
  };

  return (
    <>
      <LogoHolster>
        <img src={isMobile ? mobileLogo : logo} alt="Lead Sherpa Logo" />
      </LogoHolster>
      <StyledCard className="text-center mt-4" $isMobile={isMobile}>
        <CardHeader>Welcome back.</CardHeader>
        <div>
          <Form className="text-left" onSubmit={onSubmit} data-test="login-form">
            <FormGroup className="mb-4">
              <Label htmlFor="username">Email</Label>
              <InputGroupBorder>
                <Input
                  name="username"
                  type="email"
                  data-test="email-input"
                  value={username}
                  onChange={changeUsername}
                  placeholder="Enter email address"
                  className="large-input"
                  autoFocus
                  required
                />
              </InputGroupBorder>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">Password</Label>
              <InputGroupBorder>
                <Input
                  name="password"
                  type="password"
                  data-test="password-input"
                  value={password}
                  className="large-input"
                  onChange={changePWHandler}
                  placeholder="Enter Password"
                  required
                />
              </InputGroupBorder>
            </FormGroup>
            {formError ? (
              <Error data-test="login-form-error" message={formError} />
            ) : null}
            <Button
              className="mt-4 btn-xl d-inline-flex justify-content-center align-items-center"
              data-test="login-submit"
              block
              color="primary"
              type="submit"
              disabled={!username || !password}
            >
              <LoadingSpinner
                isLoading={submitting}
                color="light"
                renderContent={() => <>Log In</>}
              />
            </Button>
          </Form>
          <div className="mt-2 textXL text-center">
            New to Lead Sherpa?{" "}
            <Link to="/register" data-test="register-link">
              Sign up{" "}
            </Link>
          </div>
          <div className="mt-2 textXL text-center">
            <Link to="/forgot-pw" data-test="forgot-pw-link">
              Forgot password?
            </Link>{" "}
          </div>
        </div>
      </StyledCard>
    </>
  );
}

const StyledCard = styled(Card)`
  padding: var(--ypad) var(--xpad);
  border-radius: var(--pad2) !important;
  max-width: 600px;
  width: ${(props) => (props.$isMobile ? "100%" : "70%")};
`;

const CardHeader = styled.h1`
  margin-bottom: var(--pad5);
`;
