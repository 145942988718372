import { createSelector } from "reselect";
import { createSelectorContext } from "../../redux-helpers";
import { rootPath } from "../reducer";
import { getCompanyData } from "../selectors";
import { IProfile, ICompany } from "../interfaces";
import { arrayToMapIndex } from "../../utils";

const createSelector_ = createSelectorContext([...rootPath, "profiles"]);

export const selectCompanyProfiles = createSelector_("profiles");
export const getIndexedProfiles = createSelector(
  selectCompanyProfiles,
  (profiles: IProfile[]) => arrayToMapIndex("id", profiles)
);
export const getCompanyAndProfiles = createSelector(
  selectCompanyProfiles,
  getCompanyData,
  (profiles: IProfile[], company: ICompany) => ({ profiles, company })
);
export const getActiveCompanyProfiles = createSelector(
  selectCompanyProfiles,
  (profiles: any) => profiles.filter((profile: any) => profile.user.isActive)
);
