import { PhoneStatus } from "../NumberManagerStore/interfaces";
import { Record } from "../types";

export interface IDisplayMessage {
  fromProspect: number;
  message: string;
  dt: string;
}

export interface IRep {
  id: number | null;
}

export interface IRelay {
  id: number;
  agentProfile: {
    id: number;
    user: {
      id: number;
      firstName: string;
      lastName: string;
      email: string;
      isActive: boolean;
    };
    phone: string | null;
    role: string;
    company: number;
    prospectRelayCount: number;
    prospectRelayAvailable: number;
  };
  prospect: {
    addressDisplay: string;
    phoneDisplay: string;
    id: number;
    fullname: string;
    propertyAddress: string;
    propertyCity: string;
    propertyState: string;
    propertyZip: number;
  };
  created: string;
  lastActivity: string | null;
}

export interface ISmallCampaign {
  id: number;
  isArchived: boolean;
  name: string;
}

export interface IProspectSeq {
  id: number;
  sequenceId: string;
  name: string;
}

export interface IResourceStore {
  count: number;
  next: string | null;
  previous: string | null;
  isLoading: boolean;
  isLoadingMore: boolean;
  isFetchingMessages: boolean;
  error: boolean;
  errorMessage?: string;
  prospectDrafts: { [key: number]: string };
}

export interface IProspect {
  agent: number | null;
  campaigns?: Pick<ISmallCampaign, "name">[];
  campaign?: ISmallCampaign;
  doNotCall: boolean;
  optedOut: boolean;
  emailedToPodio: boolean;
  firstName: string;
  hasUnreadSms: boolean;
  id: number;
  isBlocked: boolean;
  isPriority: boolean;
  isQualifiedLead: boolean;
  lastName: string;
  displayMessage: IDisplayMessage | null;
  messages?: Array<any> | null;
  leadStage: number | null;
  name: string;
  ownerVerifiedStatus: string;
  phoneDisplay: string | null;
  phoneRaw: string | null;
  phoneType: string;
  propertyAddress: string | null;
  propertyCity: string | null;
  propertyState: string | null;
  propertyZip: string | null;
  propertyId: number | null;
  pushedToZapier: boolean;
  reminderDateLocal: string | null;
  reminderDateUtc: string | null;
  reminderEmailAddress: string | null;
  reminderAgent: string | null;
  activeProspectSequence: IProspectSeq | null;
  sherpaPhoneNumber: string | null;
  sherpaPhoneStatus: PhoneStatus | null;
  streetViewUrl: string | null;
  relay: IRelay | null;
  relayBlocked: boolean;
  tags: Array<any>;
  token: string;
  wrongNumber: boolean;
  zillowLink: string | null;
  messageDisableReason: string;
  addressDisplay: string;
  integrationTimer: number | null;
  totalCampaigns: number; // this is the number for both SMS and DM campaigns
  /** Indicates whether the data is partial data from only one data source (if the data is only coming from smartStacker) */
  partial: boolean;
}

const Prospect: IProspect = {
  agent: -1,
  doNotCall: false,
  optedOut: false,
  emailedToPodio: false,
  firstName: "",
  hasUnreadSms: false,
  id: 0,
  isBlocked: false,
  isPriority: false,
  isQualifiedLead: false,
  lastName: "",
  displayMessage: null,
  leadStage: null,
  name: "",
  ownerVerifiedStatus: "",
  phoneDisplay: "",
  phoneRaw: "",
  phoneType: "",
  propertyAddress: "",
  propertyCity: "",
  propertyState: "",
  propertyZip: "",
  propertyId: 0,
  pushedToZapier: false,
  reminderDateLocal: null,
  reminderDateUtc: null,
  reminderEmailAddress: null,
  reminderAgent: null,
  sherpaPhoneNumber: null,
  sherpaPhoneStatus: null,
  relay: null,
  relayBlocked: false,
  activeProspectSequence: null,
  token: "",
  tags: [],
  wrongNumber: false,
  campaigns: [],
  messages: null,
  zillowLink: "",
  messageDisableReason: "",
  addressDisplay: "",
  campaign: undefined,
  streetViewUrl: null,
  integrationTimer: null,
  totalCampaigns: 0,
  partial: false,
};

// does not include campaigns
export const PartialProspect: IProspect = { ...Prospect };
delete PartialProspect.campaigns;
delete PartialProspect.messages;

export interface IProspectStore extends IResourceStore {
  prospects: { [key: number]: IProspect };
  prospectDrafts: { [key: number]: string };
}

export const ProspectRecord = Record(Prospect);
export const PartialProspectRecord = Record(PartialProspect);
