import { createAction } from "../../redux-helpers";

export const ADD_TOAST = "ADD_TOAST";
export const addNewToast = createAction(ADD_TOAST);

export const addSuccessToast = (message: string) => {
  return addNewToast({ message, color: "success" });
};
export const addErrorToast = (message: string) => {
  return addNewToast({ message, color: "danger" });
};

export const REMOVE_TOAST = "REMOVE_TOAST";
export const removeToast = createAction(REMOVE_TOAST);

export const MODIFY_TOAST = "MODIFY_TOAST";
export const modifyToast = createAction(MODIFY_TOAST);

export const RESET_TOAST_ARRAY = "RESET_TOAST_ARRAY";
export const emptyToastArray = createAction(RESET_TOAST_ARRAY);
