import { combineReducers } from "redux";

// reducers
import PropertyReducer from "./property/reducers";

const reducers = combineReducers({
  property: PropertyReducer,
});

export default reducers;
