import {
  SET_FETCH_CAMPAIGNS_BATCH_PROSPECTS,
  SET_FETCH_CAMPAIGNS_BATCH_PROSPECTS_ERROR,
  CLEAR_FETCH_CAMPAIGNS_BATCH_PROSPECTS_ERROR,
  RESET_CAMPAIGNS_BATCH_PROSPECTS_DATA,
  UPDATE_CAMPAIGNS_BATCH_PROSPECTS_STATUS,
} from "./actions";
import { IState } from "./interface";
import { ApiFetchStatus } from "module/common/helpers/variables";

export const initialState: IState = {
  campaignsBatchProspects: [],
  error: "",
  next: "",
  previous: "",
  count: 0,
  status: ApiFetchStatus.Success,
};

export default function reducer(state: IState = initialState, action: any) {
  switch (action.type) {
    case UPDATE_CAMPAIGNS_BATCH_PROSPECTS_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case SET_FETCH_CAMPAIGNS_BATCH_PROSPECTS:
      return {
        ...state,
        campaignsBatchProspects: action.payload.data,
        status: ApiFetchStatus.Success,
      };
    case SET_FETCH_CAMPAIGNS_BATCH_PROSPECTS_ERROR:
      return {
        ...state,
        error: action.error,
        status: ApiFetchStatus.Error,
      };
    case CLEAR_FETCH_CAMPAIGNS_BATCH_PROSPECTS_ERROR:
      return {
        ...state,
        error: "",
      };
    case RESET_CAMPAIGNS_BATCH_PROSPECTS_DATA:
      return initialState;
    default:
      return state;
  }
}
