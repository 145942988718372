import React from "react";
import styled from "styled-components/macro";

// components
import Header from "../../../Header";
import { HeaderContents } from "module/common/styles";

const Wrapper = styled.div`
  padding: var(--pad5);
`;

const BlockedMessagePage = ({ noHeader, header, children }) => {
  return (
    <div className="pageContent d-flex flex-column">
      {!noHeader ? (
        <Header>
          <HeaderContents data-test="header-contents"> {header}</HeaderContents>
        </Header>
      ) : null}
      <Wrapper className="pageWrapper">{children}</Wrapper>
    </div>
  );
};

export default BlockedMessagePage;
