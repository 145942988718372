import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReportConfirmationModal from "../../../ProspectDetails/ReportConfirmationModal";
import useModal from "module/common/hooks/useModal";
import { IProspect } from "module/main/store/prospectStore/interfaces";
import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import styled from "styled-components";

interface Props {
  prospect: IProspect;
}

const ProspectPhoneDisplay: React.FC<Props> = ({ prospect }) => {
  const [isReportConfirmationModalOpen, , toggleReportConfirmationModal] = useModal();
  return (
    <>
      <StyledSpan className="textL" data-test="phone-display">
        {prospect.phoneDisplay}{" "}
        <span>
          {prospect.phoneType ? <>({prospect.phoneType})</> : null}
          <FontAwesomeIcon
            id="block-prospect-icon"
            icon="ban"
            color={prospect.isBlocked ? "var(--darkGray)" : "red"}
            className="ml-1 pointer fa-sm"
            data-test="report-icon"
            onClick={toggleReportConfirmationModal}
          />
          <UncontrolledTooltip placement="bottom" target="block-prospect-icon">
            {prospect.isBlocked ? "Blocked" : "Block prospect"}
          </UncontrolledTooltip>
        </span>
      </StyledSpan>
      <ReportConfirmationModal
        isOpen={isReportConfirmationModalOpen}
        toggle={toggleReportConfirmationModal}
        prospect={prospect}
      />
    </>
  );
};
const StyledSpan = styled.span`
  color: var(--darkGray);
`;

export default ProspectPhoneDisplay;
