import React from "react";
import styled from "styled-components";

const ErrorP = styled.p`
  color: var(--red);
  line-height: 1.2;
`;

const Error = ({ className = "text-center", message }) => {
  return <ErrorP className={className}>{message}</ErrorP>;
};

export default Error;
