import React from "react";
import { Link } from "react-router-dom";
import { NavLink } from "reactstrap";
import { UnreadMsgIcon } from "../../../common/components/UnreadMsg";
import { SlideIn, StyledNavItem } from "../../components/styles";
import { UncontrolledTooltip } from "reactstrap";
import WithPermissions from "../../../common/components/WithPermissions";

const CustomNavLink = ({
  name,
  pageName,
  checkSubscription,
  activeClass,
  path,
  icon,
  showCount = null,
  count = null,
  linkClass,
  textClass,
  isCollapsed,
  onClick = () => null,
}) => {
  const tooltipTarget = `tooltip-${name.replace(/\s/g, "-")}`;
  return (
    <WithPermissions checkSubscription={checkSubscription} pageName={pageName}>
      <StyledNavItem
        className={activeClass}
        data-testid={`desktop-nav-${name}`}
        onClick={onClick}
        id={tooltipTarget}
      >
        <NavLink className={linkClass} tag={Link} to={path}>
          <UnreadMsgIcon count={count} showCount={showCount} icon={icon} />
          <SlideIn
            in={isCollapsed}
            timeout={300}
            appear
            className={textClass}
            classNames="slide"
            unmountOnExit
          >
            <span>{name}</span>
          </SlideIn>
        </NavLink>
        {!isCollapsed ? (
          <UncontrolledTooltip
            target={tooltipTarget}
            placement="right"
            data-testid={`${name}-tooltip`}
          >
            {name}
          </UncontrolledTooltip>
        ) : null}
      </StyledNavItem>
    </WithPermissions>
  );
};

export default CustomNavLink;
