import { produce } from "immer";

// actions
import { ADD_EXPORT_UUID, DELETE_EXPORT_UUID } from "./actions";

// interfaces
import { IExport } from "./interfaces";

const initialState: IExport = {
  uuids: [],
};

const reducer = produce((base: IExport, action: any) => {
  switch (action.type) {
    case ADD_EXPORT_UUID: {
      const uuidsList = base.uuids;
      uuidsList.push(action.payload);
      base.uuids = uuidsList;

      break;
    }
    case DELETE_EXPORT_UUID: {
      const uuidsList = base.uuids.filter((uuid: any) => uuid !== action.payload);
      base.uuids = uuidsList;
      break;
    }
  }
}, initialState);

export default reducer;
