import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { components } from "react-select";
import styled from "styled-components";

import InputSelect from "../../../common/components/InputSelect";
import { Count } from "../../../common/components/UnreadMsg";
import ExportButton from "../ExportButton";

import { useProspectHeader } from "./hooks";
import { exportReminders } from "../../../main/store/Global/exports/thunks";

const ProspectHeader = ({ title, tooltipText, className = "", isReminder, stepType }) => {
  const { options, selectedFilter, onChange } = useProspectHeader();
  const dispatch = useDispatch();

  return (
    <HeaderWrapper
      className={`pb-1 bb-1 mb-1 d-flex justify-content-between align-items-center ${className}`}
    >
      <div className="d-flex align-items-center">
        <h3 className="m-0">{title}</h3>
        {tooltipText ? (
          <>
            <span id="tooltip-unread-messages">
              <FontAwesomeIcon
                icon="question-circle"
                size="1x"
                color="var(--black)"
                className="ml-1"
              />
            </span>
            <UncontrolledTooltip
              key="tooltip-unread-messages-tooltip"
              target="tooltip-unread-messages"
              placement="right"
            >
              {tooltipText}
            </UncontrolledTooltip>
          </>
        ) : null}
      </div>
      <InputSelect
        className="small-input flex-shrink-1"
        options={options}
        onChange={onChange}
        value={selectedFilter}
        components={{ Option }}
      />
      {isReminder ? (
        <ExportButton
          data-test="reminders-export"
          iconOnly
          size="1x"
          isLoading={false}
          onClick={() => dispatch(exportReminders(stepType, selectedFilter.value))}
        />
      ) : null}
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  & > div {
    flex: 1 0 50%;
  }
`;

const OptionLabelWrapper = styled.div`
  --size: 1.2rem;
  display: flex;
  justify-content: space-between;
  position: relative;

  .label-name {
    color: ${(props) => (props.archived ? "gray" : "black")};
  }
`;

const Option = ({ children, ...props }) => {
  const { label, count, archived, value } = props.data;

  if (value <= 0 || typeof value === "string") {
    return <components.Option {...props}>{children}</components.Option>;
  }

  return (
    <components.Option {...props}>
      <OptionLabelWrapper archived={archived}>
        <span className="label-name">{label}</span>
        <div>
          <Count color="var(--sherpaBlue)" showCount>
            {count}
          </Count>
        </div>
      </OptionLabelWrapper>
    </components.Option>
  );
};

ProspectHeader.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
};

export default ProspectHeader;
