import * as actions from "./actions";
import { addErrorToast } from "../../Global/Toasts/actions";

import { Dispatch } from "redux";

import * as api from "./api";
import { Fetching } from "module/common/helpers/variables";

const onGlobalFailure = (dispatch: Dispatch, str: string) => {
  dispatch(addErrorToast(`There was an issue ${str} property tag.`));
};

export const fetchPropertyTags = () => async (dispatch: Dispatch) => {
  dispatch(actions.fetchPropertyTagsStatus(Fetching));
  const onSuccess = ({ data }: { [key: string]: any }) => {
    dispatch(actions.fetchPropertyTagsSuccess(data));
  };
  return api
    .fetchPropertyTags()
    .then(onSuccess)
    .catch(() => onGlobalFailure(dispatch, "retrieving"));
};

export const updatePropertyTag =
  (payload: any, meta: any = { partial: false }) =>
  async (dispatch: Dispatch) => {
    const onSuccess = ({ data }: { [key: string]: any }) => {
      dispatch(actions.updatePropertyTag(data, meta));

      return data;
    };

    return api
      .updatePropertyTag(payload)
      .then(onSuccess)
      .catch(() => onGlobalFailure(dispatch, "updating"));
  };

export const removePropertyTag = (payload: any) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line
  const onSuccess = ({ data }: { [key: string]: any }) => {
    dispatch(actions.removePropertyTag(payload.id));
    return true;
  };

  return api
    .removePropertyTag(payload)
    .then(onSuccess)
    .catch(() => onGlobalFailure(dispatch, "removing"));
};

export const createPropertyTag = (payload: any) => async (dispatch: Dispatch) => {
  const onSuccess = ({ data }: { [key: string]: any }) => {
    dispatch(actions.updatePropertyTag(data, { type: "create" }));

    return data;
  };

  return api
    .createPropertyTag({ ...payload, isCustom: true })
    .then(onSuccess)
    .catch(() => onGlobalFailure(dispatch, "creating"));
};
