import React from "react";
import { PopoverHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QuickReplyDesktop, PopBody } from "./styles";

const MessageQuickRepliesDesktop = ({
  isOpen,
  toggle,
  setShowEditForm,
  renderSearchInput,
  renderQuickRepliesList,
}) => {
  return (
    <QuickReplyDesktop
      placement="auto"
      isOpen={isOpen}
      toggle={toggle}
      target="quick-reply"
      data-test="quick-replies-desktop"
    >
      <PopoverHeader className="textXL" data-test="popover-header">
        Select Quick Reply
        <div>
          <FontAwesomeIcon
            className="mr-2 pointer"
            icon="plus-circle"
            data-test="add-quick-reply"
            onClick={() => {
              toggle();
              setShowEditForm(true);
            }}
          />
          <FontAwesomeIcon className="pointer" icon="times" onClick={toggle} />
        </div>
      </PopoverHeader>
      <PopBody data-test="popover-body">
        <div className="p-3 w-100">{renderSearchInput()}</div>
        <div className="w-100">{renderQuickRepliesList()}</div>
      </PopBody>
    </QuickReplyDesktop>
  );
};

export default MessageQuickRepliesDesktop;
