// Temporary function that creates the ALL folder that's going to be
// removed once the folder-endpoint works properly

import { IMarket } from "./interfaces";

export const formatCallForwardNumber = (market: IMarket): IMarket => {
  const callForwardingNumber = market.callForwardingNumber || "";
  const [
    // eslint-disable-next-line
    _,
    areaCode = "",
    first = "",
    second = "",
  ] = callForwardingNumber.match(/^(\d{3})(\d{3})(\d{4})/) || [];
  return {
    ...market,
    callForwardingNumber: callForwardingNumber ? `(${areaCode}) ${first}-${second}` : "",
  };
};

export const formatMarkets = (data: IMarket[]): IMarket[] => {
  const marketsWithFormattedNumbers = data.map(formatCallForwardNumber);

  return marketsWithFormattedNumbers;
};
