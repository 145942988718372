import * as Sentry from "@sentry/browser";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// redux related
import { addNewToast } from "../../../store/Global/Toasts/actions";
// service worker related
import { history } from "../../../../common/helpers/history";
import { register } from "./serviceWorker";

// utils
import { ONE_SECOND_IN_MILLISECONDS } from "module/common/helpers/variables";
import useInterval from "module/common/hooks/useInterval";
import { getRegistrationTimeoutTime } from "./utils";

const POLLING_CHECK_UPDATE_INTERVAL = 30 * ONE_SECOND_IN_MILLISECONDS;

const NewContentAvailable = () => {
  const dispatch = useDispatch();
  const [reloadSite, setReloadSite] = useState(false);

  const registrationPostSkipMessage = (registration: ServiceWorkerRegistration) => {
    setTimeout(() => {
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    }, getRegistrationTimeoutTime());
  };

  const checkServiceWorkerForUpdate = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          if (!registration) return;

          if (registration.active && !navigator.serviceWorker.controller) {
            setReloadSite(true);
          }
          // if the page is refreshed without clicking on the reload button (to post the SKIP_WAITING message),
          // the toast needs to be displayed again for the user to click - service workers have a life expectancy of 24 hours,
          // so if the user doesn't click on it within that time, the service worker will automatically be updated.
          if (registration.waiting) {
            registrationPostSkipMessage(registration);
          }
          // If the registration does not exists, and it tries to update, it will get this error:
          // TypeError: Failed to update a ServiceWorker for scope
          registration.update().catch(() => {
            // try to register the service worker again
            register();
          });
        })
        .catch(() => {
          dispatch(
            addNewToast({
              message: "Error: Cannot register version notifications",
              color: "danger",
            })
          );
        });
    } else {
      // this is mostly for IE
      // eslint-disable-next-line
      console.log("Service workers are not supported.");
    }
  };

  useInterval(() => {
    checkServiceWorkerForUpdate();
  }, POLLING_CHECK_UPDATE_INTERVAL);

  // check to see if there is a new version out (PWA functionality)
  // only run this at the beginning of the mounted app if they are logged in
  useEffect(() => {
    register();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (reloadSite) {
      Sentry.captureMessage("Site reloaded from service worker");
      window.location.reload();
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  return null;
};

export default NewContentAvailable;
