import {
  FETCH_CAMPAIGN_PROSPECTS,
  FETCH_CAMPAIGN_PROSPECTS_SUCCESS,
  FETCH_CAMPAIGN_PROSPECTS_FAILURE,
  UPDATE_CAMPAIGN_PROSPECT_SUCCESS,
  FETCH_MORE_CAMPAIGN_PROSPECTS,
  FETCH_CAMPAIGN_PROSPECTS_UNREAD_SUCCESS,
  REMOVE_CAMPAIGN_PROSPECT_UNREAD,
  SET_UNREAD_MESSAGES_FETCH_STATUS,
  UPDATE_CAMPAIGN_PROSPECTS_UNREAD_COUNT,
  UPDATE_CAMPAIGN_PROSPECTS_UNREAD_REMINDERS,
} from "./actionTypes";
import { removeCampaignProspectUnread } from "./utils";
import { Fetching, Success } from "module/common/helpers/variables";

const initialState = {
  next: null,
  previous: null,
  campaignProspects: {},
  campaignProspectsUnread: [],
  campaignProspectsUnreadCount: 0,
  unreadStatus: Fetching,
  isLoading: false,
  isLoadingMore: false,
  error: false,
};

export const path = ["campaignProspectStore"];

export default function reducer(state: any = initialState, action: any) {
  switch (action.type) {
    case FETCH_CAMPAIGN_PROSPECTS:
      return { ...state, isLoading: action.payload };
    case FETCH_MORE_CAMPAIGN_PROSPECTS:
      return { ...state, isLoadingMore: action.payload };
    case FETCH_CAMPAIGN_PROSPECTS_SUCCESS: {
      const newState = { ...state };

      if (action.concatResults) {
        newState.campaignProspects = {
          ...newState.campaignProspects,
          ...action.payload.results,
        };
      } else {
        newState.campaignProspects = action.payload.results;
      }

      newState.next = action.payload.next;
      newState.previous = action.payload.previous;
      newState.isLoading = false;
      newState.isLoadingMore = false;

      return newState;
    }
    case FETCH_CAMPAIGN_PROSPECTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_CAMPAIGN_PROSPECT_SUCCESS: {
      const campaignId = action.payload.campaign.id;
      const campaignProspects = { ...state.campaignProspects };

      // only update if it exists already
      if (campaignProspects[campaignId]) {
        const prospectId = action.payload.prospect;
        const prospectIndex = campaignProspects[campaignId].findIndex(
          (cp: any) => cp.prospect === prospectId
        );

        campaignProspects[campaignId] = [...campaignProspects[campaignId]];
        campaignProspects[campaignId][prospectIndex] = action.payload;

        return {
          ...state,
          campaignProspects,
        };
      }

      return state;
    }
    case FETCH_CAMPAIGN_PROSPECTS_UNREAD_SUCCESS: {
      return {
        ...state,
        unreadStatus: Success,
        campaignProspectsUnread: action.payload.data,
        campaignProspectsUnreadCount: action.payload.count,
      };
    }
    case UPDATE_CAMPAIGN_PROSPECTS_UNREAD_REMINDERS: {
      const newCampaignProspectsUnread = state.campaignProspectsUnread.map((cp: any) => {
        if (cp.prospectId === action.payload.prospectId) {
          cp.reminderAgent = action.payload.reminderAgent;
          cp.reminderDateUtc = action.payload.reminderDateUtc;
        }
        return cp;
      });
      return {
        ...state,
        campaignProspectsUnread: newCampaignProspectsUnread,
      };
    }
    case UPDATE_CAMPAIGN_PROSPECTS_UNREAD_COUNT: {
      return {
        ...state,
        campaignProspectsUnreadCount: action.payload,
      };
    }
    case REMOVE_CAMPAIGN_PROSPECT_UNREAD: {
      const newData = removeCampaignProspectUnread(
        state.campaignProspectsUnread,
        action.payload
      );
      const newState = {
        ...state,
        campaignProspectsUnread: newData,
        campaignProspectsUnreadCount: state.campaignProspectsUnreadCount - 1,
      };

      return newState;
    }
    case SET_UNREAD_MESSAGES_FETCH_STATUS:
      return {
        ...state,
        unreadStatus: action.payload,
      };
    default:
      return state;
  }
}
