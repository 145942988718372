import { useEffect } from "react";

const authKey = "be44ee895b5aacb966457c03a123c3de";
const apiKey = "jstkxi2nopyyua1b9vdeif8kxhyaz7dr";

const NateroInitScript = (props) => {
  const { id, company } = props.userData;

  useEffect(() => {
    try {
      const setupScript = document.createElement("script");
      setupScript.type = "text/javascript";
      setupScript.async = false;
      setupScript.innerHTML = `
        try {
          _na = new na('${apiKey}', '${authKey}', {
            userId: ${id},
            accountId: ${company.id},
            trackUnload: true,
          });
        } catch(err) {
          console.log(err);
        }
      `;
      document.body.appendChild(setupScript);

      return () => {
        document.body.removeChild(setupScript);
      };
    } catch (_) {
      return null;
    }
  }, [id, company]);

  return null;
};

export const setNateroLogin = (user) => {
  if (!window._na) return null;
  window._na.login(user.id, user.company.id, {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    phone: user.profile.phone,
  });
  return null;
};

export const setNateroLogout = () => {
  if (!window._na) return null;
  window._na.logout();
  return null;
};

export const setNateroFeatureEvent = (featureName) => {
  if (!window._na) return null;
  window._na.sendFeatureEvent(featureName);
  return null;
};

export default NateroInitScript;
