export const ACCOUNT_SETTINGS_VIEW_PAGE = "account-settings/view:page";
export const ACCOUNT_SETTINGS_CANCEL_ACCOUNT = "account-settings/action:cancel-account";
export const ACCOUNT_SETTINGS_UPGRADE_ACCOUNT = "account-settings/action:upgrade-account";

export const permissions = {
  master_admin: [ACCOUNT_SETTINGS_CANCEL_ACCOUNT, ACCOUNT_SETTINGS_UPGRADE_ACCOUNT],
  admin: [ACCOUNT_SETTINGS_VIEW_PAGE],
  staff: [],
  junior_staff: [],
};
