import { createAction } from "../redux-helpers";

export const SET_PHONE_NUMBERS_STATUS = "SET_PHONE_NUMBERS_STATUS";
export const FETCH_PHONE_NUMBERS_SUCCESS = "FETCH_PHONE_NUMBERS_SUCCESS";
export const FETCH_PHONE_NUMBERS_ERROR = "FETCH_PHONE_NUMBERS_ERROR";
export const UPDATE_PHONE_NUMBER = "UPDATE_PHONE_NUMBER ";

export const setPhoneNumberStatus = createAction(SET_PHONE_NUMBERS_STATUS);
export const fetchPhoneNumbersSuccess = createAction(FETCH_PHONE_NUMBERS_SUCCESS);
export const fetchPhoneNumbersError = createAction(FETCH_PHONE_NUMBERS_ERROR);
export const updatePhoneNumber = createAction(UPDATE_PHONE_NUMBER);
