import axios, { AxiosRequestConfig } from "axios";
import axiosInstance from "../../../common/utils/axiosConfig";
import { saveToLocalStorage } from "../../../common/utils/utils";
import ReduxStore from "../store";
import { setAuthenticated } from "./actions";
import { logout } from "./thunks";
import { loadTokens } from "./loadTokens";

export interface CreateToken {
  access: string;
  refresh?: string;
}

export const setAuthToken = ({ access }: CreateToken) => {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${access}`;
};

export const revokeAuthToken = () => {
  delete axiosInstance.defaults.headers.common["Authorization"];
};

// updates access token if is an error 401 AND refresh token is valid
const updateClientToken = (error: any, { access, refresh }: CreateToken) => {
  const originalRequest = error.config;
  originalRequest.headers["Authorization"] = `Bearer ${access}`;
  saveToLocalStorage("access", access);
  setAuthToken({ access });
  ReduxStore.dispatch(setAuthenticated({ access, refresh }));
  return originalRequest;
};

export const getNewAccessToken = async (refresh: string, error: any) => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "auth/jwt/refresh/",
    data: { refresh },
  };
  return axiosInstance(config)
    .then(({ data: { access } }) =>
      axios.request(updateClientToken(error, { access, refresh }))
    )
    .catch(() => {
      ReduxStore.dispatch(logout());
    });
};

export const setAuthTokenHeader = () => {
  const { auth } = loadTokens();

  if (auth.token) setAuthToken({ access: auth.token });
};
