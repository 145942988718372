import React, { useState, useEffect } from "react";
import styled from "styled-components";
// utils
import { getPublicRequest } from "module/common/utils/publicAxios";

interface Props {}

const LoginMarketing: React.FC<Props> = () => {
  // state
  const [marketingImgs, setMarketingImgs] = useState<string[]>([]);
  const [currentImgId, setCurrentImgId] = useState<number>(0);

  const marketingPathURL = "/marketingPaths.json";

  useEffect(() => {
    // this will return an array of svg paths from public marketing folder
    getPublicRequest(marketingPathURL)
      .then(({ data: { marketingPaths } }) => {
        if (!Array.isArray(marketingPaths)) return;
        setMarketingImgs(marketingPaths);
        setCurrentImgId(Math.floor(marketingPaths.length * Math.random()));
      })
      // if we catch, it means there is no marketing folder present in public
      .catch(() => setMarketingImgs([]));
  }, []);

  if (!marketingImgs.length) return null;

  return (
    <StyledMarketSvgObject
      data={"/marketing/" + marketingImgs[currentImgId]}
      data-testid="marketing-image"
    >
      Marketing Image
    </StyledMarketSvgObject>
  );
};

export default LoginMarketing;

// Object needed to embed links in svg
export const StyledMarketSvgObject = styled.object`
  border-left: 2px solid var(--gray);
  height: 100vh;
  @media (max-width: 900px) {
    display: none;
  }
`;
