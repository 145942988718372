import AxiosInstance from "module/common/utils/axiosConfig";

export const fetchPublicProspect = (token) =>
  AxiosInstance.get(`/prospects/${token}/public/`);

export const markAllMessagesAsRead = (id) =>
  AxiosInstance.post(`/prospects/${id}/mark_as_read/`);

export const prospectPushToCampaign = (id, payload) => {
  return AxiosInstance.post(`/prospects/${id}/push_to_campaign/`, payload);
};
