import { ProspectRecord, PartialProspectRecord } from "../prospectStore/interfaces";
import { arrayToMapIndex } from "../utils";
import { strDateToMoment } from "../../../common/helpers/dateUtils";

export const trimProspectsAndMessages = (campaignProspects) => {
  return campaignProspects.reduce(
    (campaignAndProspects, campaignProspect) => {
      const prospect = campaignProspect.prospect;
      campaignAndProspects[2][prospect.id] = arrayToMapIndex("id", prospect.messages);
      prospect.messages = [];
      prospect.partial = true;

      // campaign the prospect is currently being selected from
      prospect.campaign = campaignProspect.campaign;
      campaignAndProspects[0].push(PartialProspectRecord(prospect, false));
      // replace prospect with just the id
      campaignProspect.prospect = prospect.id;

      // collect the campaignProspect
      campaignAndProspects[1].push(campaignProspect);
      return campaignAndProspects;
    },
    [[], [], {}]
  );
};

export const getMessageSortOrder = (unreadMessages) => {
  return unreadMessages
    .map((unreadMessage) => {
      unreadMessage.messageDatetime = strDateToMoment(unreadMessage.messageDatetime);
      unreadMessage.partial = true;
      // so we don't mix terms from prospect data to the one returned from unread endpoint
      unreadMessage.reminderAgent = unreadMessage.prospectReminderAgent;
      unreadMessage.reminderDateUtc = unreadMessage.prospectReminderDateUtc;
      return unreadMessage;
    })
    .sort(
      (a, b) => strDateToMoment(b.messageDatetime) - strDateToMoment(a.messageDatetime)
    );
};

export const toGroupedCampaignProspectsUnread = (
  leadStages,
  campaignProspectsUnread,
  prospects,
  sortOrder
) => {
  if (Object.keys(campaignProspectsUnread).length > 0 || leadStages.length > 0) {
    const cps = sortOrder.map((campaignId) => {
      const campaignProspect = campaignProspectsUnread[campaignId];
      const campaignProspects = [];

      if (Object.keys(prospects).length >= campaignProspect.length) {
        // regular loop for early termination
        for (const cProspect of campaignProspect) {
          const prospect = prospects[cProspect.prospect] || ProspectRecord({});
          // no prospect found on the store
          if (prospect.id === 0) continue;

          const leadStage = leadStages.find(
            (leadStage) => leadStage.id === prospect.leadStage
          );

          campaignProspects.push({
            ...cProspect,
            prospect: {
              ...prospect,
              leadStageTitle: leadStage ? leadStage.leadStageTitle : "",
            },
          });
        }
        return campaignProspects;
      }
      return [];
    });

    return cps.filter((cp) => cp.length > 0);
  }
  return [];
};

export const filterByCampaign = (filter, campaignProspects) => {
  return Object.values(campaignProspects).filter(({ campaigns }) =>
    campaigns.some((c) => c.id === filter)
  );
};

export const uniqueProspects = (prospects) => {
  return prospects.reduce((acc, prospect) => {
    const idx = acc.findIndex((data) => data.prospectId === prospect.prospectId);

    if (idx === -1) {
      acc.push(prospect);
    }

    return acc;
  }, []);
};

export const filterCampaignProspectsUnread = (unreadMessages) => {
  return unreadMessages
    .sort((a, b) => b.smsMessageId - a.smsMessageId)
    .filter(
      (unreadMessage, i, array) =>
        array.findIndex((u) => u.prospectId === unreadMessage.prospectId) === i
    );
};
