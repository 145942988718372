import { createAction } from "../redux-helpers";

export const POPULATE_CAMPAIGN_NOTES = "POPULATE_CAMPAIGN_NOTES";
export const SET_CAMPAIGN_NOTE_ERROR = "SET_CAMPAIGN_NOTE_ERROR";
export const SET_CAMPAIGN_NOTES_STATUS = "SET_CAMPAIGN_NOTES_STATUS";
export const ADD_CAMPAIGN_NOTE = "ADD_CAMPAIGN_NOTE";
export const DELETE_CAMPAIGN_NOTE = "DELETE_CAMPAIGN_NOTE";
export const EDIT_CAMPAIGN_NOTE = "EDIT_CAMPAIGN_NOTE";

export const populateCampaignNotes = createAction(POPULATE_CAMPAIGN_NOTES);
export const setCampaignNotesStatus = createAction(SET_CAMPAIGN_NOTES_STATUS);
export const setCampaignNotesError = createAction(SET_CAMPAIGN_NOTE_ERROR);
export const addCampaignNote = createAction(ADD_CAMPAIGN_NOTE);
export const editCampaignNote = createAction(EDIT_CAMPAIGN_NOTE);
export const deleteCampaignNote = createAction(DELETE_CAMPAIGN_NOTE);
