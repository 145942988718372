import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import Banner from "./components/Banner";
import { getBanner } from "../../../store/Global/Banner/selectors";

const BannerContainer = () => {
  const banners = useSelector(getBanner);
  const dispatch = useDispatch();
  const mappedBanners = banners.map(({ id, ...bannerProps }) => (
    <Banner key={id} id={id} dispatch={dispatch} {...bannerProps} />
  ));
  return (
    <Wrapper className="d-flex flex-column-reverse m-auto position-fixed w-100">
      {mappedBanners}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  pointer-events: none;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10000;

  ${(props) =>
    props.theme.isDesktop
      ? css`
          max-width: 730px;
        `
      : null}
  * {
    pointer-events: all;
  }
`;

export default BannerContainer;
