import { createSelector as createCachedSelector } from "reselect";
import { createSelectorContext } from "../../redux-helpers";

const createSelector = createSelectorContext(["Tags", "property"]);

export const getPropertyTags_ = createSelector("tags"); // uncached
export const getPropertyTagSortOrder = createSelector("sortOrder");

export const getPropertyTags = createCachedSelector(
  getPropertyTags_,
  getPropertyTagSortOrder,
  (tags: any, order: Array<number>) => {
    return order.map((id: number) => tags[id]);
  }
);

export const numOfDistressIndicators = createCachedSelector(
  getPropertyTags_,
  (tags: any) => {
    let count = 0;
    for (const key in tags) {
      if (tags[key].distressIndicator) {
        count++;
      }
    }
    return count;
  }
);
