import { combineReducers } from "redux";
import campaignsList from "./campaignsList/reducers";
import { getIn } from "module/common/utils/utils";
import {
  SET_DESKTOP_CAMPAIGNS_NEED_UPDATE,
  SET_DESKTOP_CAMPAIGN_ACTIVE_TAB,
  RESET_DESKTOP_CAMPAIGN_ACTIVE_TAB,
} from "./actions";

// selector
export const desktopCampaignActiveTab = (state: any) =>
  getIn(["uiStore", "campaignsPageDesktopView", "uiState", "activeTab"], state);

// reducer
const init = {
  activeTab: "all",
  campaignsNeedUpdate: false,
};

function uiState(state: any = init, action: any) {
  switch (action.type) {
    case SET_DESKTOP_CAMPAIGN_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case RESET_DESKTOP_CAMPAIGN_ACTIVE_TAB:
      return init;
    case SET_DESKTOP_CAMPAIGNS_NEED_UPDATE:
      return {
        ...state,
        campaignsNeedUpdate: action.payload,
      };
    default:
      return state;
  }
}

const reducers = combineReducers({
  campaignsList,
  uiState,
});

export default reducers;
