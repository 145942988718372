import { Initial, Success, FetchError, Fetching } from "module/common/helpers/variables";

import { IState } from "./interfaces";

import {
  FETCH_PARENT_MARKETS,
  FETCH_PARENT_MARKETS_FAILURE,
  FETCH_PARENT_MARKETS_SUCCESS,
} from "./actionTypes";

export const initialState: IState = {
  parentMarkets: [],
  status: Initial,
  error: false,
};

export const path = ["ParentMarkets"];

export default function reducer(state: IState = initialState, action: any) {
  switch (action.type) {
    case FETCH_PARENT_MARKETS:
      return { ...state, status: Fetching };
    case FETCH_PARENT_MARKETS_FAILURE:
      return { ...state, status: FetchError, error: action.payload };
    case FETCH_PARENT_MARKETS_SUCCESS:
      return { ...state, status: Success, parentMarkets: action.payload };
    default:
      return state;
  }
}
