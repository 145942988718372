import styled, { css } from "styled-components/macro";

export const Activity = styled.span`
  flex-grow: 0;
  margin: 0px 10px;

  a {
    cursor: default;
  }
`;

export const Container = styled.div`
  height: 50vh;
`;

export const Divider = styled.div`
  flex-grow: 1;
  border-bottom: 1px solid var(--gray);
`;

export const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: static;

  > div:first-child {
    flex-grow: 1;
  }

  & > div.displayedData {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
  }
  ${(props) =>
    props.theme.isMobile
      ? css`
          height: 100%;
        `
      : null}
`;

export const StyledList = styled.ul`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding: var(--pad4) var(--pad3) var(--pad1) var(--pad3);
  z-index: 1;
`;

export const Placeholder = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--darkGray);
  padding: var(--pad3);
  line-height: 1.3 !important;
`;

export const InputWrapper = styled.div`
  width: 100%;
  z-index: 2;
`;

export const StyledNote = styled.span`
  color: var(--sherpaBlue);
  cursor: default;
`;
