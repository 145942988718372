import { profilesToAgents } from "./transformers";
import { path as detailsViewPath } from "./reducer";
import {
  createSelectorContext,
  createSelector as createSelector2,
} from "../../redux-helpers";
import { getIn } from "module/common/utils/utils";
import { getCampaign } from "../../Campaigns/selectors";

const createSelector = createSelectorContext(detailsViewPath);

export const activeProspectSelector = createSelector("activeProspect");
export const activeCampaignSelector = createSelector(
  "activeCampaign",
  (activeCampaign: any, state: any) => getCampaign(state, activeCampaign)
);
export const actionBtnStatusSelector = createSelector("actionButtons");
export const agentSelector = createSelector2(
  ["Company", "profiles", "profiles"],
  profilesToAgents
);

export const getProspectsToCycle = (id: number) =>
  createSelector("prospectSourcePath", (prospectSourcePath: any, state: any) => {
    if (prospectSourcePath.length > 0) {
      const data = getIn(prospectSourcePath, state) || {};

      // need to check if the data is coming in from the campaign as a indexed list OR
      // from the unread messages as a flat list
      const dataToCycle = Array.isArray(data) ? data : data[id];
      if (dataToCycle) {
        // data can be undefined due to how it's being rendered on desktop/mobile
        return Object.values(dataToCycle)
          .map((campaignProspect: any) =>
            campaignProspect && campaignProspect.prospect
              ? campaignProspect.prospect
              : campaignProspect
          )
          .reduce((acc: any, id: any) => {
            const idx = id?.id || id;
            if (acc.findIndex((elem: any) => elem === id) === -1) acc.push(idx);
            return acc;
          }, []);
      }
    }

    return [];
  });
