import {
  SET_PROSPECT_SEARCH_FILTERS,
  SEARCH_PROSPECTS,
  SEARCH_PROSPECTS_SUCCESS,
  SEARCH_PROSPECTS_FAILURE,
  SEARCH_PROSPECTS_NEXT_PAGE,
  SEARCH_PROSPECTS_NEXT_PAGE_SUCCESS,
  SEARCH_RESET_RESULTS,
  SET_SEARCH_PROSPECT_IDS,
  SET_EXPORT_PARAMS,
} from "./actionTypes";

const filters = {
  searchTerm: "",
  lead_stage: {},
  tag: {},
  alltags: true,
  verification: {},
};

const initialState = {
  isLoading: false,
  isLoadingMore: false,
  sortOrder: [],
  filters,
  exportParams: {},
  error: false,
  reset: false,
};

export default function reducer(state: any = initialState, action: any) {
  switch (action.type) {
    case SEARCH_PROSPECTS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SEARCH_PROSPECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    case SEARCH_PROSPECTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SEARCH_PROSPECTS_NEXT_PAGE:
      return {
        ...state,
        isLoadingMore: true,
      };
    case SEARCH_PROSPECTS_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        isLoadingMore: false,
      };
    case SEARCH_RESET_RESULTS:
      return {
        ...state,
        sortOrder: [],
      };
    case SET_EXPORT_PARAMS:
      return {
        ...state,
        exportParams: action.payload,
      };
    case SET_SEARCH_PROSPECT_IDS:
      return {
        ...state,
        sortOrder: [...state.sortOrder, ...action.payload],
      };
    case SET_PROSPECT_SEARCH_FILTERS:
      return {
        ...state,
        filters: {
          ...filters,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
