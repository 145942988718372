import { AuthState } from "./interfaces";
import { initialState } from "./reducers";

export const loadTokens = () => {
  const refreshToken = localStorage.getItem("refresh") || "";
  const token = localStorage.getItem("access") || "";
  const hasAuthToken = token.length > 0;

  return {
    auth: {
      ...initialState,
      token,
      refreshToken,
      hasAuthToken,
    } as AuthState,
  };
};
