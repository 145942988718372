import { IPlanChoices } from "module/main/store/SubscriptionStore/interfaces";
import { selectPlanChoices } from "module/main/store/SubscriptionStore/selectors";
import { fetchPlanOptions } from "module/main/store/SubscriptionStore/thunks";
import { useAppDispatch } from "module/main/store/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

type LoadingType = boolean;

type HookType = (companyId: number) => [IPlanChoices, LoadingType];

export const usePlanChoices: HookType = (companyId: number) => {
  const dispatch = useAppDispatch();
  const subscriptionOptions: IPlanChoices = useSelector(selectPlanChoices);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchPlanOptions(companyId)).finally(() => setIsLoading(false));
  }, []);

  return [subscriptionOptions, isLoading];
};
