import { AppEnv, TrackerMode } from "module/common/helpers/variables";
import { IChurnWindow } from "./utils";
import * as Sentry from "@sentry/browser";
import { globalDynamicConfig } from "dynamicConfig";
// base level

declare const window: IChurnWindow;

const churnZeroCall = (expression: any[]) => {
  if (
    process.env.NODE_ENV !== AppEnv.Production ||
    globalDynamicConfig.config.REACT_APP_DYNAMIC_TRACKERS_MODE === TrackerMode.NoTrackers
  )
    return;

  try {
    window.ChurnZero?.push(expression);
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setExtra("Error", err || null);
      Sentry.captureMessage("Failed ChurnZero call");
    });
  }
};

// 2nd level
export const churnZeroPush = (expression: any[]) => churnZeroCall(expression);

// user level
export const churnZeroTrackEvent = ({
  eventName,
  description = undefined,
}: Record<string, string | undefined>) => {
  churnZeroPush(["trackEvent", eventName, description]);
};
export const churnZeroSetContact = ({ accountId, contactId }: Record<string, number>) =>
  churnZeroPush(["setContact", accountId, contactId]);

// specific events
export const churnZeroFlatFileErrorEvent = ({ description }: { [key: string]: string }) =>
  churnZeroTrackEvent({
    eventName: "FlatFileError",
    description,
  });
export const churnZeroCancelAccountEvent = () =>
  churnZeroTrackEvent({ eventName: "Clicked Cancel Account" });
