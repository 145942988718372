import { createSelectorContext } from "../redux-helpers";
import { path } from "./reducers";
import { createSelector as createCachedSelector } from "reselect";

const createSelector = createSelectorContext(path);

export const getSequencesListObject = createSelector("sequencesList");
export const getStatus = createSelector("status");
export const error = createSelector("error");

export const getSequences = createCachedSelector(
  getSequencesListObject,
  (sequencesList) => sequencesList && Object.values(sequencesList)
);

export const getUnreadSequences = createCachedSelector(getSequences, (sequencesList) => {
  const retValue: any = Object.values(sequencesList).filter((sequence: any) => {
    const prospectsCount = sequence?.prospects?.length;
    if (isNaN(prospectsCount)) {
      return false;
    }
    const prospects = parseInt(prospectsCount);
    return prospects > 0;
  });
  return Object.values(retValue);
});
