import React, { useState } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IProspect } from "module/main/store/prospectStore/interfaces";

// hooks
import useModal from "module/common/hooks/useModal";

// components
import UncontrolledToolTip from "../../../common/components/UncontrolledToolTip";
import Modal from "../../../common/components/Modal";
import ProspectToSequenceModal from "../DesktopCampaignsPage/SequencesTab/modals/ProspectToSequenceModal";

// redux related
import { removeUnreadMessages } from "../../../main/store/ProspectDetails/messages/thunk";
import { removeCampaignProspect } from "../../../main/store/campaignProspectStore/thunks";
import { markAllMessagesAsRead } from "module/main/services/Prospect";
import { updateProspectSuccess } from "../../../main/store/prospectStore/actions";
import { setActiveProspect } from "../../../main/store/uiStore/prospectDetailsView/actions";
import { getFetchingMessages } from "../../../main/store/prospectStore/selectors";

// forms
import ProspectClone from "../../../common/components/Forms/ProspectClone";
import ProspectEdit from "../../../common/components/Forms/ProspectEdit";

// utils
import { hasCampaignsCheck } from "../ProspectDetailsPage/utils";
import { history } from "../../../common/helpers/history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getIn } from "module/common/utils/utils";

interface Props {
  prospect: IProspect;
  onProspectSlideoutClick: () => void;
}

const ProspectSubActionButtons: React.FC<Props> = ({
  prospect,
  onProspectSlideoutClick,
}) => {
  const dispatch = useDispatch();
  const isFetchingMessages = useSelector(getFetchingMessages);
  const hasUnreadSms = prospect.hasUnreadSms;
  const hasCampaigns = hasCampaignsCheck(prospect);

  // modal control
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);
  const [modalSize, setModalSize] = useState<string>("xl");
  const [isProspectSequenceOpen, , toggleProspectSequence] = useModal();

  const editAction = () => {
    openModalForm({
      size: "md",
      title: "Edit Prospect",
      content: renderProspectEditForm,
    });
  };

  const cloneAction = () => {
    if (!hasCampaigns) return;
    openModalForm({
      size: "lg",
      title: "Clone Prospect",
      content: renderProspectCloneForm,
    });
  };

  const createPrintLink = () => {
    const { token = 0 } = prospect;
    return `/public/sms/${token}/`;
  };

  const openModalForm = ({
    title,
    content,
    size,
  }: {
    title: string;
    size: string;
    content: () => JSX.Element;
  }) => {
    setShowModal(true);
    setModalTitle(title);
    setModalContent(content());
    setModalSize(size);
  };

  const markMessageAsRead = () => {
    if (isFetchingMessages || !hasUnreadSms) return;
    dispatch(removeUnreadMessages(prospect.id, prospect));
    dispatch(removeCampaignProspect(prospect.id, prospect.hasUnreadSms));
    markAllMessagesAsRead(prospect.id);
    dispatch(updateProspectSuccess({ id: prospect.id, hasUnreadSms: false }));
  };

  const renderProspectCloneForm = () => {
    const selectedCampaign = getIn(["campaigns", 0, "id"], prospect) || null;

    return (
      <ProspectClone
        initialState={prospect}
        selectedCampaign={selectedCampaign}
        closeModal={() => setShowModal(false)}
        viewProspect={(id: string) => () => {
          dispatch(setActiveProspect(parseInt(id)));
          setShowModal(false);
          history.push(`/prospect/${id}/details`);
        }}
      />
    );
  };

  const renderProspectEditForm = () => {
    return (
      <ProspectEdit
        initialState={{
          id: prospect.id,
          firstName: prospect.firstName,
          lastName: prospect.lastName,
        }}
        closeModal={(value: boolean) => setShowModal(value)}
      />
    );
  };

  const renderModal = () => {
    return (
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal((value) => !value)}
        title={modalTitle}
        size={modalSize}
      >
        {modalContent}
      </Modal>
    );
  };

  return (
    <>
      <span data-test="mark-as-read-button" onClick={markMessageAsRead}>
        <UncontrolledToolTip
          className="ml-0"
          text={hasUnreadSms ? "Mark as read" : "Read"}
          icon={hasUnreadSms ? "envelope" : "envelope-open"}
          id="tooltip-mark-as-read"
          size="1x"
          color={hasUnreadSms ? "var(--darkGray)" : "var(--gray)"}
          hasHoverStyles
        />
      </span>
      <span data-test="edit-prospect-button" onClick={editAction}>
        <UncontrolledToolTip
          className="ml-0"
          text="Edit info"
          icon="pencil-alt"
          color="var(--darkGray)"
          id="tooltip-edit-info"
          size="1x"
          hasHoverStyles
        />
      </span>
      <span onClick={toggleProspectSequence} data-test="prospect-to-sequence-button">
        <UncontrolledToolTip
          className="ml-0"
          text={prospect.activeProspectSequence ? "Edit Sequence" : "Add to Sequence"}
          icon={prospect.activeProspectSequence ? "calendar-check" : "calendar-plus"}
          id="tooltip-sequence"
          size="1x"
          color={prospect.activeProspectSequence ? "var(--green)" : "var(--darkGray)"}
          hasHoverStyles
        />
      </span>
      <span color="link" data-test="clone-prospect-button" onClick={cloneAction}>
        <UncontrolledToolTip
          className="ml-0"
          text="Duplicate"
          icon="clone"
          color={hasCampaigns ? "var(--darkGray)" : "var(--gray)"}
          id="tooltip-duplicate"
          size="1x"
          hasHoverStyles
        />
      </span>
      <span color="link">
        <Link to={createPrintLink} target="_blank" data-test="print-sms-button">
          <UncontrolledToolTip
            className="ml-0"
            text="Print"
            icon="print"
            color="var(--darkGray)"
            id="tooltip-create-sms"
            size="1x"
            hasHoverStyles
          />
        </Link>
      </span>
      {onProspectSlideoutClick && (
        <StyledSlideout onClick={onProspectSlideoutClick} data-test="slide-out-btn">
          <FontAwesomeIcon icon="chevron-left" color="var(--sherpaBlue)" />
        </StyledSlideout>
      )}
      {showModal && renderModal()}
      {isProspectSequenceOpen ? (
        <ProspectToSequenceModal
          isOpen={isProspectSequenceOpen}
          toggle={toggleProspectSequence}
          prospect={{
            id: prospect.id,
            name: prospect.name,
          }}
          prospectSequence={prospect.activeProspectSequence}
        />
      ) : null}
    </>
  );
};

const StyledSlideout = styled.span`
  padding-top: 5px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  height: 25px;
  display: inline-block;
  margin: auto;
  width: 30px;
  text-align: center;
  background-color: var(--blueHighlight);
  cursor: pointer;
`;

export default ProspectSubActionButtons;
