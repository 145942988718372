import React from "react";
import Select, { components as reactSelectComponent } from "react-select";
import AsyncSelect from "react-select/async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WithPermissions from "./WithPermissions";

const themeStyles = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    dangerLight: "black",
    danger: "white",
    primary: "var(--gray)",
    primary75: "var(--gray)",
  },
});

const selectStyles = {
  option: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),
  className: () => "dropdown-option",
  control: (provided, state) => ({
    ...provided,
    border: "0",
    borderBottom: state.selectProps.error
      ? "2px solid var(--red)"
      : "2px solid var(--mediumGray)",
    borderRadius: "0",
    minHeight: "calc(1em + 1rem)",
    boxShadow: "none !important",
    cursor: "pointer",
    backgroundColor: !state.isDisabled ? "transparent" : "var(--lightGray)",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    stroke: "var(--sherpaBlue)",
    fill: "var(--sherpaBlue)",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0",
    fontSize: "1rem",
    height: "calc(1em + 1rem)",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "var(--disabledGray)",
    fontSize: "1rem",
  }),
  svg: (provided) => ({
    ...provided,
    color: "var(--sherpaBlue)",
    fill: "var(--sherpaBlue)",
    height: "15px",
  }),
  singleValue: (provided) => ({
    ...provided,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: "2000",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    className: "clear-indicator-custom",
  }),
};

function InputSelect({
  innerRef = null,
  components = {},
  isDisabled = false,
  ...restOfProps
}) {
  const CustomDropdownIndicator = ({ innerProps, innerRef }) => {
    return (
      <FontAwesomeIcon
        icon="chevron-down"
        color={!isDisabled ? "var(--black)" : "var(--disabledGray)"}
        ref={innerRef}
        {...innerProps}
      />
    );
  };
  // TODO: review to see why when setting it to document body, the prospect slide out closes immediately
  const menuPortalTarget = {
    menuPortalTarget: restOfProps.removeMenuPortalTarget ? null : document.body,
  };

  const Option = (props) => {
    const {
      data: { permission },
    } = props;
    return (
      <WithPermissions key={props.value} {...permission}>
        <reactSelectComponent.Option {...props} />
      </WithPermissions>
    );
  };

  return (
    <Select
      classNamePrefix="react-select"
      menuPlacement="auto"
      captureMenuScroll={false}
      isClearable={restOfProps.isClearable}
      data-test={restOfProps.dataTest}
      isDisabled={isDisabled}
      theme={themeStyles}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: CustomDropdownIndicator,
        Option,
        ...components,
      }}
      {...restOfProps}
      ref={innerRef}
      styles={{ ...selectStyles, ...restOfProps.styles }}
      {...menuPortalTarget} // ensures the options are visible outside the element
    />
  );
}

export const AsyncInputSelect = ({ innerRef, components, ...restOfProps }) => {
  const CustomDropdownIndicator = ({ innerProps, innerRef }) => {
    return (
      <FontAwesomeIcon
        icon="chevron-down"
        color={!restOfProps.isDisabled ? "var(--sherpaBlue)" : "var(--disabledGray)"}
        ref={innerRef}
        {...innerProps}
      />
    );
  };
  // TODO: review to see why when setting it to document body, the prospect slide out closes immediately
  const menuPortalTarget = {
    menuPortalTarget: restOfProps.removeMenuPortalTarget ? null : document.body,
  };

  return (
    <AsyncSelect
      classNamePrefix="react-select"
      captureMenuScroll={false}
      menuPlacement="auto"
      data-test={restOfProps.dataTest}
      theme={themeStyles}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: CustomDropdownIndicator,
        ...components,
      }}
      {...restOfProps}
      ref={innerRef}
      styles={{ ...selectStyles, ...restOfProps.styles }}
      {...menuPortalTarget} // ensures the options are visible outside the element
    />
  );
};

export default InputSelect;
