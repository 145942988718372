import React, { useMemo, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components/macro";

//component
import DataLoader from "../../common/components/DataLoader";
import {
  VirtualizedList,
  ListItem,
  SwipeableListItem,
} from "../../common/components/List";

//redux
import { campaignUnreadPollingThunk } from "../../main/store/campaignProspectStore/thunks";
import {
  getSelectedItem as getSelectedItemUnread,
  getMessagesPollingStatus as getMessagesPollingStatusUnread,
} from "../../main/store/uiStore/allUnreadPageView/selectors";
import {
  getSelectedItem as getSelectedItemSequence,
  getMessagesPollingStatus as getMessagesPollingStatusSequence,
} from "../../main/store/uiStore/sequencesView/selectors";
import { unreadTabActivateCountPolling } from "../../main/store/uiStore/allUnreadPageView/actions";
import { unreadTabActivateMessagesPolling } from "../../main/store/uiStore/allUnreadPageView/actions";
import {
  getUnreadStatus,
  getCampaignProspectsUnread,
} from "../../main/store/campaignProspectStore/selectors";
import {
  getSmsReminders,
  getCallReminders,
  getReminderStatus,
} from "../../main/store/SequencesStore/reminders/selectors";
import { path } from "../../main/store/campaignProspectStore/reducer";
import { uiGetActiveProspect } from "../../main/store/uiStore/campaignsPageDesktopView/campaignsList/filterData/selectors";

//helpers
import { isMobile } from "../../../detectDevice";
import {
  Fetching,
  Success,
  NO_UNREAD_MESSAGES,
  MESSAGES_POLLING_INTERVAL,
  NO_SMS_REMINDERS,
  NO_CALL_REMINDERS,
} from "module/common/helpers/variables";
import { useArrowNavigation } from "module/common/hooks/keyNav";

import UnreadPageNotificationModal from "./UnreadMessagePage/UnreadPageNotificationModal";

const Wrapper = styled.div`
  .itemBody {
    overflow: hidden;
  }

  .textXL.list-group-item-text,
  .textL.list-group-item-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  li {
    height: "${(props) => (!props.isMobile ? "auto !important" : "inherit")};";
  }
`;

const NewMessages = ({ prospectsToItemList, isSequence, id, backButtonText, prefix }) => {
  const currentView = (id) => {
    switch (id) {
      case "sms-reminder":
        return {
          selector: getSmsReminders,
          emptyResultsMessage: NO_SMS_REMINDERS,
        };
      case "call-reminder":
        return {
          selector: getCallReminders,
          emptyResultsMessage: NO_CALL_REMINDERS,
        };
      default:
        return {
          selector: getCampaignProspectsUnread,
          emptyResultsMessage: NO_UNREAD_MESSAGES,
        };
    }
  };
  const prospectsUnreadList_ = useSelector(currentView(id).selector);
  const activeProspectId = useSelector(uiGetActiveProspect);
  const selectedItem = useSelector(
    isSequence ? getSelectedItemSequence : getSelectedItemUnread
  );
  const dispatch = useDispatch();
  const status = useSelector(isSequence ? getReminderStatus : getUnreadStatus);
  const messagesPollingStatus = useSelector(
    isSequence ? getMessagesPollingStatusSequence : getMessagesPollingStatusUnread
  );
  const unreadStatus =
    prospectsUnreadList_.length === 0 && status === Fetching ? Fetching : Success;
  const prospectsUnreadList = useMemo(() => {
    return prospectsToItemList({
      prospectPath: [...path, "campaignProspectsUnread"],
      backButtonText,
      isSequence,
      prefix,
    })(prospectsUnreadList_);
  }, [prospectsToItemList, backButtonText, isSequence, prefix, prospectsUnreadList_]);

  useArrowNavigation(prospectsUnreadList, activeProspectId);

  useEffect(() => {
    dispatch(unreadTabActivateMessagesPolling(true));
    return () => dispatch(unreadTabActivateCountPolling(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(unreadTabActivateCountPolling(false));
    let interval;

    if (messagesPollingStatus) {
      dispatch(campaignUnreadPollingThunk());
      interval = setInterval(
        () => dispatch(campaignUnreadPollingThunk()),
        MESSAGES_POLLING_INTERVAL
      );
    }
    return () => {
      clearInterval(interval);
    };
  }, [dispatch, messagesPollingStatus]);

  const renderItem = useCallback(
    ({ index, style }) => {
      const item_ = prospectsUnreadList_[index];
      const item = prospectsUnreadList[index];
      return !isMobile ? (
        <ListItem
          key={item.id}
          id={item.id}
          style={style}
          isSelected={selectedItem === (item_.prospectId || `${item_.prospect.id}`)}
          item={{ ...item, minHeight: "60px" }}
        />
      ) : (
        <SwipeableListItem
          style={style}
          threshold=".25"
          actions={item.actions}
          key={`swipeable-item-${item.id}`}
        >
          <ListItem id={item.id} item={item} />
        </SwipeableListItem>
      );
    },
    [prospectsUnreadList, prospectsUnreadList_, selectedItem]
  );

  const renderData = useCallback(
    () => (
      <VirtualizedList
        key={"beep"}
        id="my-virtualized-list"
        items={prospectsUnreadList}
        renderItem={renderItem}
      />
    ),
    [renderItem, prospectsUnreadList]
  );

  return (
    <Wrapper isMobile={isMobile} data-test="message-list-wrapper">
      <DataLoader
        status={isSequence ? status : unreadStatus}
        data={prospectsUnreadList_}
        emptyResultsMessage={currentView(id).emptyResultsMessage}
        renderData={renderData}
      />
      <UnreadPageNotificationModal />
    </Wrapper>
  );
};

export default NewMessages;
