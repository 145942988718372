import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";

import { strDateToMoment } from "../../../common/helpers/dateUtils";
import { UnreadMsgIndicator } from "../../../common/components/UnreadMsg";

const ReminderContainer = styled.span`
  z-index: 2;
  display: flex;
  svg {
    font-size: 12px;
  }
`;

const ProspectUnreadAndReminderIcons = ({
  id,
  unreadCount = 0,
  reminderDateUtc,
  hasUnreadSms = false,
  showReminder = false,
  showUnread = false,
}) => {
  const reminderLocalDate = reminderDateUtc
    ? strDateToMoment(reminderDateUtc).format("MMMM D, YYYY [at] h:mm a")
    : null;
  return (
    <div className="d-flex flex-column h-100 justify-content-around my-1">
      <UnreadMsgIndicator
        unreadCount={unreadCount}
        readable={showUnread}
        isRead={hasUnreadSms}
      />
      {showReminder ? (
        <ReminderContainer>
          <FontAwesomeIcon
            id={`agent-reminder-${id}`}
            color="var(--sherpaBlue)"
            icon="bell"
            size="lg"
          />
          <UncontrolledTooltip target={`agent-reminder-${id}`}>
            Reminder set for {reminderLocalDate}
          </UncontrolledTooltip>
        </ReminderContainer>
      ) : null}
    </div>
  );
};

export default ProspectUnreadAndReminderIcons;
