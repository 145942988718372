import { produce } from "immer";
import { Initial, Error, Success, Fetching } from "module/common/helpers/variables";
import { IState } from "./interface";
import {
  SET_PAYMENT_INFO,
  FETCH_PAYMENT_INFO,
  FETCH_PAYMENT_INFO_ERROR,
  SET_PAYMENT_INFO_STATUS,
} from "./action";

// reducer
const initialState: IState = {
  paymentInfo: {
    expired: null,
    last4: "",
    maskedNumber: "",
  },
  status: Initial,
};
const reducer = produce((draft: IState, action: any) => {
  switch (action.type) {
    case FETCH_PAYMENT_INFO: {
      draft.status = Fetching;
      break;
    }
    case SET_PAYMENT_INFO: {
      draft.paymentInfo = action.payload;
      draft.status = Success;
      break;
    }
    case FETCH_PAYMENT_INFO_ERROR: {
      draft.status = Error;
      break;
    }
    case SET_PAYMENT_INFO_STATUS: {
      draft.status = action.payload;
      break;
    }
  }
}, initialState);

export default reducer;
