import {
  SET_SUPPORT_ITEMS,
  SET_SUPPORT_ITEMS_ERROR,
  SET_SUPPORT_ITEMS_STATUS,
} from "./actions";
import { IState } from "./interfaces";
import { Fetching, Success, FetchError } from "module/common/helpers/variables";
import { produce } from "immer";

// support reducer
export const initialState: IState = {
  items: [],
  error: "",
  status: Fetching,
};
const reducer = produce((draft: IState, action: any) => {
  switch (action.type) {
    case SET_SUPPORT_ITEMS: {
      draft.items = action.payload;
      draft.status = Success;
      break;
    }
    case SET_SUPPORT_ITEMS_STATUS: {
      draft.status = action.payload;
      break;
    }
    case SET_SUPPORT_ITEMS_ERROR: {
      draft.error = action.payload;
      draft.status = FetchError;
      break;
    }
  }
}, initialState);

export default reducer;
