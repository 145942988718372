import {
  campaignProspectList,
  campaignProspectListNextPage,
  campaignProspectUnread,
  patchCampaignProspects,
  getCampaignProspectUnreadCount,
} from "./api";
import {
  fetchCampaignProspects,
  fetchCampaignProspectsFailure,
  fetchCampaignProspectsSuccess,
  fetchMoreCampaignProspects,
  fetchCampaignProspectsUnread,
  removeCampaignProspect as removeCampaignProspectAction,
  updateCampaignProspectSuccess,
  updateCampaignProspectUnreadCount,
  setUnreadMessagesStatus,
} from "./actions";
import { updateProspectList, updateProspectSuccess } from "../prospectStore/actions";
import {
  trimProspectsAndMessages,
  getMessageSortOrder,
  filterCampaignProspectsUnread,
} from "./transformers";
import { arrayToMapIndex } from "../utils";
import { populateProspectMessages } from "../ProspectDetails/messages/actions";
import { Dispatch } from "redux";
import { addNewToast } from "../Global/Toasts/actions";
import { DocumentVisibilityState, Fetching } from "module/common/helpers/variables";
import { getPartialProspects } from "./utils";

const shouldFetch = (campaign: any, force: boolean) => {
  return !campaign || campaign.length === 0 || force;
};

export const campaignProspectSearch =
  (campaignId: number, options: any) =>
  (dispatch: Dispatch, getState: (...args: any[]) => any) => {
    const { force = false, page = null, filter, concatResults = false } = options;

    let apiParams = "";
    const { campaignProspectStore: { campaignProspects = {} } = {} } = getState() || {
      campaignProspectStore: { campaignProspects: {} },
    };

    // Fetch if 'forced' or if don't have anything
    if (shouldFetch(campaignProspects[campaignId], force)) {
      // construct filter
      if (filter) {
        apiParams = `&${filter.name}=${filter.value}`;
      }

      // check if page is set
      if (page) {
        apiParams += `&page${page}`;
      }

      dispatch(fetchCampaignProspects(true));
      return campaignProspectList(campaignId, apiParams)
        .then(({ data }) => {
          const [prospects, campaignProspects, messages] = trimProspectsAndMessages(
            data.results
          );
          dispatch(
            fetchCampaignProspectsSuccess({
              ...data,
              concatResults,
              results: { [campaignId]: campaignProspects },
            })
          );
          dispatch(
            updateProspectList({
              results: arrayToMapIndex("id", prospects),
              next: null,
              previous: null,
            })
          );
          dispatch(populateProspectMessages(messages));
        })
        .catch(() => {
          dispatch(fetchCampaignProspectsFailure(true));
        });
    }
  };

export const campaignProspectsNextPage =
  (campaignId: number) => (dispatch: Dispatch, getState: (...args: any[]) => any) => {
    const {
      campaignProspectStore: { next = null, isLoadingMore, campaignProspects },
    } = getState();
    const existingCampaignProspects = campaignProspects[campaignId];

    if (next && !isLoadingMore) {
      dispatch(fetchMoreCampaignProspects(true));
      return campaignProspectListNextPage(next)
        .then(({ data }) => {
          const [prospects, campaignProspects, messages] = trimProspectsAndMessages(
            data.results
          );
          dispatch(
            fetchCampaignProspectsSuccess({
              ...data,
              concatResults: true,
              results: {
                [campaignId]: [...existingCampaignProspects, ...campaignProspects],
              },
            })
          );
          dispatch(
            updateProspectList({
              results: arrayToMapIndex("id", prospects),
              next: null,
              previous: null,
            })
          );
          dispatch(populateProspectMessages(messages));
        })
        .catch(() => {
          // TODO: show error message?
        });
    }
  };

export const campaignUnreadPollingThunk =
  (forceUpdate: boolean = false) =>
  (dispatch: Dispatch) => {
    if (document.visibilityState === DocumentVisibilityState.HIDDEN) return;
    dispatch(setUnreadMessagesStatus(Fetching));
    campaignProspectUnread()
      .then(({ data }) => {
        const filteredData = filterCampaignProspectsUnread(data);
        const sortedData = getMessageSortOrder(filteredData);
        const prospects = getPartialProspects(filteredData);

        const updatedCount = filteredData.length;

        // Badge API to add the badge with unread count on the PWA
        if (navigator.setAppBadge) {
          navigator.setAppBadge(updatedCount);
        }
        dispatch(
          fetchCampaignProspectsUnread({
            data: sortedData,
            count: updatedCount,
            forceUpdate,
          })
        );
        dispatch(
          updateProspectList({
            results: arrayToMapIndex("id", prospects),
            next: null,
            previous: null,
            overridePages: false,
          })
        );
      })
      .catch(() =>
        dispatch(
          addNewToast({ message: "Error fetching new unread messages", color: "danger" })
        )
      );
  };

export const getUnreadMessagesCount = () => (dispatch: Dispatch) => {
  if (document.visibilityState === DocumentVisibilityState.HIDDEN) return;
  return getCampaignProspectUnreadCount()
    .then(({ data }) => {
      // Badge API to add the badge with unread count on the PWA
      if (navigator.setAppBadge) {
        navigator.setAppBadge(data.count);
      }
      dispatch(updateCampaignProspectUnreadCount(data.count));
    })
    .catch(() =>
      dispatch(
        addNewToast({ message: "Error fetching new unread messages", color: "danger" })
      )
    );
};

export const removeCampaignProspect =
  (id: number, shouldUpdate: boolean) =>
  (dispatch: Dispatch, getState: (...args: any[]) => any) => {
    if (!shouldUpdate) {
      return;
    }
    dispatch(updateProspectSuccess({ id, hasUnreadSms: false }));

    const campaignProspectStore = getState().campaignProspectStore;

    // Badge API to add the badge with unread count on the PWA
    if (navigator.setAppBadge && campaignProspectStore?.campaignProspectsUnreadCount) {
      navigator.setAppBadge(campaignProspectStore.campaignProspectsUnreadCount - 1);
    }
    // remove prospect from the campaignProspectUnread collection
    dispatch(removeCampaignProspectAction(id));
  };

export const campaignProspectMarkAsViewed =
  (campaignProspect: any) => (dispatch: Dispatch) => {
    dispatch(
      updateCampaignProspectSuccess({
        ...campaignProspect,
        hasBeenViewed: true,
        prospect: campaignProspect.prospect.id,
      })
    );
    return patchCampaignProspects(campaignProspect.id, { hasBeenViewed: true }).catch(
      () =>
        dispatch(addNewToast({ message: "Something went wrong when marking as viewed." }))
    );
  };
