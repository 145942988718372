import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button, Label, Input, InputGroupAddon } from "reactstrap";
import { isMobile } from "../../../../../../detectDevice";

import InputGroupBorder from "module/common/components/InputGroupBorder";
import Modal from "module/common/components/Modal";
import InputSelect from "module/common/components/InputSelect";
import IconBg from "module/common/components/IconBg";
import { DatetimePickerFullScreen } from "module/common/components/DatetimePicker";

import { selectUserData } from "module/main/store/Auth/selectors";
import { agentSelector } from "module/main/store/uiStore/prospectDetailsView/selectors";
import {
  prospectSetReminder,
  prospectRemoveReminder,
} from "module/main/store/prospectStore/thunks";
import { getDateFormat } from "module/main/store/Company/selectors";

const StyledDatetime = styled(DatetimePickerFullScreen)`
  width: 100%;
`;

const RenderAgentOptions = (agents) => {
  return agents.map((agent) => ({
    label: `${agent.fullName} - ${agent.email}`,
    value: agent.id,
  }));
};

const ReminderSelector = ({ prospect }) => {
  const [display, setDisplay] = useState("");
  const [modal, setModal] = useState(false);
  const [reminder, setReminder] = useState();
  const [agent, setAgent] = useState();
  const [disabled, setDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const dateFormat = useSelector(getDateFormat);
  const user = useSelector(selectUserData);
  const agentList = useSelector(agentSelector);

  const agents = RenderAgentOptions(agentList);

  const colSize = !isMobile ? 6 : 12;
  const modalSize = !isMobile ? "lg" : "sm";

  const inputProps = {
    placeholder: "Select a date and time for this reminder",
    className: "form-control",
  };

  const toggle = () => {
    setModal(!modal);
  };

  const resetForm = () => {
    if (prospect.reminderAgent) {
      agents.forEach((agentOption) => {
        if (agentOption.value === prospect.reminderAgent) {
          setAgent(agentOption);
          return;
        }
      });
      const dt = moment(prospect.reminderDateLocal);
      setReminder(dt);
    } else {
      setAgent({ label: `${user.fullName} - ${user.email}`, value: user.profile.id });
      setReminder(null);
    }
  };

  const onAgentChange = (option) => {
    setAgent(option);
  };

  const submit = () => {
    setIsSubmitting(true);
    const payload = {
      agent: agent.value,
      time: reminder.utc().format(),
    };
    dispatch(prospectSetReminder(prospect.id, payload));
    setIsSubmitting(false);
    toggle();
  };

  useEffect(() => {
    if (prospect.reminderAgent) {
      let email = "";
      agentList.forEach((a) => {
        if (a.id === prospect.reminderAgent) {
          email = a.email;
          return;
        }
      });
      const dt = moment(prospect.reminderDateLocal);
      const date = dt.format(dateFormat);
      const time = dt.format("hh:mm a");
      setDisplay(`${date} @ ${time} (${email})`);
    } else {
      setDisplay("");
    }
  }, [agentList, agents, dateFormat, prospect]);

  useEffect(() => {
    setDisabled(!reminder);
  }, [reminder]);

  const btnOptions = [
    {
      type: "button",
      name: "Cancel",
      onClick: toggle,
      color: "secondary",
    },
    {
      color: "primary",
      disabled,
      loading: isSubmitting,
      onClick: submit,
      className: "pr-4 pl-4",
      "data-test": "reminder-save-button",
      name: "Save",
    },
  ];
  return (
    <>
      <Label>Reminder</Label>
      <InputGroupBorder className="mb-2">
        <Input
          value={display}
          data-test="reminder-input"
          placeholder="Add a reminder"
          readOnly
          onClick={() => {
            resetForm();
            toggle();
          }}
        />
        <InputGroupAddon addonType="append">
          <Button className="p-0" color="link">
            <IconBg
              onClick={
                prospect.reminderAgent
                  ? () => dispatch(prospectRemoveReminder(prospect))
                  : null
              }
              icon={prospect.reminderAgent ? "times-circle" : "bell"}
              faSize="lg"
            />
          </Button>
        </InputGroupAddon>
      </InputGroupBorder>

      <Modal
        isOpen={modal}
        size={modalSize}
        toggle={() => toggle()}
        title="Reminder"
        btnOptions={btnOptions}
      >
        <Form>
          <Row>
            <Col md={colSize} className="pt-2">
              <Label>Agent</Label>
              <InputSelect
                name="agent"
                id="agent"
                value={agent}
                options={agents}
                placeholder="Select which agent will get this reminder"
                onChange={onAgentChange}
              />
            </Col>
            <Col md={colSize} className="pt-2">
              <Label>Date and Time</Label>
              <InputGroupBorder className="mb-2" data-test="reminder-date-input">
                <StyledDatetime
                  inputProps={inputProps}
                  timeFormat="hh:mm a"
                  value={reminder}
                  onChange={(dt) => setReminder(dt)}
                />
              </InputGroupBorder>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

ReminderSelector.propTypes = {
  /** The prospect that will have a reminder placed on it. */
  prospect: PropTypes.object,
  /** A list of all agents ready for use in a select input field (label, value). */
  agents: PropTypes.arrayOf(PropTypes.object),
};

export default ReminderSelector;
