import axios from "axios";

import { downloadBlob } from "module/common/utils/utils";

export const downloadExportFile = async (file, name) => {
  const response = await axios({
    url: file,
    method: "GET",
    responseType: "blob",
  });
  const blob = response.data;
  downloadBlob({ blob: blob, fileName: name });
};
