import { createAction } from "../../redux-helpers";

export const FETCH_PROPERTY_TAGS_SUCCESS = "FETCH_PROPERTY_TAGS_SUCCESS";
export const fetchPropertyTagsSuccess = createAction(FETCH_PROPERTY_TAGS_SUCCESS);

export const FETCH_PROPERTY_TAGS_STATUS = "FETCH_PROPERTY_TAGS_STATUS";
export const fetchPropertyTagsStatus = createAction(FETCH_PROPERTY_TAGS_STATUS);

export const UPDATE_PROPERTY_TAG = "UPDATE_PROPERTY_TAG";
export const updatePropertyTag = createAction(UPDATE_PROPERTY_TAG);

export const REMOVE_PROPERTY_TAG = "REMOVE_PROPERTY_TAG";
export const removePropertyTag = createAction(REMOVE_PROPERTY_TAG);

export const UPDATE_PROPERTY_TAG_SORT_ORDER = "UPDATE_PROPERTY_TAG_SORT_ORDER";
export const updatePropertyTagSortOrder = createAction(UPDATE_PROPERTY_TAG_SORT_ORDER);
