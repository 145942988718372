import React from "react";

// components
import { identity } from "module/common/utils/utils";
import { validateURL } from "../../../helpers/utils";
import TemplateTextArea from "./TemplateTextArea";
import FormRenderer from "../../FormRenderer";

const formFields = [
  [
    {
      name: "question",
      label: "Question",
      component: "input",
      inputProps: {
        placeholder: "question",
      },
      rules: {
        required: "Required",
        maxLength: { value: 160, message: "Must be at most 160 characters long" },
      },
    },
  ],
  [
    {
      name: "message",
      label: "Message",
      component: "textarea",
      rules: {
        required: "Required",
        maxLength: { value: 320, message: "Must be at most 320 characters long" },
        validate: (value) => !validateURL(value) || "Message can't contain URL links.",
      },
    },
  ],
];

export const handleError = ({ response, setError, setNonFieldErrors }) => {
  setNonFieldErrors(response.data.nonFieldErrors || []);
  Object.entries(response.data).forEach(([field, errors]) => {
    setError(
      errors.map((error) => ({
        type: "validate",
        name: field,
        message: error,
      }))
    );
  });
};

const QuickRepliesForm = ({
  closeModal = () => null,
  submitButtonName = "Submit",
  initialValues = {},
  submitHandler = () => {},
  disableButtonOnSubmit = true,
  errorHandler = handleError,
  toRequestPayload = identity,
  groupedFields = formFields,
}) => {
  const onSubmit = ({ data, setSubmittingState, setError, setServerError }) => {
    submitHandler(toRequestPayload(data), initialValues)
      .then(() => {
        if (disableButtonOnSubmit) setSubmittingState(false);
        closeModal();
      })
      .catch(({ response }) => {
        setSubmittingState(false);
        errorHandler({
          response,
          setNonFieldErrors: setServerError,
          setError,
        });
      });
  };

  const overrides = {
    message: {
      OverrideFieldControl: TemplateTextArea,
    },
  };

  return (
    <div data-test="new-sms-template-form">
      <FormRenderer
        fields={groupedFields}
        overrides={overrides}
        buttonProps={{
          "data-test": "quick-reply-form-submit",
          name: submitButtonName,
          size: "md",
          className: "pr-4 pl-4",
        }}
        onSubmit={onSubmit}
        defaultValues={initialValues}
      />
    </div>
  );
};

export default QuickRepliesForm;
