import { createAction } from "../redux-helpers";

export const FETCH_QUICK_REPLIES = "FETCH_QUICK_REPLIES";
export const FETCH_PROSPECT_QR_LIST = "FETCH_PROSPECT_QR_LIST";
export const UPDATE_QUICK_REPLIES = "UPDATE_QUICK_REPLIES";
export const UPDATE_QUICK_REPLIES_SORT_ORDER = "UPDATE_QUICK_REPLIES_SORT_ORDER";
export const REMOVE_QUICK_REPLY = "REMOVE_QUICK_REPLY";
export const HANDLE_API_ERROR = "HANDLE_API_ERROR";
export const HANDLE_API_STATUS = "HANDLE_API_STATUS";

export const fetchQuickReplies = createAction(FETCH_QUICK_REPLIES);
export const fetchProspectQRList = createAction(FETCH_PROSPECT_QR_LIST);
export const updateQuickReplies = createAction(UPDATE_QUICK_REPLIES);
export const updateQuickReplySortOrder = createAction(UPDATE_QUICK_REPLIES_SORT_ORDER);
export const removeQuickReply = createAction(REMOVE_QUICK_REPLY);
export const handleApiError = createAction(HANDLE_API_ERROR);
export const handleApiStatus = createAction(HANDLE_API_STATUS);
