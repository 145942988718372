interface Brand {
  brand: string;
  version: string;
}

interface MyNavigator extends Navigator {
  userAgentData?: {
    platform: string;
    mobile: false;
    brands: Brand[];
  };
}

export const isMobile =
  (navigator as MyNavigator).userAgentData?.mobile ||
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(
    navigator.userAgent
  );

export default isMobile;

export const isMac =
  (navigator as MyNavigator).userAgentData?.platform.indexOf("mac") !== -1;
