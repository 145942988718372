import { createAction } from "../redux-helpers";
export const SET_FETCH_CAMPAIGNS_BATCH_PROSPECTS = "SET_FETCH_CAMPAIGNS_BATCH_PROSPECTS";
export const SET_FETCH_CAMPAIGNS_BATCH_PROSPECTS_ERROR =
  "SET_FETCH_CAMPAIGNS_BATCH_PROSPECTS_ERROR";
export const CLEAR_FETCH_CAMPAIGNS_BATCH_PROSPECTS_ERROR =
  "CLEAR_FETCH_CAMPAIGNS_BATCH_PROSPECTS_ERROR";
export const RESET_CAMPAIGNS_BATCH_PROSPECTS_DATA =
  "RESET_CAMPAIGNS_BATCH_PROSPECTS_DATA";
export const UPDATE_CAMPAIGNS_BATCH_PROSPECTS_STATUS =
  "UPDATE_CAMPAIGNS_BATCH_PROSPECTS_STATUS";

export const setFetchedCampaignsBatchProspects = createAction(
  SET_FETCH_CAMPAIGNS_BATCH_PROSPECTS
);

export const resetCampaignBatchProspects = createAction(
  RESET_CAMPAIGNS_BATCH_PROSPECTS_DATA
);

export const setCampaignsBatchProspectsError = createAction(
  SET_FETCH_CAMPAIGNS_BATCH_PROSPECTS_ERROR
);

export const clearCampaignsBatchProspectsError = createAction(
  CLEAR_FETCH_CAMPAIGNS_BATCH_PROSPECTS_ERROR
);

export const updateCampaignsBatchProspectsStatus = createAction(
  UPDATE_CAMPAIGNS_BATCH_PROSPECTS_STATUS
);
