import React from "react";

// components
import Modal from "../../../../../common/components/Modal";
import { Container } from "../../styles";

const MessageQuickRepliesMobile = ({
  isOpen,
  toggle,
  renderSearchInput,
  renderQuickRepliesList,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} title="Select Quick Reply" size="md">
      <div className="pl-3 pr-3 w-100">{renderSearchInput()}</div>
      <Container data-test="container">{renderQuickRepliesList()}</Container>
    </Modal>
  );
};

export default MessageQuickRepliesMobile;
