import { createSelector as createCachedSelector } from "reselect";

import { createSelectorContext } from "../../../redux-helpers";
import { path } from "./reducers";
import { prospectsResults } from "../../../prospectStore/selectors";
import { propertiesList } from "../../../propertyStore/selectors";
import { getPropertyTags } from "../../../Tags/property/selectors";

const createSelector = createSelectorContext(path);

export const smartStackerActiveTab = createSelector("activeTab");
export const smartStackerStatus = createSelector("status");
export const smartStackerCounts = createSelector("counts");
export const smartStackerTotals = createSelector("totals");
export const smartStackerPropertiesById = createSelector("propertiesById");
export const smartStackerProspectsById = createSelector("prospectsById");
export const getSearchAfter = createSelector("searchAfter");
export const smartStackerActiveProspect = createSelector("activeProspectId");

export const smartStackerPropertiesArray = createCachedSelector(
  propertiesList,
  smartStackerPropertiesById,
  (properties: any, propertiesById: any) => {
    return propertiesById
      .map((id: number) => {
        const property = properties[id];
        return property;
      })
      .filter((property: any) => property);
  }
);

export const smartStackerProspectsArray = createCachedSelector(
  prospectsResults,
  smartStackerProspectsById,
  (prospects: any, prospectsById: any) => {
    return prospectsById.map((id: number) => {
      const prospect = prospects[id];
      return prospect;
    });
  }
);

export const getSinglePropertyTags = createCachedSelector(
  propertiesList,
  getPropertyTags,
  (_: any, id: number) => id,
  (properties: any, propertyTags: any, id: number) => {
    const currentRecord = properties[id] || [];

    const currentTags = currentRecord.tags || [];

    const popoverTags = currentTags.flatMap((datum: any) => {
      const foundItem = propertyTags.find((item: { id: number }) => item.id === datum);

      return foundItem
        ? [
            {
              value: foundItem.id,
              label: foundItem.name,
              distressIndicator: foundItem.distressIndicator,
            },
          ]
        : [];
    });

    return popoverTags;
  }
);
