import { useState, useEffect } from "react";

const useDraggable = (data, sortAction, accordion) => {
  const [items, setItems] = useState(data);
  const [draggedItem, setDraggedItem] = useState({});
  const [oldIdx, setOldIdx] = useState(null);

  useEffect(() => {
    setItems(data);
  }, [data]);

  const onDragStart = (e, index) => {
    setDraggedItem(items[index]);
    setOldIdx(index);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
  };

  const onDragOver = (index) => {
    const draggedOverItem = items[index];
    // if the item is dragged over itself, ignore
    if (draggedItem === draggedOverItem) {
      return;
    }

    setItems((items_) => {
      const _items = items_.filter((itm) => itm.id !== draggedItem.id);
      _items.splice(index, 0, draggedItem);
      return _items;
    });
  };

  const onDragEnd = () => {
    const itemIdx = items.findIndex((item) => draggedItem.id === item.id);

    setDraggedItem(null);
    setOldIdx(null);

    if (itemIdx !== -1 && !accordion) sortAction(oldIdx, itemIdx);

    if (itemIdx !== -1 && accordion) {
      sortAction(accordion, draggedItem.key, itemIdx);
    }
  };

  return [items, draggedItem, onDragStart, onDragOver, onDragEnd];
};

export default useDraggable;
