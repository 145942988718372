import Axios from "../../../common/utils/axiosConfig";

export const getMarkets = () => Axios.get("/markets/");
export const patchMarket = (id: number, payload: any) =>
  Axios.patch(`/markets/${id}/`, payload);

export const getMarketPhoneAvailability = (id: number, qt: any) =>
  Axios.get(`/markets/${id}/check_number_availability/?quantity=${qt}&best_effort=true`);

export const postMarketPurchasePhoneNumbers = (id: number, payload: any) =>
  Axios.post(`/markets/${id}/purchase_numbers/`, { ...payload, bestEffort: true });
