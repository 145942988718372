import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { SQS } from "module/common/helpers/variables";

//redux-related
import { fetchSequence } from "../../../../../main/store/SequencesStore/thunks";
import { fetchAllSequences } from "../../../../../main/store/SequencesStore/thunks";
import { deleteProspectSequenceAction } from "../../../../../main/store/SequencesStore/prospects/thunks";
import { updateReminderSuccess } from "../../../../../main/store/SequencesStore/reminders/actions";
import { getSelectedReminder } from "../../../../../main/store/SequencesStore/reminders/selectors";
import { ProspectCycleContext } from "../../../../../common/helpers/context";
import { remindersTabResetState } from "../../../../../main/store/uiStore/sequencesView/actions";
import { removeCurrentSequence } from "../../../../../main/store/SequencesStore/sequenceDetails/actions";
//Components
import Modal from "../../../../../common/components/Modal";
import AddEditModal from "./AddEditModal";
import useModal from "module/common/hooks/useModal";

const ProspectToSequenceModal = ({
  isOpen,
  toggle,
  prospectSequence,
  prospect,
  currentTab = SQS,
}) => {
  const [isWarningOpen, , toggleWarningOpen] = useModal();
  const [isAddEditOpen, setAddEditOpen, toggleAddEditOpen] = useModal();
  const [isLoading, setIsLoading] = useState();
  const [currentSelect, setCurrentSelect] = useState();
  const dispatch = useDispatch();

  //SMS History Modal related
  const { setSmsHistoryModalOpen } = useContext(ProspectCycleContext) || {};
  const { type } = useParams();
  const { id: prospectSequenceStepId, step: { stepType } = {} } =
    useSelector(getSelectedReminder) || {};

  useEffect(() => {
    if (currentSelect) dispatch(fetchSequence(currentSelect.id));
  }, [dispatch, currentSelect]);

  useEffect(() => {
    if (prospectSequence)
      setCurrentSelect({
        id: prospectSequence.sequenceId,
        name: prospectSequence.name,
      });
    dispatch(fetchAllSequences());
  }, [dispatch, prospectSequence]);

  useEffect(() => {
    return () => dispatch(removeCurrentSequence());
  }, [dispatch]);

  const handleRemove = async () => {
    setIsLoading(true);
    await dispatch(deleteProspectSequenceAction(prospect.id, prospectSequence.id)).then(
      (response) => {
        response && toggleWarningOpen();
        setIsLoading(false);
      }
    );
    if (type === SQS) {
      dispatch(updateReminderSuccess({ stepType, prospectSequenceStepId }));
      toggle();
      setSmsHistoryModalOpen(false);
      dispatch(remindersTabResetState());
    }
  };

  const checkIcon = (
    <FontAwesomeIcon icon="calendar-check" color="var(--green)" className="mr-1" />
  );
  const trashIcon = <FontAwesomeIcon icon="trash" color="white" className="mr-1" />;
  const penIcon = <FontAwesomeIcon icon="pen" className="mr-1" />;

  const RemoveWarningModal = ({
    isOpen,
    toggle,
    prospect,
    sequence,
    handleRemove,
    isLoading,
  }) => (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title="Are you sure?"
      size="md"
      btnOptions={[
        {
          name: "No, don't remove",
          color: "dark",
          onClick: toggle,
        },
        {
          name: "Yes, remove",
          loading: isLoading,
          color: "primary",
          onClick: handleRemove,
        },
      ]}
    >
      <p>
        Removing <span className="font-weight-bold">{prospect}</span> from{" "}
        <span className="font-weight-bold">{sequence}</span> is a permanent action that
        cannot be undone.
      </p>
    </Modal>
  );

  return isOpen ? (
    <>
      {prospectSequence ? (
        <Modal isOpen={isOpen} toggle={toggle} title="Your Selected Sequence" size="md">
          <div className="font-weight-bold h5 mb-3">
            {checkIcon} Sequence activated
            {prospectSequence && `: ${prospectSequence.name}`}
          </div>
          <div className="d-flex justify-content-end align-items-center">
            {prospectSequence && (
              <Button color="dark" className="mr-1" onClick={toggleWarningOpen}>
                {trashIcon}
                Remove
              </Button>
            )}
            <Button color="primary" outline className="mr-1" onClick={toggleAddEditOpen}>
              {penIcon}
              <span>Edit</span>
            </Button>
            <Button color="primary" className="mr-1" onClick={toggle}>
              Done
            </Button>
          </div>
        </Modal>
      ) : null}
      <RemoveWarningModal
        isOpen={isWarningOpen}
        toggle={toggleWarningOpen}
        prospect={prospect?.name}
        sequence={prospectSequence?.name}
        handleRemove={handleRemove}
        isLoading={isLoading}
      />
      <AddEditModal
        isOpen={isAddEditOpen}
        toggle={toggleAddEditOpen}
        setOpen={setAddEditOpen}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        prospectSequence={prospectSequence}
        prospect={prospect}
        currentTab={currentTab}
      />
    </>
  ) : null;
};

export default ProspectToSequenceModal;
