import { createSelector as createCachedSelector } from "reselect";
import { createSelectorContext } from "../../redux-helpers";
import { rootPath } from "../reducer";

import { Features } from "module/common/helpers/variables";
import { companyHasFeature } from "./utils";
import { IFeature } from "../interfaces";

const createSelector = createSelectorContext([...rootPath, "features"]);

export const selectCompanyFeatures = createSelector("features");
export const selectCompanyFeaturesStatus = createSelector("status");

export const companyHasMultiTraceAccess = createCachedSelector(
  selectCompanyFeatures,
  (features: IFeature[]) => companyHasFeature(features, Features.MultiTrace)
);

export const companyHasUpgradeAccess = createCachedSelector(
  selectCompanyFeatures,
  (features: IFeature[]) => companyHasFeature(features, Features.UpgradeAccess)
);

export const companyHasFloridaCompliance = createCachedSelector(
  selectCompanyFeatures,
  (features: IFeature[]) => companyHasFeature(features, Features.FloridaCompliance)
);

export const companyHasNewMarketDisabled = createCachedSelector(
  selectCompanyFeatures,
  (features: IFeature[]) => companyHasFeature(features, Features.NewMarketDisabled)
);

export const companyHasProfileStatsEnabled = createCachedSelector(
  selectCompanyFeatures,
  (features: IFeature[]) => companyHasFeature(features, Features.ProfileStatsEnabled)
);

export const companyHasCampaignStatsStatsEnabled = createCachedSelector(
  selectCompanyFeatures,
  (features: IFeature[]) => companyHasFeature(features, Features.CampaignStatsEnabled)
);

export const companyHasNewSkipTraceOptionsEnabled = createCachedSelector(
  selectCompanyFeatures,
  (features: IFeature[]) => companyHasFeature(features, Features.NewSkipTraceOptions)
);

export const companyHasAchPaymentMethod = createCachedSelector(
  selectCompanyFeatures,
  (features: IFeature[]) => companyHasFeature(features, Features.AchPaymentMethod)
);
