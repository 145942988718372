import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUserData } from "../../main/store/Auth/selectors";
import { UserData } from "module/main/store/Auth/interfaces";

const id = "68ba8167";
const url = "https://cdn.wootric.com/wootric-sdk.js";

declare global {
  interface Window {
    wootric: (...args: any[]) => void;
  }
}

const WootricWidget = () => {
  const userData: UserData = useSelector(selectUserData);

  useEffect(() => {
    const setupScript = document.createElement("script");
    setupScript.type = "text/javascript";
    setupScript.async = true;

    setupScript.innerHTML = `
            window.wootricSettings = {
              ${
                userData
                  ? `email: '${userData.email}',
                   created_at: '${userData.profile?.createdTimestamp}',`
                  : ""
              }
                account_token: 'NPS-${id}'
            };
           `;
    document.body.appendChild(setupScript);

    return () => {
      document.body.removeChild(setupScript);
    };
  }, [userData]);

  useEffect(() => {
    const beacon = document.createElement("script");
    beacon.type = "text/javascript";
    beacon.async = true;

    beacon.src = url;
    beacon.onload = function () {
      window.wootric && window.wootric("run");
    };
    document.body.appendChild(beacon);

    return () => {
      document.body.removeChild(beacon);
    };
  }, [userData]);

  return null;
};

export default WootricWidget;
