import { IMarket } from "module/main/store/Markets/interfaces";
import {
  selectActiveMarkets,
  selectHasActiveMarkets,
  selectMarketsWithCampaigns,
} from "module/main/store/Markets/selectors";
import { uiGetCampaigns } from "module/main/store/uiStore/campaignsPageView/selectors";
import { useSelector } from "react-redux";

export const useSmsFlow = () => {
  const activeMarkets: IMarket[] = useSelector(selectActiveMarkets);
  const activeCampaigns = useSelector(uiGetCampaigns);

  const hasMarkets = activeMarkets.length > 0;
  const hasCampaigns = activeCampaigns.length > 0;

  return {
    canIntegrateSms: true,
    hasSmsCampaigns: hasMarkets && hasCampaigns,
  };
};

export const useIsSelfManagedUser = () => {
  const hasActiveMarkets = useSelector(selectHasActiveMarkets);
  const usedMarkets = useSelector(selectMarketsWithCampaigns);

  return hasActiveMarkets || usedMarkets.length > 0;
};
