import { getIn } from "module/common/utils/utils";
import { Dispatch } from "redux";
import { addNewToast } from "./Global/Toasts/actions";

// Maps an array, so the index of each element is the value of the "indexKey"
export const arrayToMapIndex = (indexKey: string, array: Array<any>) => {
  return array.reduce((acc: any, elem: any) => {
    acc[elem[indexKey]] = elem;
    return acc;
  }, {});
};

export const mapIndexToArray = (map: any) => {
  return Object.keys(map).map((key) => map[key]);
};

export const groupBy = (fn: any, data: Array<any>) => {
  return data.reduce((acc: any, datum: any) => {
    const key = fn(datum);
    if (acc[key]) {
      acc[key].push(datum);
    } else {
      acc[key] = [datum];
    }
    return acc;
  }, {});
};

export const groupByArray = (path: Array<string>, array: Array<any>) => {
  const groupByFn = (datum: any) => getIn(path, datum);
  const mapIndexed = groupBy(groupByFn, array);

  return Object.keys(mapIndexed).map((key) => mapIndexed[key]);
};

// Merges two maps that are indexed by id
export const mergeProspects = (
  currentList: any,
  newList: any,
  shouldOverwrite: boolean
) => {
  return Object.values(newList).reduce(
    (destination: any, newProspect: any) => {
      const { id } = newProspect;

      if (!shouldOverwrite && destination[id]?.partial === false && newProspect.partial) {
        return destination;
      }

      if (destination[id]) {
        destination[id] = { ...destination[id], ...newProspect };
      } else {
        destination[id] = newProspect;
      }

      return destination;
    },
    { ...currentList }
  );
};

export const handleError = (data: any, dispatch: Dispatch) => {
  const { message, error, action } = data;
  if (action && error) {
    dispatch(action(error));
  }
  dispatch(
    addNewToast({
      message:
        message ||
        error ||
        "Sorry we were unable to complete that request. Please try again  later.",
      color: "danger",
    })
  );
};
