import React from "react";
import styled from "styled-components/macro";

const UnreadIndicator = styled.div`
  width: 100%;
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  --size: calc(4px + 0.9rem);
  flex: 0 0 50%;
  font-size: calc(var(--size) * 0.5);

  &:after {
    /* if a count is given do secondary styling */
    content: ${(props) => (props.unreadCount ? `'${props.unreadCount}'` : "''")};
    width: ${(props) => (props.unreadCount ? "var(--size)" : props.width || ".8rem")};
    height: ${(props) => (props.unreadCount ? "var(--size)" : props.height || ".8rem")};
    border-radius: ${(props) => (props.unreadCount ? "3px" : "50%")};
    position: absolute;
    top: ${(props) => (props.theme.isMobile ? "0" : "unset")};
    left: ${(props) => props.left || "50%"};
    transform: ${(props) => props.translateX || "translateX(-50%)"};
    background-color: var(--red);
    color: white;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    line-height: var(--size);
  }
`;

function UnreadMsgIndicator(props) {
  const { readable, isRead, unreadCount, ...dotProps } = props;
  return (
    <>
      {readable && !isRead && (
        <UnreadIndicator
          data-test="unread-messages-indicator"
          unreadCount={unreadCount}
          {...dotProps}
        />
      )}
    </>
  );
}

export default UnreadMsgIndicator;
