import { createSelector } from "reselect";

export const authError = (state: any) => state.auth.error;
export const selectHasAuthToken = (state: any) => state.auth.hasAuthToken;
export const selectAuthToken = (state: any) => state.auth.token;
export const getRefreshToken = (state: any) => state.auth.refreshToken;

const userData = {
  firstName: "",
  lastName: "",
  fullName: "",
  id: -1,
  company: {
    id: -1,
    name: "",
    profiles: [],
    timezone: "",
    isMessagingDisabled: false,
    autoDeadEnabled: false,
    totalSkipTraceSavings: 0,
    monthlyUploadLimit: 0,
    unmanagedMarketProspectUnitPrice: 0,
    sherpaBalance: 0,
    customerId: "",
    coldMessagesAttemptedTodayCount: 0,
    maxDailyLimitColdMessageAttempt: 0,
    invitationCode: null,
  },
};

export const selectUserData = createSelector(
  (state: any) => state.auth.userData,
  (data: any) => ({ ...userData, ...data })
);

export const selectUserId = (state: any) => state.auth.userData.id;

export const selectProfileData = (state: any) => state.auth.userData.profile;

export const selectAuthStatus = (state: any) => state.auth.status;

export const selectVerificationStatus = (state: any) => state.auth.verificationStatus;

export const selectLoggingInStatus = (state: any) => state.auth.loggingIn;
