import { produce } from "immer";
import {
  FETCH_PROSPECT_MESSAGES_LIST_SUCCESS,
  FETCH_PROSPECT_MESSAGES_LIST_ERROR,
  SET_PROSPECT_MESSAGES_LIST_STATUS,
  UPDATE_PROSPECT_MESSAGES,
  UPDATE_PROSPECT_MESSAGE,
  REMOVE_UNREAD_MESSAGES,
} from "./actions";
import { Fetching, Success, FetchError } from "module/common/helpers/variables";

const initialState = {
  error: "",
  list: {},
  unreadMsgCount: 0,
  status: Fetching,
};

export const path = ["prospectDetailsReducer", "prospectMessages"];

const computeUnreadMessagesCount = (prospectMessages: any) => {
  return Object.values(prospectMessages).reduce((count: number, messages: any) => {
    const hasUnreadMsg = Object.values(messages).some(
      (message: any) => message.unreadByRecipient
    );
    if (hasUnreadMsg) {
      return count + 1;
    }
    return count;
  }, 0);
};

const reducer = produce((base: any, action: any) => {
  switch (action.type) {
    case SET_PROSPECT_MESSAGES_LIST_STATUS: {
      base.status = action.status;
      break;
    }
    case UPDATE_PROSPECT_MESSAGES:
    case FETCH_PROSPECT_MESSAGES_LIST_SUCCESS: {
      const newMessages = { ...base.list, ...action.payload };

      base.list = newMessages;
      base.unreadMsgCount = computeUnreadMessagesCount(newMessages);
      base.status = Success;
      break;
    }
    case FETCH_PROSPECT_MESSAGES_LIST_ERROR: {
      base.error = action.error;
      base.status = FetchError;
      break;
    }
    case UPDATE_PROSPECT_MESSAGE: {
      const { payload } = action;
      const newMessages = {
        ...base.list,
        [payload.prospect]: {
          ...base.list[payload.prospect],
          [payload.id]: payload,
        },
      };

      base.list = newMessages;
      base.unreadMsgCount = computeUnreadMessagesCount(newMessages);
      break;
    }
    case REMOVE_UNREAD_MESSAGES: {
      const newProspectMessage = Object.values({ ...base.list[action.payload] }).reduce(
        (total: any, current: any): any => {
          current.unreadByRecipient = false;
          total[current.id] = current;
          return total;
        },
        {}
      );

      const newMessages = {
        ...base.list,
        [action.payload]: newProspectMessage,
      };

      base.list = newMessages;
      base.unreadMsgCount = computeUnreadMessagesCount(newMessages);
      break;
    }
  }
}, initialState);

export default reducer;
