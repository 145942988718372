import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "reactstrap";
import styled from "styled-components/macro";
import { isMobile } from "../../../../../detectDevice";
import NameBubble from "../../NameBubble";
import PropertyDataModalLink from "../../ProspectSlideOut/PropertyDataModalLink";

import { ReactstrapSpinner } from "module/common/components/ReactstrapSpinner";
import { ProspectCampaignsContext } from "../../../../common/helpers/context";
import ClickToCall from "../../ClickToCall";

import { prospectIsLoading } from "module/main/store/prospectStore/selectors";
import { prospectFetchSingle } from "module/main/store/prospectStore/thunks";

// hooks
import useWindowSize from "module/common/hooks/useWindowSize";
import ProspectPhoneDisplay from "./components/ProspectPhoneDisplay";

const StyledBadge = styled(Badge)`
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  color: var(--darkNavy);
  background-color: var(--lightGray);
  white-space: normal !important; /** to override the default badge class  */
`;

const StyledProspectDetailsCard = styled.div`
  display: flex;
  flex-grow: 1;
`;

const StyledProspectContent = styled.div`
  display: flex;
  flex-grow: 1;
`;

const StyledName = styled.h4`
  margin-bottom: 0;
`;

const ClickableSpan = styled.span`
  color: var(--sherpaBlue);
  cursor: pointer;
`;

const zillowLinkIcon = (
  <FontAwesomeIcon className="mr-1" icon={["far", "window-restore"]} />
);

const HomeIcon = <FontAwesomeIcon className="mr-1" icon="home" />;

const ProspectDetailsCard = ({ prospect, showCampaigns }) => {
  const isProspectLoading = useSelector(prospectIsLoading);
  const dispatch = useDispatch();

  const { prospectCampaigns: campaigns } = useContext(ProspectCampaignsContext) || {};
  const { zillowLink, addressDisplay } = prospect;

  const windowSize = useWindowSize();

  useEffect(() => {
    if (prospect.partial && !isProspectLoading) {
      dispatch(prospectFetchSingle(prospect.id));
    }
    // eslint-disable-next-line
  }, [prospect.partial]);

  const renderCampaignNames = () => {
    return campaigns.map((val, index) => {
      return (
        <StyledBadge className="mr-1" key={index}>
          {val.name}
        </StyledBadge>
      );
    });
  };

  const name = `${prospect.firstName} ${prospect.lastName}`;

  if (isProspectLoading) {
    return (
      <StyledProspectDetailsCard data-test="prospect-card">
        <ReactstrapSpinner size="md" />
      </StyledProspectDetailsCard>
    );
  }

  return (
    <>
      <StyledProspectDetailsCard>
        <NameBubble
          className="mr-2"
          size={!showCampaigns ? "3.5rem" : "5rem"}
          initials={`${prospect.firstName?.[0] || ""}${prospect.lastName?.[0] || ""}`}
        />
        <StyledProspectContent>
          <div className="flex-grow-1">
            <div className="d-flex justify-content-between">
              <span className="d-block">
                <StyledName> {name} </StyledName>
              </span>
              {!showCampaigns ? (
                <ClickToCall
                  iconOnly={
                    windowSize.width > 767 && windowSize.width < 991.98 && !isMobile
                  }
                  prospect={prospect}
                  disabled={prospect.doNotCall}
                />
              ) : null}
            </div>

            <ProspectPhoneDisplay prospect={prospect} />
            <span className="mb-1">
              <p className="darkGray">{addressDisplay}</p>
            </span>

            {zillowLink && !showCampaigns ? (
              <span className="d-block mb-1">
                <a href={zillowLink} rel="noopener noreferrer" data-test="zillow-link">
                  {zillowLinkIcon} View property on Zillow
                </a>
              </span>
            ) : null}
            {!isMobile && !showCampaigns ? (
              <PropertyDataModalLink id={prospect.propertyId}>
                {(openModal) => (
                  <ClickableSpan
                    data-test="property-info"
                    role="button"
                    className="p-0 mb-2 d-block"
                    onClick={openModal}
                  >
                    {HomeIcon}
                    View additional property info
                  </ClickableSpan>
                )}
              </PropertyDataModalLink>
            ) : null}
            {!showCampaigns ? (
              <span className="d-block">{renderCampaignNames()}</span>
            ) : null}
          </div>
        </StyledProspectContent>
      </StyledProspectDetailsCard>
    </>
  );
};

export default ProspectDetailsCard;
