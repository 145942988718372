import {
  VALIDATION_INVALID_CHARS,
  VALIDATION_MIN_LENGTH,
  VALIDATION_MAX_LENGTH,
  GOOGLE_MAPS_SEARCH_URL,
  TEMPLATE_TEXT_AREA_MAX_LENGTH,
  TEMPLATE_TEXT_AREA_MIN_LENGTH,
} from "./variables";
import moment from "moment-timezone";
import { getIn, capitalize } from "module/common/utils/utils";
import { isMobile } from "../../../detectDevice";

export const debounce = (callback: (...args: any[]) => void, delay: number) => {
  let debounceTimer: ReturnType<typeof setTimeout>;
  return function (this: (...args: any[]) => void) {
    const args: any = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => callback.apply(this, args), delay);
  };
};

export const getKeyByValue = (obj: any, value: any) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const isToday = (someDate: string) => {
  const today = new Date();
  const date = new Date(someDate);
  return date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
};

export const getDateFromDate = (date: any, diff: number) => {
  const returnDate = new Date(date);
  //  use negative number to go backwards
  return returnDate.setDate(returnDate.getDate() + diff);
};

export const groupBy = (objectArray: any, property: string) => {
  return objectArray.reduce((total: any, obj: any) => {
    const key = obj[property];
    if (!total[key]) {
      total[key] = [];
    }
    total[key].push(obj);
    return total;
  }, {});
};

export const hasDatePassed = (date: string) => {
  return Date.parse(date) < Date.now();
};

export const closestDate = (dates: any) => {
  const closestDate_ = dates
    //eslint-disable-next-line
    .map((date: any, idx: number) => {
      const dateDiff =
        Date.now() - Date.parse(typeof date === "string" ? date : date.date);
      // removes all dates that haven't come yet
      return {
        date: date?.date || date,
        dateDiff,
        // store idx value so it can be used to tell the Steps component where you are at.
        step: date?.step || idx,
      };
    })
    .sort((a: any, b: any) => a.dateDiff - b.dateDiff);
  // returns the first option as it is the closest to zero

  const filtered = closestDate_.filter((e: any) => Math.sign(e.dateDiff) !== -1);

  if (filtered.length > 0) {
    return filtered[0];
  }
  return closestDate_[0];
};

export const classOf = (variable: any) => {
  if (variable === null || variable === undefined) return variable;
  if (Array.isArray(variable)) return "array";
  return typeof variable;
};

export const deepCopy = (obj: any) => {
  let copy: any;
  switch (classOf(obj)) {
    case "array":
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = deepCopy(obj[i]);
      }
      return copy;
    case "object":
      copy = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
      }
      return copy;
    default:
      return obj;
  }
};

export const formatPhoneNumber = (number = "") => {
  let phoneNumber = number || "";
  if (phoneNumber.length === 12) {
    phoneNumber = phoneNumber.slice(2);
  }
  const [
    // eslint-disable-next-line
    _,
    areaCode = "",
    first = "",
    second = "",
  ] = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})/) || [];

  return `(${areaCode}) ${first}-${second}`;
};

// form validation
export const validationMessage = (message: string, value: any) => {
  switch (message) {
    case VALIDATION_MIN_LENGTH:
      return `Must consist of at least ${value} characters`;
    case VALIDATION_MAX_LENGTH:
      return `Exceeds max length of ${value} characters`;
    case VALIDATION_INVALID_CHARS:
      return `Invalid characters. ${value} are not allowed.`;
  }
};

export const formatValidation = (value: any, message: string) => {
  return { value, message: validationMessage(message, value) };
};

export const formatDate = (dateStr: string, formatStr = "L", forceTimezone = true) => {
  if (!dateStr) return null;

  const date = moment(dateStr, formatStr);

  if (forceTimezone) {
    const tzDate = moment(dateStr).format(formatStr);
    return tzDate;
  }

  return date;
};

const formatCurrencyHelper = () => {
  return {
    style: "currency",
    currency: "USD",
  };
};

const formatPercentageHelper = (num: number, errorValue: string = "TBD") => {
  if (num >= 0 && num <= 100) {
    // format to 2 decimal places
    num = Math.round(num * 100) / 100;
    return `${num}%`;
  }
  return errorValue;
};

interface FormatNumberProps {
  value: number | string;
  isCurrency?: boolean;
  isPercentage?: boolean;
  locale?: "en-US";
}

export const formatNumber = ({
  value,
  isCurrency = false,
  isPercentage = false,
  locale = "en-US",
}: FormatNumberProps) => {
  const num = parseFloat(value as string);
  let options = {};
  if (isNaN(num)) return value as string;

  if (isPercentage) return formatPercentageHelper(num);
  //set options to use the appropriate formatter
  if (isCurrency) options = formatCurrencyHelper();

  const formatter = new Intl.NumberFormat(locale, { ...options });
  return formatter.format(num);
};

export const formatSpecialCurrency = (value: number | string, digits: number) => {
  const num = parseFloat(value as string);
  if (isNaN(num)) return value as string;

  const numText = num.toString();
  const numOfDecimals = Math.min(numText.split(".")[1]?.length || 2, digits);

  return `$${num.toFixed(Math.max(numOfDecimals, 2))}`;
};

export const formatCurrency = (value: number | string, digits: number = 2) => {
  if (digits !== 2) {
    return formatSpecialCurrency(value, digits);
  }

  return formatNumber({ value, isCurrency: true });
};

export const validateURL = (value: string) => {
  return value.match(
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#%=~_|$?!:,.]*\)|[-A-Z0-9+&@#%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#%=~_|$?!:,.]*\)|[A-Z0-9+&@#%=~_|$])/gim
  );
};

export const validateWebsiteURL = (value: string) => {
  return (
    value.match(
      /^(https?:\/\/)(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_+.~#?&//=]*)$/gm
    ) || "Please enter a valid URL. Example: http(s)://example.com"
  );
};

export const nameToInitials = (name?: string): string => {
  if (!name || !name.trim()) return "";
  const [first, last] = name.split(" ");

  // handle case where name might be a first or last name
  return first && last ? `${first[0]}${last[0]}` : first[0] ?? last[0];
};

export const getGoogleMapAddressUrl = (address: string) => {
  const fullAddress = address ? address : "";

  return fullAddress
    ? `${GOOGLE_MAPS_SEARCH_URL}query=${encodeURIComponent(fullAddress)}`
    : "";
};

export const max = (number1: number | null, number2: number | null) => {
  return Math.max(number1 || 0, number2 || 0);
};

export const isNumeric = (value: any) => {
  return /^-{0,1}\d+$/.test(value);
};

export const toSelectOptions = (coll: Array<any>, schema: { [key: string]: string }) => {
  const schema_ = Object.entries(schema);
  const toSelectOption = (data: any) => {
    return schema_.reduce((acc: any, [key, path]: [string, string | Array<string>]) => {
      const normalizedPath = typeof path === "string" ? [path] : path;
      acc[key] = getIn(normalizedPath, data);
      return acc;
    }, {});
  };

  return coll.map((entry: any) => toSelectOption(entry));
};

export const toCheckboxEntries = (data: { options: Array<any>; selected: any }) => {
  const selectedCheckboxes: Array<any> = [];
  const yesToggles: Array<any> = [];

  data.options.forEach((value: any) => {
    if (data.selected[value.value].length > 0) {
      // checkbox has been selected
      selectedCheckboxes.push(value.value);
      // now check what the toggle value is
      const [, toggleValue] = data.selected[value.value];
      if (toggleValue) {
        yesToggles.push(value.value);
      }
    }
  });
  return { selectedCheckboxes, yesToggles };
};

export const toDisplaySelected = (data: { options: Array<any>; selected: any }) => {
  const selectedYes: Array<any> = [];
  const selectedNo: Array<any> = [];

  data.options.forEach((value: any) => {
    if (data.selected[value.value].length > 0) {
      const [, toggleValue] = data.selected[value.value];
      if (toggleValue) {
        selectedYes.push(value.label);
      } else {
        selectedNo.push(value.label);
      }
    }
  });
  return { selectedYes, selectedNo };
};

export const unixTStoDate = (timestamp: string) => {
  const fixedTimeStramp = parseInt(timestamp) * 1000;
  return moment(fixedTimeStramp);
};

// convert a Title with a capitalized first letter to all lower case
//  Will expand funcationality to include camel case and spaces
export const titleToKey = (string: string) => {
  return string.charAt(0)?.toLowerCase() + string.slice(1);
};

export const deviceClass = !isMobile ? " isDesktop " : " isMobile ";

export const templateMessagesRules = {
  required: "Required",
  maxLength: formatValidation(TEMPLATE_TEXT_AREA_MAX_LENGTH, VALIDATION_MAX_LENGTH),
  minLength: formatValidation(TEMPLATE_TEXT_AREA_MIN_LENGTH, VALIDATION_MIN_LENGTH),
  validate: {
    validateURLMessage: (value: string) =>
      !validateURL(value) || "Cannot contain URL links.",
  },
};

export type InputRefType = HTMLTextAreaElement | HTMLInputElement | null;

export const addDynamicField = (
  field: string,
  ref: React.RefObject<InputRefType>,
  setValue: (name: string, currentMessage: string) => void
) => {
  if (!ref.current) return;
  const { value, selectionStart, name } = ref.current;
  const selectionEndIndex = (value.slice(0, selectionStart || 0) + field).length;
  const currentMessage =
    value.slice(0, selectionStart || 0) + field + value.slice(selectionStart || 0);

  setValue(name, currentMessage);
  ref.current.selectionEnd = selectionEndIndex;
  return ref.current.focus();
};

type ApiResponseObject = Record<string | number, any> | ApiResponseObject[];

/**
 * This will take a data object and returns a new object with matching keys formatted
 * with the option given between currency and percentage.
 * It also leaves the original value intact in case it is needed
 * Example: enrichApiNumberData({ deliveryRate: 23 }, { deliveryRate: percentage }) outputs { deliveryRate: 23, deliveryRateAsPercentage: "23%" }
 * @param data This is the API data object that needs to be enriched
 * @param formattingOptions This is an object that contains the keys that need to be formatted, it accepts currency and percentage as values
 * @returns A new API data object with the enriched formatted keys
 */
export const enrichApiNumberData = (
  data: ApiResponseObject,
  formattingOptions: Record<string, "currency" | "percentage" | "number" | "mdt_datetime">
): ApiResponseObject => {
  const formatKeys = Object.keys(formattingOptions);
  const formattedData = Array.isArray(data) ? [...data] : { ...data };

  for (const [key, value] of Object.entries(formattedData)) {
    if (typeof value === "object" && value !== null) {
      formattedData[key as keyof ApiResponseObject] = enrichApiNumberData(
        value,
        formattingOptions
      );
      continue;
    }

    if (!formatKeys.includes(key)) continue;

    if (typeof value === "boolean")
      throw new Error(
        `Cannot format boolean value for key "${key}" at enrichApiNumberData`
      );

    const formatType = formattingOptions[key];
    const formattedKey = `formatted${capitalize(key)}`;

    // Nullish values are returned as is in formatNumber
    switch (formatType) {
      case "currency":
        formattedData[formattedKey as keyof ApiResponseObject] = formatNumber({
          value,
          isCurrency: true,
        });
        break;
      case "percentage":
        formattedData[formattedKey as keyof ApiResponseObject] = formatNumber({
          value,
          isPercentage: true,
        });
        break;
      case "number":
        formattedData[formattedKey as keyof ApiResponseObject] = formatNumber({
          value,
        });
        break;
      case "mdt_datetime":
        const dt = moment(value);
        if (!dt.isValid()) {
          formattedData[formattedKey as keyof ApiResponseObject] = "----";
          break;
        }
        const date = dt.format("MMM DD YYYY");
        const time = dt.format("LT");

        formattedData[formattedKey as keyof ApiResponseObject] = `${date} | ${time}`;
        break;
    }
  }
  return formattedData;
};
