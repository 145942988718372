import { createAction } from "../../redux-helpers";

export const SET_SMS_MANAGER_ACTIVE_TAB = "SET_SMS_MANAGER_ACTIVE_TAB";
export const setSmsManagerActiveTab = createAction(SET_SMS_MANAGER_ACTIVE_TAB);

export const RESET_SMS_MANAGER_ACTIVE_TAB = "RESET_SMS_MANAGER_ACTIVE_TAB";
export const resetSmsManagerActiveTab = createAction(RESET_SMS_MANAGER_ACTIVE_TAB);

export const SET_SMS_TEMPLATE_FILTERS_MODAL = "SET_SMS_TEMPLATE_FILTERS_MODAL";
export const setSmsTemplateFiltersModalOpen = createAction(
  SET_SMS_TEMPLATE_FILTERS_MODAL
);
