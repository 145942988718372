// store
import { getCompanyPaymentMethods } from "module/main/services/Company";
import { Dispatch } from "redux";
import { selectProfileData } from "../Auth/selectors";
import { addErrorToast, addNewToast, addSuccessToast } from "../Global/Toasts/actions";
import {
  setCompanyData,
  updateCompanyBrandStatus,
  updateUserFeatureNotification,
} from "./actions";
import {
  companyIntegrationStatus,
  companyProspectCount,
  companySetInvitationCode,
  getBrandRequestInfo,
  getBrandStatus,
  getOptInOptions,
  getSmsSubscriptionRequest,
  patchBrandInfo,
  PatchBrandPayload,
  patchCompany,
  putOptInAttestation,
  PutOptInPayload,
  PatchSmsRequestPayload,
  patchSmsSubscriptionRequest,
  updateFeatureNotification,
} from "./api";
import {
  fetchPaymentInfo,
  fetchPaymentInfoError,
  setPaymentInfo,
} from "./paymentInfo/action";
// utils
import {
  genericNetworkErrorMessage,
  ProfileRoles,
} from "module/common/helpers/variables";
import { formatErrorMessage } from "module/common/utils/utils";
import { formatOptInToSelectOptions } from "./utils";
import { ICompany } from "./interfaces";

export const updateCompany =
  (id: number, body: Partial<ICompany>) => async (dispatch: Dispatch) => {
    return patchCompany(id, body).then(({ data }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { profiles, ...companyData } = data;
      dispatch(setCompanyData(companyData));

      return data;
    });
  };

export const updateCompanyOptimistically =
  (id: any, body: any) => async (dispatch: Dispatch) => {
    dispatch(setCompanyData(body));
    return patchCompany(id, body);
  };

export const setCompanyInvitationCode = (id: number, payload: any) => async () => {
  return companySetInvitationCode(id, payload);
};

export const getCompanyProspectCount = (id: number) => async (dispatch: Dispatch) => {
  return companyProspectCount(id).then(({ data }) => {
    dispatch(setCompanyData({ prospectCount: data.count }));
    return data;
  });
};

export const getCompanyIntegrationStatus = (id: number) => async (dispatch: Dispatch) => {
  return companyIntegrationStatus(id)
    .then(({ data }) => {
      dispatch(
        setCompanyData({
          integrations: {
            podio: data.podio,
          },
        })
      );
      return data;
    })
    .catch((err) => {
      const defaultMessage =
        "There was an issue retrieving company integration status. Please refresh.";
      const errorMessage: any = formatErrorMessage(err, defaultMessage);

      dispatch(addErrorToast(errorMessage));
    });
};

export const getCompanyPaymentInfo =
  (id: number) => async (dispatch: Dispatch, getState: any) => {
    const state = getState();
    const { role } = selectProfileData(state);
    if ([ProfileRoles.JuniorStaff, ProfileRoles.Staff].includes(role)) return;

    dispatch(fetchPaymentInfo());

    try {
      const { data } = await getCompanyPaymentMethods(id);
      dispatch(setPaymentInfo(data));
      return data;
    } catch (err) {
      dispatch(fetchPaymentInfoError());

      const defaultMessage =
        "There was an issue retrieving payment methods. Please refresh.";
      const errorMessage: any = formatErrorMessage(err, defaultMessage);

      dispatch(addErrorToast(errorMessage));
    }
  };

export const updateFeatureNotifications =
  (id: number, data: any) => (dispatch: Dispatch) => {
    return updateFeatureNotification(id, data)
      .then(() => {
        dispatch(updateUserFeatureNotification(data));
      })
      .catch(() => {
        dispatch(addNewToast(genericNetworkErrorMessage));
      });
  };

export const getRegistrationStatus10DLC = () => (dispatch: Dispatch) => {
  return getBrandRequestInfo()
    .then(({ data }) => {
      dispatch(
        setCompanyData({
          isRegistered10DLC: !!data.results.length,
        })
      );
    })
    .catch(() => {
      dispatch(
        addErrorToast("There was an issue retrieving brand registration, please refresh.")
      );
    });
};

export const getBrandStatus10DLC = () => (dispatch: Dispatch) => {
  return getBrandStatus()
    .then(({ data }) => {
      dispatch(setCompanyData({ brandStatus: [...data.results] }));
    })
    .catch(() => {
      dispatch(
        addErrorToast("There was an issue retrieving brand status, please refresh.")
      );
    });
};

export const callPatchBrandInfo =
  (brandId: string, payload: PatchBrandPayload) => async (dispatch: Dispatch) => {
    try {
      const response = await patchBrandInfo(brandId, payload);
      dispatch(updateCompanyBrandStatus({ website: payload.website }));
      dispatch(addSuccessToast("Successfully updated the website."));
      return response;
    } catch (err: any) {
      const defaultMsg =
        "There was an issue setting brand website. Please refresh and try again";
      const errorMsg = formatErrorMessage(err, defaultMsg);
      dispatch(addErrorToast(errorMsg));
      throw err;
    }
  };

export const callGetOptInOptions = (companyId: number) => async (dispatch: Dispatch) => {
  try {
    const optInOptions = await getOptInOptions(companyId);
    const optInLanguageList = optInOptions.data.actions.PUT.optInMethods.choices;
    dispatch(
      setCompanyData({ optInOptions: formatOptInToSelectOptions(optInLanguageList) })
    );
  } catch {
    dispatch(
      addErrorToast("There was an issue retrieving the opt-in options, please refresh.")
    );
  }
};

export const callPatchCaptureSmsInterest =
  (companyId: number, payload: PatchSmsRequestPayload) => async (dispatch: Dispatch) => {
    try {
      const response = await patchSmsSubscriptionRequest(companyId, payload);
      dispatch(
        setCompanyData({
          latestSmsSubscriptionRequest: response.data,
          latestSmsSubscriptionRequestId: response.data.id,
        })
      );
      return response;
    } catch {
      dispatch(
        addErrorToast("There was an issue while recording your subscription interest.")
      );
    }
  };

export const callGetCaptureSmsInterest =
  (companyId: number) => async (dispatch: Dispatch) => {
    try {
      const response = await getSmsSubscriptionRequest(companyId);
      dispatch(
        setCompanyData({
          latestSmsSubscriptionRequest: response.data,
          latestSmsSubscriptionRequestId: response.data.id,
        })
      );
    } catch {
      dispatch(
        addErrorToast(
          "There was an issue while getting your subscription information, please refresh"
        )
      );
    }
  };

export const callPutOptInAttestation =
  (companyId: number, payload: PutOptInPayload) => async (dispatch: Dispatch) => {
    try {
      const response = await putOptInAttestation(companyId, payload);
      dispatch(
        setCompanyData({
          smsOptInAttestation: response.data,
          smsOptInAttestationId: response.data.id,
        })
      );
      dispatch(addSuccessToast("Your opt-in information has been noted!"));
    } catch (err) {
      dispatch(
        addErrorToast("There was an issue while recording your opt-in information.")
      );
      throw err;
    }
  };
