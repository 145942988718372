import AxiosConfig from "../../../../common/utils/axiosConfig";

export const pollExports = async (uuid: string) => {
  const url = `/download-history/${uuid}/poll/`;
  const response = await AxiosConfig.get(url);
  return response.data;
};

export const exportDirectMailRecipients = (id: number) => {
  // TODO: SETUP ACTUAL ENDPOINT.
  return AxiosConfig.get(`/campaigns/${id}/export_direct_mail_recipients`);
};

export const dncExport = async (remove: boolean) => {
  const response = await AxiosConfig.get(`/dnc/export?clear_dnc=${remove}`);
  return response.data;
};

export const exportPriorityUnreadProspects = async (id: number, params: any) => {
  const response = await AxiosConfig.get(`/campaigns/${id}/export/`, {
    params: { [params.name]: params.value },
  });
  return response.data;
};

export const exportProspectSearch = async (params: any) => {
  const response = await AxiosConfig.get(`/prospects/export/`, { params: params });
  return response.data;
};

export const campaignExportAll = async (id: number) => {
  const response = await AxiosConfig.get(`/campaigns/${id}/export/`);
  return response.data;
};

export const campaignExportByPhoneType = async (id: number, phoneType: string) => {
  const response = await AxiosConfig.get(
    `/campaigns/${id}/export/?phone_type=${phoneType}`
  );
  return response.data;
};

export type ExportMode = "legacy" | "zip" | "contacts";

export const skiptraceExport = async (id: number, exportMode: ExportMode) => {
  const response = await AxiosConfig.get(
    `/skip-traces/${id}/export/?export_mode=${exportMode}`
  );
  return response.data;
};

export const exportCampaignList = async (params: any) => {
  const response = await AxiosConfig.get("/campaigns/export/", { params: params });
  return response.data;
};

export const exportCompanyCampaignMetaStats = async (params: any) => {
  const response = await AxiosConfig.get("/companies/export_campaign_meta_stats/", {
    params,
  });
  return response.data;
};

export const exportCompanyProfileStats = async (params: any) => {
  const response = await AxiosConfig.get("/companies/export_profile_stats/", { params });
  return response.data;
};

export const exportReminders = async (stepType: string, filter: string = "user") => {
  const response = await AxiosConfig.get(
    `sequence-steps/export/?step_type=${stepType}&owned_by=${filter}`
  );
  return response.data;
};
