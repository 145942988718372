import { IState, IResults, IProspectNote } from "./interfaces";
import { prospectDetailsReducer } from "module/common/helpers/variables";
import { createSelector as createCachedSelector } from "reselect";

interface IProspectNotes {
  prospectDetailsReducer: {
    prospectNotes: IState & IResults;
  };
}

const reducer = prospectDetailsReducer;

export const prospectNotesList = createCachedSelector(
  ({ [reducer]: { prospectNotes } }: IProspectNotes) => prospectNotes.list,
  (notes: any) => notes || {}
);

export const selectProspectNotes = createCachedSelector(
  (_: any, id: number) => id,
  prospectNotesList,
  (id: number, list: Record<string, IProspectNote[]>) => list[id] || null
);

export const prospectNotesStatus = ({ [reducer]: { prospectNotes } }: IProspectNotes) =>
  prospectNotes.status;
