import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TelnyxRTC } from "@telnyx/webrtc";

import {
  setCallSession,
  setIsRegistered,
  setCallState,
  callCompleted,
  setIsActive,
} from "module/main/store/Calls/actions";
import { getIsRegistered } from "module/main/store/Calls/selectors";
import { addNewToast } from "module/main/store/Global/Toasts/actions";
import { postCallLog } from "module/main/services/Calls";
import { populateProspectActivities } from "module/main/store/ProspectDetails/activities/actions";
import { ONE_SECOND_IN_MILLISECONDS } from "module/common/helpers/variables";

const useTelnyxSession = ({ hasAuthToken }) => {
  const dispatch = useDispatch();
  const isRegistered = useSelector(getIsRegistered);
  const telnyxRef = useRef(null);

  const CONNECT_TIMEOUT = 2 * ONE_SECOND_IN_MILLISECONDS;

  const removeEvents = (session) => {
    if (!session) return;
    session.disconnect();
    session.off("telnyx.ready");
    session.off("telnyx.error");
    session.off("telnyx.socket.error");
    session.off("telnyx.socket.close");
    session.off("telnyx.notification");
  };

  const connect = () => {
    const session = new TelnyxRTC({
      login: "sherpac2c",
      password: "&C+2rDX66Yj7+yX#L42&!zX$",
    });

    session.on("telnyx.ready", () => {
      dispatch(setCallSession(session));
      dispatch(setIsRegistered(true));
    });

    session.on("telnyx.error", () => {
      removeEvents(session);
      dispatch(setIsRegistered(false));
    });

    session.on("telnyx.socket.error", () => {
      removeEvents(session);
      dispatch(setIsRegistered(false));
    });

    session.on("telnyx.socket.close", () => {
      removeEvents(session);
      dispatch(setIsRegistered(false));
    });

    session.on("telnyx.notification", (notification) => {
      if (notification.type === "callUpdate") {
        switch (notification.call.state) {
          case "new":
          case "requesting":
            dispatch(setCallState("Requesting"));
            break;
          case "trying":
            dispatch(setIsActive(true));
            dispatch(setCallState("Connecting"));
            break;
          case "early":
            dispatch(setCallState("Ringing"));
            break;
          case "active":
            dispatch(setCallState("Active"));
            break;
          case "hangup":
            dispatch(setCallState("Hanging up"));
            break;
          case "destroy":
            const callStart = notification.call.options.userVariables.callStart;
            const toNumber = notification.call.options.destinationNumber;
            const prospectID = notification.call.options.userVariables.prospectID;
            dispatch(callCompleted());
            if (prospectID) {
              // Only display the toast for the call and post the log if we are calling a prospect
              // (not when we are just testing the number)
              dispatch(addNewToast({ message: "Call completed.", color: "success" }));
              postCallLog(callStart, toNumber, prospectID)
                .then(({ data }) => {
                  dispatch(
                    populateProspectActivities({
                      id: prospectID,
                      data: [data],
                    })
                  );
                })
                .catch(() => {
                  dispatch(
                    addNewToast({
                      message: "We were unable to log this call.",
                      color: "danger",
                    })
                  );
                });
            }
            break;
          default:
            break;
        }
      }
    });
    session.connect();
    telnyxRef.current = session;
  };

  useEffect(() => {
    // no use connecting on login page, let's wait for Auth
    if (hasAuthToken && !isRegistered) setTimeout(connect, CONNECT_TIMEOUT);
    if (!hasAuthToken && telnyxRef.current) removeEvents(telnyxRef.current);
  }, [hasAuthToken, isRegistered]);
};

export default useTelnyxSession;
