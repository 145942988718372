import styled from "styled-components/macro";
import { Card, Container, Row } from "reactstrap";

export const TopSection = styled(Card)`
  border: none;
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadow);
  background: var(--ghostBlueGradient);
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    padding-bottom: 50px;
  }

  @media (max-width: 576px) {
    padding-bottom: 40px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  color: var(--secondary);

  @media (max-width: 768px) {
    width: inherit;
  }
`;

export const HeaderImg = styled(Container)`
  img {
    max-width: 15rem;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  letter-spacing: 0;
`;

export const SubTitle = styled.div`
  height: 50px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
`;

export const FooterRow = styled(Row)`
  justify-content: space-between;
  flex-direction: row;
  margin-top: 42px !important;
  padding-top: 13px;
  margin: 2px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
`;

export const Help = styled.div`
  color: var(--secondary);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
`;
