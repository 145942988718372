import React from "react";
import { ICompany, IProfile } from "module/main/store/Company/interfaces";
import { roles } from "module/main/permissions/permission";
import { useIsSelfManagedUser } from "module/common/hooks/useSmsFlow";
import SMSLandingPage from "./components/SMSLandingPage";
import SkipTraceLandingPage from "./components/SkipTraceLandingPage";

// NOTE: adding another page name will add it to the skip trace view
export enum NavPageNames {
  AllUnread = "all-unread",
  CampaignsSms = "campaigns-sms",
  Skiptrace = "skiptrace",
  Support = "support",
  AccountSettings = "account-settings",
  PhoneManager = "phone-manager",
  Stacker = "stacker",
}

export const userHasPermission = (profile: IProfile, permission: string) => {
  const { permissions } = roles[profile && profile.role] || [];
  return permissions && permissions.includes(permission);
};

// data-structure that holds the different type of users the type of
// users denote the different states a =company= can have in regards
// to their subscription status.
//
// The `key` at the top-level map denotes the name of the user.
// These were made up based on different combinations.
// Inside each record there's a structure with the following keys:
//
// - view :: a list of the pages the user-type has permission to view.
//           if =all= is found then it allows the user to view all pages.
//
// - showBlockedMessageTo :: a list of the pages a =blocked-message=
//                          needs to be rendered instead of the main content
//
// - blockedMessage :: is a map with keys =header= and =message= which will be
//                     used to render the blocked message. =url= is there for adding links
//
// - redirectTo :: is a route that the user is redirected if they don't have permission
//                 to view the page
//
// - blockedComponent :: in case we want to block any component from rendering
const userTypes = {
  skiptrace: {
    view: Object.values(NavPageNames),
    redirectTo: "/campaigns",
    showBlockedMessageTo: [NavPageNames.CampaignsSms, NavPageNames.AllUnread],
    blockedMessage: {
      all: {
        message: <SMSLandingPage />,
      },
    },
    blockedComponent: ["unread-message-nav-button", NavPageNames.AllUnread],
  },
  selfManaged: {
    view: ["all"],
    showBlockedMessageTo: [],
    redirectTo: "/campaigns",
    blockedComponent: [],
  },
  // brand new sign up
  noPayment: {
    view: [
      NavPageNames.Skiptrace,
      NavPageNames.CampaignsSms,
      NavPageNames.Support,
      NavPageNames.Stacker,
    ],
    showBlockedMessageTo: [
      NavPageNames.Skiptrace,
      NavPageNames.CampaignsSms,
      NavPageNames.AllUnread,
    ],
    blockedMessage: {
      [NavPageNames.Skiptrace]: {
        header: "Welcome to Lead Sherpa Skip Trace",
        message: <SkipTraceLandingPage />,
      },
      [NavPageNames.CampaignsSms]: {
        header: "Activate Your Account",
        message: <SMSLandingPage />,
      },
      [NavPageNames.AllUnread]: {
        header: "Activate Your Account",
        message: <SMSLandingPage />,
      },
    },
    redirectTo: "/campaigns",
    blockedComponent: ["unread-message-nav-button"],
  },
  good: {
    view: ["all"],
    showBlockedMessageTo: [],
    blockedComponent: [],
  },
};

export const getUserTypeBasedOnSubscription = (company: ICompany) => {
  const isSelfManaged = useIsSelfManagedUser();

  switch (true) {
    // Keeping this one on top since its an override company value
    case company.isBillingExempt:
      return userTypes.good;
    case !company.customerId:
      return userTypes.noPayment;
    case isSelfManaged:
      return userTypes.selfManaged;
    case !!company.customerId:
      return userTypes.skiptrace;
    default:
      return userTypes.good;
  }
};
