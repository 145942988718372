import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { UncontrolledTooltip } from "reactstrap";

import { formatCurrency, formatSpecialCurrency } from "module/common/helpers/utils";
import { selectCompanyBalance } from "module/main/store/Company/selectors";

interface Props {}

const COMPANY_BALANCE_ID = "company-balance";

const CompanyBalance: React.FC<Props> = () => {
  const availableBalance = useSelector(selectCompanyBalance);

  const { extendedBalance, trimmedBalance } = useMemo(() => {
    const formattedBalance = formatCurrency(availableBalance);
    const extendedBalance = formatSpecialCurrency(availableBalance, 3);
    let trimmedBalance = formattedBalance;
    if (formattedBalance.length > 7) {
      trimmedBalance = formattedBalance.slice(0, -3);
    }
    return { extendedBalance, trimmedBalance };
  }, [availableBalance]);

  return (
    <>
      <p id={COMPANY_BALANCE_ID} className="m-0">
        <span className="textXL white pointer">{trimmedBalance}</span>
        <br />
        <span className="textM white pointer">Balance</span>
      </p>
      <UncontrolledTooltip placement="right" target={COMPANY_BALANCE_ID}>
        You have {extendedBalance} available to spend in Lead Sherpa.
      </UncontrolledTooltip>
    </>
  );
};

export default CompanyBalance;
