import { createAction } from "../redux-helpers";

export const FETCH_PROPERTIES_SUCCESS = "FETCH_PROPERTIES_SUCCESS";
export const fetchPropertiesSuccess = createAction(FETCH_PROPERTIES_SUCCESS);

export const UPDATE_PROPERTIES_STATUS = "UPDATE_PROPERTIES_STATUS";
export const updatePropertiesStatus = createAction(UPDATE_PROPERTIES_STATUS);

export const UPDATE_PROPERTIES_LIST = "UPDATE_PROPERTIES_LIST";
export const updatePropertiesList = createAction(UPDATE_PROPERTIES_LIST);

export const UPDATE_SINGLE_PROPERTY = "UPDATE_SINGLE_PROPERTY";
export const updateSingleProperty = createAction(UPDATE_SINGLE_PROPERTY);
