import React from "react";
import { Spinner } from "reactstrap";
// components
import {
  NoResults,
  SpinWrap,
  StyledNoResultsFoundImageMessage,
  StyledNoResultsImage,
} from "./styles";
// utils
import { FetchError, Fetching, Success } from "module/common/helpers/variables";

import NoResultsImg from "assets/images/no_results.svg";

interface Props {
  data?: any[];
  status: string;
  emptyResultsMessage?: string | React.ReactNode;
  showEmptyResultsImage?: boolean;
  networkError?: string;
  renderData: () => void;
  dataTest?: string;
  fullPage?: boolean;
  appWrapper?: boolean;
  innerRef?: React.LegacyRef<HTMLDivElement>;
  NoResultsComponent?: React.ComponentType;
  disableSearch?: boolean;
  className?: string;
}

const DataLoader: React.FC<Props> = ({
  data = [],
  status,
  emptyResultsMessage = "No Results Found",
  showEmptyResultsImage = false,
  networkError = "Cannot be displayed at this time. Please try again later.",
  renderData,
  dataTest,
  fullPage = true,
  appWrapper = false,
  innerRef = null,
  NoResultsComponent = NoResults,
  disableSearch = false,
  className = "",
}) => {
  if (status === Fetching) {
    return (
      <SpinWrap $topPad={fullPage} $appWrapper={appWrapper} data-test="spinner">
        <Spinner color="primary" />
      </SpinWrap>
    );
  }

  const renderFinalData = () => (
    <div ref={innerRef} data-test={dataTest} className={`${className} displayedData`}>
      {renderData()}
    </div>
  );

  if (data.length) return renderFinalData();

  switch (status) {
    case Success:
      return (
        <NoResultsComponent
          $showEmptyResultsImage={showEmptyResultsImage}
          data-test="empty-data-message"
        >
          {showEmptyResultsImage ? (
            <div className="mt-4">
              <StyledNoResultsImage src={NoResultsImg} />
              <div className="mt-4">
                <h4 className="font-weight-bold text-center">{emptyResultsMessage}</h4>
                {!disableSearch && (
                  <StyledNoResultsFoundImageMessage className="mt-1 textS text-center">
                    Please try searching with another term.
                  </StyledNoResultsFoundImageMessage>
                )}
              </div>
            </div>
          ) : (
            <span className="mx-3 textL">{emptyResultsMessage}</span>
          )}
        </NoResultsComponent>
      );
    case FetchError:
      return <NoResults data-test="network-error-message">{networkError}</NoResults>;
    default:
      return renderFinalData();
  }
};

export default DataLoader;
