import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";
import { DotState } from "./utils";

interface Props {
  dotState: DotState;
  dotClick?: () => void;
}

const Dot: React.FC<Props> = ({ dotState, dotClick }) => {
  return (
    <StyledDot dotState={dotState} onClick={dotClick} data-test="dot">
      <FontAwesomeIcon
        className={`${dotState === DotState.Complete ? "d-flex" : "d-none"} icon`}
        icon="check"
        color="white"
      />
    </StyledDot>
  );
};

interface StyleProps {
  onClick?: () => void;
  dotState: DotState;
}

const colors = {
  [DotState.Error]: "var(--red)",
  [DotState.Complete]: "var(--green)",
  [DotState.Active]: "var(--sherpaBlue)",
  [DotState.Inactive]: "var(--gray)",
};

const StyledDot = styled.span<StyleProps>`
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 10px;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  background-color: ${(props) => colors[props.dotState]};
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
  .icon {
    font-size: 8px;
  }
`;

export default Dot;
