import React from "react";
import { Button } from "reactstrap";
import { LoadingSpinner } from "../../common/components/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ExportButton(props) {
  const {
    dataTest = "export-button",
    color = "var(--sherpaBlue)",
    className = "",
    size = "1em",
    isLoading,
    onClick,
    iconOnly,
  } = props;

  return (
    <span>
      <Button
        className={`d-flex align-items-center pr-0 ${className}`}
        color="link"
        onClick={onClick}
        data-test={dataTest}
      >
        {!iconOnly ? "Export" : ""}
        <LoadingSpinner
          isLoading={isLoading}
          color={color}
          size={size}
          renderContent={() => (
            <FontAwesomeIcon icon="arrow-to-bottom" color={color} className="ml-1" />
          )}
        />
      </Button>
    </span>
  );
}

export default ExportButton;
