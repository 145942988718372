import React, { memo, useEffect, useState } from "react";
import { Button, Container, Row, Col, Label } from "reactstrap";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";

// components
import Modal from "module/common/components/Modal";
import PhoneNumbersPopover from "./PhoneNumbersPopover";

// redux related
import { fetchProperty } from "module/main/store/propertyStore/thunks";
import { getProperty } from "module/main/store/propertyStore/selectors";

// utils
import {
  formatDate,
  formatPhoneNumber,
  formatNumber,
  getGoogleMapAddressUrl,
} from "module/common/helpers/utils";

// hooks
import useWindowSize from "module/common/hooks/useWindowSize";

export const StyledLabelsBlock = styled.div`
  overflow-wrap: break-words;
  display: block;
`;

export const StyledLabel = styled(Label)`
  font-weight: 700 !important;
  width: 75%;
  color: var(--darkGray);
  height: ${(props) =>
    props.usetype ? (props.usetype.length > 11 ? "40px" : "auto") : "auto"};
`;

export const StyledColRightBorder = styled(Col)`
  border-right: ${(props) =>
    props.noRightBorder ? "none !important" : "solid 1px var(--mediumGray)"};
  padding-top: 0.4rem;
  margin-top: -0.4rem;
`;

const StyledMainData = styled.div`
  margin-top: 0.75rem;
`;

// const StyledSubtitle = styled(Label)`
//   color: var(--darkGray);
//   display: block;
// `;

const StyledImage = styled.img`
  border-radius: 50%;
  margin-bottom: 12px;
`;

const UseTypeSpan = styled.span`
  display: table;
  width: 75%;
  text-align: right;
  text-align-last: right
  height: ${(props) =>
    props.usetype ? (props.usetype.length > 11 ? "40px" : "auto") : "auto"};
`;

const PropertyDataModal = (props) => {
  const {
    id,
    isOpen,
    toggle,
    handleOnViewProspectsClick,
    toggleIsOpenPropertiesDetailsModal,
  } = props;

  const dispatch = useDispatch();

  // selectors
  const property = useSelector((state) => getProperty(state, id));

  const {
    mailingAddress = "",
    propertyAddress = "",
    tagsCount = {},
    streetViewUrl = "",
    zillowLink = "",
    isVacant = "",
    phoneRaw = [],
    campaigns = 0,
  } = property;

  // local state
  const [prospects, setProspects] = useState([]);
  const windowSize = useWindowSize();

  const distressIndicators = tagsCount.distressIndicators;
  const numOfPropertyTags = tagsCount.total;
  const numOfProspectTags = 0;
  const needsBreak = windowSize.width < 730;

  const numOfProspects = prospects.length;
  const dncInfo = `${
    prospects.filter((value) => value.doNotCall === true).length
  }/${numOfProspects}`;
  const campaignsInfo = `${campaigns}/${numOfProspects}`;
  const optedOutInfo = `${
    prospects.filter((value) => value.optedOut === true).length
  }/${numOfProspects}`;

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchProperty(id)).then((response) => {
        setProspects(response.prospects);
      });
    }
  }, [isOpen, property.partial, dispatch, id]);

  const googleMapsUrl = getGoogleMapAddressUrl(propertyAddress);

  const renderNameInformation = (names) => {
    const [firstName, lastName] = names;
    const checkFirstName =
      property.hasOwnProperty(firstName) && property[firstName] !== null
        ? `${property[firstName]} `
        : "";
    const checkLastName =
      property.hasOwnProperty(lastName) && property[lastName] !== null
        ? property[lastName]
        : "";

    const name =
      checkFirstName === "" && checkLastName === ""
        ? "--"
        : `${checkFirstName}${checkLastName}`;

    return name;
  };

  const onViewProspectsClick = () => {
    // close property data modal
    toggleIsOpenPropertiesDetailsModal();

    // open prospects data modal
    handleOnViewProspectsClick();
  };

  const actions = [
    {
      node: (
        <a className="textM" href={zillowLink} rel="noopener noreferrer" target="_blank">
          <FontAwesomeIcon icon={["far", "window-restore"]} className="mr-2" />
          View property on Zillow
        </a>
      ),
    },
    {
      node: (
        <Button
          className={handleOnViewProspectsClick ? `` : `d-none`}
          color="primary"
          onClick={onViewProspectsClick}
          data-test="view-prospect-button"
        >
          {" "}
          {`View Prospect${numOfProspects > 1 ? "s" : ""}`}
        </Button>
      ),
    },
  ];

  // STORY: https://app.clubhouse.io/leadsherpa/story/15043/remove-loan-information-in-the-property-details-modal

  // const renderLoanInformation = (type) => {
  //   const loanDataAttrInfo =
  //     type === "first"
  //       ? { subTitle: "Primary", attr: "First" }
  //       : { subTitle: "Secondary", attr: "Second" };

  //   return (
  //     <>
  //       <Row className={type === "second" ? "mt-3" : "mt-2"}>
  //         <Col className="px-3">
  //           <StyledSubtitle className="mb-2">
  //             {loanDataAttrInfo.subTitle} Loan{" "}
  //           </StyledSubtitle>
  //         </Col>
  //       </Row>
  //       <Row>
  //         <StyledColRightBorder className="px-3">
  //           <div className="d-flex">
  //             <StyledLabelsBlock>
  //               <StyledLabel className="mb-1">Loan Amount: </StyledLabel>
  //               <StyledLabel className="mb-1">Loan Lender: </StyledLabel>
  //             </StyledLabelsBlock>
  //             <div>
  //               <span className="d-block text-right mb-1 font-weight-bold">
  //                 {property.hasOwnProperty(
  //                   `cur${loanDataAttrInfo.attr}PositionOpenLoanAmount`
  //                 )
  //                   ? property[`cur${loanDataAttrInfo.attr}PositionOpenLoanAmount`]
  //                   : "--"}
  //               </span>
  //               <span className="d-block text-right mb-1 font-weight-bold">
  //                 {renderNameInformation([
  //                   `cur${loanDataAttrInfo.attr}PositionOpenLoanLenderNameFirst`,
  //                   `cur${loanDataAttrInfo.attr}PositionOpenLoanLenderNameLast`,
  //                 ])}
  //               </span>
  //             </div>
  //           </div>
  //         </StyledColRightBorder>
  //         <StyledColRightBorder className="px-3">
  //           <div className="d-flex">
  //             <StyledLabelsBlock>
  //               <StyledLabel className="mb-1">Loan Interest Rate: </StyledLabel>
  //               <StyledLabel className="mb-1">Available Equity </StyledLabel>
  //             </StyledLabelsBlock>
  //             <div>
  //               <span className="d-block text-right mb-1 font-weight-bold">
  //                 {property.hasOwnProperty(
  //                   `cur${loanDataAttrInfo.attr}PositionOpenLoanInterestRate`
  //                 )
  //                   ? property[`cur${loanDataAttrInfo.attr}PositionOpenLoanInterestRate`]
  //                   : "--"}
  //               </span>
  //               <span className="d-block text-right mb-1 font-weight-bold">
  //                 {property.hasOwnProperty("availableEquity")
  //                   ? property.availableEquity
  //                   : "--"}
  //               </span>
  //             </div>
  //           </div>
  //         </StyledColRightBorder>
  //         <StyledColRightBorder className="px-3">
  //           <div className="d-flex">
  //             <StyledLabelsBlock>
  //               <StyledLabel className="mb-1">Loan Date:</StyledLabel>
  //               <StyledLabel className="mb-1">Quitclaim: </StyledLabel>
  //             </StyledLabelsBlock>
  //             <div>
  //               <span className="d-block text-right mb-1 font-weight-bold">
  //                 {property.hasOwnProperty(
  //                   `cur${loanDataAttrInfo.attr}PositionOpenLoanRecordingDate`
  //                 )
  //                   ? property[`cur${loanDataAttrInfo.attr}PositionOpenLoanRecordingDate`]
  //                   : "--"}
  //               </span>
  //               <span className="d-block text-right mb-1 font-weight-bold">
  //                 {property.hasOwnProperty("quitclaimFlag") &&
  //                 property.quitclaimFlag !== null
  //                   ? property.quitclaimFlag
  //                     ? "Yes"
  //                     : "No"
  //                   : "--"}
  //               </span>
  //             </div>
  //           </div>
  //         </StyledColRightBorder>
  //         <Col className="px-3">
  //           <div className="d-flex">
  //             <StyledLabelsBlock>
  //               <StyledLabel className="mb-1 font-weight-bold">Loan Type:</StyledLabel>
  //             </StyledLabelsBlock>
  //             <div>
  //               <span className="d-block text-right mb-1 font-weight-bold">
  //                 {property.hasOwnProperty(
  //                   `cur${loanDataAttrInfo.attr}PositionOpenLoanType`
  //                 )
  //                   ? property[`cur${loanDataAttrInfo.attr}PositionOpenLoanType`]
  //                   : "--"}
  //               </span>
  //             </div>
  //           </div>
  //         </Col>
  //       </Row>
  //     </>
  //   );
  // };

  return (
    <Modal
      dataTest="property-data-info-modal"
      toggle={toggle}
      isOpen={isOpen}
      title="Property Information"
      headerStyles={{ background: "var(--white)", color: "black" }}
      headerClassName={"d-flex flex-sm-row flex-column"}
      actions={actions}
    >
      <Container data-test="property-data-container-modal" className="mb-4">
        <Row xs={needsBreak ? 2 : 4} className="pt-2">
          <Col className="px-3">
            <div className="d-flex align-items-center justify-content-center text-center">
              <a href={googleMapsUrl} rel="noopener noreferrer" target="_blank">
                <StyledImage
                  src={streetViewUrl}
                  title={propertyAddress}
                  height="200"
                  width="200"
                  className="img-fluid"
                />
              </a>
            </div>
          </Col>
          <StyledColRightBorder className="px-3" noRightBorder={needsBreak}>
            <div>
              <StyledLabelsBlock>
                <StyledLabel className="mb-2">{`Owner Name(s) & Number(s):`}</StyledLabel>
              </StyledLabelsBlock>
              {prospects.map((value, index) => {
                if (index > 2) return null; // don't show anything pass 3 numbers so we can see the +1 more, etc
                return (
                  <div key={index} className="mb-1">
                    <span className="d-block font-weight-bold">
                      {value.firstName} {value.lastName}
                    </span>
                    <span className="d-block">{formatPhoneNumber(value.phoneRaw)}</span>
                  </div>
                );
              })}
              {/** Filtering the numbers because we only need the items after the first 3*/}
              <PhoneNumbersPopover
                numbers={phoneRaw.filter((phone) => phoneRaw.indexOf(phone) > 3)}
                prospectInfo={prospects.filter(
                  (prospect) => prospects.indexOf(prospect) > 3
                )}
                id={property.id}
                threshold={3}
                addProspectName={false}
              />
            </div>
            <StyledMainData>
              <StyledLabelsBlock>
                <StyledLabel>Owner of Record</StyledLabel>
              </StyledLabelsBlock>
              <span className="d-block font-weight-bold mb-1">
                {renderNameInformation(["partyOwner1nameFirst", "partyOwner1nameLast"])}
              </span>
            </StyledMainData>
          </StyledColRightBorder>
          <StyledColRightBorder className="px-3">
            <StyledLabelsBlock>
              <StyledLabel className="mb-2">Property Address:</StyledLabel>
            </StyledLabelsBlock>
            <div>{propertyAddress}</div>
            <StyledMainData>
              <StyledLabelsBlock>
                <StyledLabel>Relative 1:</StyledLabel>
              </StyledLabelsBlock>
              {property.relatives && property.relatives[0]
                ? property.relatives[0].name
                : "--"}
            </StyledMainData>
          </StyledColRightBorder>
          <Col className="px-3">
            <StyledLabelsBlock>
              <StyledLabel className="mb-2">Mailing Address:</StyledLabel>
            </StyledLabelsBlock>
            <div>{mailingAddress}</div>
            <StyledMainData>
              <StyledLabelsBlock>
                <StyledLabel>Relative 2:</StyledLabel>
              </StyledLabelsBlock>
              {property.relatives && property.relatives[1]
                ? property.relatives[1].name
                : "--"}
            </StyledMainData>
          </Col>
        </Row>
        <Row className="mb-1 mt-2">
          <Col>
            <hr />
          </Col>
        </Row>
        <Row xs={needsBreak ? 2 : 4}>
          <StyledColRightBorder className="px-3">
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-2">Vacant: </StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {isVacant ? (isVacant === true ? "Yes" : "No") : "--"}
              </span>
            </StyledLabelsBlock>
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-2">Skip Traced: </StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {prospects.length ? "Yes" : "No"}
              </span>
            </StyledLabelsBlock>
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-2">Distress Indicators: </StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {distressIndicators}
              </span>
            </StyledLabelsBlock>
          </StyledColRightBorder>
          <StyledColRightBorder className="px-3" noRightBorder={needsBreak}>
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-1">DNC: </StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {dncInfo}
              </span>
            </StyledLabelsBlock>
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-1">Campaigns: </StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {campaignsInfo}
              </span>
            </StyledLabelsBlock>
          </StyledColRightBorder>
          <StyledColRightBorder className="px-3">
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-1">Prospect Statuses: </StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {numOfProspectTags}
              </span>
            </StyledLabelsBlock>
          </StyledColRightBorder>
          <Col className="px-3">
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-1">Opted Out: </StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {optedOutInfo}
              </span>
            </StyledLabelsBlock>
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-1">Property Tags: </StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {numOfPropertyTags}
              </span>
            </StyledLabelsBlock>
          </Col>
        </Row>
        <Row className="mb-1 mt-2">
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col className="px-3">
            <h4 className="font-weight-bold"> Property Details</h4>
          </Col>
        </Row>
        <Row xs={needsBreak ? 2 : 4} className="mt-2">
          <StyledColRightBorder className="px-3">
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-1">Beds: </StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {property.hasOwnProperty("bedroomsCount") ? property.bedroomsCount : "--"}
              </span>
            </StyledLabelsBlock>
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-1">BathFull:</StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {property.hasOwnProperty("bathCount") ? property.bathCount : "--"}
              </span>
            </StyledLabelsBlock>
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-1">BathPartial:</StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {property.hasOwnProperty("bathPartialCount")
                  ? property.bathPartialCount
                  : "--"}
              </span>
            </StyledLabelsBlock>
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-1">Sale Date: </StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {property.hasOwnProperty("saleDate")
                  ? formatDate(property.saleDate)
                  : "--"}
              </span>
            </StyledLabelsBlock>
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-1">Sale Price: </StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {property.hasOwnProperty("salePrice")
                  ? formatNumber({ value: property.salePrice, isCurrency: true })
                  : "--"}
              </span>
            </StyledLabelsBlock>
          </StyledColRightBorder>
          <StyledColRightBorder className="px-3" noRightBorder={needsBreak}>
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-1">Year Built: </StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {property.hasOwnProperty("yearBuilt") ? property.yearBuilt : "--"}
              </span>
            </StyledLabelsBlock>
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="d-inline-block w-25 mb-1" usetype={property.type}>
                Type:{" "}
              </StyledLabel>
              <UseTypeSpan usetype={property.type} className="mb-1 font-weight-bold">
                {property.hasOwnProperty("type") ? property.type : "--"}
              </UseTypeSpan>
            </StyledLabelsBlock>
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-1">Bldg Sq Ft: </StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {property.hasOwnProperty("buildingSqft") ? property.buildingSqft : "--"}
              </span>
            </StyledLabelsBlock>
            <StyledLabelsBlock className="d-flex">
              <StyledLabel className="mb-1">Lot Sq Ft: </StyledLabel>
              <span className="d-inline-block w-25 text-right mb-1 font-weight-bold">
                {property.hasOwnProperty("lotSqft") ? property.lotSqft : "--"}
              </span>
            </StyledLabelsBlock>
          </StyledColRightBorder>
          {needsBreak && (
            <Col xs={12} className="mb-1 mt-2">
              <hr />
            </Col>
          )}
          <Col xs={6} className="px-3">
            <div className="mb-1">
              <StyledLabelsBlock>
                <StyledLabel>Legal Description</StyledLabel>
              </StyledLabelsBlock>
            </div>
            <div className="font-weight-bold">
              {property.hasOwnProperty("legalDescription")
                ? property.legalDescription
                : "--"}
            </div>
          </Col>
        </Row>
        {/** When loan information is avaible we will add it back in properly */}
        {/* <Row className="mb-1 mt-2">
              <Col>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col className="px-3">
                <h3 className="font-weight-bold"> Loan Information</h3>
              </Col>
            </Row>
            {renderLoanInformation("first")} */}
        {/** Only display the second loan information if it exists */}
        {/* {!property.hasOwnProperty("curSecondPositionOpenLoanAmount") &&
                !property.hasOwnProperty("curSecondPositionOpenLoanType") &&
                !property.hasOwnProperty("curSecondPositionOpenLoanLenderNameFirst") &&
                !property.hasOwnProperty("curSecondPositionOpenLoanLenderNameLast") &&
                !property.hasOwnProperty("curSecondPositionOpenLoanInterestRate")
                  ? null
                  : renderLoanInformation("second")} */}
      </Container>
    </Modal>
  );
};

export default memo(PropertyDataModal);
