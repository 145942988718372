import { createAction } from "../redux-helpers";

export const COMPANY_INCREMENT_MESSAGES_SENT_TODAY =
  "COMPANY/INCREMENT_MESSAGES_SENT_TODAY";
export const COMPANY_UPDATE = "COMPANY/UPDATE";

export const setCompanyData = createAction(COMPANY_UPDATE);

export const companyIncrementMessagesSentToday = createAction(
  COMPANY_INCREMENT_MESSAGES_SENT_TODAY
);

export const UPDATE_USER_FEATURE_NOTIFICATION = "UPDATE_USER_FEATURE_NOTIFICATION";
export const updateUserFeatureNotification = createAction(
  UPDATE_USER_FEATURE_NOTIFICATION
);

export const UPDATE_COMPANY_BRAND_STATUS = "UPDATE_COMPANY_BRAND_STATUS";
export const updateCompanyBrandStatus = createAction(UPDATE_COMPANY_BRAND_STATUS);

export const UPDATE_COMPANY_TEMP_BRAND_STATUS = "UPDATE_COMPANY_TEMP_BRAND_STATUS";
export const updateCompanyTempBrandStatus = createAction(
  UPDATE_COMPANY_TEMP_BRAND_STATUS
);
