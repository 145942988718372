export const PROSPECT_DETAILS_SEND_MESSAGE_ACTION = "campaign/action:send-message";
export const PROSPECT_DETAILS_PUSH_TO_CAMPAIGN_ACTION =
  "campaign/action:push-to-campaign";

export const permissions = {
  master_admin: [],
  admin: [PROSPECT_DETAILS_PUSH_TO_CAMPAIGN_ACTION],
  staff: [PROSPECT_DETAILS_SEND_MESSAGE_ACTION],
  junior_staff: [],
};
