import { Col } from "reactstrap";
import isMobile from "detectDevice";
import styled from "styled-components";

interface PublicPageProps {
  isMobile: boolean;
  bg: string;
  isLoginPage: boolean;
}

interface LoginProps {
  isMarketing?: boolean;
}

export const PublicPageWrapper = styled.div<PublicPageProps>`
  position: absolute;
  width: 100vw;
  min-height: ${(props) => (props.isMobile ? `100%;` : `100vh;`)}
  height: ${(props) => !props.isMobile && `100vh`};
  background-image: url(${(props) => props.bg});
  background-position: center;
  background-size: cover;
  ${(props) => props.isMobile && `background-attachment: fixed;`}
  ${(props) => props.isMobile && `min-height: 100%;`}
  display: flex !important;
  align-items: center !important;
  justify-content: ${(props) => (props.isMobile ? "center" : "space-between")};
  overflow: hidden;
  & :only-child {
    ${(props) =>
      props.isLoginPage && !props.isMobile
        ? `
    width: 50vw;
    flex-basis: unset;
    flex-grow: unset;
    `
        : ""};
  }
`;

export const StyledLoginContainer = styled.div<LoginProps>`
  top: 0;
  left: 0;
  margin: 0;
  background-color: white;
  height: 100%;
  flex-basis: 50vw;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  @media (max-width: 900px) {
    width: 50vw;
    flex-basis: unset;
    flex-grow: unset;
  }
`;

export const SignupFormContainer = styled.div`
  max-width: 600px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: var(--pad2) ${isMobile ? "2rem" : "5rem"};
`;

export const LogoHolster = styled.div`
  text-align: center;
  margin-top: 1rem;
  img {
    height: 2.5rem;
  }
`;

export const PublicPageCol = styled(Col)`
  max-width: 600px;
`;

export const GuideLink = styled.a`
  color: var(--primary);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
`;

export const FindOutMoreLink = styled(GuideLink)`
  margin-left: 28px;
`;

export const HeaderContents = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
