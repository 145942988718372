import { ApiFetchStatus } from "module/common/helpers/variables";
import { produce } from "immer";
import { IState } from "./interfaces";

import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_ERROR,
  UPDATE_CATEGORIES,
  REMOVE_CATEGORY,
  REMOVE_TEMPLATE,
  SET_SCRIBE_SHERPA_CATEGORIES,
} from "./actions";

export const initialState: IState = {
  status: ApiFetchStatus.Initial,
  error: "",
  categories: {},
  scribeSherpaCategories: [],
};

const reducer = produce((draft: IState, action: any) => {
  switch (action.type) {
    case FETCH_CATEGORIES: {
      draft.status = action.payload;
      break;
    }
    case FETCH_CATEGORIES_ERROR: {
      draft.status = ApiFetchStatus.Error;
      draft.error = action.payload;
      break;
    }
    case FETCH_CATEGORIES_SUCCESS: {
      draft.status = ApiFetchStatus.Success;
      draft.categories = action.payload.categories;
      break;
    }
    case SET_SCRIBE_SHERPA_CATEGORIES: {
      draft.status = ApiFetchStatus.Success;
      draft.scribeSherpaCategories = action.payload;
      break;
    }
    case UPDATE_CATEGORIES: {
      draft.status = ApiFetchStatus.Success;
      const category = draft.categories[action.payload.id];
      draft.categories[action.payload.id] = {
        ...category,
        ...action.payload.data,
      };
      break;
    }
    case REMOVE_CATEGORY: {
      delete draft.categories[action.payload.id];
      draft.status = ApiFetchStatus.Success;
      break;
    }
    case REMOVE_TEMPLATE: {
      const newArray: any = draft.categories[action.payload.id].templates.filter(
        (e: any) => e !== action.payload.tempId
      );
      draft.categories[action.payload.id].templates = newArray;
      break;
    }
  }
}, initialState);

export default reducer;
