import { IModal } from "module/main/store/Global/Modals/interfaces";

const defaultTemplates = {
  Success: {
    title: "Success",
    icon: "check-circle",
    color: "var(--success)",
  },
  Error: {
    title: "Error",
    icon: "times-circle",
    color: "var(--danger)",
  },
  Warning: {
    title: "Warning",
    icon: "exclamation-circle",
    color: "var(--warning)",
  },
};

interface ModalTemplateProps extends IModal {
  closeModal: (...args: any[]) => void;
}

export const modalTemplate = ({
  type,
  message,
  id,
  image,
  title = defaultTemplates[type].title,
  icon = defaultTemplates[type].icon,
  color = defaultTemplates[type].color,
  btnOptions,
  open,
  closeModal,
}: ModalTemplateProps) => {
  return {
    id,
    type,
    message,
    title,
    image,
    icon,
    color,
    btnOptions: btnOptions?.map((btn: any) => {
      return { ...btn, onClick: () => btn.onClick(closeModal) };
    }),
    open,
  };
};
