import { IMessage } from "module/main/store/ProspectDetails/messages/interface";

export const getFailureMessageProps = (message: IMessage) => {
  const icon = "exclamation-circle";
  switch (message.failureReason) {
    case "spam":
      return {
        icon,
        message: `Message was filtered as spam. Please change your message and try again. Consider alternate contact methods if needed.`,
      };
    case "unreachable_or_disconnected":
      return {
        icon,
        message: `Recipient is unreachable or disconnected. Please try again later.`,
      };
    case "retryable_error":
      return {
        icon,
        message: `Message was unable to send, please try again later.`,
      };
    case "opted_out":
      return {
        icon,
        message: `Recipient has opted out of receiving messages.`,
      };
    case "unknown_error":
    default:
      return {
        icon,
        message: `Message failed. Please change your message and/or try again.`,
      };
  }
};
