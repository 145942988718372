import { createSelector as createCachedSelector } from "reselect";
import { createSelectorContext } from "../redux-helpers";

const createSelector = createSelectorContext(["quickReplies"]);

const selectQuickRepliesListSortOrder = createSelector("quickRepliesListSortOrder");
const selectQRList = createSelector("quickRepliesList");

export const selectProspectQRList = createSelector("prospectQRList");

export const selectQuickRepliesList = createCachedSelector(
  selectQuickRepliesListSortOrder,
  selectQRList,
  (quickRepliesListSortOrder: any, quickRepliesList: any) =>
    quickRepliesListSortOrder.map((id: any) => quickRepliesList[id])
);
