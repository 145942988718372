import { produce } from "immer";

import { IProperty } from "./interfaces";

// actions
import {
  FETCH_PROPERTIES_SUCCESS,
  UPDATE_PROPERTIES_STATUS,
  UPDATE_PROPERTIES_LIST,
  UPDATE_SINGLE_PROPERTY,
} from "./actions";

// utils
import { Initial, Success } from "module/common/helpers/variables";

const state = {
  properties: {} as { [key: number]: IProperty },
  status: Initial,
};

export const path = ["propertyStore"];

const reducer = produce((draft: any, action: any) => {
  switch (action.type) {
    case FETCH_PROPERTIES_SUCCESS: {
      draft.properties = action.payload;
      draft.status = Success;
      break;
    }
    case UPDATE_PROPERTIES_STATUS: {
      draft.status = action.payload;
      break;
    }
    case UPDATE_PROPERTIES_LIST: {
      draft.properties = { ...draft.properties, ...action.payload };
      draft.status = Success;
      break;
    }
    case UPDATE_SINGLE_PROPERTY: {
      const id = action.payload.id;
      draft.properties[id] = { ...draft.properties[id], ...action.payload };
      break;
    }
  }
}, state);

export default reducer;
