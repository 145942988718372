import React from "react";
import styled, { css } from "styled-components";
import { Button, UncontrolledTooltip } from "reactstrap";
import TabNav from "./TabNav";
import { history } from "module/common/helpers/history";
import IconBg from "../IconBg";
import WithPermissions from "../WithPermissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LoadingButton } from "../Buttons";
import { isMobile } from "detectDevice";

const BlockToFlex = styled.div`
  ${(props) =>
    props.theme.isMobile
      ? css`
          display: block;
        `
      : css`
          display: flex;
          align-items: center;
        `}
`;

const StyledHeader = styled.div`
  ${(props) =>
    props.sticky
      ? css`
          position: sticky;
          top: 0;
          z-index: 500;
        `
      : null}

  background: ${(props) => props.background || "var(--tealBlueGradientFlip)"};
  padding: 0.75rem var(--xpad) ${(props) => (props.toggleTab ? "0" : null)};
  color: ${(props) => props.color || "var(--white)"};
  ${(props) =>
    props.theme.isMobile
      ? css`
          margin-top: 60px;
          /* navbar icon + navlink padding + nav padding */
          margin-top: calc(22px + 4vw + 0.8rem);
        `
      : null}
  ${(props) =>
    props.theme.isDesktop
      ? css`
          padding-bottom: ${(props) => (props.toggleTab ? "0" : "var(--pad2)")};
          padding-top: var(--pad2);
          padding-left: var(--xpad);
          padding-right: var(--xpad);
          flex: 0 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `
      : null}
`;

const BackButton = styled(Button)`
  align-items: center;
  padding-top: 0 !important;
  padding-bottom: 0.4em !important;
  ${(props) =>
    props.theme.isDesktop
      ? css`
          padding: 0 !important;
          &:hover svg {
            color: white !important;
          }
        `
      : null}
`;

const BackArrowHolster = styled.div`
  display: inline-block;
  margin-right: 0.4em;
  img {
    margin-top: -3px;
    height: 0.75rem;
  }
  ${(props) =>
    props.theme.isDesktop
      ? css`
          margin: 0;
          padding: 0;
        `
      : null}
`;

const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0;
  .tabbed-header {
    max-width: 100%;
    flex: 1 1 auto;
  }
  ${(props) =>
    props.theme.isDesktop
      ? css`
          align-items: center;
        `
      : null}
`;

const StyledSecondaryBtnWrapper = styled.div`
  display: inline-block;
`;

const ActionsHolster = styled.div`
  flex-direction: column;
  align-items: flex-end;
  ${(props) =>
    props.theme.isDesktop
      ? css`
          flex-direction: row;
          .secondaryActions {
            margin-top: 0;
          }
        `
      : css`
          .secondaryActions {
            margin-top: var(--pad1);
          }
        `}
`;

function TabbedHeader(props) {
  const back = () => {
    history.goBack();
  };

  // button wrapper is there because a disabled button doesn't seem to trigger the tooltip on hover
  const mainActions =
    props.data.actions &&
    props.data.actions.main.map((a, idx) => {
      return !a.override ? (
        <React.Fragment key={`${a.action}-${idx}`}>
          <span
            id={`main-action-${idx}-wrapper`}
            className={`${
              a.displayOnTabIdx && a.displayOnTabIdx !== props.activeTab ? "d-none" : ""
            }`}
          >
            <LoadingButton
              size="md"
              id={`main-action-${idx}-loading`}
              color={a.btnType}
              className={`ml-1 ${
                a.text.length <= 6 ? "pl-3 pr-3" : ""
              } textL text-truncate`}
              onClick={a.action}
              key={`main-action-${idx}`}
              data-test={a["data-test"]}
              loading={a.loading}
              disabled={a.isDisabled || a.loading}
            >
              {a.icon && <FontAwesomeIcon icon={a.icon} className="mr-1" />}
              {a.text}
            </LoadingButton>
          </span>
          {a.isDisabled && (
            <UncontrolledTooltip
              key={`main-action-${idx}-tooltip`}
              placement="bottom"
              target={`main-action-${idx}-wrapper`}
            >
              {a.disabledMessage || "Disabled"}
            </UncontrolledTooltip>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment key={`${a.action}-${idx}`}>{a.customButton}</React.Fragment>
      );
    });

  const secondaryActions =
    props.data.actions && props.data.actions.secondary
      ? props.data.actions.secondary.map((cta, idx) => {
          return (
            <WithPermissions key={`${cta.name}-${idx}`} {...cta.permissions}>
              <StyledSecondaryBtnWrapper id={`secondary-action-${idx}-wrapper`}>
                <Button
                  id={cta.name}
                  onClick={cta.action}
                  className="p-0 ml-1"
                  color="link"
                  key={idx}
                  data-test={cta["data-test"]}
                  disabled={cta.isDisabled}
                >
                  <IconBg
                    color={cta.color || "darkNavy"}
                    $textCol={cta.textCol || "sherpaTeal"}
                    icon={cta.icon}
                    width="32px"
                    height="32px"
                    size="sm"
                  />
                </Button>
              </StyledSecondaryBtnWrapper>
              {cta.isDisabled && (
                <UncontrolledTooltip
                  key={`secondary-action-${idx}`}
                  placement="bottom"
                  target={`secondary-action-${idx}-wrapper`}
                >
                  {cta.disabledMessage || "Disabled"}
                </UncontrolledTooltip>
              )}
            </WithPermissions>
          );
        })
      : null;
  const backArrow = (
    <>
      {isMobile ? (
        <FontAwesomeIcon icon="arrow-left" color="rgba(0,0,0, .65)" className="mr-1" />
      ) : (
        <FontAwesomeIcon
          icon="arrow-alt-circle-left"
          size="2x"
          color="rgba(255,255,255, .75)"
          className="mr-2"
        />
      )}
    </>
  );

  return (
    <StyledHeader className="header" {...props} data-test="tabbed-header">
      <HeaderTop backbtn={props.data.hasBackButton ? 1 : 0}>
        <div className="tabbed-header">
          <BlockToFlex>
            {props.data.hasBackButton && (
              <BackButton
                data-test="prospect-details-back-button"
                className="text-dark textM d-flex pl-0"
                color="link"
                onClick={back}
              >
                <BackArrowHolster>{backArrow}</BackArrowHolster>
                {props.data.fromText}
              </BackButton>
            )}
            {props.children}
          </BlockToFlex>
        </div>
        {props.data.actions && !isMobile ? (
          <ActionsHolster className="d-flex">
            <div className="secondaryActions">
              {secondaryActions ? secondaryActions : null}
            </div>
            <div className="d-flex">{mainActions ? mainActions : null}</div>
          </ActionsHolster>
        ) : null}
      </HeaderTop>

      {props.toggleTab && (
        <TabNav
          data={props.data}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
          color={props.color}
          border={props.border}
        />
      )}
    </StyledHeader>
  );
}

export default TabbedHeader;
