import * as api from "./api";
import { updateProspectSuccess } from "../../prospectStore/actions";
import { addNewToast } from "../../Global/Toasts/actions";
import { prospectFetchSingle } from "../../prospectStore/thunks";
import { IProspect } from "../../prospectStore/interfaces";
import { formatErrorMessage } from "module/common/utils/utils";

const handleError = (err: any, dispatch: any, message: string) => {
  const errorMessage = formatErrorMessage(err, message);
  dispatch(
    addNewToast({
      message: errorMessage,
      color: "danger",
    })
  );
};

export const createProspectSequenceAction =
  (payload: { prospect: number; sequenceId: number }) => (dispatch: any) => {
    return api
      .createProspectSequence(payload)
      .then(({ data }: any) => {
        dispatch(
          addNewToast({
            message: "Prospect successfully added to Sequence.",
            color: "success",
          })
        );
        return dispatch(prospectFetchSingle(data.prospect));
      })
      .catch((err: any) =>
        handleError(err, dispatch, "An error occurred while adding prospect to Sequence.")
      );
  };

export const updateProspectSequenceAction =
  (
    prospectSequenceId: number,
    payload: {
      sequenceId: number;
    }
  ) =>
  (dispatch: any) => {
    return api
      .updateProspectSequence(prospectSequenceId, payload)
      .then(({ data }: any) => {
        dispatch(
          addNewToast({
            message: "Prospect successfully updated.",
            color: "success",
          })
        );
        return dispatch(prospectFetchSingle(data.prospect));
      })
      .catch((err: any) =>
        handleError(err, dispatch, "An error occurred while updating Prospect Sequence.")
      );
  };

export const deleteProspectSequenceAction =
  (id: number, prospectSequenceId: number) => (dispatch: any) => {
    return api
      .deleteProspectSequence(prospectSequenceId)
      .then(() => {
        dispatch(
          updateProspectSuccess({ id, activeProspectSequence: null } as IProspect)
        );
        dispatch(
          addNewToast({
            message: "Prospect successfully removed",
            color: "success",
          })
        );
        return true;
      })
      .catch((error: any) => {
        dispatch(
          addNewToast({
            message: "An error occurred while removing prospect.",
            color: "danger",
          })
        );
        throw error;
      });
  };
