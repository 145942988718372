import React, { useMemo } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingSpinner } from "./LoadingSpinner";

const IconBg = (props) => {
  const { icon, color, $textCol, format, width, loader, size } = props;

  // turn this into a useMemo
  const [circleColor, textColor] = useMemo(() => {
    if (!format || format !== "hex") {
      if (color && $textCol) {
        return [`var(--${color})`, `var(--${$textCol})`];
      }
      if (color) {
        return [`var(--${color}Highlight)`, `var(--${color})`];
      }
      return ["var(--blueHighlight)", ""];
    }
    if (format === "hex") {
      // add opacity to bg color
      return [`${$textCol}30`, $textCol];
    }
    return ["", ""];
  }, [color, $textCol, format]);

  const loadingSpinnerSize = width ? `calc(${width} / 2 )` : ".8em";
  const circleProps = {
    className: "iconBg",
    ...props,
    color: circleColor,
    $textCol: textColor,
  };

  if (loader) {
    return (
      <Circle {...circleProps}>
        <LoadingSpinner
          isLoading={loader.isLoading}
          color={loader.color}
          size={loadingSpinnerSize}
          renderContent={() => <FontAwesomeIcon color={textColor} icon={icon} />}
        />
      </Circle>
    );
  }

  return (
    <Circle {...circleProps}>
      <FontAwesomeIcon color={textColor} icon={icon} size={size} />
    </Circle>
  );
};

const Circle = styled.div`
  --size: ${(props) => props.width || "1.6em"};

  background: ${(props) => props.color};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  color: ${(props) => props.$textCol};
  flex-basis: var(--size);
  flex-shrink: 0;

  svg {
    margin: ${(props) => props.nudge || 0};
    font-size: calc(var(--size) / 2);
  }
`;

export default IconBg;
