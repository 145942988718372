import React from "react";
import { ErrorMessage, useFormContext } from "react-hook-form";
import { Label, Col, FormGroup, Row } from "reactstrap";
import styled from "styled-components/macro";

// components
import InputGroupBorder from "module/common/components/InputGroupBorder";
import PhoneNumberField from "./inputs/PhoneNumberField";
import UncontrolledTooltip from "../UncontrolledToolTip";
import SelectField from "./inputs/SelectField";
import DatePickerField from "./inputs/DatePickerField";
import SwitchField from "./inputs/SwitchField";
import TextAreaField from "./TextAreaField";
import NumberAndInputField from "./inputs/NumberAndInputField";
import PlusMinusNumberField from "./inputs/PlusMinusNumberField";
import StringCheckboxField from "./inputs/StringCheckboxField";

// utils
import { getIn } from "module/common/utils/utils";

export const RedAsterisk = () => <RedSpan data-test="red-asterisk">*</RedSpan>;

export const emailPatternParams = {
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Invalid Email address",
  },
};

export const requiredParams = { required: { value: true, message: "Required" } };
export const max255Characters = {
  maxLength: { value: 255, message: "Must be 255 characters in length or less." },
};
export const phoneRegex = {
  pattern: {
    value: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i,
    message: "Invalid Phone number",
  },
};

export const validatePhone = (phone) =>
  phone.replace(/\D/g, "").length === 10 ? undefined : "Must be 10 digits";

const getFormInputType = (inputType) => {
  switch (inputType) {
    case "date":
      return DatePickerField;
    case "phone":
      return PhoneNumberField;
    case "select":
      return SelectField;
    case "switch":
      return SwitchField;
    case "textarea":
      return TextAreaField;
    case "plusMinusNumber":
      return PlusMinusNumberField;
    case "stringCheckbox":
      return StringCheckboxField;
    case "number":
    case "input":
    default:
      return NumberAndInputField;
  }
};

/* Purpose of this function just returns the proper calling convention
 * for a components ref.  React-Select exposes ref via =ref= but Reactstraps =Input=
 * exposes the ref via =innerRef=
 *
 * Note: this function will need to be deprecated
 */
const getRefProps = (field, methods) => {
  // NOTE: We'll only support reactstrap stuff for now
  // we can change the interface after to use any ref-prop
  const { register } = methods;

  // we'll want to accept a function which accepts the react-hook-methods
  // in order to inject context for proper validation based on other fields
  const rules = typeof field.rules === "function" ? field.rules(methods) : field.rules;

  let ref = { innerRef: (element) => register(element, rules) };
  if (field.component === "select" || field.component === "switch")
    ref = { innerRef: () => register({ name: field.name }, rules) };

  return ref;
};

const shouldWrapWithBorder = (field) => {
  // NOTE: add here all the other options that require a border
  return (
    field.component === "input" ||
    field.component === "number" ||
    field.component === "phone" ||
    field.component === "date"
  );
};

export const renderLabel = (field) => {
  const shouldShowAsterisk = () => {
    if (typeof field.rules === "function") {
      // special case when we pass `rules` as a function
      return getIn(["required"], field.rules({}));
    }
    return getIn(["rules", "required"], field);
  };

  const isFieldRequired = shouldShowAsterisk();

  return (
    <div className="d-flex mt-2">
      <Label htmlFor={field.name} className="font-weight-bold pb-2">
        {field.label}
        {isFieldRequired ? <RedAsterisk /> : null}
      </Label>
      {field.tooltip ? (
        <UncontrolledTooltipWrapper className="d-inline-flex pb-2">
          <UncontrolledTooltip {...field.tooltip} />
        </UncontrolledTooltipWrapper>
      ) : null}
    </div>
  );
};

const getWrapperComponent = (field) => {
  return (
    field.componentWrapper || (shouldWrapWithBorder(field) && InputGroupBorder) || "div"
  );
};

const renderFormField = (field, methods) => {
  // NOTE: This is coupled with the way the component needs to be
  // registered into the form.  We should make custom-components that
  // accept the react-hook-form methods that will know how to register themselves.
  const { errors } = methods;
  const InputComponent = getFormInputType(field.component);
  const refProps = getRefProps(field, methods);

  let inputProps = field.inputProps;

  // expose methods to do more complicated flows
  if (typeof inputProps === "function") {
    inputProps = field.inputProps(methods);
  }

  // props shared by all input types
  const universalProps = {
    field: field,
    "data-test": `${field.name}-${field.component}`,
    name: field.name,
    ...inputProps,
    error: errors[field.name],
    ...refProps,
  };

  const Wrapper = getWrapperComponent(field);
  return (
    <Wrapper disabled={inputProps.disabled} error={errors[field.name]}>
      <InputComponent {...universalProps} />
    </Wrapper>
  );
};

export const renderError = (field, { errors }) => {
  return (
    <ErrorMessage
      data-test={`${field.name}-${field.component}-error`}
      errors={errors}
      name={field.name}
      className="textS"
      as={<StyledError />}
    />
  );
};

const renderGroupedFields = ({ idx, methods, fields, overrides }) => {
  return (
    <Row key={idx}>
      {fields.map((field) => {
        const { OverrideFieldControl, OverrideLabel, OverrideError, OverrideInput } =
          overrides[field.name] || {};

        const { xs, sm, md, lg, xl } = field;

        return field.name ? (
          <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} key={field.name}>
            <FormGroup className={field.formGroupClassName}>
              {OverrideFieldControl ? (
                <OverrideFieldControl field={field} fields={fields} />
              ) : (
                <>
                  {OverrideLabel ? (
                    <OverrideLabel field={field} methods={methods} />
                  ) : (
                    renderLabel(field, methods)
                  )}
                  {OverrideInput ? (
                    <OverrideInput field={field} methods={methods} />
                  ) : (
                    renderFormField(field, methods)
                  )}
                  {OverrideError ? (
                    <OverrideError field={field} methods={methods} />
                  ) : (
                    renderError(field, methods)
                  )}
                </>
              )}
            </FormGroup>
          </Col>
        ) : null;
      })}
    </Row>
  );
};

/**
 * Possibly move this into the /services/api folder
 * This assumes that the endpoint returns either an object of field errors
 * OR general errors
 */
export const fetchServerErrorMessage = (resErrors) => {
  //let serverErrors = resErrors.data.nonFieldErrors || []; // comment out to see if this is the correct response or not
  let serverErrors = [];
  const fieldErrors = [];

  if (Array.isArray(resErrors.data)) {
    serverErrors = resErrors.data;
  } else {
    Object.entries(resErrors.data).forEach(([field, errors]) => {
      let message = "";
      if (Array.isArray(errors)) {
        errors.map((error) => (message += `${error}|`));
        fieldErrors.push({ type: "validate", name: field, message });
      }
    });
  }

  return { serverErrors, fieldErrors };
};

export const renderFieldErrorMessages = ({ errors, name }) => {
  return (
    <ErrorMessage
      className="textS"
      errors={errors}
      name={name}
      data-test={`${name}-validation-error`}
      id={`${name}-validation-error`}
      as={<StyledError />}
    >
      {({ message }) => {
        const res = message.split("|");
        return res.map((err, idx) => {
          return <StyledFieldError key={idx}>{err}</StyledFieldError>;
        });
      }}
    </ErrorMessage>
  );
};

export const renderServerErrorMessages = (errors) => {
  return (
    <>
      {errors.map((error, idx) => (
        <StyledError key={idx}>{error}</StyledError>
      ))}
    </>
  );
};

const FormFieldsRenderer = ({ groupedFields, overrides = {} }) => {
  const methods = useFormContext();

  return (
    <div>
      {groupedFields.map((group, idx) =>
        renderGroupedFields({ idx, fields: group, methods, overrides })
      )}
    </div>
  );
};

export const RedSpan = styled.span`
  color: red !important;
`;

export const StyledError = styled.span`
  color: var(--red);
  margin-top: 0.75em;
`;

const StyledFieldError = styled.p`
  margin-bottom: 0px;
`;

const UncontrolledTooltipWrapper = styled.div`
  span {
    display: inline-flex;
  }
`;

export default FormFieldsRenderer;
