import AxiosInstance from "../../../common/utils/axiosConfig";

export const campaignProspectList = (campaignId: number, apiParams: any) => {
  const url = `campaign-prospects/?page_size=50&campaign=${campaignId}${apiParams}`;
  return AxiosInstance.get(url);
};

export const campaignProspectListNextPage = (url: string) => {
  return AxiosInstance.get(url);
};

export const campaignProspectUnread = () => {
  return AxiosInstance.get("campaign-prospects/unread/");
};

export const campaignProspectClone = (id: number, payload: any) => {
  const url = `/prospects/${id}/clone/`;
  return AxiosInstance.post(url, payload);
};

export const patchCampaignProspects = (id: number, payload: any) => {
  return AxiosInstance.patch(`/campaign-prospects/${id}/`, payload);
};

export const getCampaignProspectUnreadCount = () => {
  return AxiosInstance.get("/campaign-prospects/unread_count/");
};
