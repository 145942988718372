import styled, { css } from "styled-components";

interface SpinWrapProps {
  $appWrapper: boolean;
  $topPad: boolean;
}

export const SpinWrap = styled.div<SpinWrapProps>`
  text-align: center;
  padding: var(--pad8);

  [class*="spinner"] {
    width: 4rem;
    height: 4rem;
    border-width: 0.4em;
  }

  ${(props) =>
    props.$appWrapper
      ? `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    margin: 0 !important;

    & > [class*='spinner'] {
      width: 7rem;
      height: 7rem;
      border-width: 0.6em;
    }
  `
      : null};

  ${(props) =>
    props.theme.isDesktop
      ? css`
          margin-top: 60px;
          /* navbar icon + navlink padding + nav padding */
          margin-top: ${props.$topPad ? "calc(22px + 4vw + .8rem)" : 0};
        `
      : null}
`;

export interface NoResultsProps {
  $showEmptyResultsImage?: boolean;
}

export const NoResults = styled.div<NoResultsProps>`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: ${(props) =>
    props.$showEmptyResultsImage ? "var(--black)" : "var(--darkGray)"};
  padding: var(--pad3);
  line-height: 1.3 !important;
`;

export const StyledNoResultsImage = styled.img`
  height: 75%;
  width: 75%;
`;

export const StyledNoResultsFoundImageMessage = styled.h3`
  color: var(--darkGray);
`;
