import { useState, useCallback } from "react";

type UseModalType = (
  defaultValue?: boolean
) => [boolean, React.Dispatch<React.SetStateAction<boolean>>, () => void];

const useModal: UseModalType = (defaultValue = false) => {
  const [isOpen, setIsOpen] = useState(defaultValue);

  const toggle = useCallback(() => setIsOpen((state) => !state), []);

  return [isOpen, setIsOpen, toggle];
};

export default useModal;
