import AxiosInstance, { delayedRequest } from "../../../common/utils/axiosConfig";
import { fastSpinner } from "module/common/helpers/variables";

export const getProspect = (id: number) => {
  const url = `/prospects/${id}/`;
  return AxiosInstance.get(url, { params: { expand: "campaigns" } });
};

export const patchProspect = (id: number, payload: any) => {
  const url = `/prospects/${id}/`;
  return delayedRequest(AxiosInstance.patch(url, payload), fastSpinner);
};

export const prospectEmailToPodio = (id: number, payload: any) => {
  const url = `prospects/${id}/email_to_podio/`;
  return delayedRequest(AxiosInstance.post(url, payload), fastSpinner);
};

export const prospectPushToZapier = (id: number, payload: any) => {
  const url = `prospects/${id}/push_to_zapier/`;
  return delayedRequest(AxiosInstance.post(url, payload), fastSpinner);
};

export const prospectSetReminder = (id: number, payload: any) => {
  const url = `prospects/${id}/set_reminder/`;
  return AxiosInstance.post(url, payload);
};

export const prospectRemoveReminder = (id: number) => {
  const url = `prospects/${id}/remove_reminder/`;
  return AxiosInstance.post(url);
};

export const getMessages = (id: any) =>
  AxiosInstance.get(`/prospects/${id}/messages/`).then(({ data }) => data);

export const sendMessage = (id: number, body: any) =>
  AxiosInstance.post(`/prospects/${id}/send_message/`, body);

export const patchMessage = (id: any) =>
  AxiosInstance.patch(`/sms-messages/${id}/`, { unreadByRecipient: false });

export const prospectGetCampaigns = (id: number) => {
  const url = `/prospects/${id}/campaigns/`;
  return AxiosInstance.get(url);
};

export const assignNumber = async (id: number, payload = {}) => {
  const url = `/prospects/${id}/assign_number/`;
  return await AxiosInstance.patch(url, payload);
};

export const reportProspect = async (id: number) => {
  return await AxiosInstance.post(`/prospects/${id}/report/`);
};
