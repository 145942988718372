import React from "react";
import { Col } from "reactstrap";
import NewMessagesList from "../NewMessagesList";

const ProspectList = ({
  className,
  colClassName,
  isSequence,
  prefix,
  prospectsToItemList,
  children,
  id,
}) => {
  return (
    <Col xs={12} md={6} className={colClassName}>
      <div className={className}>
        {children}
        <NewMessagesList
          isSequence={isSequence}
          prefix={prefix}
          prospectsToItemList={prospectsToItemList}
          id={id}
        />
      </div>
    </Col>
  );
};

export default ProspectList;
