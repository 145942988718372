export const CAMPAIGNS_EXPORT_BY_PHONE_TYPE_ACTION =
  "campaign/action:export-by-phone-type";
export const CAMPAIGNS_EDIT_ACTION = "campaign/view:edit";
export const CAMPAIGNS_VIEW_PAGE = "campaign/view:page";
export const CAMPAIGN_SUBSCRIPTION_SIGNUP = "campaign/action:signup";

export const permissions = {
  master_admin: [],
  admin: [
    CAMPAIGNS_EXPORT_BY_PHONE_TYPE_ACTION,
    CAMPAIGNS_EDIT_ACTION,
    CAMPAIGN_SUBSCRIPTION_SIGNUP,
  ],
  staff: [],
  junior_staff: [CAMPAIGNS_VIEW_PAGE],
};
