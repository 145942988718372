import BillingPage from "module/common/components/Subscription/components/BillingPage";
import SubscriptionOptions from "module/common/components/Subscription/components/SubscriptionOptions";

const subscriptionOptionsStep = {
  stepTitle: "Choose your Subscription Plan",
  Component: SubscriptionOptions,
  group: 1,
};

const billingPageStep = {
  stepTitle: "Confirm Billing Information",
  Component: BillingPage,
  group: 2,
};

export const stepGroupObj = {
  subscriptionOptionsStep,
  billingPageStep,
};
