import React from "react";

export default function Title({ name, prefix, latestMessage }) {
  return (
    <div className="text-truncate ellipsis textM font-weight-normal">
      {prefix ? <b>{prefix}</b> : null} {name} —{" "}
      <span className="textS text-muted font-italic">{latestMessage}</span>
    </div>
  );
}
