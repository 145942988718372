import { createAction } from "../redux-helpers";

export const FETCH_UPLOAD_PROSPECTS_STATUS = "FETCH_UPLOAD_PROSPECTS_STATUS";
export const setUploadProspectsFetchStatus = createAction(FETCH_UPLOAD_PROSPECTS_STATUS);

export const FETCH_UPLOAD_PROSPECTS_SUCCESS = "FETCH_UPLOAD_PROSPECTS_SUCCESS";
export const setFetchUploadProspectsSuccess = createAction(
  FETCH_UPLOAD_PROSPECTS_SUCCESS
);

export const FETCH_UPLOAD_PROSPECTS_ERROR = "FETCH_UPLOAD_PROSPECTS_ERROR";
export const setFetchUploadProspectsError = createAction(FETCH_UPLOAD_PROSPECTS_ERROR);

export const FETCH_PROP_STACK_UPLOAD_PROSPECTS = "FETCH_PROP_STACK_UPLOAD_PROSPECTS";
export const setFetchPropStackUploadProspects = createAction(
  FETCH_PROP_STACK_UPLOAD_PROSPECTS
);

export const UPDATE_UPLOADS_TAGS_COUNT = "UPDATE_UPLOADS_TAGS_COUNT";
export const updateUploadsTagsCount = createAction(UPDATE_UPLOADS_TAGS_COUNT);
