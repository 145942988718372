import { getIn } from "module/common/utils/utils";
import { createSelectorContext } from "../../../../redux-helpers";
import { path } from "./reducer";
import { ICampaign } from "module/main/store/Campaigns/interfaces";

const createSelector = createSelectorContext(path);

export const uiGetCampaignPageActiveTab = createSelector("activeTab");
export const uiGetCampaignPageActiveSort = createSelector("activeSort");
export const uiGetCampaignPageActiveMarket = createSelector("activeMarket");

export const uiGetCampaigns = (state: any) => {
  const { campaigns: { campaigns = {} } = {} } = state || {};
  const activeTab = uiGetCampaignPageActiveTab(state);
  const { sortOrder, sortedBy, nextPage, searchTerm, count, market } =
    getIn([...path, "tabs", activeTab], state) || {};

  let selectedCampaigns = sortOrder.map((order: number) => campaigns[order]);

  // In case we're switching from campaign and data is not there or was wiped out
  if (selectedCampaigns.some((campaign: ICampaign) => !campaign)) {
    selectedCampaigns = [];
  }

  return {
    sortedBy,
    nextPage,
    campaigns: selectedCampaigns,
    count,
    activeSearchTerm: searchTerm,
    activeMarket: market,
  };
};

export const uiGetSMSCampaigns = (state: any) => {
  const {
    campaigns: { campaigns = {} },
  } = state;
  const activeTab = uiGetCampaignPageActiveTab(state);
  const { sortOrder, sortedBy, nextPage, searchTerm, count, market } = getIn(
    [...path, "tabs", activeTab],
    state
  );
  let selectedCampaigns = sortOrder.map((order: number) => campaigns[order]);

  // in case we're switching from campaign and data is not there or was wiped out
  if (selectedCampaigns.some((campaign: ICampaign) => !campaign)) {
    selectedCampaigns = [];
  }

  const selectedSMSCampaigns = Object.values(selectedCampaigns).filter(
    (c: any) => !c.isDirectMail
  );

  return {
    sortedBy,
    nextPage,
    smsCampaigns: selectedSMSCampaigns,
    count,
    activeSearchTerm: searchTerm,
    activeMarket: market,
  };
};

export const uiGetActiveProspect = createSelector("activeProspect");
