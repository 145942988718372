import { produce } from "immer";

import {
  REMINDERS_TAB_UPDATE_SELECTED_ITEM,
  REMINDERS_TAB_UPDATE_FILTER,
  REMINDERS_TAB_RESET_STATE,
  REMINDERS_TAB_POLL_MESSAGE_COUNT,
  REMINDERS_TAB_POLL_MESSAGES,
} from "./actions";

export const path = ["uiStore", "sequencesView"];

const initialState = {
  selectedItemId: "00",
  filter: { label: "My Sequences", value: "user" },
  polling: {
    fetchCount: true,
    fetchMessages: false,
  },
};

const reducer = produce((base: any, action: any) => {
  switch (action.type) {
    case REMINDERS_TAB_UPDATE_SELECTED_ITEM: {
      const { selectedItemId } = action.payload;
      base.selectedItemId = selectedItemId;
      break;
    }
    case REMINDERS_TAB_UPDATE_FILTER: {
      base.filter = action.payload;
      break;
    }
    case REMINDERS_TAB_RESET_STATE: {
      base.selectedItemId = initialState.selectedItemId;
      break;
    }
    case REMINDERS_TAB_POLL_MESSAGE_COUNT: {
      base.polling.fetchCount = action.payload;
      break;
    }
    case REMINDERS_TAB_POLL_MESSAGES: {
      base.polling.fetchMessages = action.payload;
      break;
    }
  }
}, initialState);

export default reducer;
