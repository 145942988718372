import React from "react";

import ImgSkipTrace from "assets/images/skipTrace.svg";
import CardBody from "./CardBody";
import GenericLandingPage from "./GenericLandingPage";
import UncontrolledToolTip from "../../../UncontrolledToolTip";
import SkipTraceCreateBilling from "./SkipTraceCreateBilling";

const SkipTraceLandingPage = () => {
  return (
    <GenericLandingPage
      title="Skip Trace: Average hit rates of 98% or higher"
      subTitle="Get premium skip tracing at the best prices available. Top quality data, with pricing as low as $0.10 per record"
      headerImg={ImgSkipTrace}
      useChildRow
      helpLink="https://leadsherpa.freshdesk.com/support/solutions/articles/44001608382-skip-trace-data-pricing"
      SignUpPage={SkipTraceCreateBilling}
      cards={[
        [
          [
            {
              icon: "rabbit-fast",
              title: "Automated processing",
              smallPadding: true,
              body: (
                <CardBody
                  header="Lists returned in real-time!"
                  lines={[
                    "HIGH hit rates - Averaging 98%+ across millions of records every",
                    "Search by property address - free append of owner name and mailing address.",
                    "25 record minimum for batch search - lowest in the industry.",
                  ]}
                />
              ),
            },
            {
              icon: "money-bill-wave",
              title: "Never purchase the same phone number twice",
              smallPadding: true,
              body: (
                <CardBody
                  header="Automated file de-duplication + suppression against existing data."
                  lines={[
                    "Only charge for hits - you pay only if we return data back to you.",
                    "Free litigator scrub on every search - check each phone number for litigious prospects.",
                  ]}
                />
              ),
            },
          ],
          [
            {
              icon: "chart-line",
              title: "Superior data",
              smallPadding: true,
              body: (
                <CardBody
                  header="LLC, trust and entity batch skip tracing.  Get hits on virtually every property."
                  smallPadding
                  lines={[
                    "Up to 3 phone numbers for primary search",
                    "Up to 2 relatives with 3 phone numbers  each",
                    "Up to 2 emails",
                    <>
                      Property owner <strong>Golden Address</strong>
                      <UncontrolledToolTip
                        id="goldenAddress"
                        icon="question-circle"
                        size="1x"
                        text={
                          "This is one of the most unique parts of our Skip Trace tool. This is the most updated mailing address the system can find for the property owner (if they have recently moved, etc). "
                        }
                      />
                    </>,
                    "Mailing address",
                    "Vacant property flag",
                    "Bankruptcy flag and date",
                    "Deceased flag and date",
                    "Foreclosure flag and date",
                    "Liens flag and date",
                    "Judgments flag and date",
                    "Litigator flag",
                  ]}
                />
              ),
            },
          ],
        ],
      ]}
    />
  );
};

export default SkipTraceLandingPage;
