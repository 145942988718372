import React from "react";
import { Link } from "react-router-dom";
// components
import ProspectUnreadAndReminderIcons from "module/campaign/components/ProspectIconContainer";
import DesktopKebab from "module/common/components/DesktopKebab";
import StatusWrapper from "./StatusWrapper";
import Title from "./Title";
// utils / store
import store from "module/main/store/store";
import { setSelectedReminder } from "module/main/store/SequencesStore/reminders/actions";
import { campaignProspectMarkAsViewed } from "module/main/store/campaignProspectStore/thunks";
import { unreadTabUpdateSelectedItem } from "module/main/store/uiStore/allUnreadPageView/actions";
import { setActiveCampaignProspect } from "module/main/store/uiStore/campaignsPageDesktopView/campaignsList/filterData/actions";
import { clearProspectCyclePath } from "module/main/store/uiStore/prospectDetailsView/actions";
import { remindersTabUpdateSelectedItem } from "module/main/store/uiStore/sequencesView/actions";
import { AUTO_DEAD_ALL, AUTO_DEAD_UNVIEWED } from "module/common/helpers/variables";

const getProspectData = (campaignProspect: any) => {
  const {
    id: prospectSequenceStepId,
    hasBeenViewed,
    step,
    prospect: {
      id: prospectId,
      leadStageTitle = "Follow-Up",
      displayMessage,
      hasUnreadSms,
      reminderAgent,
      reminderDateUtc,
      firstName,
      lastName,
    },
  } = campaignProspect;
  // Normalizes displayMessage
  const { message = "", dt = "" } = displayMessage || {};

  const detailsId = campaignProspect?.campaign?.id || prospectSequenceStepId;
  return {
    prospectSequenceStepId,
    hasBeenViewed,
    step,
    prospectId,
    leadStageTitle,
    messageDatetime: dt,
    latestMessage: message,
    hasUnreadSms,
    reminderAgent,
    reminderDateUtc,
    detailsId,
    prospectName: `${firstName} ${lastName}`,
  };
};

const getProspectDataFromUnread = (campaignProspect: any) => {
  const {
    prospectName,
    latestMessage,
    messageDatetime,
    leadStageTitle = "Follow-Up",
    prospectId,
    reminderAgent,
    reminderDateUtc,
    unreadCount = 0,
  } = campaignProspect;

  return {
    prospectId,
    leadStageTitle,
    messageDatetime,
    latestMessage: latestMessage.message,
    unreadCount,
    prospectName,
    reminderAgent,
    reminderDateUtc,
    hasUnreadSms: true,
    detailsId: campaignProspect.campaigns?.[0].id,
  };
};

const prospectToItemList =
  (opts: any, getProspectData: (c: any) => any) => (campaignProspect: any) => {
    const { activeFilter: { payload = {} } = {}, isSequence, prefix } = opts;
    const {
      prospectSequenceStepId,
      hasBeenViewed,
      step,
      prospectId,
      leadStageTitle,
      messageDatetime,
      unreadCount,
      hasUnreadSms,
      reminderAgent,
      reminderDateUtc,
      prospectName,
      detailsId,
      latestMessage,
    } = getProspectData(campaignProspect);

    const { stepType } = step || {};
    const isAutoDeadUnviewedActive =
      payload.name === AUTO_DEAD_UNVIEWED || payload.value === AUTO_DEAD_ALL;

    // sets the active campaign prospect
    const onClickItem = () => {
      if (isSequence) {
        store.dispatch(
          remindersTabUpdateSelectedItem({
            selectedItemId: `${prospectId}`,
          })
        );
        store.dispatch(setSelectedReminder({ ...campaignProspect }));
      } else {
        store.dispatch(unreadTabUpdateSelectedItem(prospectId));
      }
      store.dispatch(setActiveCampaignProspect(prospectId));

      // if the current filter is dead_auto_unviewed --> we need mark
      // them as viewed when user clicks on the list-item.
      if (isAutoDeadUnviewedActive)
        store.dispatch(campaignProspectMarkAsViewed(campaignProspect));
    };

    const actions = [
      {
        name: "View prospect",
        data: {
          color: "link",
          to: `/prospect/${prospectId}/details`,
          onClick: () => {
            // Prospect detail view doesn't cycle correctly, so removing that option for now
            store.dispatch(clearProspectCyclePath());
            store.dispatch(unreadTabUpdateSelectedItem(prospectId));
            store.dispatch(setActiveCampaignProspect(prospectId));
          },
        },
        Component: Link,
      },
    ];

    // using the active filter decide whether or not what controls the
    // =red-unread-dot=.
    const isRead = isSequence
      ? false
      : isAutoDeadUnviewedActive
        ? hasBeenViewed
        : !hasUnreadSms;

    const icon = (
      <ProspectUnreadAndReminderIcons
        showUnread
        id={prospectId}
        hasUnreadSms={isRead}
        unreadCount={unreadCount}
        showReminder={reminderAgent}
        reminderDateUtc={reminderDateUtc}
      />
    );

    const currentId = isSequence
      ? `reminder-details-prospect-${prospectSequenceStepId}`
      : `campaign-details-prospect-unread-${prospectId}-${detailsId}`;

    const leadClass = leadStageTitle === "Dead" ? "gray" : "";

    return {
      id: currentId,
      prospectId: prospectId || "",
      name: <Title prefix={prefix} name={prospectName} latestMessage={latestMessage} />,
      subInfo: (
        <div className={`${leadClass} text-dark textS text-truncate`}>
          {leadStageTitle}
        </div>
      ),
      iconCenter: true,
      icon: icon,
      mainContent: (
        <StatusWrapper
          prospectId={prospectId}
          stepType={stepType}
          time={messageDatetime}
          actions={actions}
        />
      ),
      desktopKebab: (
        <DesktopKebab
          idx={`${prospectId}-${detailsId}`}
          key={`${prospectId}-${detailsId}`}
        >
          {actions.map((action) => {
            const Component = action.Component;
            return (
              <Component
                data-test={`${action.name}-${detailsId}`}
                key={`${prospectId}-${detailsId}`}
                {...action.data}
              >
                {action.name}
              </Component>
            );
          })}
        </DesktopKebab>
      ),
      onClick: onClickItem,
    };
  };

export const prospectsToItemList = (opts: any) => (prospects: any) =>
  prospects.map(prospectToItemList(opts, getProspectData));

export const campaignProspectsToItemList = (opts: any) => (campaignProspects: any) =>
  campaignProspects.map(prospectToItemList(opts, getProspectDataFromUnread));
