import styled from "styled-components";

export const Wrapper = styled.div`
  ${(props) => !props.isChildRow && `width: 43%;`}

  color: var(--secondary);

  @media (max-width: 576px) {
    width: inherit;
  }

  &:not(:first-child) {
    margin-left: 30px;

    @media (max-width: 576px) {
      margin-left: inherit;
      margin-top: 51px;
    }
  }

  ${(props) =>
    props.isChildRow &&
    props.isMarginLeft &&
    `
      margin-left: 30px;

      @media (max-width: 768px) {
        margin-left: inherit;
        margin-top: 51px;
      }
    `}
`;

export const Title = styled.div`
  margin-top: 12px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
`;

export const Body = styled.div`
  margin-top: 12px;

  ${(props) => props.smallPadding && `margin-top: 5px;`}
`;
