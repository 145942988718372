import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledUl = styled.ul`
  padding-inline-start: 0px;
  margin-bottom: 0px;
`;

const StyledPopover = styled(UncontrolledPopover)`
  .arrow {
    &:after {
      border-top-color: ${(props) =>
        props.$darkMode ? "black" : "transparent"} !important;
      border-bottom-color: ${(props) =>
        props.$darkMode ? "black" : "transparent"} !important;
    }
  }
  // This is so it doesn't flicker too much
  // and for some reason allows the hover to work a bit better?
  .bs-popover-bottom,
  .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.3rem;
    margin-left: 0.2rem;
  }
`;

const StyledPopoverBody = styled(PopoverBody)`
  min-width: 100px !important;
  background-color: ${(props) => (props.$darkMode ? "black" : "transparent")};
  color: ${(props) => (props.$darkMode ? "white" : "black")};
  border-radius: 5px;
`;

const StyledListItemDeleteBtn = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const HoverPopoverListItem = ({ item, onDelete, toggle, id }) => {
  if (!item) {
    return null;
  }

  return (
    <li
      id={item.value || id}
      className="px-3 py-2 d-flex align-items-center justify-content-between"
    >
      <span id={`item-label-${item.value || id}`} className="item-content">
        {item.label}
      </span>
      {onDelete && (
        <StyledListItemDeleteBtn
          id={`remove-button-${item.value || id}`}
          className="item-remove-action ml-2"
          onClick={(e) => {
            toggle && toggle();
            e.stopPropagation();
            onDelete({ value: item.value || id, label: item.label, name: item.name });
          }}
          icon="times"
        />
      )}
    </li>
  );
};

const HoverPopoverList = ({
  data,
  target,
  placement,
  onDelete,
  darkMode,
  onHoverIsOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (isOpen && onHoverIsOpen) {
      onHoverIsOpen();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <StyledPopover
      onClick={(e) => e.stopPropagation()}
      $darkMode={darkMode}
      trigger="hover"
      placement={placement}
      target={target}
      toggle={toggle}
      isOpen={isOpen}
      data-test="hover-popover-list"
    >
      <StyledPopoverBody $darkMode={darkMode}>
        <StyledUl id="list-container" className="hoverlist-container">
          {data.map((item, idx) => (
            <HoverPopoverListItem
              item={item}
              key={idx}
              id={idx}
              onDelete={onDelete}
              toggle={toggle}
            />
          ))}
        </StyledUl>
      </StyledPopoverBody>
    </StyledPopover>
  );
};

const HoverablePopoverList = ({
  id,
  data,
  children,
  onDelete,
  darkMode = false,
  placement = "bottom",
  onHoverIsOpen,
}) => {
  return (
    <span id={id} className="item-col">
      {children}
      {data.length > 0 ? (
        <HoverPopoverList
          target={id}
          data={data}
          onDelete={onDelete}
          placement={placement}
          darkMode={darkMode}
          onHoverIsOpen={onHoverIsOpen}
        />
      ) : null}
    </span>
  );
};
export default HoverablePopoverList;
