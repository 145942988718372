import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import Icon from "../Icon";

const Holster = styled.div`
  flex-basis: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  margin-right: var(--pad1);
  ${(props) =>
    props.theme.isDesktop
      ? css`
          --size: 25px;
          flex-basis: var(--size);
          font-size: var(--size);
        `
      : null}

  svg {
    font-size: 60%;
  }
  cursor: ${(props) => (props.draggable ? "inherit" : "initial")};
`;

export const StyledIcon = styled(Icon)`
  flex-basis: 50%;
`;

function IconHolster(props) {
  if (!props.children) return null;

  return (
    <Holster center={props.center} className="iconHolster">
      {props.children}
    </Holster>
  );
}

IconHolster.propTypes = {
  /** Prop to determine if the icon should be centered */
  center: PropTypes.bool,
};

export default IconHolster;
