import { isMobile } from "../../../detectDevice";

interface IMainTheme {
  isMobile: boolean;
  isDesktop: boolean;
}

const mainTheme: IMainTheme = {
  isMobile: isMobile,
  isDesktop: !isMobile,
};

export { mainTheme };
