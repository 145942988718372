import React from "react";
import { Button, UncontrolledTooltip, Popover, PopoverBody } from "reactstrap";
import styled from "styled-components";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// components
import DesktopKebab from "../DesktopKebab";
import WithPermissions from "../WithPermissions";
import { SMS_TEMPLATES_ARCHIVE_ACTION } from "../../../main/permissions/smsTemplates";
import {
  QUICK_REPLIES_VIEW_LIST_ITEM_ACTIONS,
  QUICK_REPLIES_EDIT_ACTION,
  QUICK_REPLIES_DELETE_ACTION,
} from "../../../main/permissions/quickReplies";

// redux stuff
import store from "../../../main/store/store";
import { fetchTemplateCategories } from "module/main/store/Categories/thunk";
import { updateSmsTemplate } from "../../../main/store/SmsTemplateStore/thunk";
import { ColdMessagingDisabledReasons } from "module/main/store/interfaces";

/*
 * Helper functions to transform a campaign to an appropriate interface for the =ItemList=
 * component to render.
 */

const MessageWrapper = styled.div`
  width: 85%;
  color: var(--darkGray);
`;

const OwnerSpan = styled.span`
  text-transform: capitalize;
  padding-bottom: 0px;
  margin-bottom: 0px;
`;

export const renderDeliveryPercent = (deliveryPercent) => {
  const warningIcon = (
    <FontAwesomeIcon
      className="ml-1"
      color="var(--warning)"
      icon="exclamation-triangle"
    />
  );
  const warning = deliveryPercent > 69 ? null : warningIcon;

  return (
    <>
      {deliveryPercent !== null ? `${deliveryPercent}%` : `--`}
      {deliveryPercent !== null && warning}
    </>
  );
};
export const templateToItemList =
  ({ options, categories = { 1: { title: "" } } }) =>
  (template) => {
    const {
      id,
      templateName,
      isActive,
      createdBy = {},
      lastUpdated,
      message,
      category,
      deliveryPercent,
      responseRate,
    } = template;

    const categoryId = category === null ? 0 : category;

    const kebabActions = [
      {
        name: "Edit",
        component: Button,
        permissionProps: {},
        componentProps: {
          onClick: () => options.setTemplateToEdit(template),
          color: "link",
        },
      },
      {
        name: isActive ? "Archive" : "Unarchive",
        component: Button,
        permissionProps: {
          checkRole: true,
          permission: SMS_TEMPLATES_ARCHIVE_ACTION,
        },
        componentProps: {
          onClick: () => {
            store
              .dispatch(
                updateSmsTemplate({
                  id: template.id,
                  data: { isActive: !isActive },
                  type: isActive ? "archive" : "unarchive",
                })
              )
              .then(() => {
                store.dispatch(fetchTemplateCategories());
              });
          },
          color: "link",
        },
      },
    ];
    const getRowIconProps = () => {
      const rowProps = {
        iconProps: { color: "var(--danger)", icon: "times-circle" },
        text: "Invalid",
      };
      if (
        template.reasonColdMessagingDisabled === ColdMessagingDisabledReasons.SpamHigh
      ) {
        rowProps.iconProps.icon = "exclamation-triangle";
        rowProps.text = "Spam";
      } else if (template.spamCumulativeCount > 0) {
        rowProps.iconProps = { icon: "exclamation-triangle", color: "var(--warning)" };
        rowProps.text = "Spam Warning";
      } else if (template.isValid) {
        rowProps.iconProps = { icon: "check-circle", color: "var(--success)" };
        rowProps.text = "Valid";
      }
      return rowProps;
    };

    const rowIconProps = getRowIconProps();

    return {
      id: `templates-${id}`,
      cols: [
        {
          id: `status-col-${id}`,
          content: (
            <span className="item-col" key="status">
              <div
                id={`validInvalidTooltip-${id}`}
                className="d-flex align-items-center justify-content-center"
              >
                <FontAwesomeIcon {...rowIconProps.iconProps} />
              </div>
              <UncontrolledTooltip target={`validInvalidTooltip-${id}`} placement="right">
                {rowIconProps.text}
              </UncontrolledTooltip>
            </span>
          ),
          type: "node",
        },
        {
          id: `template-name-col-${id}`,
          content: (
            <span>
              <div className="ellipsis">{templateName}</div>
              <div>
                <MessageWrapper className="ellipsis">{message}</MessageWrapper>
              </div>
            </span>
          ),
        },
        {
          id: `template-created-by-name-${id}`,
          content: <OwnerSpan>{createdBy.fullName}</OwnerSpan>,
        },
        {
          id: `template-last-updated-${id}`,
          content: moment(lastUpdated).fromNow(),
        },
        {
          id: `template-category-${id}`,
          content: categories[categoryId] ?? "",
        },
        {
          id: `template-delivery-percent-${id}`,
          content: renderDeliveryPercent(deliveryPercent),
        },
        {
          id: `template-response-rate-${id}`,
          content: responseRate ? `${responseRate}%` : `--`,
        },
      ],
      actions: kebabActions,
      // below attributes are used for the sorting
      lastUpdated: moment(lastUpdated),
      category: categories[categoryId],
      deliveryPercent,
      responseRate,
    };
  };

export const templatesToItemList = (templates, options, categories) =>
  templates.map(templateToItemList({ options, categories }));

export const quickReplyToItemList = (quickReply, opts) => {
  const { id, question, message } = quickReply;

  const kebabActions = [
    {
      name: "Edit",
      data: {
        onClick: () => {
          opts.onEditClicked();
          opts.setEditingQuickReply(quickReply);
        },
      },
      component: Button,
      permissionProps: {
        checkRole: true,
        permission: QUICK_REPLIES_EDIT_ACTION,
      },
    },
    {
      name: "Delete",
      data: {
        onClick: () => opts.setQuickReplyToDelete(quickReply),
      },
      component: Button,
      permissionProps: {
        checkRole: true,
        permission: QUICK_REPLIES_DELETE_ACTION,
      },
    },
  ];

  return {
    id,
    name: question,
    spacingSizing: "0.375rem",
    subInfo: message,
    desktopKebab: (
      <WithPermissions
        key={`quick-replies-list-item-${id}`}
        checkRole
        permission={QUICK_REPLIES_VIEW_LIST_ITEM_ACTIONS}
      >
        <DesktopKebab
          key={`quick-replies-list-item-${id}`}
          idx={`quick-replies-list-item-${id}`}
        >
          {kebabActions.map((action, idx) => {
            const Component = action.component;
            return (
              <WithPermissions
                key={`quick-replies-list-item-${id}-action-${idx}`}
                {...action.permissionProps}
              >
                <Component
                  color="link"
                  data-test={action.name?.toLowerCase()}
                  key={`quick-replies-list-item-${id}-${idx}`}
                  {...action.data}
                >
                  {action.name}
                </Component>
              </WithPermissions>
            );
          })}
        </DesktopKebab>
      </WithPermissions>
    ),
    draggable: true,
  };
};

export const quickRepliesToItemList = (quickReplies, opts) => {
  return quickReplies.map((qr) => quickReplyToItemList(qr, opts));
};

export const processFilters = (source, filters) => {
  return filterResults(filters, source);
};

// TODO: THIS NEEDS TO BE REDUCED AND MORE GENERIC
const filterResults = (newFilters, source) => {
  const hasFilters = Object.entries(newFilters).map(([, filtersList]) => {
    if (Object.keys(filtersList).length !== 0) {
      return false;
    }
    return true;
  });

  // If there are no filters applied, just return everything
  if (hasFilters.filter((e) => e === false).length === 0) {
    return source;
  }

  let newFilteredResults = [];
  var updatedResults = {
    status: [],
    category: [],
  };
  // eslint-disable-next-line
  Object.entries(newFilters).map(([_, allFilters]) => {
    const currentSource = source;
    // eslint-disable-next-line
    Object.entries(allFilters).map(([_, sectionFilters]) => {
      const filterKey = sectionFilters.filterKey[0];
      const attrKey = sectionFilters.attrKey;
      for (let i = 0; i < currentSource.length; i++) {
        if (currentSource[i][attrKey] === sectionFilters.value) {
          if (updatedResults[filterKey].length > 0) {
            const existingResultInArray = updatedResults[filterKey].filter(
              (e) => e.id === currentSource[i].id
            );
            if (existingResultInArray.length === 0) {
              updatedResults[filterKey].push(currentSource[i]);
            }
          } else {
            updatedResults[filterKey].push(currentSource[i]);
          }
        }
      }
    });

    if (updatedResults.status.length === 0 || updatedResults.category.length === 0) {
      if (updatedResults.status.length === 0) {
        if (Object.keys(newFilters.status).length > 0) {
          newFilteredResults = updatedResults.status;
        } else {
          newFilteredResults = updatedResults.category;
        }
      } else if (updatedResults.category.length === 0) {
        if (Object.keys(newFilters.category).length > 0) {
          newFilteredResults = updatedResults.category;
        } else {
          newFilteredResults = updatedResults.status;
        }
      }
    } else {
      // compare and find the results
      const finalform = [...updatedResults.status, ...updatedResults.category];

      const result = finalform.filter(
        (a, i, aa) => aa.indexOf(a) === i && aa.lastIndexOf(a) !== i
      );

      newFilteredResults = result;
    }
  });

  return newFilteredResults;
};

export const createCategoryMapping = (categories = []) => {
  const categoryMapping = {};
  for (var i = 0; i < categories.length; i++) {
    categoryMapping[categories[i]["id"]] = categories[i].title;
  }
  // special one for those unspecified
  categoryMapping[0] = "";

  return categoryMapping;
};

export const validateCompanyNames = (values) => {
  if (values.length === 0) return "Company Name is required.";
  if (values.some((item) => item.length > 32)) {
    return "Each entry needs to be less than 32 characters.";
  }
  return undefined;
};

const StyledDynamicField = styled.span`
  background: var(--highlight);
  padding: 2px 5px;
  cursor: pointer;
`;

const CoNameContainer = styled.ul`
  padding: 0 !important;
  overflow: auto;
  margin-bottom: 0;
  li {
    width: 100%;
    list-style: none;
    padding: var(--pad1) var(--pad2);
    margin-right: 0;
    margin-bottom: 0;
    &:active,
    &:hover {
      background: var(--lightGray);
      cursor: pointer;
    }
  }
`;

const StyledPopover = styled(Popover)`
  .popover-inner {
    max-width: 400px;
    max-height: 200px;
    overflow-y: auto;
  }
  .popover-body {
    padding: 0 !important;
    margin: 0 !important;
  }

  .pointer {
    cursor: pointer;
  }

  .arrow {
    left: 2rem !important;
  }
`;

const StyledCoNameItem = styled.li`
  background: ${(props) => (props.$active ? "var(--lightGray)" : "var(--white)")};
`;

const StyledCoNameChevronDown = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-top: 0.1875rem;
`;

export const filterFields = (array, test, filterProp) => {
  if (test.length > 1) {
    const newArray = array.filter((item) => item !== filterProp);
    return newArray;
  }
  return array;
};

export const renderDynamicCompanyNameDropdown = (
  addDynamicField,
  coNameOptions,
  currentCoNameSelected,
  mainPopoverOpen,
  mainPopoverToggle,
  setCoNameSelected,
  type,
  setCoNameAltSelected,
  altPopoverOpen,
  altPopoverToggle
) => {
  const coNameSelected = currentCoNameSelected === 0 ? "0" : currentCoNameSelected;

  const toggle = type === "message" ? mainPopoverToggle : altPopoverToggle;
  const setCurrentSelected =
    type === "message" ? setCoNameSelected : setCoNameAltSelected;
  const isOpen = type === "message" ? mainPopoverOpen : altPopoverOpen;

  return (
    <div className="d-flex">
      <StyledDynamicField
        data-test="companyName-field"
        onClick={() => {
          toggle();
        }}
      >{`{CompanyName:${coNameSelected}}`}</StyledDynamicField>
      <StyledCoNameChevronDown
        icon="chevron-down"
        className="ml-1"
        id={`popover${type}`}
      />
      <StyledPopover
        placement="bottom"
        isOpen={isOpen}
        target={`popover${type}`}
        hideArrow
        toggle={toggle}
        trigger="legacy"
      >
        <PopoverBody>
          <CoNameContainer>
            {coNameOptions.map((data, index) => {
              return (
                <StyledCoNameItem
                  key={index}
                  $active={coNameSelected === index}
                  data-test={`company-name-${index}`}
                  onClick={() => {
                    setCurrentSelected(index);
                    toggle();
                    addDynamicField(`{CompanyName:${index}}`);
                  }}
                >
                  {index} - {data}
                </StyledCoNameItem>
              );
            })}
          </CoNameContainer>
        </PopoverBody>
      </StyledPopover>
    </div>
  );
};

export const smsTemplateHeaderInfo = {
  hasBackButton: false,
  tabs: [
    {
      idx: "1",
      name: "Initial Templates",
      icon: "list-ul",
    },
    {
      idx: "2",
      name: "Quick Replies",
      icon: "list-ul",
    },
  ],
  actions: {
    main: [
      {
        displayOnTabIdx: "2",
        btnType: "primary",
        text: "New Quick Reply",
        // this is for adding the onclick later
        action: "createQuickReply",
        "data-test": "desktop-new-quick-reply-button",
      },
    ],
  },
};
