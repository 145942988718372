import { createSelectorContext } from "../../redux-helpers";
import { path } from "./reducers";

const createSelector = createSelectorContext(path);

export const getSelectedItem = createSelector("selectedItemId");

export const getReminderFilter = createSelector("filter");

export const getMessageCountPollingStatus = createSelector(["polling", "fetchCount"]);
export const getMessagesPollingStatus = createSelector(["polling", "fetchMessages"]);
