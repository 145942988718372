import { createSelectorContext } from "../../redux-helpers";
import { path } from "./reducer";
import { createSelector as createCachedSelector } from "reselect";
import { IReminder } from "./interfaces";

const createSelector = createSelectorContext(path);

export const getSmsReminders = createSelector(["smsRemindersList"]);
export const getCallReminders = createSelector(["callRemindersList"]);
export const getSelectedReminder = createSelector(["selectedReminder"]);
export const getReminderStatus = createSelector(["status"]);

export const getSmsRemindersProspects = createCachedSelector(
  getSmsReminders,
  (smsReminders: Array<IReminder>) => smsReminders.map(({ prospect }) => prospect)
);

export const getCallRemindersProspects = createCachedSelector(
  getCallReminders,
  (callReminders: Array<IReminder>) => callReminders.map(({ prospect }) => prospect)
);

export const getSingleCallRemindersProspects = createCachedSelector(
  (_: any, id: number) => id,
  getCallRemindersProspects,
  (idx, prospects) => prospects.find((prospect) => prospect.id === idx)
);

export const getSingleSmsRemindersProspects = createCachedSelector(
  (_: any, id: number) => id,
  getSmsRemindersProspects,
  (idx, prospects) => prospects.find((prospect) => prospect.id === idx)
);

export const hasSmsRemindersPending = createCachedSelector(
  getSmsReminders,
  (smsReminders: Array<IReminder>) => smsReminders.length > 0
);

export const hasCallRemindersPending = createCachedSelector(
  getCallReminders,
  (callReminders: Array<IReminder>) => callReminders.length > 0
);

export const hasRemindersPending = createCachedSelector(
  getSmsReminders,
  getCallReminders,
  (smsReminders: Array<IReminder>, callReminders: Array<IReminder>) =>
    smsReminders.length + callReminders.length > 0
);
