import { produce } from "immer";
import { Fetching, Success, FetchError, Initial } from "module/common/helpers/variables";

import {
  DELETE_SEQUENCE_STEP,
  UPDATE_SEQUENCE_NAME,
  CREATE_NEW_SEQUENCE,
  SAVE_NEW_SEQUENCE,
  SET_SEQUENCE_STEPS,
  REMOVE_CURRENT_SEQUENCE,
} from "./actions";
import {
  FETCH_SINGLE_SEQUENCE,
  FETCH_SINGLE_SEQUENCE_FAILURE,
  FETCH_SINGLE_SEQUENCE_SUCCESS,
} from "../actions";
import { IState } from "./interfaces";

export const path = ["sequences", "sequenceDetails"];

const initialState: IState = {
  creatingNewSequence: false,
  selectedSequence: {
    id: 0,
    name: "",
    length: 0,
    createdBy: {
      id: 0,
      fullName: "",
      email: "",
      firstName: "",
      isActive: false,
      lastLogin: "",
      lastName: "",
    },
    lastModified: "",
    createdDate: "",
    company: 0,
    steps: [],
    prospectCount: 0,
  },
  status: Initial,
};

const reducer = produce((base: IState, action: any) => {
  switch (action.type) {
    case UPDATE_SEQUENCE_NAME:
      base.selectedSequence.name = action.payload;
      break;
    case SET_SEQUENCE_STEPS: {
      base.selectedSequence.steps = [...action.payload];
      break;
    }
    case DELETE_SEQUENCE_STEP: {
      const newSequenceSteps = [...base.selectedSequence.steps!];
      newSequenceSteps.splice(action.payload, 1);
      base.selectedSequence.steps = newSequenceSteps;
      break;
    }
    case FETCH_SINGLE_SEQUENCE: {
      base.status = Fetching;
      break;
    }
    case FETCH_SINGLE_SEQUENCE_FAILURE: {
      base.creatingNewSequence = false;
      base.status = FetchError;
      break;
    }
    case FETCH_SINGLE_SEQUENCE_SUCCESS: {
      base.creatingNewSequence = false;
      base.status = Success;
      base.selectedSequence = { ...action.payload };
      break;
    }
    case CREATE_NEW_SEQUENCE: {
      base.selectedSequence = {
        ...initialState.selectedSequence,
        ...action.payload,
      };
      base.creatingNewSequence = true;
      break;
    }
    case SAVE_NEW_SEQUENCE: {
      base.selectedSequence = { ...action.payload };
      base.creatingNewSequence = true;
      break;
    }
    case REMOVE_CURRENT_SEQUENCE: {
      base.selectedSequence = { ...initialState.selectedSequence };
      base.creatingNewSequence = false;
      break;
    }
  }
}, initialState);

export default reducer;
