import { permissions as accPermissions } from "./accountSettings";
import { permissions as prospectSearchPermissions } from "./prospectSearch";
import { permissions as phoneMangerPermissions } from "./phoneNumberManager";
import { permissions as smsTemplatesPermissions } from "./smsTemplates";
import { permissions as campaignsPermissions } from "./campaigns";
import { permissions as campaignDetailsPermissions } from "./campaignDetails";
import { permissions as skipTracePermissions } from "./skiptrace";
import { permissions as dashboardPermissions } from "./dashboard";
import { permissions as prospectDetailsPermissions } from "./prospectDetails";
import { permissions as quickRepliesPermissions } from "./quickReplies";
import { permissions as leadStagesPermissions } from "./leadStages";
import { permissions as smartStackerPermissions } from "./smartStacker";
import { permissions as propertyTagsPermissions } from "./propertyTags";
import { permissions as sequencesPermissions } from "./sequences";

export const roles: { [key: string]: any } = {
  master_admin: {
    permissions: [
      ...accPermissions.master_admin,
      ...accPermissions.admin,
      ...accPermissions.staff,
      ...accPermissions.junior_staff,

      ...phoneMangerPermissions.master_admin,
      ...phoneMangerPermissions.admin,
      ...phoneMangerPermissions.staff,
      ...phoneMangerPermissions.junior_staff,

      ...smsTemplatesPermissions.master_admin,
      ...smsTemplatesPermissions.admin,
      ...smsTemplatesPermissions.staff,
      ...smsTemplatesPermissions.junior_staff,

      ...prospectSearchPermissions.master_admin,
      ...prospectSearchPermissions.admin,
      ...prospectSearchPermissions.staff,
      ...prospectSearchPermissions.junior_staff,

      ...campaignsPermissions.master_admin,
      ...campaignsPermissions.admin,
      ...campaignsPermissions.staff,
      ...campaignsPermissions.junior_staff,

      ...campaignDetailsPermissions.master_admin,
      ...campaignDetailsPermissions.admin,
      ...campaignDetailsPermissions.staff,
      ...campaignDetailsPermissions.junior_staff,

      ...skipTracePermissions.master_admin,
      ...skipTracePermissions.admin,
      ...skipTracePermissions.staff,
      ...skipTracePermissions.junior_staff,

      ...dashboardPermissions.master_admin,
      ...dashboardPermissions.admin,
      ...dashboardPermissions.staff,
      ...dashboardPermissions.junior_staff,

      ...prospectDetailsPermissions.master_admin,
      ...prospectDetailsPermissions.admin,
      ...prospectDetailsPermissions.staff,
      ...prospectDetailsPermissions.junior_staff,

      ...quickRepliesPermissions.master_admin,
      ...quickRepliesPermissions.admin,
      ...quickRepliesPermissions.staff,
      ...quickRepliesPermissions.junior_staff,

      ...leadStagesPermissions.master_admin,
      ...leadStagesPermissions.admin,
      ...leadStagesPermissions.staff,
      ...leadStagesPermissions.junior_staff,

      ...smartStackerPermissions.master_admin,
      ...smartStackerPermissions.admin,
      ...smartStackerPermissions.staff,
      ...smartStackerPermissions.junior_staff,

      ...sequencesPermissions.master_admin,
      ...sequencesPermissions.admin,
      ...sequencesPermissions.staff,
      ...sequencesPermissions.junior_staff,

      ...propertyTagsPermissions.master_admin,
      ...propertyTagsPermissions.admin,
      ...propertyTagsPermissions.staff,
      ...propertyTagsPermissions.junior_staff,
    ],
  },
  admin: {
    permissions: [
      ...accPermissions.admin,
      ...accPermissions.staff,
      ...accPermissions.junior_staff,

      ...phoneMangerPermissions.admin,
      ...phoneMangerPermissions.staff,
      ...phoneMangerPermissions.junior_staff,

      ...smsTemplatesPermissions.admin,
      ...smsTemplatesPermissions.staff,
      ...smsTemplatesPermissions.junior_staff,

      ...prospectSearchPermissions.admin,
      ...prospectSearchPermissions.staff,
      ...prospectSearchPermissions.junior_staff,

      ...campaignsPermissions.admin,
      ...campaignsPermissions.staff,
      ...campaignsPermissions.junior_staff,

      ...campaignDetailsPermissions.admin,
      ...campaignDetailsPermissions.staff,
      ...campaignDetailsPermissions.junior_staff,

      ...skipTracePermissions.admin,
      ...skipTracePermissions.staff,
      ...skipTracePermissions.junior_staff,

      ...dashboardPermissions.admin,
      ...dashboardPermissions.staff,
      ...dashboardPermissions.junior_staff,

      ...prospectDetailsPermissions.admin,
      ...prospectDetailsPermissions.staff,
      ...prospectDetailsPermissions.junior_staff,

      ...quickRepliesPermissions.admin,
      ...quickRepliesPermissions.staff,
      ...quickRepliesPermissions.junior_staff,

      ...leadStagesPermissions.admin,
      ...leadStagesPermissions.staff,
      ...leadStagesPermissions.junior_staff,

      ...smartStackerPermissions.admin,
      ...smartStackerPermissions.staff,
      ...smartStackerPermissions.junior_staff,

      ...sequencesPermissions.admin,
      ...sequencesPermissions.staff,
      ...sequencesPermissions.junior_staff,

      ...propertyTagsPermissions.admin,
      ...propertyTagsPermissions.staff,
      ...propertyTagsPermissions.junior_staff,
    ],
  },
  staff: {
    permissions: [
      ...accPermissions.staff,
      ...accPermissions.junior_staff,

      ...phoneMangerPermissions.staff,
      ...phoneMangerPermissions.junior_staff,

      ...smsTemplatesPermissions.staff,
      ...smsTemplatesPermissions.junior_staff,

      ...prospectSearchPermissions.staff,
      ...prospectSearchPermissions.junior_staff,

      ...campaignsPermissions.staff,
      ...campaignsPermissions.junior_staff,

      ...campaignDetailsPermissions.staff,
      ...campaignDetailsPermissions.junior_staff,

      ...skipTracePermissions.staff,
      ...skipTracePermissions.junior_staff,

      ...dashboardPermissions.staff,
      ...dashboardPermissions.junior_staff,

      ...prospectDetailsPermissions.staff,
      ...prospectDetailsPermissions.junior_staff,

      ...quickRepliesPermissions.staff,
      ...quickRepliesPermissions.junior_staff,

      ...leadStagesPermissions.staff,
      ...leadStagesPermissions.junior_staff,

      ...smartStackerPermissions.staff,
      ...smartStackerPermissions.junior_staff,

      ...sequencesPermissions.staff,
      ...sequencesPermissions.junior_staff,

      ...propertyTagsPermissions.staff,
      ...propertyTagsPermissions.junior_staff,
    ],
  },
  junior_staff: {
    permissions: [
      ...accPermissions.junior_staff,
      ...phoneMangerPermissions.junior_staff,
      ...smsTemplatesPermissions.junior_staff,
      ...prospectSearchPermissions.junior_staff,
      ...campaignsPermissions.junior_staff,
      ...campaignDetailsPermissions.junior_staff,
      ...skipTracePermissions.junior_staff,
      ...dashboardPermissions.junior_staff,
      ...prospectDetailsPermissions.junior_staff,
      ...quickRepliesPermissions.junior_staff,
      ...leadStagesPermissions.junior_staff,
      ...smartStackerPermissions.junior_staff,
      ...sequencesPermissions.junior_staff,
      ...propertyTagsPermissions.junior_staff,
    ],
  },
};
