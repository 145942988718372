import { createAction } from "../redux-helpers";

export const SET_ZAP_WEB_HOOK_STATUS = "SET_ZAP_WEB_HOOKS_STATUS";
export const SET_ZAP_WEB_HOOKS = "SET_ZAP_WEB_HOOKS";
export const REMOVE_ZAP_WEB_HOOK = "REMOVE_ZAP_WEB_HOOK";
export const UPDATE_ZAP_WEB_HOOKS = "UPDATE_ZAP_WEB_HOOKS";
export const SET_ZAP_WEB_HOOK_ERROR = "SET_ZAP_WEB_HOOK_ERROR";
export const ADD_ZAP_WEB_HOOK = "ADD_ZAP_WEB_HOOK";
export const DEFAULT_ZAP_WEB_HOOK = "DEFAULT_ZAP_WEB_HOOK";

export const setZapWebHookStatus = createAction(SET_ZAP_WEB_HOOK_STATUS);
export const setZapWebHooks = createAction(SET_ZAP_WEB_HOOKS);
export const updateZapWebHooks = createAction(UPDATE_ZAP_WEB_HOOKS);
export const removeZapWebHook = createAction(REMOVE_ZAP_WEB_HOOK);
export const setZapWebHookError = createAction(SET_ZAP_WEB_HOOK_ERROR);
export const addZapWebHook = createAction(ADD_ZAP_WEB_HOOK);
export const defaultZapWebHook = createAction(DEFAULT_ZAP_WEB_HOOK);
