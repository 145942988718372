export const FETCH_PROSPECTS = "FETCH_PROSPECTS";
export const FETCH_PROSPECTS_FAILURE = "FETCH_PROSPECTS_FAILURE";
export const FETCH_PROSPECTS_SUCCESS = "FETCH_PROSPECTS_SUCCESS";

export const UPDATE_PROSPECT_LIST = "UPDATE_PROSPECT_LIST";

export const FETCH_PROSPECT = "FETCH_PROSPECT";
export const FETCH_PROSPECT_FAILURE = "FETCH_PROSPECT_FAILURE";
export const FETCH_PROSPECT_SUCCESS = "FETCH_PROSPECT_SUCCESS";

export const UPDATE_PROSPECT = "UPDATE_PROSPECT";
export const UPDATE_PROSPECT_FAILURE = "UPDATE_PROSPECT_FAILURE";
export const UPDATE_PROSPECT_SUCCESS = "UPDATE_PROSPECT_SUCCESS";

export const DELETE_PROSPECT = "DELETE_PROSPECT";
export const DELETE_PROSPECT_FAILURE = "DELETE_PROSPECT_FAILURE";
export const DELETE_PROSPECT_SUCCESS = "DELETE_PROSPECT_SUCCESS";

export const RESET_PROSPECTS = "RESET_PROSPECTS";
export const FETCH_PROSPECT_NEXT_PAGE = "FETCH_PROSPECT_NEXT_PAGE";

export const SET_FETCH_MESSAGES = "SET_FETCH_MESSAGES";
export const UPDATE_PROSPECT_DRAFT = "UPDATE_PROSPECT_DRAFT";
