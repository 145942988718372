export const SMART_STACKER_VIEW_PAGE = "smart-stacker/view:page";
export const SMART_STACKER_ADD_TAGS_ACTION = "smart-stacker/action:add-tags";
export const SMART_STACKER_EXPORT_ACTION = "smart-stacker/action:export";
export const SMART_STACKER_ARCHIVE_ACTION = "smart-stacker/action:archive";
export const SMART_STACKER_PUSH_TO_CAMPAIGN_ACTION =
  "smart-stacker/action:push-to-campaign";

export const permissions = {
  master_admin: [],
  admin: [
    SMART_STACKER_EXPORT_ACTION,
    SMART_STACKER_ARCHIVE_ACTION,
    SMART_STACKER_PUSH_TO_CAMPAIGN_ACTION,
  ],
  staff: [SMART_STACKER_VIEW_PAGE, SMART_STACKER_ADD_TAGS_ACTION],
  junior_staff: [],
};
