import styled, { css } from "styled-components/macro";
import { Popover, PopoverBody, Button } from "reactstrap";

export const StyledError = styled.div`
  color: var(--red);
  text-align: center;
  margin-top: 0.75em;
  width: 100%;
  div {
    text-align: center;
    margin: 1rem 0;
    margin-top: 1.5rem;
  }
`;

export const StyledSequenceStatus = styled.div`
  text-align: center;
  font-weight: bold;
  padding: auto;
  width: 100%;
  min-height: 5.5rem;
  line-height: 5.5rem;
  margin: 1rem 0;
  margin-top: 1.5rem;
}
`;

export const MessageTextarea = styled.textarea`
  overflow-y: auto;
  overflow-x: hidden;
  // this is 1em * lineheight of .textL
  height: ${(props) => (props.scrollHeight ? `${props.scrollHeight}px` : "1.32em")};
  min-height: 1rem;
  max-height: 10rem;
  border: none;
  flex: 1;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${(props) =>
    props.theme.isDesktop && props.$addSpace
      ? css`
          margin-top: 1.4rem;
        `
      : null}
`;

export const SendMessage = styled.form`
  padding: 0.8rem var(--pad3);
  background: white;

  ${(props) =>
    props.theme.isDesktop && props.$addSpace
      ? css`
          box-shadow: 0 0 12px -3px var(--mediumGray);
          border-radius: 8px;
          margin: 5px var(--pad3);
          padding: var(--pad2) var(--pad2);
          .inputGroup {
            --border: none !important;
          }
        `
      : null}
`;

export const StyledButton = styled(Button)`
  .fa-layer-group {
    color: var(--gray) !important;
  }
  &:hover {
    .fa-layer-group {
      color: var(--sherpaBlue) !important;
    }
  }
`;

export const StyledSaveAsQuickReply = styled.span`
  color: ${(props) => (!props.$disabled ? "var(--sherpaBlue)" : "var(--gray)")};
`;

export const QuickReplyDesktop = styled(Popover)`
  --xpad: var(--pad3);
  --ypad: var(--pad2);
  --xypad: var(--ypad) var(--xpad);

  .popover {
    max-width: 400px;
    min-width: 400px;
  }

  .popover-header {
    background-image: var(--tealBlueGradient);
    color: var(--white);
    display: flex;
    justify-content: space-between;
    padding: var(--xypad);
  }
  .popover-inner {
    max-height: 400px;
    min-height: 400px;
    overflow-y: auto;
  }
  .popover-body {
    padding: 0 !important;
  }

  .pointer {
    cursor: pointer;
  }

  .arrow {
    left: 2rem !important;
  }
`;

export const PopBody = styled(PopoverBody)`
  padding: var(--pad1) var(--pad1) !important;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;

  button {
    color: var(--darkNavy);
  }
`;
