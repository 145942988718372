import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { getCompanyNames } from "../../../../main/store/Company/selectors";
import { ErrorMessage, useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Label } from "reactstrap";

// components
import InputGroupBorder from "module/common/components/InputGroupBorder";
import { AutoSizedTextArea } from "../../formFields/TextArea";
import { StyledError } from "../../formFields/FormFieldsRenderer";
import { renderDynamicCompanyNameDropdown, filterFields } from "../utils";
//variable
import {
  dynamicFields,
  TEMPLATE_TEXT_AREA_MAX_LENGTH,
} from "module/common/helpers/variables";
import UncontrolledToolTip from "../../UncontrolledToolTip";

const Wrapper = styled("div")`
  .dynamicFieldSection {
    background: var(--white);
  }
  .dynamicFields {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: -0.6rem;

    .dynamicField {
      cursor: pointer;
      background: var(--highlight);
      padding: 2px 5px;
      margin-right: 0.8em;
      margin-bottom: 0.6rem;
    }
  }
`;

const maxNumChars = TEMPLATE_TEXT_AREA_MAX_LENGTH;
const checkIcon = (
  <FontAwesomeIcon icon="check-circle" color="var(--green)" className="mr-1" />
);
const xIcon = <FontAwesomeIcon icon="times-circle" color="var(--red)" className="mr-1" />;
const warningIcon = (
  <FontAwesomeIcon
    icon={["fas", "exclamation-circle"]}
    className="mr-1"
    color="var(--warning)"
  />
);

const Warning = styled.span`
  color: var(--warning);
`;

const WarningSplitMessage = ({ message }) => {
  return message.length <= maxNumChars && message.length > 160 ? (
    <Warning className="textS" data-test="split-message-warning">
      Message may be split into more than one message.
    </Warning>
  ) : null;
};

const TemplateTextArea = ({ field }) => {
  const companyData = useSelector(getCompanyNames);
  const methods = useFormContext();
  const message = methods.watch(field.name, "");
  const textAreaRef = useRef();
  const [coNameOptions, setCoNameOptions] = useState([]);
  const [coNameSelected, setCoNameSelected] = useState(0);
  const [isCoNamePopoverOpen, setIsCoNamePopoverOpen] = useState(false);

  useEffect(() => {
    setCoNameOptions(companyData);
  }, [companyData, setCoNameOptions]);

  const toggleIsCoNamePopoverOpen = () => setIsCoNamePopoverOpen(!isCoNamePopoverOpen);

  const filteredDynamicFields = filterFields(
    dynamicFields,
    coNameOptions,
    "{CompanyName}"
  );

  const addDynamicField = (dynamicField) => {
    methods.setValue(field.name, `${message}${dynamicField}`);
    textAreaRef.current.focus();
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <Label htmlFor="message">Message Analysis</Label>
        <span className="charCount">
          {message.length <= maxNumChars && message.length > maxNumChars / 2
            ? warningIcon
            : maxNumChars < message.length
              ? xIcon
              : checkIcon}
          {message.length}/{maxNumChars} Characters
          <UncontrolledToolTip
            id="message-analysis-warning"
            text="Messages longer than 160 characters may be split, by the carrier, into more than one message. For this reason we cap messages at 320 characters."
          />
        </span>
      </div>
      <InputGroupBorder border="full" data-test="input-group-border">
        <AutoSizedTextArea
          inputProps={{
            type: "textarea",
            rows: "3",
            name: field.name,
            autoFocus: true,
          }}
          textAreaRef={textAreaRef}
          innerRef={(e) => methods.register(e, field.rules)}
        />
      </InputGroupBorder>
      <WarningSplitMessage message={message} hasErrors={methods.errors[field.name]} />
      <ErrorMessage
        errors={methods.errors}
        name={field.name}
        as={<StyledError />}
        data-test="error"
      />
      <Wrapper data-test="wrapper">
        <div className="dynamicFieldSection mt-1">
          <span className="gray textS">Click to add dynamic fields to your message.</span>
          <ul className="dynamicFields p-0 mt-2">
            {filteredDynamicFields.map((field, index) => (
              <li
                key={index}
                className="dynamicField textS"
                onClick={() => addDynamicField(field)}
              >
                {field}
              </li>
            ))}
            {coNameOptions.length > 1
              ? renderDynamicCompanyNameDropdown(
                  addDynamicField,
                  coNameOptions,
                  coNameSelected,
                  isCoNamePopoverOpen,
                  toggleIsCoNamePopoverOpen,
                  setCoNameSelected,
                  "message"
                )
              : null}
          </ul>
        </div>
      </Wrapper>
    </>
  );
};

export default TemplateTextArea;
