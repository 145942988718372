import { produce } from "immer";
import { combineReducers } from "redux";

// actions
import { SET_SERVER_STATUS } from "./actions";

// reducers
import toasts from "./Toasts/reducers";
import uploads from "./uploads/reducer";
import exports from "./exports/reducer";
import prospects from "./prospects/reducer";
import serviceWorkers from "./serviceWorkers/reducer";
import banner from "./Banner/reducers";
import modals from "./Modals/reducers";

// interfaces
import { IState } from "./interfaces";

export const initial: IState = {
  serverStatus: "ok",
};

/**
 * Global store used for any and all random things that need to be stored in the global state that
 * doesn't fit in any other store.
 */
const reducer = produce((base: IState, action: any) => {
  switch (action.type) {
    case SET_SERVER_STATUS: {
      base.serverStatus = action.payload;
      break;
    }
  }
}, initial);

export const rootPath = ["Global"];
export const exportsPath = ["Global", "exports"];
export const uploadsPath = ["Global", "uploads"];
export const toastsPath = ["Global", "toasts"];
export const modalsPath = ["Global", "modals"];
export const prospectsPath = ["Global", "prospects"];
export const serviceWorkersPath = ["Global", "serviceWorkers"];

const root = combineReducers({
  global: reducer,
  exports,
  uploads,
  toasts,
  modals,
  prospects,
  serviceWorkers,
  banner,
});

export default root;
