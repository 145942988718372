import React from "react";
import { Popover } from "reactstrap";
import { StyledPopoverBody } from "./styles";

function CustomPopover({
  toggle,
  disabled,
  trigger = "legacy",
  placement = "bottom",
  target,
  isOpen,
  children,
}) {
  return (
    <Popover
      trigger={trigger}
      placement={placement}
      isOpen={isOpen}
      offset={-35}
      target={target}
      toggle={disabled ? () => null : toggle}
      data-testid="popover"
    >
      <StyledPopoverBody>{children}</StyledPopoverBody>
    </Popover>
  );
}

export default CustomPopover;
