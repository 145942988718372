import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import ErrorBoundary from "../../components/ErrorBoundary";
import { desktopPaths, mobilePaths } from "../../routes";
import { selectHasAuthToken } from "../../store/Auth/selectors";
import { isMobile } from "detectDevice";
import { setCookie } from "module/common/helpers/cookies";
import { ROUTES_COOKIE_NAME } from "module/common/utils/utils";
import { selectCompanyFeatures } from "../../store/Company/features/selectors";
import { companyHasFeature } from "../../store/Company/features/utils";
import { version2 } from "../../routes/utils";

export const ProtectedRoute = ({
  path,
  component,
  exact,
  location = {},
  protectedFeature,
}) => {
  const isAuth = useSelector(selectHasAuthToken);
  const companyFeatures = useSelector(selectCompanyFeatures);

  useEffect(() => {
    if (!isAuth) {
      const fullPath = location.pathname + location.search;
      setCookie(ROUTES_COOKIE_NAME, fullPath, 1);
    }
    // eslint-disable-next-line
  }, []);

  if (!isAuth) {
    return <Redirect to="/login" />;
  }

  const routes = isMobile ? mobilePaths : desktopPaths;

  const isAccessible = checkIfAccessible(routes, path);
  if (!isAccessible) {
    return <Redirect to="/campaigns" />; //always have the `/` route be rerouted to `/campaigns` no matter their subscription
  }

  if (protectedFeature && !companyHasFeature(companyFeatures, protectedFeature)) {
    return <Redirect to={location.pathname.replace(version2, "")} />;
  }

  return (
    <ErrorBoundary>
      <Route path={path} component={component} exact={exact} />
    </ErrorBoundary>
  );
};

const cleanPath = (path) => {
  return `/${path.split("/")[1] || ""}`;
};

const checkIfAccessible = (routes, path) => {
  const cleanedPath = cleanPath(path);
  return routes.some((route) => route === cleanedPath);
};
