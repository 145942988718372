import { ActiveProviders } from "module/common/helpers/variables";
import { connectAndSyncTelephony, getConnectAndSyncTelephony } from "./Telephony";

export const providersUrls = {
  [ActiveProviders.telnyx]: {
    connect: connectAndSyncTelephony,
    get: getConnectAndSyncTelephony,
    consoleUrl: "https://portal.telnyx.com/",
  },
  [ActiveProviders.twilio]: {
    connect: connectAndSyncTelephony,
    get: getConnectAndSyncTelephony,
    consoleUrl: "https://console.twilio.com/",
  },
};
