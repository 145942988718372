import React from "react";
import MainInfo from "../CampaignDetailsPage/MessagesTab/MainInfo";
import Indicator from "../CampaignDetailsPage/MessagesTab/Indicator";
import SubInfo from "../CampaignDetailsPage/MessagesTab/SubInfo";
import Title from "../CampaignDetailsPage/MessagesTab/Title";
import { UnreadMsgIndicator } from "../../../common/components/UnreadMsg";

/*
 * Helper functions to transform a campaign to an appropriate interface for the =ItemList=
 * component to render.
 */

export const prospectToItemList = (prospect) => {
  const {
    id,
    name = "hello",
    latestMessageReceived,
    hasUnreadSms,
    leadStageTitle,
  } = prospect;

  const { message = "You have the wrong number", dt = "2019-12-02T21:08:27Z" } =
    latestMessageReceived || {};

  return {
    name: <Title name={name} isRead={!hasUnreadSms} />,
    subInfo: <SubInfo status={leadStageTitle} />,
    mainInfo: <MainInfo message={message} />,
    icon: <UnreadMsgIndicator readable isRead={!hasUnreadSms} />,
    link: `/campaigns/${id}/details`,
    indicator: <Indicator time={dt} />,
    actions: [
      {
        icon: "verified",
        name: "Verified",
        link: "#",
        background: "green",
      },
      {
        icon: "dnc",
        name: "DNC",
        link: "#",
        background: "white",
      },
      {
        icon: "priority",
        name: "Priority",
        link: "#",
        background: "orange",
      },
      {
        icon: "qualified",
        name: "Qualified",
        link: "#",
        background: "purple",
      },
    ],
  };
};

export const prospectsToItemList = (prospects) => prospects.map(prospectToItemList);

// helper function to determine of the prospect is in a campaign
// checks to see if the prospect is in any campaigns
export const hasCampaignsCheck = (prospect) => {
  // the data can return either a list of campaigns OR a single campaign
  const campaignsArray = prospect.campaigns;
  const campaignObj = prospect.campaign;
  if (!prospect.campaigns) {
    return campaignObj && Object.keys(campaignObj).length > 0;
  }
  return campaignsArray.length;
};
