import { produce } from "immer";

import * as actions from "./actions";
import { IState, ITemplate } from "./interface";
import { Fetching, FetchError, Success } from "module/common/helpers/variables";
import { arrayToMapIndex } from "../utils";

const initialState: IState = {
  templates: [],
  smsTemplatesSortOrder: [],
  status: Fetching,
  error: "",
};

const reducer = produce((base: IState, action: any) => {
  switch (action.type) {
    case actions.POPULATE_SMS_TEMPLATES: {
      const { templates }: { templates: ITemplate[] } = action.payload;
      const curatedTemplates = templates.filter((t) => !!t.templateName);

      base.templates = { ...base.templates, ...arrayToMapIndex("id", curatedTemplates) };

      const orderedTemplates = curatedTemplates
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((template) => template.id)
        .reduce((accumulator: number[], number: number) => {
          // To avoid adding duplicated templates
          if (!base.smsTemplatesSortOrder.includes(number)) {
            accumulator.push(number);
          }
          return accumulator;
        }, []);

      base.smsTemplatesSortOrder = [...base.smsTemplatesSortOrder, ...orderedTemplates];
      base.status = Success;
      break;
    }
    case actions.SET_SMS_TEMPLATES_STATUS: {
      base.status = action.status;
      break;
    }
    case actions.SET_SMS_TEMPLATES_ERROR: {
      base.status = FetchError;
      base.error = action.error;
      break;
    }
    case actions.UPDATE_SMS_TEMPLATES: {
      const { payload } = action;
      base.templates = {
        ...base.templates,
        [payload.data.id]: { ...base.templates[payload.data.id], ...payload.data },
      };

      if (payload.updateSort) {
        base.smsTemplatesSortOrder = [...base.smsTemplatesSortOrder, payload.data.id];
      }
      break;
    }
    case actions.REMOVE_SMS_TEMPLATES: {
      const newTemplates = Object.values(base.templates).reduce(
        (acc: any, template: any) => {
          if (!action.payload.includes(template.id)) {
            acc[template.id] = template;
          }
          return acc;
        },
        {}
      );

      base.templates = newTemplates;
      break;
    }
  }
}, initialState);

export default reducer;
