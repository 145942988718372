import { combineReducers } from "redux";
import prospectSearchView from "./prospectSearchView/reducer";
import prospectDetailsView from "./prospectDetailsView/reducer";
import campaignMessagesView from "./campaignMessagesTabView/reducer";
import prospectDetailsPageView from "./prospectDetailsPageView/reducer";
import campaignDetailsPageView from "./campaignDetailsPageView/reducer";
import campaignsPageView from "./campaignsPageView/reducer";
import campaignsPageDesktopView from "./campaignsPageDesktopView/reducers";
import skipTraceView from "./skipTraceView/reducers";
import smartStackerListView from "module/main/store/uiStore/smartStackerListView/reducers";
import smsManagerPageView from "./smsManagerPageView/reducers";
import allUnreadPageView from "./allUnreadPageView/reducers";
import campaignDetailsPageDesktopView from "./campaignDetailsPageDesktopView/reducers";
import sequencesView from "./sequencesView/reducers";

const reducers = combineReducers({
  allUnreadPageView,
  campaignDetailsPageView,
  campaignMessagesView,
  campaignsPageDesktopView,
  campaignsPageView,
  prospectSearchView,
  prospectDetailsView,
  prospectDetailsPageView,
  skipTraceView,
  smartStackerListView,
  smsManagerPageView,
  campaignDetailsPageDesktopView,
  sequencesView,
});

export default reducers;
