import moment from "moment";
import React from "react";
import styled, { css } from "styled-components";
// utils
import { globalDynamicConfig } from "dynamicConfig";
// images
import NewYearsIcon from "assets/images/newYearsIcon.svg";
import NewYearsLogo from "assets/images/newYearsLogo.svg";
import XmasIcon from "assets/images/xmasIcon.svg";
import XmasLogo from "assets/images/xmasLogo.svg";
import SherpaLogo from "assets/images/sherpaLogo.svg";
import SherpaIcon from "assets/images/sherpaIcon.svg";

interface Props {
  type: "logo" | "icon";
  isOpen?: boolean;
}

// This logo is rendered inside a `styled` `CSSTransition` from "react-transition-group" (SlideIn)
// So it can't be a component, because the css classes can't get compiled, and the css transition
// don't work, so it had to be a function
export const renderSpecialLogo = ({ type, isOpen = false }: Props) => {
  const IconsAndLogos = {
    logo: {
      christmas: XmasLogo,
      newYear: NewYearsLogo,
      normal: SherpaLogo,
    },
    icon: {
      christmas: XmasIcon,
      newYear: NewYearsIcon,
      normal: SherpaIcon,
    },
  };

  const currentColor = getColorByEnv(globalDynamicConfig.config.REACT_APP_DYNAMIC_ENV);

  // moment.js months go from 0-11, and 12 is next year's January
  const xmasStartDate = moment.utc().month(11).date(20);
  const xmasEndDate = moment.utc().month(11).date(29);
  const newYearsEndDate = moment.utc().month(12).date(9);

  const isChristmasOrBefore = moment
    .utc()
    .isBetween(xmasStartDate, xmasEndDate, "days", "[)");
  const isNewYearsOrBefore = moment
    .utc()
    .isBetween(xmasEndDate, newYearsEndDate, "days", "[)");

  if (isChristmasOrBefore) {
    const specialImg = IconsAndLogos[type].christmas;
    return (
      <StyledImage
        className="special-image"
        src={specialImg}
        alt="Lead Sherpa"
        $color={currentColor}
      />
    );
  }

  if (isNewYearsOrBefore) {
    const specialImg = IconsAndLogos[type].newYear;
    return (
      <StyledNewYearsImage
        className="special-image"
        $isOpen={isOpen}
        src={specialImg}
        alt="Lead Sherpa"
        $color={currentColor}
      />
    );
  }

  return (
    <StyledImage
      className="normal-image"
      src={IconsAndLogos[type].normal}
      alt="Lead Sherpa"
      $color={currentColor}
    />
  );
};

interface StyledProps {
  $color: "var(--warning)" | "var(--success)" | "unset";
}

const StyledImage = styled.img<StyledProps>`
  background: ${(props) => props.$color};
`;

interface StyledNewYearProps {
  $isOpen: boolean;
}

const StyledNewYearsImage = styled(StyledImage)<StyledNewYearProps>`
  ${(props) =>
    !props.$isOpen
      ? css`
          min-width: 60px !important;
          margin-top: -0.65rem !important;
          margin-bottom: -0.65rem !important;
        `
      : null}
`;

const getColorByEnv = (env: string) => {
  switch (env) {
    case "staging":
      return "var(--warning)";
    case "ephemeral":
      return "var(--success)";
    default:
      return "unset";
  }
};
