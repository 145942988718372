import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useInterval from "module/common/hooks/useInterval";
import { history } from "module/common/helpers/history";
// store
import { addNewBanner } from "../store/Global/Banner/actions";
import { getBanner } from "../store/Global/Banner/selectors";
// utils
import { globalDynamicConfig } from "dynamicConfig";
import { ONE_SECOND_IN_MILLISECONDS } from "module/common/helpers/variables";
import { IBanner } from "../store/Global/Banner/interfaces";

// Very reserved id
const ENV_BANNER_ID = 69;

const useEnvBanner = () => {
  const dispatch = useDispatch();
  const banners: IBanner[] = useSelector(getBanner);
  const [liveHistory, setLiveHistory] = useState<number>(history.length);

  const envBanner = banners.find((banner) => banner.id === ENV_BANNER_ID);
  const {
    REACT_APP_DYNAMIC_ENV,
    REACT_APP_DYNAMIC_SENTRY_RELEASE,
    LEADSHERPA_IMAGE_TAG,
  } = globalDynamicConfig.config;

  if (!["staging", "ephemeral"].includes(REACT_APP_DYNAMIC_ENV)) return;

  const diffUrl = generateDiffURL(LEADSHERPA_IMAGE_TAG, REACT_APP_DYNAMIC_SENTRY_RELEASE);

  const message = (
    <h5>
      <strong>You are on {REACT_APP_DYNAMIC_ENV}. Version:</strong>{" "}
      {REACT_APP_DYNAMIC_SENTRY_RELEASE}.{" "}
      <a rel="noopener noreferrer" href={diffUrl} target="_blank">
        Check diff
      </a>
    </h5>
  );

  const dispatchArgs = {
    message,
    id: ENV_BANNER_ID,
    icon: "exclamation-circle",
    persist: true,
  };

  useInterval(() => {
    // Doing this because the history object is not updating automatically
    setLiveHistory(history.length);
  }, ONE_SECOND_IN_MILLISECONDS);

  useEffect(() => {
    if (envBanner) return;

    switch (REACT_APP_DYNAMIC_ENV) {
      case "staging":
        dispatch(addNewBanner({ ...dispatchArgs, color: "warning" }));
        break;
      case "ephemeral":
        dispatch(addNewBanner({ ...dispatchArgs, color: "success" }));
        break;
    }
    // eslint-disable-next-line
  }, [liveHistory]);
};

const generateDiffURL = (currentSha: string, releaseTag: string) => {
  try {
    const base = "https://gitlab.com/leadsherpa/leadsherpa/-/compare/";
    // Example: v24.6.12.1-5-ga2ae7ad -> v24.6.12.1
    const latestReleaseTag = releaseTag.split("-")[0];
    const diffPath = `${latestReleaseTag}...${currentSha}`;
    return base + diffPath;
  } catch (e) {
    // eslint-disable-next-line
    console.error("Error generating diff URL for environment banner", e);
    return "";
  }
};

export default useEnvBanner;
