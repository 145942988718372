import React from "react";
import styled from "styled-components/macro";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActionPanel from "../DesktopProspectDetailsPage/ActionPanel";
import { uiGetActiveProspect } from "../../../main/store/uiStore/campaignsPageDesktopView/campaignsList/filterData/selectors";
import { useSelector } from "react-redux";

export const ShadowWrapper = styled.div`
  box-shadow: 0 0 35px -10px #646464;
  z-index: 9;
  flex: 1;
  display: flex;

  // shenanigans to get the internal scrollign to work when in this popover mode
  .pageContent {
    display: flex;

    .displayedData {
      display: flex;
      flex: 1;

      & > div {
        flex: 1;

        .tabContent {
          overflow-y: auto;
        }
      }
    }
  }
`;

export const SlideIn = styled(CSSTransition)`
  position: absolute;
  top: 0;
  right: -100%;
  transition: right 0.3s ease-in-out;
  z-index: 999;
  width: 45vw;
  display: flex;
  height: 100%;

  @media (max-width: 767.98px) {
    width: 80vw;
  }

  &.slide {
    &-enter {
      right: -100%;
      &-done {
        right: 0;
      }
    }

    &-exit {
      &-active {
        right: -100%;
      }
    }
  }
`;

export const CloseButton = styled.button`
  border-radius: 2rem 0 0 2rem;
  background: var(--blueHighlight);
  color: var(--sherpaBlue);
  border: 0;
  height: 25px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 25px -5px #646464;
  margin-top: 4rem;
  svg {
    margin-left: 4px;
  }
`;

const ProspectSlideOut = ({ prospect, outsideRef, visible, onClose }) => {
  const prospectId = useSelector(uiGetActiveProspect);

  return (
    <>
      <SlideIn in={visible} timeout={300} appear classNames="slide">
        <div ref={outsideRef} data-test="prospect-slideout">
          <CloseButton onClick={onClose}>
            <FontAwesomeIcon icon="chevron-right" size="sm" color="var(--sherpaBlue)" />
          </CloseButton>
          <ShadowWrapper data-test="shadow-wrapper">
            <ActionPanel prospectId={prospectId} prospect={prospect} />
          </ShadowWrapper>
        </div>
      </SlideIn>
    </>
  );
};

export default ProspectSlideOut;
