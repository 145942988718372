import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import styled from "styled-components";
// components
import Icon from "../../../../common/components/Icon";
import { UnreadMsgIcon } from "../../../../common/components/UnreadMsg";
import { LogoutBtn } from "./styles";
// utils
import { mobileRoutes } from "../../../routes";
import { logout } from "../../../store/Auth/thunks";
import { getIsActive } from "../../../store/Calls/selectors";
import { getCampaignProspectsUnreadCount } from "../../../store/campaignProspectStore/selectors";
import { renderSpecialLogo } from "./logoUtils";

const NavIcon = (props) => {
  return (
    <div style={{ zIndex: 999 }} onClick={props.onClick} data-test="navbar-toggle-button">
      <Icon name="emptyHamburgerWhite" width="30px" />
    </div>
  );
};

const NavbarMobile = (props) => {
  const [collapse, setCollapse] = useState(false);
  const unreadSmsCount = useSelector(getCampaignProspectsUnreadCount);
  const isCallActive = useSelector(getIsActive);

  const toggleNavbar = () => setCollapse(!collapse);

  const routes = mobileRoutes.map((route, idx) => {
    if (!route.navIcon) return null;

    const activeClass = props.page.location.pathname === route.path ? "active" : "";

    return (
      <StyledNavItem className={activeClass} key={idx}>
        <FontAwesomeIcon icon={route.navIcon} size="lg" className="mr-2" color="white" />
        <NavLink
          className="navLink textXL stretched-link"
          data-test={`${route.name?.toLowerCase()}-nav-link`}
          tag={Link}
          to={route.path}
          onClick={toggleNavbar}
        >
          {route.name}
        </NavLink>
      </StyledNavItem>
    );
  });

  const dispatch = useDispatch();

  return (
    <div>
      <StyledNavbar fixed="top" color="dark" dark expand={false} data-test="navbar">
        <NavbarBrand tag="div" data-test="navbar-brand">
          <Link to="/">{renderSpecialLogo({ type: "logo" })}</Link>
        </NavbarBrand>
        {isCallActive ? (
          <Nav className="active-call" navbar>
            <NavItem>
              <ActiveCallLink to="/active-call">
                <FontAwesomeIcon icon="phone" size="lg" />
              </ActiveCallLink>
            </NavItem>
          </Nav>
        ) : null}
        <Nav className="messages" navbar>
          <NavItem data-test="messages-navbar">
            <Link style={{ color: "white" }} to={"/new-messages"}>
              <UnreadMsgIcon count={unreadSmsCount} showCount />
            </Link>
          </NavItem>
        </Nav>
        <NavbarToggler tag={NavIcon} onClick={toggleNavbar} />
        <Collapse isOpen={collapse} navbar data-test="navbar-collapse">
          <StyledNav navbar data-test="routes">
            {routes}
          </StyledNav>
          <StyledNav navbar>
            <NavItem>
              <NavLink
                onClick={() => dispatch(logout())}
                className="navLink textL"
                data-test="logout-link"
                tag={LogoutBtn}
              >
                Log out
              </NavLink>
            </NavItem>
          </StyledNav>
        </Collapse>
        <NavScreen $isOpen={collapse} onClick={toggleNavbar}></NavScreen>
      </StyledNavbar>
    </div>
  );
};

const StyledNavbar = styled(Navbar)`
  box-shadow:
    0 0 36px -4px #00000091,
    0 0 5px -1px #0000008c;
  min-height: calc(22px + 4vw + 0.8rem);
  padding: 0.5rem 1.5rem !important;

  .navbar-brand {
    flex-grow: 1;
    line-height: normal;

    img.normal-image {
      height: 2rem;
    }
    img.special-image {
      height: 2.5rem;
    }
  }
  .messages {
    margin-right: var(--pad3);
  }
`;

const StyledNav = styled(Nav)`
  padding: var(--pad1) 0 var(--pad3);
  border-top: 1px solid var(--charcoal);
  margin-top: var(--pad1);
`;

const StyledNavItem = styled(NavItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 3px solid transparent;
  padding: var(--pad1) var(--pad3);
  position: relative;

  &:active,
  &:hover {
    background: rgba(0, 0, 0, 0.25);
    border-left-color: rgba(0, 0, 0, 0.25);
  }
  &.active {
    background: rgba(0, 0, 0, 0.6);
    border-left-color: var(--sherpaBlue);
  }
`;

const NavScreen = styled.div`
  position: absolute;
  pointer-events: ${(props) => (props.$isOpen ? "all" : "none")};
  top: 100%;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.64);
  transition: opacity 0.3s;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
`;

const ActiveCallLink = styled(Link)`
  color: green;
  padding-right: 1rem;
`;

export default NavbarMobile;
