import React from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";

interface Props {
  size?: string;
  fullSize?: boolean;
}

const ReactstrapSpinner: React.FC<Props> = ({ size = "sm", fullSize = false }) => {
  if (fullSize) {
    return (
      <SpinnerWrapper className="d-flex align-items-center justify-content-center">
        <Spinner color="primary" />
      </SpinnerWrapper>
    );
  }
  return <Spinner color="primary" size={size} />;
};

const SpinnerWrapper = styled.div`
  width: 100vw;
  height: 100vh;

  & > [class*="spinner"] {
    width: 7rem;
    height: 7rem;
    border-width: 0.6em;
  }
`;

export default ReactstrapSpinner;
