import React from "react";

// components
import PropertyDataModal from "module/common/components/PropertyDataModal";
import useModal from "module/common/hooks/useModal";

const PropertyDataModalLink = ({ id, children }) => {
  const [isOpen, , toggle] = useModal();

  return !id ? null : (
    <>
      {children(toggle)}
      {isOpen && (
        <PropertyDataModal
          id={id}
          isOpen={isOpen}
          toggle={toggle}
          toggleIsOpenPropertiesDetailsModal={toggle}
        />
      )}
    </>
  );
};

export default PropertyDataModalLink;
