import moment from "moment-timezone";

export const strDateToMoment = (strDate) => {
  return moment(strDate);
};

export const getFormattedDateTime = (dt) => {
  const datetime = strDateToMoment(dt);
  const date = datetime.format("L");
  const time = datetime.format("LT");
  return [date, time];
};

export const checkWhenDate = (date) => {
  const today = getFormattedDateTime(moment().d)[0];
  const yesterday = getFormattedDateTime(moment().subtract(1, "day"))[0];
  return date === today ? "Today" : date === yesterday ? "Yesterday" : date;
};

export const timeBetween = (date1, date2, segment = "hours") => {
  const m1 = moment(date1);
  const m2 = moment(date2);
  return m1.diff(m2, segment, true);
};

export const checkIsUSHoliday = (date, includeObservableHoldays = true) => {
  var _holidays = {
    M: {
      //Month, Day
      "01/01": "New Year's Day",
      "07/04": "Independence Day",
      "11/11": "Veteran's Day",
      "12/25": "Christmas Day",
    },
    W: {
      //Month, Week of Month, Day of Week
      "1/3/1": "Martin Luther King Jr. Day",
      "2/3/1": "Washington's Birthday",
      "5/5/1": "Memorial Day",
      "9/1/1": "Labor Day",
      "10/2/1": "Columbus Day",
      "11/4/4": "Thanksgiving Day",
    },
  };
  var diff = 1 + (0 | ((new Date(date).getDate() - 1) / 7));
  var memorial =
    new Date(date).getDay() === 1 && new Date(date).getDate() + 7 > 30 ? "5" : null;

  // check every monday to see if a holiday was on the saturday or sunday previous
  // so it can be considered as an observable holiday
  if (includeObservableHoldays) {
    if (moment(date).day() === 1) {
      const saturdayBefore = moment(date).subtract(2, "days");
      const sundayBefore = moment(date).subtract(1, "days");

      const isHoliday =
        _holidays["M"][sundayBefore.format("MM/DD")] ||
        _holidays["M"][saturdayBefore.format("MM/DD")];
      if (isHoliday) return isHoliday;
    }
  }

  return (
    _holidays["M"][moment(date).format("MM/DD")] ||
    _holidays["W"][moment(date).format("M/" + (memorial || diff) + "/d")]
  );
};

export const getFormattedDateLocale = (date, locale = "en-GB") => {
  return date
    .toLocaleDateString(locale, {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    })
    .replace(/ /g, "/");
};
