import { createSelector as createCachedSelector } from "reselect";
import { ILeadStage } from "./interfaces";
import { createSelectorContext } from "../redux-helpers";

const createSelector = createSelectorContext(["leadStages"]);

export const getLeadStagesStatus = createSelector("isLoading");
export const getSortOrder = createSelector("leadStageSortOrder");
export const getLSList = createSelector("leadStageList");
export const getLeadStageSortOrder = createSelector("leadStageSortOrder");

export const getLeadStageList = createCachedSelector(
  getLeadStageSortOrder,
  getLSList,
  (leadStageSortOrder: number[], leadStageList: Record<string, ILeadStage>) =>
    leadStageSortOrder.map((id: number) => leadStageList[id])
);

export const getCustomLeadStages = createCachedSelector(
  getLeadStageList,
  (leadStages: any) => leadStages.filter((stage: { isCustom: boolean }) => stage.isCustom)
);

export const getDefaultLeadStages = createCachedSelector(
  getLeadStageList,
  (leadStages: any) =>
    leadStages.filter((stage: { isCustom: boolean }) => !stage.isCustom)
);

export const getActiveLeadStages = createCachedSelector(
  getLeadStageList,
  (leadStages: any) => leadStages.filter((stage: { isActive: boolean }) => stage.isActive)
);

export const getLeadStageName = createCachedSelector(
  (state: any) => getLeadStageList(state),
  (_: any, name: string) => name,
  (leadStages: any, name: string) =>
    leadStages.find(
      (stage: { leadStageTitle: string }) =>
        stage.leadStageTitle?.toLowerCase() === name?.toLowerCase()
    )
);
