interface IOptInMethods {
  value: string;
  displayName: string;
}

export const formatOptInToSelectOptions = (list: IOptInMethods[]) => {
  return list.map((item) => {
    return { label: item.displayName, value: item.value };
  });
};
