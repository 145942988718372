import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup } from "reactstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CallElapsedTime from "./CallElapsedTime";

import {
  getIsActive,
  getCallProspectData,
  getCall,
  getCallState,
  getCallStartTime,
} from "../../../main/store/Calls/selectors";
import { setCallState } from "../../../main/store/Calls/actions";

const FloatingActiveCallWrapper = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 10;
  top: 1rem;
  /* this is what centers your element in the fixed wrapper*/
  display: flex;
  flex-flow: column nowrap;
  justify-content: center; /* aligns on vertical for column */
  align-items: center; /* aligns on horizontal for column */
`;
const FloatingActiveCall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50vw;
  height: 10rem;
  text-align: center;
  background-color: var(--white);
  border-radius: 10px;
  border: 1px solid var(--lightGray);
  box-shadow:
    0 0 10px -5px #00000091,
    0 0 10px -5px #0000008c;
`;

const DesktopActiveCall = () => {
  const callProspectData = useSelector(getCallProspectData);
  const activeCall = useSelector(getCall);
  const callState = useSelector(getCallState);
  const isActive = useSelector(getIsActive);
  const callStartTime = useSelector(getCallStartTime);

  const dispatch = useDispatch();

  const hangup = () => {
    activeCall.hangup();
  };

  const mute = () => {
    activeCall.toggleAudioMute();
    if (callState === "Active") {
      dispatch(setCallState("Muted"));
    } else {
      dispatch(setCallState("Active"));
    }
  };

  return (
    <>
      {callProspectData && (
        <FloatingActiveCallWrapper>
          <FloatingActiveCall>
            <h4 className="font-weight-bold">
              <Link to={`/prospect/${callProspectData.id}/details`}>
                {callProspectData.name}
              </Link>
            </h4>
            <div className="mb-2">
              <CallElapsedTime
                callStartTime={callStartTime}
                isActive={isActive}
                callState={callState}
              />
            </div>

            <ButtonGroup size="sm">
              <Button outline color="secondary" onClick={mute} disabled={!isActive}>
                {callState === "Muted" ? (
                  <>
                    <span className="mr-1">
                      <FontAwesomeIcon icon="volume-up" />
                    </span>{" "}
                    Unmute
                  </>
                ) : (
                  <>
                    <span className="mx-1">
                      <FontAwesomeIcon icon="volume-mute" className="ml-1" />
                    </span>{" "}
                    Mute
                  </>
                )}
              </Button>
              <Button color="danger" onClick={hangup} disabled={!isActive}>
                <span className="mr-1">
                  <FontAwesomeIcon icon="phone-slash" color="white" className="ml-1" />
                </span>
                End Call
              </Button>
            </ButtonGroup>
          </FloatingActiveCall>
        </FloatingActiveCallWrapper>
      )}
    </>
  );
};

export default DesktopActiveCall;
