import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { updateFeatureNotifications } from "../../store/Company/thunks";
import Modal from "../../../common/components/Modal";

const StyledModal = styled(Modal)`
  .align-center {
    margin: 0 auto;
  }
`;

const FeatureNotificationModal = ({ notification }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { displayFeature, featureNotification, userProfile } = notification;
  const [isOpen, toggleIsOpen] = useState(displayFeature);

  const handleButtonPress = (dismiss) => {
    let payload = {
      isDismissed: true,
    };
    if (!dismiss) {
      payload = {
        isTried: true,
      };
    }

    dispatch(
      updateFeatureNotifications(userProfile.id, {
        featureNotificationId: featureNotification.id,
        ...payload,
      })
    );

    toggleIsOpen(false);
    const hasExternalLink = /(https?:\/\/|\.[\w\d]{2,})/i.test(
      featureNotification.buttonLink
    );
    if (!dismiss) {
      if (hasExternalLink) {
        window.location.href = featureNotification.buttonLink;
      } else {
        history.push(featureNotification.buttonLink);
      }
    }
  };

  return (
    <StyledModal
      dataTest="feature-notification-modal"
      className="d-flex flex-column"
      isOpen={isOpen}
      toggle={() => handleButtonPress(true)}
      title={featureNotification.modalTitle || "New feature!"}
      size="lg"
    >
      <h4 className="text-center mb-3 font-weight-bold">
        {featureNotification.headerCopy}
      </h4>
      <div className="d-flex justify-content-center">
        <img
          src={featureNotification.imagePath || featureNotification.imageUrl}
          alt="New feature image"
          height="400px"
          width="auto"
        />
      </div>
      <div className="d-flex justify-content-center mt-3 mb-3">
        <h6 className="w-50 text-center">{featureNotification.bodyCopy}</h6>
      </div>
      <div className="d-flex justify-content-center mb-4">
        <Button
          className="textL font-weight-bold align-center"
          color="primary"
          onClick={() => handleButtonPress(false)}
        >
          {featureNotification.buttonText}
        </Button>
      </div>
    </StyledModal>
  );
};

export default FeatureNotificationModal;
