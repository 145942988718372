import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from "../../../../../../detectDevice";
import { SQS } from "module/common/helpers/variables";
import styled from "styled-components/macro";
import { Button } from "reactstrap";

// redux related
import { getProspectsToCycle } from "../../../../../main/store/uiStore/prospectDetailsView/selectors";
import { ProspectCycleContext } from "../../../../../common/helpers/context";

const MessageInputHeader = ({
  currentTab,
  setCurrentTab,
  prospectId,
  prospectSequence,
}) => {
  const cycle = useSelector(getProspectsToCycle(prospectId));
  const { action } = useContext(ProspectCycleContext);
  const [idx, setIdx] = useState();

  useEffect(() => {
    if (cycle.length !== 0 && prospectId) {
      const index = cycle.findIndex((id) => id === prospectId);
      if (index !== -1) {
        setIdx(index);
      }
    }
  }, [cycle, prospectId]);

  const renderSequenceHeader = () => {
    return (
      <>
        <CalendarIcon
          sequence={prospectSequence}
          className="mr-1"
          icon={prospectSequence ? "calendar-check" : "calendar-plus"}
        />
        <span className="textM">
          {prospectSequence ? "Sequence Activated" : "Add to Sequence"}
        </span>
      </>
    );
  };

  if (isMobile) return null;

  return (
    <div className="d-flex" data-test="message-input-header">
      <Header className="flex-grow-1 textM">
        <StyledReplyButton
          onClick={() => setCurrentTab("reply")}
          color="link"
          className={`${currentTab === "reply" ? "active btn-sm" : "gray btn-sm"} textS`}
        >
          <span className="textM">Reply</span>
        </StyledReplyButton>
        <StyledSequenceButton
          onClick={() => setCurrentTab(SQS)}
          color="link"
          className={`${currentTab === SQS ? "active btn-sm" : "gray btn-sm"} textS`}
        >
          {renderSequenceHeader()}
        </StyledSequenceButton>
        <StyledAddNoteButton
          onClick={() => setCurrentTab("notes")}
          color="link"
          id="add-note-link"
          data-test="add-note-link"
          className={currentTab === "notes" ? "active btn-sm" : "gray btn-sm"}
        >
          <span className="textM">Add Note</span>
        </StyledAddNoteButton>
      </Header>
      {idx !== undefined ? (
        <CycleControlContainer
          className="d-flex align-items-center"
          data-test="cycle-control"
        >
          <span className={idx > 0 ? "navArrow" : "navArrow disabled"}>
            <a data-test="prospect-cycle-left" {...action(cycle[idx - 1])}>
              {prevIcon} Prev
            </a>
          </span>
          <span
            className={
              idx + 1 < cycle.length ? "navArrow ml-2" : "navArrow disabled ml-2"
            }
          >
            <a data-test="prospect-cycle-right" {...action(cycle[idx + 1])}>
              Next {nextIcon}
            </a>
          </span>
        </CycleControlContainer>
      ) : null}
    </div>
  );
};

const prevIcon = (
  <FontAwesomeIcon className="mr-1" icon="chevron-circle-left" size="sm" />
);
const nextIcon = (
  <FontAwesomeIcon className="ml-1" icon="chevron-circle-right" size="sm" />
);

const CalendarIcon = styled(FontAwesomeIcon)`
  color: ${(props) => (props.sequence ? "var(--green)" : "var(--sherpaBlue)")};
  font-size: 1.2rem;
`;

const CycleControlContainer = styled.div`
  .navArrow {
    flex: 0 0 auto;

    &.disabled a {
      color: var(--mediumGray) !important;
      opacity: 0.5;
    }

    a {
      white-space: nowrap;
      color: var(--sherpaBlue);
      cursor: pointer;
    }
  }
`;

const Header = styled.div`
  display: flex;
  button {
    padding: 0 0.8rem;
    position: relative;
    border-radius: 0;

    &:focus {
      box-shadow: unset;
    }

    &.btn-link,
    .btn-link:hover {
      text-decoration: none;
    }

    &.active {
      color: black;
    }

    &.active: after {
      content: "";
      position: absolute;
      bottom: -4px;
      height: 2px;
      width: calc(100% - 1.5rem);
      left: 0%;
      background: var(--sherpaBlue);
    }

    &:first-child {
      border-right: 1px solid var(--mediumGray);
      padding-left: 0 !important;
      padding-right: 1.5rem !important;
    }

    &:last-child {
      border-left: 1px solid var(--mediumGray);
      padding-right: 0 !important;
      padding-left: 1.5rem !important;

      &.active: after {
        left: 1.5rem;
      }
    }
  }
`;

const StyledReplyButton = styled(Button)`
  padding-right: 1.5rem !important;
  padding-left: 1rem !important;
`;

const StyledSequenceButton = styled(Button)`
  padding-right: 1rem !important;
  padding-left: 1.5rem !important;
`;

const StyledAddNoteButton = styled(Button)`
  padding-right: 1rem !important;
  padding-left: 1.5rem !important;
`;

export default MessageInputHeader;
