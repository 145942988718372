import React from "react";
import { useDispatch } from "react-redux";
import Modal from "../../../../common/components/Modal";
import { updateReminderAction } from "../../../../main/store/SequencesStore/reminders/thunks";
import { updateReminderSuccess } from "../../../../main/store/SequencesStore/reminders/actions";
import { deleteProspectSequenceAction } from "../../../../main/store/SequencesStore/prospects/thunks";
import useAsync from "../../../../common/hooks/useAsync";

const SkipStepModal = ({
  isOpen,
  resetTab,
  toggle,
  prospectId,
  prospectSequenceId,
  prospectSequenceStepId,
  stepType,
}) => {
  const dispatch = useDispatch();
  const [executeSkip, isSkipLoading] = useAsync({
    asyncFunc: () => {
      return dispatch(updateReminderAction(stepType, prospectSequenceStepId, "skip"));
    },
  });
  const [executeRemove, isRemoveLoading] = useAsync({
    asyncFunc: () => {
      return dispatch(deleteProspectSequenceAction(prospectId, prospectSequenceId));
    },
  });

  const skipStep = () => {
    executeSkip().then(() => {
      toggle();
      dispatch(resetTab());
    });
  };

  const removeReminder = () => {
    executeRemove().then(() => {
      dispatch(updateReminderSuccess({ stepType, prospectSequenceStepId }));
      toggle();
      dispatch(resetTab());
    });
  };

  const disabled = isRemoveLoading || isSkipLoading;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title="Skip this step"
      size="md"
      btnOptions={[
        {
          name: "Cancel",
          color: "dark",
          onClick: toggle,
          disabled,
        },
        {
          name: "Remove from sequence",
          color: "primary",
          outline: true,
          loading: isRemoveLoading,
          disabled,
          onClick: removeReminder,
        },
        {
          name: "Skip step",
          color: "primary",
          loading: isSkipLoading,
          disabled,
          onClick: skipStep,
        },
      ]}
      className="text-center"
      btnOptionsClassName="justify-content-between"
    >
      <p>
        This will permanently skip this step in the sequence. The waiting period for the
        next step will start now.
      </p>
      <p>You may also opt to remove them from the full sequence.</p>
    </Modal>
  );
};

export default SkipStepModal;
