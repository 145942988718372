import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MessageWarning = ({ message = "" }) => {
  const messageLength = message.length;
  const emptyMessage = messageLength === 0;
  const messageNumber = Math.ceil(messageLength / 160);

  return (
    <Container className="mr-2">
      <span className="textS text-muted">
        Message {emptyMessage ? null : `${messageNumber}/${messageNumber} `}(
        {messageLength} characters)
      </span>
    </Container>
  );
};

export default MessageWarning;
