import React, { useState, useRef } from "react";
import styled from "styled-components/macro";
import { Button } from "reactstrap";
import NoteForm from "./NoteForm";
import Modal from "../../../common/components/Modal";

import { CSSTransition } from "react-transition-group";
import { getFormattedDateTime } from "../../../common/helpers/dateUtils";
import useModal from "../../../common/hooks/useModal";

const TransitionStyling = styled(CSSTransition)`
  --timing: 0.25s;
  position: relative;
  overflow: hidden;
  transition: left var(--timing);

  &.notes {
    &-appear {
      &:first-child {
        left: -120%;
      }
      &-active {
        &:first-child {
          left: 0%;
        }
      }
      &-done {
        &:first-child {
          left: 0%;
        }
      }
    }
    &-enter {
      &:first-child {
        left: -120%;
      }
      &-active {
        &:first-child {
          left: 0%;
        }
      }
      &-done {
        &:first-child {
          left: 0%;
        }
      }
    }
    &-exit {
      left: 0%;

      &-active {
        left: -120%;
      }

      &-done {
        left: -120%;
      }
    }
  }
`;

const ButtonBlock = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  position: relative;

  button:first-child {
    margin-right: var(--pad5);

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: calc(-1 * var(--pad5) / 2);
      transform: translate(50%, -50%);
      background: var(--mediumGray);
      width: 5px;
      height: 5px;
    }
  }

  button {
    padding: 0;
    position: relative;
  }
`;

const NoteCard = styled.li`
  display: flex;
  text-align: left;
  list-style: none;

  padding-bottom: var(--pad6);

  p {
    line-height: 1.4;
  }
`;

export const Timeline = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  flex-basis: var(--pad5);
  flex-shrink: 0;

  position: relative;

  .circle {
    width: 18px;
    height: 18px;
    background: ${(props) => props.circleColor};
    border-radius: 50%;
    z-index: 1;
  }

  .grayBar {
    width: 3px;
    background: var(--mediumGray);
    height: 100%;
    position: absolute;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      top: var(--pad7);
      left: 0;
      background: inherit;
      position: absolute;
    }
  }
`;

function Note(props) {
  const { note, deleteNote, updateNote } = props;
  const { createdDate, text, createdBy } = note;

  const [modal, setModal] = useState(false);
  const [delModal, , toggleDelModal] = useModal();
  const [editText, setEditText] = useState(text);
  const [anim, setAnim] = useState(true);

  const handleupdateNote = () => {
    updateNote(note, editText);
    setModal(false);
  };

  const dateTime = getFormattedDateTime(createdDate);

  const getFormattedName = (name) => {
    return name.replace(/(?:^|\s)\S/g, (letter) => letter.toUpperCase());
  };

  const handleDeleteClick = () => {
    toggleDelModal();
    //disables edit and delete buttons when note is deleted
    buttonBlock.current.style.pointerEvents = "none";
    setAnim(false);
    setTimeout(() => {
      deleteNote(note);
    }, 300);
  };

  const btnOptions = [
    {
      type: "button",
      name: "Cancel",
      onClick: toggleDelModal,
      color: "secondary",
    },
    {
      color: "primary",
      onClick: handleDeleteClick,
      className: "pr-4 pl-4",
      name: "Confirm",
    },
  ];

  const confirmDeleteModal = (
    <Modal
      isOpen={delModal}
      toggle={toggleDelModal}
      title="Delete Note"
      size="md"
      btnOptions={btnOptions}
    >
      <p>Are you sure you'd like to permanently delete this note?</p>
    </Modal>
  );

  const buttonBlock = useRef();

  const renderText = (text) => {
    return { __html: text.replace(/(?:\r\n|\r|\n)/g, "</br>") };
  };

  return (
    <TransitionStyling in={anim} timeout={260} classNames="notes" appear>
      <NoteCard id={props.id} data-test="note-card">
        <Timeline circleColor="var(--green)" data-test="timeline">
          <div className="circle"></div>
          <div className="grayBar"></div>
        </Timeline>
        <div data-test="note-details">
          <pre className="textL gray">{`${dateTime[0]}  |  ${dateTime[1]}`}</pre>
          <h5 className="textL font-weight-bold">
            {createdBy && createdBy.fullName && getFormattedName(createdBy.fullName)}
          </h5>
          <p className="textL break-word" dangerouslySetInnerHTML={renderText(text)} />
          <ButtonBlock ref={buttonBlock}>
            <Button
              size="lg"
              color="link"
              data-test="edit-note-button"
              onClick={() => setModal(true)}
            >
              Edit
            </Button>
            <Button
              size="lg"
              color="link"
              data-test="delete-note-button"
              onClick={toggleDelModal}
            >
              Delete
            </Button>
          </ButtonBlock>
        </div>
        <Modal toggle={() => setModal(false)} isOpen={modal} title="Edit Note" size="md">
          <NoteForm
            submitNote={handleupdateNote}
            text={editText}
            setText={setEditText}
            note={note}
            btnText="Update Note"
          />
        </Modal>
        {confirmDeleteModal}
      </NoteCard>
    </TransitionStyling>
  );
}

export default Note;
