import AxiosConfig from "../../../../common/utils/axiosConfig";

export const pollDNCUploads = async (id: string) => {
  const url = `/dnc/${id}/poll/`;
  const response = await AxiosConfig.get(url);
  return response.data;
};

export const pollRemoveDNCUploads = async (id: string) => await pollDNCUploads(id);

export const dncUpload = async (data: any) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await AxiosConfig.post("/dnc/upload/", data, config);
  return response.data;
};

export const dncBulkRemove = async (data: any) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await AxiosConfig.post("/dnc/bulk_remove/", data, config);
  return response.data;
};
