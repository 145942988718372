import React from "react";
import styled from "styled-components/macro";

interface Props {
  data: {
    idx: string;
    name: string;
    onClick: () => void;
  }[];
  setActiveTab: (idx: string) => void;
  activeTab: string;
}

const TabHeaders: React.FC<Props> = ({ data, setActiveTab, activeTab, children }) => {
  return (
    <Container>
      <StyledGroups>
        <StyledUl className="textL">
          {data.map((value) => {
            return (
              <StyledLi
                key={value.idx}
                className={activeTab === value.idx ? "active" : ""}
                onClick={() => {
                  setActiveTab(value.idx);
                  value.onClick && value.onClick();
                }}
              >
                {value.name}
              </StyledLi>
            );
          })}
        </StyledUl>
      </StyledGroups>
      <StyledChildren>{children}</StyledChildren>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin: var(--pad1) -2rem var(--pad1) -18px;

  padding-right: var(--pad4);
  padding-left: var(--pad4);

  border-bottom: 2px solid var(--mediumGray);
  justify-content: space-between;
  @media (max-width: 994px) {
    flex-direction: column-reverse;
  }
`;

const StyledGroups = styled.div`
  flex-basis: 60%;
`;

const StyledUl = styled.ul`
  display: flex;
  margin: 0;
  align-items: center;
  padding: 0;
  height: 100%;
  @media (max-width: 994px) {
    justify-content: space-between;
  }
`;

const StyledLi = styled.li`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding-bottom: var(--pad1);
  padding-right: var(--pad2);
  padding-left: var(--pad2);

  &.active {
    color: var(--sherpaBlue);
    font-weight: bold;

    &:after {
      content: "";
      position: absolute;
      bottom: -3px;
      height: 4px;
      width: 100%;
      left: 0%;
      background: var(--sherpaBlue);
    }
  }
`;

const StyledChildren = styled.div`
  @media (max-width: 994px) {
    text-align: center;
  }
`;

export default TabHeaders;
