import { ROUTES_COOKIE_NAME } from "module/common/utils/utils";
import { getAndEraseCookie } from "./cookies";

export const interestedFeaturesHelper = (interestedFeatures) => {
  const redirectRoute = getAndEraseCookie(ROUTES_COOKIE_NAME) || "/";
  if (!interestedFeatures?.length) return redirectRoute;

  const interestedFeaturesObject = {
    1: "/campaigns/sms",
    2: "/skip-trace",
    3: "/stacker",
    4: "/campaigns/dm",
  };

  let currentPriority = 3;

  interestedFeatures.forEach((feature) => {
    if (feature < currentPriority) {
      currentPriority = feature;
    }
    if (feature === 4 && (currentPriority === 2 || currentPriority === 3)) {
      currentPriority = feature;
    }
  });

  return interestedFeaturesObject[currentPriority];
};
