import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import styled, { css } from "styled-components/macro";
import { LoadingButton } from "../../../../../common/components/Buttons";

import SkipStepModal from "../../modal/SkipStepModal";
import useAsync from "../../../../../common/hooks/useAsync";

import { getActivities } from "../../../../../main/store/ProspectDetails/activities/selectors";
import { fetchActivities } from "../../../../../main/store/ProspectDetails/activities/thunks";
import { updateReminderAction } from "../../../../../main/store/SequencesStore/reminders/thunks";
import { remindersTabResetState } from "../../../../../main/store/uiStore/sequencesView/actions";

import useModal from "../../../../../common/hooks/useModal";
import { campaignsSequencesPath } from "../../../../../main/routes";

function CallOptions({ currentReminder, toggleSmSReplyModal }) {
  const { prospect, sequence, step, id } = currentReminder || {};
  const { id: subjectId } = prospect;

  const activities = useSelector((state) => getActivities(state, subjectId));
  const [isSkipModalOpen, , toggleSkipModal] = useModal();
  const dispatch = useDispatch();
  const [executeCall, isLoading] = useAsync({
    asyncFunc: async () => {
      return dispatch(updateReminderAction(step.stepType, id, "finish"));
    },
  });

  useEffect(() => {
    if (!activities && subjectId) {
      dispatch(fetchActivities(subjectId));
    }
    // eslint-disable-next-line
  }, [subjectId]);

  const callComplete = async () => {
    executeCall().then(() => dispatch(remindersTabResetState()));
  };

  return (
    <>
      <CallOptionsContainer data-test="message-options-container">
        <div className="d-flex justify-content-between">
          <Link to={`${campaignsSequencesPath}/${sequence?.id}`}>
            <FontAwesomeIcon className="mr-1" icon="external-link-alt" />
            {sequence?.name}
          </Link>
          <p className="text-secondary">Step {step?.ordinal}</p>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <Button
              outline
              disabled={isLoading}
              className="skip-button mr-1"
              data-test="skip-button"
              onClick={toggleSkipModal}
            >
              <FontAwesomeIcon className="mr-1" icon="redo" />
              Skip
            </Button>
            <Button
              disabled={isLoading}
              outline
              className="sms-reply"
              data-test="sms-reply"
              color="primary"
              onClick={toggleSmSReplyModal}
            >
              <FontAwesomeIcon className="mr-1" icon="comment-dots" />
              Sms Reply
            </Button>
          </div>
          <LoadingButton
            color="primary"
            onClick={callComplete}
            loading={isLoading}
            data-test="call-complete"
            disabled={isLoading}
          >
            <FontAwesomeIcon className="mr-1" icon="check-circle" size="1x" />
            Call Complete
          </LoadingButton>
        </div>
      </CallOptionsContainer>
      <SkipStepModal
        isOpen={isSkipModalOpen}
        resetTab={remindersTabResetState}
        toggle={toggleSkipModal}
        stepType={step.stepType}
        prospectId={prospect.id}
        prospectSequenceId={prospect.activeProspectSequence?.id}
        prospectSequenceStepId={id}
      />
    </>
  );
}

const CallOptionsContainer = styled.div`
  padding: 0.8rem var(--pad3);
  background: white;

  #sendMessage {
    width: 100%;
  }

  ${(props) =>
    props.theme.isDesktop
      ? css`
          margin: var(--pad2);
          padding: var(--pad2) var(--pad2);
          .inputGroup {
            --border: none !important;
          }
          #sendMessage {
            margin-top: 1.4rem;
          }
        `
      : null}

  .skip-button {
    border: solid 2px var(--orange);
    color: var(--orange);

    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background-color: var(--orange);
      border-color: var(--orange);
      color: var(--white);
    }
  }
`;

export default CallOptions;
