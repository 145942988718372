import { produce } from "immer";
import { Fetching, Success } from "module/common/helpers/variables";
import { POPULATE_BILLING_HISTORY, SET_BILLING_HISTORY_STATUS } from "./actions";
import { IBilling } from "../interfaces";

const initialState: IBilling = {
  historyStatus: Fetching,
  history: [],
};

const reducer = produce((draft: IBilling, action: any) => {
  switch (action.type) {
    case POPULATE_BILLING_HISTORY: {
      draft.historyStatus = Success;
      draft.history = action.payload;
      break;
    }
    case SET_BILLING_HISTORY_STATUS: {
      draft.historyStatus = action.payload;
      break;
    }
  }
}, initialState);

export default reducer;
