import React from "react";
import styled from "styled-components";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";

// helpers
import { formatPhoneNumber } from "module/common/helpers/utils";

const StyledImage = styled.img`
  border-radius: 50%;
`;

const StyledAddress = styled.span`
  white-space: nowrap;
  &::after {
    content: ", ";
  }
  @media (max-width: 615px) {
    &::after {
      content: "";
    }
  }
`;

const StyledCityStateZip = styled.span`
  white-space: nowrap;
  @media (max-width: 615px) {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
`;

const ProspectInformation = (props) => {
  const { prospect } = props;

  const address = prospect.address ? (
    <React.Fragment>
      <StyledAddress>{prospect.address.trim()}</StyledAddress>
      <StyledCityStateZip>
        {`${prospect.city}, ${prospect.state} ${prospect.zipCode}`}
      </StyledCityStateZip>
    </React.Fragment>
  ) : (
    ""
  );
  const streetView = prospect.streetViewUrl || "";

  const history = useHistory();

  const handleProspectPageClick = () => {
    history.push(`/prospect/${prospect.id}/details`);
  };
  const name = prospect.name || `${prospect.firstName} ${prospect.lastName}`;

  return (
    <div className="d-flex justify-content-between mb-3">
      <div>
        <div className="d-flex align-items-center">
          {streetView && (
            <StyledImage
              src={streetView}
              title={address}
              height="75"
              width="75"
              className="img-fluid"
            />
          )}
          <div className="ml-2">
            <h4 className="font-weight-bold mb-1">{name} </h4>
            <div className="mb-1">{formatPhoneNumber(prospect.phoneRaw)}</div>
            <div>{address}</div>
          </div>
        </div>
      </div>
      <div className="mt-1">
        <StyledButton onClick={handleProspectPageClick} color="primary">
          Prospect Page
        </StyledButton>
      </div>
    </div>
  );
};
export default ProspectInformation;
