import { createAction } from "../../redux-helpers";

export const CREATE_NEW_SEQUENCE = "CREATE_NEW_SEQUENCE";
export const SAVE_NEW_SEQUENCE = "SAVE_NEW_SEQUENCE";
export const DELETE_SEQUENCE_STEP = "DELETE_SEQUENCE_STEP";
export const UPDATE_SEQUENCE_NAME = "UPDATE_SEQUENCE_NAME";
export const SET_SEQUENCE_STEPS = "SET_SEQUENCE_STEPS";
export const REMOVE_CURRENT_SEQUENCE = "REMOVE_CURRENT_SEQUENCE";

export const deleteSequenceStep = createAction(DELETE_SEQUENCE_STEP);
export const updateSequenceName = createAction(UPDATE_SEQUENCE_NAME);
export const setSequenceSteps = createAction(SET_SEQUENCE_STEPS);
export const createNewSequence = createAction(CREATE_NEW_SEQUENCE);
export const saveNewSequence = createAction(SAVE_NEW_SEQUENCE);
export const removeCurrentSequence = createAction(REMOVE_CURRENT_SEQUENCE);
