import { createAction } from "../../../redux-helpers";

export const SMART_STACKER_UPDATE_FILTERS = "SMART_STACKER_UPDATE_FILTERS";
export const updateSmartStackerFilters = createAction(SMART_STACKER_UPDATE_FILTERS);

export const SMART_STACKER_UPDATE_LIST_FILTERS = "SMART_STACKER_UPDATE_LIST_FILTERS";
export const updateSmartStackerListFilters = createAction(
  SMART_STACKER_UPDATE_LIST_FILTERS
);

export const UPDATE_SMART_STACKER_QUERY = "UPDATE_SMART_STACKER_QUERY";
export const updateSmartStackerQuery = createAction(UPDATE_SMART_STACKER_QUERY);

export const RESET_SMART_STACKER_QUERY = "RESET_SMART_STACKER_QUERY";
export const resetSmartStackerQuery = createAction(RESET_SMART_STACKER_QUERY);

export const SMART_STACKER_RESET_FILTERS = "SMART_STACKER_RESET_FILTERS";
export const resetSmartStackerFilters = createAction(SMART_STACKER_RESET_FILTERS);

export const SMART_STACKER_UPDATE_BOOL_FILTERS = "SMART_STACKER_UPDATE_BOOL_FILTERS";
export const updateSmartStackerBoolFilters = createAction(
  SMART_STACKER_UPDATE_BOOL_FILTERS
);

export const SET_SMART_STACKER_SORT_ORDER = "SET_SMART_STACKER_SORT_ORDER";
export const setSmartStackerSortOrder = createAction(SET_SMART_STACKER_SORT_ORDER);

export const SMART_STACKER_SET_FILTERS_MODAL_OPEN =
  "SMART_STACKER_SET_FILTERS_MODAL_OPEN";
export const updateIsFiltersModalOpen = createAction(
  SMART_STACKER_SET_FILTERS_MODAL_OPEN
);

export const SMART_STACKER_SET_ADVANCED_FILTERS_MODAL_OPEN =
  "SMART_STACKER_SET_ADVANCED_FILTERS_MODAL_OPEN";
export const updateIsAdvancedFiltersModalOpen = createAction(
  SMART_STACKER_SET_ADVANCED_FILTERS_MODAL_OPEN
);

export const SET_SMART_STACKER_PROSPECT_DATA_MODAL =
  "SET_SMART_STACKER_PROSPECT_DATA_MODAL";
export const setSmartStackerProspectDataModalOpen = createAction(
  SET_SMART_STACKER_PROSPECT_DATA_MODAL
);

export const SET_SMART_STACKER_SEARCHED = "SET_SMART_STACKER_SEARCHED";
export const setSmartStackerSearched = createAction(SET_SMART_STACKER_SEARCHED);

export const UPDATE_PROP_STACK_FILTER = "UPDATE_PROP_STACK_FILTER";
export const updatePropStackFilter = createAction(UPDATE_PROP_STACK_FILTER);

export const FETCH_PROP_STACK_FILTERS = "FETCH_PROP_STACK_FILTERS";
export const fetchPropStackFilters = createAction(FETCH_PROP_STACK_FILTERS);

export const SET_ACTIVE_PROP_STACK_SAVED_FILTER = "SET_ACTIVE_PROP_STACK_SAVED_FILTER";
export const setActivePropStackSavedFilter = createAction(
  SET_ACTIVE_PROP_STACK_SAVED_FILTER
);
export const UPDATE_DATE_CRITERIA_FILTER = "UPDATE_DATE_CRITERIA_FILTER";
export const updateDateCriteriaFilter = createAction(UPDATE_DATE_CRITERIA_FILTER);
