import React from "react";
import {
  SET_SESSION,
  SET_IS_REGISTERED,
  SET_CALL,
  SET_CALL_STATE,
  SET_CALL_START_TIME,
  SET_CALL_PROSPECT_DATA,
  CALL_COMPLETED,
  SET_IS_ACTIVE,
} from "./actions";
import { IAction, IState } from "./interfaces";

const initialState: IState = {
  session: {},
  audioRef: React.createRef(),
  isRegistered: false,
  isActive: false,
  call: {},
  callState: "",
  callStartTime: null,
  prospectData: null,
};

export default function callReducer(state = initialState, action: IAction) {
  const { payload, type } = action;

  switch (type) {
    case SET_SESSION:
      return {
        ...state,
        session: payload,
      };
    case SET_IS_REGISTERED:
      return {
        ...state,
        isRegistered: payload,
      };
    case SET_IS_ACTIVE:
      return {
        ...state,
        isActive: payload,
      };
    case SET_CALL:
      return {
        ...state,
        call: payload,
      };
    case SET_CALL_STATE:
      return {
        ...state,
        callState: payload,
      };
    case SET_CALL_START_TIME:
      return {
        ...state,
        callStartTime: payload,
      };
    case SET_CALL_PROSPECT_DATA:
      return {
        ...state,
        prospectData: payload,
      };
    case CALL_COMPLETED:
      return {
        ...state,
        call: {},
        callState: "",
        callStartTime: null,
        prospectData: null,
        isActive: false,
      };
    default:
      return state;
  }
}
