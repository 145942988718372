import { createAction } from "../redux-helpers";

export const FETCH_CAMPAIGNS = "FETCH_CAMPAIGNS";
export const setFetchedCampaignStatus = createAction(FETCH_CAMPAIGNS);

export const SET_FETCH_CAMPAIGNS = "SET_FETCH_CAMPAIGNS";
export const setFetchedCampaigns = createAction(SET_FETCH_CAMPAIGNS);

export const SET_FETCH_CAMPAIGNS_ERROR = "SET_FETCH_CAMPAIGNS_ERROR";
export const setFetchedCampaignsError = createAction(SET_FETCH_CAMPAIGNS_ERROR);

export const ARCHIVE_CAMPAIGN = "ARCHIVE_CAMPAIGN";
export const setArchiveCampaign = createAction(ARCHIVE_CAMPAIGN);

export const UNARCHIVE_CAMPAIGN = "UNARCHIVE_CAMPAIGN";
export const setUnArchiveCampaign = createAction(UNARCHIVE_CAMPAIGN);

export const UPDATE_SMS_TEMPLATE = "UPDATE_SMS_TEMPLATE";
export const setUpdatedSmsTemplateCampaign = createAction(UPDATE_SMS_TEMPLATE);

export const RESET_CAMPAIGNS_DATA = "RESET_CAMPAIGNS_DATA";
export const resetCampaignsData = createAction(RESET_CAMPAIGNS_DATA);

export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const updateCampaign = createAction(UPDATE_CAMPAIGN);

// lazy loading more
export const SET_FETCH_CAMPAIGNS_NEXT_PAGE = "SET_FETCH_CAMPAIGNS_NEXT_PAGE";
export const setFetchCampaignsNextPage = createAction(SET_FETCH_CAMPAIGNS_NEXT_PAGE);

export const SET_FETCH_CAMPAIGNS_NEXT_PAGE_ERROR = "SET_FETCH_CAMPAIGNS_NEXT_PAGE_ERROR";

export const SET_FETCH_CAMPAIGNS_NEXT_PAGE_SUCCESS = "SET_FETCH_CAMPAIGNS_NEXT_SUCCESS";
export const setFetchCampaignsNextPageSuccess = createAction(
  SET_FETCH_CAMPAIGNS_NEXT_PAGE_SUCCESS
);

export const FETCH_CAMPAIGN_NEXT_PAGE = "FETCH_CAMPAIGN_NEXT_PAGE";
export const fetchCampaignNextPage = createAction(FETCH_CAMPAIGN_NEXT_PAGE);

export const PAGINATE_CAMPAIGN_LIST = "PAGINATE_CAMPAIGN_LIST";
export const paginateCampaignList = createAction(PAGINATE_CAMPAIGN_LIST);

export const UPDATE_CAMPAIGNS_LIST = "UPDATE_CAMPAIGNS_LIST";
export const updateCampaignsList = createAction(UPDATE_CAMPAIGNS_LIST);
