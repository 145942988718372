import React, { useEffect } from "react";
import DateTime from "react-datetime";
import { useFormContext, Controller } from "react-hook-form";

const DatePickerField = ({ field }) => {
  const { control, setValue, register } = useFormContext();
  const { inputProps, name, rules } = field;

  useEffect(() => {
    setValue(name, inputProps.defaultValue);
    // eslint-disable-next-line
  }, [register]);

  return (
    <Controller
      name={name}
      control={control}
      data-test="date-picker-field"
      as={<DateTime {...inputProps} />}
      rules={rules}
      value={inputProps.defaultValue}
    />
  );
};

export default DatePickerField;
