import { arrayToMapIndex } from "../utils";

export const selectKeys = (map, keys) => {
  keys.reduce((acc, key) => {
    if (map.hasOwnProperty(key)) acc[key] = map[key];
    return acc;
  }, {});
};

export const captureSort = (data) => data.map((i) => i.id);

export const updateProspectTagData = (campaignProspects, addTags, removeTags) => {
  const data = arrayToMapIndex(
    "id",
    campaignProspects.map((cp) => {
      const prospect = cp.prospect;
      // Remove tags from prospect
      prospect.tags = prospect.tags.filter((tag) => !removeTags.includes(tag));

      // Add missing tags from prospect
      addTags.forEach((tag) => {
        if (!prospect.tags.includes(tag)) {
          prospect.tags.push(tag);
        }
      });
      return prospect;
    })
  );
  return data;
};
