import * as api from "./api";
import {
  setFetchedMarketsError,
  setFetchMarketsStatus,
  setFetchedMarkets,
  updateMarket,
} from "./actions";
import { formatMarkets, formatCallForwardNumber } from "./transformers";
import { Fetching } from "module/common/helpers/variables";
import { marketsStatus } from "./selectors";
import { Dispatch } from "redux";
import { IMarket } from "./interfaces";

export const fetchMarkets =
  () => async (dispatch: Dispatch, getState: (...args: any[]) => void) => {
    const state = getState();
    const status = marketsStatus(state);

    if (status === Fetching) return;

    const handleError = (message: string) => {
      dispatch(setFetchedMarketsError(message));
    };

    dispatch(setFetchMarketsStatus(Fetching));

    return api
      .getMarkets()
      .then(({ data }) => {
        const { results }: { results: IMarket[] } = data;
        const marketFolders = formatMarkets(results);
        dispatch(setFetchedMarkets(marketFolders));

        return results;
      })
      .catch(() => {
        handleError("Error when fetching markets");
      });
  };

export const updateMarketThunk =
  (id: number, params: any) => async (dispatch: Dispatch) => {
    return api.patchMarket(id, params).then(({ data }) => {
      dispatch(updateMarket(formatCallForwardNumber(data)));
      return data;
    });
  };
