import AxiosInstance from "module/common/utils/axiosConfig";
import moment from "moment-timezone";

export const getCallLog = (params) => {
  return AxiosInstance.get("/calls/", { params });
};

export const postCallLog = async (callStartTime, toNumber, prospectID) => {
  const payload = {
    toNumber,
    startTime: callStartTime.toJSON(),
    endTime: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSZ"),
    prospect: prospectID,
  };
  return await AxiosInstance.post("/calls/", payload);
};

export const getC2CToken = async () => {
  return await AxiosInstance.get("/calls/token/");
};
