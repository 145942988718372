import { combineReducers } from "redux";

// reducers
import results from "./results/reducers";
import multipleSelect from "./multipleSelect/reducers";
import filters from "./filters/reducers";

const root = combineReducers({
  results,
  multipleSelect,
  filters,
});

export default root;
