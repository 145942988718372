import { produce } from "immer";

import { ADD_DNC_UPLOAD, DELETE_UPLOAD } from "./actions";
import { IState, IUpload } from "./interfaces";

const initialState: IState = {
  data: [],
};

const reducer = produce((base: IState, action: any) => {
  switch (action.type) {
    case ADD_DNC_UPLOAD: {
      const dataList = base.data;
      dataList.push(action.payload);
      base.data = dataList;

      break;
    }
    case DELETE_UPLOAD: {
      const dataList = base.data.filter((value: IUpload) => value.id !== action.payload);
      base.data = dataList;
      break;
    }
  }
}, initialState);

export default reducer;
