import AxiosInstance from "../../../common/utils/axiosConfig";

export const fetchProspectRelays = () => {
  return AxiosInstance.get(`/prospect-relays/`);
};

export const postDisconnectProspectRelay = (id: number) => {
  return AxiosInstance.post(`/prospect-relays/${id}/disconnect/`);
};

export const postConnectProspectRelay = (body: any) => {
  return AxiosInstance.post("prospect-relays/connect/", body);
};
