import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
// utils
import { unreadTabUpdateSelectedItem } from "module/main/store/uiStore/allUnreadPageView/actions";
import { setActiveCampaignProspect } from "module/main/store/uiStore/campaignsPageDesktopView/campaignsList/filterData/actions";
import useUpDownNavigation from "./useUpDownNavigation";

const useArrowNavigation = (prospectList: any[], activeProspectId: string) => {
  const dispatch = useDispatch();
  const [indexChange, setIndexChange] = useUpDownNavigation();

  const prospectIndex = useMemo(() => {
    return prospectList.findIndex(({ prospectId }) => activeProspectId === prospectId);
  }, [activeProspectId]);

  const handleArrowNavigation = (indexChange: number) => {
    const adjustedIndex = prospectIndex + indexChange;
    if (prospectList.length) {
      const newIndex = Math.min(Math.max(adjustedIndex, 0), prospectList.length - 1);
      const newProspectId = prospectList[newIndex].prospectId;
      dispatch(setActiveCampaignProspect(newProspectId));
      dispatch(unreadTabUpdateSelectedItem(newProspectId));
    }
  };

  useEffect(() => {
    if (indexChange) {
      handleArrowNavigation(indexChange);
      setIndexChange(0);
    }
  }, [indexChange]);
};

export default useArrowNavigation;
