import AxiosInstance from "../../../common/utils/axiosConfig";

export const campaignFetchAll = (params: any) => {
  return AxiosInstance.get("/campaigns/full/", { params });
};

export const campaignRequest = ({ id, ...config }: any) =>
  AxiosInstance({ url: `/campaigns/${id}/`, ...config });

export const campaignFollowup = (id: number, payload: any) => {
  return AxiosInstance.post(`/campaigns/${id}/followup/`, payload);
};

export const postPurchaseCampaignBalance = (id: number, payload: any) => {
  return AxiosInstance.post(`/campaigns/${id}/purchase/`, payload);
};

export const bulkUpdateProspectTagsByCampaign = async (
  campaignId: number,
  payload: any
) => {
  return await AxiosInstance.post(`campaigns/${campaignId}/tag_prospects/`, payload);
};

// export const cancelCampaign = (id: number) => {
//   return AxiosInstance.post(`campaigns/${id}/direct_mail_cancel/`);
// };

// export const createDirectMailCampaign = (payload: any) => {
//   return AxiosInstance.post("campaigns/direct_mail/", payload);
// };

// export const removeDMCampaignRecipients = (id: number, prospect_ids: number[]) => {
//   const payload = {
//     prospect_ids,
//   };
//   return AxiosInstance.patch(
//     `campaigns/${id}/remove_direct_mail_campaign_recipients/`,
//     payload
//   );
// };

// export const editDirectMailCampaign = (id: number, payload: any) => {
//   return AxiosInstance.patch(`campaigns/${id}/direct_mail/`, payload);
// };

export const getCampaigns = (params: any) => {
  return AxiosInstance.get("/campaigns/", { params });
};

export const createCampaign = (payload: any) => {
  return AxiosInstance.post("/campaigns/", payload);
};

// export const campaignDropDateCheckDM = (params: any) => {
//   return AxiosInstance.get(`/campaigns/direct_mail_target_date/`, { params });
// };

export const getCampaignsNextPage = (nextUrl: string) => {
  return AxiosInstance.get(nextUrl);
};

export const getCampaignsInfo = (payload: { ids: Array<number> }) => {
  return AxiosInstance.post("campaigns/get_cp_details_queryset/", payload);
};

export const fetchCampaignStats = (id: number) => {
  return AxiosInstance.get(`/campaigns/${id}/stats/`);
};

export const getScribeSherpaMessages = () => {
  return AxiosInstance.get("/campaigns/scribe_sherpa_messages_examples");
};
