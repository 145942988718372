import {
  setProspectRelayStatus,
  populateProspectRelays,
  removeProspectRelay,
} from "./actions";
import { resetRelays } from "./actions";
import { updateProspectSuccess } from "../prospectStore/actions";
import { IRelay } from "../prospectStore/interfaces";
import { IProfile } from "../Company/interfaces";
import { formatErrorMessage } from "module/common/utils/utils";
import { addErrorToast, addSuccessToast } from "../Global/Toasts/actions";
import { Fetching } from "module/common/helpers/variables";
import { deepCopy } from "../../../common/helpers/utils";
import { companySetProfiles } from "../Company/profiles/actions";
import { handleError } from "../utils";
import {
  fetchProspectRelays,
  postConnectProspectRelay,
  postDisconnectProspectRelay,
} from "./api";

const handleSuccessToast = (oldRelay: IRelay | null, dispatch: any) => {
  const message = `Prospect relay ${oldRelay ? "updated" : "added"}.`;
  dispatch(addSuccessToast(message));
};

export const fetchAndPopulateProspectRelays = () => (dispatch: any) => {
  dispatch(setProspectRelayStatus(Fetching));
  return fetchProspectRelays()
    .then(({ data }) => {
      dispatch(populateProspectRelays(data));
      return data;
    })
    .catch((error) => {
      handleError({ message: "Error fetching prospect relay data.", error }, dispatch);
    });
};

const updateCount = (profiles: any, agentId: number, action = "") => {
  const value = action === "disconnect" ? 1 : -1;
  profiles.forEach((profile: any) => {
    if (profile.id === agentId) {
      profile.prospectRelayCount -= value;
      profile.prospectRelayAvailable += value;
    }
  });
  return profiles;
};

export const callPostDisconnectProspectRelay =
  (oldRelay: IRelay, profiles: IProfile[]) => (dispatch: any) => {
    const {
      agentProfile: { id: agentId },
    } = oldRelay;
    return postDisconnectProspectRelay(oldRelay.id)
      .then(() => {
        const profilesClone = deepCopy(profiles);
        const updatedCompany = updateCount(profilesClone, agentId, "disconnect");

        dispatch(companySetProfiles(updatedCompany));
        dispatch(removeProspectRelay(oldRelay.id));
      })
      .catch((err) => {
        const defaultMessage = `Couldn't disconnect Prospect relay.`;
        const errMessage = formatErrorMessage(err, defaultMessage);
        dispatch(addErrorToast(errMessage));
      });
  };

interface Payload {
  prospect: number;
  agentProfile: number;
}

export const callPostConnectProspectRelay =
  (payload: Payload, profiles: IProfile[], oldRelay: IRelay | null) =>
  async (dispatch: any) => {
    return postConnectProspectRelay(payload)
      .then(({ data }) => {
        const { agentProfile, prospect } = data || {};
        const profilesClone = deepCopy(profiles);
        const updatedProfiles = updateCount(profilesClone, agentProfile.id, "connect");
        dispatch(companySetProfiles(updatedProfiles));

        dispatch(resetRelays());
        dispatch(updateProspectSuccess({ id: prospect.id, relay: data }));
        handleSuccessToast(oldRelay, dispatch);
        if (oldRelay) {
          dispatch(callPostDisconnectProspectRelay(oldRelay, profiles));
        }
      })
      .catch((err) => {
        const defaultMessage = `Couldn't ${oldRelay ? "update" : "add"} Prospect relay.`;
        const errMessage = formatErrorMessage(err, defaultMessage);
        dispatch(addErrorToast(errMessage));
      });
  };
