import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "../../../common/components/Modal";
import ImgSMS from "assets/images/sms.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setCookie, getCookie } from "../../../common/helpers/cookies";
import isMobile, { isMac } from "detectDevice";

const UnreadPageNotificationModal = () => {
  //state
  const [isOpen, setModalOpen] = useState(false);

  const cookieName = "unreadUpdateNotified";
  const unreadUpdateNotifiedCookie = getCookie(cookieName);

  const holdableKey = isMac ? "Option ⌥" : "Alt";

  const toggle = () => {
    setCookie(cookieName, true);
    setModalOpen(false);
  };

  const btnOptions = [
    {
      onClick: () => {
        toggle();
      },
      name: "Understood!",
      color: "primary",
      className: "w-100 d-flex justify-content-center align-items-center",
    },
  ];

  useEffect(() => {
    if (!unreadUpdateNotifiedCookie && !isMobile) setModalOpen(true);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title="Unread List Update"
      size="md"
      btnOptions={btnOptions}
      btnOptionsClassName="d-flex justify-content-center"
      footerClassName="border-top-0 mx-auto pt-0"
    >
      <div className="d-flex flex-column justify-content-between">
        <>
          <img
            src={ImgSMS}
            className="align-self-center mb-3"
            alt="New Market Available"
            height="200px"
            width="450px"
          />
          <h4 className={`text-center align-self-center font-weight-bold`}>
            Unread List Updated
          </h4>
          <p className={`text-center align-self-center textL`}>
            We've added new features to the Unread Message List to make navigation and
            replying to messages even easier!
          </p>
          <StyledList className="align-self-center mr-4 mb-3">
            <li>
              Arrow Navigation -{" "}
              <span>
                <i>
                  You can now navigate the unread list by holding {holdableKey} and
                  pressing the up/down arrow keys.
                </i>
              </span>
              <div className="row justify-content-center mt-0 align-items-center">
                <h4 className="my-0 mr-2">{holdableKey}</h4> +
                <FontAwesomeIcon
                  className="ml-2 mt-1"
                  color="var(--sherpaBlue)"
                  icon="arrow-alt-up"
                />
                <FontAwesomeIcon
                  className="mt-1"
                  color="var(--sherpaBlue)"
                  icon="arrow-alt-down"
                />
              </div>
            </li>
            <li>
              Automatic Selection -{" "}
              <span>
                <i>
                  The reply box will now be automatically selected as you navigate between
                  unread conversations.
                </i>
              </span>
            </li>
            <li>
              Enter to Send -{" "}
              <span>
                <i>Pressing enter will now send messages.</i>
              </span>
            </li>
          </StyledList>
        </>
      </div>
    </Modal>
  );
};

export default UnreadPageNotificationModal;

const StyledList = styled.ul`
  align-self: center;
  li {
    list-style-type: disc;
    line-height: 1.5;
    font-weight: bold;
  }
  span {
    font-weight: normal;
    font-size: 12px;
  }
`;
