import { Dispatch } from "redux";
import { Fetching } from "module/common/helpers/variables";
import { addNewToast } from "../Global/Toasts/actions";
import * as action from "./actions";
import * as api from "./api";

const updateMessage = (success: boolean, type: any) => {
  switch (type) {
    case "edit":
      return success
        ? "Successfully updated the template."
        : "Failed to update the template.";
    case "archive":
      return success
        ? "Succesfully archived the template."
        : "Failed to archive the template";
    case "unarchive":
      return success
        ? "Successfully un-archived the template."
        : "Failed to un-archive the template";
  }
};

const handleError = (message: any, error: string, dispatch: Dispatch) => {
  dispatch(action.setSmsTemplatesError(error));
  dispatch(addNewToast({ message, color: "danger" }));
};

export const fetchSmsTemplates = () => async (dispatch: Dispatch) => {
  dispatch(action.setSmsTemplatesStatus(Fetching));
  try {
    const { value, done } = await api.fetchSmsTemplatesGenerator.next();
    if (done) return;

    dispatch(action.populateSmsTemplates({ templates: value }));
    return value;
  } catch (err: any) {
    handleError("Failed to retrieve SMS templates", err, dispatch);
  }
};

export const callGetSmsTemplatesWithFilter =
  (categoryId: number, pageSize: number = 50) =>
  async (dispatch: Dispatch) => {
    return api
      .getSmsTemplatesWithFilter(categoryId, pageSize)
      .then(({ data }) => {
        dispatch(action.populateSmsTemplates({ templates: data.results }));
        return data;
      })
      .catch((err) => handleError("Failed to retrieve SMS templates", err, dispatch));
  };

export const createSmsTemplate = (payload: any) => (dispatch: Dispatch) => {
  return api
    .createSmsTemplate(payload)
    .then(({ data }) => {
      dispatch(action.updateSmsTemplates({ data, updateSort: true }));
      dispatch(addNewToast({ message: "Template created", color: "success" }));
      return data;
    })
    .catch((err) => {
      handleError("Failed to create template.", err, dispatch);
      throw err;
    });
};

export const updateSmsTemplate = (payload: any) => (dispatch: Dispatch) => {
  const { id, data, type } = payload;

  return api
    .updateSmsTemplate(id, data)
    .then(({ data }) => {
      dispatch(action.updateSmsTemplates({ data: { id, ...data } }));
      dispatch(addNewToast({ message: updateMessage(true, type), color: "success" }));
      return data;
    })
    .catch((err) => {
      handleError(updateMessage(false, type), err, dispatch);
      throw err;
    });
};
