import { produce } from "immer";
import { COMPANY_SET_PROFILES } from "./actions";
import { IProfileState } from "../interfaces";

const initial: IProfileState = {
  profiles: [],
};

const reducer = produce((draft: IProfileState, action: any) => {
  switch (action.type) {
    case COMPANY_SET_PROFILES: {
      draft.profiles = action.payload;
      break;
    }
  }
}, initial);

export default reducer;
