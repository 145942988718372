import { createAction } from "../../redux-helpers";

export const POPULATE_ACTIVITIES = "POPULATE_ACTIVITIES";
export const POPULATE_PROSPECT_ACTIVITIES = "POPULATE_PROSPECT_ACTIVITIES";
export const SET_ACTIVITIES_ERROR = "SET_ACTIVITIES_ERROR";
export const SET_ACTIVITIES_STATUS = "SET_ACTIVITIES_STATUS";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const EDIT_ACTIVITY = "EDIT_ACTIVITY";
export const RESTORE_ACTIVITIES = "RESTORE_ACTIVITIES";

export const populateActivities = createAction(POPULATE_ACTIVITIES);
export const populateProspectActivities = createAction(POPULATE_PROSPECT_ACTIVITIES);
export const setActivitiesStatus = createAction(SET_ACTIVITIES_STATUS);
