import {
  Fetching,
  messageUpdateNote,
  messageNewNote,
  messageDeleteNote,
} from "module/common/helpers/variables";
import { addNewToast } from "../../Global/Toasts/actions";
import {
  setProspectNotesError,
  setProspectNotesStatus,
  populateProspectNotes,
  editProspectNote,
  addProspectNote,
  deleteProspectNote,
} from "./actions";
import {
  fetchProspectNotes as _fetchProspectNotes,
  updateProspectNote as _updateProspectNote,
  createProspectNote as _createProspectNote,
  deleteProspectNote as _deleteProspectNote,
} from "./api";
import { IProspectNote } from "./interfaces";
import { Dispatch } from "redux";

export const fetchProspectNotes = (id: number) => async (dispatch: Dispatch) => {
  dispatch(setProspectNotesStatus(Fetching));

  const params = { expand: "created_by", prospect: id };

  return _fetchProspectNotes(params)
    .then(({ data }) => {
      const curatedResults = { [id]: data.results };
      dispatch(populateProspectNotes(curatedResults));
    })
    .catch((error: string) => {
      dispatch(
        addNewToast({ color: "danger", message: "Error fetching prospect notes" })
      );
      dispatch(setProspectNotesError(error));
    });
};

export const updateProspectNote =
  (data: { note: any; text: string }) => async (dispatch: Dispatch) => {
    const { note, text } = data;

    const body = {
      prospect: note.prospect,
      text,
    };

    return _updateProspectNote(note.id, body)
      .then(({ data }) => {
        const { prospect, id } = data;
        dispatch(editProspectNote({ id: prospect, noteId: id, results: data }));
        dispatch(addNewToast({ message: messageUpdateNote }));
      })
      .catch((error: string) => {
        dispatch(
          addNewToast({ color: "danger", message: "Error editing the prospect note" })
        );
        dispatch(setProspectNotesError(error));
      });
  };

export const createProspectNote = (data: IProspectNote) => async (dispatch: Dispatch) => {
  return _createProspectNote(data)
    .then(({ data }) => {
      dispatch(addProspectNote({ id: data.prospect, results: data }));
      dispatch(addNewToast({ message: messageNewNote }));
      return true;
    })
    .catch((error: string) => {
      dispatch(addNewToast({ color: "danger", message: "Error adding a prospect note" }));
      dispatch(setProspectNotesError(error));
      return false;
    });
};

export const removeProspectNote = (data: IProspectNote) => async (dispatch: Dispatch) => {
  return _deleteProspectNote(data.id!)
    .then(() => {
      dispatch(deleteProspectNote({ id: data.prospect, noteId: data.id }));
      dispatch(addNewToast({ message: messageDeleteNote }));
      return true;
    })
    .catch((error: string) => {
      dispatch(
        addNewToast({ color: "danger", message: "Error removing a prospect note" })
      );
      dispatch(setProspectNotesError(error));
      return false;
    });
};
