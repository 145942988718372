export const QUICK_REPLIES_VIEW_LIST_ITEM_ACTIONS =
  "quick-replies/view:list-item-actions";

export const QUICK_REPLIES_EDIT_ACTION = "quick-replies/action:edit";
export const QUICK_REPLIES_DELETE_ACTION = "quick-replies/action:delete";

export const permissions = {
  master_admin: [],
  admin: [QUICK_REPLIES_DELETE_ACTION],
  staff: [QUICK_REPLIES_VIEW_LIST_ITEM_ACTIONS, QUICK_REPLIES_EDIT_ACTION],
  junior_staff: [],
};
