import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// components
import LoginMarketing from "./LoginMarketing";
import bg from "assets/images/loginBG.jpg";
import LoginForm from "./LoginForm";
// store
import { authenticate } from "../../store/Auth/thunks";
import { authError, selectHasAuthToken } from "../../store/Auth/selectors";
// utils
import isMobile from "detectDevice";
import { interestedFeaturesHelper } from "../../../common/helpers/interestedFeaturesHelper";
import { setCookie } from "module/common/helpers/cookies";
import { INVITE_CODE_OVERRIDE_COOKIE_NAME } from "./utils";
import {
  PublicPageWrapper,
  PublicPageCol,
  StyledLoginContainer,
} from "module/common/styles";
import {
  ACCESS_REG_TEMP_STORAGE_KEY,
  REFRESH_REG_TEMP_STORAGE_KEY,
  INACTIVE_LEAD_STORAGE_KEY,
} from "module/common/helpers/storage";

export default function LoginPage(props) {
  const dispatch = useDispatch();
  // selectors
  const formError = useSelector(authError);
  const hasAuthToken = useSelector(selectHasAuthToken);

  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    // clean up temp tokens if the user comes back to the login page.
    window.localStorage.removeItem(ACCESS_REG_TEMP_STORAGE_KEY);
    window.localStorage.removeItem(REFRESH_REG_TEMP_STORAGE_KEY);
    window.localStorage.removeItem(INACTIVE_LEAD_STORAGE_KEY);
  }, []);

  // redirect if authenticated
  if (hasAuthToken) return <Redirect to="/" />;

  // submit handler
  const submit = (username, password) => {
    return dispatch(authenticate({ username, password })).then((interestingFeatures) => {
      //check if local storage has tmp tokens from reg
      const accessRegTemp = window.localStorage.getItem(ACCESS_REG_TEMP_STORAGE_KEY);
      const refreshRegTemp = window.localStorage.getItem(REFRESH_REG_TEMP_STORAGE_KEY);
      const isInactiveLead = window.localStorage.getItem(INACTIVE_LEAD_STORAGE_KEY);

      const routeBasedOnInterest = interestedFeaturesHelper(interestingFeatures);

      if (accessRegTemp || refreshRegTemp || isInactiveLead) {
        const inviteCodeOverride = queryParams.get("invite_code_override") || "";
        setCookie(INVITE_CODE_OVERRIDE_COOKIE_NAME, inviteCodeOverride, 1);

        return props.history.push("/register");
      }
      return props.history.push(routeBasedOnInterest);
    });
  };

  const LoginWrapperComponent = isMobile ? PublicPageCol : StyledLoginContainer;
  const loginProps = isMobile ? { md: 8, lg: 5 } : {};

  return (
    <PublicPageWrapper bg={bg} isLoginPage isMobile={isMobile}>
      <LoginWrapperComponent {...loginProps}>
        <LoginForm submit={submit} formError={formError} isMobile={isMobile} />
      </LoginWrapperComponent>
      {!isMobile ? <LoginMarketing /> : null}
    </PublicPageWrapper>
  );
}
