import { Providers } from "module/common/helpers/variables";
import { SpamDisabledFields } from "../interfaces";
import { ApiFetchStatus } from "module/common/helpers/variables";

export enum PhoneStatus {
  Pending = "pending",
  Cooldown = "cooldown",
  Registering = "registering",
  FailedToRegister = "failed_to_register",
  Ready = "ready",
  InService = "in_service",
  Disabled = "disabled",
  PendingRelease = "pending_release",
  Released = "released",
}

export interface IPhoneNumber extends SpamDisabledFields {
  id: number;
  marketId: number;
  status: PhoneStatus;
  provider: Providers;
  health: string;
  spamCumulativeCount: number;
  spamTodayCount: number;
  tcr10dlcCampaignId: string | null;
  provider10dlcCampaignId: string | null;
  // Fields modified by frontend
  created: string;
  lastSend: string;
  lastReceived: string;
  phone: string;
  phoneRaw: string;
  // Fields created from frontend
  parsedCreated: string;
  parsedLastSend: string;
  parsedLastReceived: string;
}

export interface IPhoneNumberMap {
  [key: number]: IPhoneNumber;
}

export interface IState {
  numbers: IPhoneNumberMap;
  status: ApiFetchStatus;
  error: boolean;
  isPhoneManagerFiltersModalOpen: boolean;
}

export interface IAction {
  type: string;
  payload: IPhoneNumberMap | IPhoneNumber | boolean | string;
  meta?: { [key: string]: any };
}
