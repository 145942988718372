import { produce } from "immer";

// actions
import { ADD_TOAST, MODIFY_TOAST, REMOVE_TOAST, RESET_TOAST_ARRAY } from "./actions";

// interfaces
import { IState, IToast } from "./interfaces";

const initialState: IState = {
  list: [],
};

const reducer = produce((base: IState, action: any) => {
  switch (action.type) {
    case ADD_TOAST: {
      const toast = action.payload as IToast;
      const toastList = base.list;
      const newToast = { ...toast, id: toast.id || new Date().toISOString() };
      toastList.push(newToast);
      base.list = toastList;

      break;
    }
    case MODIFY_TOAST: {
      const toast = action.payload as IToast;
      const id = toast.id as string;
      const message = toast.message as string;
      const toastList = base.list;
      const index = toastList.findIndex((t) => t.id === id);
      if (index !== -1) {
        toastList[index].message = message;
      }

      base.list = toastList;

      break;
    }
    case REMOVE_TOAST: {
      const toast = action.payload as IToast;
      const id = toast.id;
      const toastLists = base.list.filter((t) => t.id !== id);
      base.list = toastLists;

      break;
    }
    case RESET_TOAST_ARRAY: {
      const toastLists = base.list.filter((t) => t.persist);
      base.list = toastLists;
      break;
    }
  }
}, initialState);

export default reducer;
