import {
  SET_ZAP_WEB_HOOK_STATUS,
  SET_ZAP_WEB_HOOKS,
  REMOVE_ZAP_WEB_HOOK,
  UPDATE_ZAP_WEB_HOOKS,
  SET_ZAP_WEB_HOOK_ERROR,
  ADD_ZAP_WEB_HOOK,
  DEFAULT_ZAP_WEB_HOOK,
} from "./actions";

import { Success, FetchError } from "module/common/helpers/variables";
import {
  IState,
  IAction,
  IZapierWebhook,
  SetDefaultZapWebhookAction,
} from "./interfaces";

const initialState: IState = {
  refetch: true,
  hooks: [],
  status: Success,
  error: "",
};

export const path = ["zapierWebHooks"];

export default function ZapWebHooks(state = initialState, action: IAction) {
  switch (action.type) {
    case SET_ZAP_WEB_HOOK_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case SET_ZAP_WEB_HOOKS:
      return {
        ...state,
        hooks: action.payload,
        status: Success,
        refetch: false,
      };
    case SET_ZAP_WEB_HOOK_ERROR:
      return {
        ...state,
        status: FetchError,
        error: action.payload,
      };
    case ADD_ZAP_WEB_HOOK:
      return {
        ...state,
        hooks: [...state.hooks, action.payload],
      };
    case UPDATE_ZAP_WEB_HOOKS:
      const webhook: IZapierWebhook = action.payload as IZapierWebhook;
      const newHooks = state.hooks.map((hook: IZapierWebhook) => {
        if (hook.id === webhook.id) {
          return action.payload;
        }
        return hook;
      });
      return {
        ...state,
        hooks: newHooks,
      };
    case REMOVE_ZAP_WEB_HOOK:
      return {
        ...state,
        hooks: state.hooks.filter((hook: IZapierWebhook) => hook.id !== action.payload),
      };
    case DEFAULT_ZAP_WEB_HOOK:
      const payload = action.payload as SetDefaultZapWebhookAction;
      const { id, add } = payload;
      return {
        ...state,
        hooks: state.hooks.map((hook: IZapierWebhook) => {
          hook.isDefault = add && hook.id === id;
          return hook;
        }),
      };
    default:
      return state;
  }
}
