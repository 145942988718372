import { createAction } from "../../../../redux-helpers";

export const SET_CAMPAIGN_DESKTOP_PAGE_ACTIVE_TAB =
  "SET_CAMPAIGN_DESKTOP_PAGE_ACTIVE_TAB";
export const SET_CAMPAIGN_DESKTOP_PAGE_ACTIVE_SORT =
  "SET_CAMPAIGN_DESKTOP_PAGE_ACTIVE_SORT";
export const SET_CAMPAIGN_DESKTOP_TAB_DATA = "SET_CAMPAIGN_DESKTOP_TAB_DATA";
export const SET_ACTIVE_CAMPAIGN_PROSPECT = "SET_ACTIVE_CAMPAIGN_PROSPECT";
export const SET_CAMPAIGN_PAGE_ACTIVE_MARKET = "SET_CAMPAIGN_PAGE_ACTIVE_MARKET";
export const RESET_CAMPAIGN_LIST_FILTER_TAB = "RESET_CAMPAIGN_LIST_FILTER_TAB";
export const RESET_CAMPAIGN_LIST_FILTER_TABS = "RESET_CAMPAIGN_LIST_FILTER_TABS";

export const REMOVE_CAMPAIGN_FROM_TAB = "REMOVE_CAMPAIGN_FROM_TAB";
export const ADD_CAMPAIGN_TO_TAB = "ADD_CAMPAIGN_TO_TAB";

export const setCampaignPageActiveTab = createAction(
  SET_CAMPAIGN_DESKTOP_PAGE_ACTIVE_TAB
);
export const setCampaignPageActiveSort = createAction(
  SET_CAMPAIGN_DESKTOP_PAGE_ACTIVE_SORT
);
export const setCampaignPageActiveMarket = createAction(SET_CAMPAIGN_PAGE_ACTIVE_MARKET);
export const setCampaignPageTabData = createAction(SET_CAMPAIGN_DESKTOP_TAB_DATA);
export const resetCampaignListTabs = createAction(RESET_CAMPAIGN_LIST_FILTER_TABS);
export const resetCampaignListTab = createAction(RESET_CAMPAIGN_LIST_FILTER_TAB);
export const setActiveCampaignProspect = createAction(SET_ACTIVE_CAMPAIGN_PROSPECT);

export const addCampaignToTab = createAction(ADD_CAMPAIGN_TO_TAB);
export const removeCampaignFromTab = createAction(REMOVE_CAMPAIGN_FROM_TAB);
