import { ApiFetchStatus, ProfileRoles } from "module/common/helpers/variables";
import { Dispatch } from "redux";
import { selectProfileData } from "../Auth/selectors";
import { addErrorToast, addSuccessToast } from "../Global/Toasts/actions";
import {
  entitlementsStatusUpdate,
  entitlementsUpdate,
  planChoicesUpdate,
  planStatusUpdate,
  plainPlanChoicesUpdate,
} from "./actions";
import {
  getEntitlementsOptions,
  getCompanyEntitlements,
  putEntitlementsPlan,
  getPlanOptions,
} from "./api";
import { IEntitlements, PlanIds } from "./interfaces";

export const fetchPlanOptions = (companyId: number) => async (dispatch: Dispatch) => {
  dispatch(planStatusUpdate(ApiFetchStatus.Fetching));
  try {
    const { data } = await getEntitlementsOptions(companyId);
    dispatch(planChoicesUpdate(data.actions.PUT.planId.choices));
    return data;
  } catch (err) {
    dispatch(planStatusUpdate(ApiFetchStatus.Error));
    dispatch(addErrorToast("There was an issue retrieving the subscription plans."));
    throw err;
  }
};

export const fetchPlans = () => async (dispatch: Dispatch) => {
  try {
    const { data } = await getPlanOptions();
    dispatch(plainPlanChoicesUpdate(data));
    return data;
  } catch (err) {
    dispatch(addErrorToast("There was an issue retrieving the subscription plans."));
    throw err;
  }
};

export const callPostEntitlements =
  (companyId: number, planId: PlanIds) => async (dispatch: Dispatch) => {
    try {
      dispatch(entitlementsStatusUpdate(ApiFetchStatus.Fetching));
      const response = await putEntitlementsPlan(companyId, { planId: planId });
      dispatch(entitlementsUpdate(response.data.activeEntitlements));
      dispatch(addSuccessToast("Subscription Plan updated successfully."));
      return response;
    } catch (err) {
      dispatch(entitlementsStatusUpdate(ApiFetchStatus.Error));
      dispatch(
        addErrorToast("There was an issue updating your subscription, please try again.")
      );
      throw err;
    }
  };

export const fetchCompanyEntitlements =
  (id: number) => async (dispatch: any, getState: any) => {
    const state = getState();
    const { role } = selectProfileData(state);

    if ([ProfileRoles.JuniorStaff, ProfileRoles.Staff].includes(role)) return;

    dispatch(entitlementsStatusUpdate(ApiFetchStatus.Fetching));
    return getCompanyEntitlements(id)
      .then(({ data }: { data: IEntitlements }) => {
        dispatch(entitlementsUpdate(data));
      })
      .catch(() => {
        dispatch(entitlementsStatusUpdate(ApiFetchStatus.Error));
        dispatch(addErrorToast("Could not load Subscription Information."));
      });
  };
