import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  reportProspect,
  prospectUpdateOptimistically,
} from "../../../main/store/prospectStore/thunks";
import { addNewToast } from "../../../main/store/Global/Toasts/actions";
import Modal from "../../../common/components/Modal";

const ReportConfirmationModal = ({ isOpen, toggle, prospect }) => {
  const [isReporting, setIsReporting] = useState(false);
  const dispatch = useDispatch();

  const reportingComplete = () => {
    setIsReporting(false);
    toggle();
  };

  const onConfirm = () => {
    setIsReporting(true);
    if (!prospect.isBlocked) {
      dispatch(reportProspect(prospect.id, reportingComplete));
    } else {
      dispatch(
        prospectUpdateOptimistically(prospect.id, {
          isBlocked: !prospect.isBlocked,
        })
      );
      dispatch(
        addNewToast({
          message: "Prospect Updated Successfully.",
          color: "success",
        })
      );
      reportingComplete();
    }
  };

  const btnOptions = [
    {
      name: "Cancel",
      onClick: toggle,
      color: "secondary",
    },
    {
      name: "Confirm",
      color: "danger",
      "data-test": "report-confirm",
      onClick: onConfirm,
      loading: isReporting,
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title="Please Confirm"
      size="md"
      btnOptions={btnOptions}
    >
      <div className="mt-0">
        <p>
          {prospect.isBlocked
            ? `Unblocking this prospect will re-open the ability for this number to send inbound
              calls or texts to your Lead Sherpa account.`
            : `Reporting this prospect as a pre-litigator will add this number to be reviewed
            for the master DNC list, and will help the Lead Sherpa community avoid this prospect.
            This action will also block this prospect in your account. You will no longer receive
            any incoming calls or texts from this number.`}
        </p>
      </div>
    </Modal>
  );
};

export default ReportConfirmationModal;
