import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import MessagesTab from "./messageTab/MessageTab";
import { getMessagesFeed } from "../../main/store/ProspectDetails/messages/selectors";
import { NoResults } from "../../common/components/DataLoader";
import { uiGetActiveProspect } from "../../main/store/uiStore/campaignsPageDesktopView/campaignsList/filterData/selectors";
import { getProspect } from "../../main/store/prospectStore/selectors";
import reminderComplete from "assets/images/Hand_with_Phone.svg";

const MessageDetail = ({
  prospectId: passedProspect,
  isSequence,
  callRemindersPending,
  children = [],
  className,
  fromProspectColor,
  selectedReminder,
  toggleModal,
}) => {
  const uiProspectId = useSelector(uiGetActiveProspect);
  // nullish coalescing because prospectId is allowed to be 0;
  const prospectId = passedProspect ?? uiProspectId;
  const [stateProspectId, setStateProspectId] = useState(0);
  const prospect = useSelector((state) => getProspect(state, prospectId));
  const items = useSelector((state) => getMessagesFeed(state, prospectId));

  useEffect(() => {
    setStateProspectId(prospectId);
  }, [prospectId]);

  const showCallComplete = isSequence && !callRemindersPending;

  if (showCallComplete)
    return (
      <Wrapper>
        {children}
        <div className="d-flex py-5 px-5 flex-column align-items-center">
          <h4>Nice Job! You are done with calls today.</h4>
          <img
            className="align-self-center mt-3"
            src={reminderComplete}
            height="300px"
            width="350px"
            alt="Call reminders complete"
          />
        </div>
      </Wrapper>
    );

  return (
    <Wrapper className={className}>
      {prospectId ? (
        <>
          {children}
          <MessagesTab
            fromProspectColor={fromProspectColor}
            isSequence={isSequence}
            toggleModal={toggleModal}
            messages={items}
            prospectId={stateProspectId}
            selectedReminder={selectedReminder}
            scrollToBot
            showInitials
            isDesktop
            sherpaPhoneNumber={prospect.sherpaPhoneNumber}
            phoneRaw={prospect.phoneRaw}
            optedOut={prospect.optedOut}
            disableMessage={prospect.messageDisableReason}
          />
        </>
      ) : (
        <NoResults style={{ height: "100%" }}>No Prospect Selected</NoResults>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export default MessageDetail;
