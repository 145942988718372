import React, { useEffect, useState } from "react";
import HoverablePopoverList from "module/common/components/Popovers/HoverablePopoverList";
import styled from "styled-components/macro";

// utils
import { formatPhoneNumber } from "module/common/helpers/utils";

const StyledPhoneNumber = styled.span`
  color: var(--sherpaBlue);
  text-decoration: underline;
  cursor: pointer;
`;

const StyledSpanContainer = styled.span`
  width: 12%; // to match the size in the propertiesList file
`;

const PhoneNumbersPopover = (props) => {
  const {
    numbers,
    prospectInfo,
    id,
    threshold,
    displayFirstNumber = false,
    addProspectName = false,
  } = props;
  const [phoneNumbers, setPhoneNumbers] = useState([]);

  useEffect(() => {
    if (numbers.length > 1) {
      const formattedNumbers = numbers.map((value) => {
        return {
          label: (
            <>
              {addProspectName && <div>{prospectInfo}</div>}
              <div>{formatPhoneNumber(value)}</div>
            </>
          ),
          value: value,
        };
      });

      setPhoneNumbers(formattedNumbers);
    }
  }, [addProspectName, numbers, prospectInfo, threshold]);

  return (
    <StyledSpanContainer>
      {displayFirstNumber && (
        <span className="mr-2">{formatPhoneNumber(numbers[0])}</span>
      )}
      {phoneNumbers.length ? (
        <HoverablePopoverList
          id={`phonenumbers-list-${id}`}
          data={phoneNumbers.filter((phone) => phoneNumbers.indexOf(phone) > 0)}
          darkMode
        >
          <StyledPhoneNumber>+{phoneNumbers.length - 1} more</StyledPhoneNumber>
        </HoverablePopoverList>
      ) : null}
    </StyledSpanContainer>
  );
};
export default PhoneNumbersPopover;
