import { createAction } from "../redux-helpers";

export const FETCH_MARKETS = "FETCH_MARKETS";
export const SET_FETCH_MARKETS = "SET_FETCH_MARKETS";
export const SET_FETCH_MARKETS_ERROR = "SET_FETCH_MARKETS_ERROR";
export const DECREMENT_MARKET_CAMPAIGN_COUNT = "DECREMENT_MARKET_CAMPAIGN_COUNT";
export const UPDATE_MARKET = "UPDATE_MARKET";
export const SET_MARKET_NOTIFICATION = "SET_MARKET_NOTIFICATION";

export const setFetchMarketsStatus = createAction(FETCH_MARKETS, "status");
export const setFetchedMarkets = createAction(SET_FETCH_MARKETS, "campaignFolders");
export const setFetchedMarketsError = createAction(SET_FETCH_MARKETS_ERROR, "error");
export const decrementMarketCampaignCount = createAction(
  DECREMENT_MARKET_CAMPAIGN_COUNT,
  "market"
);
export const updateMarket = createAction(UPDATE_MARKET, "market");
export const setMarketNotifications = createAction(SET_MARKET_NOTIFICATION);
