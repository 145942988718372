import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wrapper = styled.div`
  ${(props) => `
    width: ${props.width}px;
    height: ${props.height}px;
  `}
  margin: auto;
  position: relative;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircledIcon = ({
  type,
  width,
  height,
  className,
  backgroundColor = "var(--blue-icon)",
  color = "var(--sherpaBlue)",
}) => {
  return (
    <Wrapper
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      className={className}
    >
      <FontAwesomeIcon icon={type} size="2x" color={color} />
    </Wrapper>
  );
};

CircledIcon.propTypes = {
  type: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

CircledIcon.defaultProps = {
  type: "users",
  width: "45",
  height: "45",
};

export default CircledIcon;
