import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { Button } from "reactstrap";
import moment from "moment-timezone";
import usePrevious from "../../../common/hooks/usePrevious";
import { selectUserData } from "../../../main/store/Auth/selectors";

import Modal from "../../../common/components/Modal";
import Note from "./Note";
import NoteForm from "./NoteForm";
import DataLoader from "../../../common/components/DataLoader";
import { Success, Fetching } from "module/common/helpers/variables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  background: var(--darkNavy);
  min-height: 100%;

  .notes-area {
    padding: var(--ypad) var(--xpad) 0;
    flex-basis: 60%;
    background: white;
    flex-grow: 2;
  }
  @media (max-width: 450px) {
    .notes-area {
      padding: unset;
    }
  }

  .comingSoon {
    color: white;
    font-weight: bold;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 400px;
    padding: var(--pad6);
    flex-grow: 1;
    flex-shrink: 2;
  }
`;

export const List = styled.ul`
  padding: 0 var(--xpad);
  overflow: hidden;
  width: 100%;
`;

//update date format used in each note
moment.updateLocale("en", {
  longDateFormat: { LT: "h:mma" },
});

function NotesTab({
  fetchNotes,
  subject,
  subjectId,
  notesList,
  notesStatus,
  addNote,
  editNote,
  deleteNote,
}) {
  const [modal, setModal] = useState(false);
  // for animation
  const [noteIn, setNoteIn] = useState(false);

  const toggle = () => setModal((state) => !state);

  const [newNoteText, setNewNoteText] = useState("");
  const userData = useSelector(selectUserData);
  const notesStatuses = useSelector(notesStatus);
  const dispatch = useDispatch();

  const curatedNotes = notesList || [];
  const prevList = usePrevious(curatedNotes) || curatedNotes;

  const handleNewNote = async () => {
    const note = {
      text: newNoteText,
      [subject]: subjectId,
      createdBy: userData.id,
    };

    const isSuccess = await dispatch(addNote(note));
    if (isSuccess) {
      setModal(false);
      setNewNoteText("");
    }
  };

  const handleEditNote = async (note, text) => {
    dispatch(editNote({ note, text }));
  };

  const handleDeleteNote = (note) => dispatch(deleteNote(note));

  useEffect(() => {
    // populate notes list
    if (subjectId !== 0 && !notesList) {
      dispatch(fetchNotes(subjectId));
    }
    // eslint-disable-next-line
  }, [subjectId]);

  useEffect(() => {
    const diff = curatedNotes.length - prevList.length;
    if (diff > 0) {
      setNoteIn(true);
    } else if (diff < 0) {
      setNoteIn(false);
    }
  }, [curatedNotes.length, prevList.length]);

  const mapNotes = () =>
    curatedNotes.map((note, idx) => {
      let anim = false;
      if (idx === 0 && noteIn) {
        anim = true;
      }
      return (
        <Note
          id={note.id}
          key={note.id}
          note={note}
          deleteNote={handleDeleteNote}
          updateNote={handleEditNote}
          anim={anim}
        />
      );
    });

  // notes are memoized to prevent rerenders when modal states change
  const memoizedNotes = useMemo(mapNotes, [curatedNotes]);

  const getBtnText = () => {
    const icon = notesStatuses !== Success ? "exclamation-triangle" : "check";
    return !modal ? (
      <FontAwesomeIcon data-test="submit-note-icon" icon={icon} />
    ) : (
      "Submit Note"
    );
  };

  return (
    <Wrapper data-test="notes-tab">
      <div className="notes-area">
        <Heading>
          <h4>Notes</h4>
          <Button color="primary" onClick={toggle} data-test="add-note-btn">
            Add Note
          </Button>
        </Heading>
        <Modal
          isOpen={modal}
          toggle={notesStatuses !== Fetching ? toggle : () => null}
          title="Add a Note"
          size="md"
        >
          <NoteForm
            submitNote={handleNewNote}
            text={newNoteText}
            setText={setNewNoteText}
            btnText={getBtnText()}
            notesStatus={notesStatuses}
          />
        </Modal>
        <DataLoader
          status={notesStatuses}
          data={curatedNotes}
          emptyResultsMessage="Currently there are no notes to display."
          renderData={() => <List>{memoizedNotes}</List>}
        />
      </div>
    </Wrapper>
  );
}

export default NotesTab;
