import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Alert } from "reactstrap";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
// redux
import { removeBanner } from "../../../../store/Global/Banner/actions";
// utils
import { ONE_SECOND_IN_MILLISECONDS } from "module/common/helpers/variables";

const BANNER_LINGER_TIME = 15 * ONE_SECOND_IN_MILLISECONDS;

interface Props {
  dispatch: Dispatch;
  message: string | React.ReactNode;
  id: string | number;
  persist?: boolean;
  icon: IconProp;
  viewHref?: string;
  color?: string;
}

const Banner: React.FC<Props> = ({
  dispatch,
  message,
  id,
  persist = false,
  icon,
  viewHref,
  color = "primary",
}) => {
  const [show, setShow] = useState(true);

  const toggle = useCallback(() => {
    setShow(false);
    dispatch(removeBanner(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (!persist) {
      const timeout = setTimeout(toggle, BANNER_LINGER_TIME);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [id, persist, toggle]);

  const closeOptions = { toggle, isOpen: show };

  return (
    <ShAlert className="d-flex" data-test="banner" color={color} {...closeOptions}>
      <FontAwesomeIcon
        className="ml-1 mr-3 w-auto h-100"
        color="var(--sherpaBlue)"
        icon={icon}
        size="lg"
      />
      {message}
      {viewHref && (
        <Link className="ml-auto" to={viewHref} onClick={toggle}>
          View
        </Link>
      )}
    </ShAlert>
  );
};

const ShAlert = styled(Alert)`
  margin: var(--pad1) var(--pad2) var(--pad1) !important;
  transition: top 0.3s;
  height: 38px;
  border: 1px solid var(--sherpaBlue);

  p,
  a {
    font-size: 14px;
  }

  a {
    cursor: pointer;
  }
`;

export default Banner;
