import { setActivitiesStatus, populateActivities } from "./actions";
import { Fetching, FetchError } from "module/common/helpers/variables";
import * as api from "./api";

export const fetchActivities = (id: number) => async (dispatch: any) => {
  dispatch(setActivitiesStatus(Fetching));
  return api
    .getProspectActivities(id)
    .then(({ data }) => {
      const activities = { [id]: data };
      dispatch(populateActivities(activities));
    })
    .catch(() => {
      dispatch(setActivitiesStatus(FetchError));
    });
};
