import React from "react";

export const subscriptionCardsData = {
  Connect: {
    icon: "users",
    title: "Connect",
    subTitle: "Connect with more prospects",
    content:
      "With the option to text directly from the platform using a local number, Lead Sherpa is the fastest way to reach more prospects. Combine SMS with our list-stacking software, PropStack™, to automatically deduplicate records and create targeted lists for high-volume responses.",
  },
  Conversation: {
    icon: "comments",
    title: "Conversation",
    subTitle: "Keep the conversation going",
    content:
      "Follow-up with leads using our advanced campaign builder and refine messaging with real-time visibility into campaign performance. You can even push texts to and from your own device with an SMS relay so you'll never miss out on a lead again.",
  },
  Compliance: {
    icon: "thumbs-up",
    title: "Compliance",
    subTitle: "Compliance safe",
    content:
      "Designed with always-on compliance, our SMS tool is continuously vetted by top TCPA attorneys and does not allow for robotexting or other non-compliant actions. It also automatically skips over TCPA litigant numbers — a list we update daily.",
  },
  Skiptrace: {
    icon: "user-check",
    title: "Skiptrace",
    subTitle: "Use skip traced contacts",
    content:
      "Improve response rates by using high-quality data sourced from Lead Sherpa's skip trace system. Equip your SMS campaigns with up-to-date mobile numbers and gain insights into property flags such as bankruptcy, foreclosure, and lien dates.",
  },
};

export const tenDLCData = {
  "10DLC Compliance": {
    icon: "phone",
    title: "10DLC Compliance",
    content: (
      <>
        <div className="textM">
          In order to be able to provide our A2P (application - to - person) messaging
          service, we must comply with The Campaign Registry (TCR). TCR works as the
          governing body for 10DLC (10 Digit Long Code) A2P messaging. In order to stay in
          compliance we must register each of our customer with them. To register you we
          require some basic information about your legal business registration. If you
          would like to learn more you can click below.
        </div>
        <a
          className="btn btn-link btn-lg mt-2"
          target="_blank"
          role="button"
          aria-pressed="true"
          href="https://leadsherpa.freshdesk.com/support/solutions/articles/44002323978-sms-compliance-guide-a2p-10dlc"
        >
          Learn More
        </a>
      </>
    ),
  },
  "EIN Requirements": {
    icon: "calendar-check",
    title: "EIN Requirements",
    content: (
      <>
        <div className="textM">
          TCR compliance requires all companies wishing to send messages via an A2P system
          like Lead Sherpa, have to provide an valid EIN to verify that they are an an
          actual business. We have found that an EIN must be active for at least 5
          business days, in order for TCRs system to be able to verify the business. For
          more questions please reach out to our Lead Sherpa support team:
        </div>
        <a
          className="btn btn-lg btn-link mt-3"
          href="mailto:support@leadsherpa.com"
          target="_blank"
          aria-pressed="true"
        >
          support@leadsherpa.com
        </a>
      </>
    ),
  },
  "Website Requirements": {
    icon: "globe",
    title: "Website Requirement",
    content: (
      <>
        <div className="textM">
          In order for The Campaign Registry (TCR) to approve your application to message,
          you must have a valid website. Your website must match your company name,
          explain what you do and your website domain must match your email domain.
        </div>
      </>
    ),
  },
};
