import React from "react";
import { Body, Title, Wrapper } from "./styles";

const Card = ({
  icon,
  title,
  body,
  smallPadding,
  isChildRow = false,
  isMarginLeft = false,
  className,
}) => {
  return (
    <Wrapper className={className} isChildRow={isChildRow} isMarginLeft={isMarginLeft}>
      {icon}
      <Title>{title}</Title>
      <Body className="textM" smallPadding={smallPadding}>
        {body}
      </Body>
    </Wrapper>
  );
};

Card.defaultProps = {
  smallPadding: false,
};

export default Card;
