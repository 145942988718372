import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUserData } from "../../main/store/Auth/selectors";

const id = "44000000144";
const url = "https://widget.freshworks.com/widgets/44000000144.js";

function FreshDeskWidget() {
  const userData = useSelector(selectUserData);

  useEffect(() => {
    window.fwSettings = { widget_id: id };

    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src = url;
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    const addFunction = () => {
      if (typeof window.FreshworksWidget !== "function") {
        const n = function () {
          n.q.push(arguments);
        };
        n.q = [];
        window.FreshworksWidget = n;
      }
    };
    addFunction();

    return () => {
      document.body.removeChild(script);
    };
  }, [userData.id]);

  return null;
}

export default FreshDeskWidget;
