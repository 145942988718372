import { produce } from "immer";
import {
  SET_SMS_MANAGER_ACTIVE_TAB,
  RESET_SMS_MANAGER_ACTIVE_TAB,
  SET_SMS_TEMPLATE_FILTERS_MODAL,
} from "./actions";

const state = {
  activeTab: "1",
  category: null,
  draggedItem: null,
  list: null,
  isSmsFiltersModalOpen: false,
};

export const path = ["uiStore", "smsManagerPageView"];

const reducer = produce((base: any, action: any) => {
  switch (action.type) {
    case SET_SMS_MANAGER_ACTIVE_TAB: {
      base.activeTab = action.payload;
      break;
    }
    case RESET_SMS_MANAGER_ACTIVE_TAB: {
      base.activeTab = "1";
      break;
    }
    case SET_SMS_TEMPLATE_FILTERS_MODAL: {
      base.isSmsFiltersModalOpen = action.payload;
    }
  }
}, state);

export default reducer;
