import styled from "styled-components";
import { NavItem } from "reactstrap";
import { CSSTransition } from "react-transition-group";

export const SlideIn = styled(CSSTransition)`
  --timing: 0.3s;
  --width: calc(2rem + 10vw);
  position: relative;
  overflow: hidden;
  transition:
    width var(--timing),
    margin var(--timing);
  width: 0px;
  /* display: block; */
  &:not(.navText) {
    display: none;
  }
  &.navText {
    margin-left: 0rem;
  }

  &.slide {
    &-appear {
      display: block;
      width: 0px;
      &-active {
        display: block;
        width: var(--width);
      }
      &-done {
        display: block;
        width: var(--width);
      }
    }
    &-enter {
      width: 0px;

      &-active {
        &.navText {
          margin-left: 1rem;
        }
        width: var(--width);
        display: block;
      }
      &-done {
        &.navText {
          margin-left: 1rem;
        }
        width: var(--width);
        display: block;
      }
    }
    &-exit {
      &.navText {
        margin-left: 1rem;
      }
      transition:
        width var(--timing),
        margin 0.6s;
      width: var(--width);
      &-active {
        transition:
          width var(--timing),
          margin 0.6s;
        &.navText {
          margin-left: 0rem;
        }
        display: block;
        width: 0px;
      }
    }
  }
`;

export const MiniSlideIn = styled(CSSTransition)`
  --timing: 0.2s ease-in-out;
  --left: 0%;
  position: relative;
  transition: left var(--timing);
  left: var(--left);
  flex: 0 0 100%;

  &.slide {
    &-enter {
      left: 110%;
      &-active {
        left: 110%;
      }
      &-done {
        left: var(--left);
      }
    }
    &-exit {
      left: var(--left);
      &-active {
        left: -110%;
      }
    }
  }
`;

export const StyledNavItem = styled(NavItem)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-left: 4px solid transparent;
  padding: var(--pad1) var(--pad2);
  position: relative;
  width: 100%;

  &:active,
  &:hover {
    background: rgba(0, 0, 0, 0.25);
    border-left-color: rgba(0, 0, 0, 0.25);
  }
  &.active {
    background: rgba(0, 0, 0, 0.6);
    border-left-color: var(--sherpaBlue);

    svg {
      color: white;
    }
  }
  &:not(.active) img,
  &:not(.active) span {
    opacity: 0.4;
  }

  img {
    max-width: 3vw;
  }
  svg {
    /* width: 2rem !important;
    height: 2rem; */
    color: #ffffff44;
  }
  .navLink {
    justify-content: center;
    align-items: center;
  }
  span {
    white-space: nowrap;
  }
`;
