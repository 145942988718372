import AxiosConfig from "../../../common/utils/axiosConfig";

export const getTemplateCategories = () => {
  return AxiosConfig.get(`/template-categories/`);
};

export const getScribeSherpaMessagesCategories = () => {
  return AxiosConfig.get("/campaigns/scribe_sherpa_messages_categories");
};

export const getTemplateCategory = (id: number) => {
  return AxiosConfig.get(`/template-categories/${id}`);
};

export const postTemplateCategory = (payload: any) => {
  return AxiosConfig.post(`/template-categories/`, payload);
};

export const patchTemplateCategory = (id: number, payload: any) => {
  return AxiosConfig.patch(`/template-categories/${id}/`, payload);
};

export const patchTemplateCategorySortOrder = (id: number, payload: any) => {
  return AxiosConfig.patch(`/template-categories/${id}/sort/`, payload);
};

export const deleteTemplateCategory = (id: number) => {
  return AxiosConfig.delete(`/template-categories/${id}`);
};
