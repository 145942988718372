import React, { useContext } from "react";
import styled from "styled-components/macro";

// components
import { MessageInputTextAreaContext } from "module/common/helpers/context";
import DataLoader from "../../../../../common/components/DataLoader";
// utils
import { useProspectQuickReplies } from "./hooks";
import QuickRepliesListItem from "./QuickRepliesListItem";

const QuickRepliesList = ({
  searchTerm,
  prospectId,
  setQuickRepliesModalVisibility,
  setQuickReplyToEdit,
  setShowEditForm,
}) => {
  // quick replies state
  const { allQuickReplies, isLoadingState, quickRepliesSearchResults } =
    useProspectQuickReplies(prospectId, searchTerm);

  // expose the message box input
  const { setMessageTextAreaValue, messageTextboxRef } = useContext(
    MessageInputTextAreaContext
  );

  // functionality on individual item
  const handleQuickReplyClick = (reply) => {
    if (reply.isInvalid) {
      return;
    }
    setMessageTextAreaValue(reply.messageFormatted);
    setQuickRepliesModalVisibility(false);
    messageTextboxRef.current.focus();
  };

  const onEdit = (quickReply) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    // close quick-replies modal
    setQuickRepliesModalVisibility(false);
    setQuickReplyToEdit(quickReply);
    setShowEditForm(true);
  };

  return (
    <>
      <DataLoader
        status={isLoadingState}
        data={allQuickReplies}
        emptyResultsMessage="No Quick Replies found"
        renderData={() => (
          <QuickRepliesContainer data-test="quick-replies">
            {quickRepliesSearchResults.map((reply) => (
              <QuickRepliesListItem
                key={reply.id}
                data={reply}
                onClick={handleQuickReplyClick}
                onEdit={onEdit(reply)}
              />
            ))}
          </QuickRepliesContainer>
        )}
      />
    </>
  );
};

const QuickRepliesContainer = styled.ul`
  padding: 0;

  li {
    list-style: none;
    padding: var(--pad1) var(--pad3);

    &:active,
    &:hover {
      background: var(--lightGray);
    }
  }
`;

export default QuickRepliesList;
