import React from "react";
import { useDispatch } from "react-redux";
import { removeModal } from "module/main/store/Global/Modals/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IModal } from "module/main/store/Global/Modals/interfaces";

// components
import Modal from "module/common/components/Modal";
import { modalTemplate } from "./templates";

const StatusModal = (props: IModal) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(removeModal(id));
  };

  const { title, id, open, btnOptions, image, color, message } = modalTemplate({
    ...props,
    closeModal,
  });

  return (
    <Modal
      size="md"
      title={title}
      id={id}
      isOpen={open}
      toggle={closeModal}
      btnOptions={btnOptions}
      btnOptionsClassName="justify-content-center"
    >
      <div className="d-flex justify-content-center align-items-center mb-2">
        {image ? (
          <img
            width="150px"
            src={image.src}
            alt={image.alt}
            className="align-self-center mt-3"
          />
        ) : (
          <FontAwesomeIcon size="6x" icon="exclamation-circle" color={color} />
        )}
      </div>
      <div className="px-2 pt-3 textL">{message}</div>
    </Modal>
  );
};

export default StatusModal;
