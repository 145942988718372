import { Dispatch } from "redux";
import * as Sentry from "@sentry/browser";
import {
  newSequenceKey,
  AppEnv,
  ApiFetchStatus,
  TrackerMode,
} from "module/common/helpers/variables";
import { setAuthToken, revokeAuthToken } from "./utils";
import { saveToLocalStorage, removeFromLocalStorage } from "module/common/utils/utils";

import {
  setAuthStatus,
  unsetAuthenticated,
  setAuthenticated,
  setAuthError,
  setUserInfo,
  setLoggingIn,
  setVerificationStatus,
} from "./actions";
import { companySetProfiles } from "../Company/profiles/actions";
import {
  ACCESS_REG_TEMP_STORAGE_KEY,
  REFRESH_REG_TEMP_STORAGE_KEY,
  INACTIVE_LEAD_STORAGE_KEY,
} from "module/common/helpers/storage";

import * as api from "./api";
import { postResendActivation } from "module/main/services/Auth";

import { setNateroLogin, setNateroLogout } from "module/main/scripts/natero";
import { setCompanyData } from "../Company/actions";
import { arrayToMapIndex } from "../utils";
import {
  churnZeroSetContact,
  churnZeroTrackEvent,
  IChurnWindow,
} from "module/main/services/ChurnZero";
import { globalDynamicConfig } from "dynamicConfig";
import { enrichApiNumberData } from "module/common/helpers/utils";

declare const window: IChurnWindow;

export const fetchUserInfo = () => async (dispatch: Dispatch) => {
  return api
    .getUserInfo()
    .then(({ data }) => {
      if (data.company) {
        const {
          company: { profiles, ...companyData },
          ...userData
        } = data;
        churnZeroSetContact({
          accountId: data.company.id,
          contactId: data.id,
        });
        const featureNotifications = arrayToMapIndex(
          "id",
          data.profile.featureNotifications
        );
        const formattingOptions = {
          datetimeLastSynced: "mdt_datetime",
          datetimeColdMessagingRestorable: "mdt_datetime",
        } as const;
        const formattedCompanyData = enrichApiNumberData(companyData, formattingOptions);
        dispatch(
          setCompanyData({
            featureNotifications,
            ...formattedCompanyData,
          })
        );
        dispatch(companySetProfiles(profiles));
        dispatch(setUserInfo(userData));
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

interface Credentials {
  username: string;
  password: string;
}

export const authenticate = (credentials: Credentials) => {
  return async (dispatch: any) => {
    dispatch(setAuthStatus(ApiFetchStatus.Fetching));
    dispatch(setLoggingIn(true));
    return api
      .doAuthenticate(credentials)
      .then(({ data }) => {
        setAuthToken(data);
        return dispatch(fetchUserInfo()).then((res: any) => {
          dispatch(setLoggingIn(false));
          churnZeroTrackEvent({
            eventName: "LoggedIn",
            description: res.email,
          });
          let interestingFeatures;

          // if the company is null, that means the user hasn't finished registration and must be redirected back to /register page to complete.
          if (res.company === null) {
            saveToLocalStorage(ACCESS_REG_TEMP_STORAGE_KEY, data.access);
            saveToLocalStorage(REFRESH_REG_TEMP_STORAGE_KEY, data.refresh);
          } else {
            interestingFeatures = res.profile.interestingFeatures;
            dispatch(setAuthenticated(data));
            saveToLocalStorage("access", data.access);
            saveToLocalStorage("refresh", data.refresh);
          }
          setNateroLogin(res);
          return interestingFeatures;
        });
      })
      .catch(async ({ response }) => {
        const {
          data: { detail },
        } = response;
        try {
          // throw default error if no resend potential
          if (!detail.includes("No active account")) throw new Error();
          const resendPayload = { email: credentials.username };
          // this will fail if account is already active or doesn't exist
          await postResendActivation(resendPayload);
          return saveToLocalStorage(INACTIVE_LEAD_STORAGE_KEY, "true");
        } catch {
          dispatch(setAuthError(detail));
          throw response;
        }
      });
  };
};

export const callPostVerifySession = (token: string) => async (dispatch: any) => {
  dispatch(setVerificationStatus(ApiFetchStatus.Fetching));
  return api
    .postVerifySessionToken({ token })
    .then(() => {
      dispatch(setVerificationStatus(ApiFetchStatus.Success));
    })
    .catch(() => {
      dispatch(setVerificationStatus(ApiFetchStatus.Error));
      dispatch(logout());
    });
};

export const logout = () => async (dispatch: Dispatch) => {
  // Badge API to add the badge with unread count on the PWA
  if (navigator.setAppBadge) {
    navigator.setAppBadge(0);
  }

  dispatch(unsetAuthenticated());
  revokeAuthToken();
  removeFromLocalStorage("access");
  removeFromLocalStorage("refresh");
  removeFromLocalStorage(newSequenceKey);

  setNateroLogout();
  dispatch({ type: "RESET" });

  if (
    process.env.NODE_ENV !== AppEnv.Production ||
    globalDynamicConfig.config.REACT_APP_DYNAMIC_TRACKERS_MODE ===
      TrackerMode.NoTrackers ||
    !window.ChurnZero
  )
    return;

  try {
    window.ChurnZero?.push(["trackEvent", "Logout"]);
    window.ChurnZero?.push(["stop"]);
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setExtra("Error", err || null);
      Sentry.captureMessage("Failed ChurnZero call");
    });
  }
};
