import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

// store related
import { selectUserData } from "../../../main/store/Auth/selectors";
import { getCompanyData } from "../../../main/store/Company/selectors";
import BlockedMessagePage from "./components/BlockedMessagePage";

// utils
import { getUserTypeBasedOnSubscription, userHasPermission } from "./utils";

const useWithRolePermissions = (permission) => {
  const { profile } = useSelector(selectUserData);
  return {
    hasRolePermission: userHasPermission(profile, permission),
  };
};

// decides whether a user can view a component
const userCanViewPage = (userType, pageName) => {
  return userType.view[0] === "all" || userType.view.includes(pageName);
};

// redirects or shows block message
const redirectOrShowBlockedMessage = (userType, pageName, noHeader) => {
  if (userType.redirectTo && !userType.showBlockedMessageTo.includes(pageName)) {
    return <Redirect to={userType.redirectTo} />;
  }

  if (userType.blockedMessage) {
    const blockedMessage =
      userType.blockedMessage[pageName] || userType.blockedMessage.all;
    return renderBlockedMessage(blockedMessage, noHeader);
  }
};

// renders the blocked message
const renderBlockedMessage = (blockedMessage, noHeader) => {
  return (
    <BlockedMessagePage noHeader={noHeader} header={blockedMessage.header}>
      {blockedMessage.message}
    </BlockedMessagePage>
  );
};

const WithPermissions = ({
  checkAllPermissions,
  checkSubscription,
  showBlockedMessage,
  redirect,
  checkRole,
  pageName,
  permissionDeniedMessage,
  blockedMessageWithoutHeader,
  permission,
  children,
}) => {
  const company = useSelector(getCompanyData);

  const { hasRolePermission } = useWithRolePermissions(permission);

  // get the userType based on the users subscription status
  const userType = getUserTypeBasedOnSubscription(company);
  const redirectOrMessage = (redirectTo = null) =>
    redirect ? (
      <Redirect to={redirectTo || "/campaigns"} />
    ) : permissionDeniedMessage ? (
      permissionDeniedMessage
    ) : null;

  // checks both role permissions and subscriptions
  if (checkAllPermissions) {
    // check if userType or features features restricts pages and role permission
    if (!hasRolePermission) return redirectOrMessage();

    // check now subscription permissions
    if (!userCanViewPage(userType, pageName))
      return redirectOrMessage(userType.redirectTo);

    // decide whether or not to show a blocked-page this is mainly
    // used on =container= components that want to show a blocked
    // message and not for components that require permissions from role
    if (showBlockedMessage && userType.showBlockedMessageTo.includes(pageName)) {
      const blockedMessage =
        userType.blockedMessage[pageName] || userType.blockedMessage.all;
      return renderBlockedMessage(blockedMessage, blockedMessageWithoutHeader);
    }

    // if the component being looked at is not a page we need to check
    // if subscription status needs to block it or not
    if (userType.blockedComponent.includes(pageName)) {
      return null;
    }

    // if all passes render children
    return children;
  } else if (checkSubscription) {
    // checks only subscriptions
    // check if they can view the page based on their subscription
    if (userCanViewPage(userType, pageName)) {
      // show blocked message for components that don't require redirects
      // nor want to show their content
      if (showBlockedMessage && userType.showBlockedMessageTo.includes(pageName)) {
        const blockedMessage =
          userType.blockedMessage[pageName] || userType.blockedMessage.all;
        return renderBlockedMessage(blockedMessage, blockedMessageWithoutHeader);
      }

      // if the component being looked at is not a page we need to check
      // if subscription status needs to block it or not
      if (userType.blockedComponent.includes(pageName)) {
        return null;
      }

      return children;
    }
    // redirect or show blocked message
    return redirect
      ? redirectOrShowBlockedMessage(userType, pageName, blockedMessageWithoutHeader)
      : null;
  } else if (checkRole) {
    // allow for fined-grained role-based permissions
    return hasRolePermission ? children : redirectOrMessage();
  }

  return children;
};

WithPermissions.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** Checks the user's permissions before rendering the component */
  checkAllPermissions: PropTypes.bool,
  /** Checks the user's subscription status before rendering the component */
  checkSubscription: PropTypes.bool,
  /** Displays the blocked message based on the user type if true */
  showBlockedMessage: PropTypes.bool,
  /** Redirects the user the a specific location */
  redirect: PropTypes.bool,
  /** Checks the user's role before rendering the component */
  checkRole: PropTypes.bool,
  /** Page location/area of the component */
  pageName: PropTypes.string,
  /** Displays the permissions denied message */
  permissionDeniedMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Displays the blocked message without a header if set to true */
  blockedMessageWithoutHeader: PropTypes.bool,
  /** The permission the user needs to access the children */
  permission: PropTypes.string,
};

WithPermissions.defaultProps = {
  checkAllPermissions: false,
  checkSubscription: false,
  showBlockedMessage: false,
  redirect: false,
  checkRole: false,
  pageName: "",
  permissionDeniedMessage: "",
  blockedMessageWithoutHeader: false,
  hasCompanyFeatureAccess: true,
};
export default WithPermissions;
