import React from "react";
import styled from "styled-components/macro";
import { isMobile } from "../../../../../detectDevice";

const StyledMainInfo = styled.span`
  color: var(--darkGray);
`;

export default function MainInfo(props) {
  return (
    <StyledMainInfo className={`${isMobile ? "textXL" : "textL"}`}>
      {props.message}
    </StyledMainInfo>
  );
}
