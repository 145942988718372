import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validatePhone } from "../formFields/FormFieldsRenderer";
import { campaignProspectClone } from "../../../main/store/campaignProspectStore/api";
import { MiniSlideIn } from "module/main/components/styles";
import { updateProspectList } from "../../../main/store/prospectStore/actions";
import { addNewToast } from "../../../main/store/Global/Toasts/actions";
import { prospectGetCampaigns } from "../../../main/store/prospectStore/api";
import FormRenderer from "../FormRenderer";
import DataLoader from "../DataLoader";
import { Fetching, Success } from "module/common/helpers/variables";

const ProspectClone = (props) => {
  const {
    // eslint-disable-next-line
    initialState: { phoneRaw, campaign, ...formState },
    selectedCampaign,
  } = props;
  const [phase, setPhase] = useState(0);
  const [clonedProspectId, setClonedProspectId] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [defaultValues, setDefaultValues] = useState(formState);
  const [defaultSelectedCampaign, setDefaultSelectedCampaign] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    // auto-select campaign if selectedCampaign has been passed down
    if (selectedCampaign && campaigns.length > 0) {
      const campaignFound = campaigns.find((c) => c.id === selectedCampaign);
      const campaignOption = {
        label: campaignFound.name,
        value: campaignFound.id,
      };

      setDefaultSelectedCampaign(campaignOption);
      setDefaultValues((values) => ({
        ...values,
        campaign: campaignOption,
      }));
    }

    // eslint-disable-next-line
  }, [campaigns]);

  const onSubmit = ({ data, setError, setSubmittingState, setServerError }) => {
    campaignProspectClone(formState.id, { ...data, campaign: data.campaign.value })
      .then(({ data }) => {
        setClonedProspectId(data.prospect.id);
        const newProspectData = {
          ...data.prospect,
          campaign: data.campaign,
          campaigns: [data.campaign],
        };
        dispatch(
          updateProspectList({ results: { [data.prospect.id]: newProspectData } })
        );
        setSubmittingState(false);
        setPhase(1);
      })
      .catch(({ response }) => {
        setSubmittingState(false);

        if (!response.data.detail) {
          setServerError(response.data.nonFieldErrors || []);
          Object.entries(response.data).forEach(([field, errors]) => {
            setError(
              errors.map((error) => ({
                type: "validate",
                name: field,
                message: error,
              }))
            );
          });
        } else {
          dispatch(
            addNewToast({
              message: "Something went wrong try again later.",
              color: "danger",
            })
          );
        }
      });
  };

  useEffect(() => {
    // we only want to run this once
    if (campaigns.length === 0) {
      // fetch campaigns
      prospectGetCampaigns(formState.id).then(({ data }) => {
        setCampaigns(data);

        // in case we get 1 campaign
        if (data.length === 1) {
          setDefaultValues((values) => ({
            ...values,
            campaign: { label: data[0].name, value: data[0].id },
          }));
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const campaignOptions = campaigns.map((_campaign) => ({
    label: _campaign.name,
    value: _campaign.id,
  }));

  const fields = [
    [
      {
        name: "firstName",
        label: "First Name",
        component: "input",
        inputProps: { placeholder: "Name" },
        rules: {
          required: "Required",
          maxLength: { value: 255, message: "Exceeds max length of 255" },
        },
      },
      {
        name: "lastName",
        label: "Last Name",
        component: "input",
        inputProps: { placeholder: "Last Name" },
        rules: {
          required: "Required",
          maxLength: { value: 255, message: "Exceeds max length of 255" },
        },
      },
    ],
    [
      {
        name: "phoneRaw",
        label: "Phone",
        component: "phone",
        inputProps: { placeholder: "Add phone here" },
        rules: { validate: validatePhone },
      },
    ],
    [
      {
        name: "propertyAddress",
        label: "Street",
        component: "input",
        inputProps: { placeholder: "Address" },
        rules: { required: "Required" },
      },
      {
        name: "propertyCity",
        label: "City",
        component: "input",
        inputProps: { placeholder: "City" },
        rules: { required: "Required" },
      },
    ],
    [
      {
        name: "propertyState",
        label: "State",
        component: "input",
        inputProps: { placeholder: "State" },
        rules: { required: "Required" },
      },
      {
        name: "propertyZip",
        label: "Zipcode",
        component: "input",
        inputProps: { placeholder: "Zipcode" },
        rules: { required: "Required" },
      },
    ],
    [
      {
        name: "campaign",
        label: "Campaign",
        component: "select",
        inputProps: {
          placeholder: "Select a campaign",
          options: campaignOptions,
          defaultValue: defaultSelectedCampaign,
        },
        rules: { required: "Required" },
      },
    ],
  ];

  return (
    <div data-test="prospect-clone-form">
      <MiniSlideIn
        in={phase === 0}
        timeout={300}
        appear
        classNames="slides"
        className="d-flex flex-column justify-content-between"
        data-test="phase1"
        unmountOnExit
      >
        <DataLoader
          status={campaigns.length > 0 ? Success : Fetching}
          data={campaigns}
          renderData={() => {
            return (
              <FormRenderer
                fields={fields}
                onSubmit={onSubmit}
                defaultValues={defaultValues}
                buttonProps={{
                  name: "Clone",
                  className: "pr-4 pl-4",
                  "data-test": "prospect-form-submit",
                }}
              />
            );
          }}
        />
      </MiniSlideIn>
      <MiniSlideIn
        in={phase === 1}
        timeout={300}
        appear={false}
        classNames="slide"
        className="d-flex flex-column justify-content-between"
        data-test="phase2"
        unmountOnExit
      >
        <div className="phase2">
          <h6 className="d-flex justify-content-center">
            <FontAwesomeIcon
              size="lg"
              className="mr-1"
              icon="check-circle"
              color="green"
            />
            Prospect Cloned Successfully
          </h6>
          <div className="d-flex justify-content-end">
            <Button
              data-test="view-prospect-clone-button"
              className="mt-3"
              color="primary"
              size="lg"
              onClick={props.viewProspect(clonedProspectId)}
            >
              View Prospect
            </Button>
          </div>
        </div>
      </MiniSlideIn>
    </div>
  );
};
export default ProspectClone;
