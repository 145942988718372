import React, { useRef, useState, useEffect } from "react";
import InputGroupBorder from "./InputGroupBorder";
import { Button, Input, InputGroupAddon } from "reactstrap";
import IconBg from "./IconBg";
import styled, { css } from "styled-components";
import useRefDimensions from "module/common/hooks/useDimension";

const StyledInput = styled(Input)`
  font-size: 1rem;
  ${(props) =>
    props.$dropdownToggle &&
    css`
      color: transparent !important;
      text-shadow: 0px 0px 1px #000000 !important;
    `}
`;

const InputWithClearButton = (props) => {
  const {
    icon,
    dropdownToggle,
    subInput,
    smallScreenWidth,
    placeholder: basicPlaceholder,
    placeholderForSmallScreen,
  } = props;
  const [value, setValue] = useState("");

  const inputRef = useRef({});
  const { width } = useRefDimensions(inputRef);
  const placeholder =
    width && smallScreenWidth && width <= smallScreenWidth && placeholderForSmallScreen
      ? placeholderForSmallScreen
      : basicPlaceholder || "Search";

  const determineIfDisabled = () => {
    const value = props.value || props.defaultValue;
    return typeof value === "string" && value.length < props.enableSearchThreshold;
  };

  useEffect(() => {
    setValue(props.value || "");
  }, [props.value]);

  const onChange = (e) => {
    if (dropdownToggle) {
      return null;
    }
    const {
      target: { value },
    } = e;
    setValue(value);
    props.onChange(value);
  };

  const handleKeyPress = (e) => {
    const { charCode } = e;
    if (charCode === 13) {
      props.onSearch && props.onSearch(e);
    }
  };

  return (
    <InputGroupBorder error={props.error} className="h-100">
      <StyledInput
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        value={value}
        type={props.type || "text"}
        name={props.name || "Search"}
        placeholder={placeholder}
        innerRef={(el) => {
          inputRef.current = el;
        }}
        onChange={onChange}
        autoFocus={props.autoFocus}
        $dropdownToggle={dropdownToggle}
        data-test="input-field"
        onKeyPress={handleKeyPress}
      />
      <InputGroupAddon addonType="append">
        {subInput ? (
          value.length === 0 ? null : (
            <Button
              onClick={() => {
                props.clearInput();
                setValue("");
              }}
              className="p-0"
              color="link"
              type="button"
            >
              <IconBg $textCol="white" icon="times" />
            </Button>
          )
        ) : value.length === 0 ? (
          <Button
            disabled={props.disabledOnEmpty && determineIfDisabled()}
            className="p-0 btn-sm"
            color="link"
            data-test="submit-button"
            type={dropdownToggle ? "button" : "submit"}
          >
            <IconBg $textCol="white" icon={icon ? icon : "search"} />
          </Button>
        ) : (
          <Button
            onClick={() => {
              props.clearInput();
              setValue("");
            }}
            className="p-0"
            color="link"
            type="submit"
          >
            <IconBg $textCol="white" icon="times" />
          </Button>
        )}
      </InputGroupAddon>
    </InputGroupBorder>
  );
};

export default InputWithClearButton;
