import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getServerStatus } from "../../../store/Global/selectors";
import useInterval from "module/common/hooks/useInterval";
import usePrevious from "module/common/hooks/usePrevious";
import { pingServer } from "module/main/services/Auth";
import { addNewToast, removeToast } from "../../../store/Global/Toasts/actions";
import { getToasts } from "../../../store/Global/Toasts/selectors";
import { generalNetworkError } from "module/common/helpers/variables";

const AppStatus = () => {
  const [interval, setInterval] = useState(null);

  const prevInterval = usePrevious(interval) || interval;
  const serverStatus = useSelector(getServerStatus);
  const toasts = useSelector(getToasts);
  const dispatch = useDispatch();

  useInterval(() => {
    pingServer();
  }, interval);

  const renderSuccessMessage = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <span className="font-weight-bold textL">
          Connection found - refresh to resume
        </span>
      </div>
    );
  };

  useEffect(() => {
    // the server status might be bad because the user is offline, the pages might be cached, but we want to notify the user that it's unusable offline
    if (!navigator.onLine) {
      if (toasts.length === 0 || toasts.filter((e) => e.id === "offline").length === 0) {
        dispatch(
          addNewToast({
            message:
              "No internet connection found. App cannot run offline - please connect to the internet to resume.",
            color: "danger",
            persist: "true",
            id: "offline",
          })
        );
      } else if (serverStatus === "bad") {
        //check if this toast already exists
        if (toasts.length === 0 || toasts.filter((e) => e.id === "bad").length === 0) {
          dispatch(
            addNewToast({
              message: generalNetworkError,
              color: "danger",
              persist: "true",
              id: "bad",
            })
          );
        }
      }
      setInterval(5000);
    } else {
      setInterval(null);
    }
  }, [serverStatus, toasts, dispatch]);

  useEffect(() => {
    if (prevInterval !== interval) {
      dispatch(removeToast({ id: "bad" }));
      dispatch(removeToast({ id: "offline" }));

      dispatch(
        addNewToast({
          message: renderSuccessMessage(),
          color: "success",
          persist: true,
        })
      );
    }
    // eslint-disable-next-line
  }, [interval, prevInterval, dispatch]);

  return null;
};

export default AppStatus;
