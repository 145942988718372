import React, { useEffect, useState } from "react";
import { VerticalNavButton, PanelNavContainer, PanelHeader } from "./styles";
import {
  CardBody,
  CardTitle,
  TabContent,
  TabPane,
  Row,
  Col,
  CardSubtitle,
} from "reactstrap";

import CircledIcon from "module/common/components/CircledIcon";

const VerticalPanel = ({ tabData }) => {
  const [tabs, setTabs] = useState(Object.keys(tabData)[0]);
  const [navButtons, setNavButtons] = useState([]);
  const [tabContent, setTabContent] = useState([]);

  const changeActiveTab = (e, tabName) => {
    e.preventDefault();
    setTabs(tabName);
  };

  useEffect(() => {
    const navButtons = [];
    const tabContent = [];

    Object.values(tabData).forEach(({ title, icon, content, subTitle }, i) => {
      navButtons.push(
        <VerticalNavButton
          data-test="nav-button"
          key={`vertical-panel-nav-${i}`}
          className={tabs === `${title}` ? "active nav-button" : "nav-button"}
          onMouseEnter={(e) => changeActiveTab(e, title)}
        >
          <CircledIcon active={tabs === `${title}`} type={icon} />
          <h6 className="pt-1 textM mb-0">{title}</h6>
        </VerticalNavButton>
      );
      tabContent.push(
        <TabPane data-test="tab-pane" key={i} tabId={title}>
          <PanelHeader className="mx-2 px-0">
            <CardTitle tag="h3" className="font-weight-bold mb-1">
              {tabs}
            </CardTitle>
            <CardSubtitle className=" text-muted textL">{subTitle}</CardSubtitle>
          </PanelHeader>
          <CardBody className="textL mt-1 mx-2 px-0">{content}</CardBody>
        </TabPane>
      );
    });

    setTabContent(tabContent);
    setNavButtons(navButtons);
  }, [tabs]);

  return (
    <div>
      <Row className="d-flex justify-content-between">
        <Col xs="2" className="mr-2">
          <PanelNavContainer className="flex-column">{navButtons}</PanelNavContainer>
        </Col>
        <Col xs="9" md="8" className="ml-3 pl-4 mr-auto mt-2 pt-2">
          <TabContent className="pb-0" activeTab={tabs}>
            {tabContent}
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

export default VerticalPanel;
