import styled from "styled-components/macro";
import { Card, Nav, NavItem, CardHeader } from "reactstrap";

export const PanelHeader = styled(CardHeader)`
  background-color: transparent;
`;

export const HorizontalPanelContainer = styled(Card)`
  border: 1px solid var(--blue-icon);
  background: var(--ghostBlueGradient);
  border-radius: var(--borderRadius);
  height: 100%;
`;

export const PanelNavContainer = styled(Nav)`
  justify-content: center;
  li {
    border-radius: var(--borderRadius);
  }
`;

export const VerticalNavButton = styled(NavItem)`
  h6 {
    font-weight: 600 !important;
  }
  text-align: center;
  color: transparent;
  padding: 8px;

  &.active,
  &:hover {
    background: var(--ghostBlueGradient);
    background-color: var(--blue-icon) !important;
    color: var(--primary);
    box-shadow: var(--boxShadow);
    transition: all 0.3s ease 0s;
  }
  &:hover {
    opacity: 85% !important;
    cursor: pointer;
  }
`;

export const HorizontalNavButton = styled(NavItem)`
  text-align: center;
  padding: 2px;
  font-weight: 400;
  margin: 5px;
  opacity: 40%;

  &.active,
  &:hover {
    background: var(--ghostBlue);
    opacity: 95%;
    box-shadow: var(--boxShadow);
    transition: all 0.3s ease 0s;
  }
  &:hover {
    opacity: 75% !important;
    cursor: pointer;
  }
`;
