import {
  FETCH_MARKETS,
  SET_FETCH_MARKETS,
  SET_FETCH_MARKETS_ERROR,
  DECREMENT_MARKET_CAMPAIGN_COUNT,
  UPDATE_MARKET,
} from "./actions";
import { ApiFetchStatus } from "module/common/helpers/variables";
import { IState, IMarket } from "./interfaces";

// campaigns reducer
export const initialState: IState = {
  markets: [],
  error: "",
  next: "",
  previous: "",
  count: 0,
  status: ApiFetchStatus.Initial,
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_MARKETS:
      return {
        ...state,
        status: ApiFetchStatus.Fetching,
      };
    case SET_FETCH_MARKETS:
      return {
        ...state,
        markets: action.campaignFolders,
        status: ApiFetchStatus.Success,
        count: action.campaignFolders.length,
      };
    case SET_FETCH_MARKETS_ERROR:
      return {
        ...state,
        error: action.error,
        status: ApiFetchStatus.FetchError,
      };
    case DECREMENT_MARKET_CAMPAIGN_COUNT:
      const { markets } = state;

      // decrement the campaign count for the market the campaign was archived from
      const toDecrement = markets.reduce((acc: IMarket[], curr: IMarket) => {
        if (curr.id === action.market) {
          curr.campaignCount--;
        }
        acc.push(curr);
        return acc;
      }, []);
      return {
        ...state,
        markets: [...toDecrement],
      };
    case UPDATE_MARKET: {
      const filtered_markets = state.markets.filter(
        (market) => market.id !== action.market.id
      );

      filtered_markets.push(action.market);
      return {
        ...state,
        markets: filtered_markets,
      };
    }
    default:
      return state;
  }
}
