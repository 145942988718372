import { createAction } from "../../redux-helpers";

export const FETCH_REMINDERS = "FETCH_REMINDERS";

export const FETCH_SMS_REMINDERS_SUCCESS = "FETCH_SMS_REMINDERS_SUCCESS";
export const FETCH_CALL_REMINDERS_SUCCESS = "FETCH_CALL_REMINDERS_SUCCESS";

export const FETCH_REMINDERS_FAILURE = "FETCH_REMINDERS_FAILURE";

export const UPDATE_REMINDER_SUCCESS = "UPDATE_REMINDER_SUCCESS";

export const SET_SELECTED_REMINDER = "SET_SELECTED_REMINDER";

export const UPDATE_REMINDERS_PROSPECT = "UPDATE_REMINDERS_PROSPECT";

export const fetchReminders = createAction(FETCH_REMINDERS);

export const fetchSmsRemindersSuccess = createAction(FETCH_SMS_REMINDERS_SUCCESS);
export const fetchCallRemindersSuccess = createAction(FETCH_CALL_REMINDERS_SUCCESS);

export const fetchRemindersFailure = createAction(FETCH_REMINDERS_FAILURE);

export const updateReminderSuccess = createAction(UPDATE_REMINDER_SUCCESS);

export const setSelectedReminder = createAction(SET_SELECTED_REMINDER);

export const updateRemindersProspect = createAction(UPDATE_REMINDERS_PROSPECT);
