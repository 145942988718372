import { ApiFetchStatus } from "module/common/helpers/variables";
import { AuthAction, AuthState } from "./interfaces";

export const initialState: AuthState = {
  status: ApiFetchStatus.Initial,
  verificationStatus: ApiFetchStatus.Initial,
  hasAuthToken: false,
  token: "",
  refreshToken: "",
  error: "",
  userData: {
    username: "",
    email: "",
    id: null,
    fullName: "",
    firstName: "",
    lastName: "",
    isStaff: false,
    profile: {},
  },
  loggingIn: false,
};

export const path = ["auth"];

// NOTE: Add immer
export default function authReducer(state = initialState, action: AuthAction) {
  switch (action.type) {
    case "SET_AUTH_STATUS":
      return { ...state, status: action.status };
    case "SET_VERIFICATION_STATUS":
      return { ...state, verificationStatus: action.status };
    case "SET_AUTH_STATE":
      return {
        ...state,
        token: action.access,
        refreshToken: action.refresh,
        hasAuthToken: true,
        error: "",
        status: ApiFetchStatus.Success,
        verificationStatus: ApiFetchStatus.Success,
      };
    case "SET_LOGGING_IN_STATUS": {
      return { ...state, loggingIn: action.loggingIn };
    }
    case "SET_UNAUTH_STATE":
      return {
        ...initialState,
      };
    case "SET_AUTH_ERROR":
      return {
        ...state,
        error: action.error,
      };
    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.userData,
        status: ApiFetchStatus.Success,
        verificationStatus: ApiFetchStatus.Success,
      };
    case "SET_PROFILE_DATA":
      return {
        ...state,
        userData: {
          ...state.userData,
          profile: action.profile,
        },
        status: ApiFetchStatus.Success,
      };
    default:
      return state;
  }
}
