import React from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  CardTitle,
  CardSubtitle,
  Alert,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

import ImgSMS from "assets/images/sms.svg";
import WithPermissions from "../../WithPermissions";
import HorizontalPanel from "./components/HorizontalPanel";
import VerticalPanel from "./components/VerticalPanel";
import { TopSection, TitleWrapper, SubTitle, HeaderImg, FooterRow, Help } from "./styles";
import { GuideLink } from "module/common/styles";

import { signUpSubscriptionRoute } from "module/main/routes/utils";
import { TEMP_SMS_DISABLE_REASON } from "module/common/helpers/variables";
import { ACCOUNT_SETTINGS_VIEW_PAGE } from "module/main/permissions/accountSettings";
import { subscriptionCardsData, tenDLCData } from "./utils";
import { useSmsFlow } from "module/common/hooks/useSmsFlow";

const leadSherpaSmsPageUrl = "https://leadsherpa.com/sms/";

const SMSLandingPage = () => {
  const history = useHistory();
  const { canIntegrateSms } = useSmsFlow();

  const isGetStartedDisabled = !!TEMP_SMS_DISABLE_REASON || !canIntegrateSms;

  return (
    <>
      <TopSection className="py-4 px-5 container-fluid">
        <Row className="justify-content-center mt-2">
          <Col xs="12" md="8">
            <TitleWrapper>
              <CardTitle tag="h1" className="font-weight-bold">
                Compliant SMS
              </CardTitle>
              <CardSubtitle className="text-muted textL"></CardSubtitle>
              <SubTitle className="my-2">
                The first and only system you need for easy, compliant, and profitable SMS
                campaigns.
              </SubTitle>
              {TEMP_SMS_DISABLE_REASON ? (
                <Alert
                  color="warning"
                  className="my-2 font-weight-bold"
                  data-testid="sms-disabled-alert"
                >
                  SMS subscriptions have been temporarily paused. Details will follow
                  soon.
                </Alert>
              ) : null}
            </TitleWrapper>
            <div className="my-3 container-fluid py-2">
              <WithPermissions checkRole permission={ACCOUNT_SETTINGS_VIEW_PAGE}>
                <div id="get-started-wrapper" className="d-inline-flex">
                  <Button
                    color="primary"
                    className="mr-2"
                    disabled={isGetStartedDisabled}
                    onClick={() => history.push(signUpSubscriptionRoute)}
                    data-testid="get-started-btn"
                  >
                    Get Started
                  </Button>
                </div>
                {isGetStartedDisabled ? (
                  <UncontrolledTooltip
                    target="get-started-wrapper"
                    placement="top"
                    autohide={false}
                  >
                    SMS is currently disabled. Please contact{" "}
                    <a href="mailto:support@leadsherpa.com">support@leadsherpa.com</a> for
                    more information
                  </UncontrolledTooltip>
                ) : null}
              </WithPermissions>
              <a
                className="btn btn-lg btn-link text-primary"
                href={leadSherpaSmsPageUrl}
                target="_blank"
                data-testid="find-more-btn"
              >
                Find Out More
              </a>
            </div>
          </Col>
          <Col xs="6" md="4">
            <HeaderImg className="d-flex justify-content-center">
              <img src={ImgSMS} />
            </HeaderImg>
          </Col>
        </Row>
      </TopSection>
      <Row className="py-2 mb-2">
        <Col xs="12" md="7" className="mx-auto mt-4">
          <VerticalPanel tabData={subscriptionCardsData}></VerticalPanel>
        </Col>
        <Col xs="12" md="5">
          <HorizontalPanel tabData={tenDLCData} />
        </Col>
      </Row>
      <FooterRow>
        <Help>Need some help?</Help>
        <GuideLink
          href="https://leadsherpa.freshdesk.com/support/solutions/44000815009"
          target="_blank"
          data-testid="tutorials-btn"
        >
          All Guides and Tutorials <strong>{">"}</strong>
        </GuideLink>
      </FooterRow>
    </>
  );
};

export default SMSLandingPage;
