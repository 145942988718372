export function objectValuesPolyfill() {
  if (Object.values!) return;
  Object.values = function (obj: Record<string, any>) {
    return Object.keys(obj).map((key) => {
      return obj[key];
    });
  };
}

export function objectEntriesPolyfill() {
  if (Object.entries!) return;
  Object.entries = function (obj: Record<string, any>) {
    const keys = Object.keys(obj);
    let i = keys.length;
    const newArray = new Array(i);

    while (i--) newArray[i] = [keys[i], obj[keys[i]]];

    return newArray;
  };
}

type PromiseSettledResultValue = {
  status: "fulfilled";
  value: any;
};

type PromiseSettledResultRejection = {
  status: "rejected";
  reason: any;
};

type PromiseSettledResult = PromiseSettledResultValue | PromiseSettledResultRejection;

export function promiseAllSettledPolyfill() {
  if (Promise.allSettled!) return;
  Promise.allSettled = function (promises: Promise<any>[]) {
    const mappedPromises = promises.map((promise) => {
      const newPromise = Promise.resolve(promise); // in case we pass non-promise arg
      return newPromise
        .then((value: any) => ({
          status: "fulfilled",
          value,
        }))
        .catch((reason: any) => ({
          status: "rejected",
          reason,
        }));
    });
    return Promise.all(mappedPromises) as Promise<PromiseSettledResult[]>;
  };
}
