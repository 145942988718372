import React, { useMemo } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components/macro";

import NameBubble from "../../../NameBubble";
import { formatPhoneNumber, nameToInitials } from "module/common/helpers/utils";
import { getFormattedDateTime, checkWhenDate } from "module/common/helpers/dateUtils";
import { MessageStatus } from "module/main/store/ProspectDetails/messages/interface";
import { getFailureMessageProps } from "./utils";

function Message(props) {
  const {
    id,
    message,
    mediaUrl,
    dt,
    smsCampaign,
    fromProspect,
    unreadByRecipient,
    phoneRaw,
    fromName,
    fromNumber,
    fromProspectColor,
    customWidth,
    className = "",
    showInitials,
    onClick,
    children,
    status,
  } = props;

  const dateTime = getFormattedDateTime(dt);
  const initials = nameToInitials(fromName);

  const displayFormattedPhoneNumber = (number) => {
    return number ? `|  ${formatPhoneNumber(number)}  |` : "|";
  };

  const bubbleColor = fromProspect ? "sherpaTeal" : "sherpaBlue";
  const phoneNumber = fromProspect
    ? displayFormattedPhoneNumber(phoneRaw)
    : displayFormattedPhoneNumber(fromNumber);

  const iconProps = useMemo(() => {
    switch (status) {
      case MessageStatus.Failed:
      case MessageStatus.Undelivered:
        return getFailureMessageProps(props);
      default:
        return { message: "", icon: "" };
    }
  }, [status]);

  return (
    <StyledListItem
      data-test={fromProspect ? "prospect-message" : "user-message"}
      className={`${className} message textM${unreadByRecipient ? " unread" : ""}`}
      onClick={onClick}
      width={customWidth}
    >
      <MessageInfo
        fromProspect={fromProspect}
        smsCampaign={smsCampaign}
        data-test="message-info"
      >
        <StyledMessage
          className="position-relative"
          unread={unreadByRecipient}
          fromProspect={fromProspect}
          fromProspectColor={fromProspectColor}
          hasError={!!iconProps.icon}
          data-testid="message"
        >
          {iconProps.icon ? (
            <StyledIconWrapper data-testid="icon-wrapper">
              <FontAwesomeIcon
                id={`exclamation-circle-${id}`}
                icon={iconProps.icon}
                color="var(--danger)"
                size="xl"
              />
              <UncontrolledTooltip target={`exclamation-circle-${id}`} fade={false}>
                {iconProps.message}
              </UncontrolledTooltip>
            </StyledIconWrapper>
          ) : null}

          {mediaUrl ? <img src={mediaUrl} alt="" /> : null}
          {message !== "no_text" ? message : null}
        </StyledMessage>
        {fromName ? (
          <TimeStamp className="textS mb-2" fromProspect={fromProspect}>
            <b className="pr-1">{fromName}</b> {phoneNumber}{" "}
            {`${checkWhenDate(dateTime[0])} ${dateTime[1]}`}
          </TimeStamp>
        ) : null}
        {children}
      </MessageInfo>
      {showInitials && <NameBubble color={bubbleColor} initials={initials} />}
    </StyledListItem>
  );
}

const StyledListItem = styled.li`
  list-style: none;
  margin: 0;
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--pad2);
  width: ${(props) => props.width || "unset"};

  &.unread {
    &:after {
      content: "New";
      position: absolute;
      top: 0;
      left: 0;
      color: white;
      font-weight: 900;
      transform: translate(0%, -57%);
      padding: 0.1em 0.8em 1.2em;
      line-height: 1.6;
      background: var(--sherpaBlue);
      z-index: -1;
      border-radius: 7px;
    }
  }

  .nameBubble {
    margin-bottom: calc(1.125rem + var(--pad1));
  }
`;

const MessageInfo = styled.div`
  flex-basis: 100%;
  ${(props) =>
    props.fromProspect
      ? css`
          margin-right: auto;
        `
      : css`
          margin-left: auto;
        `}
  max-width:${(props) => (props.smsCampaign ? "100%" : "70%")};
`;

const StyledIconWrapper = styled.div`
  position: absolute;
  right: 3px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
`;

const StyledMessage = styled.div`
  ${(props) =>
    props.fromProspect
      ? css`
          background-color: var(--ghostBlue);
          color: black;
          border-radius: 0.8rem 0.8rem 0.8rem 0;
        `
      : css`
          background-color: var(--sherpaBlue);
          color: white;
          border-radius: 0.8rem 0.8rem 0 0.8rem;
        `}

  padding: var(--pad2) var(--pad3);
  line-height: 1.4;
  margin-bottom: var(--pad1);
  white-space: break-spaces;
  word-wrap: anywhere;
  hyphens: auto;
  border: 2px solid var(--sherpaBlue);
  img {
    max-width: 100%;
    max-height: 500px;
  }

  ${(props) =>
    props.theme.isMobile && props.fromProspect
      ? css`
          background-color: white;
        `
      : null}
  ${(props) =>
    props.hasError
      ? css`
          color: black;
          background-color: var(--redHighlight);
          border-color: var(--red);
        `
      : null}
`;

const TimeStamp = styled.time`
  display: flex;
  justify-content: ${(props) => (props.fromProspect ? "flex-start" : "flex-end")};
  color: var(--darkGray);
  white-space: pre;
`;

export default Message;
