import { createSelector as createCachedSelector } from "reselect";
import { createSelectorContext } from "../redux-helpers";
import { anyEmpty, getIn } from "module/common/utils/utils";
import { getLeadStageList } from "../leadstages/selectors";
import { prospectsResults } from "../prospectStore/selectors";
import { getUnreadMessageFilter } from "../uiStore/allUnreadPageView/selectors";
import { path } from "./reducer";
import { filterByCampaign, uniqueProspects } from "./transformers";

const createSelector2 = createSelectorContext(path);

export const getUnreadStatus = createCachedSelector(
  (state: any) => getIn([...path, "unreadStatus"], state),
  (unreadStatus: any) => unreadStatus
);

export const isLoadingMore = createSelector2("isLoadingMore");
export const isLoading = createSelector2("isLoading");

export const campaignHasUnreadMessages = createCachedSelector(
  createSelector2("campaignProspectsUnread"),
  (_: any, id: number) => id,
  (campaignProspectsUnread: any, campaignId: number) => {
    for (const campaignProspect of Object.values(campaignProspectsUnread) as any) {
      if (campaignProspect.campaigns.some((c: any) => c.id === campaignId)) {
        return true;
      }
    }
    return false;
  }
);

export const getCampaignProspects = createCachedSelector(
  (state: any) => createSelector2("campaignProspects")(state),
  (state: any) => getLeadStageList(state),
  (state: any) => prospectsResults(state),
  (_: any, id: any) => id,
  (campaignProspectsMap: any, leadStages: any, prospects: any, id: any) => {
    const campaignProspects = campaignProspectsMap[id] || [];

    if (!anyEmpty(campaignProspects, leadStages, prospects)) {
      const newCampaignProspects = [];
      for (const campaignProspect of campaignProspects) {
        const prospect = prospects[campaignProspect.prospect] || {};

        if (!prospect.id) continue;

        const leadStage = leadStages.find(
          (leadStage: any) => leadStage.id === prospect.leadStage
        );

        newCampaignProspects.push({
          ...campaignProspect,
          prospect: {
            ...prospect,
            leadStageTitle: leadStage ? leadStage.leadStageTitle : "",
          },
        });
      }
      return newCampaignProspects;
    }

    return [];
  }
);

export const getUnreadCampaignsAndCount = createCachedSelector(
  createSelector2("campaignProspectsUnread"),
  (campaignProspectsUnread: any) => {
    const retValue: any = Object.values(campaignProspectsUnread).reduce(
      (acc: any, prospect: any) => {
        prospect.campaigns.forEach((campaign: any) => {
          if (acc[campaign.id]) acc[campaign.id].count += 1;
          else {
            acc[campaign.id] = {
              campaign: campaign,
              count: 1,
            };
          }
        });

        return acc;
      },
      {}
    );
    return Object.values(retValue);
  }
);

export const getCampaignProspectsUnread = createCachedSelector(
  createSelector2("campaignProspectsUnread"),
  getLeadStageList,
  getUnreadMessageFilter,
  (campaignProspectsUnread: any, leadStages: any, filter: any) => {
    const unreadProspects =
      filter.value === 0
        ? uniqueProspects(campaignProspectsUnread)
        : filterByCampaign(filter.value, campaignProspectsUnread);
    const campaignProspects = [];

    if (unreadProspects.length && leadStages.length) {
      for (const unreadProspect of unreadProspects as any) {
        campaignProspects.push({
          ...unreadProspect,
          leadStageTitle: unreadProspect.leadStageTitle || "",
        });
      }
    }
    return campaignProspects;
  }
);

export const getCampaignProspectsUnreadCount = createSelector2(
  "campaignProspectsUnreadCount"
);
