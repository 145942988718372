import React from "react";
import styled from "styled-components";

const CardBody = ({ header, lines, smallPadding = false }) => {
  return (
    <Container smallPadding={smallPadding}>
      <div>{header}</div>
      <ul>
        {lines.map((line, i) => (
          <li key={i}>{line}</li>
        ))}
      </ul>
    </Container>
  );
};

const Container = styled.div`
  ul {
    list-style: none;
    margin: 0 !important;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 13px;

    li {
      margin-top: 14px;

      ${(props) => props.smallPadding && `margin-top: 4px;`}

      &:first-child {
        margin-top: 12px;
      }

      &::before {
        content: "•";
        color: var(--primary);
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }
  }
`;

export default CardBody;
