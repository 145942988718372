import { createSelector as createCachedSelector } from "reselect";
import { createSelectorContext } from "../../redux-helpers";
import { path } from "./reducers";

const createSelector = createSelectorContext(path);
export const prospectActivities = createCachedSelector(
  createSelector("list"),
  (_: any, id: number) => id,
  (list: any, id: number) => {
    return list && list[id];
  }
);

export const getActivities = createCachedSelector(prospectActivities, (list: any) => {
  if (list === undefined) return list;

  const sortedActivitiesList = list
    .slice()
    .sort((a: { date: string }, b: { date: string }) => {
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);

      return bDate.getTime() - aDate.getTime();
    });

  return sortedActivitiesList;
});

export const getActivitiesStatus = createSelector("status");
