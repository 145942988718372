import React from "react";
import { Spinner } from "reactstrap";
import styled, { keyframes } from "styled-components/macro";

export const StyledSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  .spinner-border {
    width: ${(props) => props.size || "1em"};
    height: ${(props) => props.size || "1em"};
    border-width: ${(props) => props.border || "3px"};
  }
`;

export const LoadingSpinner = (props) => {
  const { color = "primary", isLoading = false } = props;
  const spinner = (
    <StyledSpinner
      className={props.className}
      style={props.style}
      size={props.size}
      border={props.border}
      data-test="spinner-wrapper"
    >
      <Spinner data-test="loading-spinner" color={color} />
    </StyledSpinner>
  );

  return isLoading ? spinner : props.renderContent();
};

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const DelaySpin = styled.span`
  border: ${(props) => `${props.borderSize || "3px"} solid var(--sherpaBlue)`};
  border-radius: 50%;
  border-right-color: transparent;
  display: inline-block;
  width: ${(props) => props.size || "1em"};
  height: ${(props) => props.size || "1em"};

  animation: 1s linear infinite ${spin};
  animation-delay: ${(props) => `-${props.delay}ms`};
`;
/**
 *
 * Custom Spinner with a delay so when the content rerenders, the spinner delay doesn't restart
 */
export const SpinnerDelay = (props) => {
  const mountTime = React.useRef(Date.now() % 1000);
  return (
    <DelaySpin
      delay={mountTime.current}
      size={props.size}
      className={props.className}
      borderSize={props.borderSize}
      data-test="spinner-delay"
    ></DelaySpin>
  );
};
