import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { components } from "react-select";
import { Button } from "reactstrap";
import InputSelect from "module/common/components/InputSelect";
import Modal from "../Modal";
import IconBg from "module/common/components/IconBg";
import {
  getLeadStageList,
  getActiveLeadStages,
  getCustomLeadStages,
} from "../../../main/store/leadstages/selectors";
import { prospectUpdateOptimistically } from "../../../main/store/prospectStore/thunks";
import { useDispatch, useSelector } from "react-redux";
import CustomLeadStageForm from "../Forms/CustomLeadStageForm";
import WithPermissions from "module/common/components/WithPermissions";
import { CUSTOM_LEAD_STAGES_EDIT } from "../../../main/permissions/leadStages";

const StyledManageLeadStageOption = styled.div`
  padding: 8px 12px; /** to mimic the styling on the options dropdown */
  color: var(--sherpaBlue);
  cursor: pointer;
  border: 1px solid var(--lightGray);
  &:hover {
    background-color: var(--lightGray);
  }
`;
const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: var(--lightGray) solid 1px;
`;

const StyledAddAction = styled.div`
  display: flex;
  justify-content: center;
  margin-top: var(--pad3);
  margin-bottom: var(--pad2);
`;

const StyledButton = styled(Button)`
  border-width: 2px;
`;

const StyledLeadData = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: var(--pad2);
  border-bottom-color: var(--lightGray);
  padding-bottom: var(--pad1);
`;
const StyledInactiveText = styled.span`
  color: var(--darkGray);
  padding-left: var(--pad2);
`;
const StyledIcons = styled.div`
  svg {
    cursor: pointer;
  }
`;

const MenuList = (props) => {
  const { hasManageLeadStagesFunc, onManageLeadStage } = props.selectProps;

  return (
    <>
      <components.MenuList {...props}>{props.children}</components.MenuList>
      {hasManageLeadStagesFunc ? (
        <WithPermissions permission={CUSTOM_LEAD_STAGES_EDIT} checkRole>
          <StyledManageLeadStageOption onClick={onManageLeadStage}>
            Add/Edit Custom Lead Stages{" "}
            <FontAwesomeIcon
              className="ml-1"
              icon="pencil-alt"
              color="var(--sherpaBlue)"
            />
          </StyledManageLeadStageOption>
        </WithPermissions>
      ) : null}
    </>
  );
};

function LeadStagesSelector(props) {
  const { prospect, hasManageLeadStagesFunc } = props;

  // local state
  const [activeLead, setActiveLead] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add/Edit Custom Lead Stages");
  const [modalType, setModalType] = useState("add");
  const [currentLeadStage, setCurrentLeadStage] = useState({});

  // selectors
  const leadStages = useSelector(getLeadStageList);
  const activeLeadStages = useSelector(getActiveLeadStages);
  const customLeadStages = useSelector(getCustomLeadStages);

  const dispatch = useDispatch();

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    if (leadStages.length && prospect["leadStage"]) {
      const initialLeadStage = leadStages.find(
        (stage) => stage.id === prospect["leadStage"]
      );
      if (initialLeadStage === undefined) {
        setActiveLead(null);
      } else {
        setActiveLead({
          label: initialLeadStage.leadStageTitle,
          value: prospect["leadStage"],
        });
      }
    }
    return () => {
      setActiveLead(null);
    };
  }, [prospect, leadStages]);

  useEffect(() => {
    // set sm template
    if (activeLead === null && prospect["leadStage"] !== null) {
      const initialLeadStage = leadStages.find(
        (stage) => stage.id === prospect["leadStage"]
      );
      if (initialLeadStage) {
        const leadStage = {
          label: initialLeadStage.leadStageTitle,
          value: prospect["leadStage"],
        };
        setActiveLead(leadStage);
      }
    }
    return () => {
      setActiveLead(null);
    };

    // eslint-disable-next-line
  }, [prospect.id]);

  // lead options
  // create lead options
  const leadOptions = activeLeadStages.map((item) => ({
    label: item.leadStageTitle,
    value: item.id,
  }));

  const resetModalValues = ({ modalType, modalTitle }) => {
    // clean up local state
    setModalType(modalType);
    setModalTitle(modalTitle);
    setCurrentLeadStage({});
  };

  const onManageLeadStage = () => {
    toggleModal();
    resetModalValues({ modalType: "view", modalTitle: "Add/Edit Custom Lead Stages" });
  };
  const handleOnSuccess = () => {
    toggleModal();
  };

  const handleOnSuccessDelete = () => {
    // only remove from view if it's the lead stage that's being deleted
    if (activeLead && currentLeadStage.id === activeLead.value) {
      setActiveLead(null);
    }
    toggleModal();
  };

  const addNewLeadStage = () => {
    setModalType("add");
    setModalTitle("Add New Lead Stage");
  };

  const onEditLeadStage = (value) => {
    resetModalValues({
      modalType: "edit",
      modalTitle: `Edit ${value.leadStageTitle} Lead Stage`,
    });

    setCurrentLeadStage(value);
  };

  const onDeleteLeadStage = (value) => {
    resetModalValues({
      modalType: "delete",
      modalTitle: `Delete ${value.leadStageTitle} Lead Stage`,
    });

    setCurrentLeadStage(value);
  };

  const handleOnBack = () => {
    resetModalValues({
      modalType: "view",
      modalTitle: "Add/Edit Custom Lead Stages",
    });
  };

  const renderManageLeadStageModal = () => {
    return (
      <Modal
        dataTest="lead-stage-selector-custom-lead-stage-form"
        title={modalTitle}
        isOpen={isModalOpen}
        toggle={toggleModal}
        size="md"
      >
        {modalType === "view" ? (
          <>{renderCustomLeadStagesList()}</>
        ) : (
          <CustomLeadStageForm
            onSuccess={handleOnSuccess}
            type={modalType}
            leadStageData={currentLeadStage}
            onDeleteSuccess={handleOnSuccessDelete}
            onBack={handleOnBack}
            buttonProps={{ block: false, name: "Submit", className: "pr-4 pl-4" }}
          />
        )}
      </Modal>
    );
  };

  const renderCustomLeadStagesList = () => {
    return (
      <div>
        {customLeadStages.map((value, idx) => {
          return (
            <StyledItem key={idx}>
              <StyledLeadData>
                {value.leadStageTitle}
                {!value.isActive ? (
                  <StyledInactiveText className="textS">inactive</StyledInactiveText>
                ) : null}
              </StyledLeadData>

              <StyledIcons>
                <FontAwesomeIcon
                  className="mr-2"
                  icon="pencil-alt"
                  color="var(--sherpaBlue)"
                  onClick={() => onEditLeadStage(value)}
                />
                <FontAwesomeIcon
                  icon="times-circle"
                  color="red"
                  onClick={() => onDeleteLeadStage(value)}
                />
              </StyledIcons>
            </StyledItem>
          );
        })}
        <StyledAddAction>
          <StyledButton
            outline
            onClick={addNewLeadStage}
            color="primary"
            data-test="add-new-leadstage"
          >
            <FontAwesomeIcon icon="plus" className="mr-2" />
            Add New Lead Stage
          </StyledButton>
        </StyledAddAction>
      </div>
    );
  };

  // on change lead
  const onLeadStageChange = (data) => {
    // ignore default optoin
    if (data.value) {
      setActiveLead(data);
      dispatch(
        prospectUpdateOptimistically(prospect.id, { leadStage: parseInt(data.value) })
      );
    }
  };

  return (
    <>
      <InputSelect
        id="statusSelect"
        className="small-input"
        value={activeLead}
        placeholder={"Select Lead Stage"}
        options={leadOptions}
        icon={<IconBg icon="project-diagram" faSize="xs" />}
        onChange={onLeadStageChange}
        styles={{ ...props.styles }}
        onSelectResetsInput={false}
        removeMenuPortalTarget={props.removeMenuPortalTarget}
        components={{ MenuList }}
        hasManageLeadStagesFunc={hasManageLeadStagesFunc}
        onManageLeadStage={onManageLeadStage}
      />
      {renderManageLeadStageModal()}
    </>
  );
}

export default LeadStagesSelector;
