import axios from "axios";

const axiosPublicInstance = axios.create({ baseURL: window.location.origin });

export const getPublicRequest = (url: string) => {
  return axiosPublicInstance.get(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache, no-store, max-age=0",
      Pragma: "no-cache",
    },
  });
};
