import { createStore, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { combineReducers } from "redux";
import { loadTokens } from "./Auth/loadTokens";

import auth from "./Auth/reducers";
import campaigns from "./Campaigns/reducers";
import campaignNotes from "./CampaignNotes/reducers";
import campaignsBatchProspectsStore from "./CampaignsBatchProspectsStore/reducers";
import categories from "./Categories/reducers";
import markets from "./Markets/reducers";
import prospectNotes from "./ProspectDetails/notes/reducers";
import prospectMessages from "./ProspectDetails/messages/reducers";
import prospectActivities from "./ProspectDetails/activities/reducers";
import supportItems from "./Support/reducers";
import leadStages from "./leadstages/reducer";
import quickReplies from "./QuickRepliesStore/reducers";
import smsTemplates from "./SmsTemplateStore/reducers";
import uiStore from "./uiStore/reducers";
import prospectStore from "./prospectStore/reducer";
import campaignProspectStore from "./campaignProspectStore/reducer";
import numberManagerStore from "./NumberManagerStore/reducer";
import skipTrace from "module/main/store/SkipTraces/reducers";
import zapierWebHooks from "./ZapierWebHooks/reducers";
import prospectRelayStore from "./ProspectRelayStore/reducers";
import UploadProspects from "./UploadProspects/reducers";
import calls from "./Calls/reducers";
import ParentMarkets from "./ParentMarkets/reducer";
import Company from "./Company/reducer";
import Global from "./Global/reducer";
import Tags from "./Tags/reducers";
import propertyStore from "./propertyStore/reducer";
import sequences from "./SequencesStore/reducers";
import subscriptionStore from "./SubscriptionStore/reducers";

import { selectKeys } from "module/common/utils/utils";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const prospectDetailsReducer = combineReducers({
  prospectNotes,
  prospectMessages,
  prospectActivities,
});

const reducers = combineReducers({
  auth,
  campaigns,
  campaignNotes,
  campaignProspectStore,
  campaignsBatchProspectsStore,
  categories,
  Company,
  leadStages,
  quickReplies,
  markets,
  numberManagerStore,
  prospectStore,
  prospectDetailsReducer,
  prospectRelayStore,
  smsTemplates,
  supportItems,
  uiStore,
  skipTrace,
  zapierWebHooks,
  UploadProspects,
  calls,
  ParentMarkets,
  Tags,
  Global,
  propertyStore,
  sequences,
  subscriptionStore,
});

const rootReducer = (state: any, action: any) => {
  // logout case where we want to reset all state from redux
  if (action.type === "RESET") state = undefined;

  return reducers(state, action);
};

//////////////////////////////////////////////////////////////////////////////////////////////
// NOTE(ALL): We can't show all reducers on the devtools otherwise it crashes.  For now the //
// defaults are below. If you wish to see another reducer then add the			    //
// reducer key on =viewableReducers=.  If the devtools crash then just			    //
// remove some reducers from =viewableReducers= and try again.				    //
// 											    //
// NOTE(ALL): Do not commit any changes you make to =viewableReducers=			    //
//////////////////////////////////////////////////////////////////////////////////////////////
const viewableReducers = [
  "campaigns",
  "Company",
  "uiStore",
  "Tags",
  "sequences",
  "ProspectDetails",
  "prospectStore",
];

const stateSanitizer = (state: any) => {
  return selectKeys(viewableReducers, state);
};

// for local stuff
const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      stateSanitizer,
      trace: true,
      traceLimit: 10,
    })) ||
  compose;

// load local-storage tokens
const authInfo = loadTokens();
const initialState = { ...authInfo };

const Store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

export type AppDispatch = typeof Store.dispatch;

export default Store;
