import React, { useEffect } from "react";
import InputSelect from "../../InputSelect";
import { useFormContext } from "react-hook-form";

// Ignore `innerRef` that is passed
// eslint-disable-next-line
const SelectField = ({ innerRef, field, ...props }) => {
  const { register, watch, setValue, unregister } = useFormContext();
  const selection = watch(field.name);

  useEffect(() => {
    register({ name: field.name }, field.rules);
    return () => {
      setValue(field.name, field.inputProps.isMulti ? [] : null);
      unregister(field.name);
    };
    // eslint-disable-next-line
  }, [register, setValue, unregister]);

  return (
    <InputSelect
      {...props}
      value={selection || null}
      onChange={(selection_) => {
        setValue(field.name, selection_);

        // call onChange in case user needs to hook into this
        const { inputProps: { onChange: onChangeHook = () => null } = {} } = field;

        onChangeHook(selection_);
      }}
    />
  );
};
export default SelectField;
