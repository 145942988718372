import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StyledIcon = styled(FontAwesomeIcon)`
  ${(props) =>
    props.spacing === "small" &&
    `
      margin-right: 6px;
    `}

  ${(props) =>
    props.spacing === "medium" &&
    `
      margin-right: 8px;
      width: 14px !important;
      height: 16px !important;
    `}

  ${(props) =>
    props.spacing === "large" &&
    `
      margin-right: 10px;
    `}
`;

const IconButton = ({ component, size, icon, text, ...rest }) => {
  const StyledBtn = styled(component)`
    ${(props) =>
      props.size === "small" &&
      `
        padding-left: 14px !important;
        padding-right: 16px !important;
      `}

    ${(props) =>
      props.size === "medium" &&
      `
        padding-top: 7px !important;
        padding-left: 16px !important;
        padding-right: 20px !important;
      `}

    ${(props) =>
      props.size === "large" &&
      `
        padding-left: 20px !important;
        padding-right: 24px !important;
      `}
  `;

  return (
    <StyledBtn size={size} {...rest}>
      <StyledIcon spacing={size} icon={icon} size="sm" />
      {text}
    </StyledBtn>
  );
};

IconButton.propTypes = {
  component: PropTypes.func,
  size: PropTypes.oneOfType([PropTypes.oneOf(["small", "medium"])]),
  icon: PropTypes.string,
  text: PropTypes.string,
};

export default IconButton;
