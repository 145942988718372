import AxiosInstance from "module/common/utils/axiosConfig";

export const createCompany = (data: any) => {
  return AxiosInstance.post("/companies/", data);
};

export const getCompanyProfileStats = (id: number, params: any) => {
  return AxiosInstance.get(`/companies/${id}/profile_stats/`, { params });
};

export const createCompanySubscription = (id: number, data: any) => {
  return AxiosInstance.post(`/companies/${id}/subscription/`, data);
};

export const getCompanyPaymentMethods = (id: number) => {
  return AxiosInstance.get(`/companies/${id}/payment_methods/`);
};

export const companyRetrySubcription = (id: number) => {
  return AxiosInstance.post(`/companies/${id}/retry_subscription/`);
};
