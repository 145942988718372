import {
  SET_SKIP_TRACE_PAGE_ACTIVE_TAB,
  SET_SKIP_TRACE_PAGE_ACTIVE_SORT,
  SET_SKIP_TRACE_TAB_DATA,
  RESET_SKIP_TRACE_LIST_FILTER_TABS,
  SET_ACTIVE_SKIP_TRACE,
  SET_PUSH_TO_CAMPAIGN_BTNS_DISABLED,
} from "./actionTypes";

const initialState = {
  activeTab: "all",
  activeSkipTrace: 0,
  tabs: {
    all: {
      count: 0,
      sortOrder: [],
      sortedBy: null,
      nextPage: null,
    },
    noskiptraces: {
      count: 0,
      sortOrder: [],
      sortedBy: null,
      nextPage: null,
    },
    ownedbyme: {
      count: 0,
      sortOrder: [],
      sortedBy: null,
      nextPage: null,
    },
  },
  pushToCampaignBtnsDisabled: false,
};

export const path = ["uiStore", "skipTraceView", "skipTraceList", "filterData"];
// NOTE: Add immer
export default function reducer(state: any = initialState, action: any) {
  switch (action.type) {
    case RESET_SKIP_TRACE_LIST_FILTER_TABS:
      return {
        ...state,
        tabs: initialState.tabs,
      };
    case SET_SKIP_TRACE_PAGE_ACTIVE_SORT:
      return {
        ...state,
        activeSort: action.payload,
      };
    case SET_SKIP_TRACE_PAGE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case SET_SKIP_TRACE_TAB_DATA: {
      let newSortOrder = [...action.payload.data.sortOrder];

      // we're just adding on to the set order as sorting is the same
      if (state.tabs[action.payload.tab].sortedBy === state.activeSort) {
        newSortOrder = [
          ...state.tabs[action.payload.tab].sortOrder,
          ...action.payload.data.sortOrder,
        ];
      } else {
        newSortOrder = action.payload.data.sortOrder;
      }
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [action.payload.tab]: {
            sortOrder: newSortOrder,
            nextPage: action.payload.data.nextPage,
            sortedBy: action.payload.data.sortedBy,
            count: action.payload.data.count,
          },
        },
      };
    }
    case SET_ACTIVE_SKIP_TRACE: {
      return {
        ...state,
        activeSkipTrace: action.payload,
      };
    }
    case SET_PUSH_TO_CAMPAIGN_BTNS_DISABLED: {
      return {
        ...state,
        pushToCampaignBtnsDisabled: action.payload,
      };
    }
    default:
      return state;
  }
}
