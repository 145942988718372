import React, { useEffect, useState } from "react";
import { formatDate } from "../../helpers/utils";

// styles
import {
  StyledMultipleDates,
  StyledCalendarAndDatesContainer,
  StyledDay,
  StyledDatePickContainer,
} from "./styles";

const DatetimePicker = (props) => {
  const {
    selectedDates: _selectedDates,
    multipleSelect,
    className,
    onChange: _onChange,
    children,
    dateFormat,
    ...restProps
  } = props;
  const [selectedDates, setSelectedDates] = useState([]);

  // based on the dates returned, we want to make sure the calendar has the dates selected
  // only want to do this once on mount. The selected dates, and selection should be done
  // in local state
  useEffect(() => {
    setSelectedDates(_selectedDates);
  }, [_selectedDates]);

  const renderDay = (props, currentDate) => {
    const selected = selectedDates.includes(formatDate(currentDate));
    return (
      <StyledDay id={formatDate(currentDate)} selected={selected} {...props}>
        {currentDate.date()}
      </StyledDay>
    );
  };

  return (
    <StyledCalendarAndDatesContainer>
      <StyledDatePickContainer multipleSelect={multipleSelect}>
        <StyledMultipleDates
          className={className}
          multipleSelect={multipleSelect}
          input={false}
          timeFormat={false}
          dateFormat={dateFormat}
          renderDay={renderDay}
          onChange={(date) => _onChange(date)}
          value={""} // force to be empty so the selected active state doesn't remain when switching options
          utc={false}
          {...restProps}
        />
        {children}
      </StyledDatePickContainer>
    </StyledCalendarAndDatesContainer>
  );
};

export default DatetimePicker;
