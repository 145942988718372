import * as api from "./api";
import { addExportUUID } from "./actions";
import { addNewToast } from "../Toasts/actions";

export const exportDNC = (remove: boolean) => async (dispatch: any) => {
  _export(api.dncExport(remove), dispatch, "DNC");
};

export const exportUnreadProspects =
  (id: number, params: any) => async (dispatch: any) => {
    _export(api.exportPriorityUnreadProspects(id, params), dispatch, "prospect");
  };

export const exportProspectSearch = (params: any) => async (dispatch: any) => {
  _export(api.exportProspectSearch(params), dispatch, "prospect");
};

export const exportCampaign = (_: string, id: number) => async (dispatch: any) => {
  _export(api.campaignExportAll(id), dispatch, "campaign prospect");
};

export const exportCampaignByPhoneType =
  (id: number, phoneType: string) => async (dispatch: any) => {
    _export(api.campaignExportByPhoneType(id, phoneType), dispatch, "campaign prospect");
  };

export const exportSkipTrace =
  (id: number, exportMode: api.ExportMode) => (dispatch: any) => {
    _export(api.skiptraceExport(id, exportMode), dispatch, "skip trace");
  };

export const exportCampaignList = (params: any) => async (dispatch: any) => {
  _export(api.exportCampaignList(params), dispatch, "campaign");
};

export const exportCompanyCampaignMetaStats = (params: any) => async (dispatch: any) => {
  _export(
    api.exportCompanyCampaignMetaStats(params),
    dispatch,
    "company-campaign-meta-stats"
  );
};

export const exportCompanyProfileStats = (params: any) => async (dispatch: any) => {
  _export(api.exportCompanyProfileStats(params), dispatch, "company-profile-stats");
};

export const exportDirectMailRecipients = (id: any) => async (dispatch: any) => {
  _export(api.exportDirectMailRecipients(id), dispatch, "dm-recipients");
};

export const exportReminders =
  (stepType: string, filter: string) => async (dispatch: any) => {
    _export(api.exportReminders(stepType, filter), dispatch, `${stepType}-reminders`);
  };

export const _export = async (response: any, dispatch: any, type: string) => {
  try {
    const data = await response;
    const uuid = data.id ? data.id : data.data.id;
    dispatch(addExportUUID(uuid));
    response
      .then((r: any) => {
        if (r.message) {
          dispatch(addNewToast({ message: r.message, color: "success" }));
        }
      })
      .catch(() => {
        addNewToast({ message: "Error removing DNC from prospects", color: "danger" });
      });
    dispatch(
      addNewToast({
        id: uuid,
        message: `Your ${type} export is generating... 0%`,
        color: "success",
        persist: true,
      })
    );
  } catch {
    dispatch(
      addNewToast({
        message: "An error occurred while generating your export!",
        color: "danger",
      })
    );
  }
};
