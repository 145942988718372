import { ApiFetchStatus } from "module/common/helpers/variables";
import {
  setCampaignsBatchProspectsError,
  updateCampaignsBatchProspectsStatus,
  setFetchedCampaignsBatchProspects,
} from "./actions";
import { addErrorToast, emptyToastArray } from "../Global/Toasts/actions";
import { formatErrorMessage } from "module/common/utils/utils";
import { Dispatch } from "redux";
import { postCampaignBatchSend, getCampaignBatchProspects } from "./api";

const handleError = ({
  message,
  error,
  dispatch,
}: {
  message: string;
  error: any;
  dispatch: Dispatch;
}) => {
  const errorMessage = formatErrorMessage(error, message);

  dispatch(emptyToastArray());
  dispatch(addErrorToast(errorMessage));
  dispatch(setCampaignsBatchProspectsError(errorMessage));
};

export const fetchCampaignsBatchProspects =
  (id: number, smsCategoryId: number) => async (dispatch: any) => {
    dispatch(updateCampaignsBatchProspectsStatus(ApiFetchStatus.Fetching));

    getCampaignBatchProspects(id, smsCategoryId)
      .then(({ data }) => {
        dispatch(setFetchedCampaignsBatchProspects({ data }));
      })
      .catch(({ response }) => {
        if (response?.data) {
          handleError({
            message: response.data.detail || "Error fetching campaign batch prospects",
            error: true,
            dispatch,
          });
        }
      });
  };

interface PostCampaignBatchSendProps {
  action: string;
  templateId?: number;
  scribeSherpaMessage?: string;
}

export const callPostCampaignBatchSend =
  (prospectId: number, { action, ...rest }: PostCampaignBatchSendProps) =>
  async (dispatch: any) => {
    const payload = action !== "send" ? { action, ...rest } : { ...rest };

    return postCampaignBatchSend(prospectId, payload).catch((error: any) => {
      handleError({
        message: error?.response?.data?.detail || "Error sending SMS message",
        error,
        dispatch,
      });
      return Promise.reject(error);
    });
  };
