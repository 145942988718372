import React from "react";
import { Input } from "reactstrap";

const NumberAndInputField = (props) => {
  const textSelect = (e) => {
    e.stopPropagation();
    e.target.select();
  };
  return <Input {...props} onFocus={textSelect} />;
};

export default NumberAndInputField;
