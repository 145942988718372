import { createAction } from "../../../redux-helpers";

// updates properties, prospects, status to Success

export const SET_SMART_STACKER_LIST_RESULTS = "SET_SMART_STACKER_LIST_RESULTS";
export const setSmartStackerListResults = createAction(SET_SMART_STACKER_LIST_RESULTS);

// updates propertiesById, prospectsById, status to Success

export const SET_SMART_STACKER_LIST_SEARCH_RESULTS =
  "SET_SMART_STACKER_LIST_SEARCH_RESULTS";
export const setSmartStackerListSearchResults = createAction(
  SET_SMART_STACKER_LIST_SEARCH_RESULTS
);

// updates status

export const SET_SMART_STACKER_STATUS = "SET_SMART_STACKER_STATUS";
export const setSmartStackerStatus = createAction(SET_SMART_STACKER_STATUS);

// sets the current active tab
export const SET_SMART_STACKER_ACTIVE_TAB = "SET_SMART_STACKER_ACTIVE_TAB";
export const setSmartStackerActiveTab = createAction(SET_SMART_STACKER_ACTIVE_TAB);

// sets the current active prospect selected
export const SET_SMART_STACKER_ACTIVE_PROSPECT = "SET_SMART_STACKER_ACTIVE_PROSPECT";
export const setSmartStackerActiveProspect = createAction(
  SET_SMART_STACKER_ACTIVE_PROSPECT
);
