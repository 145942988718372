import { IProspectStore, IProspect } from "./interfaces";
import {
  FETCH_PROSPECTS,
  FETCH_PROSPECTS_FAILURE,
  FETCH_PROSPECTS_SUCCESS,
  UPDATE_PROSPECT_LIST,
  UPDATE_PROSPECT_SUCCESS,
  DELETE_PROSPECT_SUCCESS,
  RESET_PROSPECTS,
  FETCH_PROSPECT,
  FETCH_PROSPECT_SUCCESS,
  FETCH_PROSPECT_NEXT_PAGE,
  UPDATE_PROSPECT_DRAFT,
  SET_FETCH_MESSAGES,
} from "./actionTypes";

import { mergeProspects } from "../utils";

const initialState: IProspectStore = {
  isLoading: false,
  isLoadingMore: false,
  isFetchingMessages: false,
  error: false,
  prospects: {} as { [key: number]: IProspect },
  prospectDrafts: {} as { [key: number]: string },
  next: null,
  previous: null,
  count: 0,
};

export const path = ["prospectStore"];

export default function reducer(state: IProspectStore = initialState, action: any) {
  switch (action.type) {
    case FETCH_PROSPECT:
    case FETCH_PROSPECT_SUCCESS:
    case FETCH_PROSPECTS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case FETCH_PROSPECTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_PROSPECTS_SUCCESS:
      return {
        ...state,
        prospects: { ...state.prospects, ...action.payload.results },
        next: action.payload.next,
        previous: action.payload.previous,
        count: action.payload.count,
        isLoading: false,
      };
    case UPDATE_PROSPECT_LIST: {
      const newState: any = { ...state };
      const {
        results,
        next,
        previous,
        overridePages,
        shouldOverwrite = false,
      } = action.payload;
      newState.prospects = mergeProspects(newState.prospects, results, shouldOverwrite);

      if (overridePages) {
        newState.next = next;
        newState.previous = previous;
      }

      return newState;
    }

    case UPDATE_PROSPECT_SUCCESS: {
      const id = action.payload.id;
      const newState = { ...state, prospects: { ...state.prospects } };
      const prospect = newState.prospects[id];

      newState.prospects[id] = {
        ...prospect,
        ...action.payload,
      };

      return newState;
    }
    case DELETE_PROSPECT_SUCCESS: {
      const newState = { ...state };
      delete newState.prospects[action.paydload.id];

      return newState;
    }

    case FETCH_PROSPECT_NEXT_PAGE: {
      return {
        ...state,
        isLoadingMore: action.payload,
      };
    }
    case RESET_PROSPECTS:
      return {
        ...state,
        prospects: {},
      };
    case UPDATE_PROSPECT_DRAFT: {
      const { id, draft } = action.payload;
      const newState = { ...state };
      const newDrafts = newState.prospectDrafts;
      newDrafts[id] = draft;

      return {
        ...newState,
      };
    }
    case SET_FETCH_MESSAGES:
      return {
        ...state,
        isFetchingMessages: action.payload,
      };
    default:
      return state;
  }
}
