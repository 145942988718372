import React, { useState, useEffect } from "react";
import InputSelect from "../../../../../../common/components/InputSelect";
import IconBg from "../../../../../../common/components/IconBg";
import { useSelector } from "react-redux";
import { useAppDispatch } from "module/main/store/hooks";
import {
  callPostDisconnectProspectRelay,
  callPostConnectProspectRelay,
} from "../../../../../../main/store/ProspectRelayStore/thunks";
import { updateProspectSuccess } from "../../../../../../main/store/prospectStore/actions";
import { addSuccessToast } from "../../../../../../main/store/Global/Toasts/actions";
import { formatPhoneNumber } from "module/common/helpers/utils";
import { IProspect, IRelay } from "module/main/store/prospectStore/interfaces";
import { IProfile } from "module/main/store/Company/interfaces";
import { selectCompanyProfiles } from "../../../../../../main/store/Company/profiles/selectors";
import InputGroupBorder from "../../../../../../common/components/InputGroupBorder";
import { Input, UncontrolledTooltip } from "reactstrap";

interface Props {
  prospect: IProspect;
  removeMenuPortalTarget: boolean;
}

interface IActiveRelay {
  label: string;
  value: number;
}

const ProspectRelaySelector: React.FC<Props> = ({ prospect, removeMenuPortalTarget }) => {
  // local state
  const [activeRelay, setActiveRelay] = useState<IActiveRelay | null>(null);
  // selectors
  const profiles: IProfile[] = useSelector(selectCompanyProfiles);
  const { relay } = prospect || {};

  const dispatch = useAppDispatch();

  // create relay options
  const relayOptions = profiles
    .filter((profile) => profile.phone && profile.user.isActive)
    .map((profile) => ({
      label: `${profile.user.fullName} ${formatPhoneNumber(profile.phone)}`,
      value: profile.id,
    }));

  const clearRelay = async (relay: IRelay) => {
    dispatch(callPostDisconnectProspectRelay(relay, profiles)).then(() => {
      dispatch(updateProspectSuccess({ id: prospect.id, relay: null }));
      dispatch(addSuccessToast("Prospect relay disconnected."));
      setActiveRelay(null);
    });
  };

  // on change relay
  const onRelayChange = (data: any, triggered: any) => {
    const newAgentId = data && data.value;
    // do nothing when clicking the already selected option
    if (activeRelay && activeRelay.value === newAgentId) return;
    // on clicking clear box to remove prospect
    if (triggered.action === "clear") {
      return clearRelay(relay!);
    }
    setActiveRelay(data);

    const payload = { prospect: prospect.id, agentProfile: newAgentId };
    dispatch(callPostConnectProspectRelay(payload, profiles, relay)).catch(() =>
      setActiveRelay(activeRelay)
    );
  };

  useEffect(() => {
    if (relay) {
      const activeRelay = relayOptions.find((opt) => opt.value === relay.agentProfile.id);
      setActiveRelay(activeRelay || null);
    } else {
      setActiveRelay(null);
    }
    // eslint-disable-next-line
  }, [prospect]);

  if (prospect.relayBlocked) {
    return (
      <>
        <span id="relay-unavailable-tooltip" data-test="relay-unavailable-tooltip">
          <InputGroupBorder>
            <Input placeholder="Select a relay" readOnly disabled />
          </InputGroupBorder>
        </span>
        <UncontrolledTooltip placement="auto" target="relay-unavailable-tooltip">
          Prospect Relay is not available for conversations at this time. Both the
          call-forwarding feature and the "Call Prospect" button may be used.
        </UncontrolledTooltip>
      </>
    );
  }
  return (
    <InputSelect
      id="relaySelect"
      data-test="prospect-relay-selector"
      classNamePrefix="prospect-relay-selector"
      value={activeRelay}
      placeholder="Select a relay"
      options={relayOptions}
      icon={<IconBg icon="project-diagram" faSize="xs" />}
      onChange={onRelayChange}
      isClearable
      removeMenuPortalTarget={removeMenuPortalTarget}
    />
  );
};

export default ProspectRelaySelector;
