import { produce } from "immer";
import { IState, IReminder } from "./interfaces";
import {
  FETCH_REMINDERS,
  FETCH_CALL_REMINDERS_SUCCESS,
  FETCH_SMS_REMINDERS_SUCCESS,
  FETCH_REMINDERS_FAILURE,
  UPDATE_REMINDER_SUCCESS,
  SET_SELECTED_REMINDER,
  UPDATE_REMINDERS_PROSPECT,
} from "./actions";

import { Fetching, Success, FetchError, Initial } from "module/common/helpers/variables";

export const path = ["sequences", "reminders"];

const initialState: IState = {
  smsRemindersList: [],
  callRemindersList: [],
  selectedReminder: null,
  status: Initial,
};

const reducer = produce((draft = initialState, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_REMINDERS: {
      draft.status = Fetching;
      break;
    }
    case FETCH_SMS_REMINDERS_SUCCESS: {
      draft.status = Success;
      draft.smsRemindersList = payload;
      break;
    }
    case FETCH_CALL_REMINDERS_SUCCESS: {
      draft.status = Success;
      draft.callRemindersList = payload;
      break;
    }
    case FETCH_REMINDERS_FAILURE: {
      draft.status = FetchError;
      break;
    }
    case UPDATE_REMINDER_SUCCESS: {
      const filterStep = (reminder: IReminder) =>
        reminder.id !== payload.prospectSequenceStepId;
      if (payload.stepType === "sms")
        draft.smsRemindersList = draft.smsRemindersList.filter(filterStep);
      else draft.callRemindersList = draft.callRemindersList.filter(filterStep);
      break;
    }
    case UPDATE_REMINDERS_PROSPECT: {
      const stepType = draft.selectedReminder?.step.stepType;

      const updateHelper = (remindersList: IReminder[]) => {
        for (const reminder of remindersList) {
          if (reminder.prospect.id === payload.id) {
            reminder.prospect = { ...reminder.prospect, ...payload.data };
            break;
          }
        }
      };

      stepType === "sms"
        ? updateHelper(draft.smsRemindersList)
        : updateHelper(draft.callRemindersList);
      break;
    }
    case SET_SELECTED_REMINDER: {
      draft.selectedReminder = payload;
    }
  }
}, initialState);

export default reducer;
