import { createAction } from "../../redux-helpers";

export const UNREAD_TAB_UPDATE_SELECTED_ITEM = "UNREAD_TAB_UPDATE_SELECTED_ITEM";
export const UNREAD_TAB_UPDATE_FILTER = "UNREAD_TAB_UPDATE_FILTER";
export const UNREAD_TAB_RESET_STATE = "UNREAD_TAB_RESET_STATE";
export const UNREAD_TAB_POLL_MESSAGE_COUNT = "UNREAD_TAB_POLL_MESSAGE_COUNT";
export const UNREAD_TAB_POLL_MESSAGES = "UNREAD_TAB_POLL_MESSAGES";

export const unreadTabUpdateSelectedItem = createAction(UNREAD_TAB_UPDATE_SELECTED_ITEM);
export const unreadTabUpdateFilter = createAction(UNREAD_TAB_UPDATE_FILTER);
export const unreadTabResetState = createAction(UNREAD_TAB_RESET_STATE);
export const unreadTabActivateCountPolling = createAction(UNREAD_TAB_POLL_MESSAGE_COUNT);
export const unreadTabActivateMessagesPolling = createAction(UNREAD_TAB_POLL_MESSAGES);
