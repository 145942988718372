export const SMS_TEMPLATES_ARCHIVE_ACTION = "sms-templates/action:archive";
export const SMS_TEMPLATES_CREATE_ACTION = "sms-templates/action:create";
export const SMS_TEMPLATES_VIEW_PAGE = "sms-templates/view:page";
export const SMS_TEMPLATES_EDIT_SETTINGS = "sms-templates/action:edit-settings";

export const permissions = {
  master_admin: [],
  admin: [SMS_TEMPLATES_ARCHIVE_ACTION],
  staff: [
    SMS_TEMPLATES_VIEW_PAGE,
    SMS_TEMPLATES_CREATE_ACTION,
    SMS_TEMPLATES_EDIT_SETTINGS,
  ],
  junior_staff: [],
};
