import { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProspectListContext } from "./utils";

export const useProspectHeader = () => {
  const { unreadSelector, filterSelector, updateFilter, countUnread, getOptions } =
    useContext(ProspectListContext);
  const unreadFields = useSelector(unreadSelector);
  const selectedFilter = useSelector(filterSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    // update the filter `count` if marked as read
    if (selectedFilter.value !== 0 || typeof selectedFilter.value !== "string") {
      const { count = 0 } = countUnread(unreadFields, selectedFilter);
      const newLabel = selectedFilter.label.replace(/\s\(\d+\)/, ` (${count})`);

      dispatch(
        updateFilter({
          ...selectedFilter,
          label: newLabel,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, unreadFields]);

  const onChange = useCallback(
    (selectedItem) => {
      dispatch(updateFilter(selectedItem));
    },
    [dispatch, updateFilter]
  );

  const options = getOptions(unreadFields);

  return { options, onChange, selectedFilter };
};
