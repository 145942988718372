import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";
import { isMobile } from "../../../../../detectDevice";

// components
import Fields from "./FieldsSection";
import ProspectDetailsCard from "./ProspectDetailsCard";

// redux related
import { actionBtnStatusSelector } from "../../../../main/store/uiStore/prospectDetailsView/selectors";
import {
  markMessagesAsReadLocally,
  removeUnreadMessages,
} from "../../../../main/store/ProspectDetails/messages/thunk";
import { markAllMessagesAsRead } from "module/main/services/Prospect";
import { removeCampaignProspect } from "../../../../main/store/campaignProspectStore/thunks";
import { updateProspectSuccess } from "../../../../main/store/prospectStore/actions";
import { getFetchingMessages } from "../../../../main/store/prospectStore/selectors";

import StatusActionBtns from "../../StatusActionBtns";

const StyledDivider = styled.div`
  &:not(:last-child) {
    border-bottom: 2px solid var(--mediumGray);
  }
  padding-top: var(--pad1);
  padding-bottom: var(--pad3);
  margin-right: -2rem;
  margin-left: -2rem;
  padding-right: var(--pad4);
  padding-left: var(--pad4);

  ${(props) =>
    props.theme.isDesktop
      ? css`
          padding-top: var(--pad1);
          padding-bottom: var(--pad1);
        `
      : null}
`;

const StyledStatusWrapper = styled.div`
  margin-top: var(--pad4);
`;

const DetailsTab = ({ prospect, displayProspectStatusActions = false }) => {
  // selectors
  const actionBtnStatus = useSelector(actionBtnStatusSelector);
  const isFetchingMessages = useSelector(getFetchingMessages);
  const dispatch = useDispatch();

  const displayProspectStatus =
    displayProspectStatusActions || window.location.pathname.includes("/details");
  const {
    isQualifiedLead = false,
    isPriority = false,
    wrongNumber,
    ownerVerifiedStatus,
    doNotCall,
    id,
    hasUnreadSms,
    optedOut,
  } = prospect;

  const dncActions = doNotCall
    ? []
    : [
        () => dispatch(removeUnreadMessages(id, prospect)),
        () => dispatch(removeCampaignProspect(id, hasUnreadSms)),
      ];

  const markAsReadStatus = {
    text: prospect.hasUnreadSms ? "Mark as read" : "Read",
    color: "primary",
    icon: prospect.hasUnreadSms ? "envelope" : "envelope-open",
    active: true,
    attr: null,
    status: null,
    id: "mark-as-read",
    "data-test": "mark-as-read-btn",
    actions: [
      () => dispatch(removeUnreadMessages(id, prospect)),
      () => dispatch(removeCampaignProspect(id, hasUnreadSms)),
      () => markAllMessagesAsRead(id),
      () => dispatch(updateProspectSuccess({ id, hasUnreadSms: false })),
    ],
    disabled: isFetchingMessages || !prospect.hasUnreadSms,
  };

  const statusList = [
    {
      text: "Verified",
      color: "green",
      icon: "check",
      active: ownerVerifiedStatus === "verified",
      attr: "ownerVerifiedStatus",
      status: actionBtnStatus.ownerVerifiedStatus,
    },
    {
      text: "DNC",
      color: "red",
      icon: "phone-slash",
      active: doNotCall,
      attr: "doNotCall",
      disabled: optedOut,
      status: actionBtnStatus.doNotCall,
      actions: dncActions,
    },
    {
      text: "Priority",
      color: "orange",
      icon: "bolt",
      active: isPriority,
      attr: "isPriority",
      status: actionBtnStatus.isPriority,
    },
    {
      text: "Qualified",
      color: "purple",
      icon: "star",
      active: isQualifiedLead,
      attr: "isQualifiedLead",
      status: actionBtnStatus.isQualifiedLead,
    },
    {
      text: "Wrong Number",
      color: "warning",
      icon: "phone",
      active: wrongNumber,
      attr: "wrongNumber",
      status: actionBtnStatus.wrongNumber,
      actions: [() => dispatch(markMessagesAsReadLocally(id, prospect))],
    },
  ];
  // local state
  const [statusButtonsList, setStatusButtonsList] = useState(statusList);

  useEffect(() => {
    setStatusButtonsList([...statusList]);
    if (isMobile) {
      setStatusButtonsList([...statusList, markAsReadStatus]);
    }
    // eslint-disable-next-line
  }, [isMobile, prospect]);

  return (
    <>
      <ProspectDetailsCard prospect={prospect} />
      <StyledDivider />

      {displayProspectStatus && (
        <StyledStatusWrapper>
          <StatusActionBtns
            className="statusBtn"
            prospect={prospect}
            statusList={statusButtonsList}
          />
        </StyledStatusWrapper>
      )}
      <Fields prospect={prospect} />
    </>
  );
};

export default DetailsTab;
