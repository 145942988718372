import React from "react";
import styled from "styled-components/macro";
import Dot from "./Dot";
import { DotState } from "./utils";

interface Props {
  currentStep: number;
  steps: number;
  stepErrorId?: number | null;
  showCurrentOnly: boolean;
  dotClick?: (id: number) => void;
}

const Dots: React.FC<Props> = ({
  currentStep,
  steps,
  stepErrorId = null,
  showCurrentOnly = false,
  dotClick,
}) => {
  return (
    <DotsContainer>
      {Array(steps)
        .fill(null)
        .map((_, idx) => {
          const currentId = idx + 1;
          const isActive = currentId === currentStep;
          const hasError = isActive && currentId === stepErrorId;
          const isComplete = (isActive && currentId === steps) || currentId < currentStep;

          const getDotState = () => {
            switch (true) {
              case !showCurrentOnly && isComplete:
                return DotState.Complete;
              case !showCurrentOnly && hasError:
                return DotState.Error;
              case isActive:
                return DotState.Active;
              default:
                return DotState.Inactive;
            }
          };

          return (
            <React.Fragment key={idx}>
              <Dot
                dotState={getDotState()}
                dotClick={dotClick ? () => dotClick(idx) : undefined}
              />
            </React.Fragment>
          );
        })}
    </DotsContainer>
  );
};

const DotsContainer = styled.ul`
  padding-inline-start: 0px;
  display: flex;
  justify-content: center;
  margin: 15px 0px 10px 0px;
`;

export default Dots;
