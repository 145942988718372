import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CustomInput } from "reactstrap";

interface Props {
  field: any;
  customOnChange?: () => void;
}

const StringCheckboxField: React.FC<Props> = ({ field, customOnChange }) => {
  const { watch, setValue, register } = useFormContext();
  const { name, inputProps } = field;
  const selection = watch(name);

  register({ name }, field.rules);

  useEffect(() => {
    setValue(name, inputProps.defaultValue);
  }, [register]);

  const handleOnChange = () => {
    setValue(name, !selection ? inputProps.id : "");
  };

  return (
    <>
      <CustomInput
        type="checkbox"
        name={name}
        id={inputProps.id}
        label={inputProps.label}
        disabled={inputProps.disabled}
        checked={selection || ""}
        onChange={customOnChange || handleOnChange}
      />
    </>
  );
};

export default StringCheckboxField;
