import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";

// components
import { LoadingButton } from "../Buttons";
import FormRenderer from "../FormRenderer";

// redux
import {
  createPropertyTag,
  updatePropertyTag,
} from "../../../main/store/Tags/property/thunks";

// utils
import { formatErrorMessage } from "module/common/utils/utils";
import { DISTRESS_INDICATOR_TOOLTIP } from "module/common/helpers/variables";

// styled components
import { ButtonsContainer } from "assets/styles/sc/layout";

const CreateEditPropertyTagForm = ({ data, onSuccess, onCancelClick, onBack }) => {
  const dispatch = useDispatch();
  const propertyData = data || {};
  const hasId = propertyData.hasOwnProperty("id");

  const onSubmit = ({ data, setServerError, setSubmittingState }) => {
    const handler = hasId ? updatePropertyTag : createPropertyTag;

    const onSubmitSuccess = () => {
      // close modal and stop the loading spinner
      onSuccess && onSuccess({ ...propertyData, ...data });
      setSubmittingState(false);
    };

    const onError = (err) => {
      const defaultMessage = `There was an issue ${
        hasId ? "updating" : "creating"
      } the tag, please try again later.`;
      const errorMessage = formatErrorMessage(err, defaultMessage);

      setSubmittingState(false);
      setServerError([errorMessage]);
    };

    dispatch(handler({ ...propertyData, ...data }))
      .then(onSubmitSuccess)
      .catch(onError);
  };

  const fields = [
    [
      {
        name: "name",
        label: "Name",
        component: "input",
        inputProps: {
          defaultValue: propertyData.name || null,
        },
        rules: {
          required: "Required",
          maxLength: { value: 255, message: "Exceeds max length of 255" },
        },
      },
    ],
    [
      {
        name: "distressIndicator",
        label: "Mark as a Distress Indicator",
        tooltip: {
          id: "distressIndicator-tooltip",
          text: DISTRESS_INDICATOR_TOOLTIP,
        },
        component: "switch",
        inputProps: {
          leftLabel: "No",
          rightLabel: "Yes",
          isOffOnSwitch: true,
        },
      },
    ],
  ];
  return (
    <FormRenderer
      defaultValues={{
        distressIndicator: propertyData.distressIndicator
          ? propertyData.distressIndicator
          : false,
      }}
      fields={fields}
      onSubmit={onSubmit}
      formId="property-tags"
      submitButton={({ submittingState }) => (
        <ButtonsContainer className="mt-2">
          {onBack ? (
            <Button type="button" onClick={onBack}>
              Back
            </Button>
          ) : (
            <Button onClick={onCancelClick}>Cancel</Button>
          )}
          <LoadingButton
            color="primary"
            type="submit"
            value="Submit"
            data-test="tag-submit"
            loading={submittingState}
            className="px-4"
          >
            {hasId ? "Save" : "Create"}
          </LoadingButton>
        </ButtonsContainer>
      )}
    />
  );
};

export default CreateEditPropertyTagForm;
