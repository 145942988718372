import { UserData, IProfile } from "./interfaces";

// TODO: turn these actions to use `createAction`.
export const setAuthenticated = ({ access = "", refresh = "" }) => ({
  type: "SET_AUTH_STATE",
  access,
  refresh,
});

export const setAuthStatus = (status: string) => ({ type: "SET_AUTH_STATUS", status });
export const setVerificationStatus = (status: string) => ({
  type: "SET_VERIFICATION_STATUS",
  status,
});
export const unsetAuthenticated = () => ({ type: "SET_UNAUTH_STATE" });
export const setAuthError = (error: string) => ({ type: "SET_AUTH_ERROR", error });
export const setUserInfo = (userData: UserData) => ({ type: "SET_USER_DATA", userData });
export const setProfileData = (profile: IProfile) => ({
  type: "SET_PROFILE_DATA",
  profile,
});
export const setLoggingIn = (loggingInStatus: boolean) => ({
  type: "SET_LOGGING_IN_STATUS",
  loggingIn: loggingInStatus,
});
