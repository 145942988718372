import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, UncontrolledTooltip } from "reactstrap";

// components
import Modal from "module/common/components/Modal";

// redux related
import { selectUserData } from "module/main/store/Auth/selectors";
import { prospectFetchSingle } from "module/main/store/prospectStore/thunks";
import { addNewToast } from "module/main/store/Global/Toasts/actions";

// hooks
import useModal from "module/common/hooks/useModal";

// forms
import PushToSMSCampaignForm from "../../DesktopProspectDetailsPage/PushToSMSCampaignForm";
import { usePushToCampaignProps } from "module/campaign/utils/campaignHook";

const ProspectPushToCampaign = (props) => {
  const { className, prospect } = props;

  // profile information for permissions
  const { profile } = useSelector(selectUserData);

  const [isCampaignModalOpen, , toggleCampaignModal] = useModal();

  const dispatch = useDispatch();

  const finishPushToCampaign = () => {
    // also update the property tags on the store if any

    dispatch(prospectFetchSingle(prospect.id)).catch(() => {
      dispatch(
        addNewToast({
          message: "There was an issue updating the prospect, please refresh the page.",
          color: "danger",
        })
      );
    });
  };

  const campaignModal = (
    <Modal
      isOpen={isCampaignModalOpen}
      toggle={toggleCampaignModal}
      title="Add to Campaign"
      size="md"
    >
      <PushToSMSCampaignForm
        closeModal={toggleCampaignModal}
        updateCampaign={finishPushToCampaign}
        prospect={prospect.id}
      />
    </Modal>
  );

  const pushToCampaignProps = usePushToCampaignProps(profile);

  return (
    <div className={`${className} text-center`}>
      <h5>
        If you wish to send an SMS text to this prospect, please add them to a campaign.
      </h5>
      <div className="mt-3">
        <span id="tooltip-id-bulk-push-to-campaign-permission">
          <Button
            size="lg"
            color="primary"
            onClick={toggleCampaignModal}
            disabled={pushToCampaignProps.disabled}
          >
            Add to a Campaign
          </Button>
        </span>
      </div>
      {pushToCampaignProps.tooltipText ? (
        <UncontrolledTooltip
          target="tooltip-id-bulk-push-to-campaign-permission"
          fade={false}
        >
          {pushToCampaignProps.tooltipText}
        </UncontrolledTooltip>
      ) : null}
      {campaignModal}
    </div>
  );
};

export default ProspectPushToCampaign;
