// store
import { Dispatch } from "redux";
import * as actions from "./actions";
import * as api from "./api";
import { decrementMarketCampaignCount } from "../Markets/actions";
import { clearCampaignsBatchProspectsError } from "../CampaignsBatchProspectsStore/actions";
import { fetchCampaignsBatchProspects } from "../CampaignsBatchProspectsStore/thunks";
import { updateProspectList } from "../prospectStore/actions";
import { addErrorToast, addNewToast } from "../Global/Toasts/actions";
import { setCampaignPageTabData } from "../uiStore/campaignsPageDesktopView/campaignsList/filterData/actions";
import {
  uiGetCampaignPageActiveMarket,
  uiGetCampaignPageActiveSort,
  uiGetCampaignPageActiveTab,
  uiGetCampaigns,
} from "../uiStore/campaignsPageDesktopView/campaignsList/filterData/selectors";
import { campaignIsLoadingMore } from "./selectors";
// utils
import { captureSort, updateProspectTagData } from "./utils";
import { arrayToMapIndex } from "../utils";
import { Fetching, genericNetworkErrorMessage } from "module/common/helpers/variables";

interface IParams {
  market: number;
  ordering: string;
  sms: boolean;
}

export const formatCampaigns = (data: any, params?: IParams) => {
  const payload: any = {
    sortOrder: captureSort(data.results),
    campaigns: arrayToMapIndex("id", data.results),
    previous: data.previous,
    next: data.next,
  };
  if (params) {
    payload.sortBy = params.ordering;
    payload.marketId = params.market;
  }
  return payload;
};

export const fetchSortedCampaigns = (params: IParams) => (dispatch: any) => {
  dispatch(actions.setFetchedCampaignStatus(Fetching));
  return api
    .getCampaigns(params)
    .then(({ data }) => {
      const payload = formatCampaigns(data, params);
      dispatch(actions.setFetchedCampaigns(payload));
      return data;
    })
    .catch(() => {
      dispatch(actions.setFetchedCampaignsError("Error when fetching sorted campaigns"));
    });
};

export const fetchFilteredData = (params: IParams) => async (dispatch: any) => {
  dispatch(actions.setFetchedCampaignStatus(Fetching));
  return api.getCampaigns(params).then(({ data }) => {
    const { results } = data;

    const payload = {
      campaigns: arrayToMapIndex("id", results),
    };

    dispatch(actions.setFetchedCampaigns(payload));

    return data;
  });
};

export const fetchFilteredDataNextPage = () => async (dispatch: any, getState: any) => {
  const state = getState();

  const activeTab = uiGetCampaignPageActiveTab(state);
  const activeSort = uiGetCampaignPageActiveSort(state);
  const activeMarket = uiGetCampaignPageActiveMarket(state);
  const isLoadingMore = campaignIsLoadingMore(state);
  const { nextPage, activeSearchTerm } = uiGetCampaigns(state);

  // dispatch if fetching more
  if (nextPage && !isLoadingMore) {
    dispatch(actions.setFetchCampaignsNextPage(true));
    return api
      .getCampaignsNextPage(nextPage)
      .then(({ data }) => {
        const { results } = data;

        const order = results.map((r: any) => r.id);
        const payload = {
          campaigns: arrayToMapIndex("id", results),
        };
        dispatch(actions.setFetchCampaignsNextPageSuccess(payload));
        dispatch(
          setCampaignPageTabData({
            tab: activeTab,
            data: {
              sortOrder: order,
              nextPage: data.next,
              sortedBy: activeSort,
              searchTerm: activeSearchTerm,
              market: activeMarket,
              fetchedPage: nextPage,
            },
            override: false,
          })
        );
        dispatch(actions.setFetchCampaignsNextPage(false));
        return data;
      })
      .catch(() => dispatch(addNewToast(genericNetworkErrorMessage)));
  }
};

export const callGetSingleCampaign =
  (id: number, expandFields: boolean = true) =>
  async (dispatch: any) => {
    const params = expandFields ? { expand: "market,created_by" } : null;
    return api
      .campaignRequest({ method: "get", id, params })
      .then(({ data }) => {
        dispatch(actions.updateCampaign(data));
        return data;
      })
      .catch((error) => {
        dispatch(
          addErrorToast(
            "There was an issue retrieving the campaign. Please refresh and try again"
          )
        );
        throw error.response;
      });
  };

/*************************************************************************************************/
export const archiveCampaign = (data: any) => async (dispatch: any) => {
  const { id, name, company, market, createdBy, priorityCount, isArchived } = data;

  const body = {
    name,
    company,
    market,
    createdBy,
    priorityCount,
    isArchived,
  };

  return api
    .campaignRequest({ method: "put", id, data: body })
    .then(({ data }) => {
      dispatch(actions.setArchiveCampaign(data));
      dispatch(decrementMarketCampaignCount(market));
    })
    .catch(() => {
      // TODO: show error message?
    });
};

// SMS template ID is no longer stored. We are using this to only update the batch category Id now.
export const fetchCampaignProspectsAndUpdateCampaignSmsTemplate =
  (data: any) => (dispatch: any) => {
    const { id, smsCategory } = data;
    // clear any batch prospect error
    dispatch(clearCampaignsBatchProspectsError());
    dispatch(fetchCampaignsBatchProspects(id, smsCategory));
  };

export const createFollowupCampaign =
  (campaignId: number, payload: any) => async (dispatch: any) => {
    return api
      .campaignFollowup(campaignId, payload)
      .then((xhr) => {
        dispatch(actions.updateCampaign(xhr.data));
        return xhr;
      })
      .catch((error) => {
        throw error;
      });
  };

export const createCampaign = (payload: any) => async (dispatch: any) => {
  return api.createCampaign(payload).then(({ data }) => {
    dispatch(actions.updateCampaign(data));
    return data;
  });
};

export const updateCampaign = (payload: any) => async (dispatch: any) => {
  return api
    .campaignRequest({ method: "patch", id: payload.id, data: payload })
    .then(({ data }) => {
      dispatch(actions.updateCampaign(data));
      return data;
    });
};

export const bulkUpdateProspectTagsByCampaign =
  (campaignId: number, payload: any, campaignProspects: any) => async (dispatch: any) => {
    await api.bulkUpdateProspectTagsByCampaign(campaignId, payload);
    const message = [];
    const color = "success";
    message.push("Tags successfully updated.");
    dispatch(
      updateProspectList({
        results: updateProspectTagData(campaignProspects, payload.add, payload.remove),
      })
    );
    dispatch(addNewToast({ message: message.join(" "), color: color }));
  };

// export const editDirectMailCampaign =
//   (id: number, payload: any) => (dispatch: Dispatch) => {
//     return api.editDirectMailCampaign(id, payload).then((response) => {
//       const { data } = response;
//       dispatch(actions.updateCampaign(data.campaign));
//       return response;
//     });
//   };

// export const cancelDmCampaign =
//   (id: number) => (dispatch: Dispatch, getState: (...args: any[]) => any) => {
//     const state = getState();
//     const campaign = getCampaign(state, id);
//     const updateFn = (_: any, order: any, status: string) => ({ ...order, status });
//     const updatedCampaign = { ...campaign };
//     const newStatus = "cancelled";

//     updateIn(["directmail", "order"], updateFn, newStatus, updatedCampaign);
//     dispatch(actions.updateCampaign(updatedCampaign));
//     return api.cancelCampaign(id).catch(() => dispatch(actions.updateCampaign(campaign)));
//   };

export const fetchCampaigns =
  (params: any = {}) =>
  (dispatch: Dispatch) => {
    return api.getCampaigns(params).then((response: any) => {
      const { data } = response;
      dispatch(actions.updateCampaignsList(arrayToMapIndex("id", data.results)));
      return response;
    });
  };

export const fetchCampaignsNextPage = (nextPage: string) => (dispatch: Dispatch) => {
  return api.getCampaignsNextPage(nextPage).then((response: any) => {
    const { data } = response;
    dispatch(actions.updateCampaignsList(arrayToMapIndex("id", data.results)));
    return response;
  });
};

export const fetchSpecificCampaigns = (ids: Array<number>) => (dispatch: Dispatch) => {
  return api.getCampaignsInfo({ ids }).then((response: any) => {
    const { data } = response;
    dispatch(actions.updateCampaignsList(arrayToMapIndex("id", data)));
    return response.data;
  });
};

export const fetchScribeSherpaMessages = () => (dispatch: Dispatch) => {
  return api
    .getScribeSherpaMessages()
    .then((response: any) => {
      return response.data;
    })
    .catch(() => {
      const error = "Error fetching ScribeSherpa™ messages";
      dispatch(addErrorToast(error));
      throw error;
    });
};

export const purchaseCampaignBalance =
  (campaignId: number, payload: any) => (dispatch: Dispatch) => {
    return api.postPurchaseCampaignBalance(campaignId, payload).then((response) => {
      dispatch(actions.updateCampaign({ id: campaignId, purchaseEstimate: null }));
      return response;
    });
  };
