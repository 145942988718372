import Axios, { delayedRequest } from "../../../common/utils/axiosConfig";
import { fastSpinner } from "module/common/helpers/variables";

export const getUserInfo = () => Axios.get("/auth/users/me/");
export const doAuthenticate = (payload: any) => Axios.post("/auth/jwt/create/", payload);
export const getUserBraintreeToken = () => Axios.get("/auth/users/payment_token/");

export const updatePassword = (body: any) => {
  const { newPassword, currentPassword } = body;
  return delayedRequest(
    Axios.post("/auth/users/set_password/", { newPassword, currentPassword }),
    fastSpinner
  );
};

interface VerifyPayload {
  token: string;
}
export const postVerifySessionToken = (payload: VerifyPayload) =>
  Axios.post("/auth/jwt/verify/", payload);
