export enum MessageStatus {
  Failed = "failed",
  Sent = "sent",
  Undelivered = "undelivered",
  Delivered = "delivered",
  Received = "received",
  Read = "read",
}

export enum FailureReason {
  Spam = "spam",
  UnreachableOrDisconnected = "unreachable_or_disconnected",
  RetryableError = "retryable_error",
  OptedOut = "opted_out",
  UnknownError = "unknown_error",
}

export interface IMessage {
  id: number;
  prospect: number;
  fromName: string;
  unreadByRecipient: boolean;
  mediaUrl: string | null;
  message: string;
  fromNumber: string;
  dt: string;
  fromProspect: boolean;
  status: MessageStatus;
  failureReason: FailureReason | null;
}

export interface IResults {
  count: number;
  next: null;
  previous: null;
  results?: IMessage[];
}
