import { library } from "@fortawesome/fontawesome-svg-core";
// Keep alphabetical
import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";
import {
  faEllipsisV,
  faExclamationCircle as faExclamationCircleLight,
  faHandHoldingUsd,
} from "@fortawesome/pro-light-svg-icons";
import {
  faBars,
  faCircleInfo,
  faEllipsisH,
  faExpandAlt as faExpandAltReg,
  faPlusCircle,
  faQuestionCircle,
  faTrademark,
  faWindowRestore,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faAddressBook,
  faAddressCard,
  faArchive,
  faArrowAltCircleLeft,
  faArrowCircleUp,
  faArrowCircleDown,
  faArrowLeft,
  faArrowRight,
  faArrowToBottom,
  faBan,
  faBell,
  faBolt,
  faBook,
  faBookmark,
  faBullhorn,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarPlus,
  faCalendarEdit,
  faChartBar,
  faChartLine,
  faChartNetwork,
  faCheck,
  faCheckCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleXmark,
  faCircle,
  faClipboard,
  faClipboardList,
  faClock,
  faClone,
  faCloudUploadAlt,
  faCog,
  faComments,
  faCommentDots,
  faCreditCard,
  faDotCircle,
  faDownload,
  faEnvelope,
  faEnvelopeOpen,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandAlt,
  faExternalLinkAlt,
  faFileExport,
  faFileInvoiceDollar,
  faFilter,
  faFire,
  faForward,
  faGavel,
  faGlobe,
  faHeadset,
  faHome,
  faInfoCircle,
  faLayerGroup,
  faListOl,
  faListUl,
  faLock,
  faMagnifyingGlass,
  faMailbox,
  faMailBulk,
  faMinus,
  faMobileAlt,
  faMoneyBillWave,
  faPause,
  faPauseCircle,
  faPaperPlane,
  faPen,
  faPencilAlt,
  faPhone,
  faPhoneSlash,
  faPlus,
  faPlusCircle as faPlusCircleSolid,
  faPrint,
  faProjectDiagram,
  faQuestion,
  faQuestionCircle as faQuestionCircleSolid,
  faRabbitFast,
  faReceipt,
  faRedo,
  faSave,
  faSearch,
  faShare,
  faSkull,
  faSortAmountDown,
  faSparkles,
  faStar,
  faStickyNote,
  faSyncAlt,
  faTachometerAlt,
  faTags,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faTruck,
  faUpload,
  faUser,
  faUserCheck,
  faUserCog,
  faUsers,
  faVolumeMute,
  faVolumeUp,
  faWindowRestore as faWindowRestoreSolid,
  faHistory,
  faArrowAltDown,
  faArrowAltUp,
  faPlay,
} from "@fortawesome/pro-solid-svg-icons";
// Keep alphabetical
library.add(
  faAddressBook,
  faAddressCard,
  faArchive,
  faArrowAltCircleLeft,
  faArrowAltUp,
  faArrowAltDown,
  faArrowCircleUp,
  faArrowCircleDown,
  faArrowLeft,
  faArrowRight,
  // faRedo,
  faArrowToBottom,
  faBan,
  faBars,
  faBell,
  faBolt,
  faBook,
  faBookmark,
  faBullhorn,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarPlus,
  faCalendarEdit,
  faChartBar,
  faChartLine,
  faChartNetwork,
  faCheck,
  faCheckCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleInfo,
  faCircleXmark,
  faCircle,
  faClipboard,
  faClipboardList,
  faClock,
  faClone,
  faCloudUploadAlt,
  faCog,
  faComments,
  faCommentDots,
  faCreditCard,
  faDotCircle,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faExclamationCircle,
  faExclamationCircleLight,
  faExclamationTriangle,
  faExpandAlt,
  faExpandAltReg,
  faExternalLinkAlt,
  faFacebook,
  faFileExport,
  faFileInvoiceDollar,
  faFilter,
  faFire,
  faForward,
  faGavel,
  faGlobe,
  faHandHoldingUsd,
  faHeadset,
  faHistory,
  faHome,
  faInfoCircle,
  faLayerGroup,
  faListOl,
  faListUl,
  faLock,
  faMagnifyingGlass,
  faMailbox,
  faMailBulk,
  faMinus,
  faMobileAlt,
  faMoneyBillWave,
  faPause,
  faPauseCircle,
  faPaperPlane,
  faPen,
  faPencilAlt,
  faPhone,
  faPhoneSlash,
  faPlay,
  faPlus,
  faPlusCircle,
  faPlusCircleSolid,
  faPrint,
  faProjectDiagram,
  faQuestion,
  faQuestionCircle,
  faQuestionCircleSolid,
  faRabbitFast,
  faReceipt,
  faRedo,
  faSave,
  faSearch,
  faShare,
  faSkull,
  faSortAmountDown,
  faSparkles,
  faStar,
  faStickyNote,
  faSyncAlt,
  faTachometerAlt,
  faTags,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrademark,
  faTrash,
  faTruck,
  faUpload,
  faUser,
  faUserCog,
  faUsers,
  faUserCheck,
  faVolumeMute,
  faVolumeUp,
  faWindowRestore,
  faWindowRestoreSolid,
  faYoutube
);
