import React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import { Nav, NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WithPermissions from "../../WithPermissions";
import { UnreadMsgIndicator } from "../../UnreadMsg";
import { hasRemindersPending } from "../../../../main/store/SequencesStore/reminders/selectors";
import { sequenceDotProps as dotProps } from "module/common/helpers/variables";

const StyledNavItem = styled(NavItem)`
  margin-right: var(--pad1);
  line-height: 1.5;

  ${(props) =>
    props.theme.isDesktop
      ? css`
          margin-right: ${(props) =>
            props.alignment !== "space-between" && "var(--pad7)"};
        `
      : null}
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  white-space: pre;
  padding: 0 !important;
  padding-bottom: var(--pad1) !important;
  color: ${(props) => props.color || "var(--white)"} !important;
  position: relative;
  cursor: pointer;
  align-items: center;
  z-index: 0;
  &:after,
  &:before {
    transition:
      border-bottom-width 0.25s,
      border-top-width 0.25s;
    content: "";
    position: absolute;
    z-index: 999;
    bottom: ${(props) => (props.$hasBorder ? "-2px" : "0px")};
    left: 50%;
  }
  &:after {
    width: 105%;
    border-bottom: 0px solid var(--darkNavy);
    border-radius: 15px;
    transform: translate(-50%, 30%);
    height: 0px;
  }
  &:before {
    height: 0;
    width: 0;
    border-right: 0.9rem solid transparent;
    border-top: 0px solid var(--darkNavy);
    border-left: 0.9rem solid transparent;
    transform: translate(-50%, 75%);
  }
  &.active {
    font-weight: 900;
    &:after {
      border-bottom-width: 0.25rem;
    }
    &:before {
      border-top-width: 0.5rem;
    }
    ${(props) =>
      props.theme.isDesktop
        ? css`
            padding-bottom: var(--pad2) !important;
          `
        : null}
  }
`;

const StyledNav = styled(Nav)`
  flex-wrap: nowrap !important;
  border: none !important;
  position: relative;
  margin-top: 0.8rem;
  border-bottom: ${(props) => props.border || "none"} !important;
  ${(props) =>
    props.theme.isDesktop
      ? css`
          padding-top: var(--pad2) !important;
          justify-content: ${(props) => props.alignment || "flex-start"};
          /* divider that runs across the header  */
          &:before {
            content: "";
            position: absolute;
            width: calc(100% + var(--pad5) + var(--pad5));
            height: 2px;
            top: 0;
            left: calc(-1 * var(--pad5));
            background: #ffffff38;
          }
        `
      : css`
          justify-content: space-between;
          padding-top: 0;
        `}
`;

function TabNav(props) {
  const { tabs, alignment } = props.data;
  const pendingReminders = tabs.some((tab) => tab.idx === "sequences")
    ? useSelector(hasRemindersPending)
    : false;
  const mappedTabs = tabs
    .filter((e) => e !== null)
    .map((item, key) => (
      <WithPermissions key={key} {...item.permissions}>
        <StyledNavItem alignment={alignment} className="textXL" data-test="nav-item">
          <StyledNavLink
            $hasBorder={props.border}
            color={props.color}
            className={props.activeTab === item.idx ? "active" : ""}
            onClick={() => {
              props.toggleTab(item.idx);
            }}
            data-test={item.idx}
          >
            {typeof item.icon === "string" || Array.isArray(item.icon) ? (
              <FontAwesomeIcon icon={item.icon} className="mr-2" />
            ) : (
              item.icon
            )}
            {item.idx === "sequences" ? (
              <div className="d-flex flex-column justify-content-end">
                <UnreadMsgIndicator readable isRead={!pendingReminders} {...dotProps} />
                <span>{item.name}</span>
              </div>
            ) : (
              item.name
            )}
          </StyledNavLink>
        </StyledNavItem>
      </WithPermissions>
    ));

  return (
    <StyledNav border={props.border} alignment={alignment} tabs data-test="nav">
      {mappedTabs}
    </StyledNav>
  );
}

export default TabNav;
