import { useState, useRef, useCallback, useEffect } from "react";

interface Props<ParamsType> {
  asyncFunc: (params: ParamsType) => Promise<any>;
  immediate?: boolean;
  funcParams?: any;
  initialData?: any;
}

type HookType = <ParamsType = void, DataType = any>({
  asyncFunc,
  immediate,
  funcParams,
  initialData,
}: Props<ParamsType>) => [(params: ParamsType) => Promise<any>, boolean, DataType];

const useAsync: HookType = <ParamsType, DataType>({
  asyncFunc,
  immediate = false,
  funcParams,
  initialData,
}: Props<ParamsType>) => {
  const [loading, setLoading] = useState(immediate);
  const [data, setData] = useState<DataType>(initialData);
  const mountedRef = useRef(true);

  const execute = useCallback(
    (params: ParamsType) => {
      setLoading(true);
      return asyncFunc({ ...funcParams, ...params })
        .then((response) => {
          if (!mountedRef.current) return null;
          const responseData = response?.data || response;
          setData(responseData);
          setLoading(false);
          return responseData;
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          setLoading(false);
          throw err;
        });
    },
    [asyncFunc, funcParams]
  );

  useEffect(() => {
    if (immediate) {
      execute(funcParams);
    }
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  return [execute, loading, data];
};

export default useAsync;
