import React, { useState, useCallback, useEffect } from "react";

// components
InputWithClearButton;
import { debounce } from "module/common/helpers/utils";
import QuickRepliesList from "../QuickRepliesList";
import MessageQuickRepliesDesktop from "./MessageQuickRepliesDesktop";
import MessageQuickRepliesMobile from "./MessageQuickRepliesMobile";
import { isMobile } from "detectDevice";
import InputWithClearButton from "module/common/components/InputWithClearButton";

const MessageQuickRepliesModal = ({
  isOpen,
  toggle,
  setModalVisibility,
  prospectId,
  setQuickReplyToEdit,
  setShowEditForm,
}) => {
  // search related functionality
  const [searchTerm, setSearchTerm] = useState("");
  const onSearch = useCallback(
    debounce((term) => setSearchTerm(term?.toLowerCase() || ""), 300),
    []
  );

  useEffect(() => {
    if (!isOpen && searchTerm) {
      setSearchTerm("");
    }
  }, [isOpen, searchTerm]);

  const MessageQuickRepliesComponent = isMobile
    ? MessageQuickRepliesMobile
    : MessageQuickRepliesDesktop;

  return (
    <MessageQuickRepliesComponent
      isOpen={isOpen}
      toggle={toggle}
      setShowEditForm={setShowEditForm}
      renderSearchInput={() => (
        <InputWithClearButton
          autoFocus
          placeholder="Search"
          onChange={onSearch}
          clearInput={() => setSearchTerm("")}
        />
      )}
      renderQuickRepliesList={() => (
        <QuickRepliesList
          searchTerm={searchTerm}
          prospectId={prospectId}
          setQuickRepliesModalVisibility={setModalVisibility}
          setQuickReplyToEdit={setQuickReplyToEdit}
          setShowEditForm={setShowEditForm}
        />
      )}
    />
  );
};

export default MessageQuickRepliesModal;
