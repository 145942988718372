import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CustomInput, Label } from "reactstrap";

const Switch = styled(CustomInput)`
  .custom-control-label {
    &:after {
      cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
      transition: transform 0.15s !important;
      ${(props) => props.readOnly && "pointer-events: none;"}
    }
  }
  .custom-control-input:checked {
    & ~ .custom-control-label {
      &:after {
        ${(props) => props.$readOnly && "pointer-events: none;"}
        cursor: ${(props) =>
          props.disabled || props.$readOnly ? "not-allowed" : "pointer"};
        background-color: white !important;
        border: 1px solid var(--sherpaBlue);
      }
    }
  }
`;

const ToggleSwitchWrapper = styled.div`
  .is-checked {
    color: ${(props) => (props.isOff ? "var(--darkGray)" : "var(--sherpaBlue)")};
    font-weight: 900 !important;
  }
  .not-checked {
    color: var(--darkGray);
  }
`;

const CustomSwitch = (props) => {
  const {
    id,
    name,
    isDisabled,
    onChange,
    checked,
    rightLabel,
    leftLabel,
    isOffOnSwitch,
    innerRef,
    ...rest
  } = props;

  return (
    <ToggleSwitchWrapper isOff={isOffOnSwitch && !checked} className={props.className}>
      <div className="d-flex align-items-center">
        {leftLabel && (
          <Label className="mr-1 font-weight-bold" htmlFor={id}>
            <span
              className={
                rightLabel
                  ? checked
                    ? "not-checked"
                    : "is-checked font-weight-bold"
                  : null
              }
            >
              {leftLabel}
            </span>
          </Label>
        )}
        <Switch
          name={name}
          className="onoff"
          onChange={!props.readOnly ? onChange : undefined}
          checked={checked}
          type="switch"
          id={id}
          disabled={isDisabled}
          data-test="switch-toggle"
          innerRef={innerRef}
          $readOnly={props.readOnly}
          {...rest}
        />
        <span className={rightLabel ? (checked ? "is-checked" : "not-checked") : null}>
          {rightLabel}
        </span>
      </div>
    </ToggleSwitchWrapper>
  );
};

CustomSwitch.propTypes = {
  /** id to identify the specific switch */

  id: PropTypes.string,
  /** send true to disable */
  isDisabled: PropTypes.bool,
  /** function to run when value is changed */
  onChange: PropTypes.func,
  /** boolean to indicate if switch is on or off */
  checked: PropTypes.bool,
  //* Boolean to determine if the toggle is based on an on/off switch */
  isOffOnSwitch: PropTypes.bool,
  rightLabel: PropTypes.string,
  leftLabel: PropTypes.string,
};

CustomSwitch.defaultProps = {
  isOffOnSwitch: false,
};

export default CustomSwitch;
