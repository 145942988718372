import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SQS } from "module/common/helpers/variables";
//redux-related
import { getSequences } from "../../../../../main/store/SequencesStore/selectors";
import {
  createProspectSequenceAction,
  updateProspectSequenceAction,
} from "../../../../../main/store/SequencesStore/prospects/thunks";
//Components
import Modal from "../../../../../common/components/Modal";
import InputSelect from "../../../../../common/components/InputSelect";
import usePrevious from "module/common/hooks/usePrevious";

const AddEditModal = ({
  isOpen,
  toggle,
  isLoading,
  setIsLoading,
  prospectSequence,
  prospect,
  currentTab,
  setOpen,
}) => {
  const [currentSelect, setCurrentSelect] = useState();
  const sequenceList = useSelector(getSequences);
  const prevTab = usePrevious(currentTab);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentTab === SQS && prevTab !== currentTab && !prospectSequence) setOpen(true);
  }, [currentTab, prospectSequence, prevTab, setOpen]);

  const editIcon = (
    <FontAwesomeIcon icon="calendar-edit" color="var(--sherpaBlue)" className="mr-1" />
  );
  const addIcon = (
    <FontAwesomeIcon icon="calendar-plus" color="var(--sherpaBlue)" className="mr-1" />
  );

  const sequenceOptions = useMemo(
    () =>
      sequenceList?.map((sq) => ({
        label: sq.name,
        value: { id: sq.id, name: sq.name },
      })),
    [sequenceList]
  );

  const defaultValue = currentSelect
    ? {
        label: currentSelect.name,
        value: { id: currentSelect.id, name: currentSelect.name },
      }
    : null;

  const handleUpdate = () => {
    if (!currentSelect) return;
    setIsLoading(true);
    dispatch(
      updateProspectSequenceAction(prospectSequence.id, { sequenceId: currentSelect.id })
    ).then((response) => {
      response && toggle();
      setIsLoading(false);
    });
  };

  const handleSelect = () => {
    if (!currentSelect) return;
    setIsLoading(true);
    const payload = { prospect: prospect.id, sequenceId: currentSelect.id };
    dispatch(createProspectSequenceAction(payload)).then((response) => {
      response && toggle();
      setIsLoading(false);
    });
  };

  const title = prospectSequence ? "Edit Sequence" : "Add to Sequence";

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        title={title}
        size="md"
        btnOptions={[
          {
            name: "Cancel",
            color: "dark",
            onClick: toggle,
          },
          {
            name: prospectSequence ? "Save" : "Add",
            loading: isLoading,
            color: "primary",
            onClick: prospectSequence ? handleUpdate : handleSelect,
          },
        ]}
      >
        <label htmlFor="sequence-select" className="font-weight-bold h5 mb-3">
          {prospectSequence ? editIcon : addIcon} {title}
        </label>
        <InputSelect
          id="sequence-slect"
          value={defaultValue}
          placeholder="Choose your sequence"
          options={sequenceOptions}
          onChange={({ value }) => {
            setCurrentSelect(value);
          }}
        />
      </Modal>
    </>
  );
};

export default AddEditModal;
