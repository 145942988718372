import { PartialProspect, IProspect } from "module/main/store/prospectStore/interfaces";
import { UnreadMessageResponse } from "./interfaces";

export const removeCampaignProspectUnread = (
  campaignProspectsUnread: any,
  prospectToRemove: any
) => {
  return campaignProspectsUnread.filter((campaignProspect: any) => {
    return campaignProspect.prospectId !== prospectToRemove;
  });
};

export const getCampaignsToUpdate = (campaignProspectsUnread: any, prospect: number) => {
  return Object.entries(campaignProspectsUnread).reduce(
    (acc: Array<number>, [key, prospectList]: any) => {
      const found = prospectList.find(
        (campaignProspect: any) => campaignProspect.prospect === prospect
      );

      // prospectList.length === 1 means the current prospect is the
      // last message and the campaign will have no more messages.
      if (found && prospectList.length === 1) acc.push(parseInt(key));
      return acc;
    },
    []
  );
};

export const getPartialProspects = (prospectsInfo: UnreadMessageResponse[]) => {
  return prospectsInfo.reduce((prospectList: IProspect[], prospectData) => {
    const prospect = { ...PartialProspect };
    prospect.id = prospectData.prospectId;
    prospect.ownerVerifiedStatus = prospectData.ownerVerifiedStatus;
    prospect.doNotCall = prospectData.doNotCall;
    prospect.isQualifiedLead = prospectData.isQualifiedLead;
    prospect.isPriority = prospectData.isPriority;
    prospect.wrongNumber = prospectData.wrongNumber;
    prospect.optedOut = prospectData.optedOut;
    prospect.partial = true;
    prospect.hasUnreadSms = true;
    prospect.reminderAgent = prospectData.prospectReminderAgent;
    prospect.reminderDateUtc = prospectData.prospectReminderDateUtc;

    prospectList.push(prospect);

    return prospectList;
  }, []);
};
