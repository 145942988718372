import AxiosInstance from "../../../common/utils/axiosConfig";

export const getSequences = () => {
  return AxiosInstance.get("/sequences/");
};

export const deleteSequence = (id: number) => {
  return AxiosInstance.delete(`/sequences/${id}/`);
};

export const getSequenceById = (id: number) => {
  return AxiosInstance.get(`/sequences/${id}/`);
};

export const postNewSequence = (payload: any) => {
  return AxiosInstance.post(`/sequences/`, payload);
};

export const patchSequences = (id: number, payload: any) => {
  return AxiosInstance.put(`/sequences/${id}/`, payload);
};
