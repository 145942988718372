import AxiosInstance from "module/common/utils/axiosConfig";
import { IPhoneConnection } from "../../store/Company/interfaces";

export const optionsNewTelephonyIntegration = () => AxiosInstance.options(`/telephony/`);

export const createNewTelephonyIntegration = (data: any) =>
  AxiosInstance.post(`/telephony/`, data);

type UpdateTelephonyPayload = {
  id: number;
  data: Partial<IPhoneConnection>;
};
export const updateTelephonyIntegration = ({ id, data }: UpdateTelephonyPayload) =>
  AxiosInstance.patch(`/telephony/${id}/`, data);

export const getConnectAndSyncTelephony = (id: any) =>
  AxiosInstance.get(`/telephony/${id}/connect_and_sync/`);

export const connectAndSyncTelephony = (id: number, data?: any) =>
  AxiosInstance.patch(`/telephony/${id}/connect_and_sync/`, data);
