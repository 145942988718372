import { removeCampaignProspect } from "../../campaignProspectStore/thunks";
import { updateProspectSuccess } from "../../prospectStore/actions";
import { removeUnreadMessages_ } from "./actions";

export const removeUnreadMessages = (id: any, prospect: any) => (dispatch: any) => {
  if (prospect.hasUnreadSms) dispatch(removeUnreadMessages_(id));
};

export const markMessagesAsReadLocally =
  (id: number, prospect: any) => async (dispatch: any) => {
    dispatch(removeUnreadMessages(id, prospect));
    dispatch(removeCampaignProspect(id, prospect.hasUnreadSms));
    dispatch(updateProspectSuccess({ id, hasUnreadSms: false }));
  };
