import AxiosInstance from "../../../../common/utils/axiosConfig";

export const createProspectSequence = (payload: {
  prospect: number;
  sequenceId: number;
}) => {
  return AxiosInstance.post("/prospect-sequences/", payload);
};

export const updateProspectSequence = (
  prospectSequenceId: number,
  payload: {
    sequenceId: number;
  }
) => {
  return AxiosInstance.patch(`/prospect-sequences/${prospectSequenceId}/`, payload);
};

export const deleteProspectSequence = (prospectSequenceId: number) => {
  return AxiosInstance.delete(`/prospect-sequences/${prospectSequenceId}/`);
};
