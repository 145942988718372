import { useState } from "react";
import PropTypes from "prop-types";

const Hoverable = ({ children, ...props }) => {
  const [hovering, setIsHovering] = useState(false);

  return children({ hovering, setIsHovering, ...props });
};

Hoverable.propTypes = {
  children: PropTypes.func,
};

export default Hoverable;
