import React, { useState, useEffect } from "react";
import VirtualList from "react-tiny-virtual-list";
import useVirtualizedList from "../../hooks/useVirtualizedList";

const VirtualizedList = ({
  id,
  items,
  renderItem,
  fetchMoreData = null,
  ...restOfProps
}) => {
  const [itemHeight, listHeight] = useVirtualizedList({
    data: items,
    id: id,
  });
  const [lastListLength, setLastListLength] = useState(0);

  useEffect(() => {
    setLastListLength(items.length);
  }, [items.length]);

  return (
    <VirtualList
      id={id}
      data-test="virtual-list"
      width="100%"
      height={Math.max(listHeight, 400)}
      itemCount={items.length}
      itemSize={itemHeight}
      renderItem={renderItem}
      {...restOfProps}
      onItemsRendered={({ stopIndex }) => {
        // fetch when we're in the middle of the list
        if (
          fetchMoreData &&
          items.length / 1.5 <= stopIndex &&
          lastListLength !== items.length
        ) {
          fetchMoreData();
        }
      }}
    />
  );
};

export default VirtualizedList;
