import { useState, useEffect } from "react";

const useRecomputeHeight = ({ loading, nodeId, defaultHeight, padding = 0 }) => {
  const [height, setHeight] = useState(defaultHeight);

  useEffect(() => {
    const resize = () => {
      const node = document.getElementById(nodeId);
      const windowHeight = window.innerHeight;

      // only set height when node is rendered
      if (node) {
        setHeight(windowHeight - (node.getBoundingClientRect().top + padding));
      }
    };
    window.addEventListener("resize", resize);
    resize();

    return () => window.removeEventListener("resize", resize);
  }, [loading, nodeId, padding]);

  return height;
};

export default useRecomputeHeight;
