import { produce } from "immer";

// actions
import { SET_SERVICE_WORKER_UPDATE_REQUIRED } from "./actions";

// interfaces
import { IState } from "./interfaces";

export const initial: IState = {
  isServiceWorkerUpdateRequired: false,
};

/**
 * Global store used for any and all random things that need to be stored in the global state that
 * doesn't fit in any other store.
 */
const reducer = produce((base: IState, action: any) => {
  switch (action.type) {
    case SET_SERVICE_WORKER_UPDATE_REQUIRED: {
      base.isServiceWorkerUpdateRequired = action.payload;
      break;
    }
  }
}, initial);

export default reducer;
