import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components/macro";
import { globalDynamicConfig } from "dynamicConfig";

import * as actions from "../../../main/store/Calls/actions";
import * as selectors from "../../../main/store/Calls/selectors";
import { prospectAssignNumber } from "../../../main/store/prospectStore/thunks";
import { addNewToast } from "../../../main/store/Global/Toasts/actions";
import { AppEnv } from "module/common/helpers/variables";

import { hasSMSCampaignsCheck } from "module/common/utils/utils";

import { pulse } from "assets/styles/sc/animations";

const StyledButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.$iconOnly &&
    css`
      border-radius: 50%;
      width: 24px;
      padding-right: 0 !important;
      padding-left: 0 !important;
    `}
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  ${(props) =>
    props.isCalling &&
    css`
      animation: ${pulse} 2s infinite linear;
    `}
`;

const ClickToCall = ({ prospect, disabled, iconOnly }) => {
  const [validNumber, setValidNumber] = useState(false);
  const dispatch = useDispatch();

  const audioRef = useSelector(selectors.getAudioRef);
  const isRegistered = useSelector(selectors.getIsRegistered);
  const call = useSelector(selectors.getCall);
  const callState = useSelector(selectors.getCallState);
  const isActive = useSelector(selectors.getIsActive);
  const callSession = useSelector(selectors.getCallSession);
  const isProduction =
    globalDynamicConfig.config.REACT_APP_DYNAMIC_ENV === AppEnv.Production;

  const hasCampaigns =
    (prospect.campaigns && prospect.campaigns.length) ||
    (prospect.campaign && Object.keys(prospect.campaign).length > 0);

  const makeCall = () => {
    try {
      const newCall = callSession.newCall({
        callerName: "",
        callerNumber: prospect.sherpaPhoneNumber,
        destinationNumber: isProduction
          ? prospect.phoneRaw
          : process.env.REACT_APP_CALL_OVERWRITE,
        audio: true,
        video: false,
        userVariables: {
          callStart: new Date(),
          prospectID: prospect.id,
        },
      });
      dispatch(actions.setCall(newCall));
      dispatch(actions.setCallStartTime(new Date()));
      dispatch(actions.setCallProspectData(prospect));
    } catch (_) {
      dispatch(
        addNewToast({
          message: "There was an issue with Telnyx WebRTC. Please try calling later.",
          color: "danger",
        })
      );
    }
  };

  useEffect(() => {
    if (prospect.sherpaPhoneNumber) {
      setValidNumber(true);
    } else if (hasSMSCampaignsCheck(prospect)) {
      dispatch(prospectAssignNumber(prospect.id, { force_assign: true }));
    }
  }, [prospect, dispatch, hasCampaigns]);

  // The audioRef is created in calls store and the audio element is placed in App.tsx
  if (audioRef.current && call && call.remoteStream) {
    audioRef.current.srcObject = call.remoteStream;
  }

  return (
    <>
      {Object.keys(call).length === 0 && (
        <StyledButton
          className="text-truncate"
          color="primary"
          data-test="call-prospect"
          onClick={makeCall}
          size="sm"
          $iconOnly={iconOnly}
          disabled={
            disabled ||
            !validNumber ||
            (!isProduction && !process.env.REACT_APP_CALL_OVERWRITE)
          }
        >
          <FontAwesomeIcon
            size={iconOnly ? "sm" : "1x"}
            icon="phone"
            className={iconOnly ? "" : "mr-1"}
          />
          {iconOnly ? "" : "Call Prospect"}
        </StyledButton>
      )}
      {isRegistered && Object.keys(call).length > 0 && (
        <div>
          {call !== null && (
            <StyledButton
              color="success"
              size="sm"
              $iconOnly={iconOnly}
              disabled={call === null || !isActive}
            >
              <StyledFontAwesomeIcon
                size={iconOnly ? "sm" : "1x"}
                icon="phone"
                isCalling={callState !== "Active"}
                className={iconOnly ? "" : "mr-1"}
              />
              {iconOnly ? "" : callState === "Active" ? "Connected" : "Calling"}
            </StyledButton>
          )}
        </div>
      )}
    </>
  );
};

ClickToCall.propTypes = {
  prospect: PropTypes.object,
  disabled: PropTypes.bool,
  iconOnly: PropTypes.bool,
};

export default ClickToCall;
