import { useMemo } from "react";
import { useSelector } from "react-redux";
// utils
import { userHasPermission } from "module/common/components/WithPermissions";
import { TEMP_SMS_DISABLE_REASON } from "module/common/helpers/variables";
import { PROSPECT_DETAILS_PUSH_TO_CAMPAIGN_ACTION } from "module/main/permissions/prospectDetails";
import { selectHasActiveMarkets } from "module/main/store/Markets/selectors";
import { IProfile } from "module/main/store/Company/interfaces";

export const usePushToCampaignProps = (profile: IProfile) => {
  const hasActiveMarkets = useSelector(selectHasActiveMarkets);

  const hasPushToCampaignPermission = userHasPermission(
    profile,
    PROSPECT_DETAILS_PUSH_TO_CAMPAIGN_ACTION
  );

  const pushToCampaignProps = useMemo(() => {
    const props = {
      disabled: false,
      tooltipText: "",
    };

    if (!hasActiveMarkets) {
      props.disabled = true;
      props.tooltipText =
        "You must have at least one active market to push to a campaign";
    } else if (!hasPushToCampaignPermission) {
      props.disabled = true;
      props.tooltipText = `Sorry, you don't seem to have permissions to Push to Campaign.
      Please talk with your Administrator to resolve this.`;
    } else if (TEMP_SMS_DISABLE_REASON) {
      props.disabled = true;
      props.tooltipText = "Push to Campaign has been temporarily disabled.";
    }
    return props;
  }, [hasActiveMarkets, hasPushToCampaignPermission]);

  return pushToCampaignProps;
};
