import styled from "styled-components";
import { CSSTransition } from "react-transition-group";

export const SlideLeft = styled(CSSTransition)`
  --timing: 0.2s ease-in-out;
  --left: 0%;
  position: relative;
  overflow: hidden;
  transition: left var(--timing);
  left: var(--left);
  flex: 0 0 100%;
  padding: var(--pad1);

  &.slide {
    &-enter {
      left: 110%;
      &-active {
        left: 110%;
      }
      &-done {
        left: var(--left);
      }
    }
    &-exit {
      left: var(--left);
      &-active {
        left: -110%;
      }
    }
  }
`;
