import React from "react";

// prospect related
export const ProspectCycleContext = React.createContext();
export const ProspectCampaignsContext = React.createContext();

// message-input
export const MessageInputTextAreaContext = React.createContext();

// direct-mail campaign flow
export const CampaignFlowContext = React.createContext();
