import React from "react";
import { useDispatch } from "react-redux";
import { addNewToast } from "../../../main/store/Global/Toasts/actions";
import {
  requiredParams,
  fetchServerErrorMessage,
  max255Characters,
} from "../formFields/FormFieldsRenderer";
import { prospectUpdateOptimistically } from "../../../main/store/prospectStore/thunks";
import { generalNetworkError } from "module/common/helpers/variables";
import FormRenderer from "../FormRenderer";

const fields = [
  [
    {
      name: "firstName",
      label: "First Name",
      component: "input",
      inputProps: {
        placeholder: "First Name",
      },
      rules: {
        ...requiredParams,
        ...max255Characters,
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      component: "input",
      inputProps: {
        placeholder: "Last Name",
      },
      rules: {
        ...requiredParams,
        ...max255Characters,
      },
    },
  ],
];

const ProspectEdit = (props) => {
  const { initialState, closeModal } = props;
  const dispatch = useDispatch();

  const onSubmit = ({ data, setError, setServerError, setSubmittingState }) => {
    let hasError = false;

    const prospectUpdate = dispatch(prospectUpdateOptimistically(initialState.id, data));

    prospectUpdate
      .then(() => {
        dispatch(addNewToast({ message: "Prospect updated", color: "success" }));
        setSubmittingState(false);
        closeModal(hasError);
      })
      .catch(({ response }) => {
        hasError = true;
        if (response) {
          const serverFieldErrors = fetchServerErrorMessage(response);
          setError(serverFieldErrors.fieldErrors || []);
          setServerError(serverFieldErrors.serverErrors);
        } else {
          setServerError([generalNetworkError]);
        }
        setSubmittingState(false);
      });
  };

  return (
    <div data-test="prospect-edit-form">
      <FormRenderer
        fields={fields}
        onSubmit={onSubmit}
        defaultValues={initialState}
        buttonProps={{
          name: "Edit",
          block: false,
          className: "pr-4 pl-4",
          "data-test": "prospect-form-submit",
        }}
      />
    </div>
  );
};
export default ProspectEdit;
