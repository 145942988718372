import React from "react";
import styled from "styled-components/macro";

const StyledSubInfo = styled.span`
  color: var(--sherpaBlue);
  color: ${(props) => (props.status === "Dead" ? "var(--gray)" : "var(--sherpaBlue)")};
`;

export default function SubInfo(props) {
  const defaultStage = props.status || "";

  return <StyledSubInfo status={defaultStage}>{defaultStage}</StyledSubInfo>;
}
