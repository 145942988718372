import React from "react";
import styled from "styled-components/macro";

// components
import UncontrolledToolTip from "module/common/components/UncontrolledToolTip";
// utils
import { formatNumber } from "module/common/helpers/utils";
import { formatErrorMessage } from "module/common/utils/utils";
import { formatDate } from "module/common/helpers/utils";

// store
import { fetchUserInfo } from "module/main/store/Auth/thunks";
import { setCompanyData } from "module/main/store/Company/actions";
import { ICompany } from "module/main/store/Company/interfaces";
import { addErrorToast } from "module/main/store/Global/Toasts/actions";
import { fetchMarkets } from "module/main/store/Markets/thunks";
import { getPhoneNumbersList } from "module/main/store/NumberManagerStore/thunks";
import { EstimateSubscriptionCost } from "module/main/store/SubscriptionStore/interfaces";
import { IPlan } from "module/main/store/SubscriptionStore/interfaces";
import { ActiveProviders } from "module/common/helpers/variables";
import { providersUrls } from "module/main/services/Telephony/utils";

export const Separator = styled.div`
  border-bottom: dotted 2px var(--gray);
`;

interface Description {
  name: React.ReactNode | null;
  isSectionTitle?: boolean;
  value: number | string;
  dataTestId?: string;
  feeClassName?: string;
  tooltipText?: string | null;
}

export const renderFeeSections = (descriptions: Description[][]) => (
  <div className="mb-3">
    <div className="font-weight-bold textL my-2">Transaction Summary</div>
    <hr />
    {descriptions.map((descArr, idx) => (
      <React.Fragment key={idx}>
        {descArr.map((desc) =>
          desc.isSectionTitle ? (
            <React.Fragment key={desc.dataTestId}>{desc.name}</React.Fragment>
          ) : (
            <React.Fragment key={desc.dataTestId}>
              <div
                className="d-flex justify-content-between align-items-end m-2"
                data-testid={desc.dataTestId}
              >
                <div className="textL">
                  <span data-testid="fee-name-section">{desc.name}</span>
                  {desc.tooltipText ? (
                    <UncontrolledToolTip
                      data-testid={`${desc.dataTestId}-tooltip`}
                      id={`${desc.dataTestId}-tooltip`}
                      text={desc.tooltipText}
                      placement="top"
                    />
                  ) : null}
                </div>
                <Separator
                  className="flex-grow-1 mx-2 mb-1"
                  data-testid="fee-separator"
                />
                <div
                  className={`textL ${desc.feeClassName || ""}`}
                  data-testid="fee-section"
                >
                  {formatNumber({ value: desc.value!, isCurrency: true })}
                </div>
              </div>
            </React.Fragment>
          )
        )}
        {idx < descriptions.length - 1 ? <hr /> : null}
      </React.Fragment>
    ))}
  </div>
);

export const callConnectAndSyncTelephony =
  (providerId: ActiveProviders, telephonyId: number, payload: any, company?: ICompany) =>
  async (dispatch: any) => {
    return providersUrls[providerId]
      .connect(telephonyId, payload)
      .then(() => {
        // update company data
        if (company) {
          dispatch(
            setCompanyData({
              ...company,
              hasCompanySpecifiedTelephonyIntegration: true,
            })
          );
        } else {
          dispatch(fetchUserInfo());
        }

        // after syncing we always need to refetch the phone numbers to get the up-to-date numbers
        dispatch(getPhoneNumbersList());

        // also need to refetch markets to get up-to-date markets
        dispatch(fetchMarkets());
      })
      .catch((error) => {
        const defaultMsg =
          "There was an issue syncing the telephone information, please try again later.";
        const errMsg = formatErrorMessage(error, defaultMsg);

        dispatch(addErrorToast(errMsg));
        throw errMsg;
      });
  };

export const subscriptionPlanFeeDescription = (
  currentPlan: IPlan,
  selectedPlan: IPlan,
  fees: EstimateSubscriptionCost,
  hideTransactionInfo: boolean
) => {
  const proratedText = fees.isProrated ? " charge (prorated)" : " charge";
  const diffPercent =
    fees.costTodayWithoutTaxes / (selectedPlan.price - currentPlan.price);
  const proratedCharge = fees.isProrated
    ? selectedPlan.price * diffPercent
    : selectedPlan.price;
  const nextBillingTitle =
    fees.datetimeChange && selectedPlan.id !== "free" ? (
      <p className="font-weight-bold textL my-2" data-testid="billing-date-msg">
        Your next billing date will be {formatDate(fees.datetimeChange)}.
      </p>
    ) : null;
  const feeDescription = [
    [
      {
        name: `Subscription ${proratedText}`,
        value: proratedCharge || "--",
        dataTestId: "today-total-before-tax",
        tooltipText: null,
      },
      ...(fees.isProrated
        ? [
            {
              name: "Credit from current subscription (prorated)",
              value: currentPlan.price * diffPercent || "--",
              dataTestId: "today-prorated-credit",
              tooltipText: null,
            },
            {
              name: "Subscription upgrade cost",
              value: fees.costTodayWithoutTaxes || "--",
              dataTestId: "today-subscription-upgrade",
              tooltipText: null,
            },
          ]
        : []),
      {
        name: "Estimated tax to be charged*",
        value: hideTransactionInfo ? "--" : fees.taxAmount || 0,
        dataTestId: "taxes-total",
        tooltipText: null,
      },
      {
        name: <span className="font-weight-bold">Today's{proratedText}</span>,
        value: hideTransactionInfo ? "--" : fees.costToday,
        dataTestId: "today-total",
        feeClassName: "font-weight-bold",
        tooltipText: null,
      },
    ],
    [
      {
        name: nextBillingTitle,
        dataTestId: "next-billing-date-title",
        isSectionTitle: true,
        value: "",
      },
      {
        name: `${selectedPlan.description} (Plan) price`,
        value: selectedPlan.price,
        dataTestId: "future-sub",
        tooltipText: null,
      },
      {
        name: "Estimated tax to be charged*",
        value: hideTransactionInfo ? "--" : fees.costNextBill - selectedPlan.price,
        dataTestId: "future-taxes",
        tooltipText: null,
      },
      {
        name: <span className="font-weight-bold">Estimated future monthly charge</span>,
        value: hideTransactionInfo ? "--" : fees.costNextBill,
        dataTestId: "future-total",
        feeClassName: "font-weight-bold",
        tooltipText: null,
      },
    ],
  ];
  return feeDescription;
};
