import { ApiFetchStatus } from "module/common/helpers/variables";
import { produce } from "immer";

import {
  FETCH_UPLOAD_PROSPECTS_STATUS,
  FETCH_UPLOAD_PROSPECTS_SUCCESS,
  FETCH_UPLOAD_PROSPECTS_ERROR,
  FETCH_PROP_STACK_UPLOAD_PROSPECTS,
  UPDATE_UPLOADS_TAGS_COUNT,
} from "./actions";

const initialState = {
  status: ApiFetchStatus.Initial,
  error: false,
  campaignProspects: {
    uploads: {},
  },
  propStack: {
    uploads: {},
    next: null,
    previous: null,
    count: null,
  },
};

export const path = ["UploadProspects"];

const reducer = produce((base: any, action: any) => {
  switch (action.type) {
    case FETCH_UPLOAD_PROSPECTS_STATUS: {
      base.status = action.payload.status;
      break;
    }
    case FETCH_UPLOAD_PROSPECTS_SUCCESS: {
      const { uploads, campaignId } = action.payload;

      base.status = ApiFetchStatus.Success;
      if (base.campaignProspects.uploads[campaignId]) {
        const oldUploads = base.campaignProspects.uploads[campaignId];
        const newUploads = [...oldUploads];

        uploads[campaignId].forEach((upload: any) => {
          const index = newUploads.findIndex((u: any) => u.id === upload.id);
          if (index > -1) {
            newUploads[index] = upload;
            return;
          }
          newUploads.push(upload);
        });

        base.campaignProspects.uploads = {
          ...base.campaignProspects.uploads,
          [campaignId]: newUploads,
        };
        return;
      }
      base.campaignProspects.uploads = {
        ...base.campaignProspects.uploads,
        ...uploads,
      };
      break;
    }
    case FETCH_UPLOAD_PROSPECTS_ERROR: {
      base.error = action.payload;
      base.status = ApiFetchStatus.Error;
      break;
    }
    case FETCH_PROP_STACK_UPLOAD_PROSPECTS: {
      const { next, previous, count, uploads } = action.payload;
      base.status = ApiFetchStatus.Success;
      base.propStack.next = next;
      base.propStack.previous = previous;
      base.propStack.count = count;
      base.propStack.uploads = {
        ...base.propStack.uploads,
        ...uploads,
      };
      break;
    }
    case UPDATE_UPLOADS_TAGS_COUNT: {
      base.propStack.uploads[action.payload.id] = {
        ...base.propStack.uploads[action.payload.id],
        uniquePropertyTags: action.payload.uniquePropertyTags,
      };
      break;
    }
  }
}, initialState);

export default reducer;
