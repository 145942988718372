import AxiosInstance from "module/common/utils/axiosConfig";

export const getCampaignBatchProspects = (id: number, smsCategoryId: number) =>
  AxiosInstance.get(`/campaigns/${id}/batch_prospects/?sms_category=${smsCategoryId}`);

interface BatchSendPostData {
  templateId?: number;
  action?: string;
  scribeSherpaMessage?: string;
}
export const postCampaignBatchSend = (id: number, postData: BatchSendPostData) =>
  AxiosInstance.post(`/campaign-prospects/${id}/batch_send/`, postData);
