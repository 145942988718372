import { createAction } from "../../redux-helpers";
import { IModal } from "./interfaces";

export const ADD_MODAL = "ADD_MODAL";
export const addNewModal: {
  (
    payload: IModal,
    meta?: any
  ): {
    [x: string]: any;
    type: any;
    meta: any;
  };
  toString(): any;
} = createAction(ADD_MODAL);

export const REMOVE_MODAL = "REMOVE_MODAL";
export const removeModal = createAction(REMOVE_MODAL);

export const RESET_MODAL_ARRAY = "RESET_MODAL_ARRAY";
export const emptyModalArray = createAction(RESET_MODAL_ARRAY);
