import React from "react";
import styled from "styled-components";
import moment from "moment-timezone";
import { getCompanyData } from "../../../../main/store/Company/selectors";
import { useSelector } from "react-redux";

const StyledIndicator = styled.time`
  color: var(--darkGray);
`;

export default function Indicator(props) {
  const company = useSelector(getCompanyData);

  const getFormattedDateTime = () => {
    const zone = company.timezone;
    const dateFormat = company.appSettings.dateFormat;
    const date = moment.tz(props.time, zone).format(dateFormat || "L");
    const time = moment.tz(props.time, zone).format("LT");
    return [date, time];
  };

  const dateTime = getFormattedDateTime();

  const determineDateOrTime = () => {
    const isSameDay = moment(new Date().toISOString()).isSame(props.time, "day");
    return isSameDay ? dateTime[1] : dateTime[0];
  };

  return <StyledIndicator>{determineDateOrTime()}</StyledIndicator>;
}
