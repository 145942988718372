import React, { useEffect, useMemo, useState } from "react";
import { TabContent, TabPane } from "reactstrap";
import styled, { css } from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

//components
import TabHeaders from "./TabHeaders";
import { ProspectSubActionButtons } from "../ProspectDetails";
import DetailsTab from "../ProspectDetailsPage/DetailsTab/DetailsTab";
import NotesTab from "../NotesTab/NotesTab";
import ActivityTab from "module/campaign/components/ActivityTab";

// store
import * as noteActions from "../../../main/store/ProspectDetails/notes/thunks";
import {
  prospectNotesStatus,
  selectProspectNotes,
} from "../../../main/store/ProspectDetails/notes/selectors";
import { prospectHeaderInfo } from "module/common/helpers/variables";
import * as selectors from "../../../main/store/uiStore/prospectDetailsPageView/selectors";
import * as actions from "../../../main/store/uiStore/prospectDetailsPageView/actions";
import {
  clearActiveCampaign,
  setActiveProspect,
} from "../../../main/store/uiStore/prospectDetailsView/actions";
import { getProspect } from "../../../main/store/prospectStore/selectors";
import { prospectGetCampaigns } from "../../../main/store/prospectStore/api";
import { ProspectCampaignsContext } from "../../../common/helpers/context";

function ProspectDetailsPage(props) {
  const { prospectId } = props;

  const dispatch = useDispatch();
  // prospect campaigns
  const [loadingCampaigns, setLoadingCampaigns] = useState(false);
  const [prospectCampaigns, setProspectCampaigns] = useState([]);

  // selectors
  const uiProspect = useSelector((state) => getProspect(state, prospectId));
  const activeTab = useSelector(selectors.activeTab);
  const notesList = useSelector((_) => selectProspectNotes(_, prospectId));

  const prospect = props.prospect || uiProspect;

  //props
  const notesProps = {
    fetchNotes: noteActions.fetchProspectNotes,
    subject: "prospect",
    subjectId: prospect.id,
    notesList,
    notesStatus: prospectNotesStatus,
    addNote: noteActions.createProspectNote,
    editNote: noteActions.updateProspectNote,
    deleteNote: noteActions.removeProspectNote,
  };

  // fetch campaigns that belong to the prospect we're viewing
  useEffect(() => {
    setLoadingCampaigns(true);
    prospectGetCampaigns(prospectId)
      .then(({ data }) => {
        setProspectCampaigns(data);
        setLoadingCampaigns(false);
      })
      .catch(() => {
        setLoadingCampaigns(false);
      });
  }, [prospectId]);

  useEffect(
    () => () => {
      dispatch(actions.resetProspectActiveTab());
      dispatch(clearActiveCampaign());
      dispatch(setActiveProspect(null));
    },
    [dispatch]
  );

  const toggleTab = (tab) => {
    if (activeTab !== tab) dispatch(actions.setProspectActiveTab(tab));
  };

  const headerInfo = useMemo(() => {
    const {
      // eslint-disable-next-line
      tabs: [details, messages, notes, activity],
    } = prospectHeaderInfo;
    if (props.includeMessagesTab) return [details, messages, notes, activity];
    return [details, notes, activity];
  }, [props.includeMessagesTab]);

  return (
    <div className="pageContent bg-white" data-test="prospect-details-page">
      <ProspectCampaignsContext.Provider
        value={{
          loading: loadingCampaigns,
          prospectCampaigns,
        }}
      >
        <Wrapper>
          <StyledTabHeader>
            <TabHeaders activeTab={activeTab} setActiveTab={toggleTab} data={headerInfo}>
              <ProspectSubActionButtons prospect={prospect} />
            </TabHeaders>
          </StyledTabHeader>
          <StyledTabContent activeTab={activeTab} $includeMessagesTab>
            <TabPane className="mt-3" tabId="1">
              <DetailsTab prospect={prospect} />
            </TabPane>
            {props.includeMessagesTab && (
              <TabPane tabId="2">{props.renderMessagesTab()}</TabPane>
            )}
            <TabPane className="mt-3" tabId="3">
              <NotesTab {...notesProps} />
            </TabPane>
            <TabPane tabId="4">
              <ActivityTab prospect={prospect} />
            </TabPane>
          </StyledTabContent>
        </Wrapper>
      </ProspectCampaignsContext.Provider>
    </div>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  .nav-link {
    color: var(--darkNavy) !important;
  }

  .comingSoon {
    display: none;
  }
`;

const StyledTabContent = styled(TabContent)`
  ${(props) =>
    props.$includeMessagesTab &&
    css`
      margin-top: -1.5rem !important;
    `}
  margin-top: -1.5rem !important;
  padding-left: var(--pad3);
  padding-right: var(--pad4);
  overflow-y: auto;
  flex-basis: 100%;
  background: ${(props) => (props.activeTab === "2" ? "var(--ghostBlue)" : "white")};
`;

const StyledTabHeader = styled.div`
  padding: var(--pad1) var(--pad3) var(--pad2) var(--pad3);
`;

export default ProspectDetailsPage;
