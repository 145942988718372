import { Fetching, FetchError, Success } from "module/common/helpers/variables";

import { IState } from "./interfaces";
import {
  FETCH_QUICK_REPLIES,
  FETCH_PROSPECT_QR_LIST,
  UPDATE_QUICK_REPLIES,
  UPDATE_QUICK_REPLIES_SORT_ORDER,
  REMOVE_QUICK_REPLY,
  HANDLE_API_STATUS,
  HANDLE_API_ERROR,
} from "./actions";

export const initialState: IState = {
  status: Fetching,
  error: "",
  prospectQRList: [],
  quickRepliesList: {},
  quickRepliesListSortOrder: [],
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case FETCH_PROSPECT_QR_LIST:
      return {
        ...state,
        prospectQRList: action.payload,
      };
    case FETCH_QUICK_REPLIES:
      const newQR = { ...state.quickRepliesList, ...action.payload };
      return {
        ...state,
        status: Success,
        quickRepliesList: newQR,
        quickRepliesListSortOrder: Object.values(newQR)
          .sort((x: any, y: any) => x.sortOrder - y.sortOrder)
          .map((qr: any) => qr.id),
      };
    case UPDATE_QUICK_REPLIES:
      return {
        ...state,
        status: Success,
        quickRepliesList: {
          ...state.quickRepliesList,
          [action.payload.data.id]: action.payload.data,
        },
        quickRepliesListSortOrder: action.payload.updateSort
          ? [...state.quickRepliesListSortOrder, action.payload.data.id]
          : state.quickRepliesListSortOrder,
      };
    case UPDATE_QUICK_REPLIES_SORT_ORDER:
      const sortOrder = [...state.quickRepliesListSortOrder].filter(
        (id) => id !== action.payload.id
      );

      sortOrder.splice(action.payload.index, 0, action.payload.id);
      return {
        ...state,
        quickRepliesListSortOrder: sortOrder,
      };
    case REMOVE_QUICK_REPLY:
      const newQuickRepliesList = { ...state.quickRepliesList };
      delete newQuickRepliesList[action.payload];

      return {
        ...state,
        status: Success,
        quickRepliesList: newQuickRepliesList,
        quickRepliesListSortOrder: state.quickRepliesListSortOrder.filter(
          (qrId: number) => qrId !== action.payload
        ),
      };
    case HANDLE_API_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case HANDLE_API_ERROR:
      return {
        ...state,
        error: action.error,
        status: FetchError,
      };
    default:
      return state;
  }
}
