import { produce } from "immer";
import { ApiFetchStatus } from "module/common/helpers/variables";
import { IStats } from "../interfaces";
import { SET_COMPANY_STATS_FETCH_STATUS, FETCH_COMPANY_STATS_SUCCESS } from "./actions";

const initial: IStats = {
  stats: [],
  status: ApiFetchStatus.Initial,
};

const reducer = produce((draft: IStats, action: any) => {
  switch (action.type) {
    case SET_COMPANY_STATS_FETCH_STATUS: {
      draft.status = action.payload;
      break;
    }
    case FETCH_COMPANY_STATS_SUCCESS: {
      draft.stats = action.payload;
      draft.status = ApiFetchStatus.Success;
      break;
    }
  }
}, initial);

export default reducer;
