import {
  fetchProspectQuickReplies,
  fetchQuickRepliesList,
  postQuickReply,
  putQuickReply,
  PutQuickReplyPayload,
  destroyQuickReply,
} from "./api";

import {
  fetchQuickReplies,
  fetchProspectQRList,
  removeQuickReply,
  updateQuickReplies,
  handleApiError,
  handleApiStatus,
} from "./actions";

import {
  Fetching,
  Creating,
  Updating,
  Deleting,
  Success,
} from "module/common/helpers/variables";
import { Dispatch } from "redux";
import { arrayToMapIndex } from "../utils";
import { addNewToast } from "../Global/Toasts/actions";

const handleError = (_: string, error: string, dispatch: Dispatch) => {
  dispatch(handleApiError(error));
};

export const getProspectQR = (id: number) => async (dispatch: Dispatch) => {
  dispatch(handleApiStatus(Fetching));

  return fetchProspectQuickReplies(id)
    .then(({ data }) => {
      dispatch(fetchProspectQRList(data));
    })
    .catch((response) => handleError("Failed to GET Quick Replies", response, dispatch));
};

export const getQuickReplies = () => async (dispatch: Dispatch) => {
  dispatch(handleApiStatus(Fetching));

  return fetchQuickRepliesList()
    .then(({ data }) => {
      dispatch(fetchQuickReplies(arrayToMapIndex("id", data)));
    })
    .catch((response) => handleError("Failed to GET Quick Replies", response, dispatch));
};

export const createQuickReply = (payload: any) => async (dispatch: Dispatch) => {
  dispatch(handleApiStatus(Creating));

  return postQuickReply(payload)
    .then(({ data }) => {
      dispatch(updateQuickReplies({ data, updateSort: true }));
      dispatch(addNewToast({ message: "Quick reply created!", color: "success" }));
    })
    .catch((err) => {
      handleError("Quick Reply POST error", err, dispatch);
      dispatch(
        addNewToast({
          message: `We were unable to add that quick reply. Try again later.`,
          color: "danger",
        })
      );
    });
};

export const editQuickReply =
  (
    id: number,
    payload: PutQuickReplyPayload,
    updateSort: boolean = true,
    updateState: boolean = true
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(handleApiStatus(Updating));

    return putQuickReply(id, payload)
      .then(({ data }) => {
        updateState
          ? dispatch(updateQuickReplies({ data, updateSort }))
          : dispatch(handleApiStatus(Success));
        dispatch(addNewToast({ message: "Quick reply updated.", color: "success" }));
        return data;
      })
      .catch((err) => {
        handleError("Quick Reply PUT", err, dispatch);
        dispatch(
          addNewToast({ message: "Quick reply couldn't be updated.", color: "danger" })
        );
      });
  };

export const deleteQuickReply = (id: number) => async (dispatch: Dispatch) => {
  dispatch(handleApiStatus(Deleting));

  return destroyQuickReply(id)
    .then(() => {
      dispatch(removeQuickReply(id));
      dispatch(addNewToast({ message: "Quick reply deleted.", color: "success" }));
    })
    .catch((err) => {
      handleError("Quick Reply DELETE error", err, dispatch);
      dispatch(
        addNewToast({ message: "Something went wrong try again later", color: "danger" })
      );
    });
};
