import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import { IconButton } from "../../common/components/Buttons";

// redux related
import { prospectUpdateStatus } from "../../main/store/prospectStore/thunks";

export const StatusAction = styled(IconButton)`
  background: ${(props) => {
    let bg = "";
    if (!props.active) {
      bg = "transparent";
    } else {
      bg = `var(--${props["background-color"]}) !important`;
    }
    if (props.status === "loading") {
      bg = "var(--lightGray) !important";
    }
    if (props.disabled) {
      bg = "var(--blueHighlight) !important";
    }
    return bg;
  }};
  color: ${(props) => (!props.active ? "var(--gray)" : "white")};
  border-color: ${(props) => {
    let bc = "";
    if (!props.active) {
      bc = "currentColor";
    } else {
      bc = `var(--${props["background-color"]}) !important`;
    }
    if (props.status === "loading") {
      bc = "var(--gray) !important";
    }
    if (props.disabled) {
      bc = "var(--blueHighlight) !important";
    }
    return bc;
  }};
  &:hover {
    color: ${(props) => (!props.active ? "var(--gray)" : "white")};
    background-color: transparent;
    border-color: var(--gray);
  }
`;

const OPEN = "open";
const VERIFIED = "verified";
const UNVERIFIED = "unverified";

export const getNewVerifiedStatus = (status) => {
  switch (status) {
    case OPEN:
      return VERIFIED;
    case UNVERIFIED:
      return VERIFIED;
    case VERIFIED:
      return UNVERIFIED;
    default:
      return OPEN;
  }
};

export const verifiedStatusValue = (prospect) => {
  const currentValue = prospect["ownerVerifiedStatus"];
  return getNewVerifiedStatus(currentValue);
};

const StatusActionBtns = (props) => {
  const dispatch = useDispatch();

  // onchange status
  const onStatusChange = (item) => {
    const { attr, actions = [] } = item;
    if (attr) {
      const { id } = props.prospect;
      let value = !props.prospect[attr];
      // special case for verified status as it is not a boolean but a string
      if (attr === "ownerVerifiedStatus") {
        const currentValue = props.prospect[attr];
        value = getNewVerifiedStatus(currentValue);
      }
      dispatch(prospectUpdateStatus(id, { [attr]: value }, props.prospect));
    }

    // finally run actions in array
    if (actions.length) {
      actions.forEach((action) => action());
    }
  };

  // render statusActions
  const statusActions = props.statusList.map((item, key) => (
    <StatusAction
      component={Button}
      data-test={`${
        item["data-test"] || item.text?.toLowerCase() + "-status-action-button"
      }`}
      key={key}
      attr={item.attr}
      onClick={() => onStatusChange(item)}
      outline
      background-color={item.color}
      active={item.active}
      className={`fw-black mr-1 mb-1 btn-sm d-inline-flex align-items-center ${props.className}`}
      status={item.status ? "loading" : undefined}
      disabled={item.disabled}
      size="small"
      icon={item.icon}
      text={item.text}
    />
  ));
  return <>{statusActions}</>;
};

StatusActionBtns.propTypes = {
  /** the prospect that is to be updated */
  prospect: PropTypes.object,
  /** additional className styling for the status buttons */
  className: PropTypes.string,
  /** array of status buttons */
  statusList: PropTypes.arrayOf(
    PropTypes.shape({
      /** Label of the status */
      text: PropTypes.string,
      /** color of the status button */
      color: PropTypes.string,
      /** font awesome icon on the status button */
      icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
      /** the prospect attribute value (exception is verified/unverified/open) */
      active: PropTypes.bool,
      /** prospect attribute that needs to be updated */

      attr: PropTypes.string,
      /** the current status of the attr in loading state */
      status: PropTypes.bool,
      /** additional functions that need to be executed at the same time as updating the prospect statuses */
      actions: PropTypes.array,
      /** setting the status button as disabled */
      disabled: PropTypes.bool,
    })
  ),
};

export default StatusActionBtns;
