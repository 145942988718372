import React from "react";
import moment from "moment-timezone";
import { setCookie, getCookie } from "../../../../common/helpers/cookies";
import {
  callRemindersSubPath,
  campaignsSequencesPath,
  smsRemindersSubPath,
} from "module/main/routes";
import { addNewBanner } from "../../Global/Banner/actions";
import { addNewToast } from "../../Global/Toasts/actions";
import {
  fetchCallRemindersSuccess,
  fetchReminders,
  fetchRemindersFailure,
  fetchSmsRemindersSuccess,
  updateReminderSuccess,
} from "./actions";
import { IReminder } from "./interfaces";
import { CALL } from "module/common/helpers/variables";
import {
  getAllReminders,
  getCallReminders,
  getSMSReminders,
  updateReminder,
} from "./api";

import { ParagraphWithBoldTemplate } from "../components/templates";

const REMINDER_COOKIE_NAME = "last_reminder";
const DATE_FORMAT = "YYYY/MM/DD";

export const fetchAllReminders =
  (filter: string = "user") =>
  async (dispatch: any) => {
    dispatch(fetchReminders());
    try {
      const filterRemindersByType = (reminders: IReminder[]) => {
        return reminders.reduce(
          (acc: IReminder[][], current: IReminder) => {
            if (current.step.stepType === CALL) acc[0].push(current);
            else acc[1].push(current);
            return acc;
          },
          [[], []]
        );
      };

      const {
        data: { results: reminders },
      } = await getAllReminders(filter);
      const [callReminders, smsReminders] = filterRemindersByType(reminders);

      dispatch(fetchCallRemindersSuccess(callReminders));
      dispatch(fetchSmsRemindersSuccess(smsReminders));
      dispatch(showReminderBanner(smsReminders.length, callReminders.length));
    } catch (_) {
      dispatch(fetchRemindersFailure());
    }
  };

export const fetchSMSReminders =
  (filter: string = "user") =>
  async (dispatch: any) => {
    dispatch(fetchReminders());
    try {
      const smsReminders = await getSMSReminders(filter);
      dispatch(fetchSmsRemindersSuccess(smsReminders.data.results));
    } catch (_) {
      dispatch(fetchRemindersFailure());
    }
  };

export const fetchCallReminders =
  (filter: string = "user") =>
  async (dispatch: any) => {
    dispatch(fetchReminders());
    try {
      const callReminders = await getCallReminders(filter);
      dispatch(fetchCallRemindersSuccess(callReminders.data.results));
    } catch (_) {
      dispatch(fetchRemindersFailure());
    }
  };

export const updateReminderAction =
  (stepType: string, prospectSequenceStepId: number, type: "finish" | "skip") =>
  async (dispatch: any) => {
    try {
      const response = await updateReminder(prospectSequenceStepId, type);
      dispatch(updateReminderSuccess({ stepType, prospectSequenceStepId }));
      return response;
    } catch (error) {
      dispatch(
        addNewToast({ color: "danger", message: `Error: could not update current step` })
      );
      throw error;
    }
  };

const createReminderBanner =
  (hrefSubPath: string, remindersCount: number) => async (dispatch: any) => {
    setCookie(REMINDER_COOKIE_NAME, moment(new Date()).format(DATE_FORMAT), 1);
    dispatch(
      addNewBanner({
        message: (
          <ParagraphWithBoldTemplate
            textA="You have"
            boldText={`${remindersCount} sequences reminders`}
            textB="to complete today"
          />
        ),
        viewHref: campaignsSequencesPath + hrefSubPath,
        icon: "calendar-plus",
      })
    );
  };

export const showReminderBanner =
  (smsCount: number = 0, callCount: number = 0) =>
  async (dispatch: any) => {
    const totalRemindersCount = smsCount + callCount;

    const lastReminderDate = moment(getCookie(REMINDER_COOKIE_NAME) || "", DATE_FORMAT);

    if (lastReminderDate.isValid() && lastReminderDate.isSameOrAfter(moment(), "day")) {
      return;
    }

    // If there are reminders of both types, it defaults to sms
    if (smsCount) {
      return dispatch(createReminderBanner(smsRemindersSubPath, totalRemindersCount));
    }
    if (callCount) {
      return dispatch(createReminderBanner(callRemindersSubPath, totalRemindersCount));
    }
  };
