import { createAction } from "../redux-helpers";

export const FETCH_SKIP_TRACE_STATUS = "FETCH_SKIP_TRACE_STATUS";
export const SET_FETCH_SKIP_TRACE = "SET_FETCH_SKIP_TRACE";
export const SET_FETCH_SKIP_TRACE_ERROR = "SET_FETCH_SKIP_TRACE_ERROR";
export const RESET_SKIP_TRACE_DATA = "RESET_SKIP_TRACE_DATA";
export const SET_REFETCH_SKIP_TRACE_DATA = "SET_REFETCH_SKIP_TRACE_DATA";
export const SET_SKIP_TRACE_POLLING = "SET_SKIP_TRACE_POLLING";
export const UPDATE_SMS_TEMPLATE = "UPDATE_SMS_TEMPLATE";

// lazy loading more
export const SET_FETCH_SKIP_TRACE_NEXT_PAGE = "SET_FETCH_SKIP_TRACE_NEXT_PAGE";
export const SET_FETCH_SKIP_TRACE_NEXT_PAGE_ERROR =
  "SET_FETCH_SKIP_TRACE_NEXT_PAGE_ERROR";
export const SET_FETCH_SKIP_TRACE_NEXT_PAGE_SUCCESS = "SET_FETCH_SKIP_TRACE_NEXT_SUCCESS";

export const UPDATE_SKIP_TRACE_LIST = "UPDATE_SKIP_TRACE_LIST";

export const SET_RUNNING_TRACES = "SET_RUNNING_TRACES";

export const PAGINATE_SKIP_TRACE_LIST = "PAGINATE_SKIP_TRACE_LIST";

/*************************************************************************************************/

export const updateSkipTraceList = createAction(UPDATE_SKIP_TRACE_LIST);
export const setFetchedSkipTraceStatus = createAction(FETCH_SKIP_TRACE_STATUS);
export const setFetchedSkipTraces = createAction(SET_FETCH_SKIP_TRACE);
export const setRefetchSkipTraceData = createAction(SET_REFETCH_SKIP_TRACE_DATA);
export const setFetchSkipTracesNextPage = createAction(SET_FETCH_SKIP_TRACE_NEXT_PAGE);
export const setFetchSkipTracesNextPageSuccess = createAction(
  SET_FETCH_SKIP_TRACE_NEXT_PAGE_SUCCESS
);
export const setRunningTraces = createAction(SET_RUNNING_TRACES);
export const setSkipTracePolling = createAction(SET_SKIP_TRACE_POLLING);
