import { useEffect, useRef } from "react";

type HookType = () => [boolean, React.MutableRefObject<boolean>];

const useMountedRef: HookType = () => {
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  // Sending back the boolean doesn't work
  return [mountedRef.current, mountedRef];
};

export default useMountedRef;
