import React, { useState } from "react";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomPopover from "./components/CustomPopover";
import CustomUncontrolledPopover from "./components/CustomUncontrolledPopover";

const DesktopKebab = ({ idx, disabled = false, children, type = "controlled" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = (e) => {
    setIsOpen(!isOpen);
    e.stopPropagation();
  };

  const kebabId = `kebab${idx}`;

  const sharedPopoverProps = {
    trigger: "legacy",
    placement: "bottom",
    target: kebabId,
  };

  return (
    <KebabWrap
      data-testid={kebabId}
      id={kebabId}
      onClick={disabled ? null : toggle}
      tabindex="0"
      role="button"
      disabled={disabled}
    >
      <FontAwesomeIcon icon={["fal", "ellipsis-v"]} size="2x" />
      {type === "controlled" ? (
        <CustomPopover
          {...sharedPopoverProps}
          isOpen={isOpen}
          disabled={disabled}
          toggle={toggle}
        >
          {children}
        </CustomPopover>
      ) : (
        <CustomUncontrolledPopover {...sharedPopoverProps}>
          {children}
        </CustomUncontrolledPopover>
      )}
    </KebabWrap>
  );
};

export const KebabWrap = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--pad1);
  transition:
    background-color 0.2s,
    color 0.2s;
  z-index: 2;
  color: ${(props) => (props.disabled ? "var(--mediumGray)" : "black")} !important;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover,
  &:focus {
    background: ${(props) => (props.disabled ? "none" : "var(--ghostBlue)")};
  }
  &:active {
    color: ${(props) => (props.disabled ? null : "var(--sherpaBlue)")} !important;
  }
`;

export default React.memo(DesktopKebab);
