import React from "react";
import PropTypes from "prop-types";
import ListItem from "./ListItem";
import ListHeader from "./ListHeader";
import SwipeableListItem from "./SwipeableList/SwipeableListItem";
import useDraggable from "../../hooks/useDraggable";
import { isMobile } from "../../../../detectDevice";

function List(props) {
  const [items, draggedItem, onDragStart, onDragOver, onDragEnd] = useDraggable(
    props.items,
    props.onDragSort,
    props.accordion
  );

  const getItem = (item, idx) => {
    const isSelected = item.id === props.selectedItem;
    let itm = (
      <ListItem
        beingDragged={draggedItem === item}
        onDragOver={() => onDragOver(idx)}
        onDragStart={(e) => {
          onDragStart(e, idx);
          if (props.onDragStart) {
            props.onDragStart(item, idx);
          }
        }}
        onDragEnd={(e) => {
          onDragEnd(e);
          if (props.onDragItemEnd) {
            props.onDragItemEnd(item);
          }
        }}
        isSelected={isSelected}
        hasSidebar={props.hasSidebar}
        key={item.id}
        item={item}
      />
    );

    // NOTE: Should not care if we're in mobile or desktop
    if (isMobile && item.actions && item.actions.length > 0) {
      itm = (
        <SwipeableListItem
          isSelected={isSelected}
          threshold=".25"
          actions={item.actions}
          key={`swipable-item-${itm.key}`}
        >
          <ListItem item={item} />
        </SwipeableListItem>
      );
    }

    return itm;
  };

  return (
    <div
      id="list-component"
      className={
        props.hasSidebar
          ? `text-left hasSidebar ${props.className}`
          : `text-left ${props.className}`
      }
    >
      {props.header ? (
        <ListHeader
          id={props.header.id}
          draggableList={props.header.draggableList}
          hasKebab={props.header.hasKebab}
          body={props.header.body}
        />
      ) : null}
      {props.children || items.map((item, idx) => getItem(item, idx))}
    </div>
  );
}

List.propTypes = {
  header: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.any),
  onDragSort: PropTypes.func,
  hasSidebar: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDragEnd: PropTypes.func,
};

List.defaultProps = {
  hasSidebar: false,
};

export default List;
