import { produce } from "immer";

import {
  SET_SMART_STACKER_MULTI_SELECT_TYPE,
  RESET_SMART_STACKER_MULTI_SELECT,
  SET_SMART_STACKER_SELECTED_ITEM,
  REMOVE_SMART_STACKER_SELECTED_ITEM,
  SET_SMART_STACKER_ROWS_SELECTED,
} from "./actions";

const state = {
  multiSelectType: "",
  rowsSelected: [],
  range: {
    size: null,
    // offset: 0,
  },
  selectedItems: [],
  excludedItems: [],
};

const reducer = produce((base: any, action: any) => {
  switch (action.type) {
    case SET_SMART_STACKER_SELECTED_ITEM: {
      const values = base.excludedItems.includes(action.payload);
      if (values) {
        const newArray = base.excludedItems.filter((e: number) => e !== action.payload);
        base.excludedItems = newArray;
      }

      if (
        base.multiSelectType === "" ||
        (base.multiSelectType === "rowsSelected" && !values)
      ) {
        base.selectedItems = [...base.selectedItems, action.payload];
      }
      break;
    }
    case REMOVE_SMART_STACKER_SELECTED_ITEM: {
      if (
        base.multiSelectType === "all" ||
        (base.multiSelectType === "rowsSelected" &&
          !base.selectedItems.includes(action.payload))
      ) {
        base.excludedItems = [...base.excludedItems, action.payload];
      }
      const newArray = base.selectedItems.filter((e: number) => e !== action.payload);

      base.selectedItems = newArray;
      break;
    }
    case SET_SMART_STACKER_MULTI_SELECT_TYPE: {
      base.multiSelectType = action.payload;
      base.selectedItems = [];
      base.excludedItems = [];
      break;
    }
    case RESET_SMART_STACKER_MULTI_SELECT: {
      base.selectedItems = [];
      base.excludedItems = [];
      base.multiSelectType = "";
      base.rowsSelected = [];
      base.range = {
        size: null,
      };
      break;
    }
    case SET_SMART_STACKER_ROWS_SELECTED: {
      const total = Number.parseInt(action.payload.total);

      base.multiSelectType = "rowsSelected";
      base.rowsSelected = total;
      base.range.size = total;
      break;
    }
  }
}, state);

export default reducer;
