import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "module/main/store/hooks";
import moment from "moment-timezone";
// components
import { UncontrolledTooltip } from "reactstrap";
import Message from "./components/Message";
import MessageInput from "./components/MessageInput";
import CallOptions from "./components/CallOptions";
import DataLoader from "../../../common/components/DataLoader";
import WithPermissions from "../../../common/components/WithPermissions";

// utils
import useMountedRef from "module/common/hooks/useMountedRef";
import {
  Fetching,
  Success,
  generalNetworkError,
  messagesPlaceholderText,
  pollingInterval,
} from "module/common/helpers/variables";
import {
  Activity,
  Divider,
  MessagesWrapper,
  StyledList,
  Placeholder,
  InputWrapper,
  StyledNote,
} from "./styles";

//store
import { IReminder } from "module/main/store/SequencesStore/reminders/interfaces";
import { IProspectNote } from "module/main/store/ProspectDetails/notes/interfaces";
import { IActivity } from "module/main/store/ProspectDetails/activities/interfaces";
import { IMessage } from "module/main/store/ProspectDetails/messages/interface";
import { prospectSendMessage } from "module/main/store/prospectStore/thunks";
import { addNewToast } from "module/main/store/Global/Toasts/actions";
import { setFetchMessages } from "module/main/store/prospectStore/actions";
import { markAllMessagesAsRead } from "module/main/services/Prospect";
import { removeCampaignProspect } from "module/main/store/campaignProspectStore/thunks";
import { patchMessage } from "module/main/store/prospectStore/api";
import { updateProspectMessage } from "module/main/store/ProspectDetails/messages/actions";
import { PROSPECT_DETAILS_SEND_MESSAGE_ACTION } from "../../../main/permissions/prospectDetails";
import {
  prospectFetchSingle,
  callGetMessages,
} from "module/main/store/prospectStore/thunks";
import { getFetchingMessages } from "module/main/store/prospectStore/selectors";
import { getCompanyData } from "module/main/store/Company/selectors";

type IMsgFeed = IMessage &
  IActivity &
  IProspectNote & {
    category: string;
    date: moment.Moment;
    createdDate: moment.Moment;
  };

interface Props {
  messages: IMsgFeed[];
  prospectId: number;
  disableMessage: boolean;
  sherpaPhoneNumber: string;
  phoneRaw: string;
  fromProspectColor: string;
  scrollToBot: boolean;
  isSequence: boolean;
  selectedReminder: IReminder;
  toggleModal: () => void;
  optedOut: boolean;
}

const MessagesTab: React.FC<Props> = ({
  messages,
  prospectId,
  disableMessage,
  sherpaPhoneNumber,
  phoneRaw,
  fromProspectColor,
  scrollToBot,
  isSequence,
  selectedReminder,
  toggleModal,
  optedOut,
}) => {
  const dispatch = useAppDispatch();
  // selectors
  const { timezone } = useSelector(getCompanyData);
  const isFetchingMessages = useSelector(getFetchingMessages);
  // state
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [isLoadingSms, setLoadingSms] = useState(false);
  // misc
  const [isMounted] = useMountedRef();
  const prospectIdRef = useRef<number>();

  useEffect(() => {
    setHasUnreadMessages(messages.some((message) => message.unreadByRecipient));
  }, [messages]);

  // deprecated by action that needs to update optimistically
  const updateMessages = (idx: number, value: boolean) => {
    dispatch(updateProspectMessage({ ...messages[idx], unreadByRecipient: value }));
  };

  const updateMessage = (id: number) => () => {
    const messageIdx = messages.findIndex((m) => m.id === id);
    const hasOneUnreadMessage =
      messages.filter((message) => message.unreadByRecipient).length === 1;
    if (messageIdx) {
      // optimistic update
      updateMessages(messageIdx, false);
      if (hasOneUnreadMessage) {
        // remove campaign prospect from list
        dispatch(removeCampaignProspect(Number(prospectId), hasOneUnreadMessage));
      }
      patchMessage(id).catch(() => {
        updateMessages(messageIdx, true);
        dispatch(addNewToast({ message: generalNetworkError, color: "danger" }));
      });
    }
  };

  const renderFeed = (item: IMsgFeed, idx: number) => {
    switch (item.category) {
      case "activity":
        return (
          <li
            key={idx}
            className="d-flex mb-3 justify-content-between align-items-center"
          >
            <Divider data-test="divider" />
            <Activity data-test="activity-item">
              {item.description} {` ${item.date.calendar(moment.tz(moment(), timezone))}`}{" "}
            </Activity>
            <Divider />
          </li>
        );
      case "note":
        return (
          <li
            key={idx}
            className="d-flex mb-3 justify-content-between align-items-center"
          >
            <Divider data-test="divider" />
            <Activity data-test="activity-item">
              {item.createdBy.fullName} Added a{" "}
              <StyledNote id={`tooltip-id-${item.id}`} data-test="activity-note">
                Note
              </StyledNote>{" "}
              {item.createdDate.calendar(moment.tz(moment(), timezone))}
              <UncontrolledTooltip target={`tooltip-id-${item.id}`} fade={false}>
                {item.text}
              </UncontrolledTooltip>
            </Activity>
            <Divider />
          </li>
        );
      default:
        const canUpdateSMS = item.unreadByRecipient && !isFetchingMessages;
        return (
          <Message
            key={idx}
            {...item}
            fromProspectColor={fromProspectColor}
            phoneRaw={phoneRaw}
            sherpaPhoneNumber={sherpaPhoneNumber}
            onClick={canUpdateSMS ? updateMessage(item.id) : null}
          />
        );
    }
  };

  const mapMessages = () => {
    if (messages.length)
      return [...messages].reverse().map((msg, idx) => renderFeed(msg, idx));
    return <Placeholder className="textXL">{messagesPlaceholderText}</Placeholder>;
  };

  const messagesRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const dataLoaderRef = useRef<HTMLDivElement>(null);

  const tabContent: any = document.getElementsByClassName("tab-content");
  if (messagesRef.current) tabContent.scrollTop = messagesRef.current.scrollHeight;

  const scrollToNewMessageCB = useCallback(() => {
    const { current } = messagesRef;
    if (current) {
      tabContent[0].scrollTo({
        top: current.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
      dataLoaderRef.current!.scrollTo({
        top: dataLoaderRef.current!.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line
  }, [messagesRef]);

  const fetchNewMessages = async () => {
    prospectIdRef.current = prospectId;
    setLoadingSms(true);
    await dispatch(callGetMessages(prospectId)).finally(() => {
      if (!isMounted) return;
      if (prospectIdRef.current === prospectId) setLoadingSms(false);
    });
  };

  const addNewMessage = async (message: string) => {
    dispatch(setFetchMessages(true));
    return dispatch(prospectSendMessage(prospectId, { message }))
      .then(async () => {
        if (!sherpaPhoneNumber) {
          dispatch(prospectFetchSingle(prospectId));
        }
        if (hasUnreadMessages) {
          await markAllMessagesAsRead(prospectId);
          await fetchNewMessages();
          dispatch(removeCampaignProspect(Number(prospectId), hasUnreadMessages));
        } else {
          fetchNewMessages();
        }
      })
      .catch((error: any) => {
        throw error;
      })
      .finally(() => dispatch(setFetchMessages(false)));
  };

  useEffect(() => {
    if (prospectId) {
      fetchNewMessages();
    }
    const interval = setInterval(fetchNewMessages, pollingInterval);

    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, [prospectId]);

  // scrolls to new message after added
  useEffect(() => {
    scrollToNewMessageCB();
  }, [messages.length, scrollToNewMessageCB]);

  // scrolls bot on tab active
  useEffect(() => {
    const { current } = messagesRef;

    if (current) {
      tabContent[0].scrollTop = current.scrollHeight;
    }
  }, [scrollToBot, tabContent]);

  useEffect(() => {
    const { current } = messagesRef;

    if (current) {
      dataLoaderRef.current!.scrollTop = current.scrollHeight;
    }
  }, [messagesRef]);

  return (
    <MessagesWrapper data-test="messages-tab" className="messagesTab">
      <DataLoader
        status={!messages.length && isLoadingSms ? Fetching : Success}
        data={messages}
        emptyResultsMessage={messagesPlaceholderText}
        innerRef={dataLoaderRef}
        renderData={() => (
          <StyledList className="messageList" data-test="message-list" ref={messagesRef}>
            {mapMessages()}
          </StyledList>
        )}
      />
      {isSequence ? (
        <WithPermissions checkRole permission={PROSPECT_DETAILS_SEND_MESSAGE_ACTION}>
          <InputWrapper ref={inputRef}>
            <CallOptions
              currentReminder={selectedReminder}
              toggleSmSReplyModal={toggleModal}
            />
          </InputWrapper>
        </WithPermissions>
      ) : (
        <WithPermissions checkRole permission={PROSPECT_DETAILS_SEND_MESSAGE_ACTION}>
          <InputWrapper ref={inputRef} data-test="input-wrapper">
            <MessageInput
              prospectId={prospectId}
              addNewMessage={addNewMessage}
              disableMessage={disableMessage}
              optedOut={optedOut}
              prospect={selectedReminder?.prospect}
            />
          </InputWrapper>
        </WithPermissions>
      )}
    </MessagesWrapper>
  );
};

export default MessagesTab;
