import React from "react";
import PropTypes from "prop-types";
import { Modal as reModal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, TabContent, TabPane } from "reactstrap";
import LoadingButton from "../Buttons/LoadingButton";
import TabbedHeader from "../TabbedHeader";

// styled-components
import { ButtonsContainer } from "assets/styles/sc/layout";

const StyledHeader = styled(ModalHeader)`
  background: ${(props) => props.$styles && props.$styles.background};
  color: ${(props) =>
    props.$styles && props.$styles.color ? props.$styles.color : "var(--white)"};
  padding-left: var(--pad4);
  padding-right: var(--pad4);
  font-weight: 400 !important;
  ${(props) => props.$styles.padding && `padding: ${props.$styles.padding}`};
  .close {
    color: ${(props) =>
      props.$styles && props.$styles.color ? props.$styles.color : "var(--white)"};
    opacity: 1;
    font-weight: 900;
    width: max-content;
  }
  .modal-title {
    width: 100%;
  }
`;

const StyledBody = styled(ModalBody)`
  padding: var(--pad4) var(--pad5) var(--pad5) var(--pad5);
  margin-bottom: var(--pad5) !important;
  ${(props) => props.$disableOverflow && "overflow-y: hidden !important;"}
`;

const StyledModal = styled(reModal)`
  bottom: 0;
  position: absolute !important;
  width: 100% !important;
  margin: auto !important;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%) !important;

  .modal-body {
    max-height: 80vh;
    overflow-y: ${(props) => (props.overflow ? "visible" : "auto")};
    overflow-x: ${(props) => (props.overflow ? "visible" : "hidden")};
    padding-bottom: 0 !important;
    margin-bottom: var(--pad2);
  }

  @media (max-width: 992px) {
    &:not(.modal-md, .modal-sm) {
      max-width: 80% !important;
    }
  }

  [class*="-menu"] {
    margin-bottom: 1rem;
  }
`;

function Modal(props) {
  const {
    children,
    title,
    isOpen,
    toggle,
    showClose = true,
    size,
    actions = [],
    btnOptions = [],
    footer = null,
    footerClassName = "",
    btnOptionsClassName = "",
    overflow,
    type,
    headerStyles,
    headerClassName = "",
    tabData,
    modalClassName,
    disableOverflow,
    autoFocus,
  } = props;
  const closeBtn = (
    <div className="width d-flex align-items-center justify-content-right">
      {actions.map((action, idx) =>
        !action.node ? (
          <button key={idx} className="close" onClick={action.onClick}>
            {action.icon ? <FontAwesomeIcon icon={action.icon} /> : action.node}
          </button>
        ) : (
          <div key={idx} className="close">
            {action.node}
          </div>
        )
      )}
      {showClose ? (
        <button className="close" onClick={toggle} data-test="modal-close">
          &times;
        </button>
      ) : null}
    </div>
  );

  return (
    <StyledModal
      size={size}
      isOpen={isOpen}
      data-testid={props.dataTestId}
      className={modalClassName}
      overflow={overflow ? "true" : ""}
      autoFocus={autoFocus}
    >
      {tabData ? (
        <TabbedHeader
          background={headerStyles.background}
          className={headerClassName}
          color={headerStyles.color}
          border={headerStyles.border}
          toggleTab={tabData.toggle}
          activeTab={tabData.activeTab}
          data={tabData.data}
        >
          {title}
        </TabbedHeader>
      ) : (
        <StyledHeader
          $styles={headerStyles}
          className={headerClassName}
          tag={typeof title === "string" ? "h3" : "div"}
          toggle={toggle}
          close={closeBtn}
        >
          {title}
        </StyledHeader>
      )}
      <StyledBody $disableOverflow={disableOverflow}>
        {type !== "submit" && type !== "confirmation" && (
          <div className="d-flex justify-content-center align-items-center mb-2">
            {type === "error" && (
              <FontAwesomeIcon icon="times-circle" size="2x" color="var(--danger)" />
            )}
            {type === "warning" && (
              <FontAwesomeIcon
                icon="exclamation-triangle"
                size="2x"
                color="var(--warning)"
              />
            )}
            {type === "success" && (
              <FontAwesomeIcon icon="check-circle" size="2x" color="var(--success)" />
            )}
          </div>
        )}
        {type === "tabbed" && (
          <TabContent activeTab={tabData.activeTab}>
            {tabData.tabs.map((tab, idx) => {
              return (
                <TabPane key={idx} tabId={tab.id}>
                  {tab.content}
                </TabPane>
              );
            })}
          </TabContent>
        )}
        {children}
        {btnOptions.length > 0 ? (
          <ButtonsContainer
            className={`${btnOptionsClassName} mt-3`}
            position={type === "submit" || type === "tabbed" ? "right" : "center"}
          >
            {btnOptions.map((props, idx) => {
              return props.hasOwnProperty("loading") ? (
                <LoadingButton size="md" key={idx} {...props}>
                  {" "}
                  {props.name}{" "}
                </LoadingButton>
              ) : (
                <Button size="md" key={idx} {...props}>
                  {props.name}
                </Button>
              );
            })}
          </ButtonsContainer>
        ) : null}
      </StyledBody>
      {footer && <ModalFooter className={footerClassName}>{footer}</ModalFooter>}
    </StyledModal>
  );
}

Modal.propTypes = {
  id: PropTypes.string,
  /** Content that renders in the body of the modal */
  children: PropTypes.node,
  /** Title of the modal set in the header */
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Sets the modal open or closed */
  isOpen: PropTypes.bool,
  /** Function to open/close the modal */
  toggle: PropTypes.func,
  /** Allows for overflow in the body*/
  overflow: PropTypes.bool,
  /** corresponds to bootstrap's modal sizes, ie. 'lg' or 'sm' */
  size: PropTypes.string,
  /** custom modal class name if needed ie width or height etc*/
  modalClassName: PropTypes.string,
  /** list of action buttons or icons on the header */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      node: PropTypes.node,
      icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    })
  ),
  /** Used for setting the test attribute */
  dataTestId: PropTypes.string,
  /** list of buttons that can appear on the footer of the modal. When setting the list of buttons, the order is from left to right */
  btnOptions: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      loading: PropTypes.bool,
      disabled: PropTypes.bool,
      form: PropTypes.string,
      "data-test": PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      color: PropTypes.string,
      type: PropTypes.string,
      className: PropTypes.string,
      size: PropTypes.string,
    })
  ),
  btnOptionsClassName: PropTypes.string,
  footer: PropTypes.node,
  footerClassName: PropTypes.string,
  /** Determines the type of modal and style of the modal */
  type: PropTypes.oneOf([
    "confirmation",
    "success",
    "error",
    "submit",
    "warning",
    "tabbed",
  ]),
  /** Tab object for setting up tabbed modal */
  tabData: PropTypes.shape({
    tabs: PropTypes.array,
    toggle: PropTypes.func,
    activeTab: PropTypes.any,
    data: PropTypes.object,
  }),
  /** Custom styles that can be added to the header */
  headerStyles: PropTypes.shape({
    background: PropTypes.string,
    color: PropTypes.string,
    /** used with tab header to specify a bottom tab nav border */
    border: PropTypes.string,
  }),
  /** show the x for closing the modal */
  showClose: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

Modal.defaultProps = {
  size: "xl",
  type: "submit",
  headerStyles: {
    background: "var(--tealBlueGradientFlip)",
  },
};

export default Modal;
