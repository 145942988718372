import Datetime from "react-datetime";
import styled, { css } from "styled-components/macro";

export const StyledMultipleDates = styled(Datetime)`
  .rdtPicker {
    font-size: 12px !important;
    min-width: ${(props) => (props.minWidth ? `${props.minWidth} !important` : "")};
  }
  .rdtPicker th,
  .rdtPicker td {
    height: 3rem !important;
  }

  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 20px !important;
    color: var(--sherpaBlue);
  }
  .rdtPicker td.rdtDay:hover {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  ${(props) =>
    props.disableddates &&
    css`
      .rdtPicker td.rdtOld,
      .rdtPicker td.rdtNew {
        color: initial;
      }

      .rdtPicker td.rdtOld,
      .rdtPicker td.rdtDisabled {
        color: #999999 !important;
      }
    `}
`;

export const StyledCalendarAndDatesContainer = styled.div`
  display: flex;
  margin-right: auto;
  margin-left: auto;
`;

export const StyledSelection = styled.div`
  padding-top: var(--pad1);
`;

export const StyledSelectionOption = styled.div`
  background-color: ${(props) => (props.active ? "var(--darkNavy)" : "")};
  cursor: pointer;
  color: ${(props) => (props.active ? "var(--white)" : "var(--darkNavy)")};
  font-size: 12px;
  padding-right: var(--pad9);
  padding-left: var(--pad1);
  padding-top: var(--pad1);
  padding-bottom: var(--pad1);
`;

export const StyledDay = styled.td`
  ${(props) =>
    props.selected
      ? css`
          background-color: var(--sherpaBlue) !important;
          color: var(--white) !important;
          border-radius: 30px;
        `
      : null}
`;

export const StyledDateDisplay = styled.div`
  border-bottom: solid 2px var(--mediumGray);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledDatePickContainer = styled.div`
  width: ${(props) => (props.multipleSelect ? "initial" : "100%")};
`;

export const StyledDateContainer = styled.div`
  flex: 0 0 70%;
`;

export const StyledApplyBtnContainer = styled.div`
  flex: 0 0 20%;
`;
