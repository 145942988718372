export enum UPLOAD_TYPES {
  DNC = 0,
  REMOVE_DNC = 1,
}

export interface IUpload {
  id: string;
  type: UPLOAD_TYPES;
}

export interface IState {
  data: Array<IUpload>;
}
