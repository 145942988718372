import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./module/main/App";
import "./assets/styles/css/main.css";
import { Provider } from "react-redux";
import ReduxStore from "./module/main/store/store";
import { getPublicRequest } from "module/common/utils/publicAxios";
import { globalDynamicConfig, dynamicConfigUrl, defaultConfig } from "dynamicConfig";
import { setAuthTokenHeader } from "./module/main/store/Auth/utils";
import ErrorBoundary from "./module/main/components/ErrorBoundary";
import * as Sentry from "@sentry/browser";
import { ExtraErrorData } from "@sentry/integrations";
import smoothscroll from "smoothscroll-polyfill";
import {
  objectValuesPolyfill,
  objectEntriesPolyfill,
  promiseAllSettledPolyfill,
} from "manualPolyfills";
import "react-datetime/css/react-datetime.css";
import axiosInstance from "module/common/utils/axiosConfig";
import { neutralAxiosInstance } from "module/main/services/crmIntegrations";

getPublicRequest(`/${dynamicConfigUrl}?d=${new Date().getTime()}`)
  .then(({ data }) => {
    // Reset the global dynamic config, else use the default config
    // Spreading the defaultConfig again here, else we get a getter error
    if (data) globalDynamicConfig.config = { ...defaultConfig, ...data };

    const {
      REACT_APP_DYNAMIC_REPORT_ERRORS,
      REACT_APP_DYNAMIC_BASE_URL,
      REACT_APP_DYNAMIC_ENV,
      REACT_APP_DYNAMIC_SENTRY_RELEASE,
    } = globalDynamicConfig.config;

    axiosInstance.defaults.baseURL = REACT_APP_DYNAMIC_BASE_URL;
    neutralAxiosInstance.defaults.baseURL = REACT_APP_DYNAMIC_BASE_URL;

    // polyfill to allow smooth scrolling to work on all browsers that don't support "scrollBehavior"
    smoothscroll.polyfill();
    // misc polyfill
    objectValuesPolyfill();
    objectEntriesPolyfill();
    promiseAllSettledPolyfill();

    if (REACT_APP_DYNAMIC_REPORT_ERRORS === "true" && REACT_APP_DYNAMIC_SENTRY_RELEASE) {
      // Release version should match webpack's config release version of SentryWebpackPlugin
      const release = `leadsherpa-frontend@${REACT_APP_DYNAMIC_SENTRY_RELEASE}`;
      // eslint-disable-next-line no-console
      console.log("Release version: ", release);
      Sentry.init({
        dsn: "https://74967ad691f2032bdaf2b9f3a7b3eafa@sentry.skipsherpa.com/4",
        release,
        environment: REACT_APP_DYNAMIC_ENV,
        enableTracing: true,
        integrations: [new ExtraErrorData()],
        // decluttering sentry based on: https://docs.sentry.io/platforms/javascript/#decluttering-sentry
        ignoreErrors: [
          // Random plugins/extensions
          "top.GLOBALS",
          // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
          "originalCreateNotification",
          "canvas.contentDocument",
          "ResizeObserver loop limit exceeded",
          // this will be captured as custom warning
          "Non-Error promise rejection captured",
          /Loading chunk \d+ failed/,
        ],
        denyUrls: [
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
        ],
        beforeSend(event, hint) {
          const originalException = hint.originalException || {};

          // only send the even to sentry if it's an actual error in our code to fix
          // also check to see if it's an axios error before sending the event - an axios error can include
          // Network Error / 0ms timeout
          if (
            (!hint.syntheticException && Object.keys(originalException).length === 0) ||
            (Object.keys(originalException).length !== 0 &&
              originalException.isAxiosError)
          ) {
            return null;
          }
          return event;
        },
      });
    }

    setAuthTokenHeader();

    ReactDOM.render(
      <ErrorBoundary>
        <Provider store={ReduxStore}>
          <App />
        </Provider>
      </ErrorBoundary>,
      document.getElementById("root")
    );
  })
  .catch((err) => {
    // If we can get the dynamic config, hard failure.
    const ErrChild = () => {
      throw err;
    };

    ReactDOM.render(
      <ErrorBoundary>
        <ErrChild />
      </ErrorBoundary>,
      document.getElementById("root")
    );
  });
