import {
  getTemplateCategories,
  getTemplateCategory,
  postTemplateCategory,
  patchTemplateCategory,
  patchTemplateCategorySortOrder,
  deleteTemplateCategory,
  getScribeSherpaMessagesCategories,
} from "./api";

import {
  fetchCategories,
  fetchCategoriesSuccess,
  updateCategories,
  fetchCategoriesError,
  removeCategory,
  setScribeSherpaCategories,
} from "./actions";

import {
  Fetching,
  Creating,
  Updating,
  Deleting,
  Success,
} from "module/common/helpers/variables";
import { Dispatch } from "redux";
import { arrayToMapIndex } from "../utils";
import { addErrorToast, addNewToast } from "../Global/Toasts/actions";

const handleError = (
  message: string,
  error: string,
  type: string,
  dispatch: Dispatch
) => {
  dispatch(fetchCategoriesError(error));
  dispatch(
    addNewToast({
      message: `We were unable to ${type}. Try again later.`,
      color: "danger",
    })
  );
};

export const fetchTemplateCategories = () => async (dispatch: Dispatch) => {
  dispatch(fetchCategories(Fetching));

  return getTemplateCategories()
    .then(({ data }) => {
      const indexedCategories = arrayToMapIndex("id", data);

      dispatch(fetchCategoriesSuccess({ categories: indexedCategories }));
    })
    .catch((err) => handleError("GET ERROR", err, "fetch your categories", dispatch));
};

export const fetchScribeSherpaCategories = () => async (dispatch: Dispatch) => {
  dispatch(fetchCategories(Fetching));

  return getScribeSherpaMessagesCategories()
    .then(({ data }) => {
      dispatch(setScribeSherpaCategories(data));
    })
    .catch((err) => {
      dispatch(fetchCategoriesError(err));
      dispatch(addErrorToast("Error fetching your categories"));
    });
};

export const fetchTemplateCategory = (id: number) => async (dispatch: Dispatch) => {
  dispatch(fetchCategories(Fetching));

  return getTemplateCategory(id)
    .then(({ data }) => {
      dispatch(fetchCategories(Success));
      return data;
    })
    .catch((err) => {
      handleError("GET ERROR", err, "fetch that category", dispatch);
    });
};

export const createTemplateCategory = (payload: any) => async (dispatch: Dispatch) => {
  dispatch(fetchCategories(Creating));

  return postTemplateCategory(payload)
    .then(({ data }) => {
      dispatch(updateCategories({ data, id: data.id }));
      dispatch(addNewToast({ message: "Category Created!", color: "success" }));
    })
    .catch((err) => {
      handleError("POST ERROR", err, "create that category", dispatch);
    });
};

export const createTemplateCategoryOptimistically = (payload: any) => async () => {
  return postTemplateCategory(payload);
};

export const editTemplateCategory =
  (id: number, payload: any) => async (dispatch: Dispatch) => {
    dispatch(fetchCategories(Updating));

    return patchTemplateCategory(id, payload)
      .then(({ data }) => {
        dispatch(updateCategories({ data, id: data.id }));
        dispatch(addNewToast({ message: "Category Updated!", color: "success" }));
      })
      .catch((err) => {
        handleError("PATCH ERROR", err, "edit that category", dispatch);
      });
  };

export const editCategorySortOrder =
  (id: number, payload: any) => async (dispatch: Dispatch) => {
    return patchTemplateCategorySortOrder(id, payload).catch((err) => {
      handleError("PATCH ERROR", err, "sort that category", dispatch);
    });
  };

export const removeTemplateCategoryThunk = (id: number) => async (dispatch: Dispatch) => {
  dispatch(fetchCategories(Deleting));

  return deleteTemplateCategory(id)
    .then(() => {
      dispatch(removeCategory({ id }));
      dispatch(addNewToast({ message: "Category Deleted!", color: "success" }));
    })
    .catch((err) => {
      handleError("DELETE ERROR", err, "delete that category", dispatch);
    });
};
