import React from "react";
import styled, { css } from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wrapper = styled.div`
  --size: 1.5rem;
  position: relative;
  font-size: var(--size);

  ${(props) =>
    props.theme.isDesktop
      ? css`
          --size: ${(props) => (props.showCount ? "1.65em" : "1.3em")};
          font-size: inherit;
          display: inline-block;
        `
      : null}

  .count-badge {
    transform: translate(35%, -35%);
    ${(props) =>
      props.theme.isDesktop
        ? css`
            transform: ${(props) =>
              props.showCount ? "translate(65%,-55%)" : "translate(45%,-50%)"};
          `
        : null}
  }
`;

export const Count = styled.div.attrs(() => ({
  className: "count-badge",
}))`
  background: ${(props) => (props.color ? props.color : "var(--red)")};
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  font-size: calc(var(--size) * 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-align: center;
  font-weight: 900 !important;
  position: absolute;
  top: 0;
  right: 0;
  color: white;
`;

const UnreadMsgIcon = (props) => {
  const { size = "lg", className, showCount, icon = "comment-dots" } = props;
  return (
    <Wrapper className={className} showCount={showCount}>
      <FontAwesomeIcon icon={icon} size={size} />
      {props.count ? (
        <Count showCount={showCount}>{showCount && props.count}</Count>
      ) : null}
    </Wrapper>
  );
};

export default UnreadMsgIcon;
