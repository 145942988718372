import React, { memo } from "react";
import styled, { css } from "styled-components/macro";
import { isMobile } from "../../../../detectDevice";

import { ListGroupItem, ListGroupItemText } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import IconHolster from "./IconHolster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DragIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  margin-right: var(--pad2);
  ${(props) =>
    props.theme.isDesktop
      ? css`
          flex-basis: 1rem;
        `
      : null}
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

const StyledItem = styled(ListGroupItem)`
  padding: 0 !important;
  width: 100%;
  height: auto !important;
  border: none !important;
  position: relative;

  ${(props) =>
    props.theme.isDesktop
      ? css`
          background: transparent !important;
          margin-bottom: ${(props) => (props.spacingSize ? props.spacingSize : "5px")};
          border-bottom: ${(props) =>
            props.$beingDragged
              ? "3px solid var(--sherpaBlue) !important"
              : "none !important"};
          border-radius: 4px !important;
          transform: ${(props) =>
            props.$beingDragged ? "scale(1.015)" : "scale(1) !important"};
          transition:
            transform 0.3s,
            box-shadow 0.3s;
          box-shadow: ${(props) =>
            props.$beingDragged ? "0 0 15px -8px rgba(0,0,0,.3)" : "initial"};

          & > div {
            background: white !important;
            border-radius: 4px !important;
            border: none;
            border-left: none !important;
            border-right: none !important;
          }
        `
      : css`
          background: white !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
          border-radius: 0 !important;
        `}
  ${(props) =>
    props.draggable
      ? css`
          &:hover {
            div {
              background-color: var(--tint) !important;
            }
            ${DragIcon} {
              visibility: visible;
            }
          }
        `
      : css`
          &:hover > div {
            background-color: ${(props) =>
              props.islink || props.onClick ? "var(--tint)" : "white"} !important;
            cursor: ${(props) =>
              props.islink || props.onClick ? "pointer" : "auto"} !important;
          }
        `}
`;

const bars = (
  <DragIcon>
    <span className="pb-1">
      <FontAwesomeIcon icon={["far", "bars"]} color="var(--darkGray)" />
    </span>
  </DragIcon>
);

const SubInfo = styled.div`
  color: var(--darkGray);
`;

const MainInfo = styled(ListGroupItemText)`
  line-height: 1.4 !important;
  margin: 0;
  margin-top: var(--pad1) !important;
`;

const ItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ItemName = styled.p`
  font-weight: 400;
  padding-bottom: 0.1875rem;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 50vw;
  overflow: hidden;
  ${(props) =>
    props.theme.isDesktop
      ? css`
          max-width: 40vw;
          width: 100%;
        `
      : null}
`;
const MobileStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  flex: 1 0 auto;
  justify-content: flex-end;
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: var(--pad2) var(--pad3);
  min-height: ${(props) => (props.minHeight ? props.minHeight : "4rem")};
  cursor: ${(props) => (props.draggable ? "grab" : "initial")} !important;

  .itemBody {
    flex: ${(props) => (props.hasSidebar ? 1 : 4)} 1 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding-right: 1rem;
  }

  .desktopCallouts {
    flex-grow: ${(props) => (props.hasSidebar ? 4 : 2)};

    .callout {
      flex: ${(props) => (props.hasSidebar ? 1 : null)};
      align-items: flex-start;
    }
  }
  &.selected {
    box-shadow: 0 0 15px -10px #525252;
  }
  &.selected:before {
    content: "";
    width: 10px;
    height: 100%;
    background-color: var(--sherpaBlue);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px 0px 0px 5px !important;
  }

  ${(props) =>
    props.theme.isDesktop
      ? css`
          padding: var(--pad1) var(--pad2);
        `
      : null}
`;

const StyledMainContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 2;
  flex-basis: 50%;
  > * {
    display: flex block;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-basis: ${(props) => (props.columns ? `${100 / props.columns}%` : "auto")};
    max-width: ${(props) => (props.columns ? `${100 / props.columns}%` : "auto")};
    padding-right: var(--pad1);
  }
`;

export const renderMainContent = (data) => {
  return data.map((value, index) => {
    if (value === null) {
      return null;
    }
    const { className = "", valueClassName = "" } = value;

    return (
      <div key={index} className={className}>
        <div className={`${value.value ? `ellipsis textM ${valueClassName}` : "d-none"}`}>
          {value.icon && <FontAwesomeIcon className="mr-1" {...value.icon} />}
          {value.value}
        </div>
        <div className={`${value.label ? "abel mb-0 textM darkGray" : "d-none"}`}>
          {value.label}
        </div>
        {value.node}
      </div>
    );
  });
};
function ListItem(props) {
  return (
    <>
      <StyledItem
        $beingDragged={props.beingDragged}
        onDragOver={props.onDragOver}
        islink={props.item.link}
        style={props.style}
        data-test="list-item"
        $spacingSize={props.spacingSize}
        onClick={props.item.onClick}
        draggable={props.item.draggable}
      >
        <ItemContent
          draggable={props.item.draggable}
          onDragStart={props.onDragStart}
          onDragEnd={props.onDragEnd}
          className={`${props.isSelected ? "selected" : ""} ${props.item.adjust || ""}`}
          data-test="list-item-content"
          hasSidebar={props.hasSidebar}
          id={props.id}
          minHeight={props.item.minHeight}
        >
          {/** children props can be used for the inner part of the item content */}
          {props.children ? (
            props.children
          ) : (
            <>
              {props.item.draggable ? bars : null}
              <IconHolster center={props.item.iconCenter}>{props.item.icon}</IconHolster>
              <div
                className={`${
                  props.item.name || props.item.subInfo || props.item.mainInfo
                    ? "itemBody"
                    : "d-none"
                }`}
              >
                <ItemHeader data-test="list-item-header">
                  {typeof props.item.name === "string" ? (
                    <ItemName className={`${isMobile ? "textXL" : "textL"} itemName m-0`}>
                      {props.item.name}
                    </ItemName>
                  ) : (
                    <div className={`${isMobile ? "textXL" : ""}`}>{props.item.name}</div>
                  )}
                  <MobileStatusWrapper className="d-flex">
                    {props.item.statusWrapper}
                  </MobileStatusWrapper>
                </ItemHeader>
                {props.item.subInfo && (
                  <SubInfo
                    data-test="list-item-sub-info"
                    className={`${isMobile ? "textXL" : "textM m-0"}`}
                  >
                    {props.item.subInfo}
                  </SubInfo>
                )}
                {props.item.mainInfo && (
                  <MainInfo
                    data-test="list-item-main-info"
                    className={`${isMobile ? "textXL" : "textL"}`}
                  >
                    {props.item.mainInfo}
                  </MainInfo>
                )}
              </div>
            </>
          )}
          {props.item.mainContent &&
            ((Array.isArray(props.item.mainContent) && (
              <StyledMainContent columns={props.item.mainContent.length}>
                {renderMainContent(props.item.mainContent)}
              </StyledMainContent>
            )) ||
              props.item.mainContent)}
          {props.item.activeAttr ? props.item.activeAttr : null}
          {props.item.desktopKebab ? props.item.desktopKebab : null}
          {props.item.link && <Link to={props.item.link} className="stretched-link" />}
        </ItemContent>
      </StyledItem>
    </>
  );
}

ListItem.propTypes = {
  item: PropTypes.shape({
    /** set the draggable flag to indicate that the list item can be dragged */
    draggable: PropTypes.bool,
    /** the route redirect when the item row is clicked */
    link: PropTypes.string,
    /** the container that holds the icon(s) set on the far left of the item row... TODO: make it dynamic?*/
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
    /** the name of the item row */
    name: PropTypes.node,
    /** To wrap the different special styling for mobile lists */
    statusWrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** The sub info of the item row */
    subInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** the main info of the item row  */
    mainInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** the clickable kebab with a list of actions on the far right of the item row*/
    desktopKebab: PropTypes.node,
    /** Function to call when the item row is clicked */
    onClick: PropTypes.func,
    /** Minimum line height */
    minHeight: PropTypes.string,

    /** The main content of the item row that dynamic divides the content based on the array size */
    mainContent: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          /** Label of the value */
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
          /** the icon associated with the value */
          icon: PropTypes.any,
          /** the value */
          value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
          /** option node to display rather than the label/value */
          node: PropTypes.node,
        })
      ),
      PropTypes.node,
    ]),
    /** flag that checks if the item row is being dragged */
    beingDragged: PropTypes.bool,
    /** position the icons either in the center from the top */
    iconCenter: PropTypes.bool,
    indicator: PropTypes.node,
    /** String of adjustment to be appended to className for bootstrap utility */
    adjust: PropTypes.string,
  }),
  /** Existing browser api set on the item row */
  onDragOver: PropTypes.func,
  /** Existing browser api set on the item row */
  onDragStart: PropTypes.func,
  /** Existing browser api set on the item row */
  onDragEnd: PropTypes.func,
  /** additional style on the item row */
  style: PropTypes.object,
  /** the space between each row set in em, rem or px*/
  spacingSize: PropTypes.string,
  /** flag that determines of the item row is being selected */
  isSelected: PropTypes.bool,
  /** flag that determines if the sidebar is present */
  hasSidebar: PropTypes.bool,
  /** the id of the item row */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** content that can be used for the inner part of the item content */
  children: PropTypes.node,
  /** Array of actions that appear on the swipeable list item for mobile */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      name: PropTypes.string,
      link: PropTypes.func,
      background: PropTypes.string,
    })
  ),
};

ListItem.defaultProps = {
  spacingSize: "var(--pad2)",
  name: null,
  subInfo: null,
  mainInfo: null,
  mainContent: [],
  icon: null,
  link: null,
  iconCenter: false,
  indicator: null,
  isRead: false,
  readable: false,
  actions: [],
  item: {
    adjust: "",
  },
};

/** Workaround so the props can be displayed in the storybook docs */
export const ListItemComponent = ListItem;
export default memo(ListItem);
