import React from "react";
import FreshDeskWidget from "./FreshDeskWidget";
import WootricWidget from "./WootricWidget";
import BeamerWidget from "./BeamerWidget";
import { AppEnv } from "../helpers/variables";
import { globalDynamicConfig } from "dynamicConfig";

function WidgetContainer() {
  const isProduction =
    globalDynamicConfig.config.REACT_APP_DYNAMIC_ENV === AppEnv.Production; // checks for actual production rather than the production build

  if (!isProduction) return null;

  return (
    <>
      <FreshDeskWidget />
      <BeamerWidget />
      <WootricWidget />
    </>
  );
}

export default WidgetContainer;
