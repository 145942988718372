import {
  FETCH_SKIP_TRACE_STATUS,
  SET_FETCH_SKIP_TRACE,
  SET_FETCH_SKIP_TRACE_ERROR,
  RESET_SKIP_TRACE_DATA,
  SET_REFETCH_SKIP_TRACE_DATA,
  SET_SKIP_TRACE_POLLING,
  SET_RUNNING_TRACES,
  UPDATE_SMS_TEMPLATE,
  SET_FETCH_SKIP_TRACE_NEXT_PAGE,
  SET_FETCH_SKIP_TRACE_NEXT_PAGE_SUCCESS,
  PAGINATE_SKIP_TRACE_LIST,
  UPDATE_SKIP_TRACE_LIST,
} from "./actions";
import { IState } from "./interfaces";
import { Fetching, Success, FetchError } from "module/common/helpers/variables";
import { produce } from "immer";

// skipTraces reducer
export const initialState: IState = {
  isPolling: false,
  refetch: false,
  activeMarket: null,
  sortOrder: [],
  skipTraces: {},
  running: [],
  error: "",
  next: "",
  previous: "",
  count: 0,
  status: Success,
  isLoadingMore: false,
};

export const path = ["skipTraces"];
// NOTE: Add immer
const reducer = produce((draft: IState, action: any) => {
  // export default function reducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case SET_SKIP_TRACE_POLLING: {
      draft.isPolling = payload;
      break;
    }
    case FETCH_SKIP_TRACE_STATUS: {
      draft.status = Fetching;
      draft.refetch = false;
      break;
    }
    case SET_FETCH_SKIP_TRACE: {
      draft.next = payload.next;
      draft.previous = payload.previous;
      draft.sortOrder = payload.sortOrder;
      draft.skipTraces = {
        ...draft.skipTraces,
        ...payload.skipTraces,
      };
      draft.status = Success;
      draft.refetch = false;
      break;
    }

    case PAGINATE_SKIP_TRACE_LIST: {
      Object.assign(draft, { ...payload });
      draft.skipTraces = { ...draft.skipTraces, ...payload.skipTraces };
      draft.sortOrder = [...draft.sortOrder, ...payload.sortOrder];
      draft.status = Success;
      break;
    }
    case SET_FETCH_SKIP_TRACE_ERROR: {
      draft.error = action.error;
      draft.status = FetchError;
      break;
    }
    case SET_RUNNING_TRACES: {
      draft.running = payload;
      break;
    }
    case UPDATE_SMS_TEMPLATE: {
      const skipTracesToUpdate = { ...draft.skipTraces, [payload.id]: payload };

      draft.skipTraces = skipTracesToUpdate;
      draft.status = Success;
      break;
    }
    case SET_REFETCH_SKIP_TRACE_DATA: {
      draft.refetch = true;
      break;
    }
    case RESET_SKIP_TRACE_DATA: {
      Object.assign(draft, initialState);
      break;
    }
    case SET_FETCH_SKIP_TRACE_NEXT_PAGE: {
      draft.isLoadingMore = payload;
      break;
    }
    case SET_FETCH_SKIP_TRACE_NEXT_PAGE_SUCCESS: {
      draft.skipTraces = { ...draft.skipTraces, ...payload.skipTrace };
      draft.isLoadingMore = false;
      draft.next = payload.next;
      break;
    }
    case UPDATE_SKIP_TRACE_LIST: {
      draft.skipTraces = {
        ...draft.skipTraces,
        [payload.id]: payload,
      };
    }
  }
}, initialState);

export default reducer;
