import { createSelectorContext } from "../redux-helpers";

const createSelector = createSelectorContext(["calls"]);

export const getAudioRef = createSelector("audioRef");
export const getCallSession = createSelector("session");
export const getIsRegistered = createSelector("isRegistered");
export const getCall = createSelector("call");
export const getCallState = createSelector("callState");
export const getIsActive = createSelector("isActive");
export const getCallStartTime = createSelector("callStartTime");
export const getCallProspectData = createSelector("prospectData");
