import { createAction } from "../../redux-helpers";

export const FETCH_PROSPECT_MESSAGES_LIST_SUCCESS =
  "FETCH_PROSPECT_MESSAGES_LIST_SUCCESS";
export const populateProspectMessages = createAction(
  FETCH_PROSPECT_MESSAGES_LIST_SUCCESS
);
export const FETCH_PROSPECT_MESSAGES_LIST_ERROR = "FETCH_PROSPECT_MESSAGES_LIST_ERROR";
export const setProspectMessagesError = createAction(FETCH_PROSPECT_MESSAGES_LIST_ERROR);

export const SET_PROSPECT_MESSAGES_LIST_STATUS = "SET_PROSPECT_LIST_MESSAGES_STATUS";
export const setProspectMessagesStatus = createAction(SET_PROSPECT_MESSAGES_LIST_STATUS);

export const UPDATE_PROSPECT_MESSAGE = "UPDATE_PROSPECT_MESSAGE";
export const updateProspectMessage = createAction(UPDATE_PROSPECT_MESSAGE);

export const UPDATE_PROSPECT_MESSAGES = "UPDATE_PROSPECT_MESSAGES";
export const updateProspectMessages = createAction(UPDATE_PROSPECT_MESSAGES);

export const REMOVE_UNREAD_MESSAGES = "REMOVE_UNREAD_MESSAGES";
export const removeUnreadMessages_ = createAction(REMOVE_UNREAD_MESSAGES);
