import { produce } from "immer";

// actions
import { SET_INTEGRATION_TIME } from "./actions";

// interfaces
import { IState } from "./interfaces";

const initialState: IState = {
  integrationTime: {},
};

const reducer = produce((base: IState, action: any) => {
  switch (action.type) {
    case SET_INTEGRATION_TIME: {
      if (!base.integrationTime[action.payload.id])
        base.integrationTime[action.payload.id] = action.payload.integrationTime;
      break;
    }
  }
}, initialState);

export default reducer;
