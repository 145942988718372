import { produce } from "immer";
import { ApiFetchStatus } from "module/common/helpers/variables";

import {
  SET_PHONE_NUMBERS_STATUS,
  FETCH_PHONE_NUMBERS_SUCCESS,
  FETCH_PHONE_NUMBERS_ERROR,
  UPDATE_PHONE_NUMBER,
} from "./actions";

import { IState, IPhoneNumber, IAction, IPhoneNumberMap } from "./interfaces";

const initialState: IState = {
  numbers: {},
  status: ApiFetchStatus.Initial,
  error: false,
  isPhoneManagerFiltersModalOpen: false,
};

export const path = ["numberManagerStore"];

const reducer = produce((draft: IState, action: IAction) => {
  switch (action.type) {
    case SET_PHONE_NUMBERS_STATUS:
      draft.status = action.payload as ApiFetchStatus;
      break;
    case FETCH_PHONE_NUMBERS_SUCCESS:
      draft.numbers = action.payload as IPhoneNumberMap;
      break;
    case FETCH_PHONE_NUMBERS_ERROR:
      draft.error = action.payload as boolean;
      break;
    case UPDATE_PHONE_NUMBER: {
      const id: number = (action.payload as IPhoneNumber).id;
      draft.numbers[id] = { ...draft.numbers[id], ...(action.payload as IPhoneNumber) };
      break;
    }
  }
}, initialState);
export default reducer;
