import * as actions from "./actions";
import * as campaignActions from "../../Campaigns/actions";
import { combineReducers } from "redux";
import { campaignSortingOptions } from "module/common/helpers/variables";

export const path = ["uiStore", "campaignsPageView"];

const activeFilterReducer = (state: any[] = [], action: any) => {
  switch (action.type) {
    case actions.SET_CAMPAIGN_FILTER:
      return [action.payload];
    case actions.RESET_CAMPAIGN_FILTER:
      return [];
    default:
      return state;
  }
};

const campaignInitState = {
  campaigns: [],
  nextPage: null,
};
const campaignsReducer = (state: any = campaignInitState, action: any) => {
  switch (action.type) {
    case actions.SET_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload.campaigns,
        nextPage: action.payload.next,
      };
    case actions.SET_MORE_CAMPAIGNS:
      return {
        ...state,
        campaigns: [...state.campaigns, ...action.payload.sortOrder],
        nextPage: action.payload.next,
      };
    case actions.RESET_CAMPAIGNS:
      return campaignInitState;
    case campaignActions.ARCHIVE_CAMPAIGN: {
      return {
        ...state,
        campaigns: state.campaigns.filter((id: number) => id !== action.payload.id),
      };
    }
    default:
      return state;
  }
};

const sortByReducer = (state: any = campaignSortingOptions[0], action: any) => {
  switch (action.type) {
    case actions.SET_SORTING_OPTION:
      return action.payload;
    case actions.RESET_SORTING_OPTION:
      return campaignSortingOptions[0];
    default:
      return state;
  }
};

const activeMarketReducer = (state: any = null, action: any) => {
  switch (action.type) {
    case actions.SET_ACTIVE_MARKET:
      return action.payload;
    default:
      return state;
  }
};

const reducer = combineReducers({
  activeFilter: activeFilterReducer,
  activeMarket: activeMarketReducer,
  sortBy: sortByReducer,
  campaigns: campaignsReducer,
});

export default reducer;
