import { rootPath } from "./reducer";
import { createSelector as createCachedSelector } from "reselect";
import { createSelectorContext } from "../redux-helpers";
import { ICompany, IFeatureNotifications } from "./interfaces";

const createSelector = createSelectorContext(rootPath);

export const getCompanyData = createSelector("company");
export const selectCompanyId = createSelector(["company", "id"]);

export const getFeatureNotificationsSelector = createSelector([
  "company",
  "featureNotifications",
]);

export const getDateFormat = createSelector(["company", "appSettings", "dateFormat"]);

export const getCompanyBrandRegistrationStatus = createSelector([
  "company",
  "isRegistered10DLC",
]);

export const selectCompanyBrandStatus = createSelector(["company", "brandStatus"]);
export const selectCompanyTempBrandStatus = createSelector([
  "company",
  "tempBrandStatus",
]);
export const selectCompanyOptInOptions = createSelector(["company", "optInOptions"]);
export const selectCompanyLatestSubscriptionRequestId = createSelector([
  "company",
  "latestSmsSubscriptionRequestId",
]);

export const getFeatureNotifications = createCachedSelector(
  getFeatureNotificationsSelector,
  (notifications: IFeatureNotifications) => notifications
);

export const getCompanyNames = createCachedSelector(
  getCompanyData,
  (company: ICompany) => company.outgoingCompanyNames
);

export const selectCompanyPodioStatus = createCachedSelector(
  getCompanyData,
  (company: ICompany) => company.integrations.podio
);

export const selectCompanyBalance = createCachedSelector(
  getCompanyData,
  (company: ICompany) => company.availableBalance
);

export const selectCompanyPlan = createCachedSelector(
  getCompanyData,
  (company: ICompany) => company.plan
);
