import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// components
import Hoverable from "../../../../../common/components/Hoverable";

const QuickRepliesListItem = ({ data, onEdit, onClick }) => {
  return (
    <Hoverable key={data.id}>
      {({ hovering, setIsHovering }) => (
        <li
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          className="textM btn text-left btn-block no-br d-flex justify-content-between"
          id={data.id}
          onClick={() => onClick(data)}
          data-test="quick-replies-list-item"
        >
          <span>
            {data.isInvalid ? (
              <FontAwesomeIcon icon={["fal", "exclamation-circle"]} color="red" />
            ) : null}{" "}
            {data.question}
          </span>
          {hovering ? (
            <span>
              <FontAwesomeIcon icon="pencil-alt" data-test="edit-icon" onClick={onEdit} />
            </span>
          ) : null}
        </li>
      )}
    </Hoverable>
  );
};

export default QuickRepliesListItem;
