import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sparkleIcon from "assets/images/icons/Sparkle.svg";

// hooks
import useInterval from "../../../common/hooks/useInterval";
// Create the keyframes
const glitter = keyframes`
  0% {
    -webkit-transform: scale(1.0);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1.0);
    opacity: 1;
  }
  75% {
    -webkit-transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  --size: 1rem;
  position: relative;
  font-size: var(--size);
  display: inline-block;
`;

const StyledNewSparkle = styled.img`
  width: ${(props) => (props.position === "middle" ? "var(--size)" : "0.5rem")};
  height: ${(props) => (props.position === "middle" ? "var(--size)" : "0.5rem")};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: var(--size);
  text-align: center;
  font-weight: 900 !important;
  position: absolute;
  top: ${(props) =>
    props.position === "middle"
      ? "-0.5rem"
      : props.position === "top-right"
        ? "-0.7rem"
        : "0.3rem"};
  left: ${(props) => (props.position === "middle" ? "1.75rem" : "2.75rem")};
  color: var(--warning) !important;
  opacity: 1 !important;
  ${(props) =>
    props.glitter
      ? css`
          -webkit-animation: ${glitter}
            ${(props) =>
              props.position === "middle"
                ? "3s"
                : props.position === "top-right"
                  ? "2s"
                  : "1s"}
            linear 0s infinite normal;
          -moz-animation: ${glitter}
            ${(props) =>
              props.position === "middle"
                ? "3s"
                : props.position === "top-right"
                  ? "2s"
                  : "1s"}
            linear 0s infinite normal;
          -ms-animation: ${glitter}
            ${(props) =>
              props.position === "middle"
                ? "3s"
                : props.position === "top-right"
                  ? "2s"
                  : "1s"}
            linear 0s infinite normal;
          -o-animation: ${glitter}
            ${(props) =>
              props.position === "middle"
                ? "3s"
                : props.position === "top-right"
                  ? "2s"
                  : "1s"}
            linear 0s infinite normal;
          animation: ${glitter}
            ${(props) =>
              props.position === "middle"
                ? "3s"
                : props.position === "top-right"
                  ? "2s"
                  : "1s"}
            linear 0s infinite normal;
        `
      : css``}
`;

const NewNavIcon = (props) => {
  const { size = "lg", className, icon } = props;
  const [glitter, setGlitter] = useState(true);
  const [interval, setInterval] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setGlitter(false);
    }, 3000);
    setInterval(60000);
  }, []);

  useInterval(() => {
    setGlitter(true);
    setTimeout(() => {
      setGlitter(false);
    }, 3000);
  }, interval);

  const sparkleProps = {
    src: sparkleIcon,
    name: "Sparkle",
    glitter: glitter,
  };

  return (
    <Wrapper className={className}>
      <FontAwesomeIcon icon={icon} size={size} />
      <StyledNewSparkle position="top-right" {...sparkleProps} />
      <StyledNewSparkle position="bottom-right" {...sparkleProps} />
      <StyledNewSparkle position="middle" {...sparkleProps} />
    </Wrapper>
  );
};

export default NewNavIcon;
