// fetching
export const FETCH_CAMPAIGN_PROSPECTS = "FETCH_CAMPAIGN_PROSPECTS";
export const FETCH_CAMPAIGN_PROSPECTS_FAILURE = "FETCH_CAMPAIGN_PROSPECTS_FAILURE";
export const FETCH_CAMPAIGN_PROSPECTS_SUCCESS = "FETCH_CAMPAIGN_PROSPECTS_SUCCESS";
export const FETCH_MORE_CAMPAIGN_PROSPECTS = "FETCH_MORE_CAMPAIGN_PROSPECTS";

// updating campaign-prospects by concatenation
export const UPDATE_CAMPAIGN_PROSPECT_LIST = "UPDATE_CAMPAIGN_PROSPECT_LIST";

// sending messages
export const CAMPAIGN_PROSPECTS_BATCH_SEND = "CAMPAIGN_PROSPECTS_BATCH_SEND";

// updating single campaign-prospect
export const UPDATE_CAMPAIGN_PROSPECT = "UPDATE_CAMPAIGN_PROSPECT";
export const UPDATE_CAMPAIGN_PROSPECT_SUCCESS = "UPDATE_CAMPAIGN_PROSPECT_SUCCESS";
export const UPDATE_CAMPAIGN_PROSPECT_FAILURE = "UPDATE_CAMPAIGN_PROSPECT_FAILURE";

// fetching campaign prospects unread
export const FETCH_CAMPAIGN_PROSPECTS_UNREAD = "FETCH_CAMPAIGN_PROSPECTS_UNREAD";
export const FETCH_CAMPAIGN_PROSPECTS_UNREAD_FAILURE =
  "FETCH_CAMPAIGN_PROSPECTS_UNREAD_FAILURE";
export const FETCH_CAMPAIGN_PROSPECTS_UNREAD_SUCCESS =
  "FETCH_CAMPAIGN_PROSPECTS_UNREAD_SUCCESS";
export const UPDATE_CAMPAIGN_PROSPECTS_UNREAD = "UPDATE_CAMPAIGN_PROSPECTS_UNREAD";
export const UPDATE_CAMPAIGN_PROSPECTS_UNREAD_COUNT =
  "UPDATE_CAMPAIGN_PROSPECTS_UNREAD_COUNT";
export const UPDATE_CAMPAIGN_PROSPECTS_UNREAD_REMINDERS =
  "UPDATE_CAMPAIGN_PROSPECTS_UNREAD_REMINDERS";

// delete
export const REMOVE_CAMPAIGN_PROSPECT_UNREAD = "REMOVE_CAMPAIGN_PROSPECT_UNREAD";

// pagination
export const FETCH_CAMPAIGN_NEXT_PAGE = "FETCH_CAMPAIGN_NEXT_PAGE";

export const UPDATE_CAMPAIGN_LIST = "UPDATE_CAMPAIGN_LIST";

export const SET_UNREAD_MESSAGES_FETCH_STATUS = "SET_UNREAD_MESSAGES_FETCH_STATUS";
