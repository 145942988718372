import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { NavbarBrand, NavItem, NavLink } from "reactstrap";
// components
import DesktopActiveCall from "module/common/components/ActiveCall/DesktopActiveCall";
import WithPermissions from "../../../../common/components/WithPermissions";
import CustomNavLink from "../CustomNavLink";
import { SlideIn, StyledNavItem } from "../../../components/styles";
import {
  ArrowBtnHolster,
  SpecialNav,
  LogoSlide,
  LogoutBtn,
  LogoutNav,
  StyledNav,
  StyledNavbar,
} from "./styles";
import CompanyBalance from "./components/CompanyBalance";
// utils
import { desktopRoutes } from "../../../routes";
import { UncontrolledTooltip } from "reactstrap";
import { logout } from "../../../store/Auth/thunks";
import { getIsActive } from "../../../store/Calls/selectors";
import { getCampaignProspectsUnreadCount } from "../../../store/campaignProspectStore/selectors";
import { hasRemindersPending } from "../../../store/SequencesStore/reminders/selectors";
import { setDesktopCampaignActiveTab } from "../../../store/uiStore/campaignsPageDesktopView/actions";
import { desktopCampaignActiveTab } from "../../../store/uiStore/campaignsPageDesktopView/reducers";
import { renderSpecialLogo } from "./logoUtils";

const textClass = "navText align-items-center textL pl-3";
const linkClass = "navLink textL stretched-link w-100 d-flex";

const getActiveClass = (pathname, routePath) => {
  let activeClass;

  // special case for campaigns
  if (pathname.includes("/campaign")) {
    activeClass = routePath.includes("/campaigns") ? "active" : "";
  } else if (pathname === routePath) {
    activeClass = "active";
  }

  return activeClass;
};

const NavbarDesktop = (props) => {
  const dispatch = useDispatch();
  // selectors
  const desktopCampaignTab = useSelector(desktopCampaignActiveTab);
  const count = useSelector(getCampaignProspectsUnreadCount);
  const pendingReminders = useSelector(hasRemindersPending);
  const isCallActive = useSelector(getIsActive);
  // state
  const [collapse, setCollapse] = useState(false);
  const toggleNavbar = () => setCollapse(!collapse);

  const routes = useMemo(
    () =>
      desktopRoutes.map((routes, idx) => {
        if (!routes.navIcon) return null;
        // another special case where if the user has access to property stacker,
        const activeClass = getActiveClass(props.history.location.pathname, routes.path);
        const linkProps = routes.href
          ? { tag: "a", href: routes.href, target: "_blank", rel: "noopener noreferrer" }
          : { tag: Link, to: routes.path };
        const tooltipTarget = `tooltip-${routes.name.replace(/\s/g, "-")}`;

        return (
          <WithPermissions key={idx} name={routes.name} {...routes.permissionProps}>
            <StyledNavItem
              data-testid={`desktop-nav-${routes.name}`}
              className={activeClass}
              onClick={() => {
                setCollapse(false);

                // call any other `on-click` effects that routes define
                const { onClick = () => null } = routes;
                onClick();
              }}
              id={tooltipTarget}
            >
              <NavLink className={linkClass} {...linkProps}>
                {typeof routes.navIcon === "string" || Array.isArray(routes.navIcon) ? (
                  <FontAwesomeIcon icon={routes.navIcon} size="lg" />
                ) : (
                  routes.navIcon
                )}
                <SlideIn
                  in={collapse}
                  timeout={300}
                  appear
                  className={textClass}
                  classNames="slide"
                  unmountOnExit
                >
                  <span>{routes.name}</span>
                </SlideIn>
              </NavLink>
              {!collapse ? (
                <UncontrolledTooltip
                  target={tooltipTarget}
                  placement="right"
                  data-testid={`${routes.name}-tooltip`}
                >
                  {routes.name}
                </UncontrolledTooltip>
              ) : null}
            </StyledNavItem>
          </WithPermissions>
        );
      }),
    [desktopCampaignTab, props.history.location.pathname, collapse]
  );

  const AllUnreadActiveClass = getActiveClass(
    props.history.location.pathname,
    "/all-unread"
  );

  const CampaignsActiveClass = getActiveClass(
    props.history.location.pathname,
    "/campaigns/all"
  );

  return (
    <>
      <StyledNavbar
        $isOpen={collapse}
        color="dark"
        dark
        expand={false}
        data-test="navbar"
      >
        <NavbarBrand tag="div" data-test="navbar-brand">
          <Link to="/">
            <LogoSlide
              in={collapse}
              timeout={300}
              appear
              width="150px"
              classNames="slide"
            >
              {renderSpecialLogo({ type: "logo" })}
            </LogoSlide>
            <LogoSlide
              in={!collapse}
              timeout={300}
              appear
              width="35px"
              classNames="slide"
            >
              {renderSpecialLogo({ type: "icon", isOpen: collapse })}
            </LogoSlide>
          </Link>
        </NavbarBrand>

        <ArrowBtnHolster
          isOpen={collapse}
          onClick={toggleNavbar}
          data-testid="navbar-arrow"
        >
          <FontAwesomeIcon
            icon="chevron-up"
            rotation={collapse ? null : 180}
            color="white"
          />
        </ArrowBtnHolster>

        <SpecialNav vertical navbar>
          <NavItem className="w-100 text-center">
            <CompanyBalance />
          </NavItem>
        </SpecialNav>
        <StyledNav vertical navbar data-test="routes">
          <CustomNavLink
            name="New Messages"
            path="/all-unread"
            icon="comment-dots"
            count={count}
            showCount
            checkSubscription
            activeClass={AllUnreadActiveClass}
            linkClass={linkClass}
            textClass={textClass}
            isCollapsed={collapse}
            pageName="unread-message-nav-button"
          />
          <CustomNavLink
            name="Campaigns"
            path="/campaigns/all"
            icon="bullhorn"
            count={Number(pendingReminders)}
            activeClass={CampaignsActiveClass}
            linkClass={linkClass}
            textClass={textClass}
            isCollapsed={collapse}
            checkSubscription={false}
            onClick={() => {
              setCollapse(false);
              dispatch(setDesktopCampaignActiveTab("all"));
            }}
          />
          {routes}
        </StyledNav>
        <LogoutNav vertical navbar>
          <NavItem>
            <NavLink
              onClick={() => dispatch(logout())}
              className="textL"
              data-testid="logout-link"
              tag={LogoutBtn}
            >
              Log out
            </NavLink>
          </NavItem>
        </LogoutNav>
      </StyledNavbar>
      {isCallActive ? <DesktopActiveCall /> : ""}
    </>
  );
};

export default withRouter(NavbarDesktop);
