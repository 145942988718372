import { produce } from "immer";
import * as actions from "./actions";
import { Fetching, Success } from "module/common/helpers/variables";
import { IState } from "./interface";

const initialState: IState = {
  relays: [],
  status: Fetching,
};

export const path = ["prospectRelayStore"];

const reducer = produce((draft: IState, action: any) => {
  switch (action.type) {
    case actions.POPULATE_PROSPECT_RELAYS: {
      draft.status = Success;
      draft.relays = action.payload;
      break;
    }
    case actions.ADD_PROSPECT_RELAY: {
      draft.relays = [...draft.relays, action.payload];
      break;
    }
    case actions.REMOVE_PROSPECT_RELAY: {
      const filteredRelays = [...draft.relays].filter(
        (relay) => relay.id !== action.payload
      );

      draft.relays = filteredRelays;
      break;
    }
    case actions.RESET_RELAYS: {
      draft.relays = initialState.relays;
      break;
    }
  }
}, initialState);

export default reducer;
