import { toPromise } from "module/common/utils/utils";

let id = 0;

const ITask = {
  task: () => null,
  error: () => null,
};

const IJob = {
  isFinished: false,
  isRunning: false,
};

const tasks = {};

const finishTask = (id) => () => {
  const task = tasks[id];
  if (task) {
    tasks[id].isFinished = true;
    tasks[id].isRunning = false;
  }
};

const run = () => {
  Object.entries(tasks).forEach(([id, task_]) => {
    const { task, isFinished, isRunning, errorHandler } = task_;

    if (!isFinished && !isRunning) {
      task_.isRunning = true;
      toPromise(() => task({ finishTask: finishTask(id) })).catch(errorHandler);
    }
  });
};

const removeFinishedTasks = () => {
  Object.entries(tasks).forEach(([id, task]) => {
    if (task.isFinished) {
      delete tasks[id];
    }
  });
};

const runTasks = () => {
  setInterval(() => {
    removeFinishedTasks();
    run();
  }, 10000);
};
export const addTask = (task) => {
  const job = {
    ...ITask,
    ...task,
    ...IJob,
  };

  // increment id
  id += 1;
  tasks[id] = job;
};

export default runTasks;
