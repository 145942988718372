//assets
import propstack from "assets/images/propstack.svg";
import { SkipTraceStatus } from "module/main/store/SkipTraces/interfaces";

// times
export const ONE_MINUTE_IN_MILLISECONDS = 60_000;
export const ONE_SECOND_IN_MILLISECONDS = 1_000;

// responsiveness
export const maxMobileWidth = 1280;

// data fetch status
export const Initial = "Initial";
export const Fetching = "Fetching";
export const Success = "Success";
export const Creating = "Creating";
export const FetchError = "FetchError";
export const Updating = "Updating";
export const Deleting = "Deleting";
export const Error = "Error";
export const Warning = "Warning";

export enum ApiFetchStatus {
  Initial = "Initial",
  Fetching = "Fetching",
  Success = "Success",
  Error = "Error",
  FetchError = "FetchError",
}

// Category Types
export const Template = "template";
// debounce time
export const debounceTime = 30;

// min spinner times
export const fastSpinner = 250;

// toast stuff
export const toastLingerTime = 5 * ONE_SECOND_IN_MILLISECONDS;

// Document properties
export enum DocumentVisibilityState {
  HIDDEN = "hidden",
  VISIBLE = "visible",
}

// note messages
export const messageNewNote = "New note added";
export const messageUpdateNote = "Note updated";
export const messageDeleteNote = "Note Deleted";

export const prospectStatusDateCriteria = [
  { label: "Prior To", value: "tagBefore" },
  { label: "Between", value: "tagBetween" },
  { label: "After", value: "tagAfter" },
];

export const propertyTagDateCriteria = () => {
  // TODO: Removing anything with "declared" since it's not working on the BE yet.
  // const declaredIds = ["Bankruptcy", "Lien", "Judgement", "Pre-foreclosure"];
  // const checkInclude = selection.include.filter(
  //   (e: any) => !declaredIds.includes(e.label)
  // );
  // const checkExclude = selection.exclude.filter(
  //   (e: any) => !declaredIds.includes(e.label)
  // );

  const base = [
    { label: "Tag Added Prior to", value: "tagBefore" },
    { label: "Tag Added Between", value: "tagBetween" },
    { label: "Tag Added After", value: "tagAfter" },
  ];

  // if (checkInclude.length === 0 && checkExclude.length === 0)
  //   return [
  //     { label: "Declared Prior to", value: "declaredPriorTo" },
  //     { label: "Declared Between", value: "declaredBetween" },
  //     { label: "Declared After", value: "declaredAfter" },
  //     ...base,
  //   ];

  return base;
};

// general error messages
export const generalNetworkError =
  "Something went wrong. Please refresh or try again later.";
export const genericNetworkErrorMessage = {
  message: generalNetworkError,
  color: "danger",
};

// reducer stuff
export const prospectDetailsReducer = "prospectDetailsReducer";

// sms stuff
export const pollingInterval = 20 * ONE_SECOND_IN_MILLISECONDS;
export const messagesPlaceholderText =
  "Send a message to start a conversation with this prospect";
export const quickRepliesPlaceholderText = "No Quick Replies";
export const SEND_COUNT_TRIGGER_NEXT_PAGE_THRESHHOLD = 50; // controls when the next batch-prospects should be fetched.

export const desktopProspectDetailHeaderInfo = {
  hasBackButton: true,
};

export const prospectHeaderInfo = {
  fromText: "Prospect List",
  hasBackButton: true,
  tabs: [
    {
      idx: "1",
      name: "Details",
      icon: "user",
    },
    {
      idx: "2",
      name: "Messages",
      icon: "comment-dots",
    },
    {
      idx: "3",
      name: "Notes",
      icon: "sticky-note",
    },
    {
      idx: "4",
      name: "Activity",
      icon: "history",
    },
  ],
};

export const ProspectActions = [
  {
    icon: "verified",
    name: "Verified",
    status: false,
    attr: "ownerVerifiedStatus",
    link: null,
    background: "green",
  },
  {
    icon: "dnc",
    name: "DNC",
    status: false,
    attr: "doNotCall",
    link: null,
    background: "red",
  },
  {
    icon: "priority",
    name: "Priority",
    status: false,
    attr: "isPriority",
    link: null,
    background: "orange",
  },
  {
    icon: "qualified",
    name: "Qualified",
    status: false,
    attr: "isQualifiedLead",
    link: null,
    background: "purple",
  },
];

/**
 *
 * If a user has an active subscription, we will show All Campaigns and SMS tabs
 * If a user does not have an active subscription, we will ONLY show SMS tabs
 *
 * @param hasNoActiveSubscription
 * @returns
 */
export const desktopCampaignHeaderInfo = (isGlobalComplete: boolean) => {
  const tabs = [
    {
      idx: "sms",
      name: "SMS",
      icon: "comment-dots",
      type: "sms",
    },
  ];

  tabs.unshift({
    idx: "all",
    name: "All Campaigns",
    icon: "list-ul",
    type: "all",
  });

  if (isGlobalComplete) {
    tabs.push({
      idx: "sequences",
      name: "Sequences",
      icon: "calendar-plus",
      type: "sequences",
    });
  }

  return {
    hasBackButton: false,
    tabs,
    actions: {
      main: [
        {
          displayOnTabIdx: "sequences",
          btnType: "primary",
          icon: "calendar-plus",
          text: "Create",
          // this is for adding the onclick later
          action: "createSequence",
          "data-test": "desktop-new-sequence-button",
        },
      ],
    },
  };
};

// campaign types
export const DM = "dm";
export const SMS = "sms";
export const ALL = "all";
export const SQS = "sequences";

// call reminders
export const CALL = "call";

export const OWNED_BY_ME_TAB = "ownedbyme";

export const skipTraceHeaderInfo = {
  hasBackButton: false,
  tabs: [
    {
      idx: "1",
      name: "Uploads",
      icon: "list-ul",
    },
    {
      idx: "2",
      name: "Map Fields",
      icon: "list-ul",
    },
    {
      idx: "3",
      name: "Run Skip Trace",
      icon: "list-ul",
    },
  ],
  actions: {
    main: [
      {
        btnType: "primary",
        text: "New Skip Trace",
        // this is for adding the onclick later
        // action: 'addContacts',
        "data-test": "desktop-new-skip-trace-button",
      },
    ],
  },
};

// smart stacker vars
export const smartStackerHeaderData = {
  hasBackButton: false,
  tabs: [
    {
      idx: "0",
      name: "Filtering",
      icon: "filter",
    },
    {
      idx: "1",
      name: "Imports",
      icon: "upload",
    },
  ],
  actions: {
    main: [
      {
        btnType: "primary",
        text: "Import",
        action: "importProperties",
        permissions: {},
        "data-test": "ss-import-button",
      },
    ],
  },
};

// new messages
export const MESSAGES_POLLING_INTERVAL = 1 * ONE_MINUTE_IN_MILLISECONDS;
export const NO_UNREAD_MESSAGES = "There are currently no unread messages";
export const NO_SMS_REMINDERS = "0 SMS reminder remaining";
export const NO_CALL_REMINDERS = "0 Call reminder remaining";

// no desktop message
export const noSubscriptionMessage =
  "You do not have an active subscription, please use the desktop version for subscription activation.";
// no registration on mobile message
export const noRegistrationOnMobileMessage =
  "Please use the desktop version for registration.";

// virtualize list
export const vListHeight = 600;
export const vListItems = 150;

// campaign sorting
export const campaignSortingOptions = [
  {
    label: "Newest",
    value: "-created_date",
  },
  {
    label: "Oldest",
    value: "created_date",
  },
  {
    label: "Alpha (A-Z)",
    value: "name",
  },
  {
    label: "Alpha (Z-A)",
    value: "-name",
  },
  {
    label: "Status: High",
    value: "-percent",
  },
  {
    label: "Status: Low",
    value: "percent",
  },
];

// followup campaigns
export const createFollowupMessage =
  "By creating a Follow-up Campaign all the prospects that have not responded to the SMS from this campaign will permanently moved over to the new Campaign.";
export const NO_ARCHIVE_MESSAGE = "Campaign is already archived";

// account settings

export const accountSettingsTabs = [
  {
    id: 1,
    name: "Account Info & Settings",
    component: "AccountInfoTab",
    permissionProps: {},
  },
  {
    id: 2,
    name: "Billing Information",
    component: "BillingInfoTab",
    permissionProps: {},
  },
  {
    id: 3,
    name: "Users",
    component: "UsersTab",
    permissionProps: {},
  },
  {
    id: 4,
    name: "Company",
    component: "CompanySettingsTab",
    permissionProps: {
      pageName: "account-settings:company-settings-tab",
      checkSubscription: true,
    },
  },
  {
    id: 5,
    name: "Lead Stages",
    component: "LeadStagesTab",
    permissionProps: {
      pageName: "account-settings:lead-stages-tab",
      checkSubscription: true,
    },
  },
  {
    id: 6,
    name: "Manage App Integrations",
    component: "ManageAppsTab",
    permissionProps: {
      pageName: "account-settings:manage-apps-tab",
      checkSubscription: true,
    },
  },
  {
    id: 7,
    name: "Prospect Relay Settings",
    component: "ProspectRelaySettingsTab",
    permissionProps: {
      pageName: "account-settings:relay-settings-tab",
      checkSubscription: true,
    },
  },
  {
    id: 8,
    name: "Tag Management",
    component: "TagManagementTab",
    permissionProps: {},
  },
];

// account info
export const timezones = [
  "US/Mountain",
  "US/Arizona",
  "US/Central",
  "US/Pacific",
  "US/Eastern",
  "US/Hawaii",
  "US/Alaska",
];

// skip trace

export const noSkipTraceMessage = "You have not uploaded any skip trace lists";
export const noSkipTraceCriteriaMessage =
  "There are no skip trace uploads that fit this criteria";
export const skipTracePollingTimer = 5 * ONE_SECOND_IN_MILLISECONDS;
export const skipTraceRunningVars = [
  SkipTraceStatus.Running,
  SkipTraceStatus.Paused,
  SkipTraceStatus.AutoStop,
  SkipTraceStatus.SentToTask,
  SkipTraceStatus.Setup,
  SkipTraceStatus.ReadyToBill,
  SkipTraceStatus.Billing,
];
export const skipTraceCompleteVars = [
  SkipTraceStatus.Complete,
  SkipTraceStatus.Error,
  SkipTraceStatus.Cancelled,
];

// autodead
export const autoDeadErrorMessage = "Autodead could not be toggled at this time";

// autoverify
export const autoVerifyErrorMessage = "Auto-verify could not be toggled at this time";

// recordCalls
export const recordCallsErrorMessage = "Record calls could not be toggled at this time";

// autofilter
export const autoFilterErrorMessage = "Auto filtering could not be toggled at this time";

// carrierApproved templates
export const carrierApprovedTemplateErrorMessage =
  "CarrierApproved templates could not be toggled at this time";

export const enum ProfileRoles {
  MasterAdmin = "master_admin",
  Admin = "admin",
  Staff = "staff",
  JuniorStaff = "junior_staff",
}

// user settings
export const userRoleOptions = [
  { label: "Master Admin", value: ProfileRoles.MasterAdmin },
  { label: "Admin", value: ProfileRoles.Admin },
  { label: "Staff", value: ProfileRoles.Staff },
  { label: "Junior Staff", value: ProfileRoles.JuniorStaff },
];

export const userRoleNames = userRoleOptions.reduce((acc: any, { label, value }: any) => {
  acc[value] = label;
  return acc;
}, {});

export const userStatusOptions = [
  { value: "inactive", label: "Inactive" },
  { value: "active", label: "Active" },
];

// billing history
export const NO_BILLING_HISTORY = "There is no billing history";

// activity feed
export const NO_ACTIVITY_FEED = "There has been no activity";

// sms-templates and quick-replies dynamic fields
export const dynamicFields = [
  "{FirstName}",
  "{LastName}",
  "{StreetAddress}",
  "{City}",
  "{State}",
  "{ZipCode}",
  "{Custom1}",
  "{UserFirstName}",
  "{CompanyName}",
];

// sms-templates dynamic fields for alternate message
export const dynamicAltFields = ["{CompanyName}"];

// form validation
export const VALIDATION_MIN_LENGTH = "VALIDATION_MIN_LENGTH";
export const VALIDATION_MAX_LENGTH = "VALIDATION_MAX_LENGTH";
export const VALIDATION_INVALID_CHARS = "VALIDATION_INVALID_CHARS";
export const passwordFormValidationRules = {
  required: {
    value: true,
    message: "Required",
  },
  minLength: {
    value: 8,
    message: "Must be a minimum of 8 characters in length",
  },
};
export const TEMPLATE_TEXT_AREA_MAX_LENGTH = 320;
export const TEMPLATE_TEXT_AREA_MIN_LENGTH = 40;
export const TEMPLATE_TEXT_AREA_CAUTION_LENGTH = 160;

// dashboard related
export const DEFAULT_GRAPH_SCALE = 100;
export const DEFAULT_CAMPAIGN_STAT = { campaign: {} };

// braintree
export const DEFAULT_BT_ERROR_VALUE = { details: { invalidFieldKeys: {} } };

// lead-stages
export const AUTO_DEAD_ALL = 55;
export const AUTO_DEAD_UNVIEWED = "dead_auto_unviewed";
export const DEAD = "Dead";

// google maps search-location base-url
export const GOOGLE_MAPS_SEARCH_URL = "https://www.google.com/maps/search/?api=1&";

// smart stacker stuff
/**
 * Special prospect status options
 * This is a special case where we want to add boolean values to the
 * dropdown options
 * "dnc": false,
 * "wrong_number": false,
 * "is_priority": false,
 * "is_qualified_lead": false,
 * "is_blocked": false
 */
export const specialProspectTagOptions = [
  { label: "Blocked", value: "isBlocked" },
  { label: "Do not Call", value: "doNotCall" },
  { label: "Priority", value: "isPriority" },
  { label: "Qualified Lead", value: "isQualifiedLead" },
  { label: "Wrong Number", value: "wrongNumber" },
];

export const additionalFiltersTagOptions = [
  { label: "Verified Owner", value: "verified" },
  { label: "Non Owner", value: "open" },
  { label: "Owner Not Found", value: "unverified" },
];

export const otherFiltersOptions = (dmStatus: boolean) => [
  // values based on what will be passed in the query
  { label: "Pushed to SMS", value: "inCampaign" },
  dmStatus ? { label: "Pushed to DM", value: "inDmCampaign" } : null,
  { label: "Skip Traced", value: "skipTraced" },
  { label: "Opted Out", value: "optedOut" },
  { label: "Recently Vacant", value: "recentlyVacant" },
  { label: "Has Reminder", value: "isReminder" },
  { label: "In Sequence", value: "inSequence" },
];

// smart stacker actions
export const archive = "archive";
export const addTags = "addTags";
export const removeTags = "removeTags";
export const exportResult = "export";
export const skipTrace = "skipTrace";
export const pushToCampaign = "pushToCampaign";

export const states = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];
export const pushToCampaignDetails = "pushToCampaignDetails";

// for virtualized list
export const ESTIMATED_ROW_SIZE = 60;
export const ROW_HEIGHT_PAD = 10;

// direct mail templates
export const directMailTemplateTabs = [
  {
    idx: "postcards",
    name: "Postcards",
  },
];

// image classnames is used to add additional css to the specific postcard image
export const directMailTemplates = [
  {
    label: "Street View",
    type: "postcards",
    value: "S128",
    imageClassNames: "add-shadow",
  },
  {
    label: "Orange Crush",
    value: "S127",
    type: "postcards",
    imageClassNames: "add-shadow",
  },
  {
    label: "Blessed",
    value: "S126",
    type: "postcards",
    imageClassNames: "",
  },
  {
    label: "Standard",
    value: "S124",
    type: "postcards",
    imageClassNames: "",
  },
  {
    label: "Doodle",
    value: "S123",
    type: "postcards",
    imageClassNames: "",
  },
];

export const prospectSearchImgURL = propstack;
export const newSequenceKey = "newSequence";

export const sequenceDotProps = {
  left: "100%",
  translateX: "translateX(0%)",
  width: ".5rem",
  height: ".5rem",
};

export const propStackCycleSourcePath = [
  "uiStore",
  "smartStackerListView",
  "results",
  "prospectsById",
];
//providers
export enum Providers {
  inteliquent = "inteliquent",
  telnyx = "telnyx",
  twilio = "twilio",
}
export enum ActiveProviders {
  telnyx = "telnyx",
  twilio = "twilio",
}

export enum Features {
  MultiTrace = "multitrace",
  UpgradeAccess = "upgrade_access",
  SmsRedesign = "sms_redesign",
  FloridaCompliance = "florida_compliance",
  NewMarketDisabled = "new_market_disabled",
  ProfileStatsEnabled = "profile_stats",
  CampaignStatsEnabled = "campaign_stats",
  NewSkipTraceOptions = "new_skip_trace_options",
  AchPaymentMethod = "ach_payment_method",
}

//TCR Status
export enum TCRStatus {
  Initial = "INITIAL",
  Pending = "PENDING",
  Unverified = "UNVERIFIED",
  SelfDeclared = "SELF_DECLARED",
  VettedVerified = "VETTED_VERIFIED",
  Verified = "VERIFIED",
}

export enum MarketStages {
  Healthy = "healthy",
  Provisioning = "provisioning",
  LowDelivery = "low_delivery_rate",
  Disabled = "disabled",
  Archived = "archived",
}

export enum SmsRequestStatus {
  Created = "created",
  PendingReview = "pending_review",
  RequireInfo = "requires_information",
  Fulfilled = "fulfilled",
  Disapproved = "disapproved",
}

export enum MarketStatus {
  // Provisioning --------
  AcquiringTCR = "acquiring_tcr_campaign_registration_for_provider",
  SharingTCR = "sharing_tcr_campaign_to_provider",
  AcquiringNumbers = "acquiring_numbers",
  AcquiringAdditionalNumbers = "acquiring_additional_numbers", // won't block sms
  RegisteringNumbers = "registering_numbers_with_tcr_campaign",
  RegisteringAdditionalNumbers = "registering_additionaL_numbers_with_tcr_campaign", // won't block sms
  // Disabled --------
  FailedToAcquireTCR = "failed_to_acquire_tcr_campaign_for_provider",
  FailedToShareTCR = "failed_to_share_tcr_campaign_to_provider",
  FailedToAcquireNumbers = "failed_to_acquire_numbers",
  FailedToAcquireAdditionalNumbers = "failed_to_acquire_additional_numbers", // won't block sms
  FailedToRegisterNumbers = "failed_to_register_numbers_with_tcr_campaign",
  FailedToRegisterAdditionalNumbers = "failed_to_register_additional_numbers", // won't block sms
  NoActiveSubscription = "no_active_sms_subscription",
  // Low delivery --------
  LowDelivery = "low_delivery_rate",
  // Archived --------
  Archived = "archived",
}

export enum Subscriptions {
  Starter = "starter",
  Core = "core",
  Pro = "pro",
  Enterprise = "enterprise",
}

export enum SubscriptionType {
  SignUp = "signUp",
  Upgrade = "upgrade",
  UpgradeFromPreview = "upgradeFromPreview",
  TenDLC = "tenDLC",
}

export enum SignUpStatus {
  Complete = "complete",
  Incomplete = "incomplete", // started but pending (IE missing templates)
  Ready = "ready",
  Blocked = "blocked", // mainly for campaigns waiting on a market
}

export enum AppEnv {
  Production = "production",
  Development = "development",
}

export enum TrackerMode {
  NoTrackers = "no-trackers",
  Trackers = "trackers",
}

export const ISO_DATE_FORMAT = "YYYY-MM-DD";

export const DISTRESS_INDICATOR_TOOLTIP = `A distress indicator is used to help show which properties are
most likely to sell in the near future. Some good examples: people who are
recently divorced, a home that didn't sell on the market, a property with
a quitclaim deed filed, etc.`;

export enum KeyNames {
  ArrowDown = "ArrowDown",
  ArrowUp = "ArrowUp",
  Enter = "Enter",
  S = "s",
  D = "d",
}
export const MARKET_PROVISIONING_TIME = "10 working days";

export const MINIMUM_PROSPECTS_FOR_HIGH_CAPACITY = 1000;

// upload prospects
export enum UploadStatus {
  Running = "running",
  Complete = "complete",
  Error = "error",
}

export const UPLOAD_PROSPECTS_POLLING_INTERVAL = 15 * ONE_SECOND_IN_MILLISECONDS;
export const UPLOAD_PROSPECTS_MAX_UPLOAD_COUNT = 5;
export const UPLOAD_PROSPECT_DISABLED_REASON =
  "Only 5 running prospect uploads allowed at a time.";

export const TEMP_SMS_DISABLE_REASON = "";

// SMS Categories
export const CATEGORY_MIN_TEMPLATE_COUNT = 5;
export const INITIAL_CATEGORY_TEMPLATE_COUNT = 20;
export const INITIAL_CATEGORY_TITLE = "Initial";
