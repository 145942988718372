import styled from "styled-components";

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopSection = styled(Row)`
  position: relative;
  padding: 75px 0px;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 var(--gray-shadow);

  @media (max-width: 768px) {
    padding-bottom: 50px;
  }

  @media (max-width: 576px) {
    padding-bottom: 40px;
  }
`;

export const BodySection = styled(Row)`
  position: relative;
  padding: 50px 0px 71px;
`;

export const TitleWrapper = styled(Col)`
  width: 70%;
  color: var(--secondary);

  @media (max-width: 768px) {
    width: inherit;
  }
`;

export const HeaderImg = styled.img`
  width: 30%;
  height: 30%;
  margin-left: -100px;

  @media (max-width: 768px) {
    width: 309px;
    margin-top: 30px;
    margin-left: 0px;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 51px;
`;

export const SubTitle = styled.div`
  height: 50px;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 25px;
`;

const PaddingRow = styled(Row)`
  padding: 0 12%;

  @media (max-width: 768px) {
    padding: 0 8%;
  }

  ${(props) =>
    props.isChildRow &&
    `
      padding: 0;

      @media (max-width: 768px) {
        padding: 0;
      }
    `}
`;

export const TopRow = styled(PaddingRow)`
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ButtonRow = styled(Row)`
  margin-top: 32px;
  justify-content: flex-start;

  @media (max-width: 768px) {
    justify-content: center;
    margin-left: -40px;
  }

  @media (max-width: 576px) {
    margin-top: 50px;
  }
`;

export const CardRow = styled(PaddingRow)`
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${(props) => props.mt && `margin-top: 51px;`}

  ${(props) =>
    props.isChildRow &&
    `
      margin-top: 0;
    `}
`;

const MarginRow = styled(Row)`
  margin: 0 12%;

  @media (max-width: 576px) {
    margin: 0 8%;
  }
`;

export const FooterRow = styled(MarginRow)`
  justify-content: space-between;
  flex-direction: row;
  margin-top: 42px !important;
  padding-bottom: 13px;
  border-bottom: 1px solid var(--gray-border);
`;

export const Help = styled.div`
  color: var(--secondary);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
`;
