export const CAMPAIGN_DETAILS_VIEW_PAGE = "campaign/view:page";
export const CAMPAIGN_DETAILS_ADD_SMS_CAPACITY = "campaign/action:add-capacity";
export const CAMPAIGN_DETAILS_VIEW_SEND_TAB = "campaign/view:send-tab";

export const permissions = {
  master_admin: [],
  admin: [CAMPAIGN_DETAILS_ADD_SMS_CAPACITY],
  staff: [CAMPAIGN_DETAILS_VIEW_SEND_TAB],
  junior_staff: [CAMPAIGN_DETAILS_VIEW_PAGE],
};
