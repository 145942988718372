import AxiosInstance from "../../../../common/utils/axiosConfig";

export const getAllReminders = (filter: string = "user") => {
  return AxiosInstance.get(`sequence-steps/?owned_by=${filter}`);
};

export const getSMSReminders = (filter: string = "user") => {
  return AxiosInstance.get(`sequence-steps/?step_type=sms&owned_by=${filter}`);
};

export const getCallReminders = (filter: string = "user") => {
  return AxiosInstance.get(`sequence-steps/?step_type=call&owned_by=${filter}`);
};

export const updateReminder = (
  prospectSequenceStepId: number,
  type: "finish" | "skip"
) => {
  return AxiosInstance.patch(`sequence-steps/${prospectSequenceStepId}/`, {
    action: type,
  });
};
