import { produce } from "immer";
import { COMPANY_SET_FEATURES, COMPANY_SET_FEATURE_STATUS } from "./actions";
import { IFeatures } from "../interfaces";
import { Success, Initial } from "module/common/helpers/variables";

const initial: IFeatures = {
  status: Initial,
  features: [],
};

const reducer = produce((draft: IFeatures, action: any) => {
  switch (action.type) {
    case COMPANY_SET_FEATURES: {
      draft.features = action.payload;
      draft.status = Success;
      break;
    }
    case COMPANY_SET_FEATURE_STATUS: {
      draft.status = action.payload;
    }
  }
}, initial);

export default reducer;
