import { produce } from "immer";

import {
  SET_DIRECT_MAIL_CAMPAIGN_PROSPECT_IDS,
  SET_ACTIVE_DIRECT_MAIL_CAMPAIGN_ID,
  SET_DIRECT_MAIL_CAMPAIGN_PROSPECTS_IS_LOADING,
  SET_DIRECT_MAIL_CAMPAIGN_PROSPECTS_IS_LOADING_MORE,
  SET_DIRECT_MAIL_NEXT_CAMPAIGN_PROSPECT_IDS,
  SET_DIRECT_MAIL_CAMPAIGN_SCHEDULE,
  REMOVE_DIRECT_MAIL_RECIPIENTS,
  SET_DM_CAMPAIGN_PROSPECTS_IS_UPLOADING,
} from "./actions";

export const state = {
  prospectsById: [],
  activeCampaignId: 0,
  next: null,
  prev: null,
  count: 0,
  isLoading: true,
  isLoadingMore: false,
  // can make it just an empty object once the api is working properly,
  mailing: "s127",
  schedule: {},
  isCampaignProspectsUploading: false,
};

export const path = ["uiStore", "campaignDetailsPageDesktopView", "DirectMail"];

const reducer = produce((base: any, action: any) => {
  switch (action.type) {
    case SET_DIRECT_MAIL_CAMPAIGN_PROSPECT_IDS: {
      base.prospectsById = action.payload.results;
      base.next = action.payload.next;
      base.prev = action.payload.prev;
      base.count = action.payload.count;
      base.isLoading = false;
      break;
    }
    case SET_DIRECT_MAIL_NEXT_CAMPAIGN_PROSPECT_IDS: {
      base.prospectsById = [...base.prospectsById, ...action.payload.results];
      base.next = action.payload.next;
      base.prev = action.payload.prev;
      base.count = action.payload.count;
      base.isLoadingMore = false;
      break;
    }
    case SET_ACTIVE_DIRECT_MAIL_CAMPAIGN_ID: {
      base.activeCampaignId = action.payload;
      break;
    }
    case SET_DIRECT_MAIL_CAMPAIGN_PROSPECTS_IS_LOADING: {
      base.isLoading = action.payload;
      break;
    }
    case SET_DIRECT_MAIL_CAMPAIGN_PROSPECTS_IS_LOADING_MORE: {
      base.isLoadingMore = true;
      break;
    }
    case SET_DIRECT_MAIL_CAMPAIGN_SCHEDULE: {
      base.schedule = action.payload;
      break;
    }
    case REMOVE_DIRECT_MAIL_RECIPIENTS: {
      base.prospectsById = base.prospectsById.filter(
        (id: number) => !action.payload.includes(id)
      );
      base.count = base.prospectsById.length;
      break;
    }
    case SET_DM_CAMPAIGN_PROSPECTS_IS_UPLOADING: {
      base.isCampaignProspectsUploading = action.payload;
      break;
    }
  }
}, state);

export default reducer;
