import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { Label, Button } from "reactstrap";
import PropTypes from "prop-types";
import { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//components
import InputSelect from "module/common/components/InputSelect";
import UncontrolledToolTip from "../UncontrolledToolTip";
import WithPermissions from "module/common/components/WithPermissions";
import Modal from "../Modal";

// redux related
import { getPropertyTags } from "../../../main/store/Tags/property/selectors";
import { removePropertyTag } from "../../../main/store/Tags/property/thunks";

// utils
import { toSelectOptions } from "../../helpers/utils";

// permissions
import { CUSTOM_PROPERTY_TAGS_EDIT } from "../../../main/permissions/propertyTags";

// forms
import CreateEditPropertyTagForm from "../Forms/CreateEditPropertyTagForm";

// hooks
import useModal from "module/common/hooks/useModal";

// styled components
import { ButtonsContainer } from "assets/styles/sc/layout";

const FieldWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: var(--pad4);
  }
  .iconBg {
    color: var(--sherpaBlue);
  }
`;

const StyledManagePropertyTagOption = styled.div`
  padding: 8px 12px; /** to mimic the styling on the options dropdown */
  color: var(--sherpaBlue);
  cursor: pointer;
  border: 1px solid var(--lightGray);
  &:hover {
    background-color: var(--lightGray);
  }
`;

const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: var(--lightGray) solid 1px;
`;

const StyledAddAction = styled.div`
  display: flex;
  justify-content: center;
  margin-top: var(--pad3);
  margin-bottom: var(--pad2);
`;

const StyledButton = styled(Button)`
  border-width: 2px;
`;

const StyledLeadData = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: var(--pad2);
  border-bottom-color: var(--lightGray);
  padding-bottom: var(--pad1);
`;

const StyledIcons = styled.div`
  svg {
    cursor: pointer;
  }
`;

const MenuList = (props) => {
  const { hasManagePropertyTagsFunc, onManagePropertyTag } = props.selectProps;
  return (
    <>
      <components.MenuList {...props}>{props.children}</components.MenuList>
      {hasManagePropertyTagsFunc ? (
        <WithPermissions permission={CUSTOM_PROPERTY_TAGS_EDIT} checkRole>
          <StyledManagePropertyTagOption onClick={onManagePropertyTag}>
            Add/Edit Property Tags{" "}
            <FontAwesomeIcon
              className="ml-1"
              icon="pencil-alt"
              color="var(--sherpaBlue)"
            />
          </StyledManagePropertyTagOption>
        </WithPermissions>
      ) : null}
    </>
  );
};

const defaultModalData = {
  title: "Add/Edit Property Tags",
  type: "view",
};

const TagsSelector = ({
  onChange,
  isModal,
  prospect = null,
  fullToolTip,
  className = null,
  customLabel,
  hasManagePropertyTagsFunc,
  ...restProps
}) => {
  // local state
  const [itemToEdit, setItemToEdit] = useState(null);
  const [modalData, setModalData] = useState(defaultModalData);

  // Handle changing selected options
  const [currentTags, setCurrentTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);

  // Get tags to use as options
  const tags = useSelector(getPropertyTags);
  const customTags = tags.filter((tag) => tag.isCustom === true);

  // hooks
  const [
    isOpenEditCreatePropertyTagModal,
    setIsOpenEditCreatePropertyTagModal,
    toggleIsOpenEditCreatePropertyTagModal,
  ] = useModal();

  const dispatch = useDispatch();

  const updateTags = (data) => {
    setCurrentTags(data);
    onChange(data);
  };

  let tooltip =
    "Custom property tags and distress indicators may be created under the Tag Management tab in your Account Settings.";
  if (fullToolTip) {
    tooltip = `Selected tag(s) will be applied to all properties within the file. ${tooltip}`;
  }

  useEffect(() => {
    const options = toSelectOptions(tags, { label: "name", value: "id" });
    setTagOptions(options);
    if (prospect !== null) {
      const currentTags = prospect.tags || prospect.propertyTags || [];
      setCurrentTags(options.filter((tag) => currentTags.includes(tag.value)));
    }
    // eslint-disable-next-line
  }, [dispatch, tags, prospect]);

  const resetModalValues = ({ title, type }) => {
    // clean up local state
    setModalData({ title, type });
  };

  const onManagePropertyTag = () => {
    setIsOpenEditCreatePropertyTagModal(true);
    resetModalValues(defaultModalData);
  };

  const handleOnSuccess = (data) => {
    if (data) {
      const newTag = { label: data.name, value: data.id };
      const newTags = currentTags;
      var foundIndex = currentTags.findIndex((tag) => tag.value === data.id);
      newTags[foundIndex] = newTag;
      setCurrentTags(newTags);
    }
    toggleIsOpenEditCreatePropertyTagModal();
  };

  const addNewPropertyTag = () => {
    setModalData({
      title: "Add New Property Tag",
      type: "add",
    });
  };

  const onEditPropertyTag = (value) => {
    setModalData({
      title: `Edit ${value.name} Property Tag`,
      type: "edit",
    });

    setItemToEdit(value);
  };

  const onDeletePropertyTag = (value) => {
    setModalData({
      title: `Delete ${value.name} Property Tag`,
      type: "delete",
    });
    setItemToEdit(value);
  };

  const handleOnBack = () => {
    resetModalValues(defaultModalData);
  };

  // general deletion action for all items
  const onDelete = async () => {
    const onSuccess = () => {
      // reset selected item and close modal
      const newSelectedTags = currentTags.filter((tag) => itemToEdit.id !== tag.value);
      updateTags(newSelectedTags);

      setItemToEdit("");
      toggleIsOpenEditCreatePropertyTagModal();
    };

    const response = await dispatch(removePropertyTag(itemToEdit));

    // handle success response
    if (response) {
      onSuccess();
    }
  };

  const renderCustomPropertyTagsList = () => {
    return (
      <div>
        {customTags.map((value, idx) => {
          return (
            <StyledItem key={idx}>
              <StyledLeadData>
                {value.name}{" "}
                {value.distressIndicator && (
                  <FontAwesomeIcon icon="fire" color="var(--orange)" className="ml-2" />
                )}
              </StyledLeadData>

              <StyledIcons>
                <FontAwesomeIcon
                  className="mr-2"
                  icon="pencil-alt"
                  color="var(--sherpaBlue)"
                  onClick={() => onEditPropertyTag(value)}
                />
                <FontAwesomeIcon
                  icon="times-circle"
                  color="red"
                  onClick={() => onDeletePropertyTag(value)}
                />
              </StyledIcons>
            </StyledItem>
          );
        })}
        <StyledAddAction>
          <StyledButton
            outline
            onClick={addNewPropertyTag}
            color="primary"
            data-test="add-new-property-tag"
          >
            <FontAwesomeIcon icon="plus" className="mr-2" />
            Add New Property Tag
          </StyledButton>
        </StyledAddAction>
      </div>
    );
  };

  const renderDeletePropertyTag = () => {
    return (
      <>
        <div className="text-center mt-4 mb-4">
          Are you sure you want to delete the{" "}
          <span className="font-weight-bold">{itemToEdit.name}</span> tag? This tag will
          be removed from all properties.
        </div>
        <ButtonsContainer className="mb-2">
          <Button color="secondary" onClick={handleOnBack}>
            Back
          </Button>
          <Button color="primary" onClick={onDelete}>
            Delete
          </Button>
        </ButtonsContainer>
      </>
    );
  };

  const addEditPropertyTagModal = (
    <Modal
      title={modalData.title}
      size="md"
      toggle={toggleIsOpenEditCreatePropertyTagModal}
      isOpen={isOpenEditCreatePropertyTagModal}
    >
      {modalData.type === "view" ? (
        <>{renderCustomPropertyTagsList()}</>
      ) : modalData.type === "delete" ? (
        <>{renderDeletePropertyTag()}</>
      ) : (
        <CreateEditPropertyTagForm
          onSuccess={handleOnSuccess}
          onCancelClick={toggleIsOpenEditCreatePropertyTagModal}
          data={itemToEdit}
          onBack={handleOnBack}
        />
      )}
    </Modal>
  );

  return (
    <FieldWrapper className={className} data-test="tags-selector">
      <Label>
        {customLabel}
        <span>
          <UncontrolledToolTip text={tooltip} id="tooltip-tags-create" />
        </span>
      </Label>
      <InputSelect
        name="tags"
        id="tags"
        value={currentTags}
        options={tagOptions}
        placeholder="Select"
        onChange={updateTags}
        isMulti
        removeMenuPortalTarget={!isModal}
        components={{ MenuList }}
        hasManagePropertyTagsFunc={hasManagePropertyTagsFunc}
        onManagePropertyTag={onManagePropertyTag}
        {...restProps}
      />
      {addEditPropertyTagModal}
    </FieldWrapper>
  );
};

TagsSelector.propTypes = {
  /** function to run when selected options change **/
  onChange: PropTypes.func,
  /** optional - indicate if this is in a modal (true) defaults to false **/
  isModal: PropTypes.bool,
  /** optional - passed if we are setting tags for a specific `Prospect` **/
  prospect: PropTypes.object,
  /** optional - indicate to filter to a limited set of default system tags (true), defaults to false **/
  filterDefaultTags: PropTypes.bool,
  /** optional - indicate true to use full length tool tip description, defaults to false **/
  fullToolTip: PropTypes.bool,
  /** optional - use the default label or create a custom label */
  customLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** optional - used to determine if the dropdown has the option to add/edit any property tag */
  hasManagePropertyTagsFunc: PropTypes.bool,
};

TagsSelector.defaultProps = {
  isModal: false,
  prospect: null,
  filterDefaultTags: false,
  fullToolTip: false,
  customLabel: "Property Tags",
  hasManagePropertyTagsFunc: false,
};

export default TagsSelector;
