export interface IPurchaseEstimate {
  accountBalance: number;
  balanceToReserve: number;
  itemDescription: string;
  quantityRequested: number;
  replenishmentAmount: number;
  transactionEstimate: {
    amountForBalance: number;
    amountForPurchase: number;
    amountToAuthorize: number;
    amountToAuthorizeWithoutTaxes: number;
    taxAmount: number;
    taxPercentage: number;
  } | null;
  minUnitPrice: number | null;
  maxUnitPrice: number;
  maxTotalPrice: number;
}

export enum ColdMessagingDisabledReasons {
  AdminDisabled = "admin_disabled",
  SpamLow = "spam_low",
  SpamHigh = "spam_high",
  OptedOutHigh = "opted_out_high",
  AccountCredentials = "account_credentials",
  AccountDisabled = "account_disabled",
  AccountFunds = "account_funds",
  TelcoDailyLimit = "telco_daily_limit",
  A2p10dlcDailyLimitExceeded = "a2p_10dlc_daily_limit_exceeded",
  TcpaMessagingHours = "tcpa_messaging_hours",
  CompanyDailyLimit = "company_daily_limit",
  MarketDailyLimit = "market_daily_limit",
  MarketNotHealthy = "market_not_healthy",
  InsufficientNumbers = "insufficient_numbers",
}

export interface SpamDisabledFields {
  reasonColdMessagingDisabled: ColdMessagingDisabledReasons | null;
  datetimeColdMessagingDisabled: string | null;
  datetimeColdMessagingRestorable: string | null;
}
