import { createSelector as createCacheSelector } from "reselect";
import { createSelectorContext } from "../../redux-helpers";
import { path } from "./reducer";
import { selectCompanyProfiles } from "../../Company/profiles/selectors";
import { profilesToAgents } from "../prospectDetailsView/transformers";
import { IProfile } from "../../Company/interfaces";
import { getAllCampaigns } from "../../Campaigns/selectors";

const createSelector = createSelectorContext(path);
export const uiGetCampaignActiveFilter = createSelector("activeFilter");
export const uiGetCampaignIds = createSelector(["campaigns", "campaigns"]);
export const uiGetCampaignNext = createSelector(["campaigns", "nextPage"]);
export const uiGetCampaignSortBy = createSelector("sortBy");
export const uiCampaignActiveMarket = createSelector("activeMarket");

export const uiCampaignFilteredOwners = createCacheSelector(
  selectCompanyProfiles,
  uiGetCampaignActiveFilter,
  (profiles: IProfile[], activeFilter: any) =>
    profiles.filter((profile: IProfile) => profile.id === activeFilter[0])
);

export const uiCampaignOwnersList = createCacheSelector(
  selectCompanyProfiles,
  (profiles) => profilesToAgents(profiles)
);

export const uiGetCampaigns = createCacheSelector(
  getAllCampaigns,
  uiGetCampaignIds,
  (campaigns: any, ids: number[]) => ids.map((id: number) => campaigns[id])
);
