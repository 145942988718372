import { createAction } from "../redux-helpers";

export const FETCH_SEQUENCES = "FETCH_SEQUENCES";
export const FETCH_SEQUENCES_SUCCESS = "FETCH_SEQUENCES_SUCCESS";
export const FETCH_SEQUENCES_FAILURE = "FETCH_SEQUENCES_FAILURE";
export const DELETE_SEQUENCE_SUCCESS = "DELETE_SEQUENCE_SUCCESS";
export const FETCH_SINGLE_SEQUENCE = "FETCH_SINGLE_SEQUENCE";
export const FETCH_SINGLE_SEQUENCE_FAILURE = "FETCH_SINGLE_SEQUENCE_FAILURE";
export const FETCH_SINGLE_SEQUENCE_SUCCESS = "FETCH_SINGLE_SEQUENCE_SUCCESS";
export const ADD_SEQUENCE_TO_LIST = "ADD_SEQUENCE_TO_LIST";

export const fetchSequences = createAction(FETCH_SEQUENCES);
export const fetchSequencesSuccess = createAction(FETCH_SEQUENCES_SUCCESS);
export const fetchSequencesFailure = createAction(FETCH_SEQUENCES_FAILURE);
export const deleteSequenceSuccess = createAction(DELETE_SEQUENCE_SUCCESS);
export const fetchSingleSequence = createAction(FETCH_SINGLE_SEQUENCE);
export const fetchSingleSequenceFailure = createAction(FETCH_SEQUENCES_FAILURE);
export const fetchSingleSequenceSuccess = createAction(FETCH_SINGLE_SEQUENCE_SUCCESS);
export const addSequenceToList = createAction(ADD_SEQUENCE_TO_LIST);
