import AxiosConfig from "../../../../common/utils/axiosConfig";
import { IProspectNote } from "./interfaces";

export const createProspectNote = (data: IProspectNote) =>
  AxiosConfig.post("/prospect-notes/", data);

export const fetchProspectNotes = (params: any) =>
  AxiosConfig.get("/prospect-notes/", { params });

export const updateProspectNote = (id: number, data: any) =>
  AxiosConfig.patch(`/prospect-notes/${id}/`, data);

export const deleteProspectNote = (id: number) =>
  AxiosConfig.delete(`/prospect-notes/${id}/`);
