import { createAction } from "../redux-helpers";

export const POPULATE_SMS_TEMPLATES = "POPULATE_SMS_TEMPLATES";
export const populateSmsTemplates = createAction(POPULATE_SMS_TEMPLATES);

export const SET_SMS_TEMPLATES_STATUS = "SET_SMS_TEMPLATES_STATUS";
export const setSmsTemplatesStatus = createAction(SET_SMS_TEMPLATES_STATUS, "status");

export const SET_SMS_TEMPLATES_ERROR = "SET_SMS_TEMPLATES_ERROR";
export const setSmsTemplatesError = createAction(SET_SMS_TEMPLATES_ERROR, "error");

export const REMOVE_SMS_TEMPLATES = "REMOVE_SMS_TEMPLATES";
export const removeSmsTemplates = createAction(REMOVE_SMS_TEMPLATES);

export const UPDATE_SMS_TEMPLATES = "UPDATE_SMS_TEMPLATES";
export const updateSmsTemplates = createAction(UPDATE_SMS_TEMPLATES);
