import React, { useState, useEffect, useRef } from "react";
import { Alert, InputGroupAddon, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
// store
import { createProspectNote } from "module/main/store/ProspectDetails/notes/thunks";
import { selectUserData } from "module/main/store/Auth/selectors";
import { getActivities } from "module/main/store/ProspectDetails/activities/selectors";
import {
  getProspect,
  selectProspectDraft,
} from "module/main/store/prospectStore/selectors";
import { fetchActivities } from "module/main/store/ProspectDetails/activities/thunks";
import usePrevious from "module/common/hooks/usePrevious";

// utils
import { SQS } from "module/common/helpers/variables";
import { MessageInputTextAreaContext } from "module/common/helpers/context";
import { LoadingSpinner } from "module/common/components/LoadingSpinner";
import { formatErrorMessage } from "module/common/utils/utils";
import { isMobile } from "detectDevice";

// components
import UncontrolledToolTip from "module/common/components/UncontrolledToolTip";
import { LoadingButton, IconButton } from "module/common/components/Buttons";
import MessageQuickRepliesModal from "./MessageQuickRepliesModal";
import MessageQuickRepliesEditOrCreate from "./MessageQuickRepliesEditOrCreate";
import MessageWarning from "./MessageWarning";
import MessageInputHeader from "./MessageInputHeader";
import ProspectToSequenceModal from "../../../DesktopCampaignsPage/SequencesTab/modals/ProspectToSequenceModal";
// hooks
import useWindowSize from "module/common/hooks/useWindowSize";
import useModal from "module/common/hooks/useModal";
import { updateProspectDraft } from "module/main/store/prospectStore/actions";

import {
  StyledError,
  StyledSequenceStatus,
  MessageTextarea,
  SendMessage,
  StyledButton,
  StyledSaveAsQuickReply,
} from "./styles";
import { PhoneStatus } from "module/main/store/NumberManagerStore/interfaces";

function MessageInput({
  addNewMessage,
  disableMessage,
  prospect: prospectProp,
  optedOut,
  prospectId,
}) {
  const dispatch = useDispatch();
  // selectors
  const userData = useSelector(selectUserData);
  const uiProspect = useSelector((state) => getProspect(state, prospectId));
  const activities = useSelector((state) => getActivities(state, prospectId));
  const prospectDraft = useSelector((state) => selectProspectDraft(state, prospectId));
  const prevId = usePrevious(prospectId);

  // state
  const [isQuickReplyModalOpen, setIsQuickReplyModalOpen, toggleIsQuickReplyModalOpen] =
    useModal();
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);
  const [currentTab, setCurrentTab] = useState("reply");
  const [messageError, setMessageError] = useState("");
  const [showEditForm, setShowEditForm] = useState(false);
  const [quickReplyToEdit, setQuickReplyToEdit] = useState({});
  const [input, setInput] = useState("");

  // misc
  const messageTextbox = useRef(null);
  const windowSize = useWindowSize();
  const [isProspectSequenceOpen, setProspectSequenceOpen, toggleProspectSequence] =
    useModal();

  const prospect = prospectProp || uiProspect;
  const disabled = !!disableMessage;

  const { scrollHeight } = messageTextbox?.current || {};

  useEffect(() => {
    if (currentTab === SQS) {
      setProspectSequenceOpen(true);
      setIsQuickReplyModalOpen(false);
    }
  }, [currentTab, setProspectSequenceOpen]);

  const prospectNavCleanup = () => {
    setInput("");
    setProspectSequenceOpen(false);
    setCurrentTab("reply");
    setIsQuickReplyModalOpen(false);
  };

  useEffect(() => {
    messageTextbox.current?.focus();

    if (!activities && prospectId) {
      dispatch(fetchActivities(prospectId));
    }
    prospectNavCleanup();
    setInput(prospectDraft);
    // eslint-disable-next-line
  }, [prospectId]);

  const handleKeyDown = (e) => {
    const enterKey = 13;
    const shiftKey = e.shiftKey;
    if (!shiftKey && e.which === enterKey) {
      handleSubmit(e);
    }
  };

  const handleChange = (e) => {
    setInput(e.target.value);
    dispatch(updateProspectDraft({ id: prevId, draft: e.target.value }));
    setMessageError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input === "" || disableMessage) return;

    // decide if we're going to add a new note or send a message
    setIsFetchingMessages(true);
    if (currentTab === "reply") {
      addNewMessage(input)
        .then(() => {
          setInput("");
          dispatch(updateProspectDraft({ id: prevId, draft: "" }));
          setMessageError("");
          setIsFetchingMessages(false);
          messageTextbox.current?.focus();
        })
        .catch((error) => {
          const errorMessage = formatErrorMessage(error);
          errorMessage && setMessageError(errorMessage);
          setIsFetchingMessages(false);
        });
    } else {
      // add note
      const payload = {
        text: input,
        prospect: prospectId,
        createdBy: userData.id,
      };
      dispatch(createProspectNote(payload))
        .then(() => {
          setIsFetchingMessages(false);
          setInput("");
          setMessageError("");
        })
        .catch(() => {
          setIsFetchingMessages(false);
        });
    }
  };

  const renderSequenceMessage = () => {
    return prospect.activeProspectSequence
      ? `${prospect.activeProspectSequence.name} has been selected`
      : `No sequence has been selected`;
  };

  const messagePlaceholder = () => {
    if (currentTab === "notes") {
      return "Enter your note here";
    }
    if (optedOut) {
      return isMobile
        ? "Disabled from opt-out"
        : "Messaging disabled due to prospect opt-out";
    }

    return disableMessage ? disableMessage : "Your Message";
  };

  const showPhoneAlert = prospect.sherpaPhoneStatus === PhoneStatus.Released;

  return (
    <>
      <SendMessage data-test="send-message-form" onSubmit={handleSubmit}>
        <MessageInputHeader
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          prospectId={prospectId}
          prospectSequence={prospect.activeProspectSequence}
        />
        <div>
          {currentTab === SQS ? (
            <StyledSequenceStatus className="text-center align-middle textL mt-1 mb-1">
              {renderSequenceMessage()}
            </StyledSequenceStatus>
          ) : (
            <>
              {showPhoneAlert ? (
                <Alert className="mx-0 mt-3 mb-2" color="danger">
                  The phone number you used to contact this prospect is no longer active.
                  Your next message will be sent from a new number. Please reintroduce
                  yourself to provide context and continue the conversation.
                </Alert>
              ) : null}
              <MessageTextarea
                type="text"
                name="sendMessage"
                id="sendMessage"
                ref={messageTextbox}
                placeholder={messagePlaceholder()}
                className="textM w-100"
                data-test="message-input"
                value={input}
                onChange={handleChange}
                spellCheck
                disabled={disableMessage || optedOut || isFetchingMessages}
                onKeyDown={handleKeyDown}
                scrollHeight={scrollHeight}
                $addSpace={!showPhoneAlert}
              />
            </>
          )}
          <StyledError className="text-left textS mt-0 mb-1">
            <span data-test="messaging-disabled-error">{messageError}</span>
          </StyledError>
        </div>
        {isMobile ? <hr className="w-100 mt-0 mb-1" /> : null}
        <div className="d-flex justify-content-between align-items-center">
          {currentTab !== SQS ? (
            <InputGroupAddon
              addonType="prepend"
              className="align-items-center input-group"
            >
              <StyledButton
                data-test="quick-reply-btn"
                className={
                  currentTab === "reply" ? "d-flex align-items-center p-0" : "d-none p-0"
                }
                type="button"
                color="link"
                id="quick-reply"
                onClick={() => setIsQuickReplyModalOpen(true)}
                disabled={disabled}
              >
                <UncontrolledToolTip
                  id="selectQuickReply"
                  icon="layer-group"
                  size="1x"
                  text={"Select Quick Reply"}
                />
              </StyledButton>
              {!isMobile ? (
                <StyledSaveAsQuickReply
                  className="navLink pl-1 text-truncate"
                  data-test="quick-reply-save-button"
                  $disabled={!input || disabled}
                  onClick={() => {
                    if (input && !disabled) {
                      setQuickReplyToEdit({
                        question: "",
                        message: input,
                      });
                      setShowEditForm(true);
                    }
                  }}
                >
                  {windowSize.width < 992 && !isMobile ? (
                    <UncontrolledToolTip
                      id="saveAsQuickReply"
                      icon="save"
                      size="lg"
                      text={"Save as quick reply"}
                    />
                  ) : (
                    <span className="textM">Save as quick reply</span>
                  )}
                </StyledSaveAsQuickReply>
              ) : (
                <Button
                  className="navLink mx-1 p-0"
                  data-test="quick-reply-save-button"
                  color="link"
                  disabled={!input || disabled}
                  onClick={() => {
                    setQuickReplyToEdit({
                      question: "",
                      message: input,
                    });
                    setShowEditForm(true);
                  }}
                >
                  <FontAwesomeIcon size="lg" icon="bookmark" color="var(--sherpaBlue)" />
                </Button>
              )}
            </InputGroupAddon>
          ) : null}
          {currentTab !== SQS ? (
            <InputGroupAddon
              addonType="append"
              className="input-group d-flex align-items-center justify-content-end"
            >
              {currentTab === "reply" ? (
                <>
                  <MessageWarning message={input} />
                  <IconButton
                    component={LoadingButton}
                    type="submit"
                    id="send-button"
                    data-test="send-button"
                    loading={isFetchingMessages}
                    color="primary"
                    value="Submit"
                    disabled={!input || disabled || isFetchingMessages}
                    size="medium"
                    icon="paper-plane"
                    text="Send"
                  />
                </>
              ) : currentTab === "notes" ? (
                <Button
                  type="submit"
                  name="submit"
                  data-test="save-note-button"
                  className="p-0 ml-1 font-weight-bold"
                  color="link"
                  disabled={!input || disabled}
                >
                  <LoadingSpinner
                    isLoading={isFetchingMessages}
                    color="var(--sherpaBlue)"
                    size="1.4em"
                    style={{ paddingLeft: "12px" }}
                    renderContent={() => "Save"}
                  />
                </Button>
              ) : null}
              <MessageInputTextAreaContext.Provider
                value={{
                  setMessageTextAreaValue: setInput,
                  messageTextboxRef: messageTextbox,
                }}
              >
                <MessageQuickRepliesModal
                  isOpen={isQuickReplyModalOpen}
                  toggle={toggleIsQuickReplyModalOpen}
                  setModalVisibility={setIsQuickReplyModalOpen}
                  prospectId={prospectId}
                  setQuickReplyToEdit={setQuickReplyToEdit}
                  setShowEditForm={setShowEditForm}
                />
              </MessageInputTextAreaContext.Provider>
            </InputGroupAddon>
          ) : null}
        </div>
        {disableMessage && !optedOut ? null : (
          <StyledError className="textS">
            <span data-test="messaging-disabled-error">{disableMessage}</span>
          </StyledError>
        )}
      </SendMessage>
      <MessageQuickRepliesEditOrCreate
        showEditForm={showEditForm}
        setShowEditForm={setShowEditForm}
        quickReplyToEdit={quickReplyToEdit}
        setQuickReplyToEdit={setQuickReplyToEdit}
        prospectId={prospectId}
        setQuickRepliesModalVisibility={setIsQuickReplyModalOpen}
      />
      {isProspectSequenceOpen ? (
        <ProspectToSequenceModal
          isOpen={isProspectSequenceOpen}
          toggle={toggleProspectSequence}
          prospect={{ id: prospect.id, name: prospect.name }}
          prospectSequence={prospect.activeProspectSequence}
          currentTab={currentTab}
        />
      ) : null}
    </>
  );
}

export default MessageInput;
