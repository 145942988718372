import { createAction } from "../../../redux-helpers";

export const SET_SMART_STACKER_SELECTED_ITEM = "SET_SMART_STACKER_SELECTED_ITEM";
export const setSmartStackerSelectedItem = createAction(SET_SMART_STACKER_SELECTED_ITEM);

export const REMOVE_SMART_STACKER_SELECTED_ITEM = "REMOVE_SMART_STACKER_SELECTED_ITEM";
export const removeSmartStackerSelectedItem = createAction(
  REMOVE_SMART_STACKER_SELECTED_ITEM
);

export const SET_SMART_STACKER_MULTI_SELECT_TYPE = "SET_SMART_STACKER_MULTI_SELECT_TYPE";
export const setSmartStackerMultiSelectType = createAction(
  SET_SMART_STACKER_MULTI_SELECT_TYPE
);

export const RESET_SMART_STACKER_MULTI_SELECT = "RESET_SMART_STACKER_MULTI_SELECT";
export const resetSmartStackerMultiSelection = createAction(
  RESET_SMART_STACKER_MULTI_SELECT
);

export const SET_SMART_STACKER_ROWS_SELECTED = "SET_SMART_STACKER_ROWS_SELECTED";
export const setSmartStackerRowsSelected = createAction(SET_SMART_STACKER_ROWS_SELECTED);
