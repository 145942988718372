import AxiosInstance from "module/common/utils/axiosConfig";

export const createUser = (data) => {
  return AxiosInstance.post("/auth/users/", data);
};

export const activateUser = (data) => {
  return AxiosInstance.post("/auth/users/activation/", data);
};

export const postResendActivation = (payload) => {
  // this will fail if account is already active or doesn't exist
  return AxiosInstance.post("/auth/users/resend_activation/", payload);
};

export const createInvite = (data) => {
  return AxiosInstance.post("/auth/users/invite/", data);
};

export const verifyInvite = (data) => {
  return AxiosInstance.get("/auth/users/verify_invite", { params: data });
};

export const inviteUser = (id, data) => {
  return AxiosInstance.post(`/auth/users/${id}/invitation/`, data);
};

export const resetPassword = (payload) => {
  return AxiosInstance.post("/auth/users/reset_password/", payload);
};

export const confirmNewPassword = (payload) => {
  return AxiosInstance.post("/auth/users/reset_password_confirm/", payload);
};

export const pingServer = () => {
  return AxiosInstance.get("/");
};
