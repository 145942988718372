import { createSelector as createCachedSelector } from "reselect";

import { createSelectorContext } from "../redux-helpers";
import { path } from "./reducer";
import { getIn } from "module/common/utils/utils";

const createSelector = createSelectorContext(path);

export const propertiesList = createSelector("properties");
export const propertiesListStatus = createSelector("status");

// individual prospects
export const getProperty = createCachedSelector(
  (state: any, id: any) => getIn([...path, "properties", id], state),
  (property: any) => {
    if (property) return property;
    return {};
  }
);
