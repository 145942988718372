import React, { useRef, useState } from "react";
import { UncontrolledPopover, UncontrolledPopoverProps } from "reactstrap";
import { StyledPopoverBody } from "./styles";

interface Props extends UncontrolledPopoverProps {}

export const CustomUncontrolledPopoverContext = React.createContext({
  togglePopover: () => {},
});

const CustomUncontrolledPopover: React.FC<Props> = ({
  trigger = "legacy",
  placement = "bottom",
  target,
  children,
}) => {
  const popoverRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  return (
    <UncontrolledPopover
      isOpen={isOpen}
      ref={popoverRef}
      trigger={trigger}
      placement={placement}
      target={target}
      toggle={togglePopover}
      data-testid="uncontrolled-popover"
    >
      <CustomUncontrolledPopoverContext.Provider value={{ togglePopover }}>
        <StyledPopoverBody>{children}</StyledPopoverBody>
      </CustomUncontrolledPopoverContext.Provider>
    </UncontrolledPopover>
  );
};

export default CustomUncontrolledPopover;
