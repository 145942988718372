import { createSelectorContext } from "../../redux-helpers";
import { path } from "./reducers";
import { mapIndexToArray } from "../../utils";
import { createSelector as createCachedSelector } from "reselect";
import { prospectNotesList } from "../notes/selectors";
import { prospectActivities } from "../activities/selectors";
import { allEmpty } from "module/common/utils/utils";
import { IProspectNote } from "../notes/interfaces";
import { strDateToMoment } from "../../../../common/helpers/dateUtils";

const createSelector = createSelectorContext(path);

export const getMessagesList = createSelector("list");

export const prospectMessagesList = createCachedSelector(
  getMessagesList,
  (_: any, id: number) => id,
  (list: any, id: number) => {
    const messages = list?.[id] || {};
    return mapIndexToArray(messages);
  }
);

export const prospectMessgesStatus = createSelector("status");
export const prospectMessagesUnreadCount = createSelector("unreadMsgCount");

//helper
const tagCollection = (coll: any, tag: string, category: string) => {
  return coll.map((item: any) => ({ ...item, tag, category }));
};

export const getMessagesFeed = createCachedSelector(
  prospectMessagesList,
  prospectNotesList,
  prospectActivities,
  (_: any, id: number) => id,
  (
    messagesList: any,
    notesList: Record<string, IProspectNote[]>,
    activities: [] | undefined = [],
    id: number
  ) => {
    const notes = notesList[id] || [];
    if (allEmpty(messagesList, notes, activities)) return [];

    const parseDates = (coll: any, key: string) =>
      coll.map((item: any) => ({
        ...item,
        [key]: strDateToMoment(item[key]),
      }));

    const filteredActivities = activities.filter(
      (activity: any) => activity.title !== "Created Note"
    );
    const feed = Array.prototype.concat.call(
      [],
      tagCollection(parseDates(messagesList, "dt"), "dt", "message"),
      tagCollection(parseDates(filteredActivities, "date"), "date", "activity"),
      tagCollection(parseDates(notes, "createdDate"), "createdDate", "note")
    );

    return feed.sort((item1: any, item2: any) => item1[item1.tag] - item2[item2.tag]);
  }
);
