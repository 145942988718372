import React, { useEffect, useState } from "react";
import {
  HorizontalPanelContainer,
  HorizontalNavButton,
  PanelNavContainer,
  PanelHeader,
} from "./styles";
import { CardBody, CardTitle, TabContent, TabPane } from "reactstrap";

import CircledIcon from "module/common/components/CircledIcon";

const HorizontalPanel = ({ tabData }) => {
  const [tabs, setTabs] = useState(Object.keys(tabData)[0]);
  const [navButtons, setNavButtons] = useState([]);
  const [tabContent, setTabContent] = useState([]);

  const changeActiveTab = (e, tabName) => {
    e.preventDefault();
    setTabs(tabName);
  };

  useEffect(() => {
    const navButtons = [];
    const tabContent = [];

    Object.values(tabData).forEach(({ title, icon, content }, i) => {
      navButtons.push(
        <HorizontalNavButton
          data-test="nav-button"
          key={`horizontal-panel-nav-${i}`}
          className={tabs === title ? "active nav-button" : "nav-button"}
          onMouseEnter={(e) => changeActiveTab(e, title)}
        >
          <CircledIcon backgroundColor="transparent" type={icon} />
        </HorizontalNavButton>
      );
      tabContent.push(
        <TabPane data-test="tab-pane" key={`10dlc-content-${i}`} tabId={title}>
          <div className="textM">{content}</div>
        </TabPane>
      );
    });

    setTabContent(tabContent);
    setNavButtons(navButtons);
  }, [tabs]);

  return (
    <HorizontalPanelContainer className="container-fluid my-2 text-center">
      <PanelHeader>
        <CardTitle className="font-weight-bold my-1" tag="h3">
          {tabs}
        </CardTitle>
      </PanelHeader>
      <CardBody>
        <PanelNavContainer>{navButtons}</PanelNavContainer>
        <TabContent className="pt-3 pb-0" activeTab={tabs}>
          {tabContent}
        </TabContent>
      </CardBody>
    </HorizontalPanelContainer>
  );
};

export default HorizontalPanel;
