import React from "react";
import * as Popper from "popper.js";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import styled from "styled-components/macro";
import { UncontrolledTooltip as ReUncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  id: string;
  text: React.ReactNode;
  color?: string;
  size?: SizeProp;
  icon?: IconProp;
  className?: string;
  hasHoverStyles?: boolean;
  fade?: boolean;
  placement?: Popper.Placement;
  hoverColor?: string;
  autohide?: boolean;
  onClick?: () => void;
}

const UncontrolledToolTip: React.FC<Props> = ({
  id,
  text,
  fade = false,
  color = "var(--black)",
  size = "xs",
  icon = "circle-info",
  className = "ml-1",
  hasHoverStyles = false,
  placement = "auto",
  hoverColor = "var(--lightGray)",
  autohide = false,
  onClick,
}) => {
  return (
    <StyledTooltipWrapper data-test="tooltip-wrapper" id={id}>
      <StyledSpan hasHoverStyles={hasHoverStyles} hoverColor={hoverColor}>
        <FontAwesomeIcon
          icon={icon}
          size={size}
          color={color}
          className={className}
          onClick={onClick}
        />
      </StyledSpan>
      <StyledTooltip
        key={id}
        target={id}
        fade={fade}
        autohide={autohide}
        placement={placement}
        innerClassName="text-left pe-none"
        data-test="uncontrolled-tooltip"
      >
        {text}
      </StyledTooltip>
    </StyledTooltipWrapper>
  );
};

const StyledTooltip = styled(ReUncontrolledTooltip)<Pick<Props, "autohide">>`
  ${(props) => (!props.autohide ? `cursor: default;` : null)}
`;

const StyledTooltipWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

type StyleProps = Pick<Props, "hasHoverStyles" | "hoverColor">;

const StyledSpan = styled.span<StyleProps>`
  ${(props) =>
    props.hasHoverStyles &&
    `
      padding-top: 5px;
      border-radius: 50%;
      height: 30px;
      display: inline-block;
      margin: auto;
      width: 30px;
      text-align: center;
      &:hover {
        background-color: ${props.hoverColor};
      }
    `}
`;

export default UncontrolledToolTip;
