import { produce } from "immer";
import * as actions from "./actions";
import { IState, IModal } from "./interfaces";

const initialState: IState = {
  modals: [],
};

const reducer = produce((base: IState, action: any) => {
  switch (action.type) {
    case actions.ADD_MODAL: {
      const modal = action.payload as IModal;
      const modalArray = base.modals;
      // To avoid the typescript can't assign type warning.
      const newModal = { ...modal };
      modalArray.push(newModal);

      base.modals = modalArray;

      break;
    }
    case actions.REMOVE_MODAL: {
      const modalArray: Array<IModal> = base.modals;

      base.modals = modalArray.filter((e) => e.id !== action.payload);

      break;
    }
    case actions.RESET_MODAL_ARRAY: {
      base.modals = [];

      break;
    }
  }
}, initialState);

export default reducer;
