import { createAction } from "../../redux-helpers";

export const SET_DESKTOP_CAMPAIGN_ACTIVE_TAB = "SET_DESKTOP_CAMPAIGN_ACTIVE_TAB";
export const RESET_DESKTOP_CAMPAIGN_ACTIVE_TAB = "RESET_DESKTOP_CAMPAIGN_ACTIVE_TAB";
export const SET_DESKTOP_CAMPAIGNS_NEED_UPDATE = "SET_DESKTOP_CAMPAIGNS_NEED_UPDATE";

export const setDesktopCampaignActiveTab = createAction(SET_DESKTOP_CAMPAIGN_ACTIVE_TAB);
export const resetDesktopCampaignActiveTab = createAction(
  RESET_DESKTOP_CAMPAIGN_ACTIVE_TAB
);

export const setDesktopCampaignsNeedUpdate = createAction(
  SET_DESKTOP_CAMPAIGNS_NEED_UPDATE
);
