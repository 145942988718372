import { Dispatch } from "redux";
import Axios from "../../../common/utils/axiosConfig";
import { arrayToMapIndex } from "../utils";
import { parsePhoneNumbers } from "./transformers";
import { ApiFetchStatus } from "module/common/helpers/variables";

import {
  setPhoneNumberStatus,
  fetchPhoneNumbersError,
  fetchPhoneNumbersSuccess,
  updatePhoneNumber,
} from "./actions";
import { selectUserData } from "../Auth/selectors";
import { userHasPermission } from "module/common/components/WithPermissions";
import { NUMBER_MANAGER_RELEASE_ACTION } from "../../permissions/phoneNumberManager";
import { addNewToast } from "../Global/Toasts/actions";
import { IPhoneNumber } from "./interfaces";
import { IProfile } from "../Company/interfaces";

export const getPhoneNumbersList = () => async (dispatch: any) => {
  dispatch(setPhoneNumberStatus(ApiFetchStatus.Fetching));
  return Axios.get("/phone-numbers/")
    .then(({ data }) => {
      dispatch(fetchPhoneNumbersSuccess(arrayToMapIndex("id", parsePhoneNumbers(data))));
      return data;
    })
    .catch(() => dispatch(fetchPhoneNumbersError(true)))
    .finally(() => dispatch(setPhoneNumberStatus(ApiFetchStatus.Success)));
};

export const releasePhoneNumber =
  (phoneNumber: IPhoneNumber) =>
  async (dispatch: Dispatch, getState: (...args: any[]) => any) => {
    const state = getState();
    const { profile }: { [profile: string]: IProfile } = selectUserData(state);

    // guard against any dom-manipulation from client-side
    if (userHasPermission(profile, NUMBER_MANAGER_RELEASE_ACTION)) {
      dispatch(updatePhoneNumber({ ...phoneNumber, status: "released" }));
      dispatch(addNewToast({ message: "Number has been released.", color: "success" }));
      return Axios.post(`/phone-numbers/${phoneNumber.id}/release/`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          dispatch(
            addNewToast({
              message: "Could not release number. Please try again later.",
              color: "danger",
            })
          );
          dispatch(updatePhoneNumber(phoneNumber));
        });
    }

    return new Promise((resolve: (...args: any[]) => void) => resolve(null));
  };

export const updatePhoneStatus =
  (phoneNumber: IPhoneNumber, payload: Partial<IPhoneNumber>) =>
  async (dispatch: Dispatch) => {
    dispatch(updatePhoneNumber({ ...phoneNumber, ...payload }));
    dispatch(addNewToast({ message: "Updated Number Status.", color: "success" }));
    return Axios.patch(`/phone-numbers/${phoneNumber.id}/`, payload)
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        dispatch(
          addNewToast({
            message: "Something went wrong while updating phone status.",
            color: "danger",
          })
        );
        dispatch(updatePhoneNumber(phoneNumber));
      });
  };
