import React from "react";
import { Form, Input } from "reactstrap";
import InputGroupBorder from "module/common/components/InputGroupBorder";
import { Fetching } from "module/common/helpers/variables";
import { LoadingButton } from "../../../common/components/Buttons";

function NoteForm(props) {
  const { text, setText, submitNote, note, btnText, notesStatus } = props;
  const onSubmit = (e) => {
    e.preventDefault();
    if (!text) return;
    submitNote(note);
  };

  return (
    <Form onSubmit={onSubmit}>
      <InputGroupBorder border="full">
        <Input
          spellCheck
          style={{ maxHeight: "50vh" }}
          type="textarea"
          placeholder="Enter Your Note..."
          value={text}
          rows="12"
          data-test="notes-input"
          onChange={(e) => setText(e.target.value)}
        />
      </InputGroupBorder>
      <div className="d-flex justify-content-end align-items-center mt-2">
        <LoadingButton
          color="primary"
          size="md"
          data-test="note-form-btn"
          loading={notesStatus === Fetching}
        >
          {btnText}
        </LoadingButton>
      </div>
    </Form>
  );
}

export default NoteForm;
