import { createAction } from "../../../redux-helpers";

export const SET_DIRECT_MAIL_CAMPAIGN_PROSPECT_IDS =
  "SET_DIRECT_MAIL_CAMPAIGN_PROSPECT_IDS";
export const setDirectMailCampaignProspectIds = createAction(
  SET_DIRECT_MAIL_CAMPAIGN_PROSPECT_IDS
);

export const SET_ACTIVE_DIRECT_MAIL_CAMPAIGN_ID = "SET_ACTIVE_DIRECT_MAIL_CAMPAIGN_ID";
export const setActiveDirectMailCampaignId = createAction(
  SET_ACTIVE_DIRECT_MAIL_CAMPAIGN_ID
);

export const SET_DIRECT_MAIL_CAMPAIGN_PROSPECTS_IS_LOADING =
  "SET_DIRECT_MAIL_CAMPAIGN_PROSPECTS_IS_LOADING";
export const setDirectMailCampaignProspectsIsLoading = createAction(
  SET_DIRECT_MAIL_CAMPAIGN_PROSPECTS_IS_LOADING
);

export const SET_DIRECT_MAIL_CAMPAIGN_PROSPECTS_IS_LOADING_MORE =
  "SET_DIRECT_MAIL_CAMPAIGN_PROSPECTS_IS_LOADING_MORE";
export const setDirectMailCampaignProspectsIsLoadingMore = createAction(
  SET_DIRECT_MAIL_CAMPAIGN_PROSPECTS_IS_LOADING_MORE
);

export const SET_DIRECT_MAIL_NEXT_CAMPAIGN_PROSPECT_IDS =
  "SET_DIRECT_MAIL_NEXT_CAMPAIGN_PROSPECT_IDS";
export const setDirectMailNextCampaignProspectIds = createAction(
  SET_DIRECT_MAIL_NEXT_CAMPAIGN_PROSPECT_IDS
);

export const SET_DIRECT_MAIL_CAMPAIGN_SCHEDULE = "SET_DIRECT_MAIL_CAMPAIGN_SCHEDULE";
export const setDirectMailCampaignSchedule = createAction(
  SET_DIRECT_MAIL_CAMPAIGN_SCHEDULE
);

export const REMOVE_DIRECT_MAIL_RECIPIENTS = "REMOVE_DIRECT_MAIL_RECIPIENTS";
export const removeDirectMailRecipients = createAction(REMOVE_DIRECT_MAIL_RECIPIENTS);

export const SET_DM_CAMPAIGN_PROSPECTS_IS_UPLOADING =
  "SET_DM_CAMPAIGN_PROSPECTS_IS_UPLOADING";
export const setDirectMailCampaignProspectsIsUploading = createAction(
  SET_DM_CAMPAIGN_PROSPECTS_IS_UPLOADING
);
