import React from "react";
import styled, { css } from "styled-components/macro";
import Header from "./Header";
import Dots from "./ProgressDots/Dots";

const MultiStepWizard = ({ title = "", steps, currentStep, stepErrorId = -1 }) => {
  const numberOfSteps = steps.length;
  const stepNumber = Math.min(currentStep, numberOfSteps - 1);
  // group elements
  const groups = [...new Set(steps.map(({ group }) => group))];
  const currentGroupId = groups.indexOf(steps[currentStep]?.group);
  const groupErrorId = groups.indexOf(steps[stepErrorId]?.group);

  const {
    Component,
    stepTitle,
    props,
    className,
    buttons = [],
    isCard,
  } = steps[stepNumber];

  return (
    <div className="pageContent d-flex flex-column" data-test="multi-step-wizard">
      {title ? <Header>{title}</Header> : null}
      <div className="my-auto">
        <StyledStepTitle className="mb-0">{stepTitle}</StyledStepTitle>
        <div className="d-flex mb-3">
          <StyledContainer isCard={isCard} className={className}>
            <Component {...props} />
            <div className="d-flex flex-row justify-content-between mt-3">
              {buttons.map((button, idx) => (
                <React.Fragment key={idx}>{button}</React.Fragment>
              ))}
            </div>
          </StyledContainer>
        </div>
      </div>
      <Dots
        currentStep={currentGroupId + 1}
        steps={groups.length}
        stepErrorId={groupErrorId + 1}
      />
    </div>
  );
};

const StyledContainer = styled.div`
  ${(props) =>
    props.isCard
      ? css`
          padding: var(--pad7);
          border-radius: 20px;
          background-color: #ffffff;
          border: none;
          box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.1);
        `
      : null}
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
`;

export const StyledInputSelectContainer = styled.div`
  display: flex
  flex: 1 1 100%;
  &>div{
    min-width: 25vw;
    color: white;
  }
`;

const StyledStepTitle = styled.h3`
  text-align: center;
  padding-top: var(--pad7);
  padding-bottom: var(--xpad);
  font-weight: bold;
  color: var(--sherpaBlue);
`;

export default MultiStepWizard;
