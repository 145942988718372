import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";

// components
import InputGroupBorder from "module/common/components/InputGroupBorder";
import { AutoSizedTextArea } from "./TextArea";

const TextAreaField = ({ field }) => {
  const { register } = useFormContext();
  const textAreaRef = useRef();

  return (
    <InputGroupBorder className="mt-2" border="full">
      <AutoSizedTextArea
        inputProps={{
          rows: "3",
          name: field.name,
          ...field.inputProps,
        }}
        textAreaRef={textAreaRef}
        innerRef={(e) => register(e, field.rules)}
      />
    </InputGroupBorder>
  );
};
export default TextAreaField;
