import { createAction } from "../redux-helpers";

export const POPULATE_PROSPECT_RELAYS = "POPULATE_PROSPECT_RELAYS";
export const populateProspectRelays = createAction(POPULATE_PROSPECT_RELAYS);

export const REMOVE_PROSPECT_RELAY = "REMOVE_PROSPEgCT_RELAY";
export const removeProspectRelay = createAction(REMOVE_PROSPECT_RELAY);

export const SET_PROSPECT_RELAY_STATUS = "SET_PROSPECT_RELAY_STATUS";
export const setProspectRelayStatus = createAction(SET_PROSPECT_RELAY_STATUS);

export const ADD_PROSPECT_RELAY = "ADD_PROSPECT_RELAY";
export const addProspectRelay = createAction(ADD_PROSPECT_RELAY);

export const RESET_RELAYS = "RESET_RELAYS";
export const resetRelays = createAction(RESET_RELAYS);
