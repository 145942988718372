import { createSelector as createCachedSelector } from "reselect";
import { ICampaign } from "./interfaces";
import { createSelectorContext } from "../redux-helpers";
import { path } from "./reducers";

const createSelector = createSelectorContext(path);

export const getAllCampaigns = createSelector("campaigns");

export const campaignsStatus = createSelector("status");
export const campaignIsLoading = createSelector("isLoading");

export const getCampaign = createCachedSelector(
  (_: any, campaignId: number) => campaignId,
  getAllCampaigns,
  (campaignId: number, campaigns: Record<string, ICampaign>) =>
    campaigns[campaignId] || {}
);

export const campaignIsLoadingMore = createSelector("isLoadingMore");

export const getCampaignsByIds = createCachedSelector(
  getAllCampaigns,
  (_: any, ids: Array<number>) => ids,
  (campaigns: any, ids: Array<number>) => {
    const campaignData: Array<any> = [];

    if (!ids) return [];

    ids.forEach((value) => {
      if (campaigns[value]) {
        campaignData.push(campaigns[value]);
      }
    });
    return campaignData;
  }
);
