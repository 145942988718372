import React from "react";
import styled from "styled-components/macro";

interface Props {
  initials: string;
  size?: string;
  color?: string;
  className?: string;
}

const NameBubble: React.FC<Props> = ({
  initials,
  size = "2.5rem",
  color = "yellow",
  className = "",
}) => {
  return (
    <Bubble
      className={`${className} nameBubble`}
      data-test="name-bubble"
      size={size}
      color={color}
      initials={initials.toUpperCase()}
    />
  );
};

const Bubble = styled.div<Required<Props>>`
  --size: ${(props) => props.size};
  --initials: ${(props) => `'${props.initials}'`};
  --color: ${(props) => `var(--${props.color})`};
  background-color: var(--color);
  width: var(--size);
  height: var(--size);
  border-radius: 5px;
  color: white;
  line-height: var(--size);
  font-size: calc(var(--size) * 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  position: relative;
  flex-shrink: 0;
  &:after {
    content: var(--initials);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default NameBubble;
