import React, { useState, useEffect } from "react";
import { FormGroup, Label, Button, Input } from "reactstrap";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// components
import FormRenderer from "../../../common/components/FormRenderer";

// redux related
import { fetchMarkets } from "../../../main/store/Markets/thunks";
import { selectActiveMarkets } from "../../../main/store/Markets/selectors";
import { getPropertyTags } from "../../../main/store/Tags/property/selectors";
import { createCampaign } from "../../../main/store/Campaigns/thunks";
import { addNewToast } from "../../../main/store/Global/Toasts/actions";
import { campaignFetchAll } from "../../../main/store/Campaigns/api";
import { getCompanyData } from "../../../main/store/Company/selectors";

// utils
import { toSelectOptions } from "../../../common/helpers/utils";

// styled components
import { SlideLeft } from "assets/styles/sc/transitions";

// services
import { prospectPushToCampaign } from "module/main/services/Prospect";

const StyledLabel = styled(Label)`
  margin-right: 4rem;
`;

const StyledFormGroup = styled(FormGroup)`
  * {
    font-weight: bold !important;
  }
`;

const Container = styled.div`
  flex: 0 0 ${(props) => props.$size}%;
`;

const StyledRadioOption = styled(Input)`
  margin-top: 2px !important;
`;

const StyledWarning = styled.span`
  color: var(--gray);
`;

const successIcon = (
  <FontAwesomeIcon icon="check-circle" color="var(--green)" size="2x" />
);

const warningIcon = (
  <FontAwesomeIcon icon="exclamation-triangle" color="var(--warning)" />
);

const getConditionalFields = (pushToCampaignType, campaigns, markets) => {
  const campaignOptions = toSelectOptions(campaigns, { label: "name", value: "id" });
  const marketOptions = toSelectOptions(markets, { label: "name", value: "id" });

  if (pushToCampaignType === "new") {
    return [
      [
        {
          name: "name",
          component: "input",
          label: "Name Your Campaign",
          inputProps: {},
          rules: {
            required: "Required",
            maxLength: {
              value: 255,
              message: "Must be 255 characters in length or less.",
            },
          },
        },
      ],
      [
        {
          name: "market",
          component: "select",
          label: "Market",
          inputProps: {
            options: marketOptions,
          },
          rules: {
            required: "Required",
          },
        },
      ],
    ];
  }

  return [
    [
      {
        name: "campaign",
        component: "select",
        label: "Choose from Existing Campaigns",
        inputProps: { placeholder: "Select", options: campaignOptions },
        rules: { required: "Required" },
      },
    ],
  ];
};

const PushToSMSCampaignForm = ({ closeModal, updateCampaign, prospect }) => {
  const [showPhase1, setNextPhase] = useState(0);
  const [pushToCampaignType, setPushToCampaignType] = useState("existing");
  const [campaigns, setCampaigns] = useState([]);

  // selectors
  const activeMarkets = useSelector(selectActiveMarkets);
  const propertyTags = useSelector(getPropertyTags);
  const company = useSelector(getCompanyData);

  const dispatch = useDispatch();

  const propertyTagOptions = toSelectOptions(propertyTags, {
    label: "name",
    value: "id",
  });

  const fields = [
    ...getConditionalFields(pushToCampaignType, campaigns, activeMarkets),
    [
      {
        name: "propertyTags",
        component: "select",
        label: "Property Tags",
        inputProps: {
          options: propertyTagOptions,
          isMulti: true,
        },
        tooltip: {
          id: "property-tags-push-to-campaign-modal",
          text: "Custom property tags and distress indicators may be created under the Tag Management tab in your Account Settings.",
        },
      },
    ],
  ];

  const handlePushToCampaign = ({
    payload,
    campaignId,
    setSubmittingState,
    prospectId,
  }) => {
    prospectPushToCampaign(prospectId, { campaign: campaignId, ...payload })
      .then(() => {
        setSubmittingState(false);
        setNextPhase(2);
      })
      .catch(() => {
        setSubmittingState(false);
        dispatch(
          addNewToast({
            message: "Could not push to Campaign, please try again.",
            color: "danger",
          })
        );
      });
  };

  const onSubmit = ({ data, setSubmittingState }) => {
    setSubmittingState(true);
    const payload = {
      importType: "all",
      tags: data.propertyTags ? data.propertyTags.map((tag) => tag.value) : [],
    };

    if (data.hasOwnProperty("campaign")) {
      handlePushToCampaign({
        payload,
        campaignId: data.campaign.value,
        prospectId: prospect,
        setSubmittingState,
        updateCampaign,
      });
    } else {
      dispatch(createCampaign({ name: data.name, market: data.market.value }))
        .then((response) => {
          // if campaign creating is good, now we can push to campaign
          setSubmittingState(false);
          handlePushToCampaign({
            payload,
            prospectId: prospect,
            campaignId: response.id,
            setSubmittingState,
          });
        })
        .catch(() => {
          dispatch(
            addNewToast({
              message: "Could not create a new Campaign, please try again.",
              color: "danger",
            })
          );
          setSubmittingState(false);
        });
    }
  };

  useEffect(() => {
    campaignFetchAll({ is_archived: false })
      .then(({ data }) => {
        // only pass in sms campaigns
        setCampaigns(data.filter(({ isDirectMail }) => !isDirectMail));

        if (!data.length) {
          setPushToCampaignType("new");
        }
      })
      .catch(() => {
        // TODO: add an error?
      });
    // fetch all markets
    dispatch(fetchMarkets());
  }, [dispatch]);

  return (
    <div data-test="push-to-campaign-form">
      <div>
        <SlideLeft
          in={showPhase1 === 0}
          timeout={300}
          appear
          className="slide"
          unmountOnExit
        >
          <div>
            {company.unmanagedMarketProspectsRemaining === 0 ? (
              <div className="mb-3">
                {warningIcon}{" "}
                <StyledWarning>
                  You have {company.unmanagedMarketProspectsRemaining} uploads remaining.
                  You may be charged.
                </StyledWarning>
              </div>
            ) : (
              <div className="mb-3">
                <StyledWarning className="mb-3">
                  Total Uploads Remaining: {company.unmanagedMarketProspectsRemaining}
                </StyledWarning>
              </div>
            )}
            <StyledFormGroup className="d-flex align-items-center mb-4">
              <Container $size="20" className="mr-3">
                Campaign
              </Container>
              <Container $size="80">
                <StyledLabel>
                  <StyledRadioOption
                    type="radio"
                    onChange={() => setPushToCampaignType("existing")}
                    checked={pushToCampaignType === "existing"}
                  />
                  Existing Campaign
                </StyledLabel>
                <Label>
                  <StyledRadioOption
                    type="radio"
                    onChange={() => setPushToCampaignType("new")}
                    checked={pushToCampaignType === "new"}
                  />
                  New Campaign
                </Label>
              </Container>
            </StyledFormGroup>
            <FormRenderer
              fields={fields}
              onSubmit={onSubmit}
              formId="push-single-prospect-to-campaign-form"
              buttonProps={{ name: "Add to Campaign" }}
              onCancel={closeModal}
            />
          </div>
        </SlideLeft>
        <SlideLeft
          in={showPhase1 === 2}
          timeout={300}
          appear={false}
          classNames="slide"
          className="d-flex flex-column justify-content-center"
          data-test="phase3"
          unmountOnExit
        >
          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex justify-content-center">{successIcon} </div>
            <h4 className="text-center">Push to Campaign Completed</h4>
            <div className="textL text-center">
              Prospect has been added to the selected campaign and you can start sending
              messages immediately.
            </div>
            <div className="d-flex justify-content-center mt-2">
              <Button
                className="px-4"
                color="primary"
                size="md"
                data-test="skiptrace-push-confirm"
                onClick={() => {
                  updateCampaign();
                  closeModal();
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        </SlideLeft>
      </div>
    </div>
  );
};

export default PushToSMSCampaignForm;
