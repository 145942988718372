import React, { memo } from "react";
import styled, { css } from "styled-components/macro";

import { ListGroupItem } from "reactstrap";
import PropTypes from "prop-types";

const StyledItem = styled(ListGroupItem)`
  padding: 0 !important;
  width: 100%;
  height: 100%;
  border: none !important;
  position: relative;

  ${(props) =>
    props.theme.isDesktop
      ? css`
          margin-top: var(--pad2);
          background: transparent !important;
          margin-bottom: var(--pad2);

          border-radius: 5px !important;
          transition:
            transform 0.3s,
            box-shadow 0.3s;

          & > div {
            background: transparent !important;
            border-radius: 5px !important;
            border: none;
            border-left: none !important;
            border-right: none !important;
          }
        `
      : css`
          background: transparent !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
          border-radius: 0 !important;
        `}
`;

const ItemContent = styled.div`
  flex-direction: row;

  justify-content: space-between;
  .itemBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding-right: 1rem;
  }
`;

const StyledMainContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: ${(props) => (props.hasKebab ? "2.5rem" : "")};
  margin-left: ${(props) => (props.draggableList ? "3.70rem" : "")};
  align-items: center;
  flex-grow: 2;
  flex-basis: 50%;
  > * {
    display: flex block;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: var(--pad1);
  }
`;
function ListHeader(props) {
  const { id, draggableList, hasKebab, body } = props;

  return (
    <StyledItem id={id}>
      <ItemContent>
        <StyledMainContent
          draggableList={draggableList}
          hasKebab={hasKebab}
          data-test="main-content"
        >
          {body}
        </StyledMainContent>
      </ItemContent>
    </StyledItem>
  );
}

ListHeader.propTypes = {
  /** set the draggable flag to indicate that the list item can be dragged */
  draggableList: PropTypes.bool,
  /** Header Content*/
  body: PropTypes.node,
  /** flag that determines if the sidebar is present */
  hasSidebar: PropTypes.bool,
  /**  flag that determines if the kebab is present*/
  hasKebab: PropTypes.bool,
  /** the id of the item row */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ListHeader.defaultProps = {
  hasSidebar: false,
  draggableList: false,
  body: null,
  id: 1,
};

/** Workaround so the props can be displayed in the storybook docs */
export const ListItemComponent = ListHeader;
export default memo(ListHeader);
