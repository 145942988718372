const DAY_IN_MILLISECONDS = 86400000;

export const setCookie = (name: string, value: string, days: number = 0) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * DAY_IN_MILLISECONDS);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/`;
};

export const getCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") cookie = cookie.substring(1, cookie.length);
    if (cookie.indexOf(nameEQ) === 0)
      return cookie.substring(nameEQ.length, cookie.length);
  }
  return null;
};

export const eraseCookie = (name: string) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const getAndEraseCookie = (name: string) => {
  const cookie = getCookie(name);
  eraseCookie(name);
  return cookie;
};
