import { createSelectorContext } from "../redux-helpers";
import { path } from "./reducers";

const createSelector = createSelectorContext(path);

export const selectPlanChoices = createSelector("planChoices");
export const selectPlainPlanChoices = createSelector("plainPlanChoices");
export const selectPlanStatus = createSelector("status");
export const selectEntitlements = createSelector("entitlements");
export const selectEntitlementsStatus = createSelector("entitlementsStatus");
