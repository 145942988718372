import { createAction } from "../../redux-helpers";

export const FETCH_PAYMENT_INFO = "FETCH_PAYMENT_INFO";
export const SET_PAYMENT_INFO = "SET_PAYMENT_INFO";
export const FETCH_PAYMENT_INFO_ERROR = "FETCH_PAYMENT_INFO_ERROR";
export const SET_PAYMENT_INFO_STATUS = "SET_PAYMENT_INFO_STATUS";

export const fetchPaymentInfo = createAction(FETCH_PAYMENT_INFO);
export const setPaymentInfo = createAction(SET_PAYMENT_INFO);
export const fetchPaymentInfoError = createAction(FETCH_PAYMENT_INFO_ERROR);
export const setPaymentInfoStatus = createAction(SET_PAYMENT_INFO_STATUS);
