import { useEffect, useState } from "react";
// utils
import { KeyNames } from "module/common/helpers/variables";
import useKeyUpPress from "./useKeyUpPress";

type HookType = (
  avoidScroll?: boolean
) => [number, React.Dispatch<React.SetStateAction<number>>];

const useUpDownNavigation: HookType = (avoidScroll = false) => {
  const [indexChange, setIndexChange] = useState<number>(0);

  const onKeyPressCallback = (pressedKey: KeyNames) => {
    const newIndexChange = pressedKey === KeyNames.ArrowUp ? -1 : 1;
    setIndexChange(newIndexChange);
  };

  useKeyUpPress([KeyNames.ArrowUp, KeyNames.ArrowDown], onKeyPressCallback, "altKey");

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if ([KeyNames.ArrowUp, KeyNames.ArrowDown].includes(e.key as KeyNames)) {
        e.preventDefault();
      }
    };
    if (avoidScroll) {
      window.addEventListener("keydown", keyDownHandler);
    }
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [avoidScroll]);

  return [indexChange, setIndexChange];
};

export default useUpDownNavigation;
