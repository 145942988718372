import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useFormContext } from "react-hook-form";
import { Input } from "reactstrap";
import styled from "styled-components";

import InputGroupBorder from "module/common/components/InputGroupBorder";

const PlusMinusNumberField = ({
  displayedInitial = 0,
  min,
  max,
  step,
  field,
  error,
  ...rest
}) => {
  const { register, watch, setValue } = useFormContext();
  const { name } = field;
  const selection = watch(name);
  const selectionWithInitial = selection + displayedInitial;

  register({ name }, field.rules);

  const subtract = () => {
    if (selection <= min) return;
    setValue(name, selection - step);
  };

  const add = () => {
    if (selection >= max) return;
    setValue(name, selection + step);
  };
  return (
    <InputGroupBorder class="wrapper d-flex input-group" error={error}>
      <FontAwesomeIcon {...defaultIconProps} icon="minus" onClick={subtract} />
      <SmallInput
        className="mx-2"
        type="number"
        readOnly="readonly"
        value={selectionWithInitial || 0}
        name={name}
        {...rest}
      />
      <FontAwesomeIcon {...defaultIconProps} icon="plus" onClick={add} />
    </InputGroupBorder>
  );
};

const defaultIconProps = {
  className: "align-self-center",
  size: "sm",
  role: "button",
};

const SmallInput = styled(Input)`
  max-width: 30px;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  -moz-appearance: textfield; /* Firefox */

  &[readOnly] {
    background-color: transparent;
  }
`;

export default PlusMinusNumberField;
