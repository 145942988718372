import moment from "moment-timezone";
import { formatPhoneNumber } from "../../../common/helpers/utils";

const parseDatetime = (datetime) => {
  let parsedDate = "----";
  let dt = datetime;

  if (datetime) {
    dt = moment(datetime);
    const date = dt.format("MMM DD YYYY");
    const time = dt.format("LT");

    parsedDate = `${date} | ${time}`;
  }

  return [dt, parsedDate];
};

export const parsePhoneNumbers = (numbers) =>
  numbers.map((number) => {
    const parsedNumber = { ...number };
    parsedNumber.phoneRaw = parsedNumber.phone;
    parsedNumber.phone = formatPhoneNumber(parsedNumber.phone);
    [parsedNumber.created, parsedNumber.parsedCreated] = parseDatetime(number.created);
    [parsedNumber.lastSend, parsedNumber.parsedLastSend] = parseDatetime(number.lastSend);
    [parsedNumber.lastReceived, parsedNumber.parsedLastReceived] = parseDatetime(
      number.lastReceived
    );
    return parsedNumber;
  });
