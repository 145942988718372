import React, { useState } from "react";
import { Button } from "reactstrap";
import styled from "styled-components";
import { requiredParams } from "../formFields/FormFieldsRenderer";
import { generalNetworkError } from "module/common/helpers/variables";
import FormRenderer from "../FormRenderer";
import { LoadingButton } from "../Buttons";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewCustomLeadStage,
  updateLeadStage,
  removeLeadStage,
} from "../../../main/store/leadstages/thunk";
import { prospectsResults } from "../../../main/store/prospectStore/selectors";
import { addNewToast } from "../../../main/store/Global/Toasts/actions";

// styled-components
import { ButtonsContainer } from "assets/styles/sc/layout";

const StyledErrorSpan = styled.span`
  color: var(--red);
`;

const StyledLeadStageTitle = styled.span`
  font-weight: 900 !important;
`;

const CustomLeadStageForm = (props) => {
  const { onDeleteSuccess, onSuccess, type, leadStageData, onBack, buttonProps } = props;
  const isActive =
    Object.keys(leadStageData).length === 0 ? true : leadStageData.isActive;

  //local state
  const [checked, setChecked] = useState(isActive);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(null);

  const dispatch = useDispatch();

  const onAdd = (data) => dispatch(addNewCustomLeadStage(data));
  const onEdit = (id, data) => dispatch(updateLeadStage(id, data, false, true));
  const onDelete = (id) => dispatch(removeLeadStage(id, prospects));

  const prospects = useSelector(prospectsResults);

  const onSubmit = ({ data, setServerError, setSubmittingState }) => {
    const postData = {
      ...data,
      isActive: checked,
    };

    setSubmittingState(true);
    onSubmitAction(postData)
      .then(() => {
        onSuccess();
        setSubmittingState(false);
      })
      .catch(({ response }) => {
        let errorMessage = [generalNetworkError];
        if (response && response.data) {
          const serverError = Object.values(response.data)[0];
          errorMessage =
            "nonFieldErrors" in response.data &&
            serverError[0].includes("lead_stage_title")
              ? ["Lead Stage name already exists, please choose another."]
              : serverError;
        }
        setServerError(errorMessage);
        setSubmittingState(false);
      });
  };
  const onConfirmDeleteAction = () => {
    setDeleteErrorMessage(null);
    setIsConfirmingDelete(true);
    onDelete(leadStageData.id)
      .then(() => {
        dispatch(
          addNewToast({
            message: `Lead stage ${leadStageData.leadStageTitle} has been deleted and removed from existing prospects that had this lead
        stage.`,
            type: "success",
          })
        );
        onDeleteSuccess();
        setIsConfirmingDelete(false);
      })
      .catch(() => {
        setIsConfirmingDelete(false);
        const errorMessage = [generalNetworkError];
        setDeleteErrorMessage(errorMessage);
      });
  };
  const onSubmitAction = (data) => {
    switch (type) {
      case "add":
        return onAdd(data);
      case "edit":
        return onEdit(leadStageData.id, data);
      default:
        return null;
    }
  };

  const fields = [
    [
      {
        key: "name",
        name: "leadStageTitle",
        label: "Name",
        component: "input",
        inputProps: {
          type: "text",
          placeholder: "Enter the Lead Stage title",
          "data-test": "lead-stage-title-input",
        },
        rules: {
          ...requiredParams,
          maxLength: {
            value: 32,
            message: "Must be 32 characters in length or less.",
          },
        },
      },
    ],
    [
      {
        key: "description",
        name: "description",
        label: "Description",
        component: "input",
        inputProps: {
          type: "text",
          placeholder: "Enter a description",
          "data-test": "lead-stage-description-input",
        },
        rules: {
          maxLength: {
            value: 64,
            message: "Must be 64 characters in length or less.",
          },
        },
      },
    ],
    [
      {
        key: "isActive",
        name: "isActive",
        label: "Active",
        component: "switch",
        innerRef: "isActive",
        inputProps: {
          id: "isActiveSwitch",
          onChange: () => setChecked(!checked),
          checked: checked,
          leftLabel: "Off",
          rightLabel: "On",
          isOffOnSwitch: true,
          "data-test": "lead-stage-description-switch",
        },
      },
    ],
  ];
  const renderDeleteContent = (onClickAction, btnText, content) => {
    return (
      <div>
        <p className="textXL">{content}</p>
        <p className="text-center">
          {deleteErrorMessage !== null ? (
            <StyledErrorSpan>{deleteErrorMessage}</StyledErrorSpan>
          ) : null}
        </p>
        <ButtonsContainer>
          {onBack ? (
            <Button className="back-button mt-2 pr-4 pl-4" type="button" onClick={onBack}>
              Back
            </Button>
          ) : null}
          <LoadingButton
            color="primary"
            className="mt-2 pr-4 pl-4"
            data-test="lead-stage-delete-confirm"
            type="submit"
            value={btnText}
            loading={isConfirmingDelete}
            onClick={onClickAction}
          >
            {btnText}
          </LoadingButton>
        </ButtonsContainer>
      </div>
    );
  };

  const renderDeleteMessageConfirmation = (leadStageTitle) => {
    return (
      <>
        Are you sure you want to delete{" "}
        <StyledLeadStageTitle>{leadStageTitle}</StyledLeadStageTitle>? It will be removed
        from the prospects and the action is irreversible.
      </>
    );
  };

  const renderContent = (type) => {
    switch (type) {
      case "delete":
        return renderDeleteContent(
          onConfirmDeleteAction,
          "Confirm",
          renderDeleteMessageConfirmation(leadStageData.leadStageTitle)
        );
      case "add":
      case "edit":
        return (
          <FormRenderer
            fields={fields}
            onSubmit={onSubmit}
            defaultValues={leadStageData}
            onBack={onBack}
            buttonProps={buttonProps}
          />
        );
      default:
        return null;
    }
  };

  return renderContent(type);
};
export default CustomLeadStageForm;
