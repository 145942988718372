import { produce } from "immer";
import { Fetching, Success, FetchError } from "module/common/helpers/variables";
import {
  POPULATE_PROSPECT_NOTES,
  SET_PROSPECT_NOTE_ERROR,
  SET_PROSPECT_NOTES_STATUS,
  ADD_PROSPECT_NOTE,
  EDIT_PROSPECT_NOTE,
  DELETE_PROSPECT_NOTE,
} from "./actions";
import { IResults, IState } from "./interfaces";

const initialState: IState & IResults = {
  count: 0,
  next: null,
  previous: null,
  error: "",
  list: {},
  status: Fetching,
};

const reducer = produce((draft: IState & IResults, action: any) => {
  switch (action.type) {
    case POPULATE_PROSPECT_NOTES: {
      draft.list = { ...draft.list, ...action.payload };
      draft.status = Success;
      break;
    }
    case SET_PROSPECT_NOTES_STATUS: {
      draft.status = action.payload;
      break;
    }
    case SET_PROSPECT_NOTE_ERROR: {
      draft.error = action.payload;
      draft.status = FetchError;
      break;
    }
    case ADD_PROSPECT_NOTE: {
      const { id, results } = action.payload;

      if (!draft.list[id]) draft.list[id] = [results];
      else draft.list[id].unshift(results);

      draft.status = Success;
      break;
    }
    case EDIT_PROSPECT_NOTE: {
      const { id, noteId, results } = action.payload;
      draft.list[id] = draft.list[id].map((item) => {
        if (item.id !== noteId) return item;
        return results;
      });
      draft.status = Success;
      break;
    }
    case DELETE_PROSPECT_NOTE: {
      const { id, noteId } = action.payload;

      draft.list[id] = draft.list[id].filter(({ id }) => id !== noteId);
      draft.status = Success;
      break;
    }
  }
}, initialState);

export default reducer;
