import React, { Component } from "react";
import styled, { css } from "styled-components/macro";
import * as Sentry from "@sentry/browser";

const Boundary = styled.div`
  text-align: left;
  line-height: 1.5;
  width: 550px;
  max-width: 40vw;
  margin-bottom: 15vh;
  border-left: 5px solid var(--info);
  padding-left: 1.5rem;

  h3 {
    font-weight: 400;
  }

  h3 {
    color: var(--info);
    font-weight: 400;
    margin-bottom: 0;

    ${(props) =>
      props.theme.isDesktop
        ? css`
            margin-top: var(--pad1);
          `
        : css`
            margin-top: var(--pad3);
            max-width: 75vw;
            margin-bottom: 0;
          `}
  }
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;

  width: 100vw;
  background: var(--white);
  color: var(--darkNavy);
`;

const initialState = {
  eventId: null,
  hasError: false,
};

class ErrorBoundary extends Component {
  state = { ...initialState };

  componentDidUpdate(_, prevState) {
    if (this.state === prevState) {
      this.setState(initialState);
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorWrapper data-test="error-wrapper">
          <Boundary data-test="error-boundary">
            <h3 className="m-0">
              Oops! It looks like an error has occurred. Try refreshing the page and if
              the problem persists, contact{" "}
              <a href="mailto:support@leadsherpa.com">support@leadsherpa.com</a>
            </h3>
            <h4>- Lead Sherpa Team</h4>
          </Boundary>
        </ErrorWrapper>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
