import AxiosConfig from "../../../common/utils/axiosConfig";

export const getLeadStages = () => {
  return AxiosConfig.get("/leadstages/");
};
export const addLeadStage = (data: {
  id: number;
  leadStageTitle: string;
  isActive: boolean;
  description: string;
}) => {
  return AxiosConfig.post("/leadstages/", data);
};

export const editLeadStage = (
  id: number,
  data: {
    leadStageTitle: string;
    isActive: boolean;
    description: string;
    sortOrder: number;
  }
) => {
  return AxiosConfig.put(`/leadstages/${id}/`, data);
};

export const deleteLeadStage = (id: number) => {
  return AxiosConfig.delete(`/leadstages/${id}/`);
};
