import React, { lazy, Suspense } from "react";
import { ReactstrapSpinner } from "module/common/components/ReactstrapSpinner";

interface Props {
  loader: any;
  size?: string;
  fullSize?: boolean;
}

const Loadable = ({ loader, size, fullSize }: Props) => {
  const LazyLoadedComponent = lazy(loader);

  return ({ forwardedRef, ...rest }: any) => (
    <Suspense fallback={<ReactstrapSpinner size={size} fullSize={fullSize} />}>
      <LazyLoadedComponent ref={forwardedRef} {...rest} />
    </Suspense>
  );
};

export default Loadable;
