/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from "react";
import { Alert } from "reactstrap";
import styled from "styled-components/macro";
import { toastLingerTime } from "module/common/helpers/variables";
import { removeToast } from "../../../store/Global/Toasts/actions";

const ShAlert = styled(Alert)`
  margin: var(--pad2) var(--pad2) var(--pad1) !important;
  transition: top 0.3s;
`;

const ToastComponent = ({
  dispatch,
  message,
  id,
  color,
  persist = false,
  removeClose = false,
}) => {
  const [show, setShow] = useState(true);

  const toggle = () => {
    setShow(false);
    dispatch(removeToast(id));
  };

  useEffect(() => {
    if (!persist) {
      const timeout = setTimeout(() => setShow(false), toastLingerTime);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [id, persist]);

  const closeOptions = removeClose ? {} : { toggle, isOpen: show };

  return (
    <ShAlert data-test="toast" color={color || "success"} {...closeOptions} t={message}>
      {message}
    </ShAlert>
  );
};

export default ToastComponent;
