import { createAction } from "../../redux-helpers";

export const SET_CAMPAIGN_FILTER = "UI/SET_CAMPAIGN_FILTER";
export const setCampaignFilter = createAction(SET_CAMPAIGN_FILTER);

export const RESET_CAMPAIGN_FILTER = "UI/RESET_CAMPAIGN_FILTER";
export const resetCampaignFilter = createAction(RESET_CAMPAIGN_FILTER);

export const SET_CAMPAIGNS = "UI/SET_CAMPAIGNS";
export const setCampaigns = createAction(SET_CAMPAIGNS);

export const SET_MORE_CAMPAIGNS = "UI/SET_MORE_CAMPAIGNS";
export const setMoreCampaigns = createAction(SET_MORE_CAMPAIGNS);

export const SET_SORTING_OPTION = "UI/SET_SORTING_OPTION";
export const setSortingOption = createAction(SET_SORTING_OPTION);

export const RESET_CAMPAIGNS = "UI/RESET_CAMPAIGNS";
export const resetCampaigns = createAction(RESET_CAMPAIGNS);

export const RESET_SORTING_OPTION = "UI/RESET_SORTING_OPTION";
export const resetSortByFilter = createAction(RESET_SORTING_OPTION);

export const SET_ACTIVE_MARKET = "UI/SET_ACTIVE_MARKET";
export const setActiveMarket = createAction(SET_ACTIVE_MARKET);

export const SET_NEXT_PAGE = "UI/SET_NEXT_PAGE";
export const setNextPage = createAction(SET_NEXT_PAGE);
