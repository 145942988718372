import { produce } from "immer";
import { Initial, Success } from "module/common/helpers/variables";

import {
  SET_SMART_STACKER_ACTIVE_TAB,
  SET_SMART_STACKER_LIST_RESULTS,
  SET_SMART_STACKER_LIST_SEARCH_RESULTS,
  SET_SMART_STACKER_STATUS,
  SET_SMART_STACKER_ACTIVE_PROSPECT,
} from "./actions";

export const state = {
  activeTab: "properties",
  propertiesById: [],
  prospectsById: [],
  counts: {
    properties: 0,
    prospects: 0,
  },
  totals: {
    properties: 0,
    prospects: 0,
  },
  searchAfter: {
    properties: "",
    prospects: "",
  },
  status: Initial,
  activeProspectId: 0,
};

export const path = ["uiStore", "smartStackerListView", "results"];

const reducer = produce((base: any, action: any) => {
  switch (action.type) {
    case SET_SMART_STACKER_ACTIVE_TAB: {
      base.activeTab = action.payload;
      break;
    }
    case SET_SMART_STACKER_LIST_RESULTS: {
      const { data, isFetchingNext } = action.payload;
      const { propertiesById, prospectsById, counts, totals, searchAfter } = data;

      base.status = Success;
      base.propertiesById = isFetchingNext
        ? Array.from(new Set([...base.propertiesById, ...propertiesById]))
        : propertiesById;
      base.prospectsById = isFetchingNext
        ? Array.from(new Set([...base.prospectsById, ...prospectsById]))
        : prospectsById;
      base.counts = counts;
      base.totals = totals;
      base.searchAfter = searchAfter;
      break;
    }
    case SET_SMART_STACKER_LIST_SEARCH_RESULTS: {
      base.status = Success;
      base.propertiesById = action.payload.properties;
      base.prospectsById = action.payload.prospects;
      break;
    }
    case SET_SMART_STACKER_STATUS: {
      base.status = action.payload;
      break;
    }
    case SET_SMART_STACKER_ACTIVE_PROSPECT: {
      base.activeProspectId = action.payload;
    }
  }
}, state);

export default reducer;
