import { createAction } from "../../redux-helpers";

export const REMINDERS_TAB_UPDATE_SELECTED_ITEM = "REMINDERS_TAB_UPDATE_SELECTED_ITEM";
export const REMINDERS_TAB_UPDATE_FILTER = "REMINDERS_TAB_UPDATE_FILTER";
export const REMINDERS_TAB_RESET_STATE = "REMINDERS_TAB_RESET_STATE";
export const REMINDERS_TAB_POLL_MESSAGE_COUNT = "REMINDERS_TAB_POLL_MESSAGE_COUNT";
export const REMINDERS_TAB_POLL_MESSAGES = "REMINDERS_TAB_POLL_MESSAGES";

export const remindersTabUpdateSelectedItem = createAction(
  REMINDERS_TAB_UPDATE_SELECTED_ITEM
);
export const remindersTabUpdateFilter = createAction(REMINDERS_TAB_UPDATE_FILTER);
export const remindersTabResetState = createAction(REMINDERS_TAB_RESET_STATE);
export const remindersTabActivateCountPolling = createAction(
  REMINDERS_TAB_POLL_MESSAGE_COUNT
);
export const remindersTabActivateMessagesPolling = createAction(
  REMINDERS_TAB_POLL_MESSAGES
);
