import { Button, Nav, Navbar } from "reactstrap";
import styled from "styled-components";
import { SlideIn } from "../../../components/styles";

export const LogoutBtn = styled(Button).attrs(() => ({
  color: "link",
}))`
  color: #ffffff !important;
  padding: 1em 0.5rem !important;
`;

export const LogoSlide = styled(SlideIn)`
  --width: ${(props) => props.width};
  max-width: 100%;
`;
export const StyledNavbar = styled(Navbar)`
  --openShadow: 0 18px 26px rgba(0, 0, 0, 0.6), 0 10px 10px rgba(0, 0, 0, 0.6);
  --closedShadow: 0 0 36px -4px #00000091, 0 0 5px -1px #0000008c;

  box-shadow: ${(props) => (props.$isOpen ? "var(--openShadow)" : "var(--closedShadow)")};
  min-height: 100vh;
  left: 0;
  width: ${(props) => (props.$isOpen ? "calc(8rem + 10vw)" : "calc(4rem + 35px)")};
  position: fixed !important;
  flex-basis: ${(props) => (props.$isOpen ? "300px" : "100px")};
  flex-basis: ${(props) => (props.$isOpen ? "calc(4rem + 14vw)" : "calc(4rem + 4vw)")};
  flex-shrink: 4;
  flex-grow: 0;
  padding: 0 !important;
  flex-flow: column nowrap !important;
  z-index: 999;
  transition:
    flex-basis 0.3s,
    width 0.3s;

  @media (min-width: 1800px) {
    flex-basis: ${(props) =>
      props.$isOpen ? "calc(4rem + 252px)" : "calc(4rem + 72px)"};
  }

  .navbar-brand {
    flex-grow: 0;
    margin: 0 !important;
    padding: 2rem !important;
  }
`;

export const StyledNav = styled(Nav)`
  border-top: 1px solid var(--charcoal);
  align-items: center;
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const SpecialNav = styled(StyledNav)`
  flex: 0 1 auto;
  padding: 1rem 0;
`;

export const LogoutNav = styled(StyledNav)`
  justify-content: flex-end;
  margin: 0 !important;
  flex: 0 1 auto;
  padding-bottom: 1.5rem;

  .nav-item {
    width: 100%;

    button {
      width: 100%;
    }
  }

  a {
    padding: 2rem 1rem;
  }

  &:hover,
  &:active {
    background: rgba(0, 0, 0, 0.25);
  }
`;

interface ArrowBtnHolsterProps {
  isOpen: boolean;
}

export const ArrowBtnHolster = styled.div<ArrowBtnHolsterProps>`
  position: absolute;
  /* padding and size of logo so it lines up with logo */
  top: calc(2rem + (34px / 2));
  right: 0;
  width: calc(0.6rem + 1.8vw);
  height: calc(0.6rem + 1.8vw);
  display: flex;
  transition: transform 0.15s;
  transform-origin: center center;
  transform: ${(props) =>
    props.isOpen
      ? "translate(50%,-50%) rotate(270deg)"
      : "translate(50%,-50%) rotate(-90deg)"};
  align-items: center;
  justify-content: center;
  z-index: 99;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--darkNavy);
    border-radius: 50%;
    z-index: -1;
  }

  img {
    width: calc(6px + 0.8vw);
    transition: transform 0.3s;
    transform: rotate(${(props) => (props.isOpen ? "0deg" : "180deg")});
  }

  @media (min-width: 1700px) {
    width: calc(0.8rem + 37.4px);
    height: calc(0.8rem + 37.4px);
  }
`;
