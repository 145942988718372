import { useEffect, useState, useRef } from "react";

import useRecomputeHeight from "./useRecomputeHeight";
import {
  vListHeight,
  vListItems,
  ROW_HEIGHT_PAD,
  ESTIMATED_ROW_SIZE,
} from "module/common/helpers/variables";

const useVirtualizedList = ({ data, id = "virtualizedList" }) => {
  const [itemHeight, setItemHeight] = useState(vListItems);
  const [listHeight, setListHeight] = useState(vListHeight);

  useEffect(() => {
    if (data.length > 0) {
      const sampleItem = data[0];
      const itemId = `${sampleItem.id}`;
      const item = document.getElementById(itemId);

      if (item && item.offsetHeight !== 0) {
        setItemHeight(item.offsetHeight + ROW_HEIGHT_PAD);
      }

      const windowHeight = window.innerHeight;
      const list = document.getElementById(id);
      if (list) {
        setListHeight(windowHeight - list.getBoundingClientRect().top);
      }
    }
  }, [data, id]);

  return [itemHeight, listHeight];
};

export default useVirtualizedList;

export const useVirtualized = ({
  data,
  listId,
  startingListHeight,
  listHeightPadding,
  isListLoading,
  rowHeightPadding = ROW_HEIGHT_PAD,
  estimatedRowHeight = ESTIMATED_ROW_SIZE,
  getInnerRowChildToMeasure,
  getRowId,
}) => {
  const vlist = useRef();
  const computedHeights = useRef({});
  const [needsUpdate, setNeedsUpdate] = useState(false);

  useEffect(() => {
    // resize window logic to recompute heights
    const resizeAndUpdate = () => {
      computedHeights.current = {};
      setNeedsUpdate(true);
    };
    window.addEventListener("resize", resizeAndUpdate);
    return () => window.removeEventListener("resize", resizeAndUpdate);
  }, []);

  // recompute height logic
  const listHeight = useRecomputeHeight({
    loading: isListLoading,
    nodeId: listId,
    defaultHeight: startingListHeight,
    padding: listHeightPadding,
  });

  const innerRowRef = (el) => {
    /*
      Compares the actual dom-node height vs what we have computed.
      If they do not match up, then set the computed height to the 
      actualHeight.  Then trigger the event to recompute sizes.
    */
    if (el) {
      const child = getInnerRowChildToMeasure(el);
      if (child) {
        const actualHeight = child.offsetHeight + rowHeightPadding;
        const currentHeight = computedHeights.current[child.id];

        if (actualHeight !== currentHeight) {
          computedHeights.current[child.id] = actualHeight;
          !needsUpdate && setNeedsUpdate(true);
        }
      }
    }
  };

  const getItemSize = (index) => {
    /*
      Computes item size for a given item pointed by index.
      If it doesn't find it on the computed heights then
      it'll set the default to ESTIMATED_ROW_SIZE.
    */
    const item = data[index];
    let height = estimatedRowHeight;

    if (item) {
      const id = getRowId(item);
      const currentHeights = computedHeights.current[id];

      if (!computedHeights.current[id]) {
        const node = document.getElementById(id);

        if (node) {
          const child = getInnerRowChildToMeasure(node);
          if (child) {
            computedHeights.current[id] = child.offsetHeight + rowHeightPadding;
            height = child.offsetHeight + rowHeightPadding;
          }
        } else {
          computedHeights.current[id] = height;
        }
      } else {
        height = currentHeights;
      }
    }
    return height;
  };

  useEffect(() => {
    /*
      Side Effect to recompute the virtualized list, mainly used when
      user is scrolling through the items for the first time and their dom-node
      was recently mounted(meaning the estimated height is being applied not the actual).
    */
    let isMounted = true;
    if (needsUpdate && vlist.current) {
      setTimeout(() => {
        if (isMounted) {
          vlist.current && vlist.current.recomputeSizes(0);
          setNeedsUpdate(false);
        }
      }, 2000);
    }
    return () => (isMounted = false);
  }, [needsUpdate]);

  return [vlist, listHeight, innerRowRef, getItemSize];
};
