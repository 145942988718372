import { useEffect } from "react";

const id = "MLqgpuGz16972";
const url = "https://app.getbeamer.com/js/beamer-embed.js";

const BeamerWidget = () => {
  useEffect(() => {
    const configScript = document.createElement("script");
    configScript.innerHTML = `
      var beamer_config = {
        product_id: "${id}",
        button_position: "bottom-right",
        right: 20,
        bottom: 20,
      };
    `;
    document.body.appendChild(configScript);
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src = url;
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(configScript);
    };
  }, [url]);

  return null;
};

export default BeamerWidget;
