import AxiosInstance from "../../../../common/utils/axiosConfig";

export const fetchPropertyTags = () => AxiosInstance.get("/property-tags/");

export const updatePropertyTag = (payload: any) =>
  AxiosInstance.patch(`/property-tags/${payload.id}/`, payload);

export const removePropertyTag = (payload: any) =>
  AxiosInstance.delete(`/property-tags/${payload.id}/`);

export const createPropertyTag = (payload: any) =>
  AxiosInstance.post("/property-tags/", payload);
