import { createAction } from "../redux-helpers";

export const SET_SESSION: string = "SET_SESSION";
export const SET_IS_REGISTERED: string = "SET_IS_REGISTERED";
export const SET_CALL: string = "SET_CALL";
export const SET_CALL_STATE: string = "SET_CALL_STATE";
export const SET_IS_ACTIVE: string = "SET_IS_ACTIVE";
export const SET_CALL_START_TIME: string = "SET_CALL_START_TIME";
export const SET_CALL_PROSPECT_DATA: string = "SET_CALL_PROSPECT_DATA";
export const CALL_COMPLETED: string = "CALL_COMPLETED";

export const setCallSession = createAction(SET_SESSION);
export const setIsRegistered = createAction(SET_IS_REGISTERED);
export const setCall = createAction(SET_CALL);
export const setCallState = createAction(SET_CALL_STATE);
export const setCallStartTime = createAction(SET_CALL_START_TIME);
export const setCallProspectData = createAction(SET_CALL_PROSPECT_DATA);
export const callCompleted = createAction(CALL_COMPLETED);
export const setIsActive = createAction(SET_IS_ACTIVE);
