import { formatErrorMessage } from "module/common/utils/utils";
import { addErrorToast } from "../Global/Toasts/actions";
import {
  fetchSequences,
  fetchSequencesSuccess,
  fetchSequencesFailure,
  deleteSequenceSuccess,
  fetchSingleSequence,
  fetchSingleSequenceFailure,
  fetchSingleSequenceSuccess,
  addSequenceToList,
} from "./actions";

import {
  getSequences,
  deleteSequence,
  getSequenceById,
  patchSequences,
  postNewSequence,
} from "./api";

import { ISequence } from "./interfaces";

export const fetchAllSequences = () => (dispatch: any) => {
  dispatch(fetchSequences());
  return getSequences()
    .then(({ data }) => {
      const { results }: { results: Array<ISequence> } = data;
      dispatch(fetchSequencesSuccess(results));
      return results;
    })
    .catch((error: any) => {
      dispatch(fetchSequencesFailure(error));
    });
};

export const callDeleteSequence = (id: number) => (dispatch: any) => {
  return deleteSequence(id)
    .then(() => {
      dispatch(deleteSequenceSuccess(id));
    })
    .catch((error) => {
      const errorMessage = formatErrorMessage(error);
      dispatch(addErrorToast(errorMessage));
    });
};

export const fetchSequence = (id: number) => (dispatch: any) => {
  dispatch(fetchSingleSequence());
  return getSequenceById(id)
    .then((response) => {
      dispatch(fetchSingleSequenceSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchSingleSequenceFailure());
    });
};

export const createSequence = (payload: any) => (dispatch: any) => {
  return postNewSequence(payload)
    .then((response) => {
      dispatch(addSequenceToList(response));
      return;
    })
    .catch(({ response }) => {
      const { data } = response || {};
      if (data?.name || data?.steps || data?.message) {
        throw data;
      }
    });
};

export const updateSequence = (id: number, payload: any) => () => {
  return patchSequences(id, payload)
    .then(({ data }) => {
      return data;
    })
    .catch(({ response }) => {
      const { data } = response || {};
      if (data?.name || data?.steps || data?.message) {
        throw data;
      }
    });
};
