import {
  addLeadStage,
  getLeadStages,
  editLeadStage,
  deleteLeadStage as delLeadStage,
} from "./api";

import {
  fetchLeadStages,
  fetchLeadStagesSuccess,
  fetchLeadStagesFailure,
  postLeadStage,
  postLeadStageSuccess,
  putLeadStage,
  putLeadStageSuccess,
  deleteLeadStage,
  deleteLeadStageSuccess,
} from "./actions";
import { updateProspectList } from "../prospectStore/actions";
import { arrayToMapIndex } from "../utils";
import { addNewToast } from "../Global/Toasts/actions";

export const addNewCustomLeadStage =
  (data: {
    id: number;
    leadStageTitle: string;
    isActive: boolean;
    description: string;
  }) =>
  async (dispatch: any) => {
    dispatch(postLeadStage(true));

    return addLeadStage(data)
      .then(({ data }: { [key: string]: any }) => {
        dispatch(postLeadStageSuccess({ data }));
        dispatch(
          addNewToast({
            message: `${data.leadStageTitle} has been created`,
            color: "success",
          })
        );
        return data;
      })
      .catch((err) => {
        dispatch(fetchLeadStagesFailure(err));
        dispatch(postLeadStage(false));
        dispatch(
          addNewToast({
            message: `Could not create a new ${data.leadStageTitle} Lead Stage`,
            color: "danger",
          })
        );
        throw err;
      });
  };

export const updateLeadStage =
  (
    id: number,
    data: {
      leadStageTitle: string;
      isActive: boolean;
      description: string;
      sortOrder: number;
    },
    updateSort: boolean,
    updateState: boolean
  ) =>
  async (dispatch: any) => {
    dispatch(putLeadStage(true));
    return editLeadStage(id, data)
      .then(({ data }) => {
        if (updateState) {
          dispatch(putLeadStageSuccess({ data, updateSort }));
        }
        dispatch(
          addNewToast({
            message: `${data.leadStageTitle} has been updated`,
            color: "success",
          })
        );
        return data;
      })
      .catch((err) => {
        dispatch(fetchLeadStagesFailure(err));
        dispatch(putLeadStage(false));
        dispatch(
          addNewToast({ message: "Could not update the Lead Stage", color: "danger" })
        );
        throw err;
      });
  };

export const removeLeadStage =
  (id: number, prospects: { [key: string]: any }) => async (dispatch: any) => {
    dispatch(deleteLeadStage(true));
    return delLeadStage(id)
      .then(({ data }: { [key: string]: any }) => {
        dispatch(deleteLeadStageSuccess(id));

        // also update all propects that have this leadstage id
        dispatch(
          updateProspectList({
            results: updateDeletedLeadStageOnProspects(id, prospects),
          })
        );

        return data;
      })
      .catch((err) => {
        dispatch(fetchLeadStagesFailure(err));
        dispatch(deleteLeadStage(false));
        dispatch(
          addNewToast({ message: "Could not delete a new Lead Stage", color: "danger" })
        );
        throw err;
      });
  };

export const getLeadStagesList = () => async (dispatch: any) => {
  dispatch(fetchLeadStages(true));

  return getLeadStages()
    .then((response) => {
      dispatch(fetchLeadStagesSuccess(arrayToMapIndex("id", response.data.results)));
    })
    .catch(() => dispatch(fetchLeadStagesFailure(true)));
};

// helper functions
const updateDeletedLeadStageOnProspects = (
  id: number,
  prospects: { [key: string]: any }
) => {
  const newProspects: { [key: string]: any } = {};
  for (const key in prospects) {
    if (prospects[key].leadStage === id) {
      const temp = prospects[key];
      temp.leadStage = null;
      newProspects[key] = temp;
    } else {
      newProspects[key] = prospects[key];
    }
  }
  return newProspects;
};
