import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";

// hooks
import useInterval from "module/common/hooks/useInterval";

const CallElapsedTime = ({ callStartTime, isActive, callState }) => {
  const [callDuration, setCallDuration] = useState(0);
  const [interval, setInterval] = useState(null);

  useEffect(() => {
    if (isActive) {
      setInterval(1000);
    }
  }, [isActive]);

  useInterval(() => {
    if (callStartTime) {
      const elapsedTime = new Date().getTime() - callStartTime.getTime();
      setCallDuration(Math.floor(elapsedTime / 1000));
    }
  }, interval);

  return (
    <div>
      <Label data-test="call-status">
        {callState}{" "}
        {isActive
          ? `- ${new Date(callDuration * 1000).toISOString().substr(14, 5)}`
          : `${new Date(callDuration * 1000).toISOString().substr(14, 5)}`}
      </Label>
    </div>
  );
};

export default CallElapsedTime;
