import styled, { css } from "styled-components/macro";
import { Input } from "reactstrap";

/**
 * General layout used throughout the application
 */
export const Container = styled.div`
  padding: var(--ypad) var(--xpad) 0;
`;

interface ButtonsContainerProps {
  position: "right" | "left" | "center" | null;
}

/**
 * Styling of any buttons within a form or modal
 */
export const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  justify-content: ${(props) => {
    switch (props.position) {
      case "right":
        return "flex-end";
      case "left":
        return "flex-start";
      case "center":
        return "center";
      default:
        return null;
    }
  }};
  align-items: center;
  /** if there are two buttons, only apply the style on the last last child */
  button:first-child:nth-last-child(2),
  button:first-child:nth-last-child(1) ~ button {
    margin-right: var(--pad2);
  }
`;

export const StyledInput = styled(Input)`
  margin-left: 5px;
  height: 24px;
`;

export const PageLayoutWithTables = css`
  width: calc(100vw - 83px) !important; // 83px is the size of the navbar
`;
