import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { FetchError, Fetching, Success } from "module/common/helpers/variables";
import useAsync from "module/common/hooks/useAsync";
import { addErrorToast } from "module/main/store/Global/Toasts/actions";
import { useAppDispatch } from "module/main/store/hooks";
import { selectProspectQRList } from "module/main/store/QuickRepliesStore/selectors";
import { getProspectQR } from "module/main/store/QuickRepliesStore/thunk";

const filterQuickReplyByQuestionAndMessage = (quickReplies = [], searchTerm: string) => {
  if (!searchTerm) return quickReplies;

  return quickReplies.filter(
    (quickReply: any) =>
      quickReply.question?.toLowerCase().includes(searchTerm) ||
      quickReply.message?.toLowerCase().includes(searchTerm)
  );
};

export const useProspectQuickReplies = (prospectId: number, searchTerm: string) => {
  const allQuickReplies = useSelector(selectProspectQRList);
  const [isLoadingState, setIsLoadingState] = useState(Success);
  const dispatch = useAppDispatch();
  const [executeGetProspectQR] = useAsync({
    asyncFunc: () => {
      return dispatch(getProspectQR(prospectId));
    },
  });

  useEffect(() => {
    if (prospectId && isLoadingState !== Fetching) {
      setIsLoadingState(Fetching);
      executeGetProspectQR()
        .then(() => setIsLoadingState(Success))
        .catch(() => {
          setIsLoadingState(FetchError);
          dispatch(addErrorToast("Something went wrong while fetching quick replies"));
        });
    }
  }, [prospectId, dispatch]);

  const quickRepliesSearchResults = useMemo(
    () => filterQuickReplyByQuestionAndMessage(allQuickReplies, searchTerm),
    [searchTerm, allQuickReplies]
  );

  return { allQuickReplies, isLoadingState, quickRepliesSearchResults };
};
