import { ProspectRecord } from "./interfaces";
import { createSelectorContext } from "../redux-helpers";
import { createSelector as createCachedSelector, ParametricSelector } from "reselect";
import { path } from "./reducer";
import { getIn } from "module/common/utils/utils";

const createSelector = createSelectorContext(path);

export const prospectsResults = createSelector("prospects");
export const prospectSearchResultsError = createSelector("error");
export const prospectNextPageUrl = createSelector("next");
export const prospectIsLoading = createSelector("isLoading");
export const prospectIsLoadingMore = createSelector("isLoadingMore");
export const getProspectCount = createSelector("count");
export const getFetchingMessages = createSelector("isFetchingMessages");

// Reference this for any issues or questions with the selector https://github.com/reduxjs/reselect/issues/459
// individual prospects
export const getProspect: ParametricSelector<any, any, any> = createCachedSelector(
  (state: any, id: any) => getIn([...path, "prospects", id], state),
  (prospect: any) => {
    if (prospect) return prospect;
    return ProspectRecord({}, false);
  }
);

export const selectProspectDrafts = createSelector("prospectDrafts");

export const selectProspectDraft = createCachedSelector(
  selectProspectDrafts,
  (_: any, id: number) => id,
  (prospectDrafts: any, id: number) => {
    const prospectDraft = prospectDrafts && prospectDrafts[id];
    return prospectDraft || "";
  }
);

export const getProspectsDataByIds = createCachedSelector(
  (_: any, ids: Array<number>) => ids,
  prospectsResults,
  (ids: Array<number>, prospects: any) => {
    const prospectsData: Array<any> = [];
    ids.forEach((id: number) => {
      prospectsData.push(prospects[id]);
    });
    return prospectsData;
  }
);
