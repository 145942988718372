import React from "react";
import { useFormContext } from "react-hook-form";

// components
import CustomSwitch from "../../CustomSwitch";
import { getIn } from "module/common/utils/utils";

const SwitchField = ({ field, ...props }) => {
  const checked = field.inputProps.checked;
  const checkedToggle = field.inputProps.onChange;
  const { setValue, watch } = useFormContext();
  const switchValue = watch(field.name);

  return (
    <CustomSwitch
      {...props}
      id={field.name}
      checked={checkedToggle ? checked : switchValue}
      isOffOnSwitch
      onChange={
        !checkedToggle
          ? (e) => {
              const {
                target: { checked },
              } = e;
              setValue(field.name, checked);
              const hook = getIn(["inputProps", "onChange"], field);
              hook && hook(checked);
            }
          : checkedToggle
      }
    />
  );
};

export default SwitchField;
