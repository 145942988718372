import {
  SET_CAMPAIGN_DESKTOP_PAGE_ACTIVE_TAB,
  SET_CAMPAIGN_DESKTOP_PAGE_ACTIVE_SORT,
  SET_CAMPAIGN_DESKTOP_TAB_DATA,
  RESET_CAMPAIGN_LIST_FILTER_TABS,
  RESET_CAMPAIGN_LIST_FILTER_TAB,
  SET_ACTIVE_CAMPAIGN_PROSPECT,
  REMOVE_CAMPAIGN_FROM_TAB,
  ADD_CAMPAIGN_TO_TAB,
  SET_CAMPAIGN_PAGE_ACTIVE_MARKET,
} from "./actions";
import { ARCHIVE_CAMPAIGN, UNARCHIVE_CAMPAIGN } from "../../../../Campaigns/actions";

const tabInitialData = {
  count: 0,
  sortOrder: [],
  sortedBy: null,
  nextPage: null,
  searchTerm: "",
  market: "",
};

const initialState = {
  activeSort: "-created_date",
  activeTab: "active",
  activeProspect: 0,
  activeMarket: "",
  tabs: {
    all: { ...tabInitialData },
    active: { ...tabInitialData },
    inprogress: { ...tabInitialData },
    followup: { ...tabInitialData },
    ownedbyme: { ...tabInitialData },
    archived: { ...tabInitialData },
    unread: { ...tabInitialData },
  },
};

export const path = [
  "uiStore",
  "campaignsPageDesktopView",
  "campaignsList",
  "filterData",
];

// NOTE: Add immer
export default function reducer(state: any = initialState, action: any) {
  switch (action.type) {
    case RESET_CAMPAIGN_LIST_FILTER_TAB: {
      const tab: keyof typeof initialState.tabs = action.payload;
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [tab]: initialState.tabs[tab],
        },
      };
    }
    case RESET_CAMPAIGN_LIST_FILTER_TABS:
      return {
        ...state,
        activeTab: initialState.activeTab,
        tabs: {
          ...initialState.tabs,
          archived: state.tabs.archived,
        },
      };
    case SET_CAMPAIGN_DESKTOP_PAGE_ACTIVE_SORT:
      return {
        ...state,
        activeSort: action.payload,
      };
    case SET_CAMPAIGN_DESKTOP_PAGE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case SET_CAMPAIGN_PAGE_ACTIVE_MARKET:
      return {
        ...state,
        activeMarket: action.payload,
      };
    case SET_CAMPAIGN_DESKTOP_TAB_DATA: {
      let newSortOrder = [];
      // we're just adding on to the set order as sorting is the same
      if (
        state.tabs[action.payload.tab].sortedBy === state.activeSort &&
        state.tabs[action.payload.tab].searchTerm === action.payload.data.searchTerm &&
        !action.payload.override
      ) {
        newSortOrder = [
          ...state.tabs[action.payload.tab].sortOrder,
          ...action.payload.data.sortOrder,
        ];
      } else {
        newSortOrder = action.payload.data.sortOrder;
      }

      return {
        ...state,
        tabs: {
          ...state.tabs,
          [action.payload.tab]: {
            sortOrder: newSortOrder,
            nextPage: action.payload.data.nextPage,
            sortedBy: action.payload.data.sortedBy,
            count: action.payload.data.count,
            searchTerm: action.payload.data.searchTerm,
            market: action.payload.data.market,
          },
        },
      };
    }
    case ARCHIVE_CAMPAIGN:
      // remove the archived campaign from each tab except archived tab
      if (action.payload.isArchived) {
        const tabsToRemoveFrom = ["active", "ownedbyme", "followup", "inprogress"];
        const newTabs = tabsToRemoveFrom.reduce((acc: any, key: string) => {
          const tab = { ...state.tabs[key] };
          tab.sortOrder = tab.sortOrder.filter((id: number) => id !== action.payload.id);
          tab.count = tab.sortOrder.length;
          acc[key] = tab;

          return acc;
        }, {});

        // update archived list
        const newArchivedTab = { ...state.tabs.archived };
        newArchivedTab.sortOrder.push(action.payload.id);
        newArchivedTab.count = newArchivedTab.sortOrder.length;

        return {
          ...state,
          tabs: {
            ...state.tabs,
            ...newTabs,
            archived: { ...newArchivedTab },
          },
        };
      }
      return state;
    case UNARCHIVE_CAMPAIGN: {
      const newArchivedState = { ...state.tabs.archived };
      newArchivedState.sortOrder = newArchivedState.sortOrder.filter(
        (id: any) => id !== action.payload
      );
      newArchivedState.count = newArchivedState.sortOrder.length;

      return {
        ...state,
        tabs: {
          ...state.tabs,
          archived: newArchivedState,
        },
      };
    }
    case SET_ACTIVE_CAMPAIGN_PROSPECT: {
      return {
        ...state,
        activeProspect: action.payload,
      };
    }
    case REMOVE_CAMPAIGN_FROM_TAB: {
      const { tab, id } = action.payload;
      const newOrder = state.tabs[tab].sortOrder.filter(
        (campaignId: number) => campaignId !== id
      );

      return {
        ...state,
        tabs: {
          ...state.tabs,
          [tab]: {
            ...state.tabs[tab],
            sortOrder: newOrder,
            count: newOrder.length,
          },
        },
      };
    }
    case ADD_CAMPAIGN_TO_TAB: {
      const { id, tab } = action.payload;
      const sortOrder = [...state.tabs[tab].sortOrder];
      const alreadyExists = sortOrder.findIndex(
        (campaignId: number) => campaignId === id
      );

      if (alreadyExists === -1) {
        // add it to the array
        sortOrder.push(id);
      }

      return {
        ...state,
        tabs: {
          ...state.tabs,
          [tab]: {
            ...state.tabs[tab],
            sortOrder: sortOrder,
            count: sortOrder.length,
          },
        },
      };
    }
    default:
      return state;
  }
}
