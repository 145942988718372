import axios from "axios";
import { loadTokens } from "../store/Auth/loadTokens";
import AxiosInstance from "module/common/utils/axiosConfig";

const baseURL = process.env.REACT_APP_BASE_URL;
// We have to do this to override axios 401 interceptor and do custom handling
export const neutralAxiosInstance = axios.create({ baseURL });

const prepareNeutralRequest = () => {
  const { auth } = loadTokens();
  if (!auth.token) throw new Error("Unauthorized action: access token missing.");
  neutralAxiosInstance.defaults.headers.common["Authorization"] = `Bearer ${auth.token}`;
};

export const postPodioLogin = async (data) => {
  return await AxiosInstance.post("/crm/podio/auth/", data);
};

export const patchPodioRefreshLogin = async (data) => {
  return await AxiosInstance.patch("/crm/podio/auth/refresh/", data);
};

export const getOrganizationPodio = async () => {
  prepareNeutralRequest();
  return await neutralAxiosInstance.get(`/crm/podio/organizations/get_organizations/`);
};

export const getApplicationPodio = async (spaceId) => {
  return await AxiosInstance.get(`/crm/podio/applications/${spaceId}/get_all/`);
};

export const getFieldsPodio = async (appId) => {
  return await AxiosInstance.get(`/crm/podio/fields/${appId}/get_fields/`);
};

export const getSherpaFieldsPodio = async () => {
  return await AxiosInstance.get(`/crm/podio/fields/get_sherpa_fields/`);
};

export const postMappedFieldsPodio = async (data) => {
  return await AxiosInstance.post(`/crm/podio/fields/mapped_fields/`, data);
};

export const postSyncToPodio = async (prospect_id) => {
  return await AxiosInstance.post(
    `/crm/podio/items/${prospect_id}/sync_to_podio/`,
    prospect_id
  );
};

export const getCrmStatus = async (prospect_id) => {
  return await AxiosInstance.post(
    `/crm/podio/items/${prospect_id}/get_crm_status/`,
    prospect_id
  );
};

export const postTestCreateItem = async (data) => {
  return await AxiosInstance.post(`/crm/podio/items/test_create_item/`, data);
};

export const getCrmIntegration = async () => {
  return await AxiosInstance.get(`crm/podio/auth/get_crm_integration/`);
};

export const getMappedFieldsPodio = async () => {
  return await AxiosInstance.get(`crm/podio/auth/get_podio_field_mappings/
  `);
};

export const deleteIntegrationPodio = async () => {
  return await AxiosInstance.post(`crm/podio/auth/delete/`);
};
