import { Dispatch } from "redux";
import { fetchProperty as fetchProperty_ } from "./apis";
import { updateSingleProperty } from "./actions";
import { updateProspectList } from "../prospectStore/actions";

export const fetchProperty = (id: number) => async (dispatch: Dispatch) => {
  const response = await fetchProperty_(id);
  const propertyData = normalizePropertyData(response.data);
  const results = response.data.prospects.reduce((acc: any, prospect: any) => {
    acc[prospect.id] = prospect;
    return acc;
  }, {});

  dispatch(updateSingleProperty({ ...propertyData, id, partial: false }));
  dispatch(updateProspectList({ results }));

  return response.data;
};

const normalizePropertyData = (data: any) => {
  const updatedPropertyData = data.propertyData;
  const mailingAddress = data.address.mailingAddress;
  const propertyAddress = data.address.propertyAddress;
  const tagsCount = data.propertyData.tags;

  const prospectId = data.prospects.map((prospect: { id: number }) => prospect.id);

  // remove the tags attribute as it collides with the
  // tags array from the /stacker/search endpoint
  delete updatedPropertyData.tags;

  return {
    ...updatedPropertyData,
    mailingAddress,
    propertyAddress,
    tagsCount,
    prospectId,
  };
};
